import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { type Style } from '@react-pdf/types';
import { capitalCase, sentenceCase } from 'change-case';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { type Dictionary, isNil, lowerCase, sum, truncate } from 'lodash';
import { safeMultiply, safeSubtract } from 'shared/math';
import {
  type MeQuery,
  type PaymentForJournalReportFragment,
  PaymentType,
} from '../../../../../generated/graphql';

const paymentTotalsStyles: Record<string, Style> = {
  paymentTotalsContainer: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-end',
  },
  singleTotalContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  amountContainer: {
    textAlign: 'right',
    width: '60px',
  },
};

const PaymentTotals = ({
  payment,
  onlyShowUnappliedPayments,
}: {
  readonly payment: PaymentForJournalReportFragment;
  readonly onlyShowUnappliedPayments: boolean;
}) => {
  const appliedAmount = safeSubtract(
    payment.totalAmount,
    payment.unappliedAmountDollars,
  );
  return (
    <View style={paymentTotalsStyles.paymentTotalsContainer}>
      {(!onlyShowUnappliedPayments ||
        (onlyShowUnappliedPayments && appliedAmount !== 0)) && (
        <View style={paymentTotalsStyles.singleTotalContainer}>
          <Text style={{ fontWeight: 'bold' }}>Total applied amount:</Text>
          <Text style={paymentTotalsStyles.amountContainer}>
            {currency(appliedAmount).format()}
          </Text>
        </View>
      )}
      {payment.unappliedAmountDollars !== 0 && (
        <View style={paymentTotalsStyles.singleTotalContainer}>
          <Text style={{ fontWeight: 'bold' }}>Total unapplied amount:</Text>
          <Text style={paymentTotalsStyles.amountContainer}>
            {currency(payment.unappliedAmountDollars).format()}
          </Text>
        </View>
      )}
      <View style={paymentTotalsStyles.singleTotalContainer}>
        <Text style={{ fontWeight: 'bold' }}>
          {`Total ${lowerCase(payment.paymentType)} amount:`}
        </Text>
        <Text style={paymentTotalsStyles.amountContainer}>
          {currency(payment.totalAmount).format()}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    paddingLeft: 23,
    paddingRight: 23,
    fontFamily: 'Roboto',
  },
  header1: {
    fontSize: '24px',
  },
  header2: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
});

const CELL_MARGIN = '10px';
const PAGE_BLOCK_SIZE = '300px';

export type GeneratedPaymentJournalReportProps = {
  readonly companyData: MeQuery | undefined;
  readonly dateString: string;
  readonly terminalName: string | undefined;
  readonly paymentsByContact: Dictionary<PaymentForJournalReportFragment[]>;
  readonly paymentTypes: PaymentType[];
  readonly onlyShowUnappliedPayments: boolean;
};

const GeneratedPaymentJournalReport = ({
  companyData,
  dateString,
  terminalName,
  paymentsByContact,
  paymentTypes,
  onlyShowUnappliedPayments,
}: GeneratedPaymentJournalReportProps) => {
  const paymentsByContactTransformed = Object.values(paymentsByContact).map(
    (payment) =>
      payment.map((p) =>
        p.paymentType === PaymentType.Debit
          ? {
              ...p,
              totalAmount: safeMultiply(p.totalAmount, -1),
              orders: p.orders.map((order) => ({
                ...order,
                appliedAmountDollars: safeMultiply(
                  order.appliedAmountDollars,
                  -1,
                ),
              })),
            }
          : p,
      ),
  );
  const paymentTotal = sum(
    paymentsByContactTransformed.flatMap((payment) =>
      payment.map((p) => p.totalAmount),
    ),
  );
  const unappliedTotal = sum(
    paymentsByContactTransformed.flatMap((payment) =>
      payment.map((p) => p.unappliedAmountDollars),
    ),
  );
  const appliedTotal = safeSubtract(paymentTotal, unappliedTotal);

  const paymentTypeStr = paymentTypes
    .map((paymentType) => capitalCase(paymentType))
    .join('/');

  return (
    <Document title={`${paymentTypeStr} Journal Report`}>
      <Page size="LETTER" style={styles.page}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: '12px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text style={styles.header1}>{paymentTypeStr} Journal Report</Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '10px',
            fontSize: '12px',
          }}
        >
          <View>
            <Text style={styles.header2}>{companyData?.me?.company.name}</Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: CELL_MARGIN,
              justifyContent: 'space-between',
            }}
          >
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: PAGE_BLOCK_SIZE,
                }}
              >
                {onlyShowUnappliedPayments && (
                  <Text style={{ fontWeight: 'bold' }}>Unapplied only</Text>
                )}
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Text style={{ fontWeight: 'bold' }}>Date</Text>
                  <Text style={{ marginLeft: '10px' }}>{dateString}</Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={{ fontWeight: 'bold' }}>Terminal</Text>
                  <Text style={{ marginLeft: '10px' }}>
                    {isNil(terminalName) ? 'All' : terminalName}
                  </Text>
                </View>
              </View>
            </View>
          </View>
          <View
            style={{
              marginTop: '10px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {paymentsByContactTransformed
              // Sort first by contact name and then by date. Note that a and b will be non-empty, and the nullish coalescing operator is used here just to please TypeScript.
              .sort(
                (a, b) =>
                  a[0]?.contactDisplayName.localeCompare(
                    b[0]?.contactDisplayName ?? '',
                  ) ?? 0,
              )
              .map((payments) => {
                const contactTotal = sum(
                  payments.map((payment) =>
                    onlyShowUnappliedPayments
                      ? payment.unappliedAmountDollars
                      : payment.totalAmount,
                  ),
                );
                return (
                  <View
                    key={payments.reduce(
                      (acc, payment) => `${acc}${payment.referenceNumber}`,
                      '',
                    )}
                    style={{
                      fontSize: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      paddingBottom: '5px',
                    }}
                  >
                    <View
                      style={{ width: '100%', backgroundColor: 'gainsboro' }}
                    >
                      <Text style={{ fontWeight: 'bold' }}>
                        {payments[0]?.contactDisplayName}
                      </Text>
                      <hr />
                    </View>
                    {payments
                      .sort((a, b) =>
                        dayjs(a.paymentDate).diff(dayjs(b.paymentDate)),
                      )
                      .map((payment) => (
                        <View
                          key={payment.referenceNumber}
                          style={{
                            fontSize: '10px',
                            display: 'flex',
                            flexDirection: 'column',
                            borderBottom: '2pt solid dimgray',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            marginBottom: '15px',
                          }}
                        >
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                <View
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <Text style={{ fontWeight: 'bold' }}>
                                    Ref #:{' '}
                                  </Text>
                                  <Text style={{ fontWeight: 'bold' }}>
                                    Comment:{' '}
                                  </Text>
                                </View>
                                <View
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: '10px',
                                  }}
                                >
                                  <Text>{payment.referenceNumber}</Text>
                                  <Text>{payment.comment}</Text>
                                </View>
                              </View>
                            </View>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                flex: 1,
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                <View
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  {payment.paymentType ===
                                    PaymentType.Credit && (
                                    <Text style={{ fontWeight: 'bold' }}>
                                      Credit #:{' '}
                                    </Text>
                                  )}
                                  {payment.paymentType === PaymentType.Debit ||
                                    (payment.paymentType ===
                                      PaymentType.Credit && (
                                      <>
                                        <Text style={{ fontWeight: 'bold' }}>
                                          GL terminal:{' '}
                                        </Text>
                                        <Text style={{ fontWeight: 'bold' }}>
                                          {sentenceCase(payment.paymentType)}{' '}
                                          type:{' '}
                                        </Text>
                                      </>
                                    ))}
                                </View>
                                <View
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginLeft: '10px',
                                  }}
                                >
                                  {payment.paymentType ===
                                    PaymentType.Credit && (
                                    <Text>{payment.creditNumber}</Text>
                                  )}
                                  {payment.paymentType === PaymentType.Debit ||
                                    (payment.paymentType ===
                                      PaymentType.Credit && (
                                      <>
                                        <Text>
                                          {payment.glTerminalName ?? '-'}
                                        </Text>
                                        <Text>
                                          {payment.creditTypeName ?? '-'}
                                        </Text>
                                      </>
                                    ))}
                                </View>
                              </View>
                            </View>
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-end',
                                flex: 1,
                              }}
                            >
                              <View
                                style={{
                                  backgroundColor: '#e4e4e4',
                                }}
                              >
                                <Text style={{ fontWeight: 'bold' }}>
                                  {payment.paymentType.toUpperCase()}
                                </Text>
                                <hr />
                              </View>
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                <Text style={{ fontWeight: 'bold' }}>
                                  Date:{' '}
                                </Text>
                                <Text style={{ marginLeft: '10px' }}>
                                  {dayjs(payment.paymentDate).format(
                                    'MM/DD/YYYY',
                                  )}
                                </Text>
                              </View>
                            </View>
                          </View>
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                            >
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  borderBottom: '1pt solid darkgray',
                                }}
                              >
                                <Text
                                  style={{ fontWeight: 'bold', width: '10%' }}
                                >
                                  Journal #
                                </Text>
                                <Text
                                  style={{ fontWeight: 'bold', width: '10%' }}
                                >
                                  Inv date
                                </Text>
                                <Text
                                  style={{ fontWeight: 'bold', width: '15%' }}
                                >
                                  Inv name
                                </Text>
                                <Text
                                  style={{ fontWeight: 'bold', width: '20%' }}
                                >
                                  Inv customer
                                </Text>
                                <Text
                                  style={{ fontWeight: 'bold', width: '15%' }}
                                >
                                  Order #
                                </Text>
                                <Text
                                  style={{ fontWeight: 'bold', width: '20%' }}
                                >
                                  HAWB
                                </Text>
                                <Text
                                  style={{
                                    fontWeight: 'bold',
                                    width: '10%',
                                    textAlign: 'right',
                                  }}
                                >
                                  Applied
                                </Text>
                              </View>
                              {payment.orders
                                .sort((a, b) => {
                                  const aJournalNumber = a.invoiceJournalNumber;
                                  const bJournalNumber = b.invoiceJournalNumber;
                                  if (
                                    !isNil(aJournalNumber) &&
                                    !isNil(bJournalNumber)
                                  ) {
                                    return aJournalNumber - bJournalNumber;
                                  }
                                  if (
                                    !isNil(a.invoiceName) &&
                                    !isNil(b.invoiceName)
                                  ) {
                                    return a.invoiceName.localeCompare(
                                      b.invoiceName,
                                    );
                                  }
                                  return 0;
                                })
                                .map((order) => {
                                  return (
                                    <View
                                      key={order.orderName}
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                      }}
                                    >
                                      <Text
                                        style={{
                                          width: '10%',
                                        }}
                                      >
                                        {order.invoiceJournalNumber}
                                      </Text>
                                      <Text
                                        style={{
                                          width: '10%',
                                        }}
                                      >
                                        {dayjs(order.invoiceDate).format(
                                          'MM/DD/YY',
                                        )}
                                      </Text>
                                      <Text
                                        style={{
                                          width: '15%',
                                        }}
                                      >
                                        {order.invoiceName}
                                      </Text>
                                      <Text
                                        style={{
                                          width: '20%',
                                        }}
                                      >
                                        {truncate(
                                          order.invoiceCustomerName ?? '',
                                          { length: 18 },
                                        )}
                                      </Text>
                                      <Text style={{ width: '15%' }}>
                                        {order.orderName}
                                      </Text>
                                      <Text style={{ width: '20%' }}>
                                        {order.shipperBillOfLadingNumber ?? ''}
                                      </Text>
                                      <Text
                                        style={{
                                          width: '10%',
                                          textAlign: 'right',
                                        }}
                                      >
                                        {currency(
                                          order.appliedAmountDollars,
                                        ).format()}
                                      </Text>
                                    </View>
                                  );
                                })}
                              <PaymentTotals
                                payment={payment}
                                onlyShowUnappliedPayments={
                                  onlyShowUnappliedPayments
                                }
                              />
                            </View>
                          </View>
                        </View>
                      ))}
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        paddingTop: '5px',
                      }}
                    >
                      <Text style={{ marginLeft: '10px' }}>
                        {currency(contactTotal).format()}
                      </Text>
                      <Text style={{ fontWeight: 'bold' }}>
                        Total {onlyShowUnappliedPayments ? 'unapplied ' : ''}
                        for {payments[0]?.contactDisplayName}:
                      </Text>
                    </View>
                  </View>
                );
              })}
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: CELL_MARGIN,
                color: '#181818',
              }}
            >
              {!onlyShowUnappliedPayments && (
                <View
                  style={{
                    fontSize: '10px',
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    paddingTop: '5px',
                    borderTop: '1pt solid black',
                  }}
                >
                  <Text style={{ marginLeft: '10px' }}>
                    {currency(appliedTotal).format()}
                  </Text>
                  <Text style={{ fontWeight: 'bold' }}>
                    Total applied amount:
                  </Text>
                </View>
              )}
              <View
                style={{
                  fontSize: '10px',
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  paddingTop: '5px',
                  borderTop: '1pt solid black',
                }}
              >
                <Text style={{ marginLeft: '10px' }}>
                  {currency(unappliedTotal).format()}
                </Text>
                <Text style={{ fontWeight: 'bold' }}>
                  Total unapplied amount:
                </Text>
              </View>
              {!onlyShowUnappliedPayments && (
                <View
                  style={{
                    fontSize: '10px',
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    paddingTop: '5px',
                    borderTop: '1pt solid black',
                  }}
                >
                  <Text style={{ marginLeft: '10px' }}>
                    {currency(paymentTotal).format()}
                  </Text>
                  <Text style={{ fontWeight: 'bold' }}>Total:</Text>
                </View>
              )}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default GeneratedPaymentJournalReport;
