import { FeatureFlag } from '../../../common/feature-flags';
import { CUSTOMER_PORTAL_ORDERS_PATH } from '../constants';
import CustomerPortalFeatureFlagGuard from './common/customer-portal-feature-flag-guard';
import CustomerPortalBookingRequestsTable from './customer-portal-booking-requests/customer-portal-booking-requests-table';

const CustomerPortalBookingRequestsPage = () => {
  return (
    <CustomerPortalFeatureFlagGuard
      featureFlag={FeatureFlag.FF_CUSTOMER_PORTAL_ORDER_ENTRY}
      navigateTo={CUSTOMER_PORTAL_ORDERS_PATH}
    >
      <CustomerPortalBookingRequestsTable />
    </CustomerPortalFeatureFlagGuard>
  );
};

export default CustomerPortalBookingRequestsPage;
