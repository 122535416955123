import { type Dayjs } from 'dayjs';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { SortType } from '../../../common/components/sort-component';
import { type Option } from '../../../common/filters/types';
import { useDriverSettlementBillsV2LazyQuery } from '../../../generated/graphql';
import { SettlementBillsTabOption } from '../enums';

export const SETTLEMENT_BILLS_ROW_PER_PAGE_OPTIONS = [25, 50, 100];
const PAGE_SIZE_STORAGE_KEY = 'settlement-bills-page-size';

export const useGetSettlementBills = () => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useLocalStorageState(
    PAGE_SIZE_STORAGE_KEY,
    {
      defaultValue: SETTLEMENT_BILLS_ROW_PER_PAGE_OPTIONS[0],
    },
  );

  const [tab, setTab] = useState<SettlementBillsTabOption>(
    SettlementBillsTabOption.NOT_FINALIZED,
  );
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [driverOptions, setDriverOptions] = useState<
    Option[] | null | undefined
  >(null);
  const [searchText, setSearchText] = useState<string>('');
  const [selectedSettlementBillIds, setSelectedSettlementBillIds] = useState<
    Set<string>
  >(new Set());
  const [settlementDateSort, setSettlementDateSort] = useState<SortType>(
    SortType.Descending,
  );

  const queryVariables = useMemo(() => {
    return {
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString(),
      driverUuids: driverOptions?.map((option) => option.value),
      isFinalized:
        tab === SettlementBillsTabOption.ALL
          ? undefined
          : tab === SettlementBillsTabOption.FINALIZED,
      searchText: isEmpty(searchText) ? undefined : searchText,
      sortBySettlementDateAscending: settlementDateSort === SortType.Ascending,
    };
  }, [startDate, endDate, driverOptions, tab, searchText, settlementDateSort]);

  const [getSettlements, { data, loading }] =
    useDriverSettlementBillsV2LazyQuery();
  const fetchData = useCallback(
    async (paginationParams: {
      first?: number;
      after?: string;
      last?: number;
      before?: string;
    }) => {
      setSelectedSettlementBillIds(new Set());
      await getSettlements({
        variables: {
          ...paginationParams,
          ...queryVariables,
        },
      });
    },
    [queryVariables, getSettlements],
  );

  const settlementBills = useMemo(
    () => data?.driverSettlementBills.edges.map((edge) => edge.node) ?? [],
    [data?.driverSettlementBills.edges],
  );
  const pageInfo = data?.driverSettlementBills.pageInfo;
  const totalCount = data?.driverSettlementBills.totalCount ?? 0;

  const refresh = useCallback(async () => {
    setPage(0);
    return fetchData({
      first: rowsPerPage,
    });
  }, [fetchData, rowsPerPage]);

  const handlePageChange = async ({
    newPage,
    forward,
  }: {
    newPage: number;
    forward: boolean;
  }) => {
    setPage(newPage);

    const paginationParams = forward
      ? { first: rowsPerPage, after: pageInfo?.endCursor ?? undefined }
      : { last: rowsPerPage, before: pageInfo?.startCursor ?? undefined };
    await fetchData(paginationParams);
  };

  const handleRowsPerPageChange = async (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
  };

  const handleTabChange = (newTab: SettlementBillsTabOption) => {
    setTab(newTab);
  };

  const handleDriverOptionsChange = (
    newDriverOptions: Option[] | null | undefined,
  ) => {
    setDriverOptions(newDriverOptions);
  };

  const handleDateRangeChange = (newRange: [Dayjs | null, Dayjs | null]) => {
    setStartDate(newRange[0]);
    setEndDate(newRange[1]);
  };

  const handleSearchTextChange = (newSearchText: string) => {
    setSearchText(newSearchText);
  };

  const handleSettlementDateSort = () => {
    setSettlementDateSort((prev) =>
      prev === SortType.Ascending ? SortType.Descending : SortType.Ascending,
    );
  };

  const handleSelectSettlementBill = (settlementBillId: string) => {
    setSelectedSettlementBillIds((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(settlementBillId)) {
        newSet.delete(settlementBillId);
      } else {
        newSet.add(settlementBillId);
      }
      return newSet;
    });
  };

  const handleSelectAllSettlementBills = (checked: boolean) => {
    setSelectedSettlementBillIds(
      checked ? new Set(settlementBills.map((bill) => bill.uuid)) : new Set(),
    );
  };

  useEffect((): void => {
    setSelectedSettlementBillIds(new Set());
    void refresh();
  }, [refresh]);

  return {
    loading,
    page,
    rowsPerPage,
    tab,
    startDate,
    endDate,
    driverOptions,
    settlementBills,
    totalCount,
    handlePageChange,
    handleRowsPerPageChange,
    handleTabChange,
    handleDriverOptionsChange,
    handleDateRangeChange,
    handleSearchTextChange,
    searchText,
    selectedSettlementBillIds,
    handleSelectSettlementBill,
    handleSelectAllSettlementBills,
    settlementDateSort,
    handleSettlementDateSort,
    refresh,
  };
};
