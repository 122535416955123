import { Warning } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';

const LineHaulPartialPiecesWarning = ({
  totalScans,
  totalPieces,
}: {
  readonly totalScans: number;
  readonly totalPieces: number;
}) => {
  if (totalScans > 0 && totalScans < totalPieces) {
    return (
      <Stack direction="row" alignItems="center" gap={0.5}>
        <Warning fontSize="small" color="warning" sx={{ fontSize: 12 }} />
        <Typography fontSize={12}>
          {totalScans} of {totalPieces} pcs
        </Typography>
      </Stack>
    );
  }
  return null;
};

export default LineHaulPartialPiecesWarning;
