import { isNil, uniqBy } from 'lodash';
import { useMemo } from 'react';
import useMeAsThirdPartyUser from '../../../common/react-hooks/use-me-as-third-party-user';

/**
 * Returns contacts and distinct companies for the current third-party user.
 *
 * @param filterByCustomerPortalOrderEntryEnabled - If true, only returns contacts with
 * `enableCustomerPortalOrderEntry` = true.
 */
const useContactsForMeAsThirdPartyUser = ({
  filterByCustomerPortalOrderEntryEnabled = false,
}: {
  filterByCustomerPortalOrderEntryEnabled: boolean;
}) => {
  const { thirdPartyUser, loading } = useMeAsThirdPartyUser();

  const contacts = useMemo(() => {
    if (isNil(thirdPartyUser)) {
      return [];
    }

    if (filterByCustomerPortalOrderEntryEnabled) {
      return thirdPartyUser.contacts.filter(
        (c) =>
          c.__typename === 'CustomerContactEntity' &&
          c.enableCustomerPortalOrderEntry,
      );
    }

    return thirdPartyUser.contacts;
  }, [thirdPartyUser, filterByCustomerPortalOrderEntryEnabled]);

  const distinctCompanies = useMemo(() => {
    const companies = contacts.map((c) => c.company);
    return uniqBy(companies, 'uuid');
  }, [contacts]);

  return { distinctCompanies, contacts, loading };
};

export default useContactsForMeAsThirdPartyUser;
