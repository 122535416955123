import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { useCallback, useMemo } from 'react';
import { exhaustive } from 'shared/switch';
import MultiSelectFilterEditorWithOptions from '../../../../common/filters/editors/multi-select-filter-editor';
import SingleSelectFilterEditorWithOptions from '../../../../common/filters/editors/single-select-filter-editor';
import FilterPills from '../../../../common/filters/filter-pills';
import { type Option } from '../../../../common/filters/types';
import {
  type ExtractOperators,
  type FilterConfig,
  type FilterGroup,
  type NullableFilterCondition,
} from '../../../../common/filters/types-v2';
import {
  isFilterCondition,
  isFilterGroup,
} from '../../../../common/filters/utils-v2';
import useContacts from '../../../../common/react-hooks/use-contacts';
import {
  type ContactFragment,
  type FindQuoteFiltersInput,
  QuoteSource,
  QuoteStatus,
} from '../../../../generated/graphql';

type QuoteFilterField = 'source' | 'status' | 'contactUuid';

type QuoteFilterOperatorMap = {
  source: {
    eq: QuoteSource;
    in: QuoteSource[];
  };
  status: {
    eq: QuoteStatus;
    in: QuoteStatus[];
  };
  contactUuid: {
    eq: string;
    in: string[];
  };
};

type QuoteFilterType = keyof QuoteFilterOperatorMap;

type QuoteFilterOperator = ExtractOperators<
  QuoteFilterType,
  QuoteFilterOperatorMap,
  QuoteFilterType
>;

const QUOTE_FILTER_FIELD_TO_TYPE_MAP = {
  source: 'source',
  status: 'status',
  contactUuid: 'contactUuid',
} as const;
type QuoteFilterFieldMap = typeof QUOTE_FILTER_FIELD_TO_TYPE_MAP;

export type QuoteFilters = FilterGroup<
  QuoteFilterField,
  QuoteFilterType,
  QuoteFilterOperatorMap,
  QuoteFilterFieldMap
>;

type NullableQuoteFiltersCondition = NullableFilterCondition<
  QuoteFilterField,
  QuoteFilterType,
  QuoteFilterOperatorMap,
  QuoteFilterFieldMap
>;

type QuoteFiltersConfig = FilterConfig<
  QuoteFilterField,
  QuoteFilterType,
  QuoteFilterOperatorMap,
  QuoteFilterFieldMap
>;

const DEFAULT_EMPTY_QUOTE_FILTER_CONDITION: QuoteFiltersConfig['defaultEmptyFilterCondition'] =
  {
    field: null,
    operator: null,
    value: null,
  };

const QUOTE_FILTER_FIELD_LABELS: Record<QuoteFilterField, string> = {
  source: 'Source',
  status: 'Status',
  contactUuid: 'Customer',
};

const QUOTE_FIELD_OPERATOR_LABELS: QuoteFiltersConfig['filterOperatorLabels'] =
  {
    source: {
      eq: 'Is',
      in: 'Is one of',
    },
    status: {
      eq: 'Is',
      in: 'Is one of',
    },
    contactUuid: {
      eq: 'Is',
      in: 'Is one of',
    },
  };

const QUOTE_SOURCE_OPTIONS: Array<Option<QuoteSource>> = Object.values(
  QuoteSource,
).map((source) => ({
  label: sentenceCase(source),
  value: source,
}));

const QUOTE_STATUS_OPTIONS: Array<Option<QuoteStatus>> = Object.values(
  QuoteStatus,
)
  // Filter out unused / deprecated statuses
  .filter((status) => status !== QuoteStatus.PendingContactApproval)
  .map((status) => ({
    label: sentenceCase(status),
    value: status,
  }));

// The static part of the filter config that doesn't depend on fetching contacts
const QUOTES_FILTER_CONFIG_BASE: Omit<
  QuoteFiltersConfig,
  'filterEditorComponents' | 'renderFilterParts'
> = {
  entityName: 'quote',
  supportsNesting: false,
  filterFieldLabels: QUOTE_FILTER_FIELD_LABELS,
  filterOperatorLabels: QUOTE_FIELD_OPERATOR_LABELS,
  filterFieldToTypeMap: QUOTE_FILTER_FIELD_TO_TYPE_MAP,
  defaultEmptyFilterCondition: DEFAULT_EMPTY_QUOTE_FILTER_CONDITION,
};

const renderQuoteFilterValue = (
  filterCondition: NullableQuoteFiltersCondition,
  contacts: Array<Pick<ContactFragment, 'uuid' | 'displayName'>>,
): string | null => {
  const { field, operator, value } = filterCondition;
  if (isNil(field) || isNil(operator) || isNil(value)) {
    return null;
  }
  switch (field) {
    case 'source':
    case 'status': {
      switch (operator) {
        case 'in': {
          return value.map((x) => sentenceCase(x)).join(', ');
        }
        case 'eq': {
          return sentenceCase(value);
        }
        default: {
          return exhaustive(operator);
        }
      }
    }
    case 'contactUuid': {
      switch (operator) {
        case 'in': {
          return value
            .map(
              (uuid) =>
                contacts.find((c) => c.uuid === uuid)?.displayName ?? uuid,
            )
            .join(', ');
        }
        case 'eq': {
          return contacts.find((c) => c.uuid === value)?.displayName ?? value;
        }
        default: {
          return exhaustive(operator);
        }
      }
    }
    default: {
      return exhaustive(field);
    }
  }
};

const useQuotesFilterConfig = (): QuoteFiltersConfig => {
  const { contacts } = useContacts();
  const contactOptions = useMemo(
    () =>
      contacts.map((contact) => ({
        label: contact.displayName,
        value: contact.uuid,
      })),
    [contacts],
  );

  const quoteFilterEditorComponents: QuoteFiltersConfig['filterEditorComponents'] =
    useMemo(
      () => ({
        source: {
          eq: SingleSelectFilterEditorWithOptions<QuoteSource>(
            QUOTE_SOURCE_OPTIONS,
          ),
          in: MultiSelectFilterEditorWithOptions<QuoteSource>(
            QUOTE_SOURCE_OPTIONS,
          ),
        },
        status: {
          eq: SingleSelectFilterEditorWithOptions<QuoteStatus>(
            QUOTE_STATUS_OPTIONS,
          ),
          in: MultiSelectFilterEditorWithOptions<QuoteStatus>(
            QUOTE_STATUS_OPTIONS,
          ),
        },
        contactUuid: {
          eq: SingleSelectFilterEditorWithOptions<string>(contactOptions),
          in: MultiSelectFilterEditorWithOptions<string>(contactOptions),
        },
      }),
      [contactOptions],
    );

  const renderQuoteFilterParts: QuoteFiltersConfig['renderFilterParts'] =
    useCallback(
      (filterCondition) => {
        const { field, operator, value } = filterCondition;
        if (isNil(field) || isNil(operator) || isNil(value)) {
          return null;
        }

        const renderedField = QUOTE_FILTER_FIELD_LABELS[field];
        const renderedOperator = QUOTE_FIELD_OPERATOR_LABELS[field][operator];
        const renderedValue = renderQuoteFilterValue(filterCondition, contacts);

        if (isNil(renderedValue)) {
          return null;
        }

        return {
          field: renderedField,
          operator: renderedOperator,
          value: renderedValue,
        };
      },
      [contacts],
    );

  return useMemo(
    () => ({
      ...QUOTES_FILTER_CONFIG_BASE,
      filterEditorComponents: quoteFilterEditorComponents,
      renderFilterParts: renderQuoteFilterParts,
    }),
    [quoteFilterEditorComponents, renderQuoteFilterParts],
  );
};

type QuoteFilterPillsProps = {
  readonly filters: Partial<FindQuoteFiltersInput>;
  readonly setFilters: (filters: FindQuoteFiltersInput) => void;
  readonly wrap: boolean;
};

export const QuoteFilterPills = ({
  filters: findQuoteFiltersInput,
  setFilters: setFindQuoteFiltersInput,
  wrap,
}: QuoteFilterPillsProps) => {
  const filterConfig = useQuotesFilterConfig();

  const filters = convertFindQuoteFiltersInputToFilterGroup(
    findQuoteFiltersInput,
  );

  const setFilters = (filters: QuoteFilters) => {
    setFindQuoteFiltersInput(
      convertFilterGroupToFindQuoteFiltersInput(filters),
    );
  };

  return (
    <FilterPills<
      QuoteFilterField,
      QuoteFilterType,
      QuoteFilterOperatorMap,
      QuoteFilterFieldMap
    >
      filters={filters}
      setFilters={setFilters}
      wrap={wrap}
      filterConfig={filterConfig}
    />
  );
};

const convertFindQuoteFiltersInputToFilterGroup = (
  filters: FindQuoteFiltersInput | null | undefined,
): QuoteFilters => {
  if (isNil(filters)) {
    return {
      operator: 'AND',
      conditions: [],
    };
  }

  const conditions: QuoteFilters['conditions'] = [];

  // This is pretty messy / repetitive, but it's the only way I could get the
  // types to work out.

  if (!isNil(filters.sourceFilter)) {
    if (!isNil(filters.sourceFilter.in)) {
      conditions.push({
        field: 'source',
        operator: 'in',
        value: filters.sourceFilter.in,
      });
    } else if (!isNil(filters.sourceFilter.eq)) {
      conditions.push({
        field: 'source',
        operator: 'eq',
        value: filters.sourceFilter.eq,
      });
    }
  }

  if (!isNil(filters.statusFilter)) {
    if (!isNil(filters.statusFilter.in)) {
      conditions.push({
        field: 'status',
        operator: 'in',
        value: filters.statusFilter.in,
      });
    } else if (!isNil(filters.statusFilter.eq)) {
      conditions.push({
        field: 'status',
        operator: 'eq',
        value: filters.statusFilter.eq,
      });
    }
  }

  if (!isNil(filters.contactUuidFilter)) {
    if (!isNil(filters.contactUuidFilter.in)) {
      conditions.push({
        field: 'contactUuid',
        operator: 'in',
        value: filters.contactUuidFilter.in,
      });
    } else if (!isNil(filters.contactUuidFilter.eq)) {
      conditions.push({
        field: 'contactUuid',
        operator: 'eq',
        value: filters.contactUuidFilter.eq,
      });
    }
  }

  // The backend implementation of quotes filters doesn't support nested filter groups,
  // but we still need to wrap this in a top-level filter group, because our filters
  // editor supports nesting filter groups
  return {
    operator: 'AND',
    conditions: [
      {
        operator: 'AND',
        conditions,
      },
    ],
  };
};

// Using arrays rather than records so the type of the key is preserved
// when iterating (Object.entries() returns string keys)
const FIND_QUOTES_FILTERS_INPUT_PATHS = [
  ['source', 'sourceFilter'],
  ['status', 'statusFilter'],
  ['contactUuid', 'contactUuidFilter'],
] satisfies Array<[QuoteFilterField, keyof FindQuoteFiltersInput]>;

const convertFilterGroupToFindQuoteFiltersInput = (
  filters: QuoteFilters,
): FindQuoteFiltersInput => {
  // We don't support nesting for quote filters, so we expect that there's a
  // single top-level filter group with only single filter conditions (no nested
  // filter groups).
  const topLevelFilterGroup = filters.conditions[0];
  if (isNil(topLevelFilterGroup) || !isFilterGroup(topLevelFilterGroup)) {
    return {};
  }
  const conditions = topLevelFilterGroup.conditions.filter((f) =>
    isFilterCondition(f),
  );

  const findQuoteFiltersInput: FindQuoteFiltersInput = {};

  for (const [field, path] of FIND_QUOTES_FILTERS_INPUT_PATHS) {
    const condition = conditions.find((condition) => condition.field === field);
    if (isNil(condition)) {
      continue;
    }
    findQuoteFiltersInput[path] = {
      [condition.operator]: condition.value,
    };
  }

  return findQuoteFiltersInput;
};

// For testing :(
export {
  convertFilterGroupToFindQuoteFiltersInput,
  convertFindQuoteFiltersInputToFilterGroup,
};
