import { LineHaulManifestStatus } from '../../../../../generated/graphql';
import { Box, styled } from '@mui/material';

const TerminalBadgeContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  flexShrink: 0,
});

const TerminalBadgeColorsMap: Readonly<
  Record<
    LineHaulManifestStatus | 'Awaiting',
    {
      backgroundColor: string;
      color: string;
    }
  >
> = {
  Awaiting: {
    backgroundColor: '#EBEBED',
    color: '#888D9D',
  },
  [LineHaulManifestStatus.Planning]: {
    backgroundColor: '#CB981933',
    color: '#806010',
  },
  [LineHaulManifestStatus.Departed]: {
    backgroundColor: '#4C8EFF26',
    color: '#2A74F3',
  },
  [LineHaulManifestStatus.Arrived]: {
    backgroundColor: '#00AE6F33',
    color: '#008454',
  },
};

type TerminalBadgeProps = {
  readonly code: string;
  readonly status: keyof typeof TerminalBadgeColorsMap;
  readonly isCurrentTerminal: boolean;
  readonly isDestinationTerminal: boolean;
};

const BADGE_CONFIGS = {
  default: {
    width: 40.51,
    textOffsetX: 17,
  },
  current: {
    width: 46,
    textOffsetX: 28,
  },
  destination: {
    width: 36,
    textOffsetX: 17,
  },
  currentAndDestination: {
    width: 46,
    textOffsetX: 28,
  },
} as const;

const TerminalBadge = ({
  code,
  status,
  isCurrentTerminal,
  isDestinationTerminal,
}: TerminalBadgeProps) => {
  const { backgroundColor, color } = TerminalBadgeColorsMap[status];

  const truncatedCode = code.slice(0, 3);
  const fontSize = 12;
  const height = 20;
  const config = (() => {
    if (isCurrentTerminal && isDestinationTerminal) {
      return BADGE_CONFIGS.currentAndDestination;
    }
    if (isCurrentTerminal) {
      return BADGE_CONFIGS.current;
    }
    if (isDestinationTerminal) {
      return BADGE_CONFIGS.destination;
    }
    return BADGE_CONFIGS.default;
  })();

  if (isCurrentTerminal && isDestinationTerminal) {
    return (
      <TerminalBadgeContainer>
        <svg
          width={config.width}
          height={height}
          viewBox={`0 0 ${config.width} ${height}`}
        >
          <rect
            width={config.width}
            height={height}
            rx="3"
            fill={backgroundColor}
          />
          <path
            d="M8 5C6.065 5 4.5 6.565 4.5 8.5C4.5 11.125 8 15 8 15C8 15 11.5 11.125 11.5 8.5C11.5 6.565 9.935 5 8 5ZM8 9.75C7.31 9.75 6.75 9.19 6.75 8.5C6.75 7.81 7.31 7.25 8 7.25C8.69 7.25 9.25 7.81 9.25 8.5C9.25 9.19 8.69 9.75 8 9.75Z"
            fill={color}
          />
          <text
            x={config.textOffsetX}
            y={height / 2}
            fill={color}
            fontSize={fontSize}
            textAnchor="middle"
            alignmentBaseline="central"
          >
            {truncatedCode}
          </text>
        </svg>
      </TerminalBadgeContainer>
    );
  }
  if (isCurrentTerminal) {
    return (
      <TerminalBadgeContainer>
        <svg width="50" height="20" viewBox="0 0 50 20">
          <path
            d="M43.5139 0C44.2715 0 44.964 0.428005 45.3028 1.10557L49.3028 9.10557C49.5843 9.66863 49.5843 10.3314 49.3028 10.8944L45.3028 18.8944C44.964 19.572 44.2715 20 43.5139 20H3C1.34315 20 0 18.6569 0 17V3C0 1.34315 1.34315 0 3 0L43.5139 0Z"
            fill={backgroundColor}
          />
          <path
            d="M8 5C6.065 5 4.5 6.565 4.5 8.5C4.5 11.125 8 15 8 15C8 15 11.5 11.125 11.5 8.5C11.5 6.565 9.935 5 8 5ZM8 9.75C7.31 9.75 6.75 9.19 6.75 8.5C6.75 7.81 7.31 7.25 8 7.25C8.69 7.25 9.25 7.81 9.25 8.5C9.25 9.19 8.69 9.75 8 9.75Z"
            fill={color}
          />
          <text
            x={config.textOffsetX}
            y={height / 2}
            fill={color}
            fontSize={fontSize}
            textAnchor="middle"
            alignmentBaseline="central"
          >
            {truncatedCode}
          </text>
        </svg>
      </TerminalBadgeContainer>
    );
  }
  if (isDestinationTerminal) {
    return (
      <TerminalBadgeContainer>
        <svg
          width={config.width}
          height={height}
          viewBox={`0 0 ${config.width} ${height}`}
        >
          <rect
            width={config.width}
            height={height}
            rx="3"
            fill={backgroundColor}
          />
          <text
            x={config.textOffsetX}
            y={height / 2}
            fill={color}
            fontSize={fontSize}
            textAnchor="middle"
            alignmentBaseline="central"
          >
            {truncatedCode}
          </text>
        </svg>
      </TerminalBadgeContainer>
    );
  }

  return (
    <TerminalBadgeContainer>
      <svg
        width={config.width}
        viewBox={`0 0 ${config.width} ${height}`}
        height={height}
      >
        <path
          d="M34.5139 0C35.2715 0 35.964 0.428005 36.3028 1.10557L40.3028 9.10557C40.5843 9.66863 40.5843 10.3314 40.3028 10.8944L36.3028 18.8944C35.964 19.572 35.2715 20 34.5139 20H3C1.34315 20 0 18.6569 0 17V3C0 1.34315 1.34315 0 3 0L34.5139 0Z"
          fill={backgroundColor}
        />
        <text
          x={config.textOffsetX}
          y={height / 2}
          fill={color}
          fontSize={fontSize}
          textAnchor="middle"
          alignmentBaseline="central"
        >
          {truncatedCode}
        </text>
      </svg>
    </TerminalBadgeContainer>
  );
};

export default TerminalBadge;
