import { type Dayjs } from 'dayjs';
import PalletModal from '../../../../pallet-ui/modal/pallet-modal';
import useMe from '../../../../common/react-hooks/use-me';
import { Button } from '@mui/material';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import GeneratedLineHaulManifestPdfV2 from '../../../generated-documents/components/generated-line-haul-manifest-pdf-v2';
import type { LineHaulManifest } from './utils';
import { ErrorsAlert } from '../../../../common/components/errors-alert';
import { useErrors } from '../../../../common/react-hooks/use-errors';
import pluralize from 'pluralize';

type PrintLineHaulManifestsModalProps = {
  readonly open: boolean;
  readonly setOpen: (open: boolean) => void;
  readonly manifests: LineHaulManifest[];
  readonly departDate: Dayjs;
};
const PrintLineHaulManifestsModal = ({
  open,
  setOpen,
  manifests,
  departDate,
}: PrintLineHaulManifestsModalProps) => {
  const { errors, clearErrors, onError } = useErrors();
  const { companyName } = useMe({ onError });

  const fileName = `${pluralize('manifest', manifests.length)}-${departDate.format('MM-DD-YYYY')}.pdf`;

  const document = (
    <GeneratedLineHaulManifestPdfV2
      manifests={manifests}
      companyName={companyName ?? ''}
      documentTitle={fileName}
    />
  );

  return (
    <PalletModal
      open={open}
      pinnedElements={{
        topRight: (
          <PDFDownloadLink document={document} fileName={fileName}>
            {({ loading }) => (
              <Button variant="contained" disabled={loading}>
                {loading ? 'Loading...' : 'Download'}
              </Button>
            )}
          </PDFDownloadLink>
        ),
      }}
      onClose={() => {
        setOpen(false);
      }}
    >
      <ErrorsAlert errors={errors} onClear={clearErrors} />
      <PDFViewer style={{ width: '50vw', height: '75vh' }}>
        {document}
      </PDFViewer>
    </PalletModal>
  );
};

export default PrintLineHaulManifestsModal;
