import {
  AppBar,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMemo, type CSSProperties } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';
import { FeatureFlag } from '../../common/feature-flags';
import { SIDEBAR_OPEN_KEY } from '../../common/local-storage/keys';
import useContacts from '../../common/react-hooks/use-contacts';
import useFeatureFlag from '../../common/react-hooks/use-feature-flag';
import useTerminals from '../../common/react-hooks/use-terminals';
import { chooseForegroundColor } from '../../common/utils/colors';
import { useNewTableFunctionsFeatureFlag } from '../../domains/ag-grid/use-new-table-functions-feature-flag';
import { isDevelopment } from '../../environment-variables';
import { FilterViewPage } from '../../generated/graphql';
import LeftPanelOpen from '../../icons/left-panel-open.svg?react';
import theme from '../../theme';
import NavbarTerminalSelector from './components/navbar-terminal-selector';
import { DEV_APP_BAR_COLOR } from './constants';
import { DashboardPage, type DashboardDrawerItem } from './types';
import { usePageTitleFromHandle } from './use-page-title-from-handle';
import { getAppBarTitle } from './utils';
const APP_BAR_HEIGHT_NEW_PX = 45;

export const getAppBarColor = () => {
  return isDevelopment() ? DEV_APP_BAR_COLOR : '#ffffff';
};

const getSidebarIconColor = () => {
  return isDevelopment() ? 'white' : 'black';
};

const DASHBOARD_STYLES: Record<string, CSSProperties> = {
  appBar: {
    position: 'sticky',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'left',
  },
  appBarTypography: {
    fontWeight: 400,
    fontSize: '22px',
    lineHeight: '29px',
    marginLeft: 0,
    color: '#FFFFFF',
  },
};

type AppBarProps = {
  readonly page: DashboardPage;
  readonly selectedDrawerItem?: DashboardDrawerItem<DashboardPage>;
};

export const AppBarHeader = ({ page, selectedDrawerItem }: AppBarProps) => {
  const { pathname } = useLocation();
  const { organizationUuid } = useParams();
  const { contacts } = useContacts();
  const currentOrganizationName = useMemo(() => {
    return contacts.find((contact) => contact.uuid === organizationUuid)
      ?.displayName;
  }, [organizationUuid, contacts]);

  const pageTitleFromHandle = usePageTitleFromHandle();

  const appbarColor = getAppBarColor();
  const { ffEnableNewTableFunctions } = useNewTableFunctionsFeatureFlag(
    page === DashboardPage.ORDERS ? FilterViewPage.Orders : undefined,
  );
  const ffUseLineHaulNetworks = useFeatureFlag(
    FeatureFlag.FF_LINE_HAUL_NETWORKS,
  );
  const [sidebarOpen, setSidebarOpen] = useLocalStorageState(SIDEBAR_OPEN_KEY, {
    defaultValue: true,
  });
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });

  const onNewLineHaulPage =
    pathname === '/dispatch/line-haul' && ffUseLineHaulNetworks;

  // On dispatch pages except new line haul networks page
  const onEnabledDispatchPage =
    page === DashboardPage.TRANSIT && !onNewLineHaulPage;

  const onInboundMessagesEmailPage =
    page === DashboardPage.ORDERS &&
    pathname === '/orders/inbound-messages-email';

  const onNewOrdersPageWithNewTableFunctions =
    ffEnableNewTableFunctions &&
    page === DashboardPage.ORDERS &&
    pathname === '/orders/all';

  const shouldShowTerminalSelector =
    terminalsEnabled &&
    (onEnabledDispatchPage ||
      onInboundMessagesEmailPage ||
      onNewOrdersPageWithNewTableFunctions);

  return (
    <AppBar
      component="nav"
      position="fixed"
      elevation={0}
      sx={{
        ...DASHBOARD_STYLES.appBar,
        backgroundColor: appbarColor,
        borderBottom: `1px solid ${theme.palette.borderColor.main}`,
        height: APP_BAR_HEIGHT_NEW_PX,
      }}
    >
      <Toolbar sx={{ width: '100%' }}>
        {!sidebarOpen && (
          <Tooltip title="Open sidebar">
            <IconButton
              size="small"
              sx={{
                marginRight: 1,
              }}
              onClick={() => {
                setSidebarOpen(true);
              }}
            >
              <LeftPanelOpen fill={getSidebarIconColor()} />
            </IconButton>
          </Tooltip>
        )}
        <Typography
          component="div"
          variant="h6"
          sx={{
            color: chooseForegroundColor(appbarColor),
          }}
        >
          {getAppBarTitle({
            pathname,
            selectedDrawerItem,
            currentOrganizationName,
            pageTitleFromHandle,
          })}
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          marginLeft="auto"
          alignItems="center"
        >
          {shouldShowTerminalSelector && (
            <NavbarTerminalSelector
              color={chooseForegroundColor(appbarColor)}
            />
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
