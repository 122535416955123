import EditIcon from '@mui/icons-material/Edit';
import LoginIcon from '@mui/icons-material/Login';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import { Fade, IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import Popper from '@mui/material/Popper';
import { isNil } from 'lodash';
import React, {
  type Dispatch,
  type RefObject,
  type SetStateAction,
} from 'react';
import { shallow } from 'zustand/shallow';
import {
  RouteDocument,
  RouteStatus,
  useSendRouteToAgentMutation,
  type RouteFragment,
} from '../../../../generated/graphql';
import useDispatchStore from '../../dispatch-store';
import useRouteActions from '../../hooks/use-route-actions';
import SendJobButton from '../../../agents/send-job-button';
import { type TableStopOnRoute } from '../route-card-stops-list-columns';
import RouteContextMenu, { type OpenModalsActions } from './route-context-menu';
import useGlobalStore from '../../../../layouts/dashboard/global-store';
import useFetchRoutes from '../../hooks/use-fetch-routes';

const RouteCardHoverMenu = ({
  anchorRef,
  route,
  setIsEditing,
  open,
  openModalsActions,
  stops,
}: {
  readonly anchorRef: RefObject<HTMLDivElement>;
  readonly route: RouteFragment;
  readonly setIsEditing: Dispatch<SetStateAction<boolean>>;
  readonly open: boolean;
  readonly openModalsActions: OpenModalsActions;
  readonly stops: TableStopOnRoute[];
}) => {
  const theme = useTheme();
  const setSuccessMessage = useGlobalStore(
    (state) => state.setSuccessMessage,
    shallow,
  );
  const setShowSuccessMessage = useGlobalStore(
    (state) => state.setShowSuccessMessage,
    shallow,
  );
  const setErrorMessage = useGlobalStore(
    (state) => state.setErrorMessage,
    shallow,
  );
  const setShowErrorMessage = useGlobalStore(
    (state) => state.setShowErrorMessage,
    shallow,
  );
  const [showMap, selectedViewUuid, openedRouteUuid, setOpenedRouteUuid] =
    useDispatchStore(
      (state) => [
        state.showMap,
        state.selectedViewUuid,
        state.openedRouteUuid,
        state.setOpenedRouteUuid,
      ],
      shallow,
    );
  const { showRouteOnMap } = useRouteActions();
  const { fetchRoute } = useFetchRoutes();
  const [sendRouteToAgent, { loading }] = useSendRouteToAgentMutation({
    onCompleted: (data) => {
      if (
        data.sendRouteToAgent.__typename === 'SendRouteToAgentSuccessOutput'
      ) {
        // manually refetching the route with fetchRoute to udpate the dispatch store instead of using refetchQueries
        void fetchRoute(data.sendRouteToAgent.route.uuid);
        setSuccessMessage('Route sent to agent');
        setShowSuccessMessage(true);
      } else if (data.sendRouteToAgent.__typename === 'MutationErrorOutput') {
        setErrorMessage(
          `Error sending route to agent: ${data.sendRouteToAgent.message}`,
        );
        setShowErrorMessage(true);
      }
    },
    onError: (error) => {
      setErrorMessage(`Error sending route to agent: ${error.message}`);
      setShowErrorMessage(true);
    },
  });

  const handleSendRouteToAgent = async () => {
    const driverAgentId = route.drivers[0]?.agentId;
    if (isNil(driverAgentId)) {
      setErrorMessage('Unable to send job, no agent driver assigned to route');
      setShowErrorMessage(true);
      return;
    }
    return sendRouteToAgent({
      variables: {
        sendRouteToAgentInput: {
          routeUuid: route.uuid,
          agentId: driverAgentId,
        },
      },
    });
  };
  return (
    <Popper
      transition
      keepMounted
      style={{ zIndex: 100 }}
      open={open}
      anchorEl={anchorRef?.current}
      placement="top-end"
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={50}>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              border: `1px solid ${theme.palette.borderColor.main}`,
              borderRadius: '5px',
              backgroundColor: 'white',
              p: '3px',
              mr: -1,
              mb: -3,
            }}
          >
            {route.routeInfo.status === RouteStatus.AwaitingSendToAgent && (
              <SendJobButton
                jobCount={
                  route.routeInfo.totalStops -
                  route.routeInfo.totalStopsSentToAgent
                }
                loading={loading}
                onClick={handleSendRouteToAgent}
              />
            )}
            <Tooltip title="Edit route details">
              <IconButton
                sx={{ p: '5px' }}
                disabled={route.locked}
                onClick={(e) => {
                  setIsEditing(true);
                  e.stopPropagation();
                }}
              >
                <EditIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            </Tooltip>
            {openedRouteUuid !== route.uuid && (
              <Tooltip title="View route">
                <IconButton
                  sx={{ p: '5px' }}
                  onClick={(e) => {
                    setOpenedRouteUuid(route.uuid);
                    e.stopPropagation();
                  }}
                >
                  <LoginIcon sx={{ fontSize: '20px' }} />
                </IconButton>
              </Tooltip>
            )}
            {!showMap && isNil(selectedViewUuid) && (
              <Tooltip title="Map route">
                <IconButton
                  sx={{ p: '5px' }}
                  onClick={(e) => {
                    showRouteOnMap(route.uuid);
                    e.stopPropagation();
                  }}
                >
                  <MapOutlinedIcon sx={{ fontSize: '20px' }} />
                </IconButton>
              </Tooltip>
            )}
            <RouteContextMenu
              route={route}
              stops={stops}
              openModalsActions={openModalsActions}
            />
          </Stack>
        </Fade>
      )}
    </Popper>
  );
};

export default React.memo(RouteCardHoverMenu);
