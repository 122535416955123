import { Menu, MenuItem } from '@mui/material';
import { type Dispatch, type SetStateAction, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import { PermissionResource } from '../../../../generated/graphql';
import CreateEmptyInvoiceModal from './create-empty-invoice-modal';

type InvoicesContextMenuProps = {
  readonly anchorEl: null | HTMLElement;
  readonly setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
  readonly selectedInvoiceUuids: string[];
  readonly onClickPostWithoutSending: () => void;
  readonly setShowSendAccountingReportsSuccessMessage: Dispatch<
    SetStateAction<boolean>
  >;
  readonly setShowSendAccountingReportsErrorMessage: Dispatch<
    SetStateAction<boolean>
  >;
};
const InvoicesContextMenu = ({
  anchorEl,
  setAnchorEl,
  selectedInvoiceUuids,
  onClickPostWithoutSending,
  setShowSendAccountingReportsSuccessMessage,
  setShowSendAccountingReportsErrorMessage,
}: InvoicesContextMenuProps) => {
  const [showCreateEmptyInvoiceModal, setShowCreateEmptyInvoiceModal] =
    useState<boolean>(false);

  const { userPermissions } = useUserRoles();
  const { canRead: canReadInvoices, canWrite: canWriteInvoices } =
    getPermissionsFlags(userPermissions, PermissionResource.Invoices);

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      onClose={() => {
        setAnchorEl(null);
      }}
    >
      <CreateEmptyInvoiceModal
        isOpen={showCreateEmptyInvoiceModal}
        setIsOpen={setShowCreateEmptyInvoiceModal}
      />
      {canWriteInvoices && (
        <>
          <MenuItem
            onClick={() => {
              onClickPostWithoutSending();
              setAnchorEl(null);
            }}
          >
            Post{' '}
            {selectedInvoiceUuids.length > 0
              ? `(${selectedInvoiceUuids.length})`
              : 'all'}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setShowCreateEmptyInvoiceModal(true);
            }}
          >
            Create empty invoice
          </MenuItem>
        </>
      )}
    </Menu>
  );
};

export default InvoicesContextMenu;
