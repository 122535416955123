import { isNil } from 'lodash';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { shallow } from 'zustand/shallow';
import { EnvironmentVariables } from '../../../../../environment-variables';
import useGlobalStore from '../../../../../layouts/dashboard/global-store';
import { StopMethod } from '../../../../orders/components/order-form/forms/stop-type';
import { useCustomerPortalOrderFormContext } from '../../customer-portal-order-form/contexts/customer-portal-order-form-context';
import { type CustomerPortalOrderFormValues } from '../../customer-portal-order-form/forms/types';
import { CUSTOMER_PORTAL_QUOTE_SCHEMA } from '../customer-portal-quote-schema';
import { getCreateQuoteV1Request } from '../forms/utils';
import {
  type CustomerPortalQuoteFormValues,
  type CustomerPortalQuoteRateInfo,
} from '../types';

/**
 * Hook for saving quotes in the customer portal.
 *
 * This expects to be called with third-party user auth,
 * so it should only be used in the customer portal. This works alongside the order equivalent in the customer portal form
 *
 * This also expects to be called within the CustomerPortalOrderFormValues RHF context.
 */
const useSaveQuoteCustomerPortal = ({
  companyUuid,
  contactUuid,
}: {
  companyUuid: string;
  contactUuid: string;
}) => {
  const { getValues } = useFormContext<CustomerPortalOrderFormValues>();

  const [
    setSuccessMessage,
    setShowSuccessMessage,
    setErrorMessage,
    setShowErrorMessage,
  ] = useGlobalStore(
    (state) => [
      state.setSuccessMessage,
      state.setShowSuccessMessage,
      state.setErrorMessage,
      state.setShowErrorMessage,
    ],
    shallow,
  );

  const { getStopType } = useCustomerPortalOrderFormContext();
  const inboundStopType = getStopType(StopMethod.Inbound);
  const outboundStopType = getStopType(StopMethod.Outbound);

  const errorCallback = useCallback(
    (e: unknown) => {
      // We generally try to avoid console logs in production, but this is very
      // helpful for debugging issues where customers can't save quotes in prod.
      // eslint-disable-next-line no-console
      console.error(
        `Error saving quote: ${e instanceof Error ? e.message : JSON.stringify(e)}`,
      );
      // Log the full error to the console but only show a generic error message
      // to the user: they probably can't fix e.g. a Zod validation error.
      setErrorMessage('Error saving quote');
      setShowErrorMessage(true);
    },
    [setErrorMessage, setShowErrorMessage],
  );

  const saveQuote = useCallback(
    async ({
      quoteRateInfo,
    }: {
      quoteRateInfo: CustomerPortalQuoteRateInfo;
    }): Promise<string | null> => {
      const quoteValues = getValues();

      const quoteValuesSchema =
        CUSTOMER_PORTAL_QUOTE_SCHEMA.safeParse(quoteValues);

      if (quoteValuesSchema.success) {
        const successCallback = async (
          quoteValues: CustomerPortalQuoteFormValues,
          quoteRateInfo: CustomerPortalQuoteRateInfo,
        ) => {
          const createQuoteV1Request = getCreateQuoteV1Request({
            quoteValues,
            clientId: companyUuid,
            customerId: contactUuid,
            quoteRateInfo,
            inboundStopType,
            outboundStopType,
          });

          try {
            const res = await fetch(
              `${EnvironmentVariables.VITE_BACKEND_URL}/v1/quotes`,
              {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(createQuoteV1Request),
              },
            );

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const data = await res.json();
            if (res.status !== 201 || !isNil(data.error)) {
              throw new Error(
                (data.error as string | undefined) ?? 'Error saving quote',
              );
            }

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const quoteId = data.quoteId ?? {};

            setSuccessMessage('Quote saved');
            setShowSuccessMessage(true);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return quoteId;
          } catch (error) {
            errorCallback(error);
          }
          return null;
        };

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const quoteUuid = await successCallback(
          quoteValuesSchema.data,
          quoteRateInfo,
        );
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return quoteUuid;
      }
      return null;
    },
    [
      getValues,
      companyUuid,
      contactUuid,
      inboundStopType,
      outboundStopType,
      errorCallback,
      setSuccessMessage,
      setShowSuccessMessage,
    ],
  );

  return {
    /**
     * Saves the quote from the current form values.
     */
    saveQuote,
  };
};

export { useSaveQuoteCustomerPortal };
