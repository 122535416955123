import { Add } from '@mui/icons-material';
import {
  Box,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  type SxProps,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { type ReactNode } from 'react';
import { filterNotNil } from 'shared/array';
import SecondaryButton from '../components/SecondaryButton';
import { EditFilterGroupCondition } from './edit-filter-group-condition';
import {
  type FilterConfig,
  type FilterGroupOperator,
  type FilterTypeToOperatorMap,
  type NullableFilterCondition,
  type NullableFilterGroup,
} from './types-v2';
import { getDefaultOperatorForField, isFilterCondition } from './utils-v2';

const commonSelectStyles: SxProps = {
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
  width: '100%',
};

/** Dummy component to hide the select "dropdown" icon */
const emptyIconComponent = () => null;

type DisabledSelectProps = {
  readonly value: string;
};

const DisabledSelect = ({ value }: DisabledSelectProps) => (
  <Select
    disabled
    value={value}
    size="small"
    IconComponent={emptyIconComponent}
    sx={{
      ...commonSelectStyles,
      backgroundColor: (theme) => theme.palette.background.default,
    }}
  >
    <MenuItem value={value}>{value}</MenuItem>
  </Select>
);

type EditFilterGroupProps<
  TFilterField extends string,
  TFilterType extends string,
  TFilterTypeToOperatorMap extends FilterTypeToOperatorMap<TFilterType>,
  TFilterFieldToTypeMap extends Record<TFilterField, TFilterType>,
> = {
  readonly filterGroup: NullableFilterGroup<
    TFilterField,
    TFilterType,
    TFilterTypeToOperatorMap,
    TFilterFieldToTypeMap
  >;
  readonly onFilterGroupChange: (
    updatedGroup: NullableFilterGroup<
      TFilterField,
      TFilterType,
      TFilterTypeToOperatorMap,
      TFilterFieldToTypeMap
    >,
  ) => void;
  readonly filterConfig: FilterConfig<
    TFilterField,
    TFilterType,
    TFilterTypeToOperatorMap,
    TFilterFieldToTypeMap
  >;
};

const EditFilterGroup = <
  TFilterField extends string,
  TFilterType extends string,
  TFilterTypeToOperatorMap extends FilterTypeToOperatorMap<TFilterType>,
  TFilterFieldToTypeMap extends Record<TFilterField, TFilterType>,
>({
  filterGroup,
  onFilterGroupChange,
  filterConfig,
}: EditFilterGroupProps<
  TFilterField,
  TFilterType,
  TFilterTypeToOperatorMap,
  TFilterFieldToTypeMap
>) => {
  const { defaultEmptyFilterCondition, supportsNesting, filterFieldLabels } =
    filterConfig;

  // Eventually we'll support infinite nesting, but no need for now
  const filterConditions = filterGroup.conditions.filter((f) =>
    isFilterCondition(f),
  );

  const hiddenFieldOptions = new Set(
    supportsNesting
      ? []
      : filterNotNil(filterConditions.map((condition) => condition.field)),
  );
  const visibleFieldOptions = (
    Object.keys(filterFieldLabels) as TFilterField[]
  ).filter((field) => !hiddenFieldOptions.has(field));

  /**
   * Either the filters config supports nesting (and allows multiple filter conditions on the same field)
   * or there are some fields that haven't been filtered yet
   */
  const hasAdditionalFieldsToFilter =
    supportsNesting || !isEmpty(visibleFieldOptions);

  const onOperatorChange = (newOperator: FilterGroupOperator) => {
    if (!supportsNesting) {
      return;
    }
    onFilterGroupChange({
      ...filterGroup,
      operator: newOperator,
    });
  };

  const getEditFilterConditionHandler =
    (index: number) =>
    (
      newCondition: NullableFilterCondition<
        TFilterField,
        TFilterType,
        TFilterTypeToOperatorMap,
        TFilterFieldToTypeMap
      >,
    ) => {
      onFilterGroupChange({
        ...filterGroup,
        conditions: filterGroup.conditions.map((condition, i) =>
          i === index ? newCondition : condition,
        ),
      });
    };

  const getDeleteFilterConditionHandler = (index: number) => () => {
    onFilterGroupChange({
      ...filterGroup,
      conditions: filterGroup.conditions.filter((_, i) => i !== index),
    });
  };

  const onAddFilterCondition = () => {
    const newCondition = { ...defaultEmptyFilterCondition };
    // If there's only one field to filter, set the field automatically
    if (
      isNil(newCondition.field) &&
      visibleFieldOptions.length === 1 &&
      !isNil(visibleFieldOptions[0])
    ) {
      newCondition.field = visibleFieldOptions[0];
    }
    // If the field has only one operator, set the operator automatically
    if (!isNil(newCondition.field) && isNil(newCondition.operator)) {
      newCondition.operator = getDefaultOperatorForField(
        newCondition.field,
        filterConfig,
      );
    }
    onFilterGroupChange({
      ...filterGroup,
      conditions: [...filterGroup.conditions, newCondition],
    });
  };

  const getLeftContent = (index: number): ReactNode => {
    if (index === 0) {
      return <DisabledSelect value="Where" />;
    }
    if (index === 1 && supportsNesting) {
      return (
        <Select
          value={filterGroup.operator}
          size="small"
          sx={{ ...commonSelectStyles, backgroundColor: 'white' }}
          onChange={(e) => {
            onOperatorChange(e.target.value as FilterGroupOperator);
          }}
        >
          <MenuItem value="AND">and</MenuItem>
          <MenuItem value="OR">or</MenuItem>
        </Select>
      );
    }
    return <DisabledSelect value={filterGroup.operator.toLowerCase()} />;
  };

  if (isEmpty(filterConditions)) {
    // If there are no conditions, add one so the modal isn't empty
    onAddFilterCondition();
  }

  return (
    <Stack
      display="grid"
      rowGap={1}
      gridTemplateColumns="auto 1fr auto"
      alignItems="start"
      minWidth={0}
    >
      {filterConditions.map((condition, index) => (
        <EditFilterGroupCondition<
          TFilterField,
          TFilterType,
          TFilterTypeToOperatorMap,
          TFilterFieldToTypeMap
        >
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          condition={condition}
          leftContent={getLeftContent(index)}
          filterConfig={filterConfig}
          hiddenFieldOptions={hiddenFieldOptions}
          onEditFilterCondition={getEditFilterConditionHandler(index)}
          onDeleteFilterCondition={getDeleteFilterConditionHandler(index)}
        />
      ))}
      <Box gridColumn="1 / -1">
        <Tooltip
          title={!hasAdditionalFieldsToFilter && 'No more filters available'}
        >
          <span>
            <SecondaryButton
              variant="outlined"
              startIcon={<Add />}
              disabled={!hasAdditionalFieldsToFilter}
              onClick={onAddFilterCondition}
            >
              Add
            </SecondaryButton>
          </span>
        </Tooltip>
      </Box>
    </Stack>
  );
};

export { EditFilterGroup };
