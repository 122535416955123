import {
  CircularProgress,
  Stack,
  Step,
  Stepper,
  Typography,
} from '@mui/material';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { isNil, sum } from 'lodash';
import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FeatureFlag } from '../../../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../../../common/react-hooks/use-feature-flag';
import {
  useGetLineHaulProgressQuery,
  useLineHaulLaneQuery,
} from '../../../../../../../generated/graphql';
import TrafficJamIcon from '../../../../../../../icons/traffic_jam.svg?react';
import theme from '../../../../../../../theme';
import { useOrderViewContext } from '../../../contexts/order-view-context';
import { type OrderFormValues } from '../../../forms/types';
import LineHaulTerminalStepLabel from './line-haul-terminal-step-label';
import { StatusContent } from './line-haul-timeline-event';
import { useIsUsingLinehaul } from '../../../hooks/use-is-using-linehaul';

type TimelineConnectorProps = {
  hasArrived: boolean;
  isAgentTendered: boolean;
  showingAgentIcon: boolean;
  nextShowingAgentIcon: boolean;
};

const QontoConnector = styled(StepConnector)(() => ({
  height: 'fit-content',
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 8px)',
    right: 'calc(50% + 8px)',
    bottom: 0,
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: 0,
    borderStyle: 'dashed',
    height: 1,
  },
}));

const TimelineDot = styled('div')<{ completed: boolean }>(
  ({ theme, completed }) => ({
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: completed ? theme.palette.concreteGrey[60] : 'transparent',
    border: `1px solid ${theme.palette.concreteGrey[60]}`,
    position: 'relative',
    marginTop: 8,
    marginBottom: 4,
    flexShrink: 0,
  }),
);

const TimelineIcon = styled('div')<{ completed: boolean }>(
  ({ theme, completed }) => ({
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: completed
      ? theme.palette.airfreightBlue[60]
      : 'transparent',
    position: 'relative',
    marginTop: 4,
    flexShrink: 0,
    border: `1px solid ${theme.palette.airfreightBlue[60]}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);

const TimelineConnector = styled('div')<TimelineConnectorProps>(
  ({
    theme,
    hasArrived,
    isAgentTendered,
    showingAgentIcon,
    nextShowingAgentIcon,
  }) => ({
    height: 1,
    position: 'absolute',
    top: 11,
    left: showingAgentIcon ? 16 : 8,
    right: nextShowingAgentIcon ? 0 : 0,
    ...(hasArrived
      ? {
          backgroundColor: isAgentTendered
            ? theme.palette.airfreightBlue[60]
            : theme.palette.concreteGrey[60],
        }
      : {
          backgroundImage: `linear-gradient(to right, ${
            isAgentTendered
              ? theme.palette.airfreightBlue[60]
              : theme.palette.concreteGrey[60]
          } 50%, transparent 50%)`,
          backgroundSize: '8px 1px',
          backgroundRepeat: 'repeat-x',
        }),
  }),
);

const TimelineItem = styled('div')(() => ({
  position: 'relative',
  paddingRight: 8,
  flex: 1,
  '&:last-child': {
    paddingRight: 0,
    '& .MuiTimelineConnector-root': {
      display: 'none',
    },
  },
}));

const TimelineContainer = styled(Stack)<{ hasAgentDriver: boolean }>(
  ({ theme, hasAgentDriver }) => ({
    backgroundColor: hasAgentDriver
      ? theme.palette.airfreightBlue[10]
      : 'transparent',
    borderRadius: 6,
    padding: 12,
  }),
);

const LineHaulLaneProgressOld = () => {
  const { control } = useFormContext<OrderFormValues>();

  const lineHaulLaneUuid = useWatch({ control, name: 'lineHaulLaneUuid' });
  const orderUuid = useWatch({ control, name: 'uuid' });
  const packages = useWatch({ control, name: 'packages' });
  const expectedPieceCount = useMemo(() => {
    return sum(packages?.map((pkg) => pkg.quantity));
  }, [packages]);

  const { data: laneData, loading: laneLoading } = useLineHaulLaneQuery({
    variables: {
      uuid: lineHaulLaneUuid ?? '',
    },
    skip: isNil(lineHaulLaneUuid),
  });
  const { data: progressData, loading: progressLoading } =
    useGetLineHaulProgressQuery({
      fetchPolicy: 'cache-and-network',
      variables: {
        getLineHaulProgressInput: {
          uuid: orderUuid,
        },
      },
    });

  if (laneLoading) {
    return (
      <Stack direction="column" justifyContent="center" p="20px" gap="20px">
        <CircularProgress />
      </Stack>
    );
  }

  if (isNil(laneData)) {
    return null;
  }

  return (
    <Stack direction="column" justifyContent="center" gap={2}>
      <Typography variant="h6" fontSize="16px">
        Line haul summary
      </Typography>
      {progressLoading && <CircularProgress />}
      <Stepper
        alternativeLabel
        sx={{ width: '100%', p: '0 !important' }}
        connector={<QontoConnector />}
      >
        {progressData?.getLineHaulProgress.terminalStatuses.map((status) => {
          return (
            <Step
              key={status.terminal.uuid}
              completed={
                !isNil(status.manifestDepartingFromThisTerminal) ||
                !isNil(status.markedArrivedAt) ||
                (isNil(status.manifestDepartingFromThisTerminal) &&
                  status.arrivalScans.length >= (packages?.length ?? 0))
              }
            >
              <LineHaulTerminalStepLabel
                status={status}
                expectedPieceCount={expectedPieceCount}
              />
            </Step>
          );
        })}
      </Stepper>
    </Stack>
  );
};

const LineHaulLaneProgressNew = () => {
  const { control } = useFormContext<OrderFormValues>();
  const lineHaulTimeline = useOrderViewContext()?.order?.lineHaulTimeline;
  const packages = useWatch({ control, name: 'packages' });
  const linehaulEnabled = useIsUsingLinehaul();
  const hasAgentDriver = useMemo(
    () =>
      lineHaulTimeline?.some(
        (event) => !isNil(event.departingManifest?.driver?.agentId),
      ) ?? false,
    [lineHaulTimeline],
  );

  if (isNil(lineHaulTimeline) || !linehaulEnabled) {
    return null;
  }
  const expectedPieceCount = isNil(packages)
    ? 0
    : sum(packages.map((pkg) => pkg.quantity));

  return (
    <>
      <Typography variant="h6" fontSize="16px">
        Line haul summary
      </Typography>
      <TimelineContainer
        direction="column"
        justifyContent="center"
        gap={2}
        hasAgentDriver={hasAgentDriver}
      >
        <Stack direction="row" sx={{ overflowX: 'auto', gap: 0 }}>
          {lineHaulTimeline.map((event, index) => {
            const isLastTerminal = index === lineHaulTimeline.length - 1;
            const isAgentTendered = !isNil(
              event.departingManifest?.driver?.agentId,
            );
            // the terminal icon should be filled if the manifest has departed or if it's the last terminal and has arrived or if the expected piece count have been scanned out
            const completed =
              (!isNil(event.departingManifest) &&
                !isNil(event.departingManifest.startedAt)) ||
              (isLastTerminal && !isNil(event.markedArrivedAt)) ||
              (expectedPieceCount > 0 &&
                event.arrivalScans.length >= expectedPieceCount);

            const nextEvent = lineHaulTimeline[index + 1];
            const previousEvent = lineHaulTimeline[index - 1];
            const isPreviousAgentTendered = !isNil(
              previousEvent?.departingManifest?.driver?.agentId,
            );
            const shouldShowAgentIcon =
              isAgentTendered || (isLastTerminal && isPreviousAgentTendered);

            const nextIsAgentTendered = !isNil(
              nextEvent?.departingManifest?.driver?.agentId,
            );
            // if the manifest has arrived OR the next manifest has departed, then the connector should be solid
            const shouldShowSolidDepartingConnector =
              !isNil(nextEvent?.markedArrivedAt) ||
              !isNil(nextEvent?.departingManifest?.startedAt);

            return (
              <TimelineItem key={event.terminal.code}>
                <Stack>
                  {shouldShowAgentIcon ? (
                    <TimelineIcon completed={completed}>
                      <TrafficJamIcon
                        color={
                          completed ? 'white' : theme.palette.airfreightBlue[60]
                        }
                        width={10}
                        height={10}
                      />
                    </TimelineIcon>
                  ) : (
                    <TimelineDot completed={completed} />
                  )}
                  {index === lineHaulTimeline.length - 1 ? null : (
                    <TimelineConnector
                      className="MuiTimelineConnector-root"
                      hasArrived={shouldShowSolidDepartingConnector}
                      isAgentTendered={isAgentTendered}
                      showingAgentIcon={shouldShowAgentIcon}
                      nextShowingAgentIcon={nextIsAgentTendered}
                    />
                  )}
                </Stack>
                <Stack sx={{ minWidth: 0 }} gap={0.5}>
                  <Typography fontSize="16px" fontWeight={700} mt={1}>
                    {event.terminal.code}
                  </Typography>
                  <StatusContent
                    event={event}
                    expectedPieceCount={expectedPieceCount}
                  />
                </Stack>
              </TimelineItem>
            );
          })}
        </Stack>
      </TimelineContainer>
    </>
  );
};

const LineHaulLaneProgress = () => {
  const ffLineHaulNetworks = useFeatureFlag(FeatureFlag.FF_LINE_HAUL_NETWORKS);
  if (ffLineHaulNetworks) {
    return <LineHaulLaneProgressNew />;
  }
  return <LineHaulLaneProgressOld />;
};

export default LineHaulLaneProgress;
