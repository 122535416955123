import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Divider,
  FormControl,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { type Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import { useState } from 'react';
import { ConfirmationButton } from '../../../common/components/buttons/confirmation-button';
import {
  DriverSettlementBillByUuidV2Document,
  DriverSettlementBillsV2Document,
  useDriverSettlementBillByUuidV2Query,
  useFinalizeSettlementBillsMutation,
  useUpdateDriverSettlementBillMutation,
} from '../../../generated/graphql';
import { SettlementBillLineItemsTableMode } from '../enums';
import { SettlementBillLineItemsTable } from './settlement-bill-line-items-table';
import SettlementBillPaymentSummary from './settlement-bill-payment-summary';
import SettlementBillsDownloadModal from './settlement-bills-download-modal';
import { shallow } from 'zustand/shallow';
import useGlobalStore from '../../../layouts/dashboard/global-store';

type UpdateInput = {
  newName?: string;
  newSettlementDate?: Dayjs | null;
};

const SettlementBillUnfinalized = ({
  settlementUuid,
  onClose,
}: {
  readonly settlementUuid: string;
  readonly onClose: () => void;
}) => {
  const [
    setErrorMessage,
    setShowErrorMessage,
    setSuccessMessage,
    setShowSuccessMessage,
  ] = useGlobalStore(
    (state) => [
      state.setErrorMessage,
      state.setShowErrorMessage,
      state.setSuccessMessage,
      state.setShowSuccessMessage,
    ],
    shallow,
  );

  const [name, setName] = useState<string | undefined>();
  const [startDate, setStartDate] = useState<Dayjs | null | undefined>();
  const [endDate, setEndDate] = useState<Dayjs | null | undefined>();
  const [settlementDate, setSettlementDate] = useState<
    Dayjs | null | undefined
  >();
  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const {
    data: settlementData,
    loading: dataLoading,
    refetch,
  } = useDriverSettlementBillByUuidV2Query({
    variables: {
      uuid: settlementUuid,
    },
    onCompleted: (data) => {
      const settlementBill = data?.driverSettlementBill;
      setName(settlementBill?.name ?? undefined);
      setStartDate(
        isNil(settlementBill?.billStartDate)
          ? null
          : dayjs(settlementBill?.billStartDate as string),
      );
      setEndDate(
        isNil(settlementBill?.billEndDate)
          ? undefined
          : dayjs(settlementBill?.billEndDate as string),
      );
      setSettlementDate(
        isNil(settlementBill?.settlementDate)
          ? undefined
          : dayjs(settlementBill?.settlementDate as string),
      );
    },
  });
  const [updateDriverSettlementBill, { loading: updateLoading }] =
    useUpdateDriverSettlementBillMutation({
      refetchQueries: [
        DriverSettlementBillsV2Document,
        DriverSettlementBillByUuidV2Document,
      ],
      onError: () => {
        setErrorMessage('Error updating settlement bill');
        setShowErrorMessage(true);
      },
    });

  const [finalizeBills, { loading: finalizeLoading }] =
    useFinalizeSettlementBillsMutation({
      refetchQueries: [
        DriverSettlementBillsV2Document,
        DriverSettlementBillByUuidV2Document,
      ],
      onCompleted: async (res) => {
        if (res.finalizeSettlementBills.__typename === 'MutationErrorOutput') {
          setErrorMessage('Error finalizing settlement bill');
          setShowErrorMessage(true);
        } else {
          const billResult = res.finalizeSettlementBills.results.filter(
            (result) => result.bill.uuid === settlementUuid,
          );
          if (billResult.length === 0 || !isNil(billResult[0]?.error)) {
            setErrorMessage(
              billResult[0]?.error ?? 'Error finalizing settlement bill',
            );
            setShowErrorMessage(true);
          } else {
            setSuccessMessage('Successfully finalized settlement bill');
            setShowSuccessMessage(true);
          }
        }
      },
    });

  const isFinalized = !isNil(
    settlementData?.driverSettlementBill.finalizedDate,
  );
  const loading = dataLoading || updateLoading || finalizeLoading;
  const editingDisabled = isFinalized || loading;

  const update = async ({ newName, newSettlementDate }: UpdateInput) => {
    await updateDriverSettlementBill({
      variables: {
        updateDriverSettlementBillInput: {
          driverSettlementBillUpdateInput: {
            uuid: settlementUuid,
            name: newName,
            settlementDate: isNil(newSettlementDate)
              ? undefined
              : newSettlementDate.toDate(),
          },
        },
      },
    });
  };

  return (
    <Stack height="100%" direction="column" gap={1} paddingTop={1}>
      <SettlementBillsDownloadModal
        isOpen={openDownloadModal}
        settlementBillIds={[settlementUuid]}
        onClose={() => {
          setOpenDownloadModal(false);
        }}
      />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        padding={1}
        gap={1}
      >
        <Stack direction="row" gap={1} alignItems="flex-start">
          <Stack direction="column" gap={1}>
            <Stack direction="row" gap={1} alignItems="center">
              <FormControl>
                <TextField
                  label="Name"
                  value={name ?? ''}
                  size="small"
                  style={{ width: 250 }}
                  disabled={editingDisabled}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  onBlur={async (e) => {
                    await update({ newName: e.target.value });
                  }}
                />
              </FormControl>
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disabled={editingDisabled}
                    label="Settlement date"
                    value={settlementDate}
                    renderInput={(params) => (
                      <TextField
                        aria-label="date-picker"
                        sx={{ width: 130 }}
                        size="small"
                        {...params}
                      />
                    )}
                    onChange={async (newValue) => {
                      setSettlementDate(newValue);
                      await update({ newSettlementDate: newValue });
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl>
                <TextField
                  label="Pay period"
                  value={`${startDate?.format('MM/DD/YY') ?? 'N/A'} - ${
                    endDate?.format('MM/DD/YY') ?? 'N/A'
                  }`}
                  size="small"
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    width: 180,
                  }}
                  disabled={editingDisabled}
                />
              </FormControl>
            </Stack>
            <SettlementBillPaymentSummary
              isFinalized={isFinalized}
              settlementBillUuid={
                settlementData?.driverSettlementBill.uuid ?? ''
              }
              settlementAdjustments={
                settlementData?.driverSettlementBill.settlementAdjustments ?? []
              }
              paymentInformation={
                settlementData?.driverSettlementBill.paymentInformation
              }
            />
          </Stack>
          <Stack direction="row" gap={1} alignItems="center">
            {!dataLoading && !isFinalized && (
              <ConfirmationButton
                key="finalize"
                variant="contained"
                confirmationTitle="Are you sure you want to finalize this settlement bill?"
                confirmationDescription="This action cannot be undone. Once finalized, the settlement bill will be locked and no further changes can be made."
                cancellationText="No, cancel"
                confirmationText="Yes, finalize"
                disabled={loading}
                onConfirm={async () => {
                  await finalizeBills({
                    variables: {
                      finalizeSettlementBillsInput: {
                        billUuids: [settlementUuid],
                      },
                    },
                  });
                }}
              >
                Finalize
              </ConfirmationButton>
            )}
            <IconButton
              disabled={loading}
              size="small"
              onClick={() => {
                setOpenDownloadModal(true);
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Stack direction="row" gap={1} alignItems="center">
          <IconButton disabled={loading} size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Divider />
      <SettlementBillLineItemsTable
        mode={SettlementBillLineItemsTableMode.SETTLEMENT_BILL}
        settlementBillLineItems={
          settlementData?.driverSettlementBill.settlementBillLineItems ?? []
        }
        refresh={async () => {
          await refetch();
        }}
      />
    </Stack>
  );
};

export default SettlementBillUnfinalized;
