import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { isNilOrEmptyString } from 'shared/string';
import { useGenerateOrderPreSignedGetAndPutUrlsMutation } from '../../../../generated/graphql';
import { type HandleNewDocumentParams } from '../../../end-of-day/components/multiple-document-upload-modal';
import DocumentsList from '../../../orders/components/order-form/components/overview/documents/documents-list';
import { type CustomerPortalOrderFormValues } from './forms/types';

type CustomerPortalDocumentsFieldProps = {
  readonly disabled?: boolean;
};

/**
 * Doesn't support edit mode (e.g. deleting documents that exist in the DB)
 */
const CustomerPortalDocumentsField = ({
  disabled = false,
}: CustomerPortalDocumentsFieldProps) => {
  const { control } = useFormContext<CustomerPortalOrderFormValues>();

  const {
    fields: documents,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'documents',
  });

  const [generateOrderPreSignedGetAndPutUrls] =
    useGenerateOrderPreSignedGetAndPutUrlsMutation();

  const handleUploadDocument = async ({
    fileName,
    fileType,
    documentType,
    s3Url,
  }: HandleNewDocumentParams) => {
    if (isNilOrEmptyString(s3Url)) return;
    append({ s3Url, type: documentType, fileName, fileType });
  };

  const handleDocumentDelete = (id: string) => {
    const idx = documents.findIndex((doc) => doc.id === id);
    if (idx !== -1) {
      remove(idx);
    }
  };

  const getPreSignedPutUrl = async (_fileName: string, fileType: string) => {
    const res = await generateOrderPreSignedGetAndPutUrls({
      variables: {
        generateOrderPreSignedGetAndPutUrlsInput: {
          fileType,
        },
      },
    });
    return res.data?.generateOrderPreSignedGetAndPutUrls;
  };

  const mappedDocs = useMemo(
    () =>
      documents.map((doc) => ({
        // Auto-generated UUID from useFieldArray, so we have a key
        uuid: doc.id,
        preSignedGetUrl: doc.s3Url,
        docType: doc.type,
        fileType: doc.fileType,
        fileName: doc.fileName,
        driverFormTemplateUuid: undefined,
        notes: undefined,
      })),
    [documents],
  );

  return (
    <Box>
      <Typography variant="h6" fontSize="16px">
        Files
      </Typography>
      <DocumentsList
        docs={mappedDocs}
        uploadDisabled={disabled}
        canModifyDocuments={!disabled}
        {...(!disabled && {
          getAwsUrl: getPreSignedPutUrl,
          onUploadDocument: handleUploadDocument,
          onDeleteDocument: handleDocumentDelete,
        })}
      />
    </Box>
  );
};

export default React.memo(CustomerPortalDocumentsField);
