import { TextField } from '@mui/material';
import { isNil } from 'lodash';
import { type FunctionComponent, useMemo } from 'react';
import { useActiveAgentsQuery } from '../../generated/graphql';
import AutocompleteFuzzy from '../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { isNilOrEmptyString } from 'shared/string';

export type AgentOption = {
  uuid: string;
  name: string;
};

const isOptionEqualToValue = (
  option: AgentOption | '',
  value: AgentOption | '',
) =>
  typeof option !== 'string' &&
  typeof value !== 'string' &&
  option.uuid === value.uuid;

const getOptionLabel = (option: AgentOption | '') =>
  typeof option === 'string' ? '' : option.name;

type AgentSelectProps = {
  readonly selectedOption: AgentOption | null;
  readonly onChange: (newOption: AgentOption) => void;
  readonly required?: boolean;
  readonly disabled?: boolean;
  // Used for handling errors from loading service regions.
  readonly onError: (error: Error) => void;
  readonly size?: 'small' | 'medium';
};

// Displays an autocomplete element with active service regions.
export const AgentSelect: FunctionComponent<AgentSelectProps> = ({
  selectedOption,
  onChange,
  required,
  disabled,
  onError,
  size,
}) => {
  const { data, loading } = useActiveAgentsQuery({
    onError,
  });
  const options = useMemo<AgentOption[]>(() => {
    if (isNil(data)) {
      return [];
    }
    const { activeAgents } = data;
    if (
      isNil(selectedOption) ||
      activeAgents.some((agent) => agent.uuid === selectedOption.uuid)
    ) {
      return activeAgents;
    }
    // The selected service region was previously saved but now it is archived.
    return [...activeAgents, selectedOption];
  }, [data, selectedOption]);

  return (
    <AutocompleteFuzzy<AgentOption | '', false, true, false>
      disableClearable
      disabled={disabled}
      loading={loading}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      matchSortOptions={{ keys: ['name'] }}
      renderInput={(params) => (
        <TextField {...params} label="Agent" required={required} size={size} />
      )}
      options={options}
      value={selectedOption ?? ''}
      getOptionKey={(option) =>
        typeof option === 'string' ? option : option.uuid
      }
      onChange={(_e, newValue) => {
        if (!isNilOrEmptyString(newValue)) {
          onChange(newValue);
        }
      }}
    />
  );
};
