import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { filterNotNil } from 'shared/array';
import {
  type DocumentFragment,
  type DocumentType,
  DocumentsByShipmentDocument,
  useCreateShipmentDocumentMutation,
  useDeleteDocumentMutation,
  useDocumentsByShipmentLazyQuery,
  useGenerateShipmentPreSignedPutUrlMutation,
} from '../../../../../../generated/graphql';
import { useAppDispatch } from '../../../../../../redux/hooks';
import DocumentsCards from '../../../../../end-of-day/components/documents-cards';
import { updateStandardShipmentValues } from '../../../../../shipments/redux/standard-shipments-values-slice';
import { PageMode } from '../../../../hooks/use-page-mode';
import {
  addDocument,
  updateDocument,
} from '../../../../redux/documents-values-slice';

type StandardShipmentDocumentsContainerProps = {
  readonly pageMode: PageMode;
  readonly shipmentUuids: string[];
  readonly documentUuids: string[];
  readonly isCustomerPortal?: boolean;
};

const StandardShipmentDocumentsContainer = ({
  pageMode,
  shipmentUuids,
  documentUuids,
  isCustomerPortal,
}: StandardShipmentDocumentsContainerProps) => {
  const [documents, setDocuments] = useState<DocumentFragment[]>([]);
  const [getDocumentsByShipment] = useDocumentsByShipmentLazyQuery();
  const [addShipmentDocument] = useCreateShipmentDocumentMutation({
    refetchQueries: [DocumentsByShipmentDocument],
  });
  const [deleteShipmentDocument] = useDeleteDocumentMutation();
  const [generateShipmentPreSignedPutUrl] =
    useGenerateShipmentPreSignedPutUrlMutation();
  const dispatch = useAppDispatch();

  const fetchShipmentDocuments = async () => {
    const documentsRes = await Promise.all(
      shipmentUuids.map(async (uuid) => {
        return getDocumentsByShipment({
          variables: { uuid },
        });
      }),
    );

    const docs: Array<DocumentFragment | undefined> = documentsRes.flatMap(
      (d) => {
        return d.data?.shipmentV2?.documents;
      },
    );

    setDocuments(filterNotNil(docs.filter((d) => d?.isDeleted === false)));
  };

  useEffect(() => {
    fetchShipmentDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipmentUuids]);

  const updateShipmentWithDocument = async (
    file: File,
    fileName: string,
    documentType: DocumentType,
    name: string | null,
    fileType: string,
  ) => {
    const shipmentUuid = shipmentUuids?.at(0);
    if (!isNil(shipmentUuid)) {
      const res = await addShipmentDocument({
        variables: {
          createShipmentDocumentInput: {
            documentType,
            fileName,
            fileType,
            name,
            shipmentUuid,
            isNewlyCreatedShipment: pageMode === PageMode.CREATE,
          },
        },
      });
      const newDocument = res.data?.createShipmentDocument;
      if (!isNil(newDocument)) {
        setDocuments([...documents, newDocument]);
      }

      const document = res?.data?.createShipmentDocument;
      const uuid = document?.uuid;
      if (!isNil(document) && !isNil(uuid) && !isEmpty(uuid)) {
        dispatch(
          addDocument({
            uuid,
            fileName: document.fileName,
            bucket: document.bucket,
            key: document.key,
            fileType: document.fileType,
            region: document.region,
            name,
            type: document.type,
            driverFormTemplate: document.driverFormTemplate,
          }),
        );
        dispatch(
          updateStandardShipmentValues({
            id: shipmentUuid,
            changes: { documentUuids: [...documentUuids, uuid] },
          }),
        );
      }
    }
  };

  const handleDeleteDocument = async (uuid: string) => {
    const res = await deleteShipmentDocument({
      variables: {
        uuid,
      },
    });
    setDocuments((prevState) => {
      return [...prevState].filter(
        (doc) => doc.uuid !== res.data?.deleteDocument?.uuid,
      );
    });
    dispatch(
      updateDocument({
        id: uuid,
        changes: {
          isDeleted: true,
        },
      }),
    );
  };

  const getAwsUrl = async (fileName: string, fileType: string) => {
    const res = await generateShipmentPreSignedPutUrl({
      variables: {
        generateShipmentPreSignedPutUrlInput: {
          fileName,
          fileType,
          shipmentUuid: shipmentUuids?.at(0) ?? '',
        },
      },
    });
    const putUrl = res.data?.generateShipmentPreSignedPutUrl;
    return isNil(putUrl) ? undefined : { putUrl };
  };

  return (
    <Box
      sx={{
        width: '100%',
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        mb: 2,
        mx: 2,
        p: 1,
        gap: 4,
      }}
    >
      <Box sx={{ padding: 1, width: '100%' }}>
        <Accordion
          TransitionProps={{ unmountOnExit: true }}
          sx={{
            width: '100%',
            boxShadow: 'none',
            backgroundColor: '#f7f7f7',
          }}
          defaultExpanded
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              border: 'none',
              width: isCustomerPortal === true ? '100%' : '25%',
              justifyContent: 'center',
              minHeight: '0px',
            }}
          >
            <Typography variant="h5">Uploaded documents</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{ backgroundColor: '#f7f7f7', border: 'none', margin: 0 }}
          >
            <DocumentsCards
              onUploadDocument={updateShipmentWithDocument}
              onDeleteDocument={handleDeleteDocument}
              getAwsUrl={getAwsUrl}
              docs={
                documents.map((document) => ({
                  preSignedGetUrl: document.preSignedGetUrl,
                  fileType: document.fileType,
                  fileName: document.fileName,
                  uuid: document.uuid,
                  docType: document.type,
                  driverFormTemplateUuid: document.driverFormTemplate?.uuid,
                  notes: document.notes,
                })) ?? []
              }
              cardHeight="250px"
              colSize={3}
              showAllDocumentsInOneSection
              fetchShipmentDocuments={fetchShipmentDocuments}
              showDownloadAll
              // cannot modify in customer portal
              canModifyDocuments={false}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default StandardShipmentDocumentsContainer;
