import { Box, Typography, Fade, Button, Divider, Stack } from '@mui/material';
import { useState } from 'react';
import { isNil } from 'lodash';

export type DownloadableReport = {
  label: string;
  onClick: () => void;
  description?: string;
  hidden?: boolean;
  disabled?: boolean;
};

const ReportRow = ({ report }: { readonly report: DownloadableReport }) => {
  const [isHovering, setIsHovering] = useState(false);

  if (report.hidden === true) return null;
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          cursor: report.disabled === true ? 'default' : 'pointer',
          pt: 0.25,
          pb: 0.25,
        }}
        data-test-id={`${report.label}-report-row`}
        onMouseOver={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
        onClick={report.disabled === true ? undefined : report.onClick}
      >
        <Stack direction="column" alignItems="flex-start">
          <Typography
            color={report.disabled === true ? 'text.disabled' : 'text.primary'}
          >
            {report.label}
          </Typography>
          {!isNil(report.description) && (
            <Typography variant="caption" color="text.secondary">
              {report.description}
            </Typography>
          )}
        </Stack>
        {report.disabled !== true && (
          <Fade in={isHovering}>
            <Button variant="contained" size="small" onClick={report.onClick}>
              Download
            </Button>
          </Fade>
        )}
      </Box>
      <Divider />
    </>
  );
};

export default ReportRow;
