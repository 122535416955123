import { datadogRum } from '@datadog/browser-rum';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import useHotkeys from '@reecelucas/react-use-hotkeys';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React, { type ReactNode, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import { exhaustive } from 'shared/switch';
import useLocalStorageState from 'use-local-storage-state';
import { shallow } from 'zustand/shallow';
import ClearTextFieldButton from '../../../../common/components/clear-text-field-button';
import CustomerFilterButton from '../../../../common/components/customer-filter-button';
import TerminalFilterButton from '../../../../common/components/terminal-filter-button';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../common/react-hooks/use-me';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  type PageInfo,
  PermissionResource,
  type UnifiedSearchInput,
  useUnifiedSearchInvoicesLazyQuery,
  useUnifiedSearchOrdersLazyQuery,
  useUnifiedSearchQuotesLazyQuery,
  useUnifiedSearchUnacceptedEdiAndApiOrdersLazyQuery,
  useUnifiedSearchUnacceptedEmailOrdersLazyQuery,
} from '../../../../generated/graphql';
import useGlobalStore from '../../../../layouts/dashboard/global-store';
import SearchResultsTable from './search-result-tables/search-results-table';
import useUnifiedSearchStore from './unified-search-store';
import { getRouteString } from './utils';

const NUM_RESULTS_TO_DISPLAY = 10;

export enum UnifiedSearchTab {
  ORDERS = 'ORDERS',
  EDI = 'EDI',
  EMAILS = 'EMAILS',
  QUOTES = 'QUOTES',
  INVOICES = 'INVOICES',
}

const UnifiedSearch = ({
  setOpen,
}: {
  readonly setOpen: (open: boolean) => void;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const abortController = useRef<AbortController | null>(null);
  const searchInputRef = useRef<HTMLInputElement>();
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const [focusedRowIndex, setFocusedRowIndex] = useState<number>(-1);
  const { userPermissions } = useUserRoles();
  const [setErrorMessage, setShowErrorMessage] = useGlobalStore(
    (state) => [state.setErrorMessage, state.setShowErrorMessage],
    shallow,
  );

  const { user, companyData, companyConfiguration } = useMe();
  const { canRead: canReadOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Orders,
  );
  const { canRead: canReadEmailOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.EmailOrders,
  );
  const { canRead: canReadEdiApiOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.EdiApiOrders,
  );
  const { canRead: canReadQuotes } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Quote,
  );
  const { canRead: canReadInvoices } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Invoices,
  );

  const ffDisabledUnifiedSearchCounts = useFeatureFlag(
    FeatureFlag.FF_DISABLED_UNIFIED_SEARCH_COUNTS,
  );

  const tabLabel = ({
    tabName,
    queryResult,
    loading,
  }: {
    tabName: string;
    queryResult: {
      totalCount?: number | null | undefined;
      edges: Array<{ node: any }>;
      pageInfo: PageInfo;
    } | null;
    loading: boolean;
  }) => {
    if (isNil(queryResult) || loading) {
      return tabName;
    }

    const { totalCount, edges, pageInfo } = queryResult;
    const edgesLength = edges.length;
    let countToDisplay = null;
    if (ffDisabledUnifiedSearchCounts) {
      countToDisplay =
        edgesLength < NUM_RESULTS_TO_DISPLAY || !pageInfo.hasNextPage
          ? `${edgesLength}`
          : `${edgesLength}+`;
    } else {
      countToDisplay = totalCount ?? '-';
    }
    return `${tabName}${isNil(countToDisplay) ? '' : ` (${countToDisplay})`}`;
  };

  useHotkeys(['Control+k', 'Meta+k'], async (e) => {
    e.preventDefault();
    searchInputRef.current?.focus();
  });

  useEffect(() => {
    setTimeout(() => {
      searchInputRef.current?.focus();
    }, 10);
  }, []);

  const [
    customerOption,
    setCustomerOption,
    originTerminalOption,
    setOriginTerminalOption,
    destinationTerminalOption,
    setDestinationTerminalOption,
    searchText,
    setSearchText,
    orders,
    unacceptedEmailOrders,
    unacceptedEdiAndApiOrders,
    quotes,
    invoices,
    setOrdersState,
    setUnacceptedEmailOrdersState,
    setUnacceptedEdiAndApiOrdersState,
    setQuotesState,
    setInvoicesState,
    clearData,
  ] = useUnifiedSearchStore(
    (state) => [
      state.customerOption,
      state.setCustomerOption,
      state.originTerminalOption,
      state.setOriginTerminalOption,
      state.destinationTerminalOption,
      state.setDestinationTerminalOption,
      state.searchText,
      state.setSearchText,
      state.ordersState,
      state.unacceptedEmailOrdersState,
      state.unacceptedEdiAndApiOrdersState,
      state.quotesState,
      state.invoicesState,
      state.setOrdersState,
      state.setUnacceptedEmailOrdersState,
      state.setUnacceptedEdiAndApiOrdersState,
      state.setQuotesState,
      state.setInvoicesState,
      state.clearData,
    ],
    shallow,
  );
  const [searchTextInput, setSearchTextInput] = useState<string>(searchText);

  const [currentTab, setCurrentTab] = useLocalStorageState<UnifiedSearchTab>(
    'unified_search_tab',
    {
      defaultValue: UnifiedSearchTab.ORDERS,
    },
  );

  const onChangeTab = (tab: UnifiedSearchTab) => {
    setCurrentTab(tab);
    datadogRum.addAction(`unified-search-tab-${tab}`, {
      userId: user?.uuid,
      userEmail: user?.email,
      companyName: companyData?.name,
      companyUuid: companyData?.uuid,
      searchText,
      counts: {
        orders: orders?.totalCount,
        emails: unacceptedEmailOrders?.totalCount,
        edi: unacceptedEdiAndApiOrders?.totalCount,
        quotes: quotes?.totalCount,
        invoices: invoices?.totalCount,
      },
    });
  };

  // const [searchUnified, { loading }] = useUnifiedSearchLazyQuery();

  const [searchOrders, { loading: loadingOrders }] =
    useUnifiedSearchOrdersLazyQuery({
      onError: () => {
        setErrorMessage('Error searching for orders');
        setShowErrorMessage(true);
      },
      onCompleted: (data) => {
        setOrdersState(data.unifiedSearchOrders);
      },
    });
  const [
    searchUnacceptedEmailOrders,
    { loading: loadingUnacceptedEmailOrders },
  ] = useUnifiedSearchUnacceptedEmailOrdersLazyQuery({
    onError: () => {
      setErrorMessage('Error searching for email orders');
      setShowErrorMessage(true);
    },
    onCompleted: (data) => {
      setUnacceptedEmailOrdersState(data.unifiedSearchUnacceptedEmailOrders);
    },
  });
  const [
    searchUnacceptedEdiAndApiOrders,
    { loading: loadingUnacceptedEdiAndApiOrders },
  ] = useUnifiedSearchUnacceptedEdiAndApiOrdersLazyQuery({
    onError: () => {
      setErrorMessage('Error searching for EDI orders');
      setShowErrorMessage(true);
    },
    onCompleted: (data) => {
      setUnacceptedEdiAndApiOrdersState(
        data.unifiedSearchUnacceptedEdiAndApiOrders,
      );
    },
  });
  const [searchQuotes, { loading: loadingQuotes }] =
    useUnifiedSearchQuotesLazyQuery({
      onError: () => {
        setErrorMessage('Error searching for quotes');
        setShowErrorMessage(true);
      },
      onCompleted: (data) => {
        setQuotesState(data.unifiedSearchQuotes);
      },
    });
  const [searchInvoices, { loading: loadingInvoices }] =
    useUnifiedSearchInvoicesLazyQuery({
      onError: () => {
        setErrorMessage('Error searching for invoices');
        setShowErrorMessage(true);
      },
      onCompleted: (data) => {
        setInvoicesState(data.unifiedSearchInvoices);
      },
    });

  const searchUnified = async (input: UnifiedSearchInput) => {
    const newAbortController = new AbortController();
    abortController.current = newAbortController;
    const queryInput = {
      variables: { unifiedSearchInput: input },
      context: {
        fetchOptions: {
          signal: newAbortController.signal,
        },
      },
    };

    await Promise.all([
      searchOrders(queryInput),
      searchUnacceptedEmailOrders(queryInput),
      searchUnacceptedEdiAndApiOrders(queryInput),
      searchQuotes(queryInput),
      searchInvoices(queryInput),
    ]);
  };

  const handleSearch = async ({
    input,
    contactUuid,
    originTerminalUuid,
    destinationTerminalUuid,
  }: {
    input: string;
    contactUuid: string | undefined;
    originTerminalUuid: string | undefined;
    destinationTerminalUuid: string | undefined;
  }) => {
    if (input.length > 0) {
      await searchUnified({
        searchText: input,
        contactUuid,
        originTerminalUuid,
        destinationTerminalUuid,
        ordersConnectionArgs: {
          first: NUM_RESULTS_TO_DISPLAY,
        },
        unacceptedEmailOrdersConnectionArgs: {
          first: NUM_RESULTS_TO_DISPLAY,
        },
        unacceptedEdiAndApiOrdersConnectionArgs: {
          first: NUM_RESULTS_TO_DISPLAY,
        },
        quotesConnectionArgs: {
          first: NUM_RESULTS_TO_DISPLAY,
        },
        invoicesConnectionArgs: {
          first: NUM_RESULTS_TO_DISPLAY,
        },
      });
    } else {
      clearData();
    }
    setFocusedRowIndex(-1);
  };

  useEffect(() => {
    handleSearch({
      input: searchText,
      contactUuid: customerOption?.value,
      originTerminalUuid: originTerminalOption?.value,
      destinationTerminalUuid: destinationTerminalOption?.value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchText,
    customerOption,
    originTerminalOption,
    destinationTerminalOption,
  ]);

  const tableData = () => {
    switch (currentTab) {
      case UnifiedSearchTab.ORDERS: {
        return {
          loading: loadingOrders,
          header: ['Order #', 'HAWB', 'Source', 'Customer', 'Next Route'],
          columnWidths: [120, 150, 150, 200, undefined],
          data: orders?.edges.map((order): [string, ReactNode[]] => {
            return [
              order.node.uuid,
              [
                order.node.name,
                order.node.standardOrderFields.shipperBillOfLadingNumber ?? '',
                sentenceCase(order.node.source),
                order.node.billingPartyContact.displayName,
                getRouteString({
                  startStop: order.node.startStop,
                  endStop: order.node.endStop,
                }),
              ],
            ];
          }),
        };
      }
      case UnifiedSearchTab.EMAILS: {
        return {
          loading: loadingUnacceptedEmailOrders,
          header: ['Order #', 'HAWB', 'Customer', 'Email'],
          columnWidths: [120, 150, 200, 300],
          data: unacceptedEmailOrders?.edges.map(
            (unacceptedEmailOrder): [string, ReactNode[]] => [
              unacceptedEmailOrder.node.order?.uuid ?? '',
              [
                unacceptedEmailOrder.node.order?.name ?? '',
                unacceptedEmailOrder.node.order?.standardOrderFields
                  .shipperBillOfLadingNumber ?? '',
                unacceptedEmailOrder.node.order?.billingPartyContact
                  .displayName ?? '',
                <Stack key={unacceptedEmailOrder.node.uuid}>
                  <Typography color="text.secondary" variant="caption">
                    {
                      unacceptedEmailOrder.node.scannedOrder
                        .ingestedOutlookEmailDetails?.fromAddress
                    }
                  </Typography>
                  <Typography variant="caption">
                    {
                      unacceptedEmailOrder.node.scannedOrder
                        ?.ingestedOutlookEmailDetails?.subject
                    }
                  </Typography>
                </Stack>,
              ],
            ],
          ),
        };
      }
      case UnifiedSearchTab.EDI: {
        return {
          loading: loadingUnacceptedEdiAndApiOrders,
          header: ['Order #', 'HAWB', 'MAWB', 'Customer'],
          columnWidths: [120, 150, 150, undefined],
          data: unacceptedEdiAndApiOrders?.edges.map(
            (unacceptedEdiAndApiOrder): [string, ReactNode[]] => [
              unacceptedEdiAndApiOrder.node.uuid,
              [
                unacceptedEdiAndApiOrder.node.name ?? '',
                unacceptedEdiAndApiOrder.node.standardOrderFields
                  .shipperBillOfLadingNumber ?? '',
                unacceptedEdiAndApiOrder.node.standardOrderFields
                  .masterAirwayBillOfLadingNumber ?? '',
                unacceptedEdiAndApiOrder.node.billingPartyContact.displayName ??
                  '',
              ],
            ],
          ),
        };
      }
      case UnifiedSearchTab.QUOTES: {
        return {
          loading: loadingQuotes,
          header: ['Quote #', 'Customer'],
          columnWidths: [200, undefined],
          data: quotes?.edges.map((quote): [string, ReactNode[]] => [
            quote.node.uuid,
            [quote.node.number, quote.node.billingPartyContact.displayName],
          ]),
        };
      }
      case UnifiedSearchTab.INVOICES: {
        return {
          loading: loadingInvoices,
          header: ['Invoice #', 'Date', 'Customer', 'Total', 'Balance'],
          columnWidths: [120, 150, 300, 100, undefined],
          data: invoices?.edges.map((invoice): [string, ReactNode[]] => [
            invoice.node.uuid,
            [
              companyConfiguration?.useJournalNumberForInvoice === true
                ? invoice.node.journalNumber
                : invoice.node.name,
              dayjs(invoice.node.date).format('MM/DD/YYYY'),
              invoice.node.billToContact.displayName,
              invoice.node.invoiceTotal,
              currency(invoice.node.balanceInDollars ?? 0).format(),
            ],
          ]),
        };
      }
      default: {
        return exhaustive(currentTab);
      }
    }
  };

  const selectRow = (uuid: string, openInNewTab?: boolean) => {
    let url = '';
    switch (currentTab) {
      case UnifiedSearchTab.ORDERS: {
        url = `/orders/all?orderUuid=${uuid}`;
        break;
      }
      case UnifiedSearchTab.EMAILS: {
        url = `/orders/inbound-messages-email/?orderUuid=${uuid}`;
        break;
      }
      case UnifiedSearchTab.EDI: {
        url = `/orders/inbound-messages-requests/?tab=0&orderUuid=${uuid}`;
        break;
      }
      case UnifiedSearchTab.QUOTES: {
        url = `/order-entry/quotes/${uuid}`;
        break;
      }
      case UnifiedSearchTab.INVOICES: {
        const selectedInvoice = invoices?.edges.find((invoice) => {
          return invoice.node.uuid === uuid;
        })?.node;
        const invoiceName =
          companyConfiguration?.useJournalNumberForInvoice === true
            ? selectedInvoice?.journalNumber
            : selectedInvoice?.name;
        url = `/accounting/invoices?tab=1&invoiceUuid=${uuid}&invoiceName=${invoiceName}`;
        break;
      }
      default: {
        exhaustive(currentTab);
      }
    }
    if (openInNewTab === true) {
      window.open(url, '_blank');
    } else {
      setOpen(false);
      navigate(url);
    }
  };

  const onArrow = (e: React.KeyboardEvent<HTMLDivElement> | KeyboardEvent) => {
    let length = 0;
    switch (currentTab) {
      case UnifiedSearchTab.ORDERS: {
        length = orders?.edges.length ?? 0;
        break;
      }
      case UnifiedSearchTab.EMAILS: {
        length = unacceptedEmailOrders?.edges.length ?? 0;
        break;
      }
      case UnifiedSearchTab.EDI: {
        length = unacceptedEdiAndApiOrders?.edges.length ?? 0;
        break;
      }
      case UnifiedSearchTab.QUOTES: {
        length = quotes?.edges.length ?? 0;
        break;
      }
      case UnifiedSearchTab.INVOICES: {
        length = invoices?.edges.length ?? 0;
        break;
      }
      default: {
        exhaustive(currentTab);
      }
    }
    const newIndex =
      e.code === 'ArrowDown'
        ? Math.max(0, (focusedRowIndex + 1) % length)
        : Math.max(0, (focusedRowIndex - 1) % length);
    setFocusedRowIndex(newIndex);
    e.preventDefault();
  };

  useHotkeys(['ArrowDown', 'ArrowUp'], onArrow);

  const onSearch = (newValue: string) => {
    const newValueSanitized = newValue.trim();
    setSearchText(newValueSanitized);
    setSearchTextInput(newValueSanitized);
  };

  return (
    <Stack
      sx={{
        pb: 1,
        height: '100%',
      }}
    >
      <Stack
        sx={{
          borderBottom: 1,
          borderColor: theme.palette.borderColor.main,
          position: 'relative',
          p: 1,
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Tooltip title="Drag">
              <IconButton sx={{ p: 0, mt: '-2px' }} id="drag-icon" size="small">
                <DragIndicatorIcon />
              </IconButton>
            </Tooltip>
            <Box sx={{ width: '20px', mr: 1 }}>
              {/* {loading ? (
                  <CircularProgress size={20} />
                ) : searchTextInput.length === 0 ? (
                  <SearchIcon sx={{ color: theme.palette.grey.A400 }} />
                ) : undefined} */}
            </Box>
          </Stack>
          <TextField
            inputRef={searchInputRef}
            variant="standard"
            size="small"
            placeholder="Search by order numbers, customer, address, email..."
            autoComplete="off"
            value={searchTextInput}
            sx={{ width: '100%' }}
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    {searchTextInput !== searchText && (
                      <Typography
                        variant="caption"
                        sx={{ border: 1, borderRadius: 1, px: 0.5 }}
                      >
                        enter
                      </Typography>
                    )}
                    {searchTextInput.length > 0 && (
                      <ClearTextFieldButton
                        searchText={searchTextInput}
                        handleClearSearchText={() => {
                          onSearch('');
                        }}
                      />
                    )}
                    <Divider flexItem orientation="vertical" />
                    {searchTextInput.length > 0 && (
                      <Tooltip
                        title={
                          <Typography
                            variant="caption"
                            sx={{ border: 1, borderRadius: 1, px: 0.5 }}
                          >
                            enter
                          </Typography>
                        }
                      >
                        <IconButton
                          color="primary"
                          onClick={() => {
                            onSearch(searchTextInput);
                          }}
                        >
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Stack>
                </InputAdornment>
              ),
            }}
            onFocus={(event) => {
              event.target.select();
            }}
            onKeyDown={(e) => {
              if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
                onArrow(e);
              } else if (e.key === 'Enter') {
                onSearch(searchTextInput);
                document
                  ?.getElementById(`result-row-${focusedRowIndex}`)
                  ?.click();
              }
            }}
            onChange={(e) => {
              const newValue = e.target.value;
              setSearchTextInput(newValue);

              if (newValue.trim().length === 0) {
                // This means the search text was cleared so we should reset the view
                onSearch('');
              }
            }}
          />
        </Stack>
      </Stack>
      <Tabs
        orientation="horizontal"
        variant="scrollable"
        value={currentTab}
        aria-label="email orders page tabs"
        sx={{ px: 1 }}
        onChange={(_, newValue) => {
          onChangeTab(newValue);
        }}
      >
        {canReadOrders && (
          <Tab
            value={UnifiedSearchTab.ORDERS}
            label={tabLabel({
              tabName: 'Orders',
              queryResult: orders,
              loading: loadingOrders,
            })}
            icon={loadingOrders ? <CircularProgress size={20} /> : undefined}
            iconPosition="end"
            sx={{
              minHeight: 0,
            }}
          />
        )}
        {canReadEdiApiOrders && (
          <Tab
            value={UnifiedSearchTab.EDI}
            label={tabLabel({
              tabName: 'EDI',
              queryResult: unacceptedEdiAndApiOrders,
              loading: loadingUnacceptedEdiAndApiOrders,
            })}
            icon={
              loadingUnacceptedEdiAndApiOrders ? (
                <CircularProgress size={20} />
              ) : undefined
            }
            iconPosition="end"
            sx={{
              minHeight: 0,
            }}
          />
        )}
        {canReadEmailOrders && (
          <Tab
            value={UnifiedSearchTab.EMAILS}
            label={tabLabel({
              tabName: 'Emails',
              queryResult: unacceptedEmailOrders,
              loading: loadingUnacceptedEmailOrders,
            })}
            icon={
              loadingUnacceptedEmailOrders ? (
                <CircularProgress size={20} />
              ) : undefined
            }
            iconPosition="end"
            sx={{
              minHeight: 0,
            }}
          />
        )}
        {canReadQuotes && (
          <Tab
            value={UnifiedSearchTab.QUOTES}
            label={tabLabel({
              tabName: 'Quotes',
              queryResult: quotes,
              loading: loadingQuotes,
            })}
            icon={loadingQuotes ? <CircularProgress size={20} /> : undefined}
            iconPosition="end"
            sx={{
              minHeight: 0,
            }}
          />
        )}
        {canReadInvoices && (
          <Tab
            value={UnifiedSearchTab.INVOICES}
            label={tabLabel({
              tabName: 'Invoices',
              queryResult: invoices,
              loading: loadingInvoices,
            })}
            icon={loadingInvoices ? <CircularProgress size={20} /> : undefined}
            iconPosition="end"
            sx={{
              minHeight: 0,
            }}
          />
        )}
      </Tabs>
      <Divider />
      <Stack direction="row" spacing={1} sx={{ p: 1, px: 1 }}>
        <CustomerFilterButton
          isSmall
          selectedOption={customerOption}
          handleChange={(option) => {
            setCustomerOption(option ?? undefined);
          }}
        />
        {terminalsEnabled && (
          <TerminalFilterButton
            displayCode
            isSmall
            selectedOption={originTerminalOption}
            handleChange={(option) => {
              setOriginTerminalOption(option ?? undefined);
            }}
            prefixText="Orig"
            includeInactiveTerminals={false}
          />
        )}
        {terminalsEnabled && (
          <TerminalFilterButton
            displayCode
            isSmall
            selectedOption={destinationTerminalOption}
            handleChange={(option) => {
              setDestinationTerminalOption(option ?? undefined);
            }}
            prefixText="Dest"
            includeInactiveTerminals={false}
          />
        )}
      </Stack>
      <SearchResultsTable
        loading={tableData().loading}
        focusedRowIndex={focusedRowIndex}
        headers={tableData().header}
        columnWidths={tableData().columnWidths}
        data={tableData().data ?? []}
        selectRow={selectRow}
      />
    </Stack>
  );
};

export default React.memo(UnifiedSearch);
