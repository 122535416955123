import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Alert,
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  styled,
  Table,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil, noop } from 'lodash';
import React, {
  type Dispatch,
  type SetStateAction,
  useRef,
  useState,
} from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { calculateWaitTimeInMinutes } from 'shared/stops';
import {
  getCompletedStopChipTestId,
  getStopMarkAsTestIds,
} from '../../../../../../../../utils';
import DateField from '../../../../../../../common/components/date-field';
import { FeatureFlag } from '../../../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../../../../common/react-hooks/use-me';
import { isNilOrEmptyString } from '../../../../../../../common/utils/utils';
import {
  StopStatus,
  StopType,
  useLegQuery,
  useSendStopToAgentMutation,
} from '../../../../../../../generated/graphql';
import AgentTenderedJobStatusBanner from '../../../../../../agents/agent-tendered-job-status-banner';
import SendJobButton from '../../../../../../agents/send-job-button';
import {
  AgentTenderedJobStatus,
  getAgentTenderedJobStatus,
} from '../../../../../../agents/utils';
import { useOrderFormEditAccess } from '../../../contexts/order-form-edit-access-context';
import { type StopMethod } from '../../../forms/stop-type';
import { type OrderFormFieldValues } from '../../../forms/types';
import { type OnSubmitParams } from '../../../types';
import { stopTypeLabel } from '../../../utils';
import DriverSection from './driver-section';
import StopCompletedRow from './stop-completed-row';
import StopMarkAsMenu from './stop-mark-as-menu';
import { formatStopAttemptTime, getTableBackgroundColor } from './utils';
import { useUpdateAndRefetchOrder } from '../../../hooks/use-update-and-refetch-order';
import { shallow } from 'zustand/shallow';
import useGlobalStore from '../../../../../../../layouts/dashboard/global-store';
import PalletRouterLink from '../../../../../../../pallet-ui/links/router-link/pallet-router-link';

const Cell = styled(TableCell)({
  border: 'none',
});

const Stop = ({
  idx,
  onSubmit,
  stopMethod,
  isEditMode,
  setCannotCompleteOrderModalOpen,
  setCannotCompleteOrderModalMessage,
}: {
  readonly idx: number;
  readonly stopMethod: StopMethod;
  readonly onSubmit: (params: OnSubmitParams) => Promise<boolean>;
  readonly isEditMode: boolean;
  readonly setCannotCompleteOrderModalOpen: Dispatch<SetStateAction<boolean>>;
  readonly setCannotCompleteOrderModalMessage: Dispatch<
    SetStateAction<string | undefined>
  >;
}) => {
  const { companyTimezone } = useMe();
  const { setValue, control } = useFormContext<OrderFormFieldValues>();
  const theme = useTheme();
  const [showMarkAsMenu, setShowMarkAsMenu] = useState(false);
  const markAsButtonRef = useRef<HTMLButtonElement>(null);
  const stopUuid: string | undefined = useWatch({
    control,
    name: `stops.${idx}.uuid`,
  });
  const legUuid: string | undefined = useWatch({
    control,
    name: `stops.${idx}.legUuid`,
  });
  const { data: legData } = useLegQuery(
    isNil(legUuid)
      ? {
          skip: true,
        }
      : { variables: { uuid: legUuid } },
  );
  const [setErrorMessage, setShowErrorMessage] = useGlobalStore(
    (state) => [state.setErrorMessage, state.setShowErrorMessage],
    shallow,
  );
  const [sendStopToAgent, { loading }] = useSendStopToAgentMutation({
    onCompleted: () => {
      setValue(`stops.${idx}.sentToAgentAt`, new Date());
    },
    onError: ({ message }) => {
      setErrorMessage(message);
      setShowErrorMessage(true);
    },
  });
  const { updateAndRefetchOrder } = useUpdateAndRefetchOrder();
  const completedAt = useWatch({
    control,
    name: `stops.${idx}.completedAt`,
  });
  const status = useWatch({
    control,
    name: `stops.${idx}.status`,
  });
  const stopType = useWatch({
    control,
    name: `stops.${idx}.stopType`,
  });
  const orderConsolidatedOnUuid = useWatch({
    control,
    name: `stops.${idx}.orderConsolidatedOnUuid`,
  });
  const orderConsolidatedOnName = useWatch({
    control,
    name: `stops.${idx}.orderConsolidatedOnName`,
  });
  const arrivedAt = useWatch({
    control,
    name: `stops.${idx}.arrivedAt`,
  });
  const equipmentNames = useWatch({
    control,
    name: `stops.${idx}.equipmentNames`,
  });
  const driverName = useWatch({ control, name: `stops.${idx}.driverName` });
  const agentId = useWatch({ control, name: `stops.${idx}.agentId` });
  const agentName = useWatch({ control, name: `stops.${idx}.agentName` });
  const sentToAgentAt = useWatch({
    control,
    name: `stops.${idx}.sentToAgentAt`,
  });
  const confirmedByAgentAt = useWatch({
    control,
    name: `stops.${idx}.confirmedByAgentAt`,
  });

  const ffShowAgentStatusBanner = useFeatureFlag(
    FeatureFlag.FF_AGENTS_CONFIGURATION_AND_TENDERING,
  );

  const agentTenderedJobStatus = getAgentTenderedJobStatus({
    agentName,
    sentToAgentAt,
    confirmedByAgentAt,
    completedAt,
    ffShowAgentStatusBanner,
  });
  const isAssignedToAgent = !isNil(agentName) && ffShowAgentStatusBanner;

  const { hours: waitTimeHours, minutes: waitTimeMinutes } =
    calculateWaitTimeInMinutes({
      arrivalDate: arrivedAt,
      completedDate: completedAt,
    });

  const { disabledIfFinalizedOrLater } = useOrderFormEditAccess();

  if (stopType === StopType.None) {
    return null;
  }

  const { stopMarkAsMenuButtonTestId } = getStopMarkAsTestIds({ stopIdx: idx });
  const completedStopChipTestId = getCompletedStopChipTestId({ stopType });

  const handleSendStopToAgent = async () => {
    if (isNil(agentId)) {
      setErrorMessage('Stop is not assigned to an agent');
      setShowErrorMessage(true);
      return;
    }
    await updateAndRefetchOrder({
      additionalUpdateFns: [
        {
          fn: sendStopToAgent,
          vars: {
            sendStopToAgentInput: {
              stopUuid,
              agentId,
            },
          },
        },
      ],
      onSubmit,
      actionString: 'sending stop to agent',
    });
  };

  return (
    <Stack direction="column" justifyContent="center">
      <StopMarkAsMenu
        buttonRef={markAsButtonRef.current}
        setShowContextMenu={setShowMarkAsMenu}
        showContextMenu={showMarkAsMenu}
        idx={idx}
        legData={legData}
        setCannotCompleteOrderModalOpen={setCannotCompleteOrderModalOpen}
        setCannotCompleteOrderModalMessage={setCannotCompleteOrderModalMessage}
        onSubmit={onSubmit}
      />
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6" fontSize="16px">
          {sentenceCase(stopMethod)}{' '}
          {isNil(stopType) ? '' : stopTypeLabel(stopType).toLowerCase()} summary
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '5px',
          }}
        >
          {!isNil(orderConsolidatedOnUuid) && (
            <Tooltip
              title={`Consolidated on order ${orderConsolidatedOnName} for billing`}
              placement="top"
            >
              <Chip
                clickable
                label="Consolidated"
                deleteIcon={<OpenInNewIcon sx={{ pr: 1 }} />}
                component="a"
                href={`/orders/?orderUuid=${orderConsolidatedOnUuid}`}
                target="_blank"
                color="info"
                onDelete={noop} // We need this so the deleteIcon is visible
              />
            </Tooltip>
          )}
          <Chip
            label={sentenceCase(status ?? '')}
            data-testid={completedStopChipTestId}
          />
          {isEditMode && (
            <IconButton
              ref={markAsButtonRef}
              size="small"
              aria-controls={showMarkAsMenu ? 'context-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={showMarkAsMenu ? 'true' : undefined}
              disabled={disabledIfFinalizedOrLater}
              data-testid={stopMarkAsMenuButtonTestId}
              onClick={() => {
                setShowMarkAsMenu(!showMarkAsMenu);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          )}
        </Box>
      </Stack>
      <Table
        sx={{
          backgroundColor: getTableBackgroundColor({
            theme,
            isAssignedToAgent,
            agentStatus: agentTenderedJobStatus,
          }),
          mt: '13px',
          borderRadius: '4px',
          border: 'none',
          overflow: 'hidden',
        }}
      >
        {isAssignedToAgent && (
          <AgentTenderedJobStatusBanner
            status={agentTenderedJobStatus}
            agentName={agentName}
          >
            {agentTenderedJobStatus ===
              AgentTenderedJobStatus.ASSIGNED_NOT_SENT && (
              <SendJobButton
                jobCount={1}
                loading={loading}
                onClick={handleSendStopToAgent}
              />
            )}
          </AgentTenderedJobStatusBanner>
        )}
        {!isNil(completedAt) && (
          <StopCompletedRow
            idx={idx}
            completedAt={completedAt}
            setValue={setValue}
            disabled={disabledIfFinalizedOrLater}
            equipmentNames={equipmentNames}
            driverName={driverName}
          />
        )}
        {(!isNil(arrivedAt) || !isNil(completedAt)) && (
          <TableRow>
            <Cell>
              <DateField
                date={arrivedAt ?? null}
                setDate={async (date) => {
                  setValue(`stops.${idx}.arrivedAt`, date);
                }}
                editable={!disabledIfFinalizedOrLater}
              />
            </Cell>
            <Cell>
              <Chip label="Arrived" />
            </Cell>
            {status === StopStatus.Completed && (
              <Cell>
                {!isNil(waitTimeHours) && !isNil(waitTimeMinutes)
                  ? `${
                      waitTimeHours > 0 ? `${waitTimeHours}h, ` : ''
                    }${waitTimeMinutes}m`
                  : '-'}{' '}
                wait
              </Cell>
            )}
          </TableRow>
        )}
        {(legData?.leg?.previousStopAttempts ?? []).map(
          (previousStopAttempt) => {
            const attemptDriver =
              previousStopAttempt?.routeSlot?.route?.drivers?.at(0);
            let attemptDriverName = '';
            if (!isNil(attemptDriver)) {
              attemptDriverName = `${attemptDriver?.firstName ?? ''} ${
                attemptDriver?.lastName ?? ''
              }`;
            }
            return (
              <TableRow key={previousStopAttempt.uuid}>
                <Cell>
                  {formatStopAttemptTime({
                    completedAt: previousStopAttempt.completedAt,
                    companyTimezone,
                  })}
                </Cell>
                <Cell>
                  <Chip label="Attempted" />
                </Cell>
                <Cell>
                  {!isNilOrEmptyString(previousStopAttempt.notes) && (
                    <>Notes: {previousStopAttempt.notes}</>
                  )}
                  {!isNilOrEmptyString(attemptDriverName) && (
                    <p
                      style={{
                        padding: 0,
                        margin: 0,
                        color: theme.palette.grey[500],
                      }}
                    >
                      Driver: {attemptDriverName}
                    </p>
                  )}
                </Cell>
              </TableRow>
            );
          },
        )}
      </Table>
      {!isAssignedToAgent && <DriverSection idx={idx} />}
    </Stack>
  );
};

export default React.memo(Stop);
