import dayjs, { type Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { persist } from 'zustand/middleware';
import {
  type DetailedLineHaulManifestFragment,
  type ShallowLineHaulSegmentFragment,
  type FindLineHaulManifestsV2FiltersInput,
} from '../../../generated/graphql';

export enum ManifestTab {
  Arrivals = 0,
  Departures = 1,
}

type LineHaulDispatchState = {
  appliedSearchText: string;
  departDate: Dayjs;
  planningDate: Dayjs;
  filters: FindLineHaulManifestsV2FiltersInput;
  manifests: DetailedLineHaulManifestFragment[] | null | undefined;
  unmanifestedSegments: ShallowLineHaulSegmentFragment[] | null | undefined;
  openedManifest: DetailedLineHaulManifestFragment | undefined;
  openedUnmanifestedSegment: ShallowLineHaulSegmentFragment | undefined;
  shouldRefreshGrid: boolean;
  selectingManifests: boolean;
  selectedManifestUuids: string[];
  selectedUnmanifestedSegmentUuids: string[];
  snackbarSuccessMessage: string | undefined;
  snackbarErrorMessage: string | undefined;
  orderUuidToAddToAgGrid: string | undefined;
  currentManifestTab: ManifestTab;
};

type LineHaulDispatchActions = {
  setAppliedSearchText: (searchText: string) => void;
  setPlanningDate: (planningDate: Dayjs) => void;
  setFilters: (filters: FindLineHaulManifestsV2FiltersInput) => void;
  setManifests: (
    manifests: DetailedLineHaulManifestFragment[] | null | undefined,
  ) => void;
  setUnmanifestedSegments: (
    unmanifestedSegments: ShallowLineHaulSegmentFragment[] | null | undefined,
  ) => void;
  setOpenedManifest: (
    manifest: DetailedLineHaulManifestFragment | undefined,
  ) => void;
  setOpenedUnmanifestedSegment: (
    unmanifestedSegment: ShallowLineHaulSegmentFragment | undefined,
  ) => void;
  setShouldRefreshGrid: (shouldRefresh: boolean) => void;
  setManifest: (manifest: DetailedLineHaulManifestFragment) => void;
  setSelectingManifests: (selecting: boolean) => void;
  selectManifestUuids: (manifestUuids: string[]) => void;
  selectManifestUuid: (manifestUuid: string) => void;
  selectUnmanifestedSegmentUuid: (unmanifestedSegmentUuid: string) => void;
  deselectManifestUuid: (manifestUuid: string) => void;
  deselectAllManifestUuids: () => void;
  deselectUnmanifestedSegmentUuid: (unmanifestedSegmentUuid: string) => void;
  deselectAllManifestAndUnmanifestedSegmentUuids: () => void;
  addManifest: (manifest: DetailedLineHaulManifestFragment) => void;
  setSnackbarSuccessMessage: (message: string | undefined) => void;
  setSnackbarErrorMessage: (message: string | undefined) => void;
  deleteManifest: (uuid: string) => void;
  setOrderUuidToAddToAgGrid: (uuid: string | undefined) => void;
  setCurrentManifestTab: (tab: ManifestTab) => void;
};

const initialDepartDate = dayjs();
const initialState: LineHaulDispatchState = {
  departDate: initialDepartDate,
  planningDate: dayjs(),
  filters: {
    and: [
      {
        fields: {
          departDate: { eq: initialDepartDate },
        },
      },
    ],
  },
  appliedSearchText: '',
  manifests: undefined,
  unmanifestedSegments: undefined,
  openedManifest: undefined,
  openedUnmanifestedSegment: undefined,
  shouldRefreshGrid: false,
  selectingManifests: false,
  selectedManifestUuids: [],
  selectedUnmanifestedSegmentUuids: [],
  snackbarSuccessMessage: undefined,
  snackbarErrorMessage: undefined,
  orderUuidToAddToAgGrid: undefined,
  currentManifestTab: ManifestTab.Arrivals,
};

const useLineHaulDispatchStore = create(
  persist(
    immer<LineHaulDispatchState & LineHaulDispatchActions>((set, get) => ({
      ...initialState,
      setAppliedSearchText: (searchText: string) => {
        set((state) => {
          state.appliedSearchText = searchText;
        });
      },
      setPlanningDate: (planningDate: Dayjs) => {
        set((state) => {
          state.planningDate = planningDate;
        });
      },
      setFilters: (filters: FindLineHaulManifestsV2FiltersInput) => {
        set((state) => {
          state.filters = filters;
          state.departDate =
            dayjs(filters.and?.[0]?.fields?.departDate?.eq as string) ??
            initialDepartDate;
        });
      },
      setManifests: (
        manifests: DetailedLineHaulManifestFragment[] | null | undefined,
      ) => {
        set((state) => {
          state.manifests = manifests;
        });
      },
      setUnmanifestedSegments: (
        unmanifestedSegments:
          | ShallowLineHaulSegmentFragment[]
          | null
          | undefined,
      ) => {
        set((state) => {
          state.unmanifestedSegments = unmanifestedSegments;
        });
      },
      setOpenedManifest: (
        manifest: DetailedLineHaulManifestFragment | undefined,
      ) => {
        set((state) => {
          state.openedManifest = manifest;
        });
      },
      setOpenedUnmanifestedSegment: (
        unmanifestedSegment: ShallowLineHaulSegmentFragment | undefined,
      ) => {
        set((state) => {
          state.openedUnmanifestedSegment = unmanifestedSegment;
        });
      },
      setShouldRefreshGrid: (shouldRefresh: boolean) => {
        set((state) => {
          state.shouldRefreshGrid = shouldRefresh;
        });
      },
      setManifest: (manifest: DetailedLineHaulManifestFragment) => {
        set((state) => {
          const { manifests } = state;
          if (!isNil(manifests)) {
            const manifestIndex = manifests.findIndex(
              (m) => m.uuid === manifest.uuid,
            );
            manifests[manifestIndex] = manifest;
          }
        });
      },
      setSelectingManifests: (selecting: boolean) => {
        set((state) => {
          state.selectingManifests = selecting;
        });
      },
      selectManifestUuids: (manifestUuids: string[]) => {
        set((state) => {
          for (const manifestUuid of manifestUuids) {
            if (!state.selectedManifestUuids.includes(manifestUuid)) {
              state.selectedManifestUuids.push(manifestUuid);
            }
          }
        });
      },
      selectManifestUuid: (manifestUuid: string) => {
        set((state) => {
          if (!state.selectedManifestUuids.includes(manifestUuid)) {
            state.selectedManifestUuids.push(manifestUuid);
          }
        });
      },
      deselectManifestUuid: (manifestUuid: string) => {
        set((state) => {
          state.selectedManifestUuids = state.selectedManifestUuids.filter(
            (uuid) => uuid !== manifestUuid,
          );
        });
      },
      deselectAllManifestUuids: () => {
        set((state) => {
          state.selectedManifestUuids = [];
        });
      },
      selectUnmanifestedSegmentUuid: (unmanifestedSegmentUuid: string) => {
        set((state) => {
          if (
            !state.selectedUnmanifestedSegmentUuids.includes(
              unmanifestedSegmentUuid,
            )
          ) {
            state.selectedUnmanifestedSegmentUuids.push(
              unmanifestedSegmentUuid,
            );
          }
        });
      },
      deselectUnmanifestedSegmentUuid: (unmanifestedSegmentUuid: string) => {
        set((state) => {
          state.selectedUnmanifestedSegmentUuids =
            state.selectedUnmanifestedSegmentUuids.filter(
              (uuid) => uuid !== unmanifestedSegmentUuid,
            );
        });
      },
      deselectAllManifestAndUnmanifestedSegmentUuids: () => {
        set((state) => {
          state.selectedManifestUuids = [];
          state.selectedUnmanifestedSegmentUuids = [];
        });
      },
      addManifest: (manifest: DetailedLineHaulManifestFragment) => {
        set((state) => {
          if (isNil(state.manifests?.find((m) => m.uuid === manifest.uuid))) {
            state.manifests?.push(manifest);
          }
        });
      },
      setSnackbarSuccessMessage: (message: string | undefined) => {
        set((state) => {
          state.snackbarSuccessMessage = message;
        });
      },
      setSnackbarErrorMessage: (message: string | undefined) => {
        set((state) => {
          state.snackbarErrorMessage = message;
        });
      },
      deleteManifest: (uuid: string) => {
        set((state) => {
          const manifestIdx =
            state.manifests?.findIndex((m) => m.uuid === uuid) ?? -1;
          if (manifestIdx >= 0) {
            state.manifests?.splice(manifestIdx, 1);
          }
          state.openedManifest = undefined;
          state.openedUnmanifestedSegment = undefined;
        });
      },
      setOrderUuidToAddToAgGrid: (uuid: string | undefined) => {
        set((state) => {
          state.orderUuidToAddToAgGrid = uuid;
        });
      },
      setCurrentManifestTab: (tab: ManifestTab) => {
        set((state) => {
          state.currentManifestTab = tab;
        });
      },
    })),
    {
      name: 'line-haul-dispatch-store',
      partialize: (state) => ({
        filters: state.filters,
        currentManifestTab: state.currentManifestTab,
      }),
    },
  ),
);

export default useLineHaulDispatchStore;
