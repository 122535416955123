import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import { isNil } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { FUEL_CHARGE_TOTAL_TEST_ID } from '../../../../../../../../../constants';
import { getOrderChargesFuelChargeTestIds } from '../../../../../../../../../utils';
import { FuelBillingMethod } from '../../../../../../../../generated/graphql';
import { useOrderFormEditAccess } from '../../../../contexts/order-form-edit-access-context';
import { type OrderFormFieldValues } from '../../../../forms/types';
import AuthoCodeComponent from '../autho-code-component';
import DescriptionComponent from '../description-component';
import FuelChargeRateInput from '../fuel-charges/fuel-charge-rate-input';
import { handleFuelBillingMethodChange } from '../../utils';

export type FuelChargeRowProps = {
  readonly inBillingReview: boolean;
};

const OrderChargesFuelChargeRow = ({ inBillingReview }: FuelChargeRowProps) => {
  const { disabledIfFinalizedOrLater, disabledIfInvoicePosted } =
    useOrderFormEditAccess();

  const { control, setValue } = useFormContext<OrderFormFieldValues>();

  const fuelChargeKey = `orderChargesShipment.freightCharge.fuelCharge`;
  const fuelCharge = useWatch({
    control,
    name: fuelChargeKey,
  });
  const billingMethod = useWatch({
    control,
    name: `${fuelChargeKey}.billingMethod`,
  });

  const totalCharge = useWatch({
    control,
    name: `${fuelChargeKey}.totalCharge`,
  });

  const surchargeRate = useWatch({
    control,
    name: `${fuelChargeKey}.surchargeRate`,
  });

  const flatRateDollars = useWatch({
    control,
    name: `${fuelChargeKey}.flatRateDollars`,
  });

  const authoCode = useWatch({
    control,
    name: `${fuelChargeKey}.authoCode`,
  });

  const onChangeBillingMethod = (event: SelectChangeEvent) => {
    const billingMethod = event.target.value as FuelBillingMethod;
    handleFuelBillingMethodChange({
      billingMethod,
      setValue,
      fieldPrefix: fuelChargeKey,
      currentSurchargeRate: surchargeRate,
      currentFlatRateDollars: flatRateDollars,
    });
  };

  if (isNil(fuelCharge)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  const { fuelChargeTypeSelectTestId, fuelChargeRateInputTestId } =
    getOrderChargesFuelChargeTestIds();

  return (
    <TableRow>
      <TableCell>
        <FormControl sx={{ mt: inBillingReview ? undefined : '16px' }}>
          <InputLabel>Fuel</InputLabel>
          <Select
            required
            data-testid={fuelChargeTypeSelectTestId}
            inputProps={{
              'aria-label': 'Fuel charge select',
            }}
            size="small"
            value={billingMethod}
            sx={{ width: '200px' }}
            label="Fuel Type"
            disabled={disabledIfFinalizedOrLater}
            onChange={onChangeBillingMethod}
          >
            {Object.values(FuelBillingMethod).map((method) => (
              <MenuItem key={method} value={method}>
                {sentenceCase(method)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <DescriptionComponent
          keyString={fuelChargeKey}
          disabled={disabledIfInvoicePosted}
        />
      </TableCell>
      <TableCell>
        <FuelChargeRateInput
          fuelChargePrefix={fuelChargeKey}
          billingMethod={billingMethod}
          disabled={disabledIfFinalizedOrLater}
          fuelChargeRateInputTestId={fuelChargeRateInputTestId}
        />
      </TableCell>
      <TableCell>1</TableCell>
      <TableCell colSpan={2}>
        <Typography
          sx={{ mt: inBillingReview ? undefined : '16px' }}
          data-testid={FUEL_CHARGE_TOTAL_TEST_ID}
        >
          {isNil(totalCharge) ? '-' : currency(totalCharge).format()}
        </Typography>
        <AuthoCodeComponent
          authoCode={authoCode ?? ''}
          keyString={fuelChargeKey}
          disabled={disabledIfInvoicePosted}
        />
      </TableCell>
    </TableRow>
  );
};

export default OrderChargesFuelChargeRow;
