import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Fade,
  IconButton,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import {
  type FieldErrors,
  useFieldArray,
  useFormContext,
  useFormState,
} from 'react-hook-form';
import DimensionsInput from '../../../../common/components/measurements/dimensions-input';
import RequiredLabel from '../../../../common/form/required-label';
import { type Option } from '../../../../common/types';
import { MeasurementUnits, PackageType } from '../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { useCustomerPortalOrderFormContext } from './contexts/customer-portal-order-form-context';
import { type CustomerPortalOrderFormValues } from './forms/types';

const PACKAGE_TYPE_OPTIONS = Object.values(PackageType).map((type) => ({
  value: type,
  label: sentenceCase(type),
}));

const isOptionEqualToValue = (option: Option, value: Option) =>
  option.value === value.value;

const getOptionLabel = (option: Option) => option.label;

type Package = CustomerPortalOrderFormValues['packages'][number];

type PackageRowProps = {
  readonly packageData: Package;
  readonly index: number;
  readonly onRemove: () => void;
  readonly errors: FieldErrors<Package> | undefined;
  readonly disabled: boolean;
  readonly measurementUnits: MeasurementUnits;
};

const PackageRow = ({
  packageData,
  index,
  onRemove,
  errors,
  disabled,
  measurementUnits,
}: PackageRowProps) => {
  const [isHovering, setIsHovering] = useState(false);
  const { setValue } = useFormContext<CustomerPortalOrderFormValues>();

  // We can't use FormNumberInput here because it doesn't play nice with
  // useFieldArray (which sometimes sets fields to undefined when read from useWatch)

  const [quantityInput, setQuantityInput] = useState<string>(
    isNil(packageData.quantity) ? '' : String(packageData.quantity),
  );
  const [weightInput, setWeightInput] = useState<string>(
    isNil(packageData.weight) ? '' : String(packageData.weight),
  );

  const setPackageValue = (
    key: string & keyof Package,
    value: Package[keyof Package],
  ) => {
    setValue(`packages.${index}.${key}`, value);
  };

  const dimensionsError: string | undefined = (
    errors?.length ??
    errors?.width ??
    errors?.height
  )?.message;

  return (
    <TableRow
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TableCell>
        <TextField
          size="small"
          disabled={disabled}
          value={quantityInput}
          error={!isNil(errors?.quantity)}
          helperText={errors?.quantity?.message}
          onChange={(e) => {
            const newValue = e.target.value;
            const parsedFloat = Number.parseFloat(newValue);
            setQuantityInput(newValue);
            if (!Number.isNaN(parsedFloat)) {
              setPackageValue('quantity', parsedFloat);
            } else if (newValue === '') {
              setPackageValue('quantity', 0);
            }
          }}
        />
      </TableCell>
      <TableCell>
        <Stack direction="row" gap={1} alignItems="center">
          <TextField
            size="small"
            disabled={disabled}
            value={weightInput}
            error={!isNil(errors?.weight)}
            helperText={errors?.weight?.message}
            onChange={(e) => {
              const newValue = e.target.value;
              const parsedFloat = Number.parseFloat(newValue);
              setWeightInput(newValue);
              if (!Number.isNaN(parsedFloat)) {
                setPackageValue('weight', parsedFloat);
              } else if (newValue === '') {
                setPackageValue('weight', 0);
              }
            }}
          />
          <Typography variant="body2" color="text.secondary">
            {measurementUnits === MeasurementUnits.Inches ? 'lbs' : 'kg'}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction="row" gap={1} alignItems="center">
          <DimensionsInput
            disabled={disabled}
            length={packageData.length}
            width={packageData.width}
            height={packageData.height}
            error={dimensionsError}
            onChangeLength={(value) => {
              setPackageValue('length', value ?? 0);
            }}
            onChangeWidth={(value) => {
              setPackageValue('width', value ?? 0);
            }}
            onChangeHeight={(value) => {
              setPackageValue('height', value ?? 0);
            }}
          />
          <Typography variant="body2" color="text.secondary">
            {measurementUnits === MeasurementUnits.Inches ? 'in' : 'cm'}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <AutocompleteFuzzy
          disableClearable
          size="small"
          value={
            isNil(packageData.packageType)
              ? undefined
              : {
                  value: packageData.packageType,
                  label: sentenceCase(String(packageData.packageType)),
                }
          }
          options={PACKAGE_TYPE_OPTIONS}
          matchSortOptions={{ keys: ['label'] }}
          isOptionEqualToValue={isOptionEqualToValue}
          getOptionLabel={getOptionLabel}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Type"
              sx={{ width: '100px' }}
              error={!isNil(errors?.packageType)}
              helperText={errors?.packageType?.message}
            />
          )}
          disabled={disabled}
          onChange={(_, { value }) => {
            if (!isNil(value)) {
              setPackageValue('packageType', value);
            }
          }}
        />
      </TableCell>
      <TableCell>
        <TextField
          size="small"
          disabled={disabled}
          value={packageData.description}
          error={!isNil(errors?.description)}
          helperText={errors?.description?.message}
          onChange={(e) => {
            setPackageValue('description', e.target.value);
          }}
        />
      </TableCell>
      <TableCell>
        {!disabled && (
          <Fade in={isHovering}>
            <IconButton disabled={disabled} onClick={onRemove}>
              <DeleteIcon />
            </IconButton>
          </Fade>
        )}
      </TableCell>
    </TableRow>
  );
};

type CustomerPortalPackagesProps = {
  readonly disabled: boolean;
};

/**
 * This doesn't support:
 * - Totals
 * - Reweigh
 * - Package specs
 * - Dim factor / dimensional weight
 * - Warehouse locations
 * - Expanded descriptions
 */
const CustomerPortalPackages = ({ disabled }: CustomerPortalPackagesProps) => {
  const { control } = useFormContext<CustomerPortalOrderFormValues>();
  const { errors } = useFormState<CustomerPortalOrderFormValues>();
  const {
    fields: packages,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'packages',
  });

  const { measurementUnits } = useCustomerPortalOrderFormContext();

  const addPackage = () => {
    append({
      description: undefined,
      quantity: 0,
      weight: 0,
      length: 0,
      width: 0,
      height: 0,
    });
  };

  return (
    <Stack bgcolor="white" p={2}>
      <Typography variant="h6" fontSize="16px">
        Packages
      </Typography>
      <TableContainer>
        <Table>
          <colgroup>
            <col style={{ width: '50px' }} /> {/* Quantity */}
            <col style={{ width: '150px' }} /> {/* Weight */}
            <col style={{ width: '200px' }} /> {/* Dimensions */}
            <col style={{ width: '100px' }} /> {/* Type */}
            <col /> {/* Description - will take remaining width */}
            <col style={{ width: '48px' }} /> {/* Actions column */}
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>
                <RequiredLabel>Quantity</RequiredLabel>
              </TableCell>
              <TableCell>
                <RequiredLabel>Weight</RequiredLabel>
              </TableCell>
              <TableCell>
                <RequiredLabel>Dimensions</RequiredLabel>
              </TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Description</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {packages.map((packageData, idx) => (
            <PackageRow
              key={packageData.id}
              packageData={packageData}
              index={idx}
              disabled={disabled}
              measurementUnits={measurementUnits}
              errors={errors.packages?.[idx]}
              onRemove={() => {
                remove(idx);
              }}
            />
          ))}
          {!disabled && (
            <TableRow>
              <TableCell colSpan={5}>
                <Button
                  sx={{ mr: 'auto' }}
                  disabled={disabled}
                  onClick={addPackage}
                >
                  + Add package
                </Button>
              </TableCell>
            </TableRow>
          )}
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default React.memo(CustomerPortalPackages);
