import { createAsyncThunk } from '@reduxjs/toolkit';
import { isNil } from 'lodash';
import apolloClient from '../../../apollo-client';
import { type ErrorResponse } from '../../../common/form/formValidators';
import {
  MeDocument,
  type MeQuery,
  type MeQueryVariables,
  QuoteDocument,
  type QuoteFragment,
  type QuoteQuery,
  type QuoteQueryVariables,
} from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';
import {
  addOnePackageValues,
  selectPackageById,
} from '../../packages/redux/package-values-slice';
import {
  getStandardShipmentErrors,
  type ShipmentErrorsResponse,
  upsertShipment,
} from '../../shipments/redux/standard-shipments-values-thunks';
import {
  selectQuoteValuesById,
  upsertOneQuoteValues,
} from './quote-values-slice';

export const initializeQuoteWithData = createAsyncThunk<
  string,
  {
    quote: QuoteFragment;
    companyData: MeQuery;
  },
  { state: RootState }
>('quotes/addQuote', async (arg, thunkAPI) => {
  const { quote, companyData } = arg;
  const shipmentUuids: string[] = await Promise.all(
    quote.shipments.map(async (shipment) => {
      await thunkAPI.dispatch(
        upsertShipment({
          companyData,
          isDuplicate: false,
          orderUuid: undefined,
          shipment,
          quoteUuid: quote.uuid,
          billingPartyUuid: quote.billingPartyContact.uuid,
        }),
      );
      return shipment.uuid;
    }),
  );
  const packageUuids = quote.packages.map((package_) => {
    thunkAPI.dispatch(addOnePackageValues(package_));
    return package_.uuid;
  });
  thunkAPI.dispatch(
    upsertOneQuoteValues({
      packageUuids,
      shipmentUuids,
      contactUuid: quote.billingPartyContact.uuid,
      ...quote,
    }),
  );
  return quote.uuid;
});

export const initializeQuote = createAsyncThunk<
  string,
  { uuid: string },
  { state: RootState }
>('quotes/addQuote', async (arg, thunkAPI) => {
  const quoteData = await apolloClient.query<QuoteQuery, QuoteQueryVariables>({
    query: QuoteDocument,
    variables: { uuid: arg.uuid },
  });
  const companyData = await apolloClient.query<MeQuery, MeQueryVariables>({
    query: MeDocument,
  });
  const quoteRes = await thunkAPI
    .dispatch(
      initializeQuoteWithData({
        companyData: companyData.data,
        quote: quoteData.data.quote,
      }),
    )
    .unwrap();
  return quoteRes;
});

type GetQuoteErrorsResponseArg = {
  quoteUuid: string;
  forceValidateAddress?: boolean;
  ffRecoveryTransferAddressOnly: boolean;
};

export type QuoteErrorsResponse = {
  isValid: boolean;
  errors: ErrorResponse[];
  shipmentsErrors: ShipmentErrorsResponse[];
};

export const getQuoteErrorsResponse = createAsyncThunk<
  QuoteErrorsResponse,
  GetQuoteErrorsResponseArg,
  { state: RootState }
>(
  'quotes/getQuoteErrors',
  async (arg, thunkAPI): Promise<QuoteErrorsResponse> => {
    const quoteValues = selectQuoteValuesById(
      thunkAPI.getState(),
      arg.quoteUuid,
    );

    const companyData = await apolloClient.query<MeQuery, MeQueryVariables>({
      query: MeDocument,
      fetchPolicy: 'cache-first',
    });
    const segment = companyData.data.me?.company.segment;

    if (isNil(quoteValues)) {
      throw new Error(`invalid quoteUuid: ${arg.quoteUuid}`);
    }

    const quoteErrorsResponse: QuoteErrorsResponse = {
      errors: [],
      isValid: true,
      shipmentsErrors: [],
    };

    // Packages validation
    if (
      companyData.data.me?.company.orderFormFields.onePackageRequired ===
        true &&
      (quoteValues.packageUuids?.length ?? 0) === 0
    ) {
      quoteErrorsResponse.errors.push({
        field: 'Packages',
        validationResponse: {
          valid: false,
          explanation: 'At least one package is required',
        },
      });
    }
    if (
      companyData.data.me?.company.orderFormFields.onePackageWeightRequired ===
        true &&
      !quoteValues.packageUuids.some((packageUuid) => {
        const pkg = selectPackageById(thunkAPI.getState(), packageUuid);
        return !isNil(pkg?.weight);
      })
    ) {
      quoteErrorsResponse.errors.push({
        field: 'Packages',
        validationResponse: {
          valid: false,
          explanation: 'At least one package weight is required',
        },
      });
    }

    quoteErrorsResponse.shipmentsErrors = await Promise.all(
      (quoteValues.shipmentUuids ?? []).map(async (shipmentUuid) => {
        const shipmentErrors = await thunkAPI
          .dispatch(
            getStandardShipmentErrors({
              shipmentId: shipmentUuid,
              segment,
              isQuote: true,
              forceValidateAddress: arg.forceValidateAddress,
              ffRecoveryTransferAddressOnly: arg.ffRecoveryTransferAddressOnly,
            }),
          )
          .unwrap();
        if (!shipmentErrors.isValid) {
          quoteErrorsResponse.isValid = false;
        }
        return shipmentErrors;
      }),
    );

    return quoteErrorsResponse;
  },
);
