import { TextField, Typography, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import { type SyntheticEvent, useMemo } from 'react';
import { type CustomerContactEntity } from '../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { useOrderFormEditAccess } from '../../../orders/components/order-form/contexts/order-form-edit-access-context';
import useContactsForMeAsThirdPartyUser from '../../hooks/use-contacts-for-me-as-third-party-user';
import { useCustomerPortalOrderFormContext } from './contexts/customer-portal-order-form-context';

type ContactOption = Pick<
  CustomerContactEntity,
  'uuid' | 'displayName' | 'isPrepaidOnly'
>;

const getOptionLabel = (option: ContactOption) => option.displayName;

const isOptionEqualToValue = (option: ContactOption, value: ContactOption) =>
  option.uuid === value.uuid;

type CustomerPortalContactFieldProps = {
  readonly contactUuid: string | null;
  readonly onChange: (newContactUuid: string) => void;
};

const CustomerPortalContactField = ({
  contactUuid,
  onChange,
}: CustomerPortalContactFieldProps) => {
  const { disabledIfNoAccess } = useOrderFormEditAccess();
  const theme = useTheme();

  const { companyUuid } = useCustomerPortalOrderFormContext();

  const { contacts: contactsWithOrderEntryEnabled, loading } =
    useContactsForMeAsThirdPartyUser({
      filterByCustomerPortalOrderEntryEnabled: true,
    });

  const { options, selectedOption, isPrepaidOnly } = useMemo(() => {
    if (loading) {
      return {
        options: [],
        selectedOption: undefined,
        isPrepaidOnly: false,
      };
    }

    const filteredContacts = contactsWithOrderEntryEnabled.filter(
      (contact) => contact.company.uuid === companyUuid,
    );

    const selectedContact = filteredContacts.find(
      (contact) => contact.uuid === contactUuid,
    );

    return {
      options: filteredContacts,
      selectedOption: selectedContact,
      isPrepaidOnly: selectedContact?.isPrepaidOnly ?? false,
    };
  }, [contactsWithOrderEntryEnabled, companyUuid, contactUuid, loading]);

  const handleChange = (_event: SyntheticEvent, newValue: ContactOption) => {
    onChange(newValue.uuid);
  };

  // If we don't also guard on selectedOption, the autocomplete sometimes
  // renders with undefined options and calls isOptionEqualToValue with
  // undefined values (which crashes)
  if (options.length <= 1 || isNil(selectedOption)) {
    return null;
  }

  return (
    <AutocompleteFuzzy<ContactOption, false, true, false>
      autoHighlight
      disableClearable
      disabled={disabledIfNoAccess}
      value={selectedOption}
      matchSortOptions={{ keys: ['displayName'] }}
      options={options}
      getOptionLabel={getOptionLabel}
      sx={{ flexGrow: 1, maxWidth: '250px' }}
      renderInput={(params) => (
        <TextField
          {...params}
          required
          label={
            <>
              Contact
              {isPrepaidOnly && (
                <Typography
                  component="span"
                  color={theme.palette.paleGreen.main}
                  fontSize="inherit"
                >
                  {' '}
                  (Prepaid)
                </Typography>
              )}
            </>
          }
          variant="outlined"
          size="small"
        />
      )}
      size="small"
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={handleChange}
    />
  );
};

export default CustomerPortalContactField;
