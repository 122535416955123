import {
  FormControl,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Chip,
  InputLabel,
  Stack,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isArray } from 'lodash';
import { useMemo } from 'react';
import useServices from '../../../common/react-hooks/use-services';
import { useAppDispatch } from '../../../redux/hooks';
import { updateOneContactValues } from '../redux/contact-values-slice';
import { isNilOrEmptyString } from '../../../common/utils/utils';
import { useFindServicesForCustomerConfigurationQuery } from '../../../generated/graphql';

const ContactServiceLevelField = ({
  disabled,
  contactUuid,
  serviceUuids,
}: {
  readonly disabled: boolean;
  readonly contactUuid: string;
  readonly serviceUuids: string[] | undefined | null;
}) => {
  const dispatch = useAppDispatch();

  const { data: servicesData } = useFindServicesForCustomerConfigurationQuery(
    isNilOrEmptyString(contactUuid)
      ? { skip: true }
      : {
          variables: {
            findServicesForCustomerConfigurationInput: {
              organizationUuid: contactUuid,
            },
          },
        },
  );

  const unarchivedServices = useMemo(
    () =>
      servicesData?.findServicesForCustomerConfiguration?.__typename ===
      'FindServicesForCustomerConfigurationSuccessOutput'
        ? servicesData?.findServicesForCustomerConfiguration.services
        : [],
    [servicesData],
  );

  const handleChange = (event: SelectChangeEvent) => {
    if (typeof event.target.value === 'string') {
      return;
    }
    dispatch(
      updateOneContactValues({
        id: contactUuid,
        changes: {
          serviceUuids: event.target.value as string[],
        },
      }),
    );
  };

  return (
    <FormControl sx={{ width: '50%' }}>
      <InputLabel>Service levels</InputLabel>
      <Select
        multiple
        size="medium"
        label="Service levels"
        disabled={disabled}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        value={serviceUuids ?? []}
        renderValue={(selected) => {
          if (isArray(selected)) {
            return (
              <Stack flexDirection="row" flexWrap="wrap" gap={0.5}>
                {selected.map((value) => (
                  <Chip
                    key={value}
                    label={sentenceCase(
                      unarchivedServices.find(
                        (service) => service.uuid === value,
                      )?.name ?? '',
                    )}
                  />
                ))}
              </Stack>
            );
          }
          // eslint-disable-next-line react/jsx-no-useless-fragment
          return <></>;
        }}
        onChange={handleChange}
      >
        {unarchivedServices?.map((unarchivedService) => (
          <MenuItem key={unarchivedService.uuid} value={unarchivedService.uuid}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
              width="100%"
              height={24}
            >
              {sentenceCase(unarchivedService.name)}
              {!unarchivedService?.isActive && (
                <Chip label="Archived" size="small" />
              )}
            </Stack>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ContactServiceLevelField;
