import { Button, CircularProgress, type ButtonProps } from '@mui/material';
import { forwardRef, type PropsWithChildren } from 'react';

type PalletButtonProps = PropsWithChildren<{
  /** loading = true also disables the button */
  readonly loading?: boolean;
  readonly disabled?: boolean;
  readonly variant?: ButtonProps['variant'];
  readonly color?: ButtonProps['color'];
  readonly fullWidth?: ButtonProps['fullWidth'];
  readonly startIcon?: ButtonProps['startIcon'];
  readonly endIcon?: ButtonProps['endIcon'];
  readonly type?: ButtonProps['type'];
  readonly onClick: NonNullable<ButtonProps['onClick']>;
}>;

const PalletButton = forwardRef<HTMLButtonElement, PalletButtonProps>(
  (
    {
      children,
      disabled = false,
      loading = false,
      startIcon,
      type,
      ...restProps
    },
    ref,
  ) => {
    return (
      <Button
        ref={ref}
        disabled={disabled || loading}
        startIcon={loading ? <CircularProgress size={15} /> : startIcon}
        type={type}
        {...restProps}
      >
        {children}
      </Button>
    );
  },
);

export default PalletButton;
