import { Stack, TextField } from '@mui/material';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import { exhaustive } from 'shared/switch';
import {
  type FilterConstructionFilterType,
  type FilterConstructionOperatorType,
  type Option,
  type SingleFilterConstructionType,
} from '../../../../../common/filters/types';
import {
  filterToInputType,
  getFilterNameLabel,
  getFilterOperationsByType,
  isDateFilterValueInput,
  isMultiSelectOperation,
  isTextFieldOperation,
  unaryOperators,
} from '../../../../../common/filters/utils';
import { stopPropagation } from '../../../../../common/utils/events';
import { convertValueToOption } from '../../../../../common/utils/utils';
import AutocompleteFuzzy from '../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { OrderFilterFieldV2 } from '../../../../orders/components/enums/order-filters';
import FilterBoolInput from '../../../filter-input-components/filter-bool-input';
import FilterDatePickerInput from '../../../filter-input-components/filter-date-picker-input';
import FilterMultiSelectInput from '../../../filter-input-components/filter-multi-select-input';
import FilterSingleSelectInput from '../../../filter-input-components/filter-single-select-input';
import FilterTextInput from '../../../filter-input-components/filter-text-input';
import OrdersFilterDatePicker from '../../orders-filter-date-picker';
import OrdersFilterDateRangePicker from './orders-filter-date-range-picker';
import useFilterOptions from './use-filter-options';

const FORMATTED_FILTER_NAME_OPTIONS: readonly Option[] = Object.freeze(
  (
    Object.keys(OrderFilterFieldV2) as Array<keyof typeof OrderFilterFieldV2>
  ).map((filterNameOption) => ({
    value: filterNameOption,
    label: getFilterNameLabel(filterNameOption),
  })),
);

type FilterModelSelectProps = {
  readonly filter: SingleFilterConstructionType;
  readonly setFilter: (filter: SingleFilterConstructionType) => void;
};

const FilterModelSelect = ({ filter, setFilter }: FilterModelSelectProps) => {
  const filterNameInput = filter.filter;
  const filterOperationInput = filter.op;
  const filterValueInput = filter.value;
  const filterOptionMappings = useFilterOptions(filter.filter);

  const filterType = useMemo(() => {
    if (!isNil(filter.filter)) {
      return filterToInputType(filter.filter);
    }
    return 'text';
  }, [filter.filter]);

  const shouldBeMultiselect = isMultiSelectOperation({
    filterTypes: filterType,
    filterConstructionOperatorType: filterOperationInput,
  });
  const shouldBeTextField = isTextFieldOperation({
    filterTypes: filterType,
    filterConstructionOperatorType: filterOperationInput,
  });

  const mapTypeToInputComponent = () => {
    if (unaryOperators.has(filterOperationInput)) {
      return null;
    }
    switch (filterType) {
      case 'date': {
        if (filterOperationInput === 'eqV2') {
          const dateFilterValueInput = isDateFilterValueInput(filterValueInput)
            ? filterValueInput
            : null;
          return (
            <OrdersFilterDatePicker
              filterNameInput={filterNameInput}
              filterOperationInput={filterOperationInput}
              filterValueInput={dateFilterValueInput}
              setFilter={setFilter}
            />
          );
        }
        if (filterOperationInput === 'between') {
          return (
            <OrdersFilterDateRangePicker
              filterOperationInput={filterOperationInput}
              filterNameInput={filterNameInput}
              filterValueInput={filterValueInput}
              setFilter={setFilter}
            />
          );
        }
        return (
          <FilterDatePickerInput
            filterNameInput={filterNameInput}
            filterOperationInput={filterOperationInput}
            filterValueInput={filterValueInput}
            setFilter={setFilter}
          />
        );
      }

      case 'select':
      case 'uuidList':
      case 'nonEmptyUuidList': {
        if (shouldBeTextField) {
          return (
            <FilterTextInput
              filterNameInput={filterNameInput}
              filterOperationInput={filterOperationInput}
              filterValueInput={filterValueInput}
              setFilter={setFilter}
              textFieldType="text"
            />
          );
        }
        if (shouldBeMultiselect) {
          return (
            <FilterMultiSelectInput
              filterNameInput={filterNameInput}
              filterOperationInput={filterOperationInput}
              filterValueInput={filterValueInput}
              setFilter={setFilter}
              filterOptionMappings={filterOptionMappings ?? []}
            />
          );
        }
        return (
          <FilterSingleSelectInput
            filterNameInput={filterNameInput}
            filterOperationInput={filterOperationInput}
            filterValueInput={filterValueInput}
            setFilter={setFilter}
            filterOptionMappings={filterOptionMappings ?? []}
          />
        );
      }
      case 'enum': {
        if (shouldBeMultiselect) {
          return (
            <FilterMultiSelectInput
              filterNameInput={filterNameInput}
              filterOperationInput={filterOperationInput}
              filterValueInput={filterValueInput}
              setFilter={setFilter}
              filterOptionMappings={filterOptionMappings ?? []}
            />
          );
        }
        return (
          <FilterSingleSelectInput
            filterNameInput={filterNameInput}
            filterOperationInput={filterOperationInput}
            filterValueInput={filterValueInput}
            setFilter={setFilter}
            filterOptionMappings={filterOptionMappings ?? []}
          />
        );
      }
      case 'bool': {
        return (
          <FilterBoolInput
            filterNameInput={filterNameInput}
            filterOperationInput={filterOperationInput}
            filterValueInput={filterValueInput}
            setFilter={setFilter}
          />
        );
      }
      case 'text':
      case 'integer':
      case 'float': {
        return (
          <FilterTextInput
            filterNameInput={filterNameInput}
            filterOperationInput={filterOperationInput}
            filterValueInput={filterValueInput}
            setFilter={setFilter}
            textFieldType={
              filterType === 'integer' || filterType === 'float'
                ? 'number'
                : 'text'
            }
          />
        );
      }
      default: {
        return exhaustive(filterType);
      }
    }
  };

  const filterOperationOptions: Option[] = useMemo(() => {
    return Object.entries(getFilterOperationsByType(filterType)).map(
      ([filterOperator, filterDisplayName]) => ({
        value: filterOperator,
        label: filterDisplayName,
      }),
    );
  }, [filterType]);

  return (
    <Stack
      direction="row"
      alignItems="start"
      // Tabbing while focused on an input element causes the popover to close unless we stop propagation.
      onKeyDown={stopPropagation}
    >
      <AutocompleteFuzzy
        sx={{
          backgroundColor: 'white',
          minWidth: '300px',
          flexGrow: 1,
        }}
        value={convertValueToOption({
          value: filterNameInput as string,
          optionsList: FORMATTED_FILTER_NAME_OPTIONS,
        })}
        options={FORMATTED_FILTER_NAME_OPTIONS}
        matchSortOptions={{ keys: ['label'] }}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            placeholder="Select property..."
            InputProps={{
              ...params.InputProps,
              sx: { borderRadius: 0 },
            }}
          />
        )}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        onChange={(_, selected) => {
          setFilter({
            filter: (selected?.value as FilterConstructionFilterType) ?? null,
            op: null,
            value: null,
          });
        }}
      />
      {filterType !== 'bool' && (
        <AutocompleteFuzzy
          sx={{
            backgroundColor: 'white',
            minWidth: '160px',
            minHeight: '34px',
            flexGrow: 1,
          }}
          value={convertValueToOption({
            value: filterOperationInput as string,
            optionsList: filterOperationOptions,
          })}
          options={filterOperationOptions}
          matchSortOptions={{ keys: ['label'] }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              placeholder="Operator"
              InputProps={{
                ...params.InputProps,
                sx: {
                  borderRadius: 0,
                  marginLeft: '-1px',
                },
              }}
            />
          )}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          renderOption={(props, option) => (
            <li {...props} style={{ padding: '4px' }}>
              {option.label}
            </li>
          )}
          onChange={(_, selected) => {
            setFilter({
              filter: filterNameInput,
              op: (selected?.value as FilterConstructionOperatorType) ?? null,
              value: null,
            });
          }}
        />
      )}
      {mapTypeToInputComponent()}
    </Stack>
  );
};

export default FilterModelSelect;
