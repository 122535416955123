import {
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import { type FunctionComponent, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../common/react-hooks/use-me';
import {
  OrganizationType,
  useBusinessDivisionsQuery,
  useOrganizationForFrontendHookQuery,
} from '../../../../generated/graphql';
import PalletButton from '../../../../pallet-ui/button/pallet-button';
import DefaultAddressAutocomplete from '../../components/default-address-autocomplete';
import DefaultContactPersonAutocomplete from '../../components/default-contact-person-autocomplete';
import OrganizationNotes from '../../components/organization-notes';
import { OrganizationPageMode, type OrganizationPageProps } from '../../enums';
import { type OrganizationFormValues } from '../../form/types';
import { useStyles } from '../../styles';
import BillingConfigCard from './billing-config-card';
import CustomerPortalConfigCard from './customer-portal-config-card';
import EdiConfigCard from './edi-config-card';
import ReferenceNumberLabelsConfigCard from './reference-number-labels-config-card';
import ServiceLevelConfigCard from './service-level-config-card';
import VehicleTypesConfigCard from './vehicle-types-config-card';

const GeneralTabForm: FunctionComponent<
  OrganizationPageProps & {
    readonly formLoading: boolean;
    readonly setSelectedOrganizationTypes: (types: OrganizationType[]) => void;
    readonly isAgent: boolean;
    readonly isCustomer: boolean;
    readonly onSubmit: () => void;
    readonly submitLoading: boolean;
    readonly initialTypes: OrganizationType[];
  }
> = ({
  formLoading,
  setSelectedOrganizationTypes,
  isAgent,
  isCustomer,
  onSubmit,
  submitLoading,
  initialTypes,
  ...props
}) => {
  const {
    control,
    formState: { isDirty },
  } = useFormContext<OrganizationFormValues>();
  const { mode, uuid } = props;
  const theme = useTheme();

  const styles = useStyles();
  //   const isAgent = useWatch({ control, name: 'isAgent' });
  //   const isCustomer = useWatch({ control, name: 'isCustomer' });
  const { data: organizationData } = useOrganizationForFrontendHookQuery({
    skip: isNil(uuid) || mode === OrganizationPageMode.CREATE,
    variables: {
      input: { uuid: uuid ?? '' },
    },
  });

  const [uncheckExistingTypeError, setUncheckExistingTypeError] =
    useState(false);

  const { userUuid, loading: meLoading } = useMe();

  const ffEnableCustomerPortalOrderEntry = useFeatureFlag(
    FeatureFlag.FF_CUSTOMER_PORTAL_ORDER_ENTRY,
  );

  // these are fields that are only relevant to customers
  const showBusinessDivision = isCustomer;
  const showIdentifier = isCustomer;
  const showDefaultAddress = isCustomer && mode === OrganizationPageMode.EDIT;
  const showDefaultContactPerson =
    (isCustomer || isAgent) && mode === OrganizationPageMode.EDIT;
  const showServiceLevels = isCustomer;
  const showVehicleTypes = isCustomer;
  const showReferenceNumberLabels =
    isCustomer && mode === OrganizationPageMode.EDIT;
  const showBillingConfig = isCustomer;
  const showEdiConfig = isCustomer || isAgent;
  const showCustomerPortalConfig =
    isCustomer && ffEnableCustomerPortalOrderEntry;

  const { data: businessDivisionsData } = useBusinessDivisionsQuery({
    skip: !showBusinessDivision,
  });
  const businessDivisions = businessDivisionsData?.businessDivisions;

  if (formLoading) {
    return <CircularProgress />;
  }

  return (
    <Stack width="100%" gap={2}>
      <Stack
        direction="row"
        justifyContent="flex-end"
        sx={{
          position: 'sticky',
          top: 0,
          // this needs to match the color of the stack set in OrganizationPage
          backgroundColor: theme.palette.concreteGrey[10],
          zIndex: 1000,
          py: 1,
          borderBottom: `1px solid ${theme.palette.concreteGrey[30]}`,
        }}
      >
        {mode !== OrganizationPageMode.VIEW && (
          <PalletButton
            variant="contained"
            disabled={!isDirty}
            loading={submitLoading}
            onClick={onSubmit}
          >
            {mode === OrganizationPageMode.CREATE ? 'Create' : 'Save'}
          </PalletButton>
        )}
      </Stack>
      <Stack direction="row" gap={2} maxHeight="700px">
        <Card
          sx={{
            flex: 3,
            ...styles.card,
          }}
        >
          <CardContent sx={styles.cardContent}>
            <Stack gap={2}>
              <Typography variant="h6">General</Typography>
              <Stack direction="row" gap={2}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl
                      required
                      sx={{ flex: 1 }}
                      error={!isNil(error)}
                    >
                      <FormLabel>Name</FormLabel>
                      <TextField
                        {...field}
                        sx={{ width: '80%' }}
                        placeholder="Name"
                        variant="outlined"
                        helperText={error?.message}
                      />
                    </FormControl>
                  )}
                />
              </Stack>
              <Stack>
                <Controller
                  name="types"
                  control={control}
                  defaultValue={[]}
                  render={({
                    field: { value = [], onChange },
                    fieldState: { error },
                  }) => (
                    <FormControl
                      required
                      sx={{ flex: 1, gap: 1 }}
                      error={!isNil(error)}
                    >
                      <Stack>
                        <FormLabel
                          sx={{ fontWeight: 'bold', fontSize: '16px' }}
                        >
                          Account type
                        </FormLabel>
                        <Typography
                          variant="caption"
                          color={theme.palette.concreteGrey[50]}
                        >
                          Account types may only be added, not removed. For
                          example, a customer account can become a dual customer
                          and agent account, but cannot be changed to an agent
                          account.
                        </Typography>
                      </Stack>
                      <FormGroup row sx={{ gap: 10 }}>
                        {Object.values(OrganizationType).map((enumValue) => (
                          <Stack key={enumValue}>
                            <FormControlLabel
                              key={enumValue}
                              control={
                                <Checkbox
                                  checked={value.includes(enumValue)}
                                  onChange={(e) => {
                                    const newValue = e.target.checked
                                      ? [...value, enumValue]
                                      : value.filter((v) => v !== enumValue);

                                    // if the initial types has values not in new values
                                    if (
                                      initialTypes.some(
                                        (type) => !newValue.includes(type),
                                      )
                                    ) {
                                      setUncheckExistingTypeError(true);
                                    } else {
                                      setUncheckExistingTypeError(false);
                                    }

                                    onChange(newValue);
                                    setSelectedOrganizationTypes(newValue);
                                  }}
                                />
                              }
                              label={
                                <Stack justifyContent="flex-start" pt={2}>
                                  <Typography>
                                    {sentenceCase(enumValue)}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    color={theme.palette.concreteGrey[50]}
                                  >
                                    {enumValue === OrganizationType.Customer
                                      ? 'Books orders that you fulfill'
                                      : 'Paid partner that fulfills portions of your orders'}
                                  </Typography>
                                </Stack>
                              }
                            />
                          </Stack>
                        ))}
                      </FormGroup>
                      {!isNil(error) && (
                        <FormHelperText>{error.message}</FormHelperText>
                      )}
                      <FormHelperText color="error">
                        {uncheckExistingTypeError &&
                          'Cannot uncheck an existing account type'}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </Stack>
              <Stack direction="row" gap={2}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl
                      required
                      sx={{ flex: 1 }}
                      error={!isNil(error)}
                    >
                      <FormLabel>Email</FormLabel>
                      <TextField
                        {...field}
                        placeholder="Email"
                        variant="outlined"
                        helperText={error?.message}
                      />
                    </FormControl>
                  )}
                />
                <Controller
                  name="referenceNumber"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl sx={{ flex: 1 }} error={!isNil(error)}>
                      <FormLabel>Account ID</FormLabel>
                      <TextField
                        {...field}
                        placeholder="Account ID"
                        variant="outlined"
                        helperText={error?.message}
                      />
                    </FormControl>
                  )}
                />
              </Stack>
              <Stack direction="row" gap={2}>
                {showBusinessDivision && (
                  <Controller
                    name="businessDivisionUuid"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl sx={{ flex: 1 }} error={!isNil(error)}>
                        <FormLabel>Business Division</FormLabel>
                        <Select
                          {...field}
                          id="business-division-select"
                          disabled={mode === OrganizationPageMode.VIEW}
                          value={field.value ?? 'None'}
                        >
                          <MenuItem value="None">None</MenuItem>
                          {businessDivisions?.businessDivisions.map(
                            (businessDivision) => (
                              <MenuItem
                                key={businessDivision.uuid}
                                value={businessDivision.uuid}
                              >
                                {businessDivision.name}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                        {!isNil(error) && (
                          <FormHelperText>{error.message}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                )}
                {showIdentifier && (
                  <Controller
                    name="invoiceOrOrderIdentifier"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <FormControl
                        required
                        sx={{ flex: 1 }}
                        error={!isNil(error)}
                      >
                        <FormLabel>Invoice/Order Identifier</FormLabel>
                        <TextField
                          {...field}
                          sx={{ flex: 1 }}
                          placeholder="Invoice/Order Identifier"
                          variant="outlined"
                          helperText={error?.message}
                        />
                      </FormControl>
                    )}
                  />
                )}
              </Stack>
              <Stack direction="row" gap={2}>
                {showDefaultContactPerson && (
                  <DefaultContactPersonAutocomplete
                    control={control}
                    organizationContactPersons={
                      organizationData?.organization?.contactPersons ?? []
                    }
                  />
                )}
                {showDefaultAddress ? (
                  <DefaultAddressAutocomplete
                    control={control}
                    organizationAddresses={
                      organizationData?.organization?.addresses ?? []
                    }
                  />
                ) : (
                  // add a flex so the contact person autocomplete doesn't fill
                  <Stack flex={1} />
                )}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
        {mode === OrganizationPageMode.EDIT && !isNil(uuid) && !meLoading && (
          <Card sx={{ flex: 2, ...styles.card }}>
            <CardContent sx={{ ...styles.cardContent, height: '100%' }}>
              <Stack gap={2} height="100%">
                <Typography variant="h6">Notes</Typography>
                <OrganizationNotes
                  organizationUuid={uuid}
                  userUuid={userUuid}
                  loading={meLoading || formLoading}
                />
              </Stack>
            </CardContent>
          </Card>
        )}
      </Stack>
      {showServiceLevels && <ServiceLevelConfigCard mode={mode} />}
      {showEdiConfig && <EdiConfigCard mode={mode} />}
      {showBillingConfig && <BillingConfigCard mode={mode} />}
      {showCustomerPortalConfig && <CustomerPortalConfigCard mode={mode} />}
      {showVehicleTypes && <VehicleTypesConfigCard mode={mode} />}
      {showReferenceNumberLabels && (
        <ReferenceNumberLabelsConfigCard mode={mode} />
      )}
    </Stack>
  );
};

export default GeneralTabForm;
