import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { isNil } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import { shallow } from 'zustand/shallow';
import {
  OrderStatus,
  OrderStatusFilterType,
  type TypeAheadOrderFragment,
  useTypeaheadOrderUuidsQueryLazyQuery,
} from '../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import useOrderForm from '../../../orders/components/order-form/forms/use-order-form';
import useSaveOrderBrm from '../../../orders/components/order-form/forms/use-save-order-brm';
import useBillingReviewStore from '../../billing-review-store';
import useBillingReviewActions from '../../hooks/use-billing-review-actions';
import { OUTSTANDING_ORDERS_PAGE_SIZE } from '../../types/constants';
import OutstandingOrderPage from './outstanding-order-page';
import SelectAndSendOrders from './select-and-send-orders';

type ReviewOutstandingOrdersModalProps = {
  readonly initialOrderUuids: string[];
  readonly onClose?: () => void;
  readonly isOpen: boolean;
};

const ReviewOutstandingOrdersModal = ({
  initialOrderUuids,
  onClose,
  isOpen,
}: ReviewOutstandingOrdersModalProps) => {
  const theme = useTheme();
  const { saveOrder: saveCurrentOrder } = useSaveOrderBrm();
  const { form } = useOrderForm();

  const [
    outstandingOrdersInPage,
    searchedOrders,
    setOutstandingOrderUuidsInReview,
    setOpenedOutstandingOrderUuid,
    isSubmitting,
    setIsSubmitting,
    resetStore,
  ] = useBillingReviewStore(
    (state) => [
      state.outstandingOrdersInPage,
      state.searchedOrders,
      state.setOutstandingOrderUuidsInReview,
      state.setOpenedOutstandingOrderUuid,
      state.isSubmitting,
      state.setIsSubmitting,
      state.resetStore,
    ],
    shallow,
  );

  const { fetchAndAddSearchedOrder, fetchOutstandingOrdersAndSetStore } =
    useBillingReviewActions();

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [newSearchText, setNewSearchText] = useState('');
  const versionIdRef = useRef(0);
  const [debouncedNewSearchText] = useDebounce(newSearchText, 400);
  const [getOrderResults, { loading: searchLoading }] =
    useTypeaheadOrderUuidsQueryLazyQuery();
  const [searchOptions, setSearchOptions] = useState<
    Array<{ value: string; label: string; object: TypeAheadOrderFragment }>
  >([]);

  useEffect(() => {
    // Create a signal to abort the request if needed
    const controller = new AbortController();
    const { signal } = controller;

    const fetchData = async () => {
      const currentVersionId = versionIdRef.current + 1;
      versionIdRef.current = currentVersionId;

      try {
        const orderData = await getOrderResults({
          variables: {
            searchText: debouncedNewSearchText,
            first: 10,
            statusFilters: [
              {
                status: OrderStatus.Cancelled,
                filterType: OrderStatusFilterType.Equals,
              },
              {
                status: OrderStatus.Created,
                filterType: OrderStatusFilterType.Equals,
              },
              {
                status: OrderStatus.InProgress,
                filterType: OrderStatusFilterType.Equals,
              },
              {
                status: OrderStatus.Delivered,
                filterType: OrderStatusFilterType.Equals,
              },
              {
                status: OrderStatus.Finalized,
                filterType: OrderStatusFilterType.Equals,
              },
              {
                status: OrderStatus.HasIssue,
                filterType: OrderStatusFilterType.Equals,
              },
            ],
            onInvoice: { value: false },
            versionId: currentVersionId,
          },
          context: {
            // Attach the AbortController's signal to the context
            fetchOptions: {
              signal,
            },
          },
        });

        // Check if the response corresponds to the latest request
        if (
          currentVersionId === orderData.data?.orders.versionId &&
          debouncedNewSearchText === newSearchText
        ) {
          const options =
            orderData.data?.orders.edges.map(({ node: order }) => ({
              value: order.uuid,
              label:
                order.standardOrderFields.shipperBillOfLadingNumber ??
                order.name,
              object: order,
            })) ?? [];
          setSearchOptions(options);
        }
      } catch (error) {
        // Check if the error is due to the request being aborted
        captureException(
          `Search error in billing review modal: ${String(error)}`,
        );
      }
    };

    // Trigger the fetchData function
    fetchData();

    // Cleanup function to cancel the previous ongoing operation
    return () => {
      controller.abort();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedNewSearchText]);

  const fetchOrders = async ({
    first,
    after,
    last,
    before,
    uuids,
    isInitialFetch,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
    uuids: string[];
    isInitialFetch?: boolean;
  }) => {
    await fetchOutstandingOrdersAndSetStore({
      queryVariables: {
        first,
        after,
        last,
        before,
        uuids: uuids ?? undefined,
      },
      isInitialFetch,
      openLastOrderOnPage: false,
      uuids,
    });
  };

  const handleClose = async () => {
    saveCurrentOrder({});
    resetStore();
    if (!isNil(onClose)) {
      onClose();
    }
  };

  const handleOpen = async () => {
    if ((initialOrderUuids ?? []).length > 0) {
      fetchOrders({
        first: OUTSTANDING_ORDERS_PAGE_SIZE,
        uuids: initialOrderUuids,
        isInitialFetch: true,
      });
      setOutstandingOrderUuidsInReview(initialOrderUuids);
    } else {
      fetchOrders({
        first: OUTSTANDING_ORDERS_PAGE_SIZE,
        uuids: [],
        isInitialFetch: true,
      });
    }
  };

  const saveAndExit = async () => {
    setIsSubmitting(true);
    await saveCurrentOrder({});
    handleClose();
    setIsSubmitting(false);
  };

  useEffect(() => {
    setOpenedOutstandingOrderUuid(undefined);
    if (isOpen) {
      handleOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <FormProvider {...form}>
      <Dialog
        open={isOpen}
        maxWidth="xl"
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '100%',
            },
          },
        }}
      >
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!isNil(errorMessage)}
          onClose={() => {
            setErrorMessage(null);
          }}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
        <IconButton
          sx={{ ml: 'left', p: '2px', position: 'absolute' }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!isNil(errorMessage)}
          onClose={() => {
            setErrorMessage(null);
          }}
        >
          <Alert severity="error">{errorMessage}</Alert>
        </Snackbar>
        <Stack
          spacing={1}
          sx={{
            p: 2.5,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              px: '5px',
            }}
          >
            <AutocompleteFuzzy
              disableCloseOnSelect
              options={searchLoading ? [] : searchOptions}
              matchSortOptions={{ keys: ['label'] }}
              sx={{ minWidth: '200px' }}
              clearOnBlur={false}
              filterOptions={(x) => x}
              noOptionsText="We couldn't find this order"
              loading={searchLoading}
              renderOption={(
                props,
                option: {
                  value: string;
                  label: string;
                  object: TypeAheadOrderFragment;
                },
              ) => (
                <li {...props}>
                  <Stack spacing={0.5}>
                    <Typography>{option.label}</Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: theme.palette.grey[400],
                      }}
                    >
                      {option.object.name}
                    </Typography>
                  </Stack>
                </li>
              )}
              renderInput={(params) => (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <TextField
                    {...params}
                    size="small"
                    label="Find orders by HAWB"
                    value={newSearchText}
                    sx={{ width: 250 }}
                    onChange={(e) => {
                      setNewSearchText(e.target.value);
                    }}
                  />
                  {searchLoading && <CircularProgress size={20} />}
                </Stack>
              )}
              onChange={async (e, option) => {
                await saveCurrentOrder({});
                if (
                  !isNil(option) &&
                  [...searchedOrders, ...outstandingOrdersInPage].every(
                    (itrOrder) =>
                      itrOrder.uuid !== option.object.uuid &&
                      (itrOrder.ordersProratedWith ?? []).every(
                        (proratedOrderOfItrOrder) =>
                          proratedOrderOfItrOrder.uuid !== option.object.uuid,
                      ),
                  )
                ) {
                  fetchAndAddSearchedOrder(option.object.uuid);
                }
                setOpenedOutstandingOrderUuid(option?.object.uuid);
              }}
            />
            <Stack direction="row" alignItems="center" spacing={2}>
              <SelectAndSendOrders />
              <Button
                variant="contained"
                disabled={isSubmitting}
                onClick={saveAndExit}
              >
                <>
                  {isSubmitting && (
                    <CircularProgress size={10} sx={{ mr: '5px' }} />
                  )}
                  Done reviewing{' '}
                  {outstandingOrdersInPage.length + searchedOrders.length > 0
                    ? `${outstandingOrdersInPage.length + searchedOrders.length} `
                    : ''}
                  orders
                </>
              </Button>
            </Stack>
          </Stack>
          <OutstandingOrderPage setErrorMessage={setErrorMessage} />
        </Stack>
      </Dialog>
    </FormProvider>
  );
};

export default ReviewOutstandingOrdersModal;
