import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../common/react-hooks/use-user-roles';
import {
  type CreatePersonInput,
  PermissionResource,
  PersonStatus,
  useArchivePersonMutation,
  useCreatePersonMutation,
  usePersonsQuery,
  useRestorePersonMutation,
  useUpdatePersonMutation,
} from '../../../generated/graphql';
import theme from '../../../theme';
import ContactPageMode from './contact-page-mode';
import PeoplePanelPerson from './people-panel-person';

type PeoplePanelProps = {
  readonly contactUuid: string;
  readonly mode: ContactPageMode;
  readonly handleChangeMode: (mode: ContactPageMode) => void;
};

const PeoplePanel = ({
  contactUuid,
  mode,
  handleChangeMode,
}: PeoplePanelProps) => {
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteContacts } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Contacts,
  );

  const {
    data: personsData,
    refetch,
    loading,
  } = usePersonsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      contactUuid,
    },
  });
  const [createPerson, { loading: creating }] = useCreatePersonMutation({
    fetchPolicy: 'network-only',
  });
  const [updatePerson, { loading: updating }] = useUpdatePersonMutation({
    fetchPolicy: 'network-only',
  });
  const [archivePerson, { loading: archiving }] = useArchivePersonMutation({
    fetchPolicy: 'network-only',
  });
  const [restorePerson, { loading: restoring }] = useRestorePersonMutation({
    fetchPolicy: 'network-only',
  });

  const [selectedPersonId, setSelectedPersonId] = useState<string | null>(
    personsData?.persons.persons[0]?.id ?? null,
  );

  const selectedPerson = useMemo(
    () =>
      isNil(selectedPersonId)
        ? null
        : personsData?.persons.persons.find(
            ({ id }) => id === selectedPersonId,
          ),
    [personsData, selectedPersonId],
  );

  useEffect(() => {
    if (!isNil(personsData) && isNil(selectedPersonId)) {
      const [firstPerson] = personsData.persons.persons;
      setSelectedPersonId(firstPerson?.id ?? null);
    }
  }, [personsData, selectedPersonId]);

  const handleSave = (person: Omit<CreatePersonInput, 'contactUuid'>) => {
    if (mode === ContactPageMode.CREATE) {
      createPerson({
        variables: {
          input: {
            ...person,
            contactUuid,
          },
        },
        onCompleted: async (data) => {
          const { data: newPersonsData } = await refetch();
          const newPerson = newPersonsData.persons.persons.find(
            ({ id }) => id === data.createPerson.person.id,
          );
          if (!isNil(newPerson)) {
            setSelectedPersonId(newPerson.id);
          }
          handleChangeMode(ContactPageMode.VIEW);
        },
      });
    } else if (!isNil(selectedPerson)) {
      updatePerson({
        variables: {
          input: {
            id: selectedPerson.id,
            contactUuid,
            ...person,
          },
        },
        onCompleted: () => {
          handleChangeMode(ContactPageMode.VIEW);
        },
      });
    }
  };

  const onToggleArchive = () => {
    if (!isNil(selectedPerson)) {
      if (selectedPerson.status === PersonStatus.Active) {
        archivePerson({
          variables: {
            id: selectedPerson.id,
          },
        });
      } else {
        restorePerson({
          variables: {
            id: selectedPerson.id,
          },
        });
      }
    }
  };

  return (
    <Stack direction="row" spacing={2} width="100%">
      <List
        sx={{
          flex: 1,
          backgroundColor: 'white',
          border: '1px solid #E0E0E0',
          borderRadius: '8px',
          p: 0,
          minHeight: '200px',
        }}
      >
        <Box sx={{ p: 1 }}>
          <Button
            fullWidth
            variant="outlined"
            disabled={mode === ContactPageMode.CREATE || !canWriteContacts}
            size="small"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
            onClick={() => {
              handleChangeMode(ContactPageMode.CREATE);
            }}
          >
            <AddIcon />
            <span>Add person</span>
          </Button>
        </Box>
        {personsData?.persons.persons.map((person) => (
          <ListItem key={person.id} disablePadding>
            <ListItemButton
              disabled={mode !== ContactPageMode.VIEW}
              selected={
                !isNil(selectedPersonId) && person.id === selectedPersonId
              }
              onClick={() => {
                setSelectedPersonId(person.id);
              }}
            >
              <ListItemText
                primary={`${person.firstName} ${person.lastName}`}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Card
        sx={{
          flex: 3,
          p: 2,
          boxShadow: 'none',
          border: '1px solid #E0E0E0',
        }}
      >
        {loading ? (
          <Box sx={{ py: 3, textAlign: 'center' }}>
            <CircularProgress />
          </Box>
        ) : !isNil(selectedPerson) || mode === ContactPageMode.CREATE ? (
          <PeoplePanelPerson
            key={
              mode === ContactPageMode.CREATE ? 'create' : selectedPerson?.id
            }
            contactUuid={contactUuid}
            person={mode === ContactPageMode.CREATE ? null : selectedPerson}
            mode={mode}
            handleChangeMode={handleChangeMode}
            canWrite={canWriteContacts}
            handleSave={handleSave}
            saving={creating || updating}
            togglingArchive={archiving || restoring}
            onToggleArchive={onToggleArchive}
          />
        ) : (
          <Stack justifyContent="center" alignItems="center" height="100%">
            <Typography color={theme.palette.concreteGrey[40]}>
              You do not have any people for this account.
            </Typography>
          </Stack>
        )}
      </Card>
    </Stack>
  );
};

export default PeoplePanel;
