import {
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import pluralize from 'pluralize';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import {
  ORDER_PAGE_LINE_HAUL_SELECT_TEST_ID,
  ORDER_PAGE_SERVICE_SELECT_INPUT_TEST_ID,
  ORDER_PAGE_TRANSPORT_ON_LINE_HAUL_CHECKBOX_TEST_ID,
} from '../../../../../../../constants';
import { FeatureFlag } from '../../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../../common/react-hooks/use-feature-flag';
import useLineHaulLanes from '../../../../../../common/react-hooks/use-line-haul-lanes';
import useMe from '../../../../../../common/react-hooks/use-me';
import useServices from '../../../../../../common/react-hooks/use-services';
import {
  FulfillmentType,
  ShipmentType,
  StopType,
  useGetLineHaulProgressLazyQuery,
  usePackageSpecsQuery,
  useVehicleTypeCapacityLazyQuery,
  useVehicleTypesMinimalQuery,
  type VehicleTypesMinimalQuery,
  VehicleTypeStatus,
  OrderStatus,
  type QuoteStatus,
} from '../../../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import theme from '../../../../../../theme';
import { useOrderFormEditAccess } from '../../contexts/order-form-edit-access-context';
import { type OrderFormFieldValues } from '../../forms/types';
import { OrderFormEditAccess } from '../../forms/use-order-form-edit-access';
import { useUpdateShipmentToMatchExpected } from '../../hooks/use-expected-order-components';
import { useOrderFormContact } from '../../hooks/use-order-form-contact';
import { INBOUND_STOP_IDX, OUTBOUND_STOP_IDX } from '../constants';
import ServiceAutocomplete from './components/service-autocomplete';
import {
  dateAndTimeIsWithinAvailability,
  vehicleTypeCapacityWarningMessage,
} from './utils';

const lineHaulTooltipMessage = ({
  editAccess,
  status,
  inboundStopTerminalUuid,
  outboundStopTerminalUuid,
  inboundStopType,
  outboundStopType,
}: {
  editAccess: OrderFormEditAccess;
  status: OrderStatus | QuoteStatus | null | undefined;
  inboundStopType: StopType;
  outboundStopType: StopType;
  inboundStopTerminalUuid: string | null;
  outboundStopTerminalUuid: string | null;
}) => {
  if (
    editAccess === OrderFormEditAccess.FinalizedOrOnInvoice ||
    editAccess === OrderFormEditAccess.InvoicePosted ||
    status === OrderStatus.Finalized ||
    status === OrderStatus.Invoiced
  ) {
    return 'Unfinalize charges to edit line haul';
  }
  if (inboundStopType === StopType.None || outboundStopType === StopType.None) {
    return 'Add a stop to use line haul';
  }
  if (
    inboundStopTerminalUuid === outboundStopTerminalUuid &&
    !isNil(inboundStopTerminalUuid) &&
    !isNil(outboundStopTerminalUuid)
  ) {
    return 'Inbound and outbound stops must be different terminals';
  }
  return 'Select different terminals to use line haul';
};

type MinimalVehicleType = VehicleTypesMinimalQuery['vehicleTypes'][number];

// TEMPORARY to suppress the service availability warning message while we don't have a proper way
// in state to represent when this component is being shown in the customer portal.
const isCustomerPortal = false;

const Service = ({ isEditMode }: { readonly isEditMode: boolean }) => {
  const { control, setValue, clearErrors } =
    useFormContext<OrderFormFieldValues>();
  const { errors } = useFormState({ control });
  const { companyConfiguration } = useMe();

  const { contact } = useOrderFormContact();
  const { services } = useServices();
  const { data: vehicleTypesData } = useVehicleTypesMinimalQuery({
    fetchPolicy: 'cache-and-network',
  });
  // Use the PackageSpecsQuery rather than PackageSpecsMinimalQuery because the former should already be active on
  // the order page and we can avoid an additional network request.
  const { data: packageSpecsData } = usePackageSpecsQuery({
    fetchPolicy: 'cache-first',
  });
  const [getVehicleTypeCapacity, { data: vehicleTypeCapacityData }] =
    useVehicleTypeCapacityLazyQuery({
      fetchPolicy: 'cache-first',
    });

  const orderUuid = useWatch({ control, name: 'uuid' });
  const serviceUuid = useWatch({ control, name: 'serviceUuid' });
  const vehicleTypeUuid = useWatch({ control, name: 'vehicleTypeUuid' });
  const vehicleTypeOptions: MinimalVehicleType[] = [];
  if (
    contact?.__typename === 'CustomerContactEntity' &&
    contact.useCustomVehicleTypes
  ) {
    for (const vt of contact.vehicleTypes) {
      if (vt.status === VehicleTypeStatus.Active) {
        vehicleTypeOptions.push(vt);
      }
    }
  } else {
    for (const vt of vehicleTypesData?.vehicleTypes ?? []) {
      if (vt.status === VehicleTypeStatus.Active) {
        vehicleTypeOptions.push(vt);
      }
    }
  }
  let selectedVehicleType = isNil(vehicleTypeUuid)
    ? undefined
    : vehicleTypesData?.vehicleTypes.find((vt) => vt.uuid === vehicleTypeUuid);
  if (!isNil(vehicleTypeUuid) && isNil(selectedVehicleType)) {
    // Find the archived vehicle type. It should be displayed even though it's archived or is no longer available for the contact.
    selectedVehicleType = vehicleTypesData?.vehicleTypes.find(
      (vt) => vt.uuid === vehicleTypeUuid,
    );
    if (!isNil(selectedVehicleType)) {
      vehicleTypeOptions.push(selectedVehicleType);
    }
  }

  const fulfillmentType = useWatch({ control, name: 'fulfillmentType' });
  const lineHaulLaneUuid = useWatch({ control, name: 'lineHaulLaneUuid' });
  const isUsingLineHaul = useWatch({ control, name: 'isUsingLineHaul' });
  const enableLineHaul = useWatch({ control, name: 'enableLineHaul' });
  const packages = useWatch({ control, name: 'packages' }) ?? [];
  const useKilograms = useWatch({ control, name: 'useKilograms' });
  const useCentimeters = useWatch({ control, name: 'useCentimeters' });
  const [showLineHaulCheckbox, setShowLineHaulCheckbox] =
    useState<boolean>(false);
  const showFulfillmentOptions =
    companyConfiguration?.fulfillmentTypeEnabled === true;

  const inboundStopType = useWatch({
    control,
    name: `stops.${INBOUND_STOP_IDX}.stopType`,
  });
  const inboundStopTerminalUuid = useWatch({
    control,
    name: `stops.${INBOUND_STOP_IDX}.terminalUuid`,
  });
  const inboundStopServiceDate = useWatch({
    control,
    name: `stops.${INBOUND_STOP_IDX}.serviceDate`,
  });
  const inboundStopApptStart = useWatch({
    control,
    name: `stops.${INBOUND_STOP_IDX}.appointmentTime`,
  });
  const inboundStopApptEnd = useWatch({
    control,
    name: `stops.${INBOUND_STOP_IDX}.endAppointmentTime`,
  });
  const outboundStopType = useWatch({
    control,
    name: `stops.${OUTBOUND_STOP_IDX}.stopType`,
  });
  const outboundStopTerminalUuid = useWatch({
    control,
    name: `stops.${OUTBOUND_STOP_IDX}.terminalUuid`,
  });
  const outboundStopServiceDate = useWatch({
    control,
    name: `stops.${OUTBOUND_STOP_IDX}.serviceDate`,
  });
  const outboundStopApptStart = useWatch({
    control,
    name: `stops.${OUTBOUND_STOP_IDX}.appointmentTime`,
  });
  const outboundStopApptEnd = useWatch({
    control,
    name: `stops.${OUTBOUND_STOP_IDX}.endAppointmentTime`,
  });

  const status = useWatch({
    control,
    name: 'status',
  });
  const { lanes, allLanes } = useLineHaulLanes({
    includeInactiveTerminals: false,
  });

  const { editAccess, disabledIfFinalizedOrLater } = useOrderFormEditAccess();

  const ffLineHaulNetworks = useFeatureFlag(FeatureFlag.FF_LINE_HAUL_NETWORKS);

  const [
    getLineHaulProgress,
    { data: progressData, loading: progressLoading },
  ] = useGetLineHaulProgressLazyQuery();
  const ffCourierv1 = useFeatureFlag(FeatureFlag.FF_COURIER_V1);
  const ffTariffChainsEnabled = useFeatureFlag(
    FeatureFlag.FF_TARIFF_CHAINS_ENABLED,
  );

  const showVehicleTypeAutocomplete = ffCourierv1 || ffTariffChainsEnabled;

  useEffect(() => {
    if (!isNil(vehicleTypeUuid)) {
      getVehicleTypeCapacity({
        variables: { vehicleTypeUuid },
      });
    }
  }, [vehicleTypeUuid, getVehicleTypeCapacity]);

  useEffect(() => {
    if (!isNil(orderUuid)) {
      getLineHaulProgress({
        variables: {
          getLineHaulProgressInput: {
            uuid: orderUuid,
          },
        },
      });
    }
  }, [orderUuid, getLineHaulProgress]);

  // Disallow line haul if the inbound and outbound stop terminals are the same
  useEffect(() => {
    if (
      !isNil(inboundStopTerminalUuid) &&
      !isNil(outboundStopTerminalUuid) &&
      inboundStopTerminalUuid === outboundStopTerminalUuid
    ) {
      setValue('isUsingLineHaul', false);
      setValue('lineHaulLaneUuid', null);
      setValue('enableLineHaul', false);
    }
  }, [inboundStopTerminalUuid, outboundStopTerminalUuid, setValue]);

  const removeLaneFromOrder = () => {
    setValue('lineHaulLaneUuid', null);
    setValue('isUsingLineHaul', false);
  };
  const handleSelectLaneCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (useLineHaulNetworks) {
      setValue('isUsingLineHaul', false);
      setValue('lineHaulLaneUuid', null);
      setValue('enableLineHaul', e.target.checked);
    } else if (e.target.checked) {
      setValue('isUsingLineHaul', true);
    } else {
      removeLaneFromOrder();
    }
  };

  const validLanes = useMemo(() => {
    return lanes
      ?.filter((l) =>
        isNil(inboundStopTerminalUuid)
          ? true
          : inboundStopTerminalUuid === l.originTerminal.uuid,
      )
      .filter((l) =>
        isNil(outboundStopTerminalUuid)
          ? true
          : outboundStopTerminalUuid === l.destinationTerminal.uuid,
      )
      .concat(
        allLanes?.filter((l) => l.uuid === lineHaulLaneUuid && !l.isActive) ??
          [],
      );
  }, [
    inboundStopTerminalUuid,
    outboundStopTerminalUuid,
    allLanes,
    lanes,
    lineHaulLaneUuid,
  ]);

  const laneIsInvalid = useMemo(() => {
    return (
      allLanes?.some((l) => l.uuid === lineHaulLaneUuid && !l.isActive) ?? false
    );
  }, [lineHaulLaneUuid, allLanes]);

  const linehaulSegmentOnManifest =
    progressData?.getLineHaulProgress.terminalStatuses.some(
      (terminalStatus) => {
        return (
          !isNil(terminalStatus.manifestDepartingFromThisTerminal) ||
          !isNil(terminalStatus.markedArrivedAt)
        );
      },
    );

  const orderOnLineHaulManifest =
    progressData?.getLineHaulProgress.terminalStatuses.some(
      (terminalStatus) => {
        return !isNil(terminalStatus.manifestDepartingFromThisTerminal);
      },
    );

  const useLineHaulNetworks =
    ffLineHaulNetworks && linehaulSegmentOnManifest === false;

  const laneOptions =
    validLanes
      ?.map((lane) => ({
        value: lane.uuid,
        label: lane.label,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const customFilterForLaneAutocomplete = (
    option: { value: string; label: string },
    { inputValue }: { inputValue: string },
  ) => {
    // Custom matching logic: remove hyphens from both the input and option
    const sanitizedInput = inputValue.replaceAll('-', '');
    const sanitizedOption = option.label.replaceAll('-', '');

    // Use a case-insensitive comparison
    return sanitizedOption.toLowerCase().includes(sanitizedInput.toLowerCase());
  };

  const deriveLaneFromTerminals = async () => {
    if (isNil(validLanes) || laneIsInvalid) {
      return;
    }
    if (
      inboundStopType === StopType.None ||
      outboundStopType === StopType.None
    ) {
      removeLaneFromOrder();
      return;
    }

    if (!isNil(inboundStopTerminalUuid) && !isNil(outboundStopTerminalUuid)) {
      const matchingLane = validLanes.find(
        (lane) =>
          lane.originTerminal.uuid === inboundStopTerminalUuid &&
          lane.destinationTerminal.uuid === outboundStopTerminalUuid,
      );

      if (!isNil(matchingLane) && isUsingLineHaul) {
        // if the current lane uuid is not null and the auto-selected lane differs
        // set the lane to the auto-selected one and check the box.
        const newLaneUuid = matchingLane.uuid;
        setValue('lineHaulLaneUuid', newLaneUuid);
      } else if (validLanes.length === 0) {
        removeLaneFromOrder();
      }
    } else if (validLanes.length === 0) {
      removeLaneFromOrder();
    }
    // Removing logic to auto-select first lane
    // else {
    //   const currentLaneValid = validLanes.find(
    //     (l) => l.uuid === lineHaulLaneUuid,
    //   );
    //   if (isNil(currentLaneValid) && isUsingLineHaul) {
    //     setValue('lineHaulLaneUuid', validLanes.at(0)?.uuid ?? null);
    //   }
    // }
  };

  useEffect(() => {
    deriveLaneFromTerminals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inboundStopTerminalUuid,
    outboundStopTerminalUuid,
    inboundStopType,
    outboundStopType,
  ]);

  useEffect(() => {
    if (!isNil(isUsingLineHaul)) {
      setShowLineHaulCheckbox(true);
      deriveLaneFromTerminals();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUsingLineHaul, inboundStopType, outboundStopType]);

  // A warning message is shown in the customer portal if the availability dates/times do not cover the service date
  // or appointment times of a stop.
  const serviceAvailabilityWarningMessage = useMemo<string | null>(() => {
    if (!isCustomerPortal || isNil(serviceUuid)) {
      return null;
    }
    const service = services.find(({ uuid }) => uuid === serviceUuid);
    if (isNil(service)) {
      return null;
    }

    const stopsNotCovered: string[] = [];

    if (
      inboundStopType !== StopType.None &&
      !isNil(inboundStopServiceDate) &&
      !dateAndTimeIsWithinAvailability({
        date: inboundStopServiceDate,
        startTime: inboundStopApptStart,
        endTime: inboundStopApptEnd,
        availabilities: service.serviceAvailabilityDates,
      })
    ) {
      stopsNotCovered.push(sentenceCase(inboundStopType).toLowerCase());
    }

    if (
      outboundStopType !== StopType.None &&
      !isNil(outboundStopServiceDate) &&
      !dateAndTimeIsWithinAvailability({
        date: outboundStopServiceDate,
        startTime: outboundStopApptStart,
        endTime: outboundStopApptEnd,
        availabilities: service.serviceAvailabilityDates,
      })
    ) {
      stopsNotCovered.push(sentenceCase(outboundStopType).toLowerCase());
    }

    if (stopsNotCovered.length === 0) {
      return null;
    }

    return `The availability for this service does not cover the service date or time for the ${stopsNotCovered.join(' and ')} ${pluralize('stop', stopsNotCovered.length)}.`;
  }, [
    serviceUuid,
    services,
    inboundStopServiceDate,
    inboundStopApptStart,
    inboundStopApptEnd,
    inboundStopType,
    outboundStopServiceDate,
    outboundStopApptStart,
    outboundStopApptEnd,
    outboundStopType,
  ]);

  const vehicleTypeCapacityWarning =
    !isNil(vehicleTypeCapacityData) && !isNil(packageSpecsData)
      ? vehicleTypeCapacityWarningMessage({
          vehicleTypeCapacity: vehicleTypeCapacityData.vehicleType,
          packageSpecs: packageSpecsData.packageSpecs.packageSpecs,
          packages,
          useKilograms: useKilograms ?? false,
          useCentimeters: useCentimeters ?? false,
        })
      : null;

  const { updateShipmentToMatchExpected } = useUpdateShipmentToMatchExpected();

  /* Update all shipments *except* line haul to match the expected shipment type */
  /* (Changing FulfillmentType has no effect on line haul shipments) */
  const onUpdateFulfillmentType = () => {
    updateShipmentToMatchExpected({
      shipmentType: ShipmentType.Regular,
      stopIdx: INBOUND_STOP_IDX,
    });
    updateShipmentToMatchExpected({
      shipmentType: ShipmentType.Regular,
      stopIdx: OUTBOUND_STOP_IDX,
    });
    updateShipmentToMatchExpected({
      shipmentType: ShipmentType.OrderCharges,
    });
  };

  return (
    <Stack
      flexGrow={1}
      direction="row"
      alignItems="center"
      gap="14px"
      flexWrap="wrap"
    >
      <FormControl
        sx={{
          flexBasis: '300px',
          minWidth: '180px',
        }}
      >
        <ServiceAutocomplete
          label="Service Level"
          deriveLaneFromTerminals={deriveLaneFromTerminals}
          isEditMode={isEditMode}
          contact={contact}
        />
        {!isNil(errors.serviceUuid?.message) && (
          <FormHelperText sx={{ color: '#D32F2F' }}>
            {errors.serviceUuid?.message?.toString() ?? ''}
          </FormHelperText>
        )}
        {ffCourierv1 && !isNil(serviceAvailabilityWarningMessage) && (
          <FormHelperText sx={{ color: theme.palette.warning.main }}>
            {serviceAvailabilityWarningMessage}
          </FormHelperText>
        )}
      </FormControl>
      {showVehicleTypeAutocomplete && (
        <Controller
          name="vehicleTypeUuid"
          control={control}
          render={({ field }) => (
            <FormControl
              sx={{
                flexBasis: '210px',
                minWidth: '150px',
              }}
            >
              <AutocompleteFuzzy<MinimalVehicleType, false, false, false>
                {...field}
                size="small"
                value={selectedVehicleType ?? null}
                options={vehicleTypeOptions}
                matchSortOptions={{ keys: ['name'] }}
                getOptionLabel={(vt) => vt.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Vehicle Type"
                    sx={{ width: '100%' }}
                    error={
                      !isNil(
                        'vehicleTypeUuid' in errors
                          ? errors.vehicleTypeUuid
                          : null,
                      )
                    }
                  />
                )}
                disabled={disabledIfFinalizedOrLater}
                onChange={(_event, option) => {
                  field.onChange(option?.uuid ?? null);
                }}
              />
              {'vehicleTypeUuid' in errors &&
                !isNil(errors.vehicleTypeUuid?.message) && (
                  <FormHelperText sx={{ color: '#D32F2F' }}>
                    {errors.vehicleTypeUuid?.message?.toString()}
                  </FormHelperText>
                )}
              {ffCourierv1 && !isNil(vehicleTypeCapacityWarning) && (
                <FormHelperText sx={{ color: theme.palette.warning.main }}>
                  {vehicleTypeCapacityWarning}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      )}
      {showFulfillmentOptions && (
        <Controller
          name="fulfillmentType"
          control={control}
          render={({ field }) => (
            <FormControl
              required
              sx={{
                ml: '4px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '13px',
                minWidth: 'max-content',
              }}
            >
              <FormLabel component="legend">Fulfill on</FormLabel>
              <RadioGroup
                value={field}
                aria-disabled={disabledIfFinalizedOrLater}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  onUpdateFulfillmentType();
                }}
              >
                <Stack direction="row">
                  <FormControlLabel
                    checked={fulfillmentType === FulfillmentType.Dedicated}
                    value={FulfillmentType.Dedicated}
                    control={<Radio />}
                    label="the same route"
                    disabled={disabledIfFinalizedOrLater}
                  />
                  <FormControlLabel
                    checked={fulfillmentType === FulfillmentType.MultiTrip}
                    value={FulfillmentType.MultiTrip}
                    control={<Radio />}
                    label="separate routes"
                    disabled={disabledIfFinalizedOrLater}
                    sx={{
                      mr: 0,
                    }}
                  />
                </Stack>
              </RadioGroup>
              {'fulfillmentType' in errors && (
                <FormHelperText sx={{ color: '#D32F2F' }}>
                  {errors.fulfillmentType?.message?.toString()}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      )}
      {!isNil(validLanes) &&
        !isNil(companyConfiguration) &&
        companyConfiguration.lineHaulEnabled &&
        (progressLoading ? (
          <CircularProgress size={20} />
        ) : (
          <>
            {(ffLineHaulNetworks || showLineHaulCheckbox) && (
              <Tooltip
                title={lineHaulTooltipMessage({
                  editAccess,
                  status,
                  inboundStopType,
                  outboundStopType,
                  inboundStopTerminalUuid,
                  outboundStopTerminalUuid,
                })}
                disableHoverListener={
                  (useLineHaulNetworks || validLanes.length > 0) &&
                  inboundStopType !== StopType.None &&
                  outboundStopType !== StopType.None &&
                  editAccess !== OrderFormEditAccess.FinalizedOrOnInvoice &&
                  editAccess !== OrderFormEditAccess.InvoicePosted &&
                  status !== OrderStatus.Finalized &&
                  status !== OrderStatus.Invoiced &&
                  inboundStopTerminalUuid !== outboundStopTerminalUuid
                }
                placement="top"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  ml="-7px"
                  minWidth="max-content"
                >
                  <Checkbox
                    inputProps={{
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      'data-testid':
                        ORDER_PAGE_TRANSPORT_ON_LINE_HAUL_CHECKBOX_TEST_ID,
                      id: 'service-is-using-line-haul',
                    }}
                    checked={
                      useLineHaulNetworks ? enableLineHaul : isUsingLineHaul
                    }
                    disabled={
                      inboundStopType === StopType.None ||
                      outboundStopType === StopType.None ||
                      (!useLineHaulNetworks && validLanes.length === 0) ||
                      disabledIfFinalizedOrLater ||
                      status === OrderStatus.Finalized ||
                      status === OrderStatus.Invoiced ||
                      (!useLineHaulNetworks &&
                        laneIsInvalid &&
                        linehaulSegmentOnManifest === true) ||
                      (useLineHaulNetworks &&
                        orderOnLineHaulManifest === true) ||
                      (inboundStopTerminalUuid === outboundStopTerminalUuid &&
                        !isNil(inboundStopTerminalUuid) &&
                        !isNil(outboundStopTerminalUuid))
                    }
                    onChange={handleSelectLaneCheckbox}
                  />
                  <FormLabel
                    htmlFor="service-is-using-line-haul"
                    sx={{
                      color:
                        inboundStopType === StopType.None ||
                        outboundStopType === StopType.None ||
                        (!useLineHaulNetworks && validLanes.length === 0) ||
                        disabledIfFinalizedOrLater ||
                        status === OrderStatus.Finalized ||
                        status === OrderStatus.Invoiced ||
                        (inboundStopTerminalUuid === outboundStopTerminalUuid &&
                          !isNil(inboundStopTerminalUuid) &&
                          !isNil(outboundStopTerminalUuid))
                          ? 'grey'
                          : 'black',
                    }}
                  >
                    Line haul
                  </FormLabel>
                </Stack>
              </Tooltip>
            )}
            {!useLineHaulNetworks && isUsingLineHaul && (
              <FormControl
                sx={{
                  minWidth: '145px',
                  flexBasis: '155px',
                  flexShrink: 2,
                }}
              >
                <AutocompleteFuzzy
                  size="small"
                  disabled={
                    disabledIfFinalizedOrLater ||
                    status === OrderStatus.Finalized ||
                    status === OrderStatus.Invoiced ||
                    (laneIsInvalid && linehaulSegmentOnManifest)
                  }
                  matchSortOptions={{ keys: ['label'] }}
                  value={
                    isNil(lineHaulLaneUuid)
                      ? null
                      : {
                          value: lineHaulLaneUuid,
                          label:
                            validLanes.find(
                              (lane) => lane.uuid === lineHaulLaneUuid,
                            )?.label ?? '',
                        }
                  }
                  data-testid={ORDER_PAGE_LINE_HAUL_SELECT_TEST_ID}
                  options={laneOptions}
                  getOptionLabel={(option) => option.label}
                  isOptionEqualToValue={(option, value) =>
                    option.value === value.value
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Lane"
                      error={!isNil(errors.lineHaulLaneUuid)}
                      inputProps={{
                        ...params.inputProps,
                        'data-testid': ORDER_PAGE_SERVICE_SELECT_INPUT_TEST_ID,
                      }}
                    />
                  )}
                  filterOptions={(options, { inputValue }) =>
                    options.filter((option) =>
                      customFilterForLaneAutocomplete(option, {
                        inputValue,
                      }),
                    )
                  }
                  onChange={(_event, option) => {
                    clearErrors();
                    setValue(`lineHaulLaneUuid`, option?.value ?? '');
                  }}
                />
                {!isNil(errors.lineHaulLaneUuid?.message) && (
                  <FormHelperText sx={{ color: '#D32F2F' }}>
                    {errors.lineHaulLaneUuid?.message?.toString()}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </>
        ))}
    </Stack>
  );
};

export default React.memo(Service);
