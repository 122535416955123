import { Chip, Stack, TextField } from '@mui/material';
import { sentenceCase } from 'change-case';
import { isNil } from 'lodash';
import React, { useMemo } from 'react';
import {
  ORDER_PAGE_SERVICE_SELECT_INPUT_TEST_ID,
  ORDER_PAGE_SERVICE_SELECT_TEST_ID,
} from '../../../constants';
import {
  type ShallowContactFragment,
  useServicesForOrderQuery,
} from '../../generated/graphql';
import AutocompleteFuzzy from '../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import useServices from '../react-hooks/use-services';

const MINIMIZED_FONT_SIZE = 12;

const ServiceLevelAutocompleteComponent = ({
  contactUuid,
  isMinimized = false,
  selectedServiceUuid,
  onChange,
  disableClearable = false,
  width,
  disabled,
  error,
  label,
  required,
}: {
  readonly contactUuid: string | undefined;
  readonly isMinimized?: boolean;
  readonly selectedServiceUuid: string | null;
  readonly onChange: (
    service: ShallowContactFragment['services'][0] | null,
  ) => void;
  readonly disableClearable?: boolean;
  readonly width?: number;
  readonly disabled?: boolean;
  readonly error?: boolean;
  readonly label?: string;
  readonly required?: boolean;
}) => {
  const { data: servicesForOrderData } = useServicesForOrderQuery(
    isNil(contactUuid)
      ? {
          skip: true,
        }
      : {
          variables: {
            findServicesForOrderInput: {
              selectedServiceUuid,
              organizationUuid: contactUuid,
            },
          },
        },
  );

  // Memoize the service levels calculation, it is possible that the contact is not available
  // in which case we will use the services from the company
  const serviceLevels = useMemo(() => {
    return servicesForOrderData?.servicesForOrder?.__typename ===
      'FindServicesForOrderSuccessOutput'
      ? servicesForOrderData?.servicesForOrder.services
      : [];
  }, [servicesForOrderData]);

  const isServiceArchived = (serviceUuid: string) => {
    const service = serviceLevels?.find(
      (service) => service.uuid === serviceUuid,
    );
    return !isNil(service) && !service.isActive;
  };

  const serviceLevelOptions =
    serviceLevels
      ?.map((service) => ({
        value: service.uuid,
        label: sentenceCase(service.name),
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  return (
    <AutocompleteFuzzy
      size="small"
      disabled={disabled}
      disableClearable={disableClearable}
      data-testid={ORDER_PAGE_SERVICE_SELECT_TEST_ID}
      value={
        isNil(selectedServiceUuid)
          ? null
          : {
              value: selectedServiceUuid,
              label:
                serviceLevelOptions.find(
                  (service) => service.value === selectedServiceUuid,
                )?.label ?? '',
            }
      }
      sx={{ width }}
      matchSortOptions={{ keys: ['label'] }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      options={serviceLevelOptions}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField
          className={isMinimized ? 'minimized-autocomplete' : undefined}
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            'data-testid': ORDER_PAGE_SERVICE_SELECT_INPUT_TEST_ID,
          }}
          required={required}
          error={error}
        />
      )}
      ListboxProps={isMinimized ? { style: { maxHeight: 300 } } : {}}
      renderOption={(props, option) => (
        <li
          {...props}
          key={option.value}
          style={
            isMinimized
              ? {
                  fontSize: MINIMIZED_FONT_SIZE,
                  paddingLeft: 10,
                  paddingRight: 10,
                }
              : {}
          }
        >
          <Stack
            direction="row"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            {option.label}
            {isServiceArchived(option.value) && (
              <Chip label="Archived" size="small" />
            )}
          </Stack>
        </li>
      )}
      onChange={(_event, option) => {
        const serviceLevel = serviceLevels?.find(
          (s) => s.uuid === option?.value,
        );
        onChange(serviceLevel ?? null);
      }}
    />
  );
};

export const ServiceLevelAutocomplete = React.memo(
  ServiceLevelAutocompleteComponent,
);
