import { Card, Stack, Table, Typography, useTheme } from '@mui/material';
import { isNil } from 'lodash';
import { safeMultiply } from 'shared/math';
import ErrorBanner from '../../../../../common/components/error-banner';
import {
  OrderConsolidationMethod,
  type PreviewProratedConsolidationV2Output,
  type ProratedOrderV2Fragment,
  type ProratedShipmentV2Fragment,
} from '../../../../../generated/graphql';
import {
  TableCellCustom,
  TableHeadCustom,
  TableHeaderTypography,
  TableRowCustom,
  TableTypography,
} from './common';
import { exhaustive } from 'shared/switch';

const convertConsolidationMethodToText = (
  consolidationMethod: OrderConsolidationMethod,
  order: ProratedOrderV2Fragment,
  totalOrderCount: number,
  totalWeight: number,
) => {
  switch (consolidationMethod) {
    case OrderConsolidationMethod.ByWeight: {
      return `${order.weight} of ${totalWeight} lbs`;
    }
    case OrderConsolidationMethod.EvenDistribution: {
      return `1 of ${totalOrderCount} orders`;
    }
    case OrderConsolidationMethod.BaseOrder: {
      return 'All charges on base order';
    }
    default: {
      return exhaustive(consolidationMethod);
    }
  }
};
const BreakdownOfChargesAfterProrate = ({
  inboundShipmentInfo,
  outboundShipmentInfo,
  order,
  consolidationMethod,
  totalOrderCount,
  totalWeight,
}: {
  readonly inboundShipmentInfo: ProratedShipmentV2Fragment | null;
  readonly outboundShipmentInfo: ProratedShipmentV2Fragment | null;
  readonly order: ProratedOrderV2Fragment;
  readonly consolidationMethod: OrderConsolidationMethod;
  readonly totalOrderCount: number;
  readonly totalWeight: number;
}) => {
  const theme = useTheme();

  return (
    <Stack bgcolor={theme.palette.concreteGrey[10]}>
      <Table>
        {isNil(inboundShipmentInfo) && isNil(outboundShipmentInfo) ? (
          <TableCellCustom sx={{ width: '100%' }}>
            <TableTypography isGrey align="center">
              Order is missing shipment information
            </TableTypography>
          </TableCellCustom>
        ) : (
          <TableRowCustom addBorderTop>
            <TableCellCustom cellPadding={6} sx={{ width: '50%' }}>
              <TableTypography bold>{order.hawb}</TableTypography>
              <TableTypography color={theme.palette.concreteGrey[50]}>
                {order.name}
              </TableTypography>
            </TableCellCustom>
            <TableCellCustom cellPadding={6} sx={{ width: '30%' }}>
              <TableTypography>
                {isNil(order.proportionForConsolidation)
                  ? '-'
                  : `${safeMultiply(order.proportionForConsolidation, 100)}%`}
              </TableTypography>
              <TableTypography color={theme.palette.concreteGrey[50]}>
                {convertConsolidationMethodToText(
                  consolidationMethod,
                  order,
                  totalOrderCount,
                  totalWeight,
                )}
              </TableTypography>
            </TableCellCustom>
            <TableCellCustom
              cellPadding={6}
              sx={{ width: '20%', textAlign: 'right' }}
            >
              ${order.totalAfterProrating}
            </TableCellCustom>
          </TableRowCustom>
        )}
      </Table>
    </Stack>
  );
};

const ProratedOrderDetails = ({
  consolidationOutput,
}: {
  readonly consolidationOutput:
    | PreviewProratedConsolidationV2Output
    | undefined;
}) => {
  const theme = useTheme();

  if (isNil(consolidationOutput?.__typename)) {
    return null;
  }
  if (consolidationOutput.__typename === 'QueryErrorOutput') {
    return <ErrorBanner errorMessage={consolidationOutput.message} />;
  }
  if (
    consolidationOutput.__typename ===
    'PreviewProratedConsolidationV2SuccessOutput'
  ) {
    const { proratedOrders, totalAfterProrating } = consolidationOutput;

    return (
      <Card sx={{ height: '70vh', overflow: 'scroll' }}>
        <Stack bgcolor={theme.palette.concreteGrey[20]} height="100%">
          <Stack
            px={2}
            py={1.5}
            borderBottom={`1px solid ${theme.palette.concreteGrey[30]}`}
          >
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h6" fontSize={16}>
                Review consolidated orders
              </Typography>
            </Stack>
          </Stack>
          <Stack flexGrow={1} height="100%" mt={1.5}>
            <Stack marginX={1} bgcolor={theme.palette.concreteGrey[20]}>
              <Table>
                <TableHeadCustom>
                  <TableCellCustom sx={{ width: '50%' }}>
                    <TableHeaderTypography sidePadding={4}>
                      Order
                    </TableHeaderTypography>
                  </TableCellCustom>
                  <TableCellCustom sx={{ width: '30%' }}>
                    <TableHeaderTypography>
                      Distribution rate
                    </TableHeaderTypography>
                  </TableCellCustom>
                  <TableCellCustom sx={{ width: '20%', textAlign: 'right' }}>
                    <TableHeaderTypography>Amount</TableHeaderTypography>
                  </TableCellCustom>
                </TableHeadCustom>
              </Table>
              {proratedOrders?.map((proratedOrder) => {
                return (
                  <BreakdownOfChargesAfterProrate
                    key={proratedOrder.uuid}
                    inboundShipmentInfo={proratedOrder.inboundShipment ?? null}
                    outboundShipmentInfo={
                      proratedOrder.outboundShipment ?? null
                    }
                    order={proratedOrder}
                    consolidationMethod={
                      consolidationOutput.consolidationMethod
                    }
                    totalOrderCount={proratedOrders.length}
                    totalWeight={consolidationOutput.totalWeightConsolidated}
                  />
                );
              })}
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              marginTop={1}
              marginX={1}
              bgcolor={theme.palette.concreteGrey[10]}
              p={1}
              borderRadius={1}
            >
              <Typography fontWeight={600} fontSize={14}>
                Consolidated total:
              </Typography>
              <Typography fontWeight={600} fontSize={14}>
                ${totalAfterProrating.toFixed(2)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    );
  }

  return null;
};

export default ProratedOrderDetails;
