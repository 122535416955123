import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { isNil } from 'lodash';
import { type Theme } from '@mui/material';
import { AgentTenderedJobStatus } from '../../../../../../agents/utils';

dayjs.extend(timezone);
dayjs.extend(utc);

export const getTableBackgroundColor = ({
  theme,
  isAssignedToAgent,
  agentStatus,
}: {
  theme: Theme;
  isAssignedToAgent: boolean;
  agentStatus: AgentTenderedJobStatus;
}): string | undefined => {
  if (!isAssignedToAgent) {
    return theme.palette.grey[100];
  }
  if (agentStatus === AgentTenderedJobStatus.COMPLETED) {
    return theme.palette.airfreightBlue[20];
  }
  return theme.palette.grey[50];
};

/**
 * Formats a stop attempt time to a human readable format
 */
export const formatStopAttemptTime = ({
  completedAt,
  companyTimezone,
}: {
  completedAt: Date | null | undefined;
  companyTimezone: string | null | undefined;
}) => {
  if (isNil(completedAt) || isNil(companyTimezone)) {
    return '';
  }
  return dayjs(completedAt).tz(companyTimezone).format('MM/DD/YYYY hh:mma');
};
