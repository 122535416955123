import {
  Checkbox,
  FormLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { useId } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { getHazmatClassDescription, getHazmatClassNumber } from 'shared/copy';
import { HazmatClass } from '../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { type CustomerPortalOrderFormValues } from './forms/types';

const HAZMAT_CLASS_OPTIONS = Object.values(HazmatClass).map((hazmatClass) => ({
  value: hazmatClass,
  label: `${getHazmatClassNumber(hazmatClass)}`,
  description: `${getHazmatClassDescription(hazmatClass)}`,
}));

type CustomerPortalHandlingFieldProps = {
  readonly disabled: boolean;
};

const CustomerPortalHandlingField = ({
  disabled,
}: CustomerPortalHandlingFieldProps) => {
  const { control, setValue } = useFormContext<CustomerPortalOrderFormValues>();

  const inBondCheckboxId = useId();
  const hazmatCheckboxId = useId();

  const inBondInformation = useWatch({
    control,
    name: 'inBondInformation',
  });

  const hazmatInformation = useWatch({
    control,
    name: 'hazmatInformation',
  });

  const handleInBondToggle = (checked: boolean) => {
    setValue(
      'inBondInformation',
      checked ? { itTeNumber: undefined } : undefined,
    );
  };

  const handleHazmatToggle = (checked: boolean) => {
    setValue(
      'hazmatInformation',
      checked
        ? {
            // There's no meaningful default value we could use for hazmatClass
            hazmatClass: undefined as unknown as HazmatClass,
            hazmatDescription: undefined,
          }
        : undefined,
    );
  };

  return (
    <Stack bgcolor="white" p={2} gap={2}>
      <Typography variant="h6" fontSize="16px">
        Handling
      </Typography>
      <Stack direction="row" gap={1}>
        <Stack direction="row" gap={1} alignItems="start" flex={1}>
          <Checkbox
            id={inBondCheckboxId}
            checked={!isNil(inBondInformation)}
            disabled={disabled}
            onChange={(e) => {
              handleInBondToggle(e.target.checked);
            }}
          />
          <Stack gap={2} flexGrow={1}>
            <FormLabel
              htmlFor={inBondCheckboxId}
              sx={{ cursor: disabled ? 'default' : 'pointer' }}
            >
              <Typography>In bond</Typography>
              <Typography variant="caption" color="text.secondary">
                International freight requiring customs clearance
              </Typography>
            </FormLabel>
            {!isNil(inBondInformation) && (
              <Stack gap={1}>
                <Controller
                  name="inBondInformation.itTeNumber"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="IT / TE #"
                      size="small"
                      {...field}
                      error={!isNil(error)}
                      helperText={error?.message}
                      disabled={disabled}
                    />
                  )}
                />
                <Typography variant="caption" color="text.secondary">
                  Please also upload your customs documentation
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack direction="row" gap={1} alignItems="start" flex={1}>
          <Checkbox
            id={hazmatCheckboxId}
            checked={!isNil(hazmatInformation)}
            disabled={disabled}
            onChange={(e) => {
              handleHazmatToggle(e.target.checked);
            }}
          />
          <Stack gap={2} flexGrow={1}>
            <FormLabel
              htmlFor={hazmatCheckboxId}
              sx={{ cursor: disabled ? 'default' : 'pointer' }}
            >
              <Typography>Hazmat</Typography>
              <Typography variant="caption" color="text.secondary">
                Order contains hazardous materials
              </Typography>
            </FormLabel>
            {!isNil(hazmatInformation) && (
              <Stack gap={1}>
                <Controller
                  name="hazmatInformation.hazmatClass"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <AutocompleteFuzzy
                      {...field}
                      disableClearable
                      size="small"
                      value={
                        isNil(field.value)
                          ? undefined
                          : HAZMAT_CLASS_OPTIONS.find(
                              (option) => option.value === field.value,
                            )
                      }
                      options={HAZMAT_CLASS_OPTIONS}
                      matchSortOptions={{ keys: ['label', 'description'] }}
                      getOptionLabel={(option) =>
                        `${option.label} - ${option.description}`
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Stack>
                            <Typography>{option.label}</Typography>
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {option.description}
                            </Typography>
                          </Stack>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label="Hazmat class"
                          error={!isNil(error)}
                          helperText={error?.message}
                        />
                      )}
                      disabled={disabled}
                      onChange={(_event, option) => {
                        field.onChange(option?.value ?? null);
                      }}
                    />
                  )}
                />
                <Controller
                  name="hazmatInformation.hazmatDescription"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      multiline
                      label="Description"
                      size="small"
                      {...field}
                      error={!isNil(error)}
                      helperText={error?.message}
                      disabled={disabled}
                      placeholder="Material, shape, contents, etc."
                    />
                  )}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CustomerPortalHandlingField;
