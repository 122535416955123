import {
  Box,
  Button,
  IconButton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { isFunction } from 'lodash';
import React from 'react';
import type { MouseEventHandler, ReactNode } from 'react';
import theme from '../../theme';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

/* -------------------------------------------------------------------------------
Bulk Actions Count
------------------------------------------------------------------------------- */

const BULK_ACTIONS_COUNT_WIDTH = '28px';

const CountContainer = styled(Box)({
  backgroundColor: theme.palette.airfreightBlue['60'],
  aspectRatio: '1/1',
  borderRadius: '6px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: 'inherit',
  width: BULK_ACTIONS_COUNT_WIDTH,
  fontSize: '14px',
});

const MAX_DISPLAY_COUNT = 99;

const countToText = (count: number): string => {
  if (count > MAX_DISPLAY_COUNT) {
    return `${MAX_DISPLAY_COUNT}+`;
  }
  return count.toString();
};

type BulkActionsCountProps = {
  readonly count: number;
};
const BulkActionsCount = ({ count }: BulkActionsCountProps) => (
  <Stack
    direction="row"
    alignItems="center"
    gap={1}
    color="white"
    px={1}
    borderRight="1px solid #FDFDFD1F"
    fontWeight="500"
    sx={{ userSelect: 'none' }}
  >
    <CountContainer>{countToText(count)}</CountContainer>
    <Typography fontWeight="inherit">Selected</Typography>
  </Stack>
);

/* -------------------------------------------------------------------------------
Bulk Actions Option
------------------------------------------------------------------------------- */

type BulkActionsOptionColor = 'white' | 'red';

const BulkActionsOptionColors = {
  white: {
    default: 'white',
    hover: 'rgba(255, 255, 255, 0.8)',
    active: 'rgba(255, 255, 255, 0.6)',
    disabled: 'rgba(255, 255, 255, 0.38)',
  },
  red: {
    default: theme.palette.errorRed[60],
    hover: theme.palette.errorRed[50],
    active: theme.palette.errorRed[70],
    disabled: 'rgba(255, 99, 97, 0.38)',
  },
} as const;

type BulkActionsOptionProps = {
  readonly icon: ReactNode;
  readonly label: string;
  readonly onClick: MouseEventHandler<HTMLButtonElement>;
  readonly color?: BulkActionsOptionColor;
  readonly disabled?: boolean;
};

const BulkActionsOption = ({
  icon,
  label,
  onClick,
  color = 'white',
  disabled = false,
}: BulkActionsOptionProps) => (
  <Button
    disabled={disabled}
    sx={{
      '&:hover': {
        backgroundColor: 'transparent',
        '& .MuiTypography-root, & .MuiBox-root': {
          color: disabled
            ? BulkActionsOptionColors[color].disabled
            : BulkActionsOptionColors[color].hover,
        },
      },
      '&:active': {
        backgroundColor: 'transparent',
        '& .MuiTypography-root, & .MuiBox-root': {
          color: disabled
            ? BulkActionsOptionColors[color].disabled
            : BulkActionsOptionColors[color].active,
        },
      },
      '&.Mui-disabled': {
        backgroundColor: 'transparent',
      },
    }}
    onClick={onClick}
  >
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      color={
        disabled
          ? BulkActionsOptionColors[color].disabled
          : BulkActionsOptionColors[color].default
      }
    >
      <Box display="flex" justifyContent="center" alignItems="center">
        {icon}
      </Box>
      <Typography fontWeight="500" whiteSpace="nowrap">
        {label}
      </Typography>
    </Stack>
  </Button>
);

/* -------------------------------------------------------------------------------
Bulk Actions Close
------------------------------------------------------------------------------- */

type BulkActionsCloseProps = {
  readonly onClick: MouseEventHandler<HTMLButtonElement>;
};
const BulkActionsClose = ({ onClick }: BulkActionsCloseProps) => (
  <Box borderLeft="1px solid #FDFDFD1F" alignContent="center" color="white">
    <IconButton
      sx={{
        color: 'white',
        '&:hover': {
          backgroundColor: 'transparent',
          color: 'rgba(255, 255, 255, 0.8)',
        },
        '&:active': {
          backgroundColor: 'transparent',
          color: 'rgba(255, 255, 255, 0.6)',
        },
      }}
      onClick={onClick}
    >
      <CloseRoundedIcon />
    </IconButton>
  </Box>
);

/* -------------------------------------------------------------------------------
Bulk Actions Container
------------------------------------------------------------------------------- */

const BULK_ACTIONS_CONTAINER_HEIGHT = '44px';
const BULK_ACTIONS_DIVIDER_HEIGHT = '24px';

type BulkActionsContainerProps = {
  readonly children: ReactNode;
};
const BulkActionsContainer = ({ children }: BulkActionsContainerProps) => {
  let count: ReactNode = null;
  const options: ReactNode[] = [];
  let close: ReactNode = null;

  React.Children.forEach(children, (child) => {
    if (!React.isValidElement(child) || !isFunction(child.type)) return;
    const { type } = child;

    switch (type.name) {
      case BulkActionsCount.name: {
        count = child;
        break;
      }
      case BulkActionsOption.name: {
        options.push(child);
        break;
      }
      case BulkActionsClose.name: {
        close = child;
        break;
      }
      default: {
        // eslint-disable-next-line no-console
        console.warn(
          `Unknown child type for BulkActionsContainer: ${type.name}`,
        );
        break;
      }
    }
  });

  return (
    <Stack
      direction="row"
      borderRadius={3}
      style={{ backgroundColor: theme.palette.airfreightBlue['90'] }}
      height={BULK_ACTIONS_CONTAINER_HEIGHT}
    >
      {count}
      {options.length > 0 && (
        <Stack direction="row" px={1} gap={0.5}>
          {options.map((option, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={index}>
              {index > 0 && (
                <Box
                  borderLeft="1px solid #FDFDFD1F"
                  alignSelf="center"
                  height={BULK_ACTIONS_DIVIDER_HEIGHT}
                />
              )}
              {option}
            </React.Fragment>
          ))}
        </Stack>
      )}
      {close}
    </Stack>
  );
};

type BulkActionsPopoverProps = {
  readonly children: ReactNode;
  readonly bottomOffset?: string | number;
};
const BulkActionsPopover = ({
  children,
  bottomOffset = '5%',
}: BulkActionsPopoverProps) => (
  <Box
    position="absolute"
    left="50%"
    bottom={bottomOffset}
    sx={{ transform: 'translateX(-50%)' }}
    width="fit-content"
    zIndex={1}
  >
    {children}
  </Box>
);

const Root = BulkActionsPopover;
const Count = BulkActionsCount;
const Option = BulkActionsOption;
const Container = BulkActionsContainer;
const Close = BulkActionsClose;

export {
  Root,
  Count,
  Option,
  Container,
  Close,
  //
  BulkActionsPopover,
  BulkActionsCount,
  BulkActionsOption,
  BulkActionsContainer,
  BulkActionsClose,
};

export type {
  BulkActionsPopoverProps,
  BulkActionsCountProps,
  BulkActionsOptionProps,
  BulkActionsContainerProps,
};
