import PeopleTabConfig from './people-tab-config';

type OrganizationPeopleTabProps = {
  readonly organizationUuid: string;
};

const PeopleTab = ({ organizationUuid }: OrganizationPeopleTabProps) => {
  return <PeopleTabConfig organizationUuid={organizationUuid} />;
};

export default PeopleTab;
