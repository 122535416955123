import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import fileDownload from 'js-file-download';
import { isEmpty, isNil } from 'lodash';
import React, { type Dispatch, type SetStateAction, useState } from 'react';
import BusinessDivisionFilterButton, {
  type BusinessDivisionOption,
} from '../../../../../common/components/business-division-filter-button';
import CustomerFilterButton from '../../../../../common/components/customer-filter-button';
import DateDropdownPicker, {
  type DateOption,
  defaultPast1WeekDateRangeOption,
} from '../../../../../common/components/date-dropdown-picker';
import DownloadTypeSelection, {
  DownloadType,
} from '../../../../../common/components/download-type-selection';
import ServiceFilterButton from '../../../../../common/components/service-filter-button';
import SingleSelectFilterButton from '../../../../../common/components/single-select-filter-button';
import TerminalFilterButton from '../../../../../common/components/terminal-filter-button';
import { type Option } from '../../../../../common/filters/types';
import {
  OnTimePerformanceReportDetailType,
  useOnTimePerformanceReportCsvLazyQuery,
  useOnTimePerformanceReportPdfLazyQuery,
} from '../../../../../generated/graphql';
import useInvoicesStore from '../../../invoices-store';
import styles from '../../../styles';
import { accountingReportDefaultDateOption } from '../../accounting-reports/constants';
import { useDownloadReport } from '../../../../reports/hooks/use-download-report';

type DownloadInvoiceRegisterReportModalProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const DownloadOnTimePerformanceReportModal = ({
  isOpen,
  setIsOpen,
}: DownloadInvoiceRegisterReportModalProps) => {
  const [dateOption, setDateOption] = useState<DateOption>(
    defaultPast1WeekDateRangeOption,
  );
  const [customerOptions, setCustomerOptions] = useState<
    Option[] | undefined
  >();
  const [terminalOptions, setTerminalOptions] = useState<
    Option[] | undefined
  >();
  const [serviceOptions, setServiceOptions] = useState<Option[] | undefined>();
  const [businessDivisionOption, setBusinessDivisionOption] = useState<
    BusinessDivisionOption | undefined
  >();
  const [reportDetailType, setReportDetailType] = useState<
    OnTimePerformanceReportDetailType | undefined
  >(OnTimePerformanceReportDetailType.Detailed);
  const [downloadType, setDownloadType] = useState<DownloadType>(
    DownloadType.PDF,
  );

  const createFileDownload = useInvoicesStore(
    (state) => state.createFileDownload,
  );

  const [getOnTimePerformanceReportCsv] =
    useOnTimePerformanceReportCsvLazyQuery();
  const [getOnTimePerformanceReportPdf] =
    useOnTimePerformanceReportPdfLazyQuery();

  const resetFilters = () => {
    setCustomerOptions(undefined);
    setDateOption(accountingReportDefaultDateOption);
  };

  const input = {
    customerUuids: customerOptions?.map((option) => option.value),
    terminalUuids: terminalOptions?.map((option) => option.value),
    serviceUuids: serviceOptions?.map((option) => option.value),
    businessDivisionUuids: isNil(businessDivisionOption)
      ? undefined
      : [businessDivisionOption.value],
    stopStartDate: dateOption.startDate,
    stopEndDate: dateOption.endDate,
    reportDetailType: null,
  };

  const startDownload = async () => {
    setIsOpen(false);
    const completeDownload = createFileDownload();
    let presignedGetUrl = null;
    let fileName = null;
    let errors = null;

    try {
      if (downloadType === DownloadType.PDF) {
        const pdfRes = await getOnTimePerformanceReportPdf({
          variables: {
            input,
          },
        });
        presignedGetUrl = pdfRes.data?.onTimePerformanceReportPdf.url;
        fileName = pdfRes.data?.onTimePerformanceReportPdf.fileName;
        errors = pdfRes.data?.onTimePerformanceReportPdf.errors;
      } else {
        const csvRes = await getOnTimePerformanceReportCsv({
          variables: {
            input,
          },
        });
        presignedGetUrl = csvRes.data?.onTimePerformanceReportCsv.url;
        fileName = csvRes.data?.onTimePerformanceReportCsv.fileName;
        errors = csvRes.data?.onTimePerformanceReportCsv.errors;
      }

      if (!isNil(errors) && !isEmpty(errors)) {
        completeDownload({ alertSeverity: 'error', message: errors[0] });
      } else if (!isNil(presignedGetUrl) && !isNil(fileName)) {
        const getFileRes = await fetch(presignedGetUrl, {
          cache: 'no-cache',
        });

        const blob = await getFileRes.blob();

        fileDownload(blob, fileName);
        completeDownload();
      } else {
        completeDownload({
          alertSeverity: 'error',
          message:
            'An error occurred while generating the report, please try again and contact support if the issue persists',
        });
      }
      resetFilters();
    } catch (error) {
      completeDownload({
        alertSeverity: 'error',
        message:
          'An error occurred while generating the report, please try again and contact support if the issue persists',
      });
      // eslint-disable-next-line no-console
      console.error('Error generating on-time performance report', error);
    }
  };

  const startDownloadPdfWithMeasurement = useDownloadReport({
    name: 'On-time performance report PDF',
    rumLabel: 'download-on-time-performance-report-pdf',
    filters: input,
    downloadType,
    downloadReport: startDownload,
    onComplete: resetFilters,
  });

  const startDownloadCsvWithMeasurement = useDownloadReport({
    name: 'On-time performance report CSV',
    rumLabel: 'download-on-time-performance-report-csv',
    filters: input,
    downloadType,
    downloadReport: startDownload,
    onComplete: resetFilters,
  });

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <Box sx={[styles.modal, { height: 'fit-content', width: '650px' }]}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={10}>
            <Typography sx={{ fontWeight: 'bold' }}>
              Download On-Time Performance Report
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              sx={{ float: 'right' }}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="row" gap={1}>
            <DateDropdownPicker
              filterTitle="Stop Service Date"
              dateOption={dateOption}
              setDateOption={setDateOption}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <CustomerFilterButton<false>
              selectedOptionsMultiselect={customerOptions}
              handleChangeMultiselect={(
                options: Option[] | null | undefined,
              ) => {
                setCustomerOptions(options ?? undefined);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TerminalFilterButton
              prefixText="Terminal"
              includeInactiveTerminals={false}
              selectedOptionsMultiselect={terminalOptions}
              handleChangeMultiselect={(
                options: Option[] | null | undefined,
              ) => {
                setTerminalOptions(options ?? undefined);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <ServiceFilterButton
              selectedOptionsMultiselect={serviceOptions}
              handleChangeMultiselect={(
                options: Option[] | null | undefined,
              ) => {
                setServiceOptions(options ?? undefined);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <BusinessDivisionFilterButton
              prefixText="Business division"
              selectedOption={businessDivisionOption}
              handleChange={(option: BusinessDivisionOption | undefined) => {
                setBusinessDivisionOption(option);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{ float: 'left' }}
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={1}
            >
              <DownloadTypeSelection
                labelText="File type"
                cacheId="ON_TIME_PERFORMANCE_REPORT"
                selectedOption={downloadType}
                handleChange={(selectedDownloadType: DownloadType) => {
                  setDownloadType(selectedDownloadType);
                  if (selectedDownloadType !== DownloadType.CSV) {
                    setReportDetailType(undefined);
                  }
                }}
              />
              {downloadType === DownloadType.CSV && (
                <SingleSelectFilterButton
                  option={reportDetailType}
                  handleChange={(
                    selectedDetailType:
                      | OnTimePerformanceReportDetailType
                      | undefined,
                  ) => {
                    setReportDetailType(selectedDetailType);
                  }}
                  options={Object.values(OnTimePerformanceReportDetailType)}
                  enableDefaultFilter={false}
                  filterTitle="Report type:"
                />
              )}
            </Box>
            <Box sx={{ float: 'right' }}>
              <Button
                sx={{ width: '100px' }}
                variant="contained"
                color="info"
                onClick={
                  downloadType === DownloadType.PDF
                    ? startDownloadPdfWithMeasurement
                    : startDownloadCsvWithMeasurement
                }
              >
                Download
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DownloadOnTimePerformanceReportModal;
