import {
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { isNil } from 'lodash';
import {
  Controller,
  get,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { v4 } from 'uuid';
import AddressAutofill from '../../../../common/components/address-autofill';
import { type AddressFormField } from '../../../addresses/redux/addresses-values-slice';
import { useOrderFormEditAccess } from '../../../orders/components/order-form/contexts/order-form-edit-access-context';
import { type StopMethod } from '../../../orders/components/order-form/forms/stop-type';
import { useMemo, useState } from 'react';
import { type CustomerPortalOrderFormValues } from './forms/types';
import { getCustomerPortalOrderFormStopField } from './forms/utils';
import { useTerminalsForThirdPartyUserQuery } from '../../../../generated/graphql';
import { useCustomerPortalOrderFormContext } from './contexts/customer-portal-order-form-context';
import { type Option } from '../../../../common/types';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { BaseTerminalAutocomplete } from '../../../orders/components/order-form/components/terminal-autocomplete';

type CustomerPortalAddressFieldProps = {
  readonly stopMethod: StopMethod;
};

enum AddressType {
  TERMINAL = 'terminal',
  CUSTOM = 'custom',
}

const CustomerPortalAddressField = ({
  stopMethod,
}: CustomerPortalAddressFieldProps) => {
  const { control, setValue, getValues } =
    useFormContext<CustomerPortalOrderFormValues>();
  const { errors } = useFormState<CustomerPortalOrderFormValues>();

  // Track address input type (custom or terminal)
  const [addressType, setAddressType] = useState<AddressType>(
    AddressType.CUSTOM,
  );
  // Track selected terminal when using terminal address type
  const [selectedTerminal, setSelectedTerminal] = useState<Option | null>(null);

  const addressFieldPath =
    `${getCustomerPortalOrderFormStopField(stopMethod)}.address` as const;
  const { disabledIfNoAccess: disabled } = useOrderFormEditAccess();

  const name = useWatch({
    control,
    name: `${addressFieldPath}.name`,
  });
  const line1 = useWatch({
    control,
    name: `${addressFieldPath}.line1`,
  });
  const line2 = useWatch({
    control,
    name: `${addressFieldPath}.line2`,
  });
  const city = useWatch({
    control,
    name: `${addressFieldPath}.city`,
  });
  const state = useWatch({
    control,
    name: `${addressFieldPath}.state`,
  });
  const zipcode = useWatch({
    control,
    name: `${addressFieldPath}.zipcode`,
  });
  const country = useWatch({
    control,
    name: `${addressFieldPath}.country`,
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const addressError: string | undefined = (
    get(errors, addressFieldPath)?.name ??
    get(errors, addressFieldPath)?.line1 ??
    get(errors, addressFieldPath)?.line2 ??
    get(errors, addressFieldPath)?.city ??
    get(errors, addressFieldPath)?.state ??
    get(errors, addressFieldPath)?.zipcode ??
    get(errors, addressFieldPath)?.country
  )?.message;

  const handleAddressChange = ({ address }: { address: AddressFormField }) => {
    setValue(addressFieldPath, {
      ...getValues(addressFieldPath),
      name: address.name ?? '',
      line1: address.line1 ?? '',
      line2: address.line2 ?? '',
      city: address.city ?? '',
      state: address.state ?? '',
      zipcode: address.zip ?? '',
      country: address.country ?? '',
    });
  };

  const currentAddressOption = {
    label: name,
    value: name,
    address: {
      name,
      line1,
      line2,
      city,
      state,
      zip: zipcode,
      country,
      // These are required for the address autocomplete to work
      // but not used for the customer portal :/
      isLocal: true,
      uuid: v4(),
    } satisfies AddressFormField,
  };

  const { companyUuid, terminalsEnabled } = useCustomerPortalOrderFormContext();

  const { data: terminalsData } = useTerminalsForThirdPartyUserQuery(
    terminalsEnabled
      ? {
          variables: {
            findTerminalsForThirdPartyUserInput: {
              isActive: true,
              companyUuid,
            },
          },
          fetchPolicy: 'cache-and-network',
        }
      : { skip: true },
  );
  const terminals = useMemo(
    () => terminalsData?.terminalsForThirdPartyUser ?? [],
    [terminalsData?.terminalsForThirdPartyUser],
  );

  // Handle address type change (custom or terminal)
  const handleAddressTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newAddressType = event.target.value as AddressType;
    // Validate that the value is one of our expected types

    if (
      newAddressType !== AddressType.CUSTOM &&
      newAddressType !== AddressType.TERMINAL
    ) {
      return; // Return early if not a valid address type
    }

    setAddressType(newAddressType);

    if (newAddressType === AddressType.CUSTOM) {
      setSelectedTerminal(null);
      // Clear the address fields
      const emptyAddress: AddressFormField = {
        name: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        isLocal: true,
        uuid: v4(),
      };
      handleAddressChange({ address: emptyAddress });
    }
  };

  // Handle terminal selection
  const handleTerminalChange = (
    _event: React.SyntheticEvent,
    option: Option | null,
  ) => {
    if (isNil(option)) {
      setSelectedTerminal(null);
      return;
    }

    setSelectedTerminal(option);

    // Find the selected terminal
    const terminal = terminals.find((t) => t.uuid === option.value);
    if (!isNil(terminal?.address)) {
      // Autofill address fields with terminal address
      const terminalAddress: AddressFormField = {
        name: terminal.name,
        line1: terminal.address.line1 ?? '',
        line2: terminal.address.line2 ?? '',
        city: terminal.address.city ?? '',
        state: terminal.address.state ?? '',
        zip: terminal.address.zip ?? '',
        country: 'US',
        isLocal: true,
        uuid: v4(),
      };

      handleAddressChange({ address: terminalAddress });
    }
  };

  return (
    <Stack gap={1}>
      {terminalsEnabled && <FormLabel>Address</FormLabel>}
      <Stack gap={2} width="100%" flexDirection="column">
        {/* Address Type Selection - only show if terminals are enabled */}
        {terminalsEnabled && (
          <FormControl component="fieldset">
            <RadioGroup
              row
              value={addressType}
              aria-label="Address type"
              onChange={handleAddressTypeChange}
            >
              <FormControlLabel
                value={AddressType.CUSTOM}
                control={<Radio />}
                label="Street Address"
                disabled={disabled}
              />
              <FormControlLabel
                value={AddressType.TERMINAL}
                control={<Radio />}
                label="Terminal"
                disabled={disabled}
              />
            </RadioGroup>
          </FormControl>
        )}
        {terminalsEnabled && addressType === AddressType.TERMINAL ? (
          <>
            <BaseTerminalAutocomplete
              terminals={terminals}
              terminalUuid={selectedTerminal?.value}
              disabled={disabled}
              error={undefined}
              onChange={handleTerminalChange}
            />
            <Typography variant="body2" color="text.secondary">
              Select a terminal to use its address
            </Typography>
          </>
        ) : (
          <>
            {/* Name Field */}
            <Controller
              name={`${addressFieldPath}.name`}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  fullWidth
                  required
                  label="Name"
                  size="small"
                  value={value ?? ''}
                  error={!isNil(error)}
                  helperText={error?.message}
                  disabled={disabled}
                  aria-label="Address name"
                  onChange={onChange}
                />
              )}
              disabled={disabled}
            />

            {/* Address Autofill */}
            <AddressAutofill
              required
              freeSolo
              newStyling
              handleChange={handleAddressChange}
              error={addressError}
              currentOption={currentAddressOption}
              nameInputValue={name}
              hideLine2={false}
              disabled={disabled}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default CustomerPortalAddressField;
