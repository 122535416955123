import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import { Badge } from '@mui/material';
import { subHours } from 'date-fns';
import { memo, useEffect } from 'react';
import { ONE_MINUTE_IN_MS } from 'shared/constants';
import {
  DateFilterType,
  FilterOperator,
  type InvoiceSendJobBatchesQueryVariables,
  useInvoiceSendJobBatchesQuery,
} from '../../../../../generated/graphql';

const recentInvoiceSendJobBatchesVariables: InvoiceSendJobBatchesQueryVariables =
  {
    first: 1,
    inProgress: true,
    createdAtDateFilters: [
      {
        filterType: DateFilterType.IsAfter,
        filterOperator: FilterOperator.And,
        value: subHours(new Date(), 1),
      },
    ],
  };

const InvoiceSendMenuIconWithCount = () => {
  const {
    data: recentInvoiceSendJobBatches,
    startPolling,
    stopPolling,
  } = useInvoiceSendJobBatchesQuery({
    variables: recentInvoiceSendJobBatchesVariables,
  });

  useEffect(() => {
    startPolling(ONE_MINUTE_IN_MS);

    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  return (
    <Badge
      badgeContent={
        recentInvoiceSendJobBatches?.invoiceSendJobBatches.totalCount ?? 0
      }
      color="info"
      showZero={false}
    >
      <AttachEmailIcon />
    </Badge>
  );
};

export default memo(InvoiceSendMenuIconWithCount);
