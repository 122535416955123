import { isNotNilOrEmptyString } from 'shared/string';
import { exhaustive } from 'shared/switch';
import { LoginStatus } from '../../generated/graphql';
import { type DashboardDrawerItem, type DashboardPage } from './types';

export const getAppBarTitle = ({
  pathname,
  selectedDrawerItem,
  currentOrganizationName,
  pageTitleFromHandle,
}: {
  pathname: string;
  selectedDrawerItem?: DashboardDrawerItem<DashboardPage>;
  currentOrganizationName?: string;
  pageTitleFromHandle: string | undefined;
}) => {
  if (isNotNilOrEmptyString(pageTitleFromHandle)) {
    return pageTitleFromHandle;
  }
  if (isNotNilOrEmptyString(currentOrganizationName)) {
    return `Edit ${currentOrganizationName}`;
  }
  return selectedDrawerItem?.tabs.find((tab) => pathname === tab.url)?.name;
};

export const getLoginStatusCopy = (status: LoginStatus): string => {
  switch (status) {
    case LoginStatus.Success: {
      return 'Success';
    }
    case LoginStatus.InvalidCredentials: {
      return 'Invalid credentials';
    }
    case LoginStatus.Unauthorized: {
      return 'Unauthorized access';
    }
    case LoginStatus.ClientChallenge: {
      return 'Select client';
    }
    case LoginStatus.ThirdPartyUserNoOrganization: {
      return 'No carriers are associated with this account';
    }
    default: {
      return exhaustive(status);
    }
  }
};
