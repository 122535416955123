import { Chip, useTheme } from '@mui/material';
import { green, grey, lightBlue, orange } from '@mui/material/colors';
import { exhaustive } from 'shared/switch';
import {
  type RouteInfoFragment,
  RouteStatus,
} from '../../../../generated/graphql';

const RouteStatusChip = ({
  routeInfo,
  minified,
}: {
  readonly routeInfo: RouteInfoFragment;
  readonly minified?: boolean;
}) => {
  let color;
  let minifiedColor;
  let backgroundColor;
  let fontWeight;
  let label = 'Unknown';
  let numberOfStopsInCurrentState;

  switch (routeInfo.status) {
    case RouteStatus.NotStarted: {
      label = 'NOT STARTED';
      minifiedColor = grey['500'];
      backgroundColor = grey['50'];
      fontWeight = 400;
      numberOfStopsInCurrentState =
        routeInfo.totalStopsCompleted +
        routeInfo.totalStopsCancelled +
        routeInfo.totalStopsFailed;
      break;
    }
    case RouteStatus.InProgress: {
      color = lightBlue['900'];
      minifiedColor = lightBlue['500'];
      backgroundColor = lightBlue['50'];
      label = 'IN PROG.';
      numberOfStopsInCurrentState =
        routeInfo.totalStopsCompleted +
        routeInfo.totalStopsCancelled +
        routeInfo.totalStopsFailed;
      break;
    }
    case RouteStatus.Complete: {
      color = green['900'];
      minifiedColor = green['600'];
      backgroundColor = '#BBF7D0';
      label = 'DONE';
      numberOfStopsInCurrentState =
        routeInfo.totalStopsCompleted +
        routeInfo.totalStopsCancelled +
        routeInfo.totalStopsFailed;
      break;
    }
    case RouteStatus.Incomplete: {
      color = orange['900'];
      minifiedColor = orange['500'];
      backgroundColor = orange['50'];
      label = 'INCOMP.';
      numberOfStopsInCurrentState =
        routeInfo.totalStopsCompleted +
        routeInfo.totalStopsCancelled +
        routeInfo.totalStopsFailed;
      break;
    }
    case RouteStatus.AwaitingSendToAgent: {
      label = 'PENDING - SEND JOBS';
      minifiedColor = grey['500'];
      backgroundColor = grey['50'];
      numberOfStopsInCurrentState = routeInfo.totalStopsSentToAgent;
      break;
    }
    case RouteStatus.AwaitingAgentConfirmation: {
      label = 'PENDING CONFIRMATION';
      minifiedColor = grey['500'];
      backgroundColor = grey['50'];
      numberOfStopsInCurrentState = routeInfo.totalStopsConfirmedByAgent;
      break;
    }
    default: {
      return exhaustive(routeInfo.status);
    }
  }

  label += ` ${numberOfStopsInCurrentState}/${routeInfo.totalStops}`;

  if (minified === true) {
    return (
      <Chip
        size="small"
        sx={{ height: 8, width: 8, backgroundColor: minifiedColor }}
        label=""
      />
    );
  }

  return (
    <Chip
      size="small"
      variant="outlined"
      sx={{
        height: 18,
        width: 'fit-content',
        fontSize: '12px',
        fontWeight: fontWeight ?? '500',
        borderColor: color,
        color,
        backgroundColor,
      }}
      label={label}
    />
  );
};

export default RouteStatusChip;
