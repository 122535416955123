import { Add, RemoveCircleOutline } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import pluralize from 'pluralize';
import { useEffect, useRef, useState, type ReactNode } from 'react';
import PalletButtonGroup from '../../pallet-ui/button-group/pallet-button-group';
import SecondaryButton from '../components/SecondaryButton';
import { EditFilterGroup } from './edit-filter-group';
import {
  type FilterConfig,
  type FilterGroup,
  type FilterGroupOperator,
  type FilterTypeToOperatorMap,
  type NullableFilterGroup,
} from './types-v2';
import {
  filterEmptyFilterConditions,
  isFilterGroup,
  isFilterGroupPartiallyEmpty,
} from './utils-v2';

const POPOVER_CONTENT_WIDTH = '850px';

const TOP_LEVEL_OPERATOR_OPTIONS = [
  { value: 'AND' as const, label: 'All of' },
  { value: 'OR' as const, label: 'At least one of' },
];

enum FilterGroupEditMode {
  Simple = 'SIMPLE',
  Advanced = 'ADVANCED',
}

/** If there are >1 filter groups within the top-level filter group, show the "advanced" UI */
const getFilterGroupEditMode = <
  TFilterField extends string,
  TFilterType extends string,
  TFilterTypeToOperatorMap extends FilterTypeToOperatorMap<TFilterType>,
  TFilterFieldToTypeMap extends Record<TFilterField, TFilterType>,
>(
  topLevelFilterGroup: NullableFilterGroup<
    TFilterField,
    TFilterType,
    TFilterTypeToOperatorMap,
    TFilterFieldToTypeMap
  >,
  supportsNesting: boolean,
) => {
  if (!supportsNesting) {
    return FilterGroupEditMode.Simple;
  }
  const numFilterGroups = topLevelFilterGroup.conditions.length ?? 0;
  return numFilterGroups > 1
    ? FilterGroupEditMode.Advanced
    : FilterGroupEditMode.Simple;
};

const EMPTY_GROUP_FILTER = <
  TFilterField extends string,
  TFilterType extends string,
  TFilterTypeToOperatorMap extends FilterTypeToOperatorMap<TFilterType>,
  TFilterFieldToTypeMap extends Record<TFilterField, TFilterType>,
>(
  defaultEmptyFilterCondition: FilterConfig<
    TFilterField,
    TFilterType,
    TFilterTypeToOperatorMap,
    TFilterFieldToTypeMap
  >['defaultEmptyFilterCondition'],
): NullableFilterGroup<
  TFilterField,
  TFilterType,
  TFilterTypeToOperatorMap,
  TFilterFieldToTypeMap
> => ({
  operator: 'AND' as const,
  conditions: [defaultEmptyFilterCondition],
});

type EditFiltersProps<
  TFilterField extends string,
  TFilterType extends string,
  TFilterTypeToOperatorMap extends FilterTypeToOperatorMap<TFilterType>,
  TFilterFieldToTypeMap extends Record<TFilterField, TFilterType>,
> = {
  readonly filterGroup: FilterGroup<
    TFilterField,
    TFilterType,
    TFilterTypeToOperatorMap,
    TFilterFieldToTypeMap
  >;
  readonly setFilterGroup: (
    filterGroup: FilterGroup<
      TFilterField,
      TFilterType,
      TFilterTypeToOperatorMap,
      TFilterFieldToTypeMap
    >,
  ) => void;
  readonly filterConfig: FilterConfig<
    TFilterField,
    TFilterType,
    TFilterTypeToOperatorMap,
    TFilterFieldToTypeMap
  >;
  readonly onClose: () => void;
};

const EditFilters = <
  TFilterField extends string,
  TFilterType extends string,
  TFilterTypeToOperatorMap extends FilterTypeToOperatorMap<TFilterType>,
  TFilterFieldToTypeMap extends Record<TFilterField, TFilterType>,
>({
  filterGroup: propsFilterGroup,
  setFilterGroup: propsSetFilterGroup,
  filterConfig,
  onClose,
}: EditFiltersProps<
  TFilterField,
  TFilterType,
  TFilterTypeToOperatorMap,
  TFilterFieldToTypeMap
>) => {
  const { defaultEmptyFilterCondition, entityName, supportsNesting } =
    filterConfig;

  // Temporary, nullable state for unapplied filters
  const [tempFilterGroup, setTempFilterGroup] =
    useState<
      NullableFilterGroup<
        TFilterField,
        TFilterType,
        TFilterTypeToOperatorMap,
        TFilterFieldToTypeMap
      >
    >(propsFilterGroup);

  useEffect(() => {
    setTempFilterGroup(propsFilterGroup);
  }, [propsFilterGroup]);

  const setFilterGroupOperator = (newOperator: FilterGroupOperator) => {
    setTempFilterGroup((fg) => ({
      operator: newOperator,
      conditions: fg?.conditions ?? [],
    }));
  };

  const getUpdateNestedFilterGroupHandler =
    (groupIndex: number) =>
    (
      updatedGroup: NullableFilterGroup<
        TFilterField,
        TFilterType,
        TFilterTypeToOperatorMap,
        TFilterFieldToTypeMap
      >,
    ) => {
      setTempFilterGroup((fg) => ({
        operator: fg.operator,
        conditions: fg.conditions.map((condition, i) =>
          i === groupIndex ? updatedGroup : condition,
        ),
      }));
    };

  const onAddFilterGroup = () => {
    setTempFilterGroup((fg) => ({
      operator: fg.operator,
      conditions: [
        ...fg.conditions,
        EMPTY_GROUP_FILTER(defaultEmptyFilterCondition),
      ],
    }));
    // We use a timeout because the scrollHeight is not updated immediately after adding a new group
    setTimeout(() => {
      if (!isNil(filterGroupRef.current)) {
        filterGroupRef.current.scrollTo({
          top: filterGroupRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }
    }, 100);
  };

  const onDeleteFilterGroup = (groupIndex: number) => {
    if (tempFilterGroup.conditions.length === 1) {
      setTempFilterGroup(EMPTY_GROUP_FILTER(defaultEmptyFilterCondition));
    } else {
      setTempFilterGroup((fg) => ({
        operator: fg.operator,
        conditions: fg.conditions.filter((_, i) => i !== groupIndex),
      }));
    }
  };

  /**
   * We allow users to apply completely empty filters (we just remove those empty filters)
   * but we don't want them to lose work if they haven't finished selecting the filter yet.
   */
  const isApplyDisabled = isFilterGroupPartiallyEmpty(tempFilterGroup);

  const onApply = () => {
    // Remove incomplete / empty filter conditions
    const tempFilterGroupWithoutNulls =
      filterEmptyFilterConditions(tempFilterGroup);
    propsSetFilterGroup(tempFilterGroupWithoutNulls);
    onClose();
  };

  const mode = getFilterGroupEditMode(tempFilterGroup, supportsNesting);

  let filtersContent: ReactNode;
  if (mode === FilterGroupEditMode.Simple) {
    const singleFilterGroup = tempFilterGroup.conditions[0];
    if (!isNil(singleFilterGroup) && isFilterGroup(singleFilterGroup)) {
      filtersContent = (
        <>
          <Typography variant="h6">Find {pluralize(entityName)}</Typography>
          <EditFilterGroup<
            TFilterField,
            TFilterType,
            TFilterTypeToOperatorMap,
            TFilterFieldToTypeMap
          >
            filterGroup={singleFilterGroup}
            filterConfig={filterConfig}
            onFilterGroupChange={getUpdateNestedFilterGroupHandler(0)}
          />
        </>
      );
    }
  } else {
    filtersContent = (
      <>
        <Typography variant="h6">
          Find {pluralize(entityName)} that match...
        </Typography>
        <Stack gap={1} direction="row" alignItems="center">
          <PalletButtonGroup<FilterGroupOperator>
            options={TOP_LEVEL_OPERATOR_OPTIONS}
            value={tempFilterGroup.operator}
            onChange={setFilterGroupOperator}
          />
          <Typography>these groups of filters:</Typography>
        </Stack>
        {tempFilterGroup.conditions
          .filter((f) => isFilterGroup(f))
          .map((nestedGroup, groupIndex) => (
            <Stack
              // eslint-disable-next-line react/no-array-index-key
              key={groupIndex}
              direction="row"
              gap={1}
              alignItems="start"
            >
              <Box
                padding={1}
                borderRadius={1}
                border={1}
                borderColor={(theme) => theme.palette.borderColor.main}
                sx={{
                  backgroundColor: (theme) => theme.palette.background.default,
                }}
                flexGrow={1}
              >
                <EditFilterGroup<
                  TFilterField,
                  TFilterType,
                  TFilterTypeToOperatorMap,
                  TFilterFieldToTypeMap
                >
                  filterGroup={nestedGroup}
                  filterConfig={filterConfig}
                  onFilterGroupChange={getUpdateNestedFilterGroupHandler(
                    groupIndex,
                  )}
                />
              </Box>
              <IconButton
                size="small"
                sx={{ marginY: 1 }}
                onClick={() => {
                  onDeleteFilterGroup(groupIndex);
                }}
              >
                <RemoveCircleOutline />
              </IconButton>
            </Stack>
          ))}
      </>
    );
  }

  const filterGroupRef = useRef<HTMLDivElement>(null);

  return (
    <Stack maxHeight="80vh" maxWidth="80vw" width={POPOVER_CONTENT_WIDTH}>
      <Stack
        ref={filterGroupRef}
        gap={2}
        padding={2}
        sx={{ overflowY: 'auto' }}
      >
        {filtersContent}
      </Stack>
      <Stack>
        <Divider />
        <Stack
          direction="row"
          justifyContent={supportsNesting ? 'space-between' : 'end'}
          py={1.5}
          px={2}
          gap={2}
        >
          {supportsNesting && (
            <Tooltip title="Add more conditions to find specific orders">
              <SecondaryButton
                variant="outlined"
                startIcon={<Add />}
                onClick={onAddFilterGroup}
              >
                Add filter group
              </SecondaryButton>
            </Tooltip>
          )}
          <Stack direction="row" gap={2}>
            <SecondaryButton sx={{ boxShadow: 'none' }} onClick={onClose}>
              Cancel
            </SecondaryButton>
            <Tooltip
              title={
                isApplyDisabled && 'Select operators and values for all filters'
              }
            >
              <span>
                <Button
                  variant="contained"
                  disabled={isApplyDisabled}
                  onClick={onApply}
                >
                  Apply
                </Button>
              </span>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export { EditFilters };
