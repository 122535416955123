import {
  createEntityAdapter,
  createSelector,
  createSlice,
  type EntityId,
  type EntityState,
} from '@reduxjs/toolkit';
import { type Temporal } from 'temporal-polyfill';
import {
  type AppointmentCallStatus,
  type AppointmentTextStatus,
  type InboundMethod,
  type OutboundMethod,
  type StandardStopType,
  type StopStatus,
  type StopType,
} from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';

export type StopValues = Partial<{
  addressUuid: string;
  appointmentRequired: boolean;
  appointmentTextStatus: AppointmentTextStatus | null;
  appointmentCallStatus?: AppointmentCallStatus | null;
  appointmentTime: string | null;
  proofOfDeliverySignee?: string | null;
  arrivedAt: Date | null;
  billOfLadingNumber: string | null;
  courierReadyOrDueDate: string | null;
  completedAt: Date | null;
  refusedBy: string | undefined | null;
  consigneeAddressUuid: string | null;
  consigneeContactPersonUuid: string | null;
  contactPersonUuid: string | null;
  createdAt?: Date | null;
  driverCompletedAt?: Date | null;
  driverUuid?: string | null;
  endAppointmentTime: string | null;
  equipmentUuid?: string | null;
  name?: string | null;
  notes?: string | null;
  orderUuid?: string | null;
  routeSlotUuid?: string | null;
  routeUuid: string | null;
  serviceDate?: Date | null;
  serviceDateV2?: Temporal.PlainDate | null;
  shipmentUuid?: string | null;
  shipperAddressUuid: string | null;
  shipperContactPersonUuid: string | null;
  specialInstructions: string | null;
  standardStopType: StandardStopType;
  expectedInboundArrivalDate: string | null | undefined;
  contactPersonUuids: string[] | null;
  appointmentManuallyConfirmed?: boolean | null;
  incomingCarrier: string | null;
  outboundCarrier: string | null;
  destinationAirport: string | null;
  isSpecial: boolean;
  terminalUuid: string | null;
  paperworkMarkedComplete?: boolean | null;
  canMarkAttemptComplete?: boolean | null;
  transferAddressUuid: string | null;
}> & {
  uuid: string;
  status: StopStatus;
  stopType: StopType | null;
  inboundMethod: InboundMethod | null | undefined;
  outboundMethod: OutboundMethod | null | undefined;
};

const stopValuesAdapter = createEntityAdapter<StopValues>({
  selectId: (stop) => stop.uuid,
});

export const stopValuesSlice = createSlice({
  name: 'stopValues',
  initialState: stopValuesAdapter.getInitialState(),
  reducers: {
    addOneStopValues: stopValuesAdapter.addOne,
    updateOneStopValues: stopValuesAdapter.updateOne,
    removeOneStopValues: stopValuesAdapter.removeOne,
    setAllStopValues: stopValuesAdapter.setAll,
    upsertOneStopValues: stopValuesAdapter.upsertOne,
  },
});

export const {
  selectById: selectStopValuesById,
  selectAll: selectAllStopValues,
} = stopValuesAdapter.getSelectors((state: RootState) => state.stopValues);

const stopValuesReducer = stopValuesSlice.reducer;

const stopValuesSelector = (state: RootState) => state.stopValues;

export const selectStopsByIds = createSelector(
  stopValuesSelector,
  (state: RootState, ids: EntityId[]) => ids,
  (state: EntityState<StopValues>, entityIds: EntityId[]) => {
    const entityResults: StopValues[] = [];
    for (const id of entityIds) {
      const match = stopValuesAdapter.getSelectors().selectById(state, id);
      if (match) {
        entityResults.push(match);
      }
    }
    return entityResults;
  },
);

export const selectStopIncomingCarrierById = createSelector(
  selectStopValuesById,
  (values: StopValues | undefined) => {
    return values?.incomingCarrier;
  },
);

export const selectStopCompletedAtById = createSelector(
  selectStopValuesById,
  (values: StopValues | undefined) => {
    return values?.completedAt;
  },
);

export const selectStopArrivedAtById = createSelector(
  selectStopValuesById,
  (values: StopValues | undefined) => {
    return values?.arrivedAt;
  },
);

export const selectStopStatusById = createSelector(
  selectStopValuesById,
  (values: StopValues | undefined) => {
    return values?.status;
  },
);

export const selectAddressUuidForStop = createSelector(
  selectStopValuesById,
  (values: StopValues | undefined) => {
    return values?.addressUuid;
  },
);

export const selectContactPersonUuidForStop = createSelector(
  selectStopValuesById,
  (values: StopValues | undefined) => {
    return values?.contactPersonUuid;
  },
);

export const selectConsigneeAddressUuidForStop = createSelector(
  selectStopValuesById,
  (values: StopValues | undefined) => {
    return values?.consigneeAddressUuid;
  },
);

export const selectConsigneeContactPersonUuidForStop = createSelector(
  selectStopValuesById,
  (values: StopValues | undefined) => {
    return values?.consigneeContactPersonUuid;
  },
);

export const selectShipperAddressUuidForStop = createSelector(
  selectStopValuesById,
  (values: StopValues | undefined) => {
    return values?.shipperAddressUuid;
  },
);

export const selectShipperContactPersonUuidForStop = createSelector(
  selectStopValuesById,
  (values: StopValues | undefined) => {
    return values?.shipperContactPersonUuid;
  },
);

export const selectInboundMethod = createSelector(
  selectStopValuesById,
  (values: StopValues | undefined) => {
    return values?.inboundMethod;
  },
);

export const selectExpectedInboundArrivalDate = createSelector(
  selectStopValuesById,
  (values: StopValues | undefined) => {
    return values?.expectedInboundArrivalDate;
  },
);

export const selectOutboundMethod = createSelector(
  selectStopValuesById,
  (values: StopValues | undefined) => {
    return values?.outboundMethod;
  },
);

export const selectAppointmentTime = createSelector(
  selectStopValuesById,
  (values: StopValues | undefined) => {
    return values?.appointmentTime;
  },
);

export const selectEndAppointmentTime = createSelector(
  selectStopValuesById,
  (values: StopValues | undefined) => {
    return values?.endAppointmentTime;
  },
);

export const selectIsSpecialForStop = createSelector(
  selectStopValuesById,
  (values: StopValues | undefined) => {
    return values?.isSpecial;
  },
);

export const selectPaperworkCompleteForStop = createSelector(
  selectStopValuesById,
  (values: StopValues | undefined) => {
    return values?.paperworkMarkedComplete;
  },
);

export const {
  addOneStopValues,
  removeOneStopValues,
  updateOneStopValues,
  setAllStopValues,
  upsertOneStopValues,
} = stopValuesSlice.actions;

export default stopValuesReducer;
