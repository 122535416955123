import { isValid } from 'date-fns';
import { isEmpty, isNil } from 'lodash';
import { useForm, type FieldErrors, type Resolver } from 'react-hook-form';
import { MAX_INTEGER, safeDivide } from 'shared/math';
import {
  AccessorialType,
  type AccessorialDateRangeConfigFragment,
} from '../../../../../generated/graphql';
import { NO_FUEL_PROFILE, type AccessorialRange } from '../common';

export type AccessorialDateRangeConfigFormValues = {
  startDate: Date;
  endDate: Date | null;
  rate: number;
  percentForSettlement: number | null;
  fuelProfileUuid: string | null;
  ranges: AccessorialRange[];
};

export const getAccessorialRangesForForm = (
  accessorialDateRangeConfig: AccessorialDateRangeConfigFragment,
) => {
  return accessorialDateRangeConfig.accessorialRanges
    .sort(
      (a, b) =>
        (a.lessThanOrEqualToValue ?? 0) - (b.lessThanOrEqualToValue ?? 0),
    )
    .map((range, idx) => ({
      ...range,
      lessThanOrEqualToValue:
        idx === accessorialDateRangeConfig.accessorialRanges.length - 1 &&
        range.lessThanOrEqualToValue === MAX_INTEGER
          ? null
          : range.lessThanOrEqualToValue,
      rate: safeDivide(range.rateUsdCents, 100, 10),
    }));
};

// TODO: this should be a Zod schema
export const getAccessorialDateRangeConfigFormResolver: (
  accessorialType: AccessorialType,
) => Resolver<AccessorialDateRangeConfigFormValues> =
  (accessorialType: AccessorialType) => async (formValues) => {
    const errors: FieldErrors<AccessorialDateRangeConfigFormValues> = {};

    const { startDate, rate, ranges } = formValues;

    // Because of the janky way we serialize dates through GraphQL, sometimes
    // the form values contain a string instead of a Date object, so we pass it through `new Date()` first
    if (isNil(startDate) || !isValid(new Date(startDate))) {
      errors.startDate = {
        type: 'required',
        message: 'Start date is required',
      };
    }

    if (isNil(rate)) {
      errors.rate = {
        type: 'required',
        message: 'Rate is required',
      };
    }

    if (!isEmpty(ranges)) {
      const lastRange = ranges.at(-1);
      if (!isNil(lastRange)) {
        lastRange.lessThanOrEqualToValue = MAX_INTEGER;
      }

      if (accessorialType === AccessorialType.Weight) {
        for (const [i, range] of ranges.entries()) {
          if (isNil(range.lessThanOrEqualToValue)) {
            // eslint-disable-next-line max-depth
            if (isNil(errors.ranges)) {
              errors.ranges = [];
            }
            errors.ranges[i] = {
              type: 'required',
              message: 'Range must have a value',
            };
          }
          if (isNil(range.rate)) {
            // eslint-disable-next-line max-depth
            if (isNil(errors.ranges)) {
              errors.ranges = [];
            }
            errors.ranges[i] = {
              type: 'required',
              message: 'Rate is required',
            };
          }
        }
      }
    }

    if (!isEmpty(errors)) {
      return {
        values: {},
        errors,
      };
    }

    return {
      values: formValues,
      errors: {},
    };
  };

const useAccessorialDateRangeConfigForm = (
  accessorialType: AccessorialType,
) => {
  return useForm({
    resolver: getAccessorialDateRangeConfigFormResolver(accessorialType),
    defaultValues: { fuelProfileUuid: NO_FUEL_PROFILE, ranges: [] },
  });
};

export default useAccessorialDateRangeConfigForm;
