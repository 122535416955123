import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import Button from '@mui/material/Button';
import { isNil } from 'lodash';
import pluralize from 'pluralize';
import React, { useEffect, useState } from 'react';
import { filterNotNil } from 'shared/array';
import { shallow } from 'zustand/shallow';
import useSelectedTerminalUuid from '../../../common/react-hooks/use-selected-terminal-uuid';
import {
  type StopFragment,
  useGenerateRoutesLazyQuery,
} from '../../../generated/graphql';
import useDispatchStore from '../dispatch-store';
import useFetchStops from '../hooks/use-fetch-stops';
import useRouteActions from '../hooks/use-route-actions';
import SelectedUnassignedStopsAgGridTable from '../selected-unassigned-stops-ag-grid-table';
import { isStopFragment } from '../utils';
import GenerateRoutesConstraints from './generate-routes-constraints';
import GenerateRoutesPreviewStopsMap from './generate-routes-preview-stops-map';
import GenerateRoutesResult from './generate-routes-result';
import useGenerateRoutesStore from './use-generate-routes-store';

const GenerateRoutesModal = ({
  open,
  onClose,
  onConfirm,
  initialStopUuids,
}: {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onConfirm: () => void;
  readonly initialStopUuids: string[];
}) => {
  const { selectedTerminalUuid } = useSelectedTerminalUuid();
  const [planningDate] = useDispatchStore(
    (state) => [state.planningDate],
    shallow,
  );
  const [driverEquipmentConstraints, appointmentWindowType, resetStore] =
    useGenerateRoutesStore(
      (state) => [
        state.driverEquipmentConstraints,
        state.appointmentWindowType,
        state.reset,
      ],
      shallow,
    );
  const [step, setStep] = useState<number>(0);
  const [selectedStopUuids, setSelectedStopUuids] =
    useState<string[]>(initialStopUuids);
  const [stops, setStops] = useState<StopFragment[]>([]);
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [generateRoutes, { data: generateRoutesData, loading }] =
    useGenerateRoutesLazyQuery();
  const { createNewRoutes } = useRouteActions();
  const { fetchStops } = useFetchStops();

  useEffect(() => {
    const fetchAllStops = async () => {
      const data = await fetchStops({
        variables: {
          uuids: initialStopUuids,
        },
        useCache: true,
        first: 100,
      });
      setStops(
        filterNotNil(
          data.stops?.edges.map((e) =>
            isStopFragment(e.node) ? e.node : null,
          ),
        ),
      );
    };
    fetchAllStops();
  }, [initialStopUuids, fetchStops]);

  const onSubmitGenerateRoutesProblem = async () => {
    await generateRoutes({
      variables: {
        generateRoutesInput: {
          stopUuids: selectedStopUuids,
          appointmentWindowType,
          driverEquipmentConstraints: driverEquipmentConstraints
            .filter((constraint) => constraint.active)
            .map((constraint) => ({
              driverUuid: constraint.driverUuid,
              equipmentUuid: constraint.equipmentUuid,
              maxWeight: constraint.maxWeight,
              maxVolume: constraint.maxVolume,
              maxPieces: constraint.maxPieces,
              startTime: undefined, // TODO: implement driver start/end
              endTime: undefined,
            })),
        },
      },
    });
  };

  const onCreateRoutes = async () => {
    if (isNil(planningDate)) return;
    setIsCreating(true);
    await Promise.allSettled(
      (generateRoutesData?.generateRoutes.routes ?? []).map(async (route) => {
        return createNewRoutes({
          planningDate,
          terminalUuid: selectedTerminalUuid,
          stopUuids: route.stops.map((s) => s.stop.uuid),
          driverUuid: route.driver.uuid,
          equipmentUuid: route.equipment?.uuid,
        });
      }),
    );
    setIsCreating(false);
    onConfirm();
    onClose();
  };

  const selectStopsSection = (
    <>
      <Typography variant="caption">
        {selectedStopUuids.length} {pluralize('stop', selectedStopUuids.length)}{' '}
        selected
      </Typography>
      <Grid container sx={{ height: '100%' }} spacing={1}>
        <Grid item xs={6}>
          <SelectedUnassignedStopsAgGridTable
            stops={stops}
            selectedStopUuids={selectedStopUuids}
            setSelectedStopUuids={setSelectedStopUuids}
          />
        </Grid>
        <Grid item xs={6}>
          <GenerateRoutesPreviewStopsMap
            stops={stops.filter((s) => selectedStopUuids.includes(s.stop.uuid))}
            setSelectedStopUuids={setSelectedStopUuids}
          />
        </Grid>
      </Grid>
    </>
  );

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="xl"
      onClose={() => {
        resetStore();
        onClose();
      }}
    >
      <Stack direction="row" justifyContent="space-between" sx={{ pr: 2 }}>
        <DialogTitle>Generate routes</DialogTitle>
        <Stepper activeStep={step} sx={{ width: '500px' }}>
          <Step>
            <StepLabel>Select stops</StepLabel>
          </Step>
          <Step>
            <StepLabel>Configure</StepLabel>
          </Step>
          <Step>
            <StepLabel>Optimize</StepLabel>
          </Step>
        </Stepper>
      </Stack>
      <DialogContent sx={{ height: '77vh' }}>
        <Stack sx={{ height: '100%' }}>
          {step === 0 && selectStopsSection}
          {step === 1 && <GenerateRoutesConstraints />}
          {step === 2 && (
            <GenerateRoutesResult
              generateRoutesData={generateRoutesData?.generateRoutes}
              loading={loading}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        {step > 0 && (
          <Button
            onClick={() => {
              setStep((prev) => prev - 1);
            }}
          >
            Back
          </Button>
        )}
        {step === 2 &&
          (!loading &&
          !isNil(generateRoutesData?.generateRoutes.errorMessage) ? (
            <Button
              variant="contained"
              onClick={() => {
                onConfirm();
                onClose();
              }}
            >
              Cancel
            </Button>
          ) : (
            <Button
              disabled={isCreating || loading}
              endIcon={isCreating ? <CircularProgress size={15} /> : null}
              variant="contained"
              onClick={onCreateRoutes}
            >
              Create routes
            </Button>
          ))}
        {step < 2 && (
          <Button
            disabled={
              (step === 0 && selectedStopUuids.length === 0) ||
              (step === 1 &&
                driverEquipmentConstraints.every(
                  (constraint) => !constraint.active,
                ))
            }
            variant="contained"
            onClick={() => {
              if (step === 1) {
                onSubmitGenerateRoutesProblem();
              }
              setStep((prevStep) => prevStep + 1);
            }}
          >
            Next
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(GenerateRoutesModal);
