import { Popper, styled, TextField, Typography } from '@mui/material';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { isNil } from 'lodash';
import type { MatchSorterOptions } from 'match-sorter';
import {
  forwardRef,
  type HTMLAttributes,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { type ListChildComponentProps, VariableSizeList } from 'react-window';
import type { ContactEntity } from '../../generated/graphql';
import AutocompleteFuzzy from '../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';

import theme, {
  optionFocusedBackgroundColor,
  optionFocusedSelectedBackgroundColor,
  optionSelectedBackgroundColor,
} from '../../theme';
import { FeatureFlag } from '../feature-flags';
import useContacts from '../react-hooks/use-contacts';
import useFeatureFlag from '../react-hooks/use-feature-flag';

type ContactOption = Pick<
  ContactEntity,
  'uuid' | 'displayName' | 'contactReferenceNumber'
>;

const liHeightWithReferenceNumber = () => 60;

const liHeightWithoutReferenceNumber = () => 45;

const listboxPaddingPx = 6;

const MAX_NAME_LENGTH_FOR_ONE_LINE = 40;
const EXTRA_HEIGHT_PER_LINE_FOR_LONG_NAMES = 20;
const MAX_LISTBOX_HEIGHT = 400;

type RenderData = {
  props: HTMLAttributes<HTMLLIElement>;
  option: ContactOption;
  ffShowContactId: boolean;
};

const renderRow = ({
  data,
  index,
  style,
}: ListChildComponentProps<RenderData[]>) => {
  const dataSet = data[index];
  if (isNil(dataSet)) {
    return null;
  }

  const { props, option, ffShowContactId } = dataSet;

  return (
    <li
      {...props}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
        gap: '3px',
        padding: '6px 14px',
        overflow: 'hidden',
        ...style,
      }}
      onMouseDown={props.onClick}
    >
      <Typography>{option.displayName}</Typography>
      {ffShowContactId && (
        <Typography sx={{ fontSize: '14px', color: theme.palette.grey[400] }}>
          {option.contactReferenceNumber ?? '-'}
        </Typography>
      )}
    </li>
  );
};

const useResetCache = (itemCount: number) => {
  const ref = useRef<VariableSizeList>(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [itemCount]);

  return ref;
};

const Listbox = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(
  ({ children }, ref) => {
    const items = children as RenderData[];
    const itemCount = items.length;
    const gridRef = useResetCache(itemCount);
    const [firstItem] = items;

    const liHeightFn = (itemIndex: number) => {
      const showContactId = firstItem?.ffShowContactId ?? false; // Default to false if undefined
      const baseHeight = showContactId
        ? liHeightWithReferenceNumber()
        : liHeightWithoutReferenceNumber();

      // Guard against invalid indices
      if (itemIndex < 0 || itemIndex >= itemCount) {
        return baseHeight;
      }

      const item = items[itemIndex];

      if (!isNil(item)) {
        // if the name is too long add extra height
        const nameLength = item.option.displayName.length;

        // Cap maximum height addition to prevent extreme cases
        const extraLines = Math.min(
          Math.floor(nameLength / MAX_NAME_LENGTH_FOR_ONE_LINE),
          3, // Maximum of 3 extra line heights
        );

        return baseHeight + extraLines * EXTRA_HEIGHT_PER_LINE_FOR_LONG_NAMES;
      }
      return baseHeight;
    };

    return (
      <div ref={ref} role="listbox">
        <VariableSizeList
          ref={gridRef}
          className={autocompleteClasses.listbox}
          itemData={items}
          height={MAX_LISTBOX_HEIGHT}
          width="100%"
          innerElementType="ul"
          itemSize={liHeightFn}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </div>
    );
  },
);

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    padding: `${listboxPaddingPx}px 0`,
    boxSizing: 'border-box',
    '& ul': {
      position: 'relative',
      padding: 0,
      margin: 0,
      '& li.Mui-focused': {
        backgroundColor: optionFocusedBackgroundColor,
      },
      '& li[aria-selected="true"]': {
        backgroundColor: optionSelectedBackgroundColor,
      },
      '& li.Mui-focused[aria-selected="true"]': {
        backgroundColor: optionFocusedSelectedBackgroundColor,
      },
    },
  },
});

const matchSorterOptions: MatchSorterOptions<ContactOption> = {
  keys: ['displayName', 'contactReferenceNumber'],
};

const getOptionLabel = (option: ContactOption) => option.displayName;

const ContactAutocompleteComponent = ({
  billingPartyContactUuid,
  onChange,
  onPressEnter,
  open,
  disabled = false,
  label,
  required,
}: {
  readonly billingPartyContactUuid: string | undefined;
  readonly onChange: (value: string | undefined) => void;
  readonly onPressEnter?: () => void;
  readonly open?: boolean;
  readonly disabled?: boolean;
  readonly label?: string;
  readonly required?: boolean;
}) => {
  const { contacts } = useContacts();
  const currentContact = contacts.find(
    (contact) => contact.uuid === billingPartyContactUuid,
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const ffShowContactId = useFeatureFlag(FeatureFlag.FF_SHOW_CONTACT_ID);

  useEffect(() => {
    if (!isNil(open) && open) {
      inputRef.current?.focus();
    }
  }, [open]);

  return (
    <AutocompleteFuzzy<ContactOption, false, false, false>
      autoHighlight
      disableListWrap
      id="contact-autocomplete"
      size="small"
      sx={{
        width: '100%',
      }}
      clearOnBlur={false}
      disabled={disabled}
      value={currentContact ?? null}
      options={contacts}
      getOptionLabel={getOptionLabel}
      matchSortOptions={matchSorterOptions}
      required={required}
      renderInput={(params) => (
        <TextField
          data-cy="billing-party-contact-modal-input"
          {...params}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onPressEnter?.();
            }
          }}
          size="small"
          inputRef={inputRef}
          label={label}
          required={required}
        />
      )}
      PopperComponent={StyledPopper}
      onChange={(event, option) => {
        onChange(option?.uuid);
      }}
      ListboxComponent={Listbox}
      // @ts-expect-error -- react-window expects a data object instead of a ReactNode
      renderOption={(props, option): RenderData => ({
        props,
        option,
        ffShowContactId,
      })}
    />
  );
};

export default ContactAutocompleteComponent;
