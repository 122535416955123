import { type Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import { type DriverSettlementBillSummaryV2Fragment } from '../../../generated/graphql';
import SettlementBillSnapshot from './settlement-bill-snapshot';
import SettlementBillUnfinalized from './settlement-bill-unfinalized';

const SettlementBill = ({
  settlementBillSummary,
  onClose,
}: {
  readonly settlementBillSummary: DriverSettlementBillSummaryV2Fragment;
  readonly onClose: () => void;
}) => {
  const { uuid: settlementUuid } = settlementBillSummary;

  const isFinalizedWithSnapshot =
    !isNil(settlementBillSummary.finalizedDate) &&
    settlementBillSummary.snapshotExists;

  return isFinalizedWithSnapshot ? (
    <SettlementBillSnapshot settlementUuid={settlementUuid} onClose={onClose} />
  ) : (
    <SettlementBillUnfinalized
      settlementUuid={settlementUuid}
      onClose={onClose}
    />
  );
};

export default SettlementBill;
