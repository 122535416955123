import { groupBy, values } from 'lodash';
import { useMemo } from 'react';
import { exhaustive } from 'shared/switch';
import {
  FilterOperator,
  FilterViewPage,
  OrderTableField,
  OrderDetailedStatus,
} from '../../generated/graphql';
import { type DefaultFilterTabsConfigs } from '../ag-grid/orders/types';
import { useNewTableFunctionsFeatureFlag } from '../ag-grid/use-new-table-functions-feature-flag';
import {
  convertDefaultOrderStatusFilterTabToLegacyFilterModelJson,
  convertDefaultOrderStatusFilterTabToOrderTableFilterModel,
} from '../ag-grid/utils';
import {
  attemptedOrderStatusFilters,
  completedOrderFilters,
  incompleteOrderStatusFilters,
  onHoldOrderStatusFilters,
  OrderStatusFilterOption,
} from './components/enums/order-filters';
import { OrdersTab } from './components/types';

/**
 * Detailed statuses that are used for orders that haven't been persisted in the system yet.
 */
export const CREATE_ORDER_DETAILED_STATUSES: OrderDetailedStatus[] = [
  OrderDetailedStatus.Creating,
];

export const VIEW_ALL_ORDERS_PAGE_DEFAULT_ORDER_TABLE_FIELDS = [
  OrderTableField.BillOfLadingNumber,
  OrderTableField.Name,
  OrderTableField.PrimaryAddress,
  OrderTableField.Charges,
  OrderTableField.Status,
];

export const useViewAllOrdersPageFilterTabsConfigs =
  (): DefaultFilterTabsConfigs<OrdersTab> => {
    const { ffEnableNewTableFunctions } = useNewTableFunctionsFeatureFlag(
      FilterViewPage.Orders,
    );

    return useMemo(() => {
      const convertOrderStatusFilterToFilterModel = ffEnableNewTableFunctions
        ? (
            status:
              | OrderStatusFilterOption.INCOMPLETE
              | OrderStatusFilterOption.ATTEMPTED
              | OrderStatusFilterOption.ON_HOLD
              | OrderStatusFilterOption.COMPLETED,
          ) =>
            convertDefaultOrderStatusFilterTabToOrderTableFilterModel(
              status,
              ffEnableNewTableFunctions,
            )
        : convertDefaultOrderStatusFilterTabToLegacyFilterModelJson;
      return {
        defaultTab: OrdersTab.AllOrders,
        baseTab: OrdersTab.AllOrders,
        tabs: [
          {
            value: OrdersTab.AllOrders,
            label: 'All',
            filtersToApply: {},
          },
          {
            value: OrdersTab.Incomplete,
            label: 'Incomplete',
            filterModel: convertOrderStatusFilterToFilterModel(
              OrderStatusFilterOption.INCOMPLETE,
            ),
            filtersToApply: {
              statusFilters: incompleteOrderStatusFilters,
            },
          },
          {
            value: OrdersTab.AttemptedOrders,
            label: 'Attempted',
            filterModel: convertOrderStatusFilterToFilterModel(
              OrderStatusFilterOption.ATTEMPTED,
            ),
            filtersToApply: {
              statusFilters: attemptedOrderStatusFilters,
              hasFailures: {
                filterOperator: FilterOperator.And,
                value: true,
              },
            },
          },
          {
            value: OrdersTab.OnHold,
            label: 'On hold',
            filterModel: convertOrderStatusFilterToFilterModel(
              OrderStatusFilterOption.ON_HOLD,
            ),
            filtersToApply: {
              statusFilters: onHoldOrderStatusFilters,
            },
          },
          {
            value: OrdersTab.Complete,
            label: 'Complete',
            filterModel: convertOrderStatusFilterToFilterModel(
              OrderStatusFilterOption.COMPLETED,
            ),
            filtersToApply: {
              statusFilters: completedOrderFilters,
            },
          },
        ],
      };
    }, [ffEnableNewTableFunctions]);
  };

/** Note: also includes fields that are only sortable when ffEnableNewTableFunctions is enabled */
const SORTABLE_ORDER_TABLE_FIELDS: OrderTableField[] = [
  OrderTableField.BillOfLadingNumber,
  OrderTableField.BusinessDivision,
  OrderTableField.CompletedAt,
  OrderTableField.CustomerName,
  OrderTableField.DestinationDetails,
  OrderTableField.DestinationTerminal,
  OrderTableField.DimWeight,
  OrderTableField.InBond,
  OrderTableField.InboundAddress,
  OrderTableField.InboundAddressName,
  OrderTableField.InboundCityName,
  OrderTableField.InboundCompletedDate,
  OrderTableField.InboundDriverName,
  OrderTableField.InboundRouteDate,
  OrderTableField.InboundRouteName,
  OrderTableField.InboundServiceDate,
  OrderTableField.InboundZipcode,
  OrderTableField.IsReweighed,
  OrderTableField.Mawb,
  OrderTableField.Name,
  OrderTableField.OrderConsignee,
  OrderTableField.OrderServiceDate,
  OrderTableField.OriginTerminal,
  OrderTableField.OutboundAddress,
  OrderTableField.OutboundAddressName,
  OrderTableField.OutboundCityName,
  OrderTableField.OutboundCompletedDate,
  OrderTableField.OutboundDriverName,
  OrderTableField.OutboundMethod,
  OrderTableField.OutboundRouteDate,
  OrderTableField.OutboundRouteName,
  OrderTableField.OutboundServiceDate,
  OrderTableField.OutboundZipcode,
  OrderTableField.PieceCount,
  OrderTableField.PrimaryAddress,
  OrderTableField.PrimaryConsignee,
  OrderTableField.PrimaryServiceLevel,
  OrderTableField.ReceivedAt,
  OrderTableField.SecondaryRefNumber,
  OrderTableField.Source,
  OrderTableField.TotalSkids,
  OrderTableField.Weight,
  OrderTableField.Zipcode,
];

enum OrderTableFieldGroup {
  General = 'General',
  Status = 'Status',
  Stops = 'Stops',
  Inbound = 'Inbound',
  Outbound = 'Outbound',
  Handling = 'Handling',
  Billing = 'Billing',
}

const getOrderTableFieldGroup = (
  field: OrderTableField,
): OrderTableFieldGroup => {
  switch (field) {
    case OrderTableField.BusinessDivision:
    case OrderTableField.Company:
    case OrderTableField.ContactInformation:
    case OrderTableField.CustomerName:
    case OrderTableField.CompletedAt:
    case OrderTableField.CreatedAt:
    case OrderTableField.ExternalNotes:
    case OrderTableField.BillOfLadingNumber:
    case OrderTableField.Mawb:
    case OrderTableField.Notes:
    case OrderTableField.Name:
    case OrderTableField.Status:
    case OrderTableField.OrderType:
    case OrderTableField.SecondaryRefNumber:
    case OrderTableField.OrderServiceDate:
    case OrderTableField.PrimaryServiceDate:
    case OrderTableField.PrimaryServiceLevel:
    case OrderTableField.ShipmentType:
    case OrderTableField.Source:
    case OrderTableField.StopTypes:
    case OrderTableField.Tags:
    case OrderTableField.LineHaulManifests: {
      return OrderTableFieldGroup.General;
    }
    case OrderTableField.Attempted:
    case OrderTableField.BillingReviewStatus:
    case OrderTableField.InBond:
    case OrderTableField.HoldReason:
    case OrderTableField.NextAddress:
    case OrderTableField.NextAppointment:
    case OrderTableField.NextConsignee:
    case OrderTableField.ContactName:
    case OrderTableField.NextDeadline:
    case OrderTableField.OnHold:
    case OrderTableField.OsdReason:
    case OrderTableField.PaperworkComplete:
    case OrderTableField.HasDriverPod:
    case OrderTableField.Photos:
    case OrderTableField.PodName:
    case OrderTableField.IsReweighed: {
      return OrderTableFieldGroup.Status;
    }
    case OrderTableField.PrimaryAddress:
    case OrderTableField.PrimaryConsignee:
    case OrderTableField.PrimaryAddressType:
    case OrderTableField.PrimaryAppointment:
    case OrderTableField.CityName:
    case OrderTableField.PrimaryContactName:
    case OrderTableField.PrimaryDeadline:
    case OrderTableField.DriverName:
    case OrderTableField.RouteName:
    case OrderTableField.RoutingLocation:
    case OrderTableField.Zipcode: {
      return OrderTableFieldGroup.Stops;
    }
    case OrderTableField.InboundAddress:
    case OrderTableField.InboundAddressName:
    case OrderTableField.InboundAddressType:
    case OrderTableField.InboundAppointment:
    case OrderTableField.InboundAppointmentDate:
    case OrderTableField.InboundAppointmentTime:
    case OrderTableField.InboundAppointmentConfirmed:
    case OrderTableField.InboundCityName:
    case OrderTableField.InboundCompletedDate:
    case OrderTableField.InboundDriverName:
    case OrderTableField.InboundMethod:
    case OrderTableField.InboundPaperwork:
    case OrderTableField.ReceivedAt:
    case OrderTableField.InboundRouteDate:
    case OrderTableField.InboundRouteName:
    case OrderTableField.InboundRouting:
    case OrderTableField.InboundServiceDate:
    case OrderTableField.InboundStopType:
    case OrderTableField.InboundZipcode:
    case OrderTableField.InboundContactName:
    case OrderTableField.InboundOnTimePerformanceStatus:
    case OrderTableField.InboundStopWaitTime:
    case OrderTableField.InboundStopTotalTime: {
      return OrderTableFieldGroup.Inbound;
    }
    case OrderTableField.OutboundAddress:
    case OrderTableField.OutboundAddressName:
    case OrderTableField.OutboundAddressType:
    case OrderTableField.OutboundAppointment:
    case OrderTableField.OutboundAppointmentDate:
    case OrderTableField.OutboundAppointmentTime:
    case OrderTableField.OutboundAppointmentConfirmed:
    case OrderTableField.OutboundCityName:
    case OrderTableField.OutboundCompletedDate:
    case OrderTableField.OrderConsignee:
    case OrderTableField.OutboundDriverName:
    case OrderTableField.OutboundMethod:
    case OrderTableField.OutboundPaperwork:
    case OrderTableField.OutboundRouteDate:
    case OrderTableField.OutboundRouteName:
    case OrderTableField.OutboundRouting:
    case OrderTableField.OutboundServiceDate:
    case OrderTableField.OutboundStopType:
    case OrderTableField.OutboundZipcode:
    case OrderTableField.OutboundContactName:
    case OrderTableField.OutboundOnTimePerformanceStatus:
    case OrderTableField.OutboundStopWaitTime:
    case OrderTableField.OutboundStopTotalTime: {
      return OrderTableFieldGroup.Outbound;
    }
    case OrderTableField.DestinationDetails:
    case OrderTableField.DestinationTerminal:
    case OrderTableField.DimWeight:
    case OrderTableField.Dims:
    case OrderTableField.IsLinehaul:
    case OrderTableField.ItTeNumber:
    case OrderTableField.OriginTerminal:
    case OrderTableField.PieceCount:
    case OrderTableField.Special:
    case OrderTableField.TotalSkids:
    case OrderTableField.UnNumber:
    case OrderTableField.Weight: {
      return OrderTableFieldGroup.Handling;
    }
    case OrderTableField.Charges:
    case OrderTableField.TotalChargesWithItemized:
    case OrderTableField.DriverSettlement:
    case OrderTableField.InboundCharges:
    case OrderTableField.OutboundCharges:
    case OrderTableField.LineHaulCharges:
    case OrderTableField.AdditionalCharges: {
      return OrderTableFieldGroup.Billing;
    }
    default: {
      return exhaustive(field);
    }
  }
};

export type GroupedOrderTableFields = {
  name: string;
  fields: OrderTableField[];
};

const groupOrderTableFields = (
  fields: OrderTableField[],
): GroupedOrderTableFields[] => {
  const groupedFields = groupBy(fields, getOrderTableFieldGroup);
  // Map over OrderTableFieldGroup to preserve the ordering
  return values(OrderTableFieldGroup).map((group) => ({
    name: group,
    fields: groupedFields[group] ?? [],
  }));
};

export const ALL_GROUPED_ORDER_TABLE_FIELDS = groupOrderTableFields(
  values(OrderTableField),
);

export const SORTABLE_GROUPED_ORDER_TABLE_FIELDS = groupOrderTableFields(
  SORTABLE_ORDER_TABLE_FIELDS,
);
