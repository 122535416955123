import { v4 } from 'uuid';
import { z } from 'zod';
import { ShipmentStatus } from '../../../../../generated/graphql';
import {
  type CustomChargeSchemaOptions,
  getCustomChargeSchema,
} from './custom-charge-schema';
import { freightChargeSchema } from './freight-charge-schema';
import { zDateOrDatetimeString } from './zod-utils';

export const getOrderChargesShipmentSchema = ({
  accessorials,
}: CustomChargeSchemaOptions) =>
  z.object({
    uuid: z
      .string()
      .uuid()
      .default(() => v4()),
    freightCharge: freightChargeSchema.nullish(),
    customCharges: z
      .array(
        getCustomChargeSchema({
          accessorials,
        }),
      )
      .optional(),
    miles: z.number().nullish(),
    isLocal: z.boolean().nullish(),
    shipmentStatus: z.nativeEnum(ShipmentStatus).nullish(),
    totalCharge: z.number().nullish(),
    invoiceUuid: z.string().uuid().nullish(),
    invoiceName: z.string().nullish(),
    invoiceDate: zDateOrDatetimeString().nullish(),
    invoicePostedDate: zDateOrDatetimeString().nullish(),
  });
