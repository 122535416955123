import {
  CustomerPortalOrderReviewStatus,
  OrderTableField,
} from '../../../../generated/graphql';
import { type FindBookingRequestsFiltersInput } from './booking-request-filters';

export enum CustomerPortalBookingRequestTab {
  ALL = 'All',
  QUOTED_REQUESTS = 'Quoted requests',
  REJECTED_REQUESTS = 'Rejected requests',
}

export const CUSTOMER_PORTAL_BOOKING_REQUESTS_DEFAULT_FILTERS: Record<
  CustomerPortalBookingRequestTab,
  FindBookingRequestsFiltersInput
> = {
  [CustomerPortalBookingRequestTab.ALL]: {},
  [CustomerPortalBookingRequestTab.QUOTED_REQUESTS]: {
    hasQuotesFilter: { eq: true },
  },
  [CustomerPortalBookingRequestTab.REJECTED_REQUESTS]: {
    customerPortalOrderReviewStatusFilter: {
      eq: CustomerPortalOrderReviewStatus.Rejected,
    },
  },
};

export const CUSTOMER_PORTAL_BOOKING_REQUESTS_TABLE_FIELDS = [
  OrderTableField.Name,
  OrderTableField.BillOfLadingNumber,
  OrderTableField.Company,
  OrderTableField.CreatedAt,
  OrderTableField.Charges,
];
