import { Button, useTheme } from '@mui/material';

type SendJobButtonProps = {
  readonly jobCount: number;
  readonly loading?: boolean;
  readonly onClick: () => void;
};

const SendJobButton = ({ jobCount, loading, onClick }: SendJobButtonProps) => {
  const theme = useTheme();
  const getLabel = () => {
    if (jobCount === 1) {
      return 'Send job';
    }
    return `Send ${jobCount} jobs`;
  };
  return (
    // TO DO: change to use PalletButton
    <Button
      sx={{
        backgroundColor: theme.palette.airfreightBlue[60],
        '&:hover': {
          backgroundColor: theme.palette.airfreightBlue[70],
        },
        color: 'white',
      }}
      disabled={loading}
      variant="contained"
      size="small"
      onClick={onClick}
    >
      {getLabel()}
    </Button>
  );
};

export default SendJobButton;
