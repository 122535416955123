import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Alert,
  Checkbox,
  CircularProgress,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Menu,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TablePagination from '@mui/material/TablePagination';
import { isEmpty, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { exhaustive } from 'shared/switch';
import { useDebounce } from 'use-debounce';
import { shallow } from 'zustand/shallow';
import useMe from '../../../../common/react-hooks/use-me';
import useWindowDimensions from '../../../../common/react-hooks/use-window-dimensions';
import {
  type FindOrdersOnInvoiceSort,
  FindOrdersOnInvoiceSortFields,
  type InvoiceOrderWithShipmentsFragment,
  type MoveShipmentsBetweenInvoicesOutput,
  type PageInfo,
  Segment,
  SortDirection,
  useMoveShipmentsBetweenInvoicesMutation,
  usePaginatedOrdersByInvoiceLazyQuery,
  useRemoveShipmentsFromInvoiceMutation,
} from '../../../../generated/graphql';
import { invoiceRefetchQueries } from '../../constants';
import useInvoiceTotals from '../../hooks/use-invoice-totals';
import useInvoicesStore from '../../invoices-store';
import { SingleColumnInvoiceOrdersTableSortLabel } from '../../utils';
import AdditionalShipmentsToBeMovedToNewInvoiceModal from './additional-shipments-to-be-moved-new-invoice-modal';
import InvoiceOrderListRow from './invoice-orders-list-row';
import SelectInvoiceToMoveToModal from './select-invoice-to-move-to-modal';

const DEFAULT_SHIPMENTS_PAGE_SIZE = 10;
const ROW_PER_PAGE_OPTIONS = [10, 25, 50, 100];
enum MoveType {
  Fresh = 'fresh',
  Existing = 'existing',
}

type InvoiceOrdersListProps = {
  readonly invoiceUuid: string;
  readonly contactUuid: string | undefined;
  readonly disableInvoiceEditing: boolean;
  readonly inCustomersTab?: boolean; // To be used with Customers -> Invoices
};

const InvoiceOrdersList = ({
  invoiceUuid,
  contactUuid,
  disableInvoiceEditing,
  inCustomersTab = false,
}: InvoiceOrdersListProps) => {
  const { height } = useWindowDimensions();
  const { segment } = useMe();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showInvoiceSelectionMoveModal, setShowInvoiceSelectionMoveModal] =
    useState<boolean>(false);
  const [
    showAdditionalShipmentsToBeMovedToNewInvoiceModal,
    setShowAdditionalShipmentsToBeMovedToNewInvoiceModal,
  ] = useState<boolean>(false);
  const [moveOrdersToFreshInvoiceSuccess, setMoveOrdersToFreshInvoiceSuccess] =
    useState<boolean>(false);
  const { fetchInvoiceTotalsByUuids } = useInvoiceTotals();
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    DEFAULT_SHIPMENTS_PAGE_SIZE,
  );
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState<number>(0);
  const [pageInfo, setPageInfo] = useState<PageInfo | undefined>();
  const [orders, setOrders] = useState<InvoiceOrderWithShipmentsFragment[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [sort, setSort] = useState<FindOrdersOnInvoiceSort>({
    sortBy: FindOrdersOnInvoiceSortFields.ShipperBillOfLadingNumber,
    sortDirection: SortDirection.Asc,
  });

  const [isSelectAllCheckboxSelected, setIsSelectAllCheckboxSelected] =
    useState(false);
  const [moveOrdersToFreshInvoiceOutput, setMoveOrdersToFreshInvoiceOutput] =
    useState<MoveShipmentsBetweenInvoicesOutput | null>(null);
  const [
    moveOrdersToExistingInvoiceOutput,
    setMoveOrdersToExistingInvoiceOutput,
  ] = useState<MoveShipmentsBetweenInvoicesOutput | null>(null);
  const [
    shouldRefreshInvoiceList,
    selectedInvoiceOrderUuids,
    deselectAllSelectedInvoiceOrderUuids,
    setSelectedInvoiceOrderUuids,
    setShouldRefreshInvoiceList,
  ] = useInvoicesStore(
    (state) => [
      state.shouldRefreshInvoiceList,
      state.selectedInvoiceOrderUuids,
      state.deselectAllInvoiceOrderUuids,
      state.setSelectedInvoiceOrderUuids,
      state.setShouldRefreshInvoiceList,
    ],
    shallow,
  );
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const [getInvoiceOrders, { loading }] =
    usePaginatedOrdersByInvoiceLazyQuery();
  const [removeShipmentsFromInvoice] = useRemoveShipmentsFromInvoiceMutation({
    refetchQueries: invoiceRefetchQueries,
    awaitRefetchQueries: true,
  });

  const deselectSelectAllCheckbox = () => {
    setIsSelectAllCheckboxSelected(false);
    deselectAllSelectedInvoiceOrderUuids();
  };
  const fetchOrders = async ({
    first,
    after,
    last,
    before,
  }: {
    first?: number | null | undefined;
    after?: string | null | undefined;
    last?: number | null | undefined;
    before?: string | null | undefined;
  }) => {
    const res = await getInvoiceOrders({
      variables: {
        invoiceUuid,
        first,
        after,
        last,
        before,
        searchText: debouncedSearchText,
        sorts: [sort],
      },
    });

    deselectSelectAllCheckbox();
    setOrders(
      res.data?.paginatedOrdersByInvoice.edges.map((edge) => edge.node) ?? [],
    );
    setTotalCount(res.data?.paginatedOrdersByInvoice.totalCount ?? 0);
    setPageInfo(res.data?.paginatedOrdersByInvoice.pageInfo);
  };

  const refresh = async () => {
    fetchOrders({ first: rowsPerPage });
    setPage(0);
  };

  const removeInvoiceOrderLocally = (uuid: string) => {
    const idx = orders.findIndex((order) => order.uuid === uuid);
    if (idx !== -1) {
      setOrders((prevState) => {
        const newOrderssArr = [...prevState];
        newOrderssArr.splice(idx, 1);
        return newOrderssArr;
      });
    }
  };

  // reset all state so we can do another move.
  const onSuccessfulMove = (newInvoiceUuid: string, moveType: MoveType) => {
    setMoveOrdersToFreshInvoiceOutput(null);
    setMoveOrdersToExistingInvoiceOutput(null);
    setShowInvoiceSelectionMoveModal(false);
    setShowAdditionalShipmentsToBeMovedToNewInvoiceModal(false);
    selectedInvoiceOrderUuids.map((uuid) => {
      removeInvoiceOrderLocally(uuid);
    });
    deselectAllSelectedInvoiceOrderUuids();
    fetchInvoiceTotalsByUuids([invoiceUuid, newInvoiceUuid], true);
    refresh();
    if (moveType === MoveType.Fresh) {
      setMoveOrdersToFreshInvoiceSuccess(true);
      setShouldRefreshInvoiceList(true);
    }
  };

  const onCloseMoveModal = () => {
    setMoveOrdersToFreshInvoiceOutput(null);
    setMoveOrdersToExistingInvoiceOutput(null);
    setShowInvoiceSelectionMoveModal(false);
    setShowAdditionalShipmentsToBeMovedToNewInvoiceModal(false);
  };

  const handleMoveShipmentsBetweenInvoicesCompletion = (
    moveOperationOutput: MoveShipmentsBetweenInvoicesOutput,
    moveType: MoveType,
  ) => {
    switch (moveType) {
      case MoveType.Fresh: {
        setMoveOrdersToFreshInvoiceOutput(moveOperationOutput);
        if (
          moveOperationOutput.__typename ===
          'MoveShipmentsBetweenInvoicesIncompleteOperationOutput'
        ) {
          setShowAdditionalShipmentsToBeMovedToNewInvoiceModal(true);
        }
        break;
      }
      case MoveType.Existing: {
        setMoveOrdersToExistingInvoiceOutput(moveOperationOutput);
        break;
      }
      default: {
        exhaustive(moveType);
      }
    }

    if (
      moveOperationOutput.__typename ===
      'MoveShipmentsBetweenInvoicesSuccessOutput'
    ) {
      onSuccessfulMove(moveOperationOutput.newInvoiceUuid, moveType);
    }
  };

  const getShipmentsOnInvoice = (orderUuids: string[]) => {
    return orders
      .filter((order) => orderUuids.includes(order.uuid))
      .flatMap((order) => order.shipments.map((shipment) => shipment.uuid));
  };
  const [moveShipmentsBetweenInvoices, { loading: moveOperationLoading }] =
    useMoveShipmentsBetweenInvoicesMutation({
      refetchQueries: invoiceRefetchQueries,
      awaitRefetchQueries: true,
    });

  const removeOrdersFromInvoice = async (orderUuids: string[]) => {
    orderUuids.map((uuid) => {
      removeInvoiceOrderLocally(uuid);
    });
    const shipmentUuids = getShipmentsOnInvoice(orderUuids);
    await removeShipmentsFromInvoice({
      variables: {
        removeShipmentsFromInvoiceInput: {
          uuids: shipmentUuids,
        },
      },
    });
    deselectAllSelectedInvoiceOrderUuids();
    fetchInvoiceTotalsByUuids([invoiceUuid], true);
    refresh();
  };

  const moveOrdersToFreshInvoice = async ({
    additionalShipmentUuidsToMove,
  }: {
    additionalShipmentUuidsToMove: string[];
  }) => {
    const shipmentUuidsFromSelectedOrderUuids = getShipmentsOnInvoice(
      selectedInvoiceOrderUuids,
    );
    await moveShipmentsBetweenInvoices({
      variables: {
        moveShipmentsBetweenInvoicesInput: {
          shipmentUuids: [
            ...shipmentUuidsFromSelectedOrderUuids,
            ...additionalShipmentUuidsToMove,
          ],
          invoiceMovingFromUuid: invoiceUuid,
          invoiceMovingToUuid: null,
        },
      },
      onCompleted: ({
        moveShipmentsBetweenInvoices: moveShipmentsBetweenInvoicesResult,
      }) => {
        handleMoveShipmentsBetweenInvoicesCompletion(
          moveShipmentsBetweenInvoicesResult,
          MoveType.Fresh,
        );
      },
    });
  };

  const moveOrdersToExistingInvoice = async ({
    newInvoiceUuid,
    additionalShipmentUuidsToMove,
  }: {
    newInvoiceUuid: string;
    additionalShipmentUuidsToMove: string[];
  }) => {
    const shipmentUuidsFromSelectedOrderUuids = getShipmentsOnInvoice(
      selectedInvoiceOrderUuids,
    );
    await moveShipmentsBetweenInvoices({
      variables: {
        moveShipmentsBetweenInvoicesInput: {
          shipmentUuids: [
            ...shipmentUuidsFromSelectedOrderUuids,
            ...additionalShipmentUuidsToMove,
          ],
          invoiceMovingFromUuid: invoiceUuid,
          invoiceMovingToUuid: newInvoiceUuid,
        },
      },
      onCompleted: ({
        moveShipmentsBetweenInvoices: moveShipmentsBetweenInvoicesResult,
      }) => {
        handleMoveShipmentsBetweenInvoicesCompletion(
          moveShipmentsBetweenInvoicesResult,
          MoveType.Existing,
        );
      },
    });
  };

  const prev = async (newPage: number) => {
    await fetchOrders({
      last: rowsPerPage,
      before: pageInfo?.startCursor ?? undefined,
    });
    setPage(newPage);
  };
  const next = async (newPage: number) => {
    await fetchOrders({
      first: rowsPerPage,
      after: pageInfo?.endCursor ?? undefined,
    });
    setPage(newPage);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    invoiceUuid,
    rowsPerPage,
    debouncedSearchText,
    sort,
    shouldRefreshInvoiceList,
  ]);

  const bolString = segment === Segment.Cartage ? 'HAWB' : 'Pro #';
  const refString = segment === Segment.Cartage ? 'Ref #' : 'Secondary Ref #';

  return (
    <TableContainer
      sx={{
        maxHeight: height - 305,
        overflowY: 'scroll',
      }}
    >
      <Grid
        container
        alignItems="center"
        sx={{
          paddingLeft: inCustomersTab ? undefined : 2,
          paddingRight: 2,
          paddingBottom: 1,
        }}
      >
        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={moveOrdersToFreshInvoiceSuccess}
          onClose={() => {
            setMoveOrdersToFreshInvoiceSuccess(false);
          }}
        >
          <Alert> New invoice created successfully</Alert>
        </Snackbar>
        {!isNil(moveOrdersToFreshInvoiceOutput) &&
          moveOrdersToFreshInvoiceOutput?.__typename ===
            'MutationErrorOutput' && (
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={
                moveOrdersToFreshInvoiceOutput?.__typename ===
                'MutationErrorOutput'
              }
              onClose={() => {
                setMoveOrdersToFreshInvoiceOutput(null);
              }}
            >
              <Alert
                severity="error"
                onClose={() => {
                  setMoveOrdersToFreshInvoiceOutput(null);
                }}
              >
                {moveOrdersToFreshInvoiceOutput?.message}
              </Alert>
            </Snackbar>
          )}
        <Grid item xs={4}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <TextField
              size="small"
              label="Search Orders"
              InputProps={{ style: { backgroundColor: 'white' } }}
              value={searchText}
              sx={{ maxWidth: '300px' }}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            {loading && <CircularProgress size={20} />}
          </Stack>
        </Grid>
        <Grid item xs={8}>
          <Stack
            direction="row"
            sx={{ width: '100%' }}
            justifyContent="flex-end"
            alignItems="center"
          >
            {!inCustomersTab && (
              <>
                <Button
                  size="small"
                  color="info"
                  disabled={
                    isEmpty(selectedInvoiceOrderUuids) || disableInvoiceEditing
                  }
                  aria-haspopup="menu"
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  Bulk Actions
                  {selectedInvoiceOrderUuids.length > 0
                    ? ` (${selectedInvoiceOrderUuids.length})`
                    : null}
                  <ArrowDropDownIcon />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  onClose={() => {
                    setAnchorEl(null);
                  }}
                >
                  <MenuItem
                    onClick={async () =>
                      moveOrdersToFreshInvoice({
                        additionalShipmentUuidsToMove: [],
                      })
                    }
                  >
                    Move to a new invoice
                  </MenuItem>
                  {!isNil(contactUuid) && (
                    <MenuItem
                      onClick={() => {
                        setShowInvoiceSelectionMoveModal(true);
                      }}
                    >
                      Move to another invoice
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={async () =>
                      removeOrdersFromInvoice(selectedInvoiceOrderUuids)
                    }
                  >
                    Remove
                  </MenuItem>
                </Menu>
                {!isNil(contactUuid) && (
                  <SelectInvoiceToMoveToModal
                    isOpen={showInvoiceSelectionMoveModal}
                    handleConfirm={async (newInvoiceUuid: string) =>
                      moveOrdersToExistingInvoice({
                        newInvoiceUuid,
                        additionalShipmentUuidsToMove:
                          moveOrdersToExistingInvoiceOutput?.__typename ===
                          'MoveShipmentsBetweenInvoicesIncompleteOperationOutput'
                            ? moveOrdersToExistingInvoiceOutput.additionalShipmentUuidsToMove
                            : [],
                      })
                    }
                    moveOperationOutput={moveOrdersToExistingInvoiceOutput}
                    moveOperationLoading={moveOperationLoading}
                    onClose={onCloseMoveModal}
                  />
                )}
                {!isNil(contactUuid) &&
                  moveOrdersToFreshInvoiceOutput?.__typename ===
                    'MoveShipmentsBetweenInvoicesIncompleteOperationOutput' && (
                    <AdditionalShipmentsToBeMovedToNewInvoiceModal
                      isOpen={showAdditionalShipmentsToBeMovedToNewInvoiceModal}
                      handleConfirm={async () =>
                        moveOrdersToFreshInvoice({
                          additionalShipmentUuidsToMove:
                            moveOrdersToFreshInvoiceOutput?.__typename ===
                            'MoveShipmentsBetweenInvoicesIncompleteOperationOutput'
                              ? moveOrdersToFreshInvoiceOutput.additionalShipmentUuidsToMove
                              : [],
                        })
                      }
                      moveOperationOutput={moveOrdersToFreshInvoiceOutput}
                      onClose={onCloseMoveModal}
                    />
                  )}
              </>
            )}
            <TablePagination
              labelRowsPerPage="Show"
              rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
              component="div"
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{
                disabled: loading || page === 0,
              }}
              nextIconButtonProps={{
                disabled:
                  loading || page + 1 === Math.ceil(totalCount / rowsPerPage),
              }}
              onPageChange={(e, newPage: number) => {
                if (newPage > page) {
                  next(newPage);
                } else {
                  prev(newPage);
                }
              }}
              onRowsPerPageChange={(e) => {
                setRowsPerPage(Number(e.target.value));
              }}
            />
          </Stack>
        </Grid>
      </Grid>

      <Table
        stickyHeader
        aria-label="invoice-preview-table"
        size="small"
        sx={{
          '& .MuiTableCell-sizeSmall': {
            padding: '5px 10px',
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>
              {!inCustomersTab && (
                <Checkbox
                  size="small"
                  checked={isSelectAllCheckboxSelected}
                  disabled={disableInvoiceEditing}
                  onClick={(e) => {
                    if (isSelectAllCheckboxSelected) {
                      deselectSelectAllCheckbox();
                    } else {
                      setSelectedInvoiceOrderUuids(orders.map((o) => o.uuid));
                      setIsSelectAllCheckboxSelected(true);
                    }
                    e.stopPropagation();
                  }}
                />
              )}
            </TableCell>
            <TableCell>Order #</TableCell>
            <TableCell>
              {SingleColumnInvoiceOrdersTableSortLabel({
                label: bolString,
                sortBy: FindOrdersOnInvoiceSortFields.ShipperBillOfLadingNumber,
                currentSort: sort,
                setSort,
              })}
            </TableCell>
            <TableCell>
              {SingleColumnInvoiceOrdersTableSortLabel({
                label: 'MAWB',
                sortBy:
                  FindOrdersOnInvoiceSortFields.MasterAirwayBillOfLadingNumber,
                currentSort: sort,
                setSort,
              })}
            </TableCell>
            <TableCell>{refString}</TableCell>
            <TableCell>Total</TableCell>
            {inCustomersTab && <TableCell>Balance</TableCell>}
            <TableCell>Payments</TableCell>
            <TableCell>
              {SingleColumnInvoiceOrdersTableSortLabel({
                label: 'Service date',
                sortBy: FindOrdersOnInvoiceSortFields.ServiceDate,
                currentSort: sort,
                setSort,
              })}
            </TableCell>
            {inCustomersTab && <TableCell>Pcs</TableCell>}
            {inCustomersTab && <TableCell>Wt</TableCell>}
            <TableCell sx={{ minWidth: '200px', maxWidth: '200px' }}>
              {SingleColumnInvoiceOrdersTableSortLabel({
                label: 'Consignee',
                sortBy: FindOrdersOnInvoiceSortFields.Consignee,
                currentSort: sort,
                setSort,
              })}
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <InvoiceOrderListRow
              key={order.uuid}
              order={order}
              disableInvoiceEditing={disableInvoiceEditing}
              removeFromInvoice={() => {
                removeOrdersFromInvoice([order.uuid]);
              }}
              invoiceUuid={invoiceUuid}
              invoiceContactUuid={contactUuid ?? ''}
              inCustomersTab={inCustomersTab}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InvoiceOrdersList;
