import { type QueryHookOptions } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import {
  type FindPaginatedOrganizationsInput,
  type PaginatedOrganizationsQuery,
  type PaginatedOrganizationsQueryVariables,
  usePaginatedOrganizationsQuery,
} from '../../generated/graphql';

const DEFAULT_PAGE_SIZE = 20;

export const useOrganizationsForTable = ({
  first,
  last,
  after,
  before,
  searchText,
  isActive,
  ...queryHookOptions
}: FindPaginatedOrganizationsInput &
  QueryHookOptions<
    PaginatedOrganizationsQuery,
    PaginatedOrganizationsQueryVariables
  >) => {
  const { data, loading, error, refetch } = usePaginatedOrganizationsQuery({
    variables: {
      findPaginatedOrganizationsInput: {
        first,
        last,
        after,
        before,
        searchText,
        isActive,
      },
    },
    ...queryHookOptions,
  });
  const [page, setPage] = useState<number>(0);

  const pageInfo = data?.paginatedOrganizations?.pageInfo;

  const refetchWithInitialVariables = useCallback(
    async (variables: FindPaginatedOrganizationsInput) =>
      refetch({
        findPaginatedOrganizationsInput: {
          ...variables,
        },
      }),
    [refetch],
  );

  useEffect(() => {
    setPage(0);
    void refetchWithInitialVariables({
      first: DEFAULT_PAGE_SIZE,
      searchText,
      isActive,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, isActive]);

  const prev = async () => {
    await refetchWithInitialVariables({
      last: DEFAULT_PAGE_SIZE,
      before: pageInfo?.startCursor ?? undefined,
      searchText,
      isActive,
    });
  };

  const next = async () => {
    await refetchWithInitialVariables({
      first: DEFAULT_PAGE_SIZE,
      after: pageInfo?.endCursor ?? undefined,
      searchText,
      isActive,
    });
  };

  return {
    ...data?.paginatedOrganizations,
    loading,
    error,
    refetch: refetchWithInitialVariables,
    next,
    prev,
    page,
    setPage,
  };
};
