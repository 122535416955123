import { TableCell, TableRow, Typography, useTheme } from '@mui/material';
import currency from 'currency.js';
import { isNil } from 'lodash';
import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { LINE_HAUL_CHARGE_TOTAL_TEST_ID } from '../../../../../../../../../constants';
import {
  type FreightChargeValues,
  type FuelChargeValues,
  type OrderFormValues,
} from '../../../../forms/types';
import { calculateLineHaulShipmentTotalCharge } from '../../../../forms/utils';
import LineHaulFreightChargeRow from './line-haul-freight-charge-row';
import LineHaulFuelChargeRow from './line-haul-fuel-charge-row';

const LineHaulCharge = () => {
  const { control, setValue } = useFormContext<OrderFormValues>();

  const freightTotal: FreightChargeValues['totalCharge'] = useWatch({
    control,
    name: `lineHaulShipment.freightCharge.totalCharge`,
  });
  const fuelTotal: FuelChargeValues['totalCharge'] = useWatch({
    control,
    name: `lineHaulShipment.freightCharge.fuelCharge.totalCharge`,
  });

  const totalCharge = calculateLineHaulShipmentTotalCharge({
    freightChargeTotal: freightTotal ?? 0,
    fuelChargeTotal: fuelTotal ?? 0,
  });

  useEffect(() => {
    setValue(`lineHaulShipment.totalCharge`, totalCharge);
  }, [totalCharge, setValue]);

  const theme = useTheme();

  return (
    <>
      <TableRow sx={{ backgroundColor: theme.palette.grey[100] }}>
        <TableCell>LINE HAUL</TableCell>
        <TableCell />
        <TableCell />
        <TableCell />
        <TableCell />
      </TableRow>
      <LineHaulFreightChargeRow />
      <LineHaulFuelChargeRow />
      <TableRow>
        <TableCell>Total</TableCell>
        <TableCell />
        <TableCell />
        <TableCell>
          <Typography
            textAlign="right"
            data-testid={LINE_HAUL_CHARGE_TOTAL_TEST_ID}
          >
            {isNil(totalCharge) ? '-' : currency(totalCharge).format()}
          </Typography>
        </TableCell>
        <TableCell />
      </TableRow>
    </>
  );
};

export default React.memo(LineHaulCharge);
