import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined';
import SendIcon from '@mui/icons-material/Send';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Stack, Tooltip, useTheme } from '@mui/material';
import { sentenceCase } from 'change-case';
import { exhaustive } from 'shared/switch';
import { StopDetailsStatus } from '../../../../generated/graphql';

const StopDetailsStatusIcon = ({
  status,
  message,
}: {
  readonly status: StopDetailsStatus;
  readonly message: string | undefined | null;
}) => {
  const theme = useTheme();
  let icon;
  switch (status) {
    case StopDetailsStatus.OnHand: {
      icon = (
        <PanToolOutlinedIcon
          color="success"
          sx={{
            fontSize: '13px',
          }}
        />
      );
      break;
    }
    case StopDetailsStatus.OnRoute: {
      icon = (
        <EventAvailableOutlinedIcon
          color="info"
          sx={{
            fontSize: '13px',
          }}
        />
      );
      break;
    }
    case StopDetailsStatus.InboundRequired: {
      icon = (
        <WarningAmberOutlinedIcon
          color="warning"
          sx={{
            fontSize: '13px',
          }}
        />
      );
      break;
    }
    case StopDetailsStatus.AwaitingSendToAgent: {
      icon = (
        <SendIcon
          color="disabled"
          sx={{
            fontSize: '13px',
          }}
        />
      );
      break;
    }
    case StopDetailsStatus.AwaitingAgentConfirmation: {
      icon = (
        <SendIcon
          sx={{
            color: theme.palette.airfreightBlue[60],
            fontSize: '13px',
          }}
        />
      );
      break;
    }
    default: {
      return exhaustive(status);
    }
  }

  return (
    <Tooltip
      title={
        <Stack>
          <span>{sentenceCase(status)}</span>
          <span>{message}</span>
        </Stack>
      }
    >
      {icon}
    </Tooltip>
  );
};

export default StopDetailsStatusIcon;
