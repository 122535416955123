import {
  Box,
  Chip,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isArray } from 'lodash';
import { type Control, Controller } from 'react-hook-form';
import { type OrganizationFormValues } from '../form/types';
import { useFindServicesForCustomerConfigurationQuery } from '../../../generated/graphql';
import { useParams } from 'react-router-dom';
import { isNilOrEmptyString } from '../../../common/utils/utils';
import { useMemo } from 'react';

const ContactServiceLevelsMultiSelect = ({
  control,
  disabled,
}: {
  readonly control: Control<OrganizationFormValues>;
  readonly disabled: boolean;
}) => {
  const { organizationUuid } = useParams();

  const { data: servicesData } = useFindServicesForCustomerConfigurationQuery(
    isNilOrEmptyString(organizationUuid)
      ? { skip: true }
      : {
          variables: {
            findServicesForCustomerConfigurationInput: {
              organizationUuid,
            },
          },
        },
  );

  const unarchivedServices = useMemo(
    () =>
      servicesData?.findServicesForCustomerConfiguration?.__typename ===
      'FindServicesForCustomerConfigurationSuccessOutput'
        ? servicesData?.findServicesForCustomerConfiguration.services
        : [],
    [servicesData],
  );

  return (
    <FormControl sx={{ flex: 1 }}>
      <FormLabel>Service levels</FormLabel>
      <Controller
        name="serviceUuids"
        control={control}
        defaultValue={[]}
        render={({ field }) => (
          <Select
            {...field}
            multiple
            value={field.value ?? []} // Ensure value is always an array
            size="medium"
            disabled={disabled}
            renderValue={(selected) => {
              if (isArray(selected)) {
                return (
                  <Stack flexDirection="row" flexWrap="wrap" gap={0.5}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={sentenceCase(
                          unarchivedServices.find(
                            (service) => service.uuid === value,
                          )?.name ?? '',
                        )}
                      />
                    ))}
                  </Stack>
                );
              }
              return null;
            }}
          >
            {unarchivedServices?.map((unarchivedService) => (
              <MenuItem
                key={unarchivedService.uuid}
                value={unarchivedService.uuid}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={1}
                  width="100%"
                  height={24}
                >
                  {sentenceCase(unarchivedService.name)}
                  {!unarchivedService?.isActive && (
                    <Chip label="Archived" size="small" />
                  )}
                </Stack>
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormControl>
  );
};

export default ContactServiceLevelsMultiSelect;
