import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
import {
  Chip,
  Checkbox,
  Skeleton,
  TableCell,
  TableRow,
  Stack,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React, { type Dispatch, type SetStateAction } from 'react';
import { useSearchParams } from 'react-router-dom';
import { safeDivide } from 'shared/math';
import { transformDateTimeToDateTimeString } from '../../../../common/utils/prettyPrintUtils';
import {
  type CompanyConfigurationFragment,
  InvoiceStatus,
  type ShallowInvoiceV2Fragment,
} from '../../../../generated/graphql';
import useInvoiceEmailStatuses from '../../hooks/use-invoice-email-statuses';
import useInvoiceTotals from '../../hooks/use-invoice-totals';
import useInvoicesStore from '../../invoices-store';
import EmailTransactionStatusChip from './transmissions/emails/email-transaction-status-chip';

const QuickbooksSyncStatus = ({
  syncDate,
}: {
  readonly syncDate?: Date | null;
}) => {
  const icon = isNil(syncDate) ? (
    <SyncDisabledIcon fontSize="small" />
  ) : (
    <CheckCircleIcon color="success" fontSize="small" />
  );
  const syncStatus = isNil(syncDate)
    ? 'Not synced'
    : transformDateTimeToDateTimeString(dayjs(syncDate).toISOString());
  return (
    <TableCell valign="baseline">
      <Stack direction="row" alignItems="center" gap={1}>
        {icon}
        {syncStatus}
      </Stack>
    </TableCell>
  );
};

type InvoiceListRowProps = {
  readonly invoice: ShallowInvoiceV2Fragment;
  readonly companyConfiguration?: CompanyConfigurationFragment | null;
  readonly openedInvoiceUuid: string | undefined;
  readonly setOpenedInvoiceUuid: Dispatch<SetStateAction<string | undefined>>;
  readonly showInvoiceDetails: boolean;
  readonly setShowInvoiceDetails: Dispatch<SetStateAction<boolean>>;
  readonly isUsingQuickbooks: boolean;
  readonly isUsingQuickbooksDesktop: boolean;
};

const InvoiceListRow = ({
  invoice,
  companyConfiguration,
  openedInvoiceUuid,
  setOpenedInvoiceUuid,
  showInvoiceDetails,
  setShowInvoiceDetails,
  isUsingQuickbooks,
  isUsingQuickbooksDesktop,
}: InvoiceListRowProps) => {
  const [, setSearchParams] = useSearchParams();
  const {
    uuid,
    status,
    date,
    createdAt,
    dueDate,
    lastSyncedToQuickbooksAt,
    quickbooksInvoiceId,
    syncedToQuickbooksDesktopAt,
    journalNumber,
    name,
  } = invoice;
  const invoiceName =
    !isNil(companyConfiguration) &&
    Boolean(companyConfiguration.useJournalNumberForInvoice)
      ? (journalNumber?.toString() ?? '')
      : (name ?? '');

  const { invoiceTotals, invoiceBalances } = useInvoiceTotals();
  const { invoiceEmailSendStatuses } = useInvoiceEmailStatuses();
  const invoiceTotal = invoiceTotals[uuid];
  const balanceInCents = invoiceBalances[uuid];
  const emailSendStatus = invoiceEmailSendStatuses[uuid];

  const isChecked = useInvoicesStore((state) =>
    state.selectedInvoiceUuids.includes(uuid),
  );
  const selectInvoiceUuid = useInvoicesStore(
    (state) => state.selectInvoiceUuid,
  );
  const unselectInvoiceUuid = useInvoicesStore(
    (state) => state.unselectInvoiceUuid,
  );

  const onCheck = () => {
    if (isChecked) {
      unselectInvoiceUuid(uuid);
    } else {
      selectInvoiceUuid(uuid, status === InvoiceStatus.NotFinalized);
    }
  };

  return (
    <TableRow
      hover
      selected={uuid === openedInvoiceUuid}
      role="checkbox"
      sx={{ cursor: 'pointer' }}
      onClick={() => {
        setSearchParams((sp) => {
          const newParams = new URLSearchParams(sp);
          newParams.delete('invoiceName');
          newParams.set('invoiceUuid', uuid);
          return newParams;
        });
        setOpenedInvoiceUuid(uuid);
        setShowInvoiceDetails(true);
      }}
    >
      <TableCell>
        <Checkbox
          checked={isChecked}
          onClick={(e) => {
            onCheck();
            e.stopPropagation();
          }}
        />
      </TableCell>
      <TableCell>{invoiceName}</TableCell>
      {!showInvoiceDetails && (
        <TableCell>
          {status === InvoiceStatus.ReadyToInvoice
            ? 'Posted'
            : sentenceCase(status)}
        </TableCell>
      )}
      <TableCell>{invoice.billToContact.displayName}</TableCell>
      <TableCell align="center">
        {isNil(emailSendStatus) ? (
          <Chip size="small" label="Not sent" />
        ) : (
          <EmailTransactionStatusChip status={emailSendStatus} />
        )}
      </TableCell>
      <TableCell>
        {isNil(invoiceTotal) ? <Skeleton /> : currency(invoiceTotal).format()}
      </TableCell>
      <TableCell>
        {isNil(balanceInCents) ? (
          <Skeleton />
        ) : (
          currency(safeDivide(balanceInCents, 100)).format()
        )}
      </TableCell>
      {!showInvoiceDetails &&
        isUsingQuickbooks &&
        QuickbooksSyncStatus({
          syncDate: isNil(quickbooksInvoiceId)
            ? null
            : lastSyncedToQuickbooksAt,
        })}
      {!showInvoiceDetails &&
        isUsingQuickbooksDesktop &&
        QuickbooksSyncStatus({ syncDate: syncedToQuickbooksDesktopAt })}
      {!showInvoiceDetails && (
        <>
          <TableCell>{dayjs(createdAt).format('MM/DD/YY')}</TableCell>
          <TableCell>{dayjs(date).format('MM/DD/YY')}</TableCell>
        </>
      )}
      <TableCell>
        {isNil(dueDate) ? 'None' : dayjs(dueDate).format('MM/DD/YY')}
      </TableCell>
    </TableRow>
  );
};

export default React.memo(InvoiceListRow);
