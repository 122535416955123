import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import dayjs, { type Dayjs } from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import moment from 'moment';
import { type Dispatch, type SetStateAction, useEffect, useState } from 'react';
import { DateObject } from 'react-multi-date-picker';

const parseTimeString = (timeInput: string, dateString: string) => {
  let date = moment(`${dateString} ${timeInput}`).toDate();
  if (Number.isNaN(date.getTime())) {
    // First parsing is adding a space between the am/pm and the time value
    const parsedTime = timeInput.toLowerCase().replace(/[ap]/, ' $&');
    date = moment(`${dateString} ${parsedTime}`).toDate();
    if (!Number.isNaN(date.getTime())) {
      return new DateObject(date);
    }
    // Second parse is for if there is no colon, add one into the time value.
    if (!parsedTime.includes(':')) {
      const timeValue = parsedTime.split(' ')[0];
      if (timeValue?.length === 3) {
        date = moment(
          `${dateString} ${parsedTime.slice(0, 1)}:${parsedTime.slice(1)}`,
        ).toDate();
        if (!Number.isNaN(date.getTime())) {
          return new DateObject(date);
        }
      }
      if (timeValue?.length === 4) {
        date = moment(
          `${dateString} ${parsedTime.slice(0, 2)}:${parsedTime.slice(2)}`,
        ).toDate();
        if (!Number.isNaN(date.getTime())) {
          return new DateObject(date);
        }
      }
    }
    return null;
  }
  return new DateObject(date);
};

type TimePickerComponentProps = {
  readonly appointmentTime: string | Date | undefined | null;
  readonly updateAppointmentTime: (
    appointmentTime: Dayjs | undefined | null,
  ) => void;
  readonly hideClearable?: boolean;
  readonly onClear?: () => void;
  readonly onFocus?: () => void;
  readonly onBlur?: () => void;
  readonly disabled?: boolean;
  readonly placeholder?: string;
  readonly hideError?: boolean;
  readonly minimized?: boolean;
  readonly minimizedWidth?: number;
  readonly width?: string | number;
  readonly fullWidth?: boolean;
  readonly label?: string;
  readonly testId?: string;
};

const TimePickerComponent = ({
  appointmentTime,
  updateAppointmentTime,
  hideClearable,
  onClear,
  onFocus = () => {},
  onBlur = () => {},
  disabled,
  placeholder,
  hideError = false,
  minimized = false,
  minimizedWidth = 90,
  width,
  fullWidth,
  label,
  testId,
}: TimePickerComponentProps) => {
  const [appointmentTimeInput, setAppointmentTimeInput] = useState('');
  const [appointmentTimeError, setAppointmentTimeError] = useState('');

  useEffect(() => {
    if (!isNil(appointmentTime) && isEmpty(appointmentTimeInput)) {
      setAppointmentTimeInput(dayjs(appointmentTime ?? '').format('HH:mm'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentTime]);

  const appointmentTimeOnChange = (
    appointmentTimeChange: DateObject | null,
  ) => {
    try {
      const appointmentTimeDate = new Date();
      const time = isNil(appointmentTimeChange)
        ? undefined
        : dayjs()
            .set('year', appointmentTimeDate.getFullYear())
            .set('month', appointmentTimeDate.getMonth())
            .set('date', appointmentTimeDate.getDate())
            .set('hours', appointmentTimeChange.hour)
            .set('minute', appointmentTimeChange.minute);
      updateAppointmentTime(time);
    } catch {
      // To-do: Upsert error
    }
  };

  const onTimeInputChange = (
    timeInput: string,
    onChange: (date: DateObject) => void,
    setTimeError: Dispatch<SetStateAction<string>>,
  ) => {
    const fullDate = isNil(appointmentTime) ? dayjs() : dayjs(appointmentTime);
    const d = moment(fullDate.toDate()).format('L');
    const date = parseTimeString(timeInput, d)?.toDate();
    if (isNil(date) || Number.isNaN(date.getTime())) {
      setTimeError('Time is invalid.');
    } else {
      onChange(new DateObject(date));
      setTimeError('');
    }
  };

  return (
    <Box sx={{ maxWidth: '95px' }}>
      <TextField
        placeholder={placeholder}
        disabled={disabled}
        value={appointmentTimeInput}
        size="small"
        label={label}
        variant={minimized ? 'standard' : 'outlined'}
        InputProps={{
          inputProps: {
            style: minimized
              ? {
                  fontSize: '14px',
                  width: minimizedWidth,
                }
              : { width },
            'data-testid': testId,
          },
          endAdornment:
            hideClearable !== true && disabled !== true ? (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    updateAppointmentTime(null);
                    setAppointmentTimeInput('');
                    onClear?.();
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ) : null,
        }}
        sx={{
          backgroundColor: 'white',
          width: fullWidth === true ? '100%' : undefined,
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={async (e) => {
          // Spaces are not allowed.
          const trimmedValue = e.target.value.trim();
          if (trimmedValue.length > 0) {
            onTimeInputChange(
              trimmedValue,
              appointmentTimeOnChange,
              setAppointmentTimeError,
            );
            setAppointmentTimeInput(trimmedValue);
          } else {
            updateAppointmentTime(null);
            setAppointmentTimeInput('');
          }
        }}
      />
      {!hideError && !isEmpty(appointmentTimeError) && (
        <FormHelperText sx={{ color: 'red' }}>
          {appointmentTimeError}
        </FormHelperText>
      )}
    </Box>
  );
};

export default TimePickerComponent;
