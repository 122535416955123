import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  TableCell,
  TableRow,
  Typography,
  Stack,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import { isNil } from 'lodash';
import { useFormContext, useWatch } from 'react-hook-form';
import { FUEL_CHARGE_TOTAL_TEST_ID } from '../../../../../../../../../constants';
import { getStopFuelChargeTestIds } from '../../../../../../../../../utils';
import { FuelBillingMethod } from '../../../../../../../../generated/graphql';
import { useOrderFormChargesEditAccess } from '../../../../contexts/order-form-charges-edit-access-context';
import { type OrderFormFieldValues } from '../../../../forms/types';
import { contextToFuelChargePrefix } from '../../../../utils';
import AuthoCodeComponent, {
  MT_IF_HAS_AUTHO_CODE_STYLES,
} from '../autho-code-component';
import DescriptionComponent, {
  MT_IF_HAS_DESCRIPTION_STYLES,
} from '../description-component';
import FuelChargeRateInput from './fuel-charge-rate-input';
import { type OrderShipmentContext } from '../../../../types';
import { handleFuelBillingMethodChange } from '../../utils';

export type FuelChargeRowProps = {
  readonly idx: number;
  readonly context: OrderShipmentContext;
};

const FuelChargeRow = ({ idx, context }: FuelChargeRowProps) => {
  const { inSettlement } = context;
  const namePrefix = contextToFuelChargePrefix(context);

  const { control, setValue } = useFormContext<OrderFormFieldValues>();

  const { editingChargesDisabled } = useOrderFormChargesEditAccess({
    chargesContext: context,
  });

  const fuelChargeUuid = useWatch({
    control,
    name: `${namePrefix}.uuid`,
  });
  const billingMethod = useWatch({
    control,
    name: `${namePrefix}.billingMethod`,
  });

  const totalCharge = useWatch({
    control,
    name: `${namePrefix}.totalCharge`,
  });

  const surchargeRate = useWatch({
    control,
    name: `${namePrefix}.surchargeRate`,
  });

  const flatRateDollars = useWatch({
    control,
    name: `${namePrefix}.flatRateDollars`,
  });

  const authoCode = useWatch({
    control,
    name: `${namePrefix}.authoCode`,
  });

  const onChangeBillingMethod = (event: SelectChangeEvent) => {
    const billingMethod = event.target.value as FuelBillingMethod;
    handleFuelBillingMethodChange({
      billingMethod,
      setValue,
      fieldPrefix: namePrefix,
      currentSurchargeRate: surchargeRate,
      currentFlatRateDollars: flatRateDollars,
    });
  };

  if (isNil(fuelChargeUuid)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  const { fuelChargeTypeSelectTestId, fuelChargeRateInputTestId } =
    getStopFuelChargeTestIds({
      stopIdx: idx,
    });

  return (
    <TableRow>
      <TableCell>
        <FormControl
          sx={{
            ...MT_IF_HAS_DESCRIPTION_STYLES,
            width: '100%',
          }}
        >
          <InputLabel>Fuel</InputLabel>
          <Select
            required
            data-testid={fuelChargeTypeSelectTestId}
            inputProps={{
              'aria-label': 'Fuel charge select',
            }}
            size="small"
            value={billingMethod}
            sx={{ flex: 1 }}
            label="Fuel Type"
            disabled={editingChargesDisabled}
            onChange={onChangeBillingMethod}
          >
            {Object.values(FuelBillingMethod).map((method) => (
              <MenuItem key={method} value={method}>
                {sentenceCase(method)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {!inSettlement && (
          <DescriptionComponent
            keyString={namePrefix}
            disabled={editingChargesDisabled}
          />
        )}
      </TableCell>
      <TableCell>
        <FuelChargeRateInput
          fuelChargePrefix={namePrefix}
          billingMethod={billingMethod}
          disabled={editingChargesDisabled}
          fuelChargeRateInputTestId={fuelChargeRateInputTestId}
        />
      </TableCell>
      <TableCell>1</TableCell>
      <TableCell>
        <Stack alignItems="flex-end" justifyContent="flex-end">
          <Typography
            sx={MT_IF_HAS_AUTHO_CODE_STYLES}
            data-testid={FUEL_CHARGE_TOTAL_TEST_ID}
          >
            {isNil(totalCharge) ? '-' : currency(totalCharge).format()}
          </Typography>
          {!inSettlement && (
            <AuthoCodeComponent
              authoCode={authoCode ?? ''}
              keyString={namePrefix}
              disabled={editingChargesDisabled}
            />
          )}
        </Stack>
      </TableCell>
      <TableCell />
    </TableRow>
  );
};

export default FuelChargeRow;
