import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { type OrderFormValues } from '../../forms/types';
import PalletModal from '../../../../../../pallet-ui/modal/pallet-modal';
import { useUpdatePieceCountMutation } from '../../../../../../generated/graphql';
import PalletButton from '../../../../../../pallet-ui/button/pallet-button';
import { shallow } from 'zustand/shallow';
import useGlobalStore from '../../../../../../layouts/dashboard/global-store';
import { useUpdateAndRefetchOrder } from '../../hooks/use-update-and-refetch-order';
import { type OnSubmitParams } from '../../types';

type EditOnHandCountModalProps = {
  readonly open: boolean;
  readonly onSubmit: (params: OnSubmitParams) => Promise<boolean>;
  readonly onClose: () => void;
};

const EditOnHandCountModal = ({
  open,
  onClose,
  onSubmit,
}: EditOnHandCountModalProps) => {
  const { getValues, setValue } = useFormContext<OrderFormValues>();
  const [count, setCount] = useState(() => getValues('pieceCount') ?? 0);
  const inputRef = useRef<HTMLInputElement>(null);

  const [
    setSuccessMessage,
    setShowSuccessMessage,
    setErrorMessage,
    setShowErrorMessage,
  ] = useGlobalStore(
    (state) => [
      state.setSuccessMessage,
      state.setShowSuccessMessage,
      state.setErrorMessage,
      state.setShowErrorMessage,
    ],
    shallow,
  );
  const { updateAndRefetchOrder } = useUpdateAndRefetchOrder();

  const [updatePieceCount, { loading: isLoading }] =
    useUpdatePieceCountMutation({
      onCompleted: () => {
        setSuccessMessage('Packages on hand updated');
        setShowSuccessMessage(true);
      },
      onError: (error) => {
        setErrorMessage(error.message);
        setShowErrorMessage(true);
      },
    });

  // auto focus the input when the modal opens
  useEffect(() => {
    if (open) {
      // Use a small timeout to ensure the modal is fully rendered
      setTimeout(() => {
        inputRef.current?.focus();
        inputRef.current?.select();
      }, 100);
    }
  }, [open]);

  const handleIncrement = () => {
    setCount(count + 1);
  };

  const handleDecrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  const handleUpdate = async () => {
    setValue('pieceCount', count);
    await updateAndRefetchOrder({
      additionalUpdateFns: [
        {
          fn: updatePieceCount,
          vars: {
            updatePieceCountInput: {
              uuid: getValues('uuid'),
              pieceCount: undefined,
            },
          },
        },
      ],
      onSubmit,
      actionString: 'marking as on hand',
    });
    onClose();
  };

  return (
    <PalletModal
      open={open}
      title="Update packages on hand"
      pinnedElements={{
        bottomLeft: (
          <PalletButton variant="text" onClick={onClose}>
            Cancel
          </PalletButton>
        ),
        bottomRight: (
          <PalletButton
            variant="contained"
            loading={isLoading}
            onClick={handleUpdate}
          >
            Update
          </PalletButton>
        ),
      }}
      onClose={onClose}
    >
      <Stack direction="row" gap={1} alignItems="center">
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          border="1px solid #e0e0e0"
          borderRadius={1}
          maxWidth="150px"
        >
          <IconButton size="small" onClick={handleDecrement}>
            <RemoveIcon />
          </IconButton>
          <TextField
            inputRef={inputRef}
            value={count}
            inputProps={{
              style: { textAlign: 'center' },
              min: 0,
            }}
            sx={{
              '& .MuiOutlinedInput-root fieldset': {
                border: 'none',
              },
            }}
            size="small"
            onChange={(e) => {
              if (e.target.value === '') {
                setCount(0);
                return;
              }
              const value = Number.parseInt(e.target.value, 10);
              if (!Number.isNaN(value) && value >= 0) {
                setCount(value);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                void handleUpdate();
              }
            }}
          />
          <IconButton size="small" onClick={handleIncrement}>
            <AddIcon />
          </IconButton>
        </Stack>
        <Typography color="text.secondary">packages</Typography>
      </Stack>
    </PalletModal>
  );
};

export default EditOnHandCountModal;
