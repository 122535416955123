import { TextField, Typography } from '@mui/material';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { isNotNil } from 'shared/optional';
import { isNilOrEmptyString } from 'shared/string';
import { exhaustive } from 'shared/switch';
import { FuelBillingMethod } from '../../../../../../../../generated/graphql';
import { type OrderFormFieldValues } from '../../../../forms/types';
import { useShouldRateOrder } from '../../../../hooks/use-should-rate-order';
import { type FuelChargePrefix } from '../../../../utils';

export type FuelChargeRateInputProps = {
  readonly fuelChargePrefix: FuelChargePrefix;
  readonly billingMethod: FuelBillingMethod;
  readonly disabled: boolean;
  readonly fuelChargeRateInputTestId: string;
};

const FuelChargeRateInput = ({
  fuelChargePrefix,
  billingMethod,
  disabled,
  fuelChargeRateInputTestId,
}: FuelChargeRateInputProps) => {
  const { control, setValue } = useFormContext<OrderFormFieldValues>();
  const detailedStatus = useWatch({ control, name: 'detailedStatus' });

  const surchargeRateDollars = useWatch({
    control,
    name: `${fuelChargePrefix}.surchargeRate`,
  });
  const flatRateDollars = useWatch({
    control,
    name: `${fuelChargePrefix}.flatRateDollars`,
  });

  const [surchargeRateDollarInput, setSurchargeRateDollarInput] = useState(
    surchargeRateDollars?.toString() ?? '',
  );
  const [flatRateDollarInput, setFlatRateDollarInput] = useState(
    flatRateDollars?.toString() ?? '',
  );

  useEffect(() => {
    setSurchargeRateDollarInput(surchargeRateDollars?.toString() ?? '');
  }, [surchargeRateDollars]);

  useEffect(() => {
    setFlatRateDollarInput(flatRateDollars?.toString() ?? '');
  }, [flatRateDollars]);

  const { shouldRateOrder } = useShouldRateOrder({
    detailedStatus,
  });

  if (billingMethod === FuelBillingMethod.None) {
    return <Typography>-</Typography>;
  }

  if (billingMethod === FuelBillingMethod.AutoCalculate && shouldRateOrder) {
    return (
      <Typography>
        {isNilOrEmptyString(surchargeRateDollars)
          ? 'None'
          : `${surchargeRateDollars}%`}
      </Typography>
    );
  }

  const onChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const parsedAmount = Number.parseFloat(e.target.value);
    const newValue = Number.isNaN(parsedAmount) ? null : parsedAmount;

    switch (billingMethod) {
      case FuelBillingMethod.Percentage: {
        setSurchargeRateDollarInput(e.target.value);
        setValue(`${fuelChargePrefix}.surchargeRate`, newValue);
        break;
      }
      case FuelBillingMethod.FlatRate: {
        setFlatRateDollarInput(e.target.value);
        setValue(`${fuelChargePrefix}.flatRateDollars`, newValue);
        break;
      }
      case FuelBillingMethod.AutoCalculate: {
        // Don't do anything since the rate is auto-calculated by the backend
        break;
      }
      default: {
        exhaustive(billingMethod);
      }
    }
  };

  const valueInput =
    billingMethod === FuelBillingMethod.Percentage
      ? surchargeRateDollarInput
      : flatRateDollarInput;

  return (
    <TextField
      size="small"
      disabled={disabled}
      label={sentenceCase(billingMethod?.toString() ?? '')}
      InputLabelProps={{
        // We need to control the shrink prop because MUI doesn't automatically shrink the
        // label when the value prop is updated by use-rate-order.ts
        shrink: isNotNil(valueInput),
      }}
      value={valueInput}
      inputProps={{ 'data-testid': fuelChargeRateInputTestId }}
      onChange={onChange}
      onBlur={onChange}
    />
  );
};

export default FuelChargeRateInput;
