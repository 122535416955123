import {
  Card,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Tooltip,
  Typography,
  Box,
  Radio,
  RadioGroup,
} from '@mui/material';
import { isNil } from 'lodash';
import { type Dispatch, type SetStateAction, useEffect, useMemo } from 'react';
import {
  OrderConsolidationMethod,
  type OrderForConsolidationFlowFragment,
} from '../../../../../generated/graphql';
import theme from '../../../../../theme';
import { z } from 'zod';

export enum ChargesToConsolidate {
  INBOUND_CHARGES = 'INBOUND',
  OUTBOUND_CHARGES = 'OUTBOUND',
}

const ConsolidationOptionsPanel = ({
  selectedChargesToConsolidate,
  setSelectedChargesToConsolidate,
  lineHaulEnabled,
  primaryOrder,
  consolidationMethod,
  setConsolidationMethod,
}: {
  readonly selectedChargesToConsolidate: Record<ChargesToConsolidate, boolean>;
  readonly setSelectedChargesToConsolidate: Dispatch<
    SetStateAction<Record<ChargesToConsolidate, boolean>>
  >;
  readonly lineHaulEnabled: boolean;
  readonly primaryOrder: OrderForConsolidationFlowFragment;
  readonly consolidationMethod: OrderConsolidationMethod;
  readonly setConsolidationMethod: Dispatch<
    SetStateAction<OrderConsolidationMethod>
  >;
}) => {
  const { inboundShipmentValid, outboundShipmentValid } = useMemo(() => {
    return {
      inboundShipmentValid: !isNil(primaryOrder.inboundShipment?.freightCharge),
      outboundShipmentValid: !isNil(
        primaryOrder.outboundShipment?.freightCharge,
      ),
    };
  }, [primaryOrder.inboundShipment, primaryOrder.outboundShipment]);

  useEffect(() => {
    if (isNil(selectedChargesToConsolidate)) {
      setSelectedChargesToConsolidate({
        INBOUND: inboundShipmentValid,
        OUTBOUND: outboundShipmentValid,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChargesToConsolidate]);

  useEffect(() => {
    if (isNil(selectedChargesToConsolidate)) {
      setSelectedChargesToConsolidate({
        INBOUND: inboundShipmentValid,
        OUTBOUND: outboundShipmentValid,
      });
    } else {
      setSelectedChargesToConsolidate({
        ...selectedChargesToConsolidate,
        INBOUND: inboundShipmentValid,
        OUTBOUND: outboundShipmentValid,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inboundShipmentValid, outboundShipmentValid]);

  if (isNil(selectedChargesToConsolidate)) {
    return null;
  }

  return (
    <Stack paddingX={2} gap={1} paddingBottom={2}>
      <Card variant="outlined">
        <Stack
          paddingX={2}
          paddingY={1.5}
          borderBottom={`1px solid ${theme.palette.concreteGrey[30]}`}
          bgcolor={theme.palette.concreteGrey[20]}
        >
          <Typography fontWeight={600}>
            Select your consolidation options
          </Typography>
        </Stack>
        <Stack paddingX={2} paddingY={1}>
          <Typography fontSize={14}>
            Pro-rate weight-based charges for:
          </Typography>
          <FormGroup>
            <Stack mt={1} flexWrap="wrap" flexDirection="row">
              <Box width="50%">
                <FormControlLabel
                  control={
                    <Tooltip
                      title={
                        inboundShipmentValid &&
                        'Primary order requires an inbound shipment to pro-rate by inbound'
                      }
                    >
                      <span>
                        <Checkbox
                          checked={
                            selectedChargesToConsolidate.INBOUND &&
                            inboundShipmentValid
                          }
                          disabled={!inboundShipmentValid}
                          sx={{
                            cursor: inboundShipmentValid ? 'pointer' : 'auto',
                          }}
                          onChange={(e) => {
                            setSelectedChargesToConsolidate({
                              ...selectedChargesToConsolidate,
                              INBOUND: e.target.checked,
                            });
                          }}
                        />
                      </span>
                    </Tooltip>
                  }
                  label="Inbound freight & fuel"
                  sx={{
                    color: inboundShipmentValid ? 'inherit' : 'text.disabled',
                    cursor: inboundShipmentValid ? 'pointer' : 'default',
                    '& .MuiFormControlLabel-label': {
                      cursor: inboundShipmentValid ? 'pointer' : 'default',
                    },
                  }}
                />
              </Box>
              <Box width="50%">
                <FormControlLabel
                  control={
                    <span>
                      <Tooltip
                        title="Primary order requires an outbound shipment to pro-rate by outbound"
                        disableHoverListener={outboundShipmentValid}
                      >
                        <Checkbox
                          checked={
                            selectedChargesToConsolidate?.OUTBOUND &&
                            outboundShipmentValid
                          }
                          disabled={!outboundShipmentValid}
                          sx={{
                            cursor: outboundShipmentValid
                              ? 'pointer'
                              : 'not-allowed',
                          }}
                          onChange={(e) => {
                            setSelectedChargesToConsolidate({
                              ...selectedChargesToConsolidate,
                              OUTBOUND: e.target.checked,
                            });
                          }}
                        />
                      </Tooltip>
                    </span>
                  }
                  label="Outbound freight & fuel"
                  sx={{
                    color: outboundShipmentValid ? 'inherit' : 'text.disabled',
                    cursor: outboundShipmentValid ? 'pointer' : 'default',
                    '& .MuiFormControlLabel-label': {
                      cursor: outboundShipmentValid ? 'pointer' : 'default',
                    },
                  }}
                />
              </Box>
            </Stack>
          </FormGroup>
          <Stack mt={2}>
            <Typography fontSize={14}>
              Distribute the consolidated total to
            </Typography>
            <RadioGroup
              defaultValue={consolidationMethod}
              onChange={(e) => {
                const consolidationMethodSchema = z.nativeEnum(
                  OrderConsolidationMethod,
                );
                const result = consolidationMethodSchema.safeParse(
                  e.target.value,
                );
                if (result.success) {
                  setConsolidationMethod(result.data);
                }
              }}
            >
              <Stack mt={1} flexWrap="wrap" flexDirection="row">
                <Box width="50%">
                  <FormControlLabel
                    control={<Radio />}
                    value={OrderConsolidationMethod.ByWeight}
                    label="each order proportional to its weight"
                  />
                </Box>
                <Box width="50%">
                  <FormControlLabel
                    control={<Radio />}
                    value={OrderConsolidationMethod.EvenDistribution}
                    label="all orders evenly"
                  />
                </Box>
                <Box width="50%">
                  <FormControlLabel
                    control={<Radio />}
                    value={OrderConsolidationMethod.BaseOrder}
                    label="only the base order"
                  />
                </Box>
              </Stack>
            </RadioGroup>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};

export default ConsolidationOptionsPanel;
