import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import { isNil } from 'lodash';
import { memo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { LINE_HAUL_FUEL_CHARGE_TOTAL_TEST_ID } from '../../../../../../../../../constants';
import { getLineHaulFuelChargeTestIds } from '../../../../../../../../../utils';
import { FuelBillingMethod } from '../../../../../../../../generated/graphql';
import { useOrderFormEditAccess } from '../../../../contexts/order-form-edit-access-context';
import { type OrderFormFieldValues } from '../../../../types';
import DescriptionComponent, {
  MT_IF_HAS_DESCRIPTION_STYLES,
} from '../description-component';
import FuelChargeRateInput from '../fuel-charges/fuel-charge-rate-input';
import { handleFuelBillingMethodChange } from '../../utils';

const LineHaulFuelChargeRow = () => {
  const { control, setValue } = useFormContext<OrderFormFieldValues>();

  const fuelChargeKey = 'lineHaulShipment.freightCharge.fuelCharge';
  const billingMethod: FuelBillingMethod | undefined = useWatch({
    control,
    name: `lineHaulShipment.freightCharge.fuelCharge.billingMethod`,
  });

  const totalCharge = useWatch({
    control,
    name: `lineHaulShipment.freightCharge.fuelCharge.totalCharge`,
  });

  const surchargeRate = useWatch({
    control,
    name: `${fuelChargeKey}.surchargeRate`,
  });

  const flatRateDollars = useWatch({
    control,
    name: `${fuelChargeKey}.flatRateDollars`,
  });

  const onChangeBillingMethod = (event: SelectChangeEvent) => {
    const billingMethod = event.target.value as FuelBillingMethod;
    handleFuelBillingMethodChange({
      billingMethod,
      setValue,
      fieldPrefix: fuelChargeKey,
      currentSurchargeRate: surchargeRate,
      currentFlatRateDollars: flatRateDollars,
    });
  };

  const {
    lineHaulFuelChargeTypeSelectTestId,
    lineHaulFuelChargeRateInputTestId,
  } = getLineHaulFuelChargeTestIds();

  const { disabledIfFinalizedOrLater, disabledIfInvoicePosted } =
    useOrderFormEditAccess();

  return (
    <TableRow>
      <TableCell>
        <FormControl sx={MT_IF_HAS_DESCRIPTION_STYLES}>
          <InputLabel>Fuel</InputLabel>
          <Select
            required
            data-testid={lineHaulFuelChargeTypeSelectTestId}
            inputProps={{ 'aria-label': 'Fuel charge select' }}
            size="small"
            value={billingMethod}
            sx={{ width: '200px' }}
            label="Fuel"
            disabled={disabledIfFinalizedOrLater}
            onChange={onChangeBillingMethod}
          >
            {Object.values(FuelBillingMethod).map((method) => (
              <MenuItem key={method} value={method}>
                {sentenceCase(method)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <DescriptionComponent
          keyString={fuelChargeKey}
          disabled={disabledIfInvoicePosted}
        />
      </TableCell>
      <TableCell>
        <FuelChargeRateInput
          fuelChargePrefix={fuelChargeKey}
          billingMethod={billingMethod}
          disabled={disabledIfFinalizedOrLater}
          fuelChargeRateInputTestId={lineHaulFuelChargeRateInputTestId}
        />
      </TableCell>
      <TableCell>1</TableCell>
      <TableCell>
        <Typography
          data-testid={LINE_HAUL_FUEL_CHARGE_TOTAL_TEST_ID}
          textAlign="right"
        >
          {isNil(totalCharge) ? '-' : currency(totalCharge).format()}{' '}
        </Typography>
      </TableCell>
      <TableCell />
    </TableRow>
  );
};

export default memo(LineHaulFuelChargeRow);
