import { parse } from 'cookie';
import { decodeJwt } from 'jose';
import { useMemo } from 'react';
import { isNilOrEmptyString } from 'shared/string';
import { z } from 'zod';
import { EnvironmentVariables } from '../../environment-variables';

// This intentionally doesn't use .strict(), I'm only including the fields we might use now
const NEW_JWT_PAYLOAD_SCHEMA = z.object({
  version: z.string(),
  sub: z.string().uuid(),
  clientUuid: z.string().uuid(),
});

/** Reads the current clientUuid from the session cookie. */
export const useClientUuidFromSessionCookie = ():
  | {
      kind: 'success';
      clientUuid: string;
    }
  | {
      kind: 'error';
    } => {
  return useMemo(() => {
    const sessionCookie = parse(document.cookie)[
      EnvironmentVariables.VITE_SESSION_COOKIE_KEY
    ];

    if (isNilOrEmptyString(sessionCookie)) {
      return { kind: 'error' };
    }

    try {
      const decoded = decodeJwt(sessionCookie);

      const parsed = NEW_JWT_PAYLOAD_SCHEMA.safeParse(decoded);
      if (!parsed.success) {
        return { kind: 'error' };
      }

      return { kind: 'success', clientUuid: parsed.data.clientUuid };
    } catch {
      return { kind: 'error' };
    }
  }, []);
};
