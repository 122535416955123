import { Stack, TextField, Typography } from '@mui/material';
import AutocompleteFuzzy from '../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import useDrivers from '../../../../../common/react-hooks/use-drivers';
import useMe from '../../../../../common/react-hooks/use-me';
import { getDriverIdentifier } from '../../../../dispatch/utils';
import { useMemo, useState } from 'react';
import { type LineHaulManifest } from '../utils';
import {
  LineHaulManifestGroupsDocument,
  useUpdateManifestDriverMutation,
  type DriverFragment,
} from '../../../../../generated/graphql';
import DispatchableDriversAutocomplete from '../../../../../common/components/dispatchable-drivers-autocomplete';

// Type for the driver option in the AutocompleteFuzzy component
type DriverOption = {
  value: string;
  label: string;
};

const sortDriversByDriverIdentifier = (
  drivers: DriverFragment[],
  useDriverNumberForDispatch: boolean,
) => {
  return [...drivers].sort((a: DriverFragment, b: DriverFragment) =>
    getDriverIdentifier(a, useDriverNumberForDispatch, false).localeCompare(
      getDriverIdentifier(b, useDriverNumberForDispatch, false),
    ),
  );
};

type ManifestDriverProps = {
  readonly manifest: LineHaulManifest;
};

const ManifestDriver = ({ manifest }: ManifestDriverProps) => {
  const { companyConfiguration, loading: companyConfigurationLoading } =
    useMe();
  const { drivers, loading: driversLoading } = useDrivers();

  const [selectedDriverOption, setSelectedDriverOption] =
    useState<DriverOption | null>({
      label: getDriverIdentifier(
        manifest.driver,
        companyConfiguration?.useDriverNumberForDispatch ?? false,
        false,
      ),
      value: manifest.driver?.uuid ?? '',
    });
  const [updateManifestDriver] = useUpdateManifestDriverMutation({
    refetchQueries: [LineHaulManifestGroupsDocument],
  });

  // Update the driver on the manifest and refetch the manifests
  const updateDriverOnManifest = async (driverUuid: string) => {
    await updateManifestDriver({
      variables: {
        updateManifestDriverInput: {
          uuid: manifest.uuid,
          driverUuid,
        },
      },
    });
  };

  // Sort the drivers by driver identifier and map to the options for the AutocompleteFuzzy component
  const sortedDriversOptions = useMemo(
    () =>
      sortDriversByDriverIdentifier(
        drivers,
        companyConfiguration?.useDriverNumberForDispatch ?? false,
      ).map((d) => ({
        label: getDriverIdentifier(
          d,
          companyConfiguration?.useDriverNumberForDispatch,
          false,
        ),
        value: d.uuid,
      })),
    [drivers, companyConfiguration?.useDriverNumberForDispatch],
  );

  const isLoading = companyConfigurationLoading || driversLoading;

  return (
    <Stack direction="row" gap={2} alignItems="center" flex={1}>
      <Typography fontWeight={500}>Driver</Typography>
      <DispatchableDriversAutocomplete
        showTerminalNameForCompanyDrivers
        disabled={isLoading}
        selectedDriverUuid={manifest.driver?.uuid ?? null}
        terminalUuid={manifest.startTerminal?.uuid ?? null}
        driverTooltipText={null}
        onChangeDriver={(newDriver) => {
          void updateDriverOnManifest(newDriver?.uuid ?? '');
        }}
      />
    </Stack>
  );
};

export default ManifestDriver;
