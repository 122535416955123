import ModeIcon from '@mui/icons-material/Mode';
import {
  Chip,
  Fade,
  FormControl,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import type React from 'react';
import { useState } from 'react';
import {
  Controller,
  useFormContext,
  useWatch,
  type UseFormSetValue,
} from 'react-hook-form';
import DateField from '../../../../../../../common/components/date-field';
import { isNilOrEmptyString } from '../../../../../../../common/utils/utils';
import { useOrderFormEditAccess } from '../../../contexts/order-form-edit-access-context';
import { type OrderFormFieldValues } from '../../../forms/types';

type StopCompletedRowProps = {
  readonly idx: number;
  readonly completedAt: Date | null | undefined;
  readonly setValue: UseFormSetValue<OrderFormFieldValues>;
  readonly disabled: boolean;
  readonly equipmentNames: string | null | undefined;
  readonly driverName: string | null | undefined;
};

const StopCompletedRow: React.FC<StopCompletedRowProps> = ({
  idx,
  completedAt,
  setValue,
  disabled,
  equipmentNames,
  driverName,
}) => {
  const [isEditingPodSignee, setIsEditingPodSignee] = useState(false);
  const [isHoveringPodSignee, setIsHoveringPodSignee] = useState(false);

  const { control } = useFormContext<OrderFormFieldValues>();
  const proofOfDeliverySignee = useWatch({
    control,
    name: `stops.${idx}.proofOfDeliverySignee`,
  });
  const { disabledIfFinalizedOrLater } = useOrderFormEditAccess();
  return (
    <TableRow sx={{ border: 'none' }}>
      <TableCell sx={{ border: 'none' }}>
        <DateField
          date={completedAt ?? null}
          setDate={(date) => {
            setValue(`stops.${idx}.completedAt`, date);
          }}
          editable={!disabled}
        />
      </TableCell>
      <TableCell sx={{ border: 'none' }}>
        <Chip label="Completed" />
      </TableCell>
      <TableCell sx={{ border: 'none' }}>
        <Stack direction="column">
          {!isNilOrEmptyString(equipmentNames) && (
            <Typography variant="body2">
              Equipment: Used {equipmentNames}
            </Typography>
          )}
        </Stack>
        {!isEditingPodSignee && (
          <Stack
            direction="row"
            alignItems="center"
            onMouseEnter={() => {
              setIsHoveringPodSignee(true);
            }}
            onMouseLeave={() => {
              setIsHoveringPodSignee(false);
            }}
          >
            <Typography variant="body2">
              {isNil(proofOfDeliverySignee)
                ? 'No signee'
                : `Signed by ${proofOfDeliverySignee}`}
            </Typography>
            {!disabledIfFinalizedOrLater && (
              <Fade in={isHoveringPodSignee}>
                <IconButton
                  disabled={disabledIfFinalizedOrLater}
                  sx={{ p: 0 }}
                  onClick={() => {
                    setIsEditingPodSignee(true);
                  }}
                >
                  <ModeIcon sx={{ fontSize: '16px', p: 0 }} />
                </IconButton>
              </Fade>
            )}
          </Stack>
        )}
        {isEditingPodSignee && (
          <Stack direction="row" alignItems="center">
            <Controller
              name={`stops.${idx}.proofOfDeliverySignee`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    value={value}
                    label="Signee"
                    disabled={disabledIfFinalizedOrLater}
                    onChange={onChange}
                    onBlur={() => {
                      setIsEditingPodSignee(false);
                    }}
                  />
                </FormControl>
              )}
            />
          </Stack>
        )}
        {!isNilOrEmptyString(driverName) && (
          <Typography color="grey" variant="body2">
            Driver: {driverName}
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
};

export default StopCompletedRow;
