import { Button, Fade, TableCell, TableRow, Box, Dialog } from '@mui/material';
import { sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { safeAdd } from 'shared/math';
import ButtonLink from '../../../../common/components/buttons/button-link';
import {
  type QuoteFragment,
  QuoteSource,
  QuoteStatus,
} from '../../../../generated/graphql';
import ApproveQuoteModal from './approve-quote-area';
import { getQuoteSourceDisplayName } from '../../utils';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';

const QuoteRow = ({ quote }: { readonly quote: QuoteFragment }) => {
  const [isHovering, setIsHovering] = useState(false);
  const navigate = useNavigate();
  const [showQuotesModal, setShowQuotesModal] = useState(false);

  const ffCustomerPortalQuoteEntry = useFeatureFlag(
    FeatureFlag.FF_CUSTOMER_PORTAL_QUOTE_ENTRY,
  );

  return (
    <>
      {showQuotesModal && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={showQuotesModal}
          onClose={() => {
            setShowQuotesModal(false);
          }}
        >
          <ApproveQuoteModal
            open={showQuotesModal}
            setOpen={setShowQuotesModal}
            quoteUuid={quote.uuid}
            billingPartyContactUuid={quote.billingPartyContact.uuid}
            isFromQuoteProfile={false}
          />
        </Dialog>
      )}
      <TableRow
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
      >
        <TableCell>{quote.number}</TableCell>
        <TableCell>{dayjs(quote.createdAt).format('MM/DD')}</TableCell>
        <TableCell>{quote.billingPartyContact.displayName}</TableCell>
        <TableCell>
          {quote.shipments[0]?.legs[0]?.endStop.address.name ?? 'None'}
        </TableCell>
        <TableCell>{sentenceCase(quote.status)}</TableCell>
        <TableCell>
          $
          {quote.shipments
            .reduce((prev, curr) => safeAdd(prev, curr.totalChargesAmount), 0)
            .toFixed(2)}
        </TableCell>
        {ffCustomerPortalQuoteEntry && (
          <TableCell>{getQuoteSourceDisplayName(quote.source)}</TableCell>
        )}
        <TableCell>
          {/* This is to intentionally hide the open button for customer portal quotes, clients should interact with the user outside the TMS */}
          {quote.source !== QuoteSource.CustomerPortal && (
            <Fade in={isHovering}>
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                <Button
                  variant="contained"
                  onClick={() => {
                    navigate(`/order-entry/quotes/${quote.uuid}`);
                  }}
                >
                  Open
                </Button>
                {quote.status === QuoteStatus.Pending && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setShowQuotesModal(true);
                    }}
                  >
                    Approve
                  </Button>
                )}
                {!isNil(quote.order) && (
                  <ButtonLink
                    href={`/orders?orderUuid=${quote.order.uuid}`}
                    variant="contained"
                  >
                    Open order
                  </ButtonLink>
                )}
              </Box>
            </Fade>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default QuoteRow;
