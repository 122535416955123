import { useEffect } from 'react';
import { ONE_MINUTE_IN_MS } from 'shared/constants';
import {
  useAcknowledgeFailedInvoiceSendJobsMutation,
  useInvoiceSendJobBatchesQuery,
} from '../../../../../generated/graphql';

const recentFailedSendJobBatchesVariables = {
  first: 10,
  hasFailures: true,
  acknowledged: false,
};

const useInvoiceSendJobRecentFailures = () => {
  const {
    data: recentFailedSendJobBatches,
    startPolling,
    stopPolling,
    refetch,
  } = useInvoiceSendJobBatchesQuery({
    variables: recentFailedSendJobBatchesVariables,
  });
  const [acknowledgeFailedInvoiceSendJobs] =
    useAcknowledgeFailedInvoiceSendJobsMutation();

  useEffect(() => {
    startPolling(ONE_MINUTE_IN_MS * 2);

    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling]);

  const acknowledgeInvoiceSendJobFailures = async ({
    invoiceSendJobBatchUuid,
  }: {
    invoiceSendJobBatchUuid: string;
  }) => {
    await acknowledgeFailedInvoiceSendJobs({
      variables: {
        invoiceSendJobBatchUuid,
      },
    });
    refetch();
  };

  return { recentFailedSendJobBatches, acknowledgeInvoiceSendJobFailures };
};

export default useInvoiceSendJobRecentFailures;
