import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Fade,
  FormControl,
  FormHelperText,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { get, isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { type FieldPath, useFormContext } from 'react-hook-form';
import { isNilOrEmptyString } from 'shared/string';
import { calculateDimensionalWeight } from 'shared/weight';
import DimensionsInput from '../../../../common/components/measurements/dimensions-input';
import FormNumberInput from '../../../orders/components/order-form/components/common/form-number-input';
import { useOrderFormEditAccess } from '../../../orders/components/order-form/contexts/order-form-edit-access-context';
import {
  type OrderFormFieldValues,
  type OrderFormValues,
  type PackageValues,
} from '../../../orders/components/order-form/forms/types';

const FONT_SIZE = '12px';
const styles = {
  errorText: {
    color: '#D32F2F',
    position: 'absolute',
    whiteSpace: 'nowrap',
  },
  inputWithErrorContainer: {
    position: 'relative',
    flexWrap: 'nowrap',
  },
};

const PackageRow = ({
  idx,
  deletePackage,
}: {
  readonly idx: number;
  readonly deletePackage: (uuid: string) => void;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const weightKey = `packages.${idx}.weight`;
  const quantityKey: FieldPath<OrderFormFieldValues> = `packages.${idx}.quantity`;
  const heightKey = `packages.${idx}.height`;
  const widthKey = `packages.${idx}.width`;
  const lengthKey = `packages.${idx}.length`;
  const dimFactorKey = 'dimFactor';
  const quantity: PackageValues['quantity'] = watch(quantityKey);
  const weight: PackageValues['weight'] = watch(weightKey);
  const [weightInput, setWeightInput] = useState(String(weight ?? ''));
  const length: PackageValues['length'] = watch(lengthKey);
  const width: PackageValues['width'] = watch(widthKey);
  const height: PackageValues['height'] = watch(heightKey);
  const dimFactor: OrderFormValues['dimFactor'] = watch(dimFactorKey);
  const uuid: PackageValues['uuid'] = watch(`packages.${idx}.uuid`);
  const [dimWeight, setDimWeight] = useState('');
  const packageQuantityError = get(
    errors,
    `packages.${idx}.quantity.message`,
  )?.toString();
  const packageWeightError = get(
    errors,
    `packages.${idx}.weight.message`,
  )?.toString();
  const hasError =
    !isNilOrEmptyString(packageQuantityError) ||
    !isNilOrEmptyString(packageWeightError);
  const PADDING_AMOUNT = hasError ? '16px 3px' : '3px';

  const { disabledIfFinalizedOrLater } = useOrderFormEditAccess();

  useEffect(() => {
    const dimensionalWeight = calculateDimensionalWeight({
      length,
      width,
      height,
      quantity,
      dimFactor: dimFactor ?? undefined,
    });
    setDimWeight(dimensionalWeight.toFixed(2));
  }, [length, width, height, dimFactor, quantity, weight]);

  return (
    <TableRow
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TableCell sx={{ width: '250px', padding: PADDING_AMOUNT }}>
        <Box sx={styles.inputWithErrorContainer}>
          <FormNumberInput
            nonNegativeInteger
            fieldName={quantityKey}
            disabled={disabledIfFinalizedOrLater}
            sx={{ width: '40px' }}
            inputProps={{ style: { fontSize: FONT_SIZE, height: 12.5 } }}
            error={!isNil(packageQuantityError)}
          />
          {!isNil(packageQuantityError) && (
            <FormHelperText sx={styles.errorText}>
              {packageQuantityError}
            </FormHelperText>
          )}
        </Box>
      </TableCell>
      <TableCell sx={{ width: '250px', padding: PADDING_AMOUNT }}>
        <Box sx={styles.inputWithErrorContainer}>
          <FormControl fullWidth>
            <TextField
              disabled={disabledIfFinalizedOrLater}
              size="small"
              inputProps={{ style: { fontSize: FONT_SIZE, height: 12.5 } }}
              sx={{ width: '60px' }}
              value={weightInput}
              onChange={(e) => {
                const parsedFloat = Number.parseFloat(e.target.value);
                setWeightInput(e.target.value);
                if (!Number.isNaN(parsedFloat)) {
                  setValue(weightKey, parsedFloat);
                }
              }}
            />
          </FormControl>
          {!isNil(packageWeightError) && (
            <FormHelperText sx={styles.errorText}>
              {packageWeightError}
            </FormHelperText>
          )}
        </Box>
      </TableCell>
      <TableCell sx={{ padding: PADDING_AMOUNT }}>
        <DimensionsInput
          size="small"
          disabled={disabledIfFinalizedOrLater}
          length={length}
          width={width}
          height={height}
          onChangeLength={(length) => {
            setValue(lengthKey, length);
          }}
          onChangeWidth={(width) => {
            setValue(widthKey, width);
          }}
          onChangeHeight={(height) => {
            setValue(heightKey, height);
          }}
        />
      </TableCell>
      <TableCell sx={{ padding: PADDING_AMOUNT }}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="caption">{dimWeight}</Typography>{' '}
          {!disabledIfFinalizedOrLater && (
            <Fade in={isHovering}>
              <IconButton
                size="small"
                onClick={() => {
                  deletePackage(uuid);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Fade>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default PackageRow;
