import {
  createEntityAdapter,
  createSelector,
  createSlice,
  type EntityId,
  type EntityState,
} from '@reduxjs/toolkit';
import {
  type DayOfWeekAll,
  type MeasurementUnits,
  type NumberOfConsignees,
  type NumberOfShippers,
  type OrderConsolidationType,
  type OrderDetailedStatus,
  type OrderSegmentType,
  type OrderSource,
  type OrderStatus,
  type OrderWithSameBillOfLadingNumberAsReviewOrderFragment,
  type PaymentMethod,
  PickupOrDelivery,
  type WeightUnits,
} from '../../../../generated/graphql';
import type { RootState } from '../../../../redux/store';
import { selectShipmentPickupOrDelivery } from '../../../shipments/redux/standard-shipments-values-slice';

export type StandardOrderValues = {
  uuid: string;
  billingPartyContactUuid: string | undefined;
  consolidatedShipmentString?: string | undefined;
  defaultConsigneeStopUuid: string | undefined;
  defaultShipperStopUuid: string | undefined;
  dimFactor: number;
  documentUuids: string[] | undefined;
  driversNeededInVehicle: number | undefined;
  fieldsUuid: string;
  hasEdiCancellation: boolean;
  lineHaulLaneEndTerminalUuid: string | undefined;
  lineHaulLaneStartTerminalUuid: string | undefined;
  lineHaulLaneUuid: string | undefined;
  enableLineHaul: boolean;
  measurementUnits: MeasurementUnits | undefined;
  masterAirwayBillOfLadingNumber: string | undefined;
  name: string | undefined;
  notes: string | undefined;
  personName: string | undefined;
  personPhoneNumber: string | undefined;
  personEmail: string | undefined;
  numberOfConsignees: NumberOfConsignees | undefined;
  numberOfShippers: NumberOfShippers | undefined;
  packageUuids: string[] | undefined;
  purchaseOrderNumber: string | undefined;
  receivedDate: Date | undefined;
  detailedStatus: OrderDetailedStatus | undefined;
  pickedDate: string | undefined;
  dateMarkedLoaded?: string | undefined;
  scannedOrderResultUuid: string | undefined;
  secondaryRefNumber: string | undefined;
  serviceUuid: string | undefined;
  status: OrderStatus | undefined;
  source: OrderSource | undefined;
  orderConsolidationType: OrderConsolidationType | undefined;
  paymentMethod: PaymentMethod | undefined;
  onHand: boolean | undefined;
  pieceCount: number | undefined;
  piecesPicked: number | undefined;
  piecesLoaded?: number | undefined;
  tertiaryRefNumber: string | undefined;
  shipmentSubscriberUuids?: string[] | undefined;
  shipmentUuids: string[] | undefined;
  shipperBillOfLadingNumber: string | undefined;
  issueReason?: string | undefined;
  weightUnits: WeightUnits | undefined;
  warehouseUuid: string | undefined;
  quoteUuid?: string | undefined;
  quoteNumber?: string | undefined;
  tagUuids: string[] | undefined;
  refNumbers: string[] | undefined;
  isDraftOrder?: boolean | undefined;
  createdFromOrderTemplateUuid?: string | undefined;
  recurringOrderFrequencyUuid?: string | undefined;
  startDate?: Date | undefined;
  endDate?: Date | undefined;
  repeatIntervalWeeks?: number | undefined;
  daysOfWeek?: DayOfWeekAll[] | undefined;
  orderSegmentType?: OrderSegmentType | undefined;
  ordersWithSameBillOfLadingNumber?:
    | OrderWithSameBillOfLadingNumberAsReviewOrderFragment[]
    | undefined;
  totalSkids?: number | undefined;
};

const standardOrdersValuesAdapter = createEntityAdapter<StandardOrderValues>({
  selectId: (standardOrderValues) => standardOrderValues.uuid,
});

export const standardOrdersValuesSlice = createSlice({
  name: 'standardOrdersValues',
  initialState: standardOrdersValuesAdapter.getInitialState(),
  reducers: {
    addStandardOrderValues: standardOrdersValuesAdapter.addOne,
    updateOneStandardOrderValues: standardOrdersValuesAdapter.updateOne,
    upsertOneStandardOrderValues: standardOrdersValuesAdapter.upsertOne,
    updateManyStandardOrderValues: standardOrdersValuesAdapter.updateMany,
    removeOneStandardOrderValues: standardOrdersValuesAdapter.removeOne,
    setAllStandardOrderValues: standardOrdersValuesAdapter.setAll,
  },
});

export const {
  selectById: selectStandardOrderValuesById,
  selectAll: selectAllStandardOrderValues,
} = standardOrdersValuesAdapter.getSelectors(
  (state: RootState) => state.standardOrderValues,
);

export const selectDefaultConsigneeStopUuid = createSelector(
  selectStandardOrderValuesById,
  (values: StandardOrderValues | undefined) => {
    return values?.defaultConsigneeStopUuid;
  },
);

export const selectDefaultShipperStopUuid = createSelector(
  selectStandardOrderValuesById,
  (values: StandardOrderValues | undefined) => {
    return values?.defaultShipperStopUuid;
  },
);

export const selectBillingPartyUuid = createSelector(
  selectStandardOrderValuesById,
  (values: StandardOrderValues | undefined) => {
    return values?.billingPartyContactUuid;
  },
);

export const selectDetailedStatus = createSelector(
  selectStandardOrderValuesById,
  (values: StandardOrderValues | undefined) => {
    return values?.detailedStatus;
  },
);

export const selectNumberOfConsignees = createSelector(
  selectStandardOrderValuesById,
  (values: StandardOrderValues | undefined) => {
    return values?.numberOfConsignees;
  },
);

export const selectNumberOfShippers = createSelector(
  selectStandardOrderValuesById,
  (values: StandardOrderValues | undefined) => {
    return values?.numberOfShippers;
  },
);

export const selectOrderName = createSelector(
  selectStandardOrderValuesById,
  (values: StandardOrderValues | undefined) => {
    return values?.name;
  },
);

export const selectOrderStatus = createSelector(
  selectStandardOrderValuesById,
  (values: StandardOrderValues | undefined) => {
    return values?.status;
  },
);

export const selectReceivedDate = createSelector(
  selectStandardOrderValuesById,
  (values: StandardOrderValues | undefined) => {
    return values?.receivedDate;
  },
);

export const selectPackageUuidsForOrder = createSelector(
  selectStandardOrderValuesById,
  (values: StandardOrderValues | undefined) => {
    return values?.packageUuids;
  },
);

export const selectShipperBillOfLadingNumber = createSelector(
  selectStandardOrderValuesById,
  (values: StandardOrderValues | undefined) => {
    return values?.shipperBillOfLadingNumber;
  },
);

export const selectStandardOrderWeightUnits = createSelector(
  selectStandardOrderValuesById,
  (values: StandardOrderValues | undefined) => {
    return values?.weightUnits;
  },
);

export const selectStandardOrderDimFactor = createSelector(
  selectStandardOrderValuesById,
  (values: StandardOrderValues | undefined) => {
    return values?.dimFactor;
  },
);

export const selectStandardOrderMeasurementUnits = createSelector(
  selectStandardOrderValuesById,
  (values: StandardOrderValues | undefined) => {
    return values?.measurementUnits;
  },
);

export const selectMasterAirwayBillOfLadingNumber = createSelector(
  selectStandardOrderValuesById,
  (values: StandardOrderValues | undefined) => {
    return values?.masterAirwayBillOfLadingNumber;
  },
);

export const selectSecondaryRefNumber = createSelector(
  selectStandardOrderValuesById,
  (values: StandardOrderValues | undefined) => {
    return values?.secondaryRefNumber;
  },
);

export const selectRefNumbers = createSelector(
  selectStandardOrderValuesById,
  (values: StandardOrderValues | undefined) => {
    return values?.refNumbers;
  },
);

export const selectIsDraftOrder = createSelector(
  selectStandardOrderValuesById,
  (values: StandardOrderValues | undefined) => {
    return values?.isDraftOrder;
  },
);

export const selectOrderOnHand = createSelector(
  selectStandardOrderValuesById,
  (values: StandardOrderValues | undefined) => {
    return values?.onHand;
  },
);

export const selectOrderPieceCount = createSelector(
  selectStandardOrderValuesById,
  (values: StandardOrderValues | undefined) => {
    return values?.pieceCount;
  },
);

export const selectRecurringOrderFrequency = createSelector(
  selectStandardOrderValuesById,
  (values: StandardOrderValues | undefined) => {
    return {
      startDate: values?.startDate,
      endDate: values?.endDate,
      repeatIntervalWeeks: values?.repeatIntervalWeeks,
      daysOfWeek: values?.daysOfWeek,
    };
  },
);

export const selectStandardOrderShipmentUuids = createSelector(
  selectStandardOrderValuesById,
  (state: RootState) => state,
  (values: StandardOrderValues | undefined, state) => {
    const shipments = (values?.shipmentUuids ?? []).map((uuid) => {
      return {
        uuid,
        stopType: selectShipmentPickupOrDelivery(state, uuid),
      };
    });
    const recoveryShipments = shipments.filter(
      (s) => s.stopType === PickupOrDelivery.Recovery,
    );
    const deliveryShipments = shipments.filter(
      (s) => s.stopType === PickupOrDelivery.Delivery,
    );
    const pickupShipments = shipments.filter(
      (s) => s.stopType === PickupOrDelivery.Pickup,
    );
    const transferShipments = shipments.filter(
      (s) => s.stopType === PickupOrDelivery.Transfer,
    );
    return recoveryShipments
      .concat(pickupShipments)
      .concat(deliveryShipments)
      .concat(transferShipments)
      .map((s) => s.uuid);
  },
);

export const selectStandardOrderScannedOrderResultUuid = createSelector(
  selectStandardOrderValuesById,
  (values: StandardOrderValues | undefined) => {
    return values?.scannedOrderResultUuid;
  },
);

const standardOrdersSelector = (state: RootState) => state.standardOrderValues;

export const selectStandardOrdersByIds = createSelector(
  standardOrdersSelector,
  (state: RootState, ids: EntityId[]) => ids,
  (state: EntityState<StandardOrderValues>, entityIds: EntityId[]) => {
    const entityResults: StandardOrderValues[] = [];
    for (const id of entityIds) {
      const match = standardOrdersValuesAdapter
        .getSelectors()
        .selectById(state, id);
      if (match) {
        entityResults.push(match);
      }
    }
    return entityResults;
  },
);

export const {
  addStandardOrderValues,
  updateOneStandardOrderValues,
  upsertOneStandardOrderValues,
  updateManyStandardOrderValues,
  removeOneStandardOrderValues,
  setAllStandardOrderValues,
} = standardOrdersValuesSlice.actions;

const standardOrdersValuesReducer = standardOrdersValuesSlice.reducer;

export default standardOrdersValuesReducer;
