import {
  type CustomChargeBillingMethod,
  type CustomChargeEntity,
  type CustomerContactEntity,
  type DocumentType,
  type InvoiceEntity,
  type InvoiceTransmissionMethod,
  type InvoiceType,
  type StandardShipmentEntity,
  type TimePeriod,
} from '../../../generated/graphql';
import type { DocumentFormatForInvoiceDownload } from '../../invoice-old/utils';

export enum InvoiceOrderTabs {
  Unfinalized = 'Unfinalized',
  Cancelled = 'Cancelled',
  Finalized = 'Finalized',
  FinalizedNoCharge = 'Finalized No Charge',
  BillingIssue = 'Billing Issue',
  Invoiced = 'Invoiced',
  All = 'All',
}

export enum InvoiceStatusTab {
  NOT_POSTED = 'NOT_POSTED',
  POSTED = 'POSTED',
  ALL = 'ALL',
}

export enum InvoiceBalanceStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  ALL = 'ALL',
}

export type InvoiceShipment = {
  shipmentUuid: string;
  contact:
    | {
        invoiceTimePeriodSplit?: TimePeriod | null | undefined;
        numberOfOrdersPerInvoice?: number | null | undefined;
        uuid: string;
      }
    | undefined;
  orderUuid: string;
};

// An order with the fields needed to construct a request to send its shipments to billing.
export type OrderToSendToBilling = {
  uuid: string;
  billingPartyContact: Pick<
    CustomerContactEntity,
    'uuid' | 'invoiceTimePeriodSplit' | 'numberOfOrdersPerInvoice'
  >;
  shipments: Array<
    Pick<StandardShipmentEntity, 'uuid' | 'hideFromBilling'> & {
      invoice?: Pick<InvoiceEntity, 'uuid'> | null;
    }
  >;
};

export type InvoiceShipmentFragmentCustomCharge = Omit<
  CustomChargeEntity,
  'billingMethod'
> & {
  customChargeBillingMethod: CustomChargeBillingMethod;
};

export enum DownloadStatus {
  InProgress = 'IN_PROGRESS',
  Finished = 'FINISHED',
}

export type InvoiceDownload = {
  firstDocumentInvoiceName: string;
  downloadLink: string;
  invoiceUuid: string | undefined;
  invoiceType: InvoiceType;
};

export type FileDownload = {
  id: string;
  invoices: InvoiceToSendOption[];
  singleUrl: InvoiceDownload | undefined;
  multipleUrl: InvoiceDownload[] | undefined;
  spreadsheets: DocumentFormatForInvoiceDownload[] | undefined;
  invoiceType: InvoiceType;
  status: DownloadStatus;
  shouldEmail?: boolean;
  progress: number;
};

export type DownloadProgress = {
  id: string;
  progress: number;
  failed?: boolean;
};

export type InvoiceToSendOption = {
  invoiceUuid: string;
  invoiceName: string;
  invoiceEmailSubject: string | null | undefined;
  invoiceNote: string | null | undefined;
  invoiceSelectedEmails: string[] | null | undefined;
  invoiceEmailOptions: string[] | null | undefined;
  invoiceJournalNumber: number;
  invoiceContactUuid: string;
  invoiceContactDisplayName: string;
  invoiceTransmissionMethod: InvoiceTransmissionMethod;
  invoiceDownloadType: InvoiceType;
  invoiceAttachments: DocumentType[];
};

export type InvoiceConfig = {
  addToExistingInvoices: boolean;
  numberOfOrdersPerInvoice: number | null | undefined;
  invoiceTimePeriodSplit: TimePeriod | null | undefined;
};

export type InvoiceOrderReviewType =
  | InvoiceOrderTabs.Unfinalized
  | InvoiceOrderTabs.Finalized
  | InvoiceOrderTabs.FinalizedNoCharge
  | InvoiceOrderTabs.BillingIssue;
