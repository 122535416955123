import { TextField } from '@mui/material';
import { type Option } from '../../../common/filters/types';
import AutocompleteFuzzy from '../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { type FilterEditorProps } from '../types-v2';
import { BaseFilterEditorInputPropsSx } from './base-filter-editor-props';
type SingleSelectFilterEditorProps<TValue extends string | boolean> =
  FilterEditorProps<TValue> & {
    readonly options: Array<Option<TValue>>;
  };

const SingleSelectFilterEditor = <TValue extends string | boolean>({
  value,
  onChange,
  options,
}: SingleSelectFilterEditorProps<TValue>) => {
  return (
    <AutocompleteFuzzy<Option<TValue | null>>
      sx={{ backgroundColor: 'white' }}
      value={options.find((option) => option.value === value) ?? null}
      options={options}
      matchSortOptions={{ keys: ['label'] }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          placeholder="Value"
          InputProps={{
            ...params.InputProps,
            sx: {
              ...BaseFilterEditorInputPropsSx,
              marginLeft: '-2px',
            },
          }}
        />
      )}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={(_, selected) => {
        onChange(selected?.value ?? null);
      }}
    />
  );
};

const SingleSelectFilterEditorWithOptions = <TValue extends string | boolean>(
  options: Array<Option<TValue>>,
) => {
  return ({ value, onChange }: FilterEditorProps<TValue>) => {
    return (
      <SingleSelectFilterEditor
        value={value}
        options={options}
        onChange={onChange}
      />
    );
  };
};

export default SingleSelectFilterEditorWithOptions;
