import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Button,
  CircularProgress,
  Divider,
  Skeleton,
  Stack,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import theme from '../../../../../theme';

const ManifestDetailsLoading = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Stack height="100%" width="100%">
      <Stack p={1} direction="row" alignItems="center" gap={1} width="100%">
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            navigate(location.pathname, { replace: true });
          }}
        >
          Back
        </Button>
        <Stack>
          <Skeleton
            sx={{ backgroundColor: theme.palette.grey[300] }}
            variant="text"
            height={27}
            width={200}
          />
          <Skeleton
            sx={{ backgroundColor: theme.palette.grey[300] }}
            variant="text"
            height={18}
            width={150}
          />
        </Stack>
      </Stack>
      <Divider />
      <Stack
        height="100%"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size={50} />
      </Stack>
    </Stack>
  );
};

export default ManifestDetailsLoading;
