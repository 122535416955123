import { sentenceCase } from 'change-case';
import { isEmpty, isNil } from 'lodash';
import pluralize from 'pluralize';
import { isNilOrEmptyString } from 'shared/string';
import { z } from 'zod';
import { validatePhoneNumber } from '../../../../../common/utils/utils';
import {
  type OrderFormFieldsFragment,
  FreightBillingMethod,
  FuelBillingMethod,
  FulfillmentType,
  HazmatClass,
  OrderDetailedStatus,
  OrderSegmentType,
  OrderStatus,
  PackageGroup,
  StopType,
} from '../../../../../generated/graphql';
import { INBOUND_STOP_IDX, OUTBOUND_STOP_IDX } from '../components/constants';
import { documentSchema } from './document-schema';
import { lineHaulShipmentSchema } from './line-haul-shipment-schema';
import { getOrderChargesShipmentSchema } from './order-charges-shipment-schema';
import { orderCommentSchema } from './order-comment-schema';
import { getPackagesSchema } from './packages-schema';
import { recurringOrderFrequencySchema } from './recurring-order-frequency-schema';
import { isServiceDateRequiredForStop } from './service-date-utils';
import { type StopSchemaOptions, getStopSchema } from './stop-schema';
import { isRegularStop } from './stop-type';
import { tagSchema } from './tag-schema';
import { zDateOrDatetimeString } from './zod-utils';

export type OrderSchemaOptions = StopSchemaOptions & {
  requireServiceLevel: boolean;
  requireFulfillmentType: boolean;
  orderFormFields?: OrderFormFieldsFragment;
};

export const getOrderSchema = ({
  accessorials,
  ffRecoveryTransferAddressOnly,
  requireServiceLevel,
  requireFulfillmentType,
  orderFormFields,
}: OrderSchemaOptions) => {
  const schema = z.object({
    updatedAt: zDateOrDatetimeString().nullish(),
    scannedOrderResultUuid: z.string().uuid().nullish(),
    useKilograms: z.boolean().nullish(),
    useCentimeters: z.boolean().nullish(),
    uuid: z.string().uuid(),
    serviceUuid: requireServiceLevel
      ? z
          .string({ required_error: 'Missing service level' })
          .uuid({ message: 'Missing service level' })
      : z.string().uuid({ message: 'Invalid service level' }).nullish(),
    fieldsUuid: z.string().uuid().nullable(),
    contactUuid: z
      .string({ required_error: 'Missing contact' })
      .uuid({ message: 'Missing contact' })
      .trim()
      .min(1, 'Missing contact'),
    contactStationId: z.string().uuid({ message: 'Missing station' }).nullish(),
    name: z.string().min(1, 'Missing name').nullish(),
    shipperBillOfLadingNumber: z
      .string({ required_error: 'Missing HAWB' })
      .trim()
      .min(1, 'Missing HAWB'),
    lockShipperBillOfLadingNumber: z.boolean(),
    masterAirwayBillOfLadingNumber: z.string().nullish(),
    secondaryReferenceNumber: z.string().nullish(),
    lockSecondaryReferenceNumber: z.boolean(),
    tertiaryReferenceNumber: z.string().nullish(),
    quaternaryReferenceNumber: z.string().nullish(),
    quinaryReferenceNumber: z.string().nullish(),
    dimFactor: z.number().nullish(),
    units: z.string().nullish(),
    onHand: z.boolean().nullish(),
    receivedAtOriginDate: zDateOrDatetimeString().nullish(),
    receivedDate: zDateOrDatetimeString().nullish(),
    pickedDate: zDateOrDatetimeString().nullish(),
    pieceCount: z.number().nullish(),
    piecesPicked: z.number().nullish(),
    loadedDate: zDateOrDatetimeString().nullish(),
    personName: z.string().nullish(),
    personPhoneNumber: z.string().nullish(),
    personEmail: z.preprocess(
      // Preprocess empty strings to undefined
      // because email() doesn't accept empty strings
      (val) => (val === '' ? undefined : val),
      z.string().email({ message: 'Invalid contact email' }).nullish(),
    ),
    paymentMethod: z.string().nullish(),
    codCheckAmountInDollars: z.number().nullish(),
    codCheckNumber: z.string().nullish(),
    isCollectOnDelivery: z.boolean().nullish(),
    defaultFuelBillingMethod: z.nativeEnum(FuelBillingMethod).optional(),
    defaultFuelSurcharge: z.number().optional(),
    defaultFreightBillingMethod: z.nativeEnum(FreightBillingMethod).optional(),
    refusedBy: z.string().nullish(),
    refusedDate: zDateOrDatetimeString().nullish(),
    holdReasonUuid: z
      .string()
      .uuid({ message: 'Missing hold reason' })
      .nullish(),
    holdReasonName: z.string().nullish(),
    warehouseUuid: z.string().uuid({ message: 'Missing warehouse' }).nullish(),
    thirdPartyBrokerUuid: z
      .string()
      .uuid({ message: 'Missing third party broker' })
      .nullish(),
    tags: z.array(tagSchema).nullish(),
    notes: z.string().nullish(),
    documents: z.array(documentSchema.nullish()),
    stops: z
      .array(
        getStopSchema({
          ffRecoveryTransferAddressOnly,
          accessorials,
        }),
      )
      .nullish(),
    packages: getPackagesSchema(orderFormFields),
    isCrossDock: z.boolean().nullish(),
    recurringOrderFrequency: recurringOrderFrequencySchema.nullish(),
    refNumbers: z.array(z.string()).nullish(),
    orderSegmentType: z.nativeEnum(OrderSegmentType).nullable(),
    fulfillmentType: requireFulfillmentType
      ? z
          .nativeEnum(FulfillmentType, {
            required_error: 'Missing fulfillment type',
          })
          .nullable()
      : z.nativeEnum(FulfillmentType).nullish(),
    vehicleTypeUuid: z.string().uuid().nullish(),
    isCourier: z.boolean().nullish(),
    orderComments: z.array(orderCommentSchema.nullish()).nullish(),
    newComment: orderCommentSchema.nullish(),
    detailedStatus: z.nativeEnum(OrderDetailedStatus).nullish(),
    deletedAutoAppliedAccessorials: z.array(z.string()),
    osd: z.boolean().nullish(),
    status: z.nativeEnum(OrderStatus).nullish(),
    orderChargesShipment: getOrderChargesShipmentSchema({
      accessorials,
    }).nullable(),
    isUsingLineHaul: z.boolean(),
    enableLineHaul: z.boolean(),
    lineHaulShipment: lineHaulShipmentSchema.nullable(),
    lineHaulLaneUuid: z.string().uuid().nullish(),
    // Allows the empty string in case the user types in a number and deletes it
    chargeableWeightCubicMeters: z.preprocess(
      (val) => (isEmpty(val) ? null : Number(val)),
      z
        .number()
        .min(0)
        .nullish()
        .refine((val) => val === null || !Number.isNaN(val), {
          message: 'Chargeable weight must be a number',
        }),
    ),
    totalSkids: z.number().nullish(),
    isReweighed: z.boolean().nullish(),
    driverQualificationIds: z.array(z.string()).nullish(),
    inBond: z.boolean().nullish(),
    hazmat: z.boolean().nullish(),
    itTeNumber: z.string().nullish(),
    externalNotes: z.string().nullish(),
    unNumber: z.string().nullish(),
    hazmatDescription: z.string().nullish(),
    emergencyResponseNumber: z
      .string()
      .nullish()
      .refine((val) => isNilOrEmptyString(val) || validatePhoneNumber(val), {
        message:
          'Invalid emergency response number. Please enter a number in the format (###) ###-####',
      }),
    requiresPlacard: z.boolean().nullish(),
    hazmatClass: z.nativeEnum(HazmatClass).nullish(),
    packageGroup: z.nativeEnum(PackageGroup).nullish(),
    totalCharge: z.number().nullish(),
    previousTotalAmountCents: z.number().nullish(),
  });
  return schema
    .refine((data) => !(data.isUsingLineHaul && isNil(data.lineHaulLaneUuid)), {
      message: 'Missing line haul lane',
      path: ['lineHaulLaneUuid'],
    })
    .refine(
      (data) => {
        if (data.fulfillmentType !== FulfillmentType.Dedicated) {
          return true;
        }
        const inboundDriverUuid = data?.stops?.[INBOUND_STOP_IDX]?.driverUuid;
        const outboundDriverUuid = data?.stops?.[OUTBOUND_STOP_IDX]?.driverUuid;
        if (isNil(inboundDriverUuid) || isNil(outboundDriverUuid)) {
          return true;
        }
        return inboundDriverUuid === outboundDriverUuid;
      },
      {
        message:
          'Drivers must match when stops are fulfilled on the same route',
        path: [
          `stops.${INBOUND_STOP_IDX}.driverUuid`,
          `stops.${OUTBOUND_STOP_IDX}.driverUuid`,
        ],
      },
    )
    .superRefine((data, ctx) => {
      const validateServiceDateRequired = (
        stopType: StopType,
        otherStopType: StopType,
        serviceDate: Date | null | undefined,
        path: string,
        isRecurringTemplate: boolean,
        // eslint-disable-next-line max-params
      ) => {
        // TODO: Make this work with serviceDateV2 instead of serviceDate.
        if (
          isServiceDateRequiredForStop({
            stopType,
            otherStopType,
          }) &&
          isNil(serviceDate) &&
          !isRecurringTemplate
        ) {
          const errorMessagePrefix = isRegularStop(stopType)
            ? 'Service date'
            : 'Arrival date';
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `${errorMessagePrefix} is required for ${pluralize(sentenceCase(stopType))}`,
            path: [path],
          });
        }
      };

      const inboundStop = data.stops?.[INBOUND_STOP_IDX];
      const outboundStop = data.stops?.[OUTBOUND_STOP_IDX];
      const inboundStopType = inboundStop?.stopType;
      const outboundStopType = outboundStop?.stopType;
      const isRecurringTemplate = !isNil(data.recurringOrderFrequency);

      if (!isNil(inboundStopType)) {
        validateServiceDateRequired(
          inboundStopType,
          outboundStopType ?? StopType.None,
          inboundStop?.serviceDate,
          `stops.${INBOUND_STOP_IDX}.serviceDate`,
          isRecurringTemplate,
        );
      }

      if (!isNil(outboundStopType)) {
        validateServiceDateRequired(
          outboundStopType,
          inboundStopType ?? StopType.None,
          outboundStop?.serviceDate,
          `stops.${OUTBOUND_STOP_IDX}.serviceDate`,
          isRecurringTemplate,
        );
      }
    });
};
