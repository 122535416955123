import {
  Button,
  MenuList,
  Menu,
  Stack,
  Typography,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import {
  ManifestsUpdateStatusSuccessMessage,
  type LineHaulManifest,
} from '../utils';
import ManifestStatusChip from '../../manifest-status-chip';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState } from 'react';
import {
  LineHaulManifestGroupsDocument,
  LineHaulManifestStatus,
  useUpdateManifestsStatusMutation,
} from '../../../../../generated/graphql';
import { useErrors } from '../../../../../common/react-hooks/use-errors';
import { ErrorsAlert } from '../../../../../common/components/errors-alert';
import useLineHaulDispatchStore from '../../../store/line-haul-dispatch-store';
import { CHIP_COLORS } from '../../utils';

type StatusPickerProps = {
  readonly manifest: LineHaulManifest;
};

const StatusPicker = ({ manifest }: StatusPickerProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const setSnackbarSuccessMessage = useLineHaulDispatchStore(
    (state) => state.setSnackbarSuccessMessage,
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { errors, onError, clearErrors } = useErrors();
  const [updateManifestsStatus, { loading: updateManifestsStatusLoading }] =
    useUpdateManifestsStatusMutation({
      onCompleted: (data) => {
        if (data.updateManifestsStatus.__typename === 'MutationErrorOutput') {
          onError(data.updateManifestsStatus.message);
        } else if (
          data.updateManifestsStatus.__typename ===
          'UpdateManifestsStatusSuccessOutput'
        ) {
          setSnackbarSuccessMessage(ManifestsUpdateStatusSuccessMessage);
        }
      },
      onError,
      refetchQueries: [LineHaulManifestGroupsDocument],
    });

  const handleStatusUpdate = async (status: LineHaulManifestStatus) => {
    await updateManifestsStatus({
      variables: {
        input: {
          manifestUuids: [manifest.uuid],
          status,
        },
      },
    });
  };

  return (
    <Stack>
      <ErrorsAlert errors={errors} onClear={clearErrors} />
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" gap={0.5}>
          {updateManifestsStatusLoading && (
            <CircularProgress size={15} sx={{ color: 'primary.main' }} />
          )}
          <Typography variant="subtitle2">Status</Typography>
          <Button
            endIcon={
              <ArrowDropDownIcon sx={{ color: CHIP_COLORS[manifest.status] }} />
            }
            disabled={updateManifestsStatusLoading}
            onClick={handleClick}
          >
            <ManifestStatusChip status={manifest.status} />
          </Button>
        </Stack>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuList>
            {Object.values(LineHaulManifestStatus).map((status) => {
              return (
                <MenuItem
                  key={status}
                  onClick={() => {
                    handleClose();
                    void handleStatusUpdate(status);
                  }}
                >
                  <ManifestStatusChip status={status} />
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </Stack>
    </Stack>
  );
};

export default StatusPicker;
