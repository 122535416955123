import {
  Box,
  Button,
  Dialog,
  DialogActions,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { type Dispatch, type SetStateAction, useMemo, useState } from 'react';
import useContacts from '../../../../common/react-hooks/use-contacts';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import useInvoices from './use-invoices';

const modalInnerContainerStyle = {
  bgcolor: 'background.paper',
  boxShadow: 24,
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  padding: 2,
};

const CreateEmptyInvoiceModal = ({
  isOpen,
  setIsOpen,
}: {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const [contactInput, setContactInput] = useState('');
  const [contactUuid, setContactUuid] = useState<string>();
  const [error, setError] = useState<string>();
  const { contacts } = useContacts();
  const { createInvoice } = useInvoices();

  const reset = () => {
    setContactInput('');
    setContactUuid(undefined);
    setError(undefined);
  };

  const onCreate = async () => {
    if (isNil(contactUuid)) {
      setError('Please select a valid contact');
    } else {
      await createInvoice({
        contactUuid,
      });
      setIsOpen(false);
      reset();
    }
  };

  const options = useMemo(
    () =>
      contacts.map((contact) => ({
        value: contact.uuid,
        label: contact.displayName,
      })),
    [contacts],
  );

  const currentContactOption = useMemo(() => {
    const currentContact = contacts.find(
      (contact) => contact.uuid === contactUuid,
    );

    return isNil(contactUuid)
      ? { value: '', label: '' }
      : { value: contactUuid, label: currentContact?.displayName };
  }, [contactUuid, contacts]);

  return (
    <Dialog
      maxWidth="xs"
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <Box sx={modalInnerContainerStyle}>
        <Typography variant="h6" sx={{ mb: '20px' }}>
          Create an Empty Invoice
        </Typography>
        <Typography sx={{ mb: '10px' }}>Select a billing customer</Typography>
        <AutocompleteFuzzy
          size="small"
          sx={{ width: '300px' }}
          value={currentContactOption}
          options={options}
          matchSortOptions={{ keys: ['label'] }}
          inputValue={contactInput}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              error={!isNil(error)}
              helperText={error}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                setContactInput(e.target.value);
              }}
            />
          )}
          onChange={(event, option) => {
            setContactUuid(option?.value);
            setContactInput(option?.label ?? '');
          }}
        />
        <DialogActions>
          <Button variant="contained" sx={{ mt: '20px' }} onClick={onCreate}>
            Create Invoice
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default CreateEmptyInvoiceModal;
