import { Add } from '@mui/icons-material';
import {
  Button,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  TextField,
  useTheme,
} from '@mui/material';
import {
  type ColDef,
  type GridReadyEvent,
  type IServerSideGetRowsParams,
  type SideBarDef,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { isEmpty, isNil } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import useStateRef from 'react-usestateref';
import styled from 'styled-components';
import apolloClient from '../../../apollo-client';
import { type DateOption } from '../../../common/components/date-dropdown-picker';
import { FeatureFlag } from '../../../common/feature-flags';
import { type Option } from '../../../common/filters/types';
import { useClientUuidFromSessionCookie } from '../../../common/react-hooks/use-client-uuid-from-session-cookie';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useMeAsThirdPartyUser from '../../../common/react-hooks/use-me-as-third-party-user';
import {
  type CustomerPortalOrderForTableFragment,
  CustomerPortalOrdersDocument,
  type CustomerPortalOrdersQuery,
  type CustomerPortalOrdersQueryVariables,
  FilterOperator,
  type FilterViewPage,
} from '../../../generated/graphql';
import {
  type CustomerPortalOrderFilterField,
  getFetchCustomerPortalOrdersQueryVariables,
  isThirdPartyAgent,
} from '../../customer-portal/utils';
import { type FilterModel } from '../../orders/components/enums/order-filters';
import SelectedFilterButtonForToolbar from '../../orders/components/selected-filter-button-for-toolbar';
import useFilterStore from '../filter-store';

const sideBarConfig: SideBarDef = {
  toolPanels: [
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
      toolPanelParams: {
        suppressExpandAll: false,
        suppressFilterSearch: false,
      },
    },
  ],
  defaultToolPanel: 'filters',
  position: 'left',
};

const CustomerPortalOrdersFilterRow = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const useStyles = () => {
  const theme = useTheme();
  return {
    mainColor: {
      color: theme.palette.primary.main,
    },
    filterButton: {
      borderRadius: '4px',
    },
    menuText: {
      fontSize: '14px',
    },
    totalText: {
      ml: '4px',
      mb: '2px',
      color: 'black',
      fontSize: '15px',
    },
    filterTitle: {
      fontSize: '14px',
      ml: '3px',
    },
    filterValue: {
      fontSize: '14px',
      ml: '5px',
      fontWeight: 'bold',
    },
    select: {
      color: theme.palette.primary.main,
    },
  };
};

type OrderStatus = 'all' | 'in progress' | 'accepted' | 'completed' | 'paid';

type State = {
  searchText: string;
  currentCursor: string | null | undefined;
  customFilterModelJson: FilterModel;
  currentFilterViewUuid: string | null;
  currentFilterViewName: string | null;
  customerOption: Option | undefined;
  dateOption: DateOption | undefined;
  companyFilter?: string;
  contactFilter?: string;
  orderStatus: OrderStatus;
};

type CustomerPortalOrdersTableProps = {
  readonly columnDefinitions: Array<
    ColDef<CustomerPortalOrderForTableFragment>
  >;
  readonly pageSize: number;

  readonly pageType: FilterViewPage;
  readonly shouldRememberFilters?: boolean;
  readonly companies: Array<{
    uuid: string;
    name: string;
  }>;
  readonly contacts: Array<{
    uuid: string;
    displayName: string;
    company: {
      uuid: string;
    };
  }>;
};

const CustomerPortalOrdersTableWithFiltersAgGrid = ({
  columnDefinitions,
  pageSize,
  pageType,
  shouldRememberFilters = false,
  companies,
  contacts,
}: CustomerPortalOrdersTableProps) => {
  const { thirdPartyUser } = useMeAsThirdPartyUser();
  const styles = useStyles();
  const gridRef =
    useRef<AgGridReact<CustomerPortalOrderForTableFragment>>(null);
  const toolPanelVisibleRef = useRef(false);
  const isAgent = isThirdPartyAgent(thirdPartyUser);

  const ffWhiteLabelCustomerPortal = useFeatureFlag(
    FeatureFlag.FF_WHITE_LABEL_CUSTOMER_PORTAL,
  );
  const clientUuidResult = useClientUuidFromSessionCookie();
  const isWhiteLabelUser =
    ffWhiteLabelCustomerPortal && clientUuidResult.kind === 'success';

  const {
    rememberedFilters,
    setRememberedFilters,
    rememberedSearch,
    setRememberedSearch,
  } = useFilterStore((state) => {
    return {
      rememberedSearch: state.search,
      rememberedFilters: state.filters,
      setRememberedFilters: state.setFilters,
      setRememberedSearch: state.setSearch,
    };
  });

  const [, setState, stateRef] = useStateRef<State>({
    searchText: shouldRememberFilters ? (rememberedSearch[pageType] ?? '') : '',
    currentCursor: null,
    customFilterModelJson: {},
    currentFilterViewName: null,
    currentFilterViewUuid: null,
    customerOption: undefined,
    dateOption: undefined,
    orderStatus: 'all',
  });
  const [searching, setSearching] = useState<boolean>(false);
  const [contactFilter, setContactFilter] = useState(
    stateRef.current.contactFilter,
  );
  const [columnDefs, setColumnDefs] =
    useState<Array<ColDef<CustomerPortalOrderForTableFragment>>>(
      columnDefinitions,
    );

  const createServerSideDatasource = () => {
    return {
      getRows(
        params: IServerSideGetRowsParams<CustomerPortalOrderForTableFragment>,
      ) {
        const variables = getFetchCustomerPortalOrdersQueryVariables({
          filterModel: params.request.filterModel,
        });
        apolloClient
          .query<CustomerPortalOrdersQuery, CustomerPortalOrdersQueryVariables>(
            {
              query: CustomerPortalOrdersDocument,
              variables: {
                ...variables,
                first: pageSize,
                after: stateRef.current.currentCursor,
                searchText: stateRef.current.searchText?.trim(),
                companyFilter: stateRef.current.companyFilter,
                contactFilter: stateRef.current.contactFilter,
                dispatched:
                  stateRef.current.orderStatus === 'accepted' ||
                  stateRef.current.orderStatus === 'in progress'
                    ? {
                        value: true,
                        filterOperator: FilterOperator.And,
                      }
                    : undefined,
              },
            },
          )
          .then((res) => res.data)
          .then((data) => {
            setState((prevState) => {
              return {
                ...prevState,
                currentCursor: data?.customerPortalOrders.pageInfo.endCursor,
              };
            });
            params.success({
              rowData:
                data?.customerPortalOrders.edges.map((edge) => edge.node) ?? [],
              rowCount: data?.customerPortalOrders.totalCount ?? undefined,
            });
            setSearching(false);
          });
      },
    };
  };

  // call this if we want to refresh the grid (filters change, etc.)
  const refreshGrid = (refreshServerSide = true) => {
    if (!isNil(gridRef.current?.api)) {
      gridRef.current?.api.showNoRowsOverlay();
      if (refreshServerSide) {
        gridRef.current?.api.refreshServerSide({ purge: true });
      }
      gridRef.current?.api.paginationGoToFirstPage();
      gridRef.current?.api.hideOverlay();
    }
  };

  /**
   * Restores any remembered filters.
   */
  const handleRememberedFilters = (params: GridReadyEvent) => {
    const rememberedFilter = rememberedFilters[pageType];
    if (!isNil(rememberedFilter)) {
      params.api.setFilterModel(JSON.parse(rememberedFilter));
    }
  };

  useEffect(() => {
    setColumnDefs(columnDefinitions);
    if (!isNil(gridRef.current?.api)) {
      gridRef.current?.api.sizeColumnsToFit();
    }
  }, [columnDefinitions]);

  const onGridReady = (params: GridReadyEvent) => {
    const datasource = createServerSideDatasource();
    params.api.setServerSideDatasource(datasource);
    params.api.sizeColumnsToFit();
    params.api.closeToolPanel();
    if (shouldRememberFilters) {
      handleRememberedFilters(params);
    }
  };

  const closeFilterToolPanel = useCallback(() => {
    const filterToolPanel =
      gridRef.current?.api.getToolPanelInstance('filters');
    filterToolPanel?.collapseFilters();
    // reset filters to all before closing.
    filterToolPanel?.refresh();
    filterToolPanel?.setFilterLayout(columnDefs);
    gridRef.current?.api.closeToolPanel();
  }, [columnDefs]);

  const applyModel = useCallback((filterModel: FilterModel | null) => {
    if (!isNil(gridRef.current?.api)) {
      gridRef.current?.api.setFilterModel(filterModel);
    }
  }, []);

  const defaultColDef: ColDef<CustomerPortalOrderForTableFragment> = {
    flex: 1,
    autoHeight: true,
    wrapHeaderText: true,
    wrapText: true,
    resizable: false,
    suppressMenu: true,
  };

  // This closes the toolpanel if we click outside the filter toolpanel since AG Grid doesn't have an API for this
  useEffect(() => {
    const handleDocumentClick = (event: Event) => {
      const gridApi = gridRef.current?.api;
      const isToolPanelClicked = (event.target as Element).closest(
        '.ag-filter-toolpanel',
      );

      const isDateRangePickerClicked = (event.target as Element).closest(
        '.rmdp-wrapper',
      );

      if (
        gridApi &&
        !isToolPanelClicked &&
        !isDateRangePickerClicked &&
        toolPanelVisibleRef.current
      ) {
        closeFilterToolPanel();
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [closeFilterToolPanel]);

  useEffect(() => {
    if (isEmpty(stateRef.current.customFilterModelJson)) {
      setSearching(true);
      applyModel(null);
      setSearching(false);
    } else {
      applyModel(stateRef.current.customFilterModelJson);
    }
  }, [stateRef, applyModel]);

  const destroyFilter = useCallback((field: CustomerPortalOrderFilterField) => {
    gridRef.current?.api.destroyFilter(field);
  }, []);

  const handleDeleteFilter = async (field: CustomerPortalOrderFilterField) => {
    // reset the filter
    destroyFilter(field);

    // collapse that filter in the panel.
    const toolPanel = gridRef.current?.api.getToolPanelInstance('filters');
    toolPanel?.collapseFilters([field]);
  };
  const handleClickSelectedFilter = async (
    field: CustomerPortalOrderFilterField,
  ) => {
    // open the toolpanel and show that filter
    const toolPanel = gridRef.current?.api.getToolPanelInstance('filters');
    toolPanel?.expandFilters([field]);
    toolPanel?.setFilterLayout([
      columnDefs.find((colDef) => colDef.field === field) ?? {},
    ]);
    gridRef.current?.api.openToolPanel('filters');
  };

  // callback which AG Grid calls when a filter changes.
  const handleFilterChanged = () => {
    const filterModel = gridRef.current?.api?.getFilterModel();

    if (shouldRememberFilters) {
      setRememberedFilters(JSON.stringify(filterModel ?? {}), pageType);
    }

    setState((prevState) => {
      return {
        ...prevState,
        currentCursor: null,
      };
    });
  };

  const handleSearch = () => {
    setSearching(true);
    setState((prevState) => {
      return {
        ...prevState,
        currentCursor: null,
      };
    });
    refreshGrid(true);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setState((prevState) => ({
      ...prevState,
      orderStatus: event.target.value as OrderStatus,
    }));
    gridRef.current?.api.refreshServerSide({ purge: true });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <TextField
          size="small"
          label="Search Orders"
          InputProps={{ style: { backgroundColor: 'white' } }}
          value={stateRef.current.searchText}
          sx={{ width: '400px' }}
          onKeyDown={async (e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
          onChange={(e) => {
            setState((prevState: State) => {
              if (shouldRememberFilters) {
                setRememberedSearch(e.target.value, pageType);
              }
              return {
                ...prevState,
                searchText: e.target.value,
                currentCursor: null,
              };
            });
          }}
        />
        <Button variant="contained" disabled={searching} onClick={handleSearch}>
          Search
        </Button>
      </Grid>
      <CustomerPortalOrdersFilterRow item xs={12}>
        {companies.length > 1 && (
          <FormControl sx={{ minWidth: 200 }} size="small">
            <InputLabel id="company-select">Company</InputLabel>
            <Select
              labelId="company-select"
              value={
                isWhiteLabelUser
                  ? clientUuidResult.clientUuid
                  : (stateRef.current.companyFilter ?? null)
              }
              disabled={isWhiteLabelUser}
              label="Company"
              sx={styles.select}
              onChange={(e) => {
                setSearching(true);
                const value = isEmpty(e.target.value)
                  ? undefined
                  : e.target.value;
                let newContactFilter = contactFilter;
                if (
                  !isNil(value) &&
                  !isEmpty(contactFilter) &&
                  value !==
                    contacts.find((contact) => contact.uuid === contactFilter)
                      ?.company.uuid
                ) {
                  newContactFilter = undefined;
                  setContactFilter(newContactFilter);
                }
                setState((prevState) => ({
                  ...prevState,
                  companyFilter: value ?? undefined,
                  contactFilter: newContactFilter,
                  currentCursor: null,
                }));
                refreshGrid(true);
              }}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {companies.map((company) => (
                <MenuItem key={company.uuid} value={company.uuid}>
                  {company.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {contacts.length > 1 && (
          <FormControl sx={{ minWidth: 200 }} size="small">
            <InputLabel id="contact-select">Contact</InputLabel>
            <Select
              labelId="contact-select"
              value={contactFilter ?? ''}
              label="Contact"
              sx={styles.select}
              onChange={(e) => {
                setSearching(true);
                const value = isEmpty(e.target.value)
                  ? undefined
                  : e.target.value;
                setState((prevState) => ({
                  ...prevState,
                  contactFilter: value,
                  currentCursor: null,
                }));
                setContactFilter(value);
                refreshGrid(true);
              }}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {contacts.map((contact) => (
                <MenuItem
                  key={contact.uuid}
                  value={contact.uuid}
                  disabled={
                    !isEmpty(stateRef.current.companyFilter) &&
                    stateRef.current.companyFilter !== contact.company.uuid
                  }
                >
                  {contact.displayName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {isAgent && (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Order Status</InputLabel>
            <Select<OrderStatus>
              sx={{ maxWidth: '250px' }}
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={stateRef.current.orderStatus}
              label="Order Status"
              onChange={handleChange}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="accepted">Accepted</MenuItem>
              <MenuItem value="in progress">In Progress</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="paid">Paid</MenuItem>
            </Select>
          </FormControl>
        )}
        <Button
          size="medium"
          startIcon={<Add />}
          sx={styles.filterButton}
          variant="outlined"
          onClick={(_e) => {
            gridRef.current?.api.openToolPanel('filters');
          }}
        >
          Filter
        </Button>
        {Object.keys(gridRef.current?.api?.getFilterModel() ?? {}).map(
          (key) => {
            return (
              <SelectedFilterButtonForToolbar
                key={key}
                prependText={undefined}
                filterModel={gridRef.current?.api?.getFilterModel()}
                keyName={key}
                handleDelete={async () =>
                  handleDeleteFilter(key as CustomerPortalOrderFilterField)
                }
                handleSelect={async () =>
                  handleClickSelectedFilter(
                    key as CustomerPortalOrderFilterField,
                  )
                }
              />
            );
          },
        )}
      </CustomerPortalOrdersFilterRow>
      <Grid item xs={12}>
        <div
          className="ag-theme-material ag-non-compact"
          style={{ height: '100%', width: '100%' }}
        >
          <AgGridReact<CustomerPortalOrderForTableFragment>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref={gridRef}
            pagination
            animateRows
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowModelType="serverSide"
            paginationPageSize={pageSize}
            cacheBlockSize={pageSize}
            domLayout="autoHeight"
            sideBar={sideBarConfig}
            onGridReady={onGridReady}
            onFilterChanged={handleFilterChanged}
            onToolPanelVisibleChanged={() => {
              toolPanelVisibleRef.current = !toolPanelVisibleRef.current;
            }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default CustomerPortalOrdersTableWithFiltersAgGrid;
