import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import React, { useEffect } from 'react';
import { Controller, FormProvider, type SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { MAX_INTEGER, safeMultiply } from 'shared/math';
import { shallow } from 'zustand/shallow';
import {
  type AccessorialDateRangeConfigFragment,
  AccessorialType,
  type SpecialAccessorialType,
  useDeleteAccessorialDateRangeConfigMutation,
  useFuelProfilesQuery,
  useUpdateAccessorialDateRangeConfigMutation,
} from '../../../../../generated/graphql';
import {
  AccessorialRateTextField,
  getBackUrl,
  NO_FUEL_PROFILE,
} from '../common';
import useAccessorialDateRangeConfigForm, {
  type AccessorialDateRangeConfigFormValues,
  getAccessorialRangesForForm,
} from '../forms/use-accessorial-date-range-config-form';
import useAccessorialEditorStore from '../use-accessorial-editor-store';
import SpecialAccessorialRateMatrixEditor from './special-accessorial-rate-matrix-editor';
import WeightBasedAccessorialRateEditor from './weight-based-accessorial-rate-editor';

type AccessorialPriceEditorProps = {
  readonly accessorialUuid: string;
  readonly accessorialDateRangeConfig: AccessorialDateRangeConfigFragment;
  readonly accessorialDateRangeConfigLength: number;
  readonly refetchAccessorialDateRangeConfigs: () => Promise<void>;
  readonly accessorialType: AccessorialType;
  readonly specialAccessorialType?: SpecialAccessorialType | null;
  readonly contactUuid: string | undefined;
};
const AccessorialPriceEditor = ({
  accessorialUuid,
  accessorialDateRangeConfig,
  accessorialDateRangeConfigLength,
  refetchAccessorialDateRangeConfigs,
  accessorialType,
  specialAccessorialType,
  contactUuid,
}: AccessorialPriceEditorProps) => {
  const form = useAccessorialDateRangeConfigForm(accessorialType);
  const confirm = useConfirm();

  const fuelProfileUuidValue = form.getValues('fuelProfileUuid');

  const [
    setShowUpdateAccessorialDateRangeSuccessMessage,
    setShowUpdateAccessorialDateRangeErrorMessage,
    shouldSaveDateRangeConfig,
    setShouldSaveDateRangeConfig,
  ] = useAccessorialEditorStore(
    (state) => [
      state.setShowUpdateAccessorialDateRangeSuccessMessage,
      state.setShowUpdateAccessorialDateRangeErrorMessage,
      state.shouldSaveDateRangeConfig,
      state.setShouldSaveDateRangeConfig,
    ],
    shallow,
  );

  const [
    updateAccessorialDateRangeConfig,
    { loading: updateAccessorialDateRangeConfigLoading },
  ] = useUpdateAccessorialDateRangeConfigMutation();
  const [deleteAccessorialDateRangeConfig] =
    useDeleteAccessorialDateRangeConfigMutation();

  const { data: fuelProfilesData, loading: fuelProfilesLoading } =
    useFuelProfilesQuery();

  useEffect(() => {
    form.reset({
      startDate: accessorialDateRangeConfig.startDate,
      endDate: accessorialDateRangeConfig.endDate,
      percentForSettlement: accessorialDateRangeConfig.percentForSettlement,
      rate: accessorialDateRangeConfig.rate,
      fuelProfileUuid:
        accessorialDateRangeConfig?.fuelProfile?.uuid ?? NO_FUEL_PROFILE,
      ranges: getAccessorialRangesForForm(accessorialDateRangeConfig),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessorialDateRangeConfig]);

  /**
   * @warning Don't call this directly, use `form.handleSubmit(onSubmitSuccess)` instead to validate the form first
   */
  const onSubmitSuccess: SubmitHandler<
    AccessorialDateRangeConfigFormValues
  > = async (data) => {
    const {
      startDate,
      endDate,
      percentForSettlement,
      rate,
      fuelProfileUuid,
      ranges,
    } = data;

    const res = await updateAccessorialDateRangeConfig({
      variables: {
        updateAccessorialDateRangeConfigInput: {
          uuid: accessorialDateRangeConfig.uuid,
          accessorialUuid,
          startDate,
          endDate: endDate ?? undefined,
          percentForSettlement: isNil(percentForSettlement)
            ? undefined
            : percentForSettlement,
          rateUsd: isNil(rate) ? undefined : rate,
          fuelProfileUuid:
            !isNil(fuelProfileUuid) && fuelProfileUuid !== NO_FUEL_PROFILE
              ? fuelProfileUuid
              : null,
          accessorialRangeUpdateInputs: ranges.map((r) => ({
            uuid: r.uuid,
            lessThanOrEqualToValue: r.lessThanOrEqualToValue ?? MAX_INTEGER,
            rateUsdCents: safeMultiply(r.rate ?? 0, 100),
          })),
        },
      },
    });
    await refetchAccessorialDateRangeConfigs();
    if (isNil(res.errors)) {
      setShowUpdateAccessorialDateRangeSuccessMessage(true);
    } else {
      onSubmitError(res.errors);
    }
  };

  const onSubmitError = (error: unknown) => {
    // eslint-disable-next-line no-console
    console.error(error);
    setShowUpdateAccessorialDateRangeErrorMessage(true);
  };

  const onDelete = () => {
    confirm({
      title: 'Are you sure you want to delete this price?',
      description: (
        <Typography>
          Any orders currently using this price will no longer use this price.
          This action cannot be undone.
        </Typography>
      ),
      cancellationText: `Cancel`,
      confirmationText: `Confirm`,
    }).then(async () => {
      await deleteAccessorialDateRangeConfig({
        variables: {
          deleteAccessorialDateRangeConfigInput: {
            uuid: accessorialDateRangeConfig.uuid,
          },
        },
      });
      await refetchAccessorialDateRangeConfigs();
    });
  };

  const navigate = useNavigate();

  // This effect is SUPER hacky, we should pull the date range config form up into
  // RangeBasedAccessorialForm and do the saving there, instead of "calling" it here.
  useEffect(() => {
    if (shouldSaveDateRangeConfig) {
      (async () => {
        await form.handleSubmit(async (data) => {
          await onSubmitSuccess(data);
          navigate(getBackUrl(contactUuid));
        }, onSubmitError)();
        setShouldSaveDateRangeConfig(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSaveDateRangeConfig]);

  let label = 'Rate';
  if (accessorialType === AccessorialType.Skid) {
    label = 'Rate per skid';
  } else if (accessorialType === AccessorialType.Unit) {
    label = 'Rate per unit';
  }

  return (
    <FormProvider {...form}>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Controller
            render={({ field: { onChange, value } }) => (
              <FormControl>
                <InputLabel
                  shrink={!isEmpty(fuelProfileUuidValue)}
                  id="select-fuel-profile-label"
                >
                  Fuel Profile
                </InputLabel>
                <Select
                  sx={{ minWidth: '200px', width: 'fit-content' }}
                  labelId="select-fuel-profile-label"
                  id="select-fuel-profile"
                  name="fuelProfileUuid"
                  label="Fuel Profile"
                  size="small"
                  value={value}
                  disabled={
                    fuelProfilesLoading ||
                    updateAccessorialDateRangeConfigLoading
                  }
                  onChange={onChange}
                  onBlur={form.handleSubmit(onSubmitSuccess, onSubmitError)}
                >
                  <MenuItem key={NO_FUEL_PROFILE} value={NO_FUEL_PROFILE}>
                    {NO_FUEL_PROFILE}
                  </MenuItem>
                  {fuelProfilesData?.fuelProfiles?.map((fuelProfile) => (
                    <MenuItem key={fuelProfile.uuid} value={fuelProfile.uuid}>
                      {fuelProfile.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            name="fuelProfileUuid"
            control={form.control}
          />
        </Grid>
        <Grid item xs={7} />
        <Grid item xs={12}>
          {(accessorialType === AccessorialType.Standard ||
            accessorialType === AccessorialType.Skid ||
            accessorialType === AccessorialType.Unit ||
            accessorialType === AccessorialType.WaitTime) && (
            <Controller
              render={({ field: { onChange, value } }) => (
                <AccessorialRateTextField
                  isCurrency
                  value={value}
                  error={form.formState.errors.rate?.message}
                  name="rate"
                  label={label}
                  type="number"
                  disabled={updateAccessorialDateRangeConfigLoading}
                  onBlur={form.handleSubmit(onSubmitSuccess, onSubmitError)}
                  onChange={(e) => {
                    const parsedFloat = Number.parseFloat(e.target.value);
                    onChange(parsedFloat);
                  }}
                />
              )}
              name="rate"
              control={form.control}
            />
          )}
          {accessorialType === AccessorialType.Special && (
            <SpecialAccessorialRateMatrixEditor
              accessorialDateRangeConfigUuid={accessorialDateRangeConfig.uuid}
              specialAccessorialType={specialAccessorialType}
            />
          )}
          {accessorialType === AccessorialType.Weight && (
            <WeightBasedAccessorialRateEditor />
          )}
        </Grid>

        {accessorialDateRangeConfigLength > 1 && (
          <Grid item xs={12}>
            <Button color="error" startIcon={<DeleteIcon />} onClick={onDelete}>
              DELETE PRICE
            </Button>
          </Grid>
        )}
      </Grid>
    </FormProvider>
  );
};

export default React.memo(AccessorialPriceEditor);
