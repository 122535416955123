import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { type Option } from '../../../../common/filters/types';
import {
  type UnifiedSearchInvoicesFragment,
  type UnifiedSearchOrdersFragment,
  type UnifiedSearchQuotesFragment,
  type UnifiedSearchUnacceptedEdiAndApiOrdersFragment,
  type UnifiedSearchUnacceptedEmailOrdersFragment,
} from '../../../../generated/graphql';

type UnifiedSearchStore = {
  customerOption: Option | undefined;
  originTerminalOption: Option | undefined;
  destinationTerminalOption: Option | undefined;
  searchText: string;
  hasError: boolean;
  ordersState: UnifiedSearchOrdersFragment | null;
  unacceptedEmailOrdersState: UnifiedSearchUnacceptedEmailOrdersFragment | null;
  unacceptedEdiAndApiOrdersState: UnifiedSearchUnacceptedEdiAndApiOrdersFragment | null;
  quotesState: UnifiedSearchQuotesFragment | null;
  invoicesState: UnifiedSearchInvoicesFragment | null;
};

type UnifiedSearchActions = {
  setCustomerOption: (option: Option | undefined) => void;
  setOriginTerminalOption: (option: Option | undefined) => void;
  setDestinationTerminalOption: (option: Option | undefined) => void;
  setSearchText: (text: string) => void;
  setHasError: (hasError: boolean) => void;
  setOrdersState: (data: UnifiedSearchOrdersFragment | null) => void;
  setUnacceptedEmailOrdersState: (
    data: UnifiedSearchUnacceptedEmailOrdersFragment | null,
  ) => void;
  setUnacceptedEdiAndApiOrdersState: (
    data: UnifiedSearchUnacceptedEdiAndApiOrdersFragment | null,
  ) => void;
  setQuotesState: (data: UnifiedSearchQuotesFragment | null) => void;
  setInvoicesState: (data: UnifiedSearchInvoicesFragment | null) => void;
  clearData: () => void;
  reset: () => void;
};

const initialState: UnifiedSearchStore = {
  customerOption: undefined,
  originTerminalOption: undefined,
  destinationTerminalOption: undefined,
  searchText: '',
  hasError: false,
  ordersState: null,
  unacceptedEmailOrdersState: null,
  unacceptedEdiAndApiOrdersState: null,
  quotesState: null,
  invoicesState: null,
};

const useUnifiedSearchStore = create(
  immer<UnifiedSearchStore & UnifiedSearchActions>((set, get) => ({
    ...initialState,
    setCustomerOption: (option) => {
      set({ customerOption: option });
    },
    setOriginTerminalOption: (option) => {
      set({ originTerminalOption: option });
    },
    setDestinationTerminalOption: (option) => {
      set({ destinationTerminalOption: option });
    },
    setSearchText: (text) => {
      set({ searchText: text });
    },
    setHasError: (hasError) => {
      set({ hasError });
    },
    setOrdersState: (data) => {
      set({
        ordersState: data ?? null,
      });
    },
    setUnacceptedEmailOrdersState: (data) => {
      set({
        unacceptedEmailOrdersState: data ?? null,
      });
    },
    setUnacceptedEdiAndApiOrdersState: (data) => {
      set({
        unacceptedEdiAndApiOrdersState: data ?? null,
      });
    },
    setQuotesState: (data) => {
      set({
        quotesState: data ?? null,
      });
    },
    setInvoicesState: (data) => {
      set({
        invoicesState: data ?? null,
      });
    },
    clearData: () => {
      set({
        ordersState: null,
        unacceptedEmailOrdersState: null,
        unacceptedEdiAndApiOrdersState: null,
        quotesState: null,
        invoicesState: null,
      });
    },
    reset: () => {
      set(initialState);
    },
  })),
);

export default useUnifiedSearchStore;
