import { MenuItem, TextField } from '@mui/material';
import { sentenceCase } from 'change-case';
import { type ReactNode, memo } from 'react';
import { shallow } from 'zustand/shallow';
import { useAvailableTariffTypes } from '../../../../../common/react-hooks/use-available-tariff-types';
import { TariffGroupType } from '../../../../../generated/graphql';
import useTariffGroupActions from '../hooks/use-tariff-group-actions';
import useTariffGroupStore, {
  convertTariffGroupTypeToTariffZoneLocationType,
} from '../store/tariff-group-state-store';

const TariffGroupTypeField = () => {
  const {
    ordinaryTariffGroupAvailable,
    lineHaulTariffGroupAvailable,
    transferTariffGroupAvailable,
    pointToPointTariffGroupAvailable,
  } = useAvailableTariffTypes();

  const { refetchAndSetLocationZones } = useTariffGroupActions();
  const [tariffGroupType, zoneType, setTariffGroupType, setTariffZoneGroupId] =
    useTariffGroupStore(
      (state) => [
        state.tariffGroupType,
        state.zoneType,
        state.setTariffGroupType,
        state.setTariffZoneGroupId,
      ],
      shallow,
    );
  const handleChangeTariffGroupType = async (
    newTariffGroupType: TariffGroupType,
  ) => {
    if (newTariffGroupType !== TariffGroupType.Ordinary) {
      refetchAndSetLocationZones({
        variables: {
          tariffZoneType: zoneType,
          tariffZoneLocationType:
            convertTariffGroupTypeToTariffZoneLocationType(newTariffGroupType),
        },
      });
      setTariffZoneGroupId(null);
    }
    setTariffGroupType(newTariffGroupType);
  };

  const menuItems: ReactNode[] = [];
  if (ordinaryTariffGroupAvailable) {
    menuItems.push(
      <MenuItem value={TariffGroupType.Ordinary}>Default</MenuItem>,
    );
  }
  if (transferTariffGroupAvailable) {
    menuItems.push(
      <MenuItem value={TariffGroupType.Transfer}>
        {sentenceCase(TariffGroupType.Transfer)}
      </MenuItem>,
    );
  }
  if (lineHaulTariffGroupAvailable) {
    menuItems.push(
      <MenuItem value={TariffGroupType.LineHaul}>
        {sentenceCase(TariffGroupType.LineHaul)}
      </MenuItem>,
    );
  }
  if (pointToPointTariffGroupAvailable) {
    menuItems.push(
      <MenuItem value={TariffGroupType.PointToPoint}>
        {sentenceCase(TariffGroupType.PointToPoint)}
      </MenuItem>,
    );
  }

  return (
    <TextField
      fullWidth
      select
      label="Tariff Group Type"
      value={tariffGroupType}
      size="small"
      onChange={async (e) =>
        handleChangeTariffGroupType(e.target.value as TariffGroupType)
      }
    >
      {menuItems}
    </TextField>
  );
};

export default memo(TariffGroupTypeField);
