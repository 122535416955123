import {
  type ICellRendererParams,
  type ColDef,
  type ValueGetterParams,
} from 'ag-grid-community';
import {
  QuoteSource,
  QuoteStatus,
  type ClientQuoteFragment,
} from '../../../../generated/graphql';
import dayjs from 'dayjs';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import { isNil } from 'lodash';
import { Stack } from '@mui/material';
import ButtonLink from '../../../../common/components/buttons/button-link';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';

enum QuotesTableField {
  QuoteNumber = 'QuoteNumber',
  CreatedAt = 'CreatedAt',
  Source = 'Source',
  Status = 'Status',
  Customer = 'Customer',
  TotalCharge = 'TotalCharge',
}

type UseQuotesTableColumnDefsProps = {
  readonly setQuote: (quote: ClientQuoteFragment) => void;
  readonly setShowQuotesModal: (showQuotesModal: boolean) => void;
};

/**
 * @description A hook that encapsulates the column definitions for the quotes table, including header names, value getters, column sizes etc.
 * @returns An array of column definitions for the quotes table.
 */
export const useQuotesTableColumnDefs = ({
  setQuote,
  setShowQuotesModal,
}: UseQuotesTableColumnDefsProps): Array<ColDef<ClientQuoteFragment>> => {
  const navigate = useNavigate();

  const columnDefs: Array<ColDef<ClientQuoteFragment>> = useMemo(() => {
    return [
      {
        field: QuotesTableField.QuoteNumber,
        headerName: 'Number',
        valueGetter: (params: ValueGetterParams<ClientQuoteFragment>) => {
          return params.data?.number;
        },
      },
      {
        headerName: 'Created',
        field: QuotesTableField.CreatedAt,
        valueGetter: (params: ValueGetterParams<ClientQuoteFragment>) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          return dayjs(params?.data?.createdAt).format('MM/DD/YYYY');
        },
      },
      {
        headerName: 'Source',
        field: QuotesTableField.Source,
        valueGetter: (params: ValueGetterParams<ClientQuoteFragment>) => {
          return params.data === undefined
            ? '-'
            : sentenceCase(params.data.source);
        },
      },
      {
        headerName: 'Status',
        field: QuotesTableField.Status,
        valueGetter: (params: ValueGetterParams<ClientQuoteFragment>) => {
          return params.data === undefined
            ? '-'
            : sentenceCase(params.data.status);
        },
      },
      {
        headerName: 'Customer',
        field: QuotesTableField.Customer,
        valueGetter: (params: ValueGetterParams<ClientQuoteFragment>) => {
          return params.data?.billingPartyContact?.displayName;
        },
      },
      {
        headerName: 'Total Charge',
        field: QuotesTableField.TotalCharge,
        valueGetter: (params: ValueGetterParams<ClientQuoteFragment>) => {
          return isNil(params.data?.totalCharge)
            ? '-'
            : currency(params.data.totalCharge.value).format();
        },
      },
      {
        field: 'Button',
        headerName: 'Actions',
        width: 200,
        maxWidth: 200,
        cellRenderer: (params: ICellRendererParams<ClientQuoteFragment>) => {
          const quote = params.data;
          if (quote === undefined) {
            return null;
          }

          return (
            <Stack
              direction="row"
              gap={1}
              alignItems="center"
              sx={{ height: '100%' }}
            >
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  navigate(`/order-entry/quotes/${quote.uuid}`);
                }}
              >
                View
              </Button>
              {quote.status === QuoteStatus.Pending &&
                quote.source === QuoteSource.ManuallyCreated && (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      setQuote(quote);
                      setShowQuotesModal(true);
                    }}
                  >
                    Approve
                  </Button>
                )}
              {!isNil(quote.order) && (
                <ButtonLink
                  href={`/orders?orderUuid=${quote.order.uuid}`}
                  variant="outlined"
                  size="small"
                >
                  Open order
                </ButtonLink>
              )}
            </Stack>
          );
        },
      },
    ];
  }, [navigate, setQuote, setShowQuotesModal]);

  return columnDefs;
};
