import { Box, Stack } from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useState } from 'react';
import { isNilOrEmptyString } from 'shared/string';
import { shallow } from 'zustand/shallow';
import CommentInput from '../../../common/components/comments/order-comments/comment-input';
import CommentsList from '../../../common/components/comments/order-comments/comments-list';
import {
  useCreateContactCommentMutation,
  useDeleteContactCommentMutation,
  useOrganizationNotesQuery,
  useUpdateContactCommentMutation,
} from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import { OrderFormEditAccessProvider } from '../../orders/components/order-form/contexts/order-form-edit-access-context';
import { OrderFormEditAccess } from '../../orders/components/order-form/forms/use-order-form-edit-access';

type OrganizationNotesProps = {
  readonly organizationUuid: string;
  readonly userUuid: string | undefined;
  readonly loading: boolean;
};

const OrganizationNotes = ({
  organizationUuid,
  userUuid,
  loading,
}: OrganizationNotesProps) => {
  const [
    setSuccessMessage,
    setShowSuccessMessage,
    setErrorMessage,
    setShowErrorMessage,
  ] = useGlobalStore(
    (state) => [
      state.setSuccessMessage,
      state.setShowSuccessMessage,
      state.setErrorMessage,
      state.setShowErrorMessage,
    ],
    shallow,
  );

  const [newComment, setNewComment] = useState('');

  // Query for comments
  const { data: notesData, refetch: refetchComments } =
    useOrganizationNotesQuery({
      variables: {
        findOrganizationNotesInput: { organizationUuid },
      },
    });
  const noteList = notesData?.organizationNotes ?? [];

  const [createContactComment] = useCreateContactCommentMutation();
  const [updateContactComment] = useUpdateContactCommentMutation();
  const [deleteContactComment] = useDeleteContactCommentMutation();

  const showUpdateContactCommentErrorMessage = () => {
    setErrorMessage(`Error updating order comment`);
    setShowErrorMessage(true);
  };

  const onEditContactComment = async (
    commentUuid: string,
    newOrderComment: string,
  ) => {
    if (isNilOrEmptyString(commentUuid)) {
      showUpdateContactCommentErrorMessage();
      return;
    }

    try {
      await updateContactComment({
        variables: {
          updateContactCommentInput: {
            uuid: commentUuid,
            comment: newOrderComment,
          },
        },
      });
      await refetchComments();
      setSuccessMessage('Successfully updated note');
      setShowSuccessMessage(true);
    } catch {
      showUpdateContactCommentErrorMessage();
    }
  };

  const showDeleteOrderCommentErrorMessage = () => {
    setErrorMessage(`Error deleting note`);
    setShowErrorMessage(true);
  };
  const onDeleteContactComment = async (commentUuid: string) => {
    if (isEmpty(commentUuid)) {
      showDeleteOrderCommentErrorMessage();
      return;
    }

    try {
      const deleteContactCommentResponse = await deleteContactComment({
        variables: {
          deleteContactCommentInput: {
            uuid: commentUuid,
          },
        },
      });
      if (
        deleteContactCommentResponse.data?.deleteContactComment.__typename ===
        'DeleteContactCommentSuccessOutput'
      ) {
        await refetchComments();
        setSuccessMessage('Successfully deleted note');
        setShowSuccessMessage(true);
      } else {
        showDeleteOrderCommentErrorMessage();
      }
    } catch {
      showDeleteOrderCommentErrorMessage();
    }
  };

  const onCreateComment = async () => {
    if (
      isNil(userUuid) ||
      isNil(organizationUuid) ||
      isEmpty(newComment.trim())
    ) {
      return;
    }

    try {
      await createContactComment({
        variables: {
          createContactCommentInput: {
            contactUuid: organizationUuid,
            comment: newComment,
            userUuid,
          },
        },
      });

      await refetchComments();
      setNewComment('');
      setSuccessMessage('Successfully created note');
      setShowSuccessMessage(true);
    } catch {
      setErrorMessage('Error creating note');
      setShowErrorMessage(true);
    }
  };

  return (
    <Stack height="100%">
      <CommentInput
        value={newComment}
        disabled={loading}
        onChange={(e) => {
          setNewComment(e.target.value);
        }}
        onSubmit={onCreateComment}
      />
      <OrderFormEditAccessProvider value={OrderFormEditAccess.All}>
        <Box sx={{ flexGrow: 1, minHeight: 0 }}>
          <CommentsList
            comments={noteList}
            onEditComment={onEditContactComment}
            onDeleteComment={onDeleteContactComment}
          />
        </Box>
      </OrderFormEditAccessProvider>
    </Stack>
  );
};

export default OrganizationNotes;
