import { zodResolver } from '@hookform/resolvers/zod';
import { isNil } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { v4 } from 'uuid';
import {
  type OrganizationFragment,
  OrganizationType,
} from '../../../generated/graphql';
import { OrganizationPageMode } from '../enums';
import { getOrganizationSchema } from './organization-schema';
import { type OrganizationFormValues } from './types';
import { convertOrganizationToFormValues } from './utils';

const DEFAULT_FORM_VALUES: Required<OrganizationFormValues> = {
  uuid: v4(),
  name: '',
  types: [OrganizationType.Customer],
  isCustomer: true,
  isAgent: false,
  referenceNumber: null,
  email: '',
  defaultContactPersonUuid: null,
  isaId: null,
  ediContactCode: null,
  stediPartnerId: null,
  ediApplicationId: null,
  defaultAddressUuid: null,
  businessDivisionUuid: null,
  invoiceOrOrderIdentifier: '',
  defaultServiceUuid: null,
  defaultInboundEdiServiceUuid: null,
  defaultOutboundEdiServiceUuid: null,
  defaultInboundOutboundEdiServiceUuid: null,
  defaultPointToPointTariffChainId: null,
  defaultFuelSurcharge: null,
  defaultDimFactor: null,
  defaultTransferTariffChainId: null,
  defaultLineHaulTariffChainId: null,
  defaultOrdinaryTariffChainId: null,
  showEdiReferenceNumbersOnOrderPage: null,
  isPrepaidOnly: null,
  defaultUseTariff: null,
  serviceUuids: [],
  vehicleTypeUuids: [],
  useCustomVehicleTypes: null,
  enableCustomerPortalOrderEntry: null,
  referenceNumberLabels: [],
};

const useOrganizationForm = ({
  organizationTypes,
  data,
  dataLoading,
  mode,
}: {
  organizationTypes: OrganizationType[];
  data: OrganizationFragment | null;
  dataLoading: boolean;
  mode: OrganizationPageMode;
}) => {
  const { schema, variant, isAgent, isCustomer } = useMemo(
    () => getOrganizationSchema(organizationTypes),
    [organizationTypes],
  );

  const form = useForm<OrganizationFormValues>({
    resolver: zodResolver(schema),
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: DEFAULT_FORM_VALUES,
  });

  // This is a workaround to update the form values when the organization types change.
  // even though the schema has literals it doesn't seem to work...
  useEffect(() => {
    form.setValue('isAgent', isAgent, { shouldValidate: true });
    form.setValue('isCustomer', isCustomer, { shouldValidate: true });
  }, [form, isAgent, isCustomer]);

  useEffect(() => {
    if (!isNil(data)) {
      const organizationValues = convertOrganizationToFormValues(data);
      if (!isNil(organizationValues)) {
        form.reset(organizationValues);
      }
    }
  }, [data, form]);

  return useMemo(
    () => ({
      form,
      loading: mode === OrganizationPageMode.EDIT && isNil(data) && dataLoading,
      isAgent,
      isCustomer,
      variant,
    }),
    [form, mode, data, dataLoading, variant, isAgent, isCustomer],
  );
};

export default useOrganizationForm;
