import { type MutationFunction } from '@apollo/client';
import { cubicMeters } from '@buge/ts-units/volume';
import currency from 'currency.js';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Decimal } from 'decimal.js';
import { isEmpty, isNil, omit } from 'lodash';
import { filterNotNil } from 'shared/array';
import { getNoonOfDay } from 'shared/date';
import { addMany, safeDivide, safeMultiply } from 'shared/math';
import { getPermissionsFlags } from 'shared/roles';
import { exhaustive } from 'shared/switch';
import { Temporal } from 'temporal-polyfill';
import { v4 } from 'uuid';
import apolloClient from '../../../../../apollo-client';
import type { DefaultPackageSpec } from '../../../../../common/react-hooks/use-get-contact-default-package-spec';
import useMe from '../../../../../common/react-hooks/use-me';
import useUserRoles from '../../../../../common/react-hooks/use-user-roles';
import { BillingMethod } from '../../../../../common/types';
import {
  convertKilogramsToPounds,
  convertPoundsToKilograms,
  convertToCentimeters,
  convertToInches,
  isNilOrEmptyString,
} from '../../../../../common/utils/utils';
import { isDevelopment } from '../../../../../environment-variables';
import {
  type AddressUpsertInput,
  AppointmentCallStatus,
  AppointmentTextStatus,
  type CompanyConfigurationFragment,
  type CreateOrderCommentInput,
  type CreateStandardOrderMutationFn,
  type CustomChargeArrayUpdateInput,
  CustomChargeBillingMethod,
  type CustomChargeCreateInput,
  type CustomChargeFragment,
  DayOfWeekAll,
  DeadlineType,
  type Exact,
  FreightBillingMethod,
  type FreightChargeCreateInput,
  type FreightChargeUpsertInput,
  FuelBillingMethod,
  FulfillmentType,
  InboundMethod,
  type IndependentCustomCostCreateInput,
  type IndependentCustomCostUpsertInput,
  type IndependentFreightCostCreateInput,
  type IndependentFreightCostUpsertInput,
  type IndependentSettlementBillLineItemCreateInput,
  type IndependentSettlementBillLineItemUpsertInput,
  MeasurementUnits,
  NumberOfConsignees,
  NumberOfShippers,
  OrderBillingStatus,
  OrderDetailedStatus,
  OrderNameDocument,
  type OrderNameQuery,
  type OrderNameQueryVariables,
  OrderSegmentType,
  OrderSource,
  OrderStatus,
  OutboundMethod,
  type OutstandingOrderFragmentFragment,
  type OutstandingStandardShipmentFragment,
  type PackageArrayUpdateInput,
  type PackageCreateInput,
  type PackageFragment,
  type PackageType,
  PermissionResource,
  PickupOrDelivery,
  RepeatFrequencyUnit,
  type SettlementBillLineItemForOrderFragment,
  SettlementMode,
  type ShallowContactFragment,
  type ShipmentChargesFragment,
  ShipmentStatus,
  ShipmentType,
  type StandardOrderCreateInput,
  type StandardOrderFragmentFragment,
  type StandardOrderQueryResult,
  type StandardShipmentArrayUpdateInput,
  type StandardShipmentCreateInput,
  type StandardShipmentFragment,
  type StandardShipmentFreightChargeFragment,
  StandardShipmentType,
  StandardStopType,
  type StopDriverMapForOrderFragment,
  StopStatus,
  StopType,
  type UpdateStandardOrderInput,
  type UpdateStandardOrderMutationFn,
} from '../../../../../generated/graphql';
import {
  dummyStopTypes,
  inboundStopTypes,
  isCrossDock,
  outboundStopTypes,
} from '../../../lib/stop-types';
import { shouldUpdateTotalCharge } from '../components/charges/utils';
import { INBOUND_STOP_IDX, OUTBOUND_STOP_IDX } from '../components/constants';
import { type OrderUpdateFn, type TimingData } from '../types';
import {
  convertDollarsToDecimalCents,
  getExpectedOrderComponentStatus,
  getOutboundStop,
} from '../utils';
import {
  coalesceStopTypesForBackend,
  deriveLogicalStopType,
  isInboundStop,
  isPartnerCarrierStop,
} from './stop-type';
import {
  type AddressOptionalValues,
  type AddressValues,
  type ContactPersonValues,
  type CustomChargeValues,
  type CustomCostValues,
  type FreightChargeValues,
  type FreightCostValues,
  type FuelChargeValues,
  type FuelCostValues,
  type IndependentSettlementBillLineItemValues,
  type LineHaulShipmentValues,
  type OrderChargesShipmentValues,
  type OrderFormValues,
  type PackageValues,
  type RecurringOrderFrequencyValues,
  type StopDriverMapValues,
  type StopValues,
  Units,
} from './types';

dayjs.extend(isBetween);

export const DUPLICATE_EVERYTHING = 'everything';
export const DUPLICATE_NOTHING = 'nothing';

type CanFinalizeChargesResult = {
  userHasPermissionToFinalizeCharges: boolean;
  isOrderFinalizable: boolean;
  reasonChargesNotFinalizable: string | null;
};

export const useCanFinalizeCharges = ({
  detailedStatus,
  packages,
  isPaperworkComplete,
}: {
  detailedStatus: OrderDetailedStatus | undefined | null;
  packages: PackageValues[];
  isPaperworkComplete: boolean;
}): CanFinalizeChargesResult => {
  const { companyConfiguration } = useMe();
  const { userPermissions } = useUserRoles();
  const { canWrite: userCanFinalizeCharges } = getPermissionsFlags(
    userPermissions,
    PermissionResource.FinalizeChargesOrders,
  );
  const { canWrite: userCanFinalizeChargesWithoutPaperworkComplete } =
    getPermissionsFlags(
      userPermissions,
      PermissionResource.FinalizeChargesOrdersPaperworkIncomplete,
    );

  if (
    detailedStatus === OrderDetailedStatus.InvoicePosted ||
    detailedStatus === OrderDetailedStatus.OnInvoice
  ) {
    return {
      userHasPermissionToFinalizeCharges: userCanFinalizeCharges,
      isOrderFinalizable: false,
      reasonChargesNotFinalizable: 'Order is on an invoice',
    };
  }

  if (
    companyConfiguration?.requireDimsToFinalizeCharges === true &&
    !packages.every(
      (p) => !isNil(p.height) && !isNil(p.length) && !isNil(p.width),
    )
  ) {
    return {
      userHasPermissionToFinalizeCharges: userCanFinalizeCharges,
      isOrderFinalizable: false,
      reasonChargesNotFinalizable:
        'All packages require dims in order to finalize',
    };
  }

  if (!isPaperworkComplete) {
    if (userCanFinalizeChargesWithoutPaperworkComplete) {
      return {
        userHasPermissionToFinalizeCharges: userCanFinalizeCharges,
        isOrderFinalizable: true,
        reasonChargesNotFinalizable: null,
      };
    }
    return {
      userHasPermissionToFinalizeCharges: userCanFinalizeCharges,
      isOrderFinalizable: false,
      reasonChargesNotFinalizable:
        'Charges cannot be finalized because paperwork is not complete',
    };
  }

  return {
    userHasPermissionToFinalizeCharges: userCanFinalizeCharges,
    isOrderFinalizable: true,
    reasonChargesNotFinalizable: null,
  };
};

export const convertCasing = (
  value: string | null | undefined,
  useAllCaps: boolean | undefined,
) => {
  if (useAllCaps === true) {
    return value?.toUpperCase();
  }
  return value;
};

const convertCasingStringArray = (
  value: string[],
  useAllCaps: boolean | undefined,
) => {
  if (useAllCaps === true) {
    return value.map((v) => v.toUpperCase());
  }
  return value;
};

export const getCanMarkReceivedAtOrigin = ({
  stops,
}: {
  stops: StopValues[];
}): boolean => {
  const originTerminalUuid = stops?.[INBOUND_STOP_IDX]?.terminalUuid;
  const destinationTerminalUuid = stops?.[OUTBOUND_STOP_IDX]?.terminalUuid;
  return (
    !isNil(originTerminalUuid) &&
    !isNil(destinationTerminalUuid) &&
    originTerminalUuid !== destinationTerminalUuid
  );
};

export const getCanMarkOrderAsNotComplete = ({
  stops,
  orderStatus,
}: {
  stops: StopValues[] | null | undefined;
  orderStatus: OrderStatus | null | undefined;
}): boolean => {
  const everyStopCompleted =
    stops?.every((s) => !isNil(s.completedAt)) ?? false;
  return orderStatus === OrderStatus.Delivered && everyStopCompleted;
};

export const getCannotCompleteOrder = ({
  stops,
  terminalsEnabled,
  ffRequireTransferAddress,
}: {
  stops: StopValues[] | null | undefined;
  terminalsEnabled: boolean;
  ffRequireTransferAddress: boolean;
}): string | null => {
  const outboundStop = getOutboundStop(stops ?? []);
  if (outboundStop?.stopType === StopType.Transfer) {
    if (ffRequireTransferAddress && isEmpty(outboundStop?.address?.line1)) {
      return 'The transfer stop for the order needs to have an address';
    }
    if (terminalsEnabled && isEmpty(outboundStop?.terminalUuid)) {
      return 'The transfer stop for the order needs to have a destination';
    }
  }
  return null;
};

const getHideFromBilling = ({
  hideFromBilling,
  stopType,
}: {
  hideFromBilling: boolean;
  stopType: StopType;
}) => {
  return hideFromBilling || isPartnerCarrierStop(stopType);
};

export const getPickupOrDelivery = (
  stopType:
    | StopType.Delivery
    | StopType.Pickup
    | StopType.Recovery
    | StopType.Transfer
    | StopType.PartnerCarrierPickup
    | StopType.PartnerCarrierDropoff,
) => {
  switch (stopType) {
    case StopType.PartnerCarrierPickup:
    case StopType.Delivery: {
      return PickupOrDelivery.Delivery;
    }
    case StopType.PartnerCarrierDropoff:
    case StopType.Pickup: {
      return PickupOrDelivery.Pickup;
    }
    case StopType.Recovery: {
      return PickupOrDelivery.Recovery;
    }
    case StopType.Transfer: {
      return PickupOrDelivery.Transfer;
    }
    default: {
      return exhaustive(stopType);
    }
  }
};

export const convertPackagePayloadToFormData = ({
  package: pkg,
  useCentimeters,
  useKilograms,
}: {
  package: Omit<PackageFragment, 'packageSpec'> & {
    packageSpec?: { id: string } | null | undefined;
  };
  useCentimeters: boolean | null | undefined;
  useKilograms: boolean | null | undefined;
}): PackageValues => {
  let { width, length, height, weight } = pkg;
  if (useCentimeters === true) {
    width = convertToCentimeters(width);
    length = convertToCentimeters(length);
    height = convertToCentimeters(height);
  }
  if (useKilograms === true) {
    weight = convertPoundsToKilograms(weight);
  }
  return {
    uuid: pkg.uuid,
    packageSpecId: pkg.packageSpec?.id,
    description: pkg.description,
    width: width ?? null,
    height: height ?? null,
    length: length ?? null,
    weight: weight ?? null,
    quantity: pkg.quantity ?? 1,
    type: pkg.type ?? null,
    warehouseLocationName: pkg.warehouseLocation?.name ?? null,
    warehouseLocationUuid: pkg.warehouseLocation?.uuid ?? null,
  };
};

const convertFreightChargePayloadToFormData = (
  freightCharge: StandardShipmentFreightChargeFragment,
  shipmentCharges: ShipmentChargesFragment,
): FreightChargeValues => {
  const { totalFuelCharge } = shipmentCharges;
  const freightChargeSettlementBillingMethod = isNil(
    freightCharge?.settlementFlatRate,
  )
    ? BillingMethod.Percentage
    : BillingMethod.FlatRate;
  let freightChargeDeductionTotal = 0;
  if (freightChargeSettlementBillingMethod === BillingMethod.FlatRate) {
    freightChargeDeductionTotal = freightCharge.settlementFlatRate ?? -1;
  } else if (
    freightChargeSettlementBillingMethod === BillingMethod.Percentage
  ) {
    freightChargeDeductionTotal = currency(freightCharge.total ?? 0).multiply(
      safeDivide(freightCharge.settlementPercentageRate ?? 0, 100, 5),
    ).value;
  }

  const fuelChargeSettlementBillingMethod = isNil(
    freightCharge?.fuelCharge?.settlementFlatRate,
  )
    ? BillingMethod.Percentage
    : BillingMethod.FlatRate;
  let fuelChargeDeductionTotal = 0;
  if (fuelChargeSettlementBillingMethod === BillingMethod.FlatRate) {
    fuelChargeDeductionTotal =
      freightCharge.fuelCharge?.settlementFlatRate ?? -1;
  } else if (fuelChargeSettlementBillingMethod === BillingMethod.Percentage) {
    fuelChargeDeductionTotal = currency(totalFuelCharge ?? 0).multiply(
      safeDivide(
        freightCharge.fuelCharge?.settlementPercentageRate ?? 0,
        100,
        5,
      ),
    ).value;
  }

  return {
    tariffUuid: freightCharge?.tariff?.uuid ?? null,
    uuid: freightCharge?.uuid ?? v4(),
    billingMethod:
      freightCharge?.billingMethod ?? FreightBillingMethod.FlatRate,
    quantity: freightCharge?.quantity,
    discountRate: freightCharge?.discountRate ?? null,
    description: freightCharge?.description ?? null,
    errorMessage: null,
    authoCode: freightCharge?.authoCode ?? null,
    deductionTotal: freightChargeDeductionTotal,
    rate: freightCharge?.rate,
    settlementFlatRate: freightCharge?.settlementFlatRate ?? null,
    settlementPercentageRate: freightCharge?.settlementPercentageRate ?? null,
    settlementBillingMethod: freightChargeSettlementBillingMethod,
    totalCharge: freightCharge.total,
    fuelCharge: {
      totalCharge: totalFuelCharge,
      uuid: freightCharge?.fuelCharge?.uuid ?? v4(),
      billingMethod:
        freightCharge?.fuelCharge?.type ?? FuelBillingMethod.AutoCalculate,
      deductionTotal: fuelChargeDeductionTotal,
      surchargeRate: freightCharge?.fuelCharge?.surchargeRate ?? null,
      flatRateDollars: freightCharge?.fuelCharge?.flatRate ?? null,
      description: freightCharge?.fuelCharge?.description ?? null,
      authoCode: freightCharge?.fuelCharge?.authoCode ?? null,
      settlementFlatRate: freightCharge?.fuelCharge?.settlementFlatRate ?? null,
      settlementPercentageRate:
        freightCharge?.fuelCharge?.settlementPercentageRate ?? null,
      settlementBillingMethod: fuelChargeSettlementBillingMethod,
    },
  };
};

const convertCustomChargePayloadToFormData = (
  customCharge: CustomChargeFragment,
): CustomChargeValues => {
  const settlementBillingMethod = isNil(customCharge?.settlementFlatRate)
    ? BillingMethod.Percentage
    : BillingMethod.FlatRate;
  let deductionTotal = 0;
  if (settlementBillingMethod === BillingMethod.FlatRate) {
    deductionTotal = customCharge.settlementFlatRate ?? 0;
  } else if (settlementBillingMethod === BillingMethod.Percentage) {
    deductionTotal = currency(customCharge.total ?? 0).multiply(
      safeDivide(customCharge.settlementPercentageRate ?? 0, 100, 5),
    ).value;
  }

  return {
    isAutoApplied: customCharge.isAutoApplied,
    rate: customCharge.rate,
    quantity: customCharge.quantity,
    accessorialUuid: customCharge.accessorialTemplate?.uuid ?? null,
    accessorialName: customCharge.accessorialTemplate?.name ?? null,
    accessorialType: customCharge.accessorialTemplate?.type ?? null,
    deductionTotal,
    zoneUuid:
      customCharge.accessorialTemplate?.__typename ===
      'ZoneBasedAccessorialEntity'
        ? (customCharge.zoneBasedAccessorialZoneUuid ?? null)
        : (customCharge.specialAccessorialTariffZoneUuid ?? null),
    chargeGroupUuid:
      customCharge.accessorialTemplate?.__typename ===
      'ZoneBasedAccessorialEntity'
        ? (customCharge.zoneBasedAccessorialChargeGroupUuid ?? null)
        : (customCharge.specialAccessorialChargeGroupUuid ?? null),
    specialAccessorialMatrixItemUuid:
      customCharge.specialAccessorialMatrixItem?.uuid ?? null,
    zoneBasedAccessorialMatrixItemUuid:
      customCharge.zoneBasedAccessorialMatrixItem?.uuid ?? null,
    accessorialRangeUuid:
      customCharge.accessorialTemplate?.__typename ===
      'WeightBasedAccessorialEntity'
        ? (customCharge.accessorialRange?.uuid ?? null)
        : null,
    uuid: customCharge.uuid,
    billingMethod: customCharge.billingMethod,
    description: customCharge.description ?? null,
    authoCode: customCharge?.authoCode ?? null,
    isLocal: false,
    name:
      !isEmpty(customCharge.name) && !isNil(customCharge.name)
        ? customCharge.name
        : (customCharge.accessorialTemplate?.name ?? ''),
    fuelSurchargePercentageRate: customCharge.fuelSurchargeRate ?? null,
    postedFuelSurchargeRate: customCharge.postedFuelSurchargeRate ?? null,
    settlementFlatRate: customCharge?.settlementFlatRate ?? null,
    settlementPercentageRate: customCharge?.settlementPercentageRate ?? null,
    settlementBillingMethod,
    totalCharge: customCharge.total,
    useAccessorialRate: false,
  };
};

export const convertLineHaulShipmentPayloadToFormData = ({
  shipment,
  lineHaulCompletedDate,
}: {
  shipment: StandardShipmentFragment | OutstandingStandardShipmentFragment;
  lineHaulCompletedDate: Date | null;
}): LineHaulShipmentValues => {
  return {
    uuid: shipment.uuid,
    freightCharge: isNil(shipment.freightCharge)
      ? null
      : convertFreightChargePayloadToFormData(
          shipment.freightCharge,
          shipment.shipmentCharges,
        ),
    isLocal: false,
    dateForFuelCharge: lineHaulCompletedDate,
    invoiceName: shipment.invoice?.name,
    invoiceUuid: shipment.invoice?.uuid,
    invoiceDate: shipment.invoice?.date,
    invoicePostedDate: shipment.invoice?.postedAt,
  };
};

export const convertOrderChargesShipmentPayloadToFormData = (
  shipment: StandardShipmentFragment | OutstandingStandardShipmentFragment,
): OrderChargesShipmentValues => {
  return {
    uuid: shipment.uuid,
    freightCharge: isNil(shipment.freightCharge)
      ? null
      : convertFreightChargePayloadToFormData(
          shipment.freightCharge,
          shipment.shipmentCharges,
        ),
    customCharges: shipment.customCharges.map((customCharge) =>
      convertCustomChargePayloadToFormData(customCharge),
    ),
    isLocal: false,
    shipmentStatus: shipment.status,
    invoiceName: shipment.invoice?.name,
    invoiceUuid: shipment.invoice?.uuid,
    invoiceDate: shipment.invoice?.date,
    invoicePostedDate: shipment.invoice?.postedAt,
  };
};

export const getInitialFuelChargeValues = ({
  defaultFuelBillingMethod,
  defaultFuelSurcharge,
}: {
  defaultFuelBillingMethod?: FuelBillingMethod | null;
  defaultFuelSurcharge?: number | null;
}): FuelChargeValues => {
  return {
    uuid: v4(),
    billingMethod: defaultFuelBillingMethod ?? FuelBillingMethod.AutoCalculate,
    deductionTotal: 0,
    surchargeRate: defaultFuelSurcharge ?? 0,
    totalCharge: 0,
    description: null,
    authoCode: null,
    settlementPercentageRate: null,
    settlementFlatRate: null,
    settlementBillingMethod: null,
    flatRateDollars: null,
  };
};

export const getInitialFuelCostValues = ({
  defaultFuelBillingMethod,
  defaultFuelSurcharge,
}: {
  defaultFuelBillingMethod?: FuelBillingMethod | null;
  defaultFuelSurcharge?: number | null;
}): FuelCostValues => {
  return {
    uuid: v4(),
    billingMethod: defaultFuelBillingMethod ?? FuelBillingMethod.AutoCalculate,
    surchargeRate: defaultFuelSurcharge ?? 0,
    totalCharge: 0,
    flatRateDollars: null,
    description: null,
    authoCode: null,
  };
};

export const zeroFreightChargeStopTypes: StopType[] = [
  StopType.Recovery,
  StopType.PartnerCarrierDropoff,
  StopType.PartnerCarrierPickup,
  StopType.None,
];

export type FreightCostValuesContext = {
  fulfillmentType: FulfillmentType;
  defaultFuelSurcharge?: number | null;
  context:
    | {
        shipmentType: ShipmentType.Regular;
        stopType: StopType | null | undefined;
      }
    | {
        shipmentType: ShipmentType.LineHaul | ShipmentType.OrderCharges;
      };
};

export type FreightChargeValuesContext = FreightCostValuesContext & {
  freightBillingMethod: FreightBillingMethod;
};

export const getInitialFreightChargeValues = ({
  fulfillmentType,
  defaultFuelSurcharge,
  freightBillingMethod,
  context,
}: FreightChargeValuesContext): FreightChargeValues | null => {
  if (
    fulfillmentType === FulfillmentType.MultiTrip &&
    context.shipmentType === ShipmentType.OrderCharges
  ) {
    return null;
  }
  if (
    fulfillmentType === FulfillmentType.Dedicated &&
    context.shipmentType === ShipmentType.Regular
  ) {
    return null;
  }

  let shouldUseNoCharge = false;
  if (context.shipmentType === ShipmentType.Regular) {
    shouldUseNoCharge =
      fulfillmentType === FulfillmentType.Dedicated
        ? true
        : zeroFreightChargeStopTypes.includes(
            context.stopType ?? StopType.Pickup,
          );
  }

  return {
    uuid: v4(),
    billingMethod: shouldUseNoCharge
      ? FreightBillingMethod.FlatRate
      : freightBillingMethod,
    deductionTotal: 0,
    quantity: 0,
    rate: 0,
    totalCharge: 0,
    fuelCharge: getInitialFuelChargeValues({
      defaultFuelBillingMethod: shouldUseNoCharge
        ? FuelBillingMethod.None
        : FuelBillingMethod.AutoCalculate,
      defaultFuelSurcharge: shouldUseNoCharge ? 0 : defaultFuelSurcharge,
    }),
    discountRate: null,
    description: null,
    authoCode: null,
    settlementPercentageRate: null,
    settlementFlatRate: null,
    settlementBillingMethod: null,
    tariffUuid: null,
    errorMessage: null,
  };
};

export const getInitialFreightCostValues = ({
  fulfillmentType,
  defaultFuelSurcharge,
  context,
}: FreightCostValuesContext): FreightCostValues | null => {
  if (
    fulfillmentType === FulfillmentType.Dedicated &&
    context.shipmentType === ShipmentType.Regular
  ) {
    return null;
  }

  let shouldUseNoCharge = false;
  if (context.shipmentType === ShipmentType.Regular) {
    shouldUseNoCharge =
      fulfillmentType === FulfillmentType.Dedicated
        ? true
        : zeroFreightChargeStopTypes.includes(
            context.stopType ?? StopType.Pickup,
          );
  }

  return {
    uuid: v4(),
    // Billing method defaults to tariff in settlement
    billingMethod: shouldUseNoCharge
      ? FreightBillingMethod.FlatRate
      : FreightBillingMethod.Tariff,
    fuelCost: getInitialFuelCostValues({
      defaultFuelBillingMethod: shouldUseNoCharge
        ? FuelBillingMethod.None
        : FuelBillingMethod.AutoCalculate,
      defaultFuelSurcharge: shouldUseNoCharge ? 0 : defaultFuelSurcharge,
    }),
    quantity: 0,
    rate: 0,
    totalCharge: 0,
    discountRate: null,
    tariffUuid: null,
    errorMessage: null,
    authoCode: null,
    description: null,
  };
};

export const convertStopDriverMapPayloadToFormData = ({
  stopDriverMap,
  isAttempt,
  attemptedAt,
}: {
  stopDriverMap: StopDriverMapForOrderFragment;
  isAttempt?: boolean;
  attemptedAt?: Date;
}): StopDriverMapValues => {
  return {
    uuid: stopDriverMap.uuid,
    driverUuid: stopDriverMap.driver.uuid,
    stopUuid: stopDriverMap.stop.uuid,
    driverSettlementBillUuid: stopDriverMap.driverSettlementBill?.uuid,
    name: stopDriverMap.name,
    enableDriverSettlement: stopDriverMap.enableDriverSettlement,
    revenuePercentageRate: stopDriverMap.revenuePercentageRate,
    revenueFlatRate: stopDriverMap.revenueFlatRate,
    driverPayoutFinalized: stopDriverMap.driverPayoutFinalized,
    isAttempt: isAttempt ?? false,
    attemptedAt,
  };
};

export const convertIndependentSettlementBillLineItemPayloadToFormData = ({
  settlementBillLineItem,
  duplicateEverything,
}: {
  settlementBillLineItem: SettlementBillLineItemForOrderFragment;
  duplicateEverything: boolean;
}): IndependentSettlementBillLineItemValues | null => {
  if (duplicateEverything) {
    // If we are duplicating an order, we don't copy over any settlement info
    return null;
  }

  const {
    id,
    settlementMode,
    totalAmountUsdDollars,
    driver,
    driverSettlementBill,
    freightCost,
    customCosts,
  } = settlementBillLineItem;

  if (isNil(driver) || settlementMode !== SettlementMode.Independent) {
    // eslint-disable-next-line no-console
    console.warn(
      `Invalid independent settlement bill line item can not be parsed to form data`,
      {
        id,
        driver,
        settlementMode,
      },
    );
    return null;
  }

  const fuelCost = freightCost?.fuelCost ?? null;
  const settlementBillFinalizedDate: string | null | undefined =
    driverSettlementBill?.finalizedDate as string | null | undefined;

  return {
    id,
    settlementMode: SettlementMode.Independent,
    driverUuid: driver.uuid,
    driverSettlementBillUuid: driverSettlementBill?.uuid ?? null,
    driverSettlementBillName: driverSettlementBill?.name ?? null,
    settlementBillFinalizedDate: isNil(settlementBillFinalizedDate)
      ? null
      : new Date(settlementBillFinalizedDate),
    totalCharge: totalAmountUsdDollars ?? 0,
    isLocal: false,
    freightCost: isNil(freightCost)
      ? null
      : {
          uuid: freightCost.id,
          billingMethod:
            freightCost.billingMethod ?? FreightBillingMethod.FlatRate,
          quantity: freightCost.quantity ?? null,
          rate:
            freightCost.rateUsdDollars ??
            freightCost.flatRateUsdDollars ??
            null,
          totalCharge: freightCost.totalAmountUsdDollars ?? 0,
          tariffUuid: freightCost.tariff?.uuid ?? null,
          errorMessage: null,
          description: null,
          authoCode: null,
          fuelCost: {
            uuid: fuelCost?.id ?? v4(),
            billingMethod:
              fuelCost?.billingMethod ?? FuelBillingMethod.AutoCalculate,
            flatRateDollars: fuelCost?.flatRateUsdDollars ?? null,
            surchargeRate: fuelCost?.surchargeRate ?? null,
            totalCharge: fuelCost?.totalAmountUsdDollars ?? 0,
            description: null,
            authoCode: null,
          },
        },
    customCosts: customCosts.map((cc) => {
      const billingMethod = isNil(cc.accessorialTemplate)
        ? CustomChargeBillingMethod.AdHoc
        : CustomChargeBillingMethod.Accessorial;
      if (billingMethod !== cc.billingMethod || isNil(cc.billingMethod)) {
        // eslint-disable-next-line no-console
        console.warn(
          `Custom cost ${cc.id} has stored billing method ${cc.billingMethod ?? 'NONE'} but derived billing method ${billingMethod} with accessorial template uuid ${cc.accessorialTemplate?.uuid ?? 'N/A'}`,
        );
      }

      return {
        uuid: cc.id,
        billingMethod,
        quantity: cc.quantity ?? null,
        rate: cc.rateUsdDollars ?? null,
        fuelSurchargePercentageRate: cc.fuelSurchargeRate ?? null,
        postedFuelSurchargeRate: cc.fuelSurchargeRate ?? null,
        totalCharge: cc.totalAmountUsdDollars ?? 0,
        accessorialUuid: cc.accessorialTemplate?.uuid ?? null,
        accessorialName: cc.accessorialTemplate?.name ?? null,
        accessorialType: cc.accessorialTemplate?.type ?? null,
        zoneUuid:
          cc.accessorialTemplate?.__typename === 'ZoneBasedAccessorialEntity'
            ? (cc.zoneBasedAccessorialMatrixItem?.zoneUuid ?? null)
            : (cc.specialAccessorialMatrixItem?.zoneUuid ?? null),
        chargeGroupUuid:
          cc.accessorialTemplate?.__typename === 'ZoneBasedAccessorialEntity'
            ? (cc.zoneBasedAccessorialMatrixItem?.chargeGroupUuid ?? null)
            : (cc.specialAccessorialMatrixItem?.chargeGroupUuid ?? null),
        specialAccessorialMatrixItemUuid:
          cc.specialAccessorialMatrixItem?.uuid ?? null,
        zoneBasedAccessorialMatrixItemUuid:
          cc.zoneBasedAccessorialMatrixItem?.uuid ?? null,
        accessorialRangeUuid:
          cc.accessorialTemplate?.__typename === 'WeightBasedAccessorialEntity'
            ? (cc.accessorialRange?.uuid ?? null)
            : null,
        isLocal: false,
        name:
          !isEmpty(cc.name) && !isNil(cc.name)
            ? cc.name
            : (cc.accessorialTemplate?.name ?? ''),
        useAccessorialRate: false,
        description: null,
        authoCode: null,
        isAutoApplied: false,
      };
    }),
  };
};

export const convertStopPayloadToFormData = ({
  shipment,
  terminalsEnabled,
  duplicateEverything,
  defaultFuelSurcharge,
  freightBillingMethod,
  fulfillmentType,
}: {
  shipment: StandardShipmentFragment;
  terminalsEnabled: boolean;
  duplicateEverything: boolean;
  defaultFuelSurcharge?: number | null;
  freightBillingMethod: FreightBillingMethod;
  fulfillmentType: FulfillmentType;
}): StopValues => {
  const leg = shipment.legs?.[0];
  const stop = leg?.endStop;
  const address = stop?.address;
  const contactPerson = stop?.contactPerson;
  const shipperContactPerson = stop?.shipperContactPerson;
  const consigneeContactPerson = stop?.consigneeContactPerson;
  const transferAddress = stop?.transferAddress;
  const legUuid = leg?.uuid;
  const stopType = deriveLogicalStopType(
    stop?.stopType,
    leg?.endStop.inboundMethod,
    leg?.endStop.outboundMethod,
  );
  const customCharges = shipment.customCharges.map((customCharge) =>
    convertCustomChargePayloadToFormData(customCharge),
  );
  const freightCharge = isNil(shipment.freightCharge)
    ? getInitialFreightChargeValues({
        defaultFuelSurcharge,
        freightBillingMethod,
        context: {
          shipmentType: ShipmentType.Regular,
          stopType,
        },
        fulfillmentType,
      })
    : convertFreightChargePayloadToFormData(
        shipment.freightCharge,
        shipment.shipmentCharges,
      );

  const allowOptionalAddressStops = [
    StopType.PartnerCarrierDropoff,
    StopType.PartnerCarrierPickup,
    StopType.Transfer,
  ];

  let addressValues: AddressValues | null;
  if (allowOptionalAddressStops.includes(stopType)) {
    addressValues =
      !isNil(address) &&
      !isEmpty(address.name) &&
      !isEmpty(address.line1) &&
      !isEmpty(address.city)
        ? {
            uuid: duplicateEverything ? v4() : address.uuid,
            city: address.city,
            country: address.country,
            line1: address.line1,
            line2: address.line2 ?? null,
            name: address.name,
            state: address.state,
            zip: address.zip,
            longitude: address.longitude ?? null,
            latitude: address.latitude ?? null,
            specialInstructions: address.specialInstructions,
            preventCoordRecompute: address.preventCoordRecompute,
            internalNotes: address.internalNotes,
            iataCode: address.iataCode,
          }
        : null;
  } else {
    addressValues = isNil(address)
      ? null
      : {
          uuid: duplicateEverything ? v4() : address.uuid,
          city: address.city,
          country: address.country,
          line1: address.line1,
          line2: address.line2 ?? null,
          name: address.name,
          state: address.state,
          zip: address.zip,
          longitude: address.longitude ?? null,
          latitude: address.latitude ?? null,
          specialInstructions: address.specialInstructions,
          preventCoordRecompute: address.preventCoordRecompute,
          internalNotes: address.internalNotes,
          iataCode: address.iataCode,
        };
  }

  const contactPersonValues: ContactPersonValues | null = isNil(contactPerson)
    ? null
    : {
        uuid: duplicateEverything ? v4() : contactPerson.uuid,
        email: contactPerson.email ?? null,
        firstName: contactPerson.firstName ?? null,
        lastName: contactPerson.lastName ?? null,
        notes: contactPerson.notes ?? null,
        phone: contactPerson.phone ?? null,
      };
  const shipperContactPersonValues: ContactPersonValues | null = isNil(
    shipperContactPerson,
  )
    ? null
    : {
        uuid: duplicateEverything ? v4() : shipperContactPerson.uuid,
        email: shipperContactPerson.email ?? null,
        firstName: shipperContactPerson.firstName ?? null,
        lastName: shipperContactPerson.lastName ?? null,
        notes: shipperContactPerson.notes ?? null,
        phone: shipperContactPerson.phone ?? null,
      };

  const consigneeContactPersonValues: ContactPersonValues | null = isNil(
    consigneeContactPerson,
  )
    ? null
    : {
        uuid: duplicateEverything ? v4() : consigneeContactPerson.uuid,
        email: consigneeContactPerson.email ?? null,
        firstName: consigneeContactPerson.firstName ?? null,
        lastName: consigneeContactPerson.lastName ?? null,
        notes: consigneeContactPerson.notes ?? null,
        phone: consigneeContactPerson.phone ?? null,
      };

  const driver = duplicateEverything
    ? undefined
    : stop?.routeSlot?.route?.drivers[0];
  const shipperAddress = stop?.shipperAddress;
  const consigneeAddress = stop?.consigneeAddress;

  const appointmentConfirmedByTextOrCall =
    stop?.appointmentTextStatus === AppointmentTextStatus.Confirmed ||
    stop?.appointmentCallStatus === AppointmentCallStatus.Confirmed;

  const appointmentManuallyConfirmed = stop?.appointmentManuallyConfirmed;
  const appointmentConfirmed = isNil(appointmentManuallyConfirmed)
    ? appointmentConfirmedByTextOrCall
    : appointmentManuallyConfirmed;

  const attemptStopDriverMaps: StopDriverMapValues[] =
    leg?.previousStopAttempts
      .flatMap((stopAttempt) => stopAttempt.stopDriverMaps)
      .map((stopDriverMap) =>
        convertStopDriverMapPayloadToFormData({
          stopDriverMap,
          isAttempt: true,
          attemptedAt: stopDriverMap.stop?.completedAt,
        }),
      ) ?? [];
  const stopDriverMaps: StopDriverMapValues[] = [
    ...(stop?.stopDriverMaps ?? []).map((stopDriverMap) =>
      convertStopDriverMapPayloadToFormData({ stopDriverMap }),
    ),
    ...attemptStopDriverMaps,
  ];
  const settlementBillLineItems: IndependentSettlementBillLineItemValues[] =
    filterNotNil(
      shipment.independentSettlementBillLineItems?.map(
        (settlementBillLineItem) =>
          convertIndependentSettlementBillLineItemPayloadToFormData({
            settlementBillLineItem,
            duplicateEverything,
          }),
      ) ?? [],
    );

  const freightChargeSettlement = freightCharge?.deductionTotal;
  const fuelChargeSettlement = freightCharge?.fuelCharge?.deductionTotal;
  const totalSettlement = (
    customCharges?.reduce(
      (prev, curr) => prev.add(curr.deductionTotal ?? 0),
      currency(0),
    ) ?? currency(0)
  )
    .add(freightChargeSettlement ?? 0)
    .add(fuelChargeSettlement ?? 0).value;
  const settlementBillingMethod = isNil(stop?.settlementDeductionFlatRate)
    ? BillingMethod.Percentage
    : BillingMethod.FlatRate;
  const settlementDeductionFlatRate =
    stop?.settlementDeductionFlatRate ?? undefined;
  const settlementDeductionPercentageRate =
    stop?.settlementDeductionPercentageRate ?? undefined;
  const settlementTotal =
    settlementBillingMethod === BillingMethod.FlatRate
      ? settlementDeductionFlatRate
      : safeMultiply(
          safeDivide(settlementDeductionPercentageRate ?? 0, 100, 5),
          totalSettlement ?? 0,
          5,
        );

  const { fields } = shipment;

  return {
    shipmentStatus: duplicateEverything
      ? ShipmentStatus.Created
      : shipment.status,
    shipmentType: shipment.shipmentType,
    stopType,
    customCharges,
    appointmentConfirmed: duplicateEverything
      ? undefined
      : appointmentConfirmed,
    appointmentRequired: duplicateEverything
      ? undefined
      : stop?.appointmentRequired,
    settlementDeductionFlatRate,
    settlementDeductionPercentageRate,
    settlementBillingMethod,
    settlementName: stop?.settlementDeductionName ?? null,
    settlementTotal,
    routeUuid: duplicateEverything ? undefined : stop?.routeSlot?.route?.uuid,
    routeSlotUuid: duplicateEverything ? undefined : stop?.routeSlot?.uuid,
    routeDate: duplicateEverything ? undefined : stop?.routeSlot?.route?.date,
    isSpecial: stop?.isSpecial ?? undefined,
    miles:
      shipment.freightCharge?.billingMethod === FreightBillingMethod.PerMile
        ? shipment.freightCharge?.quantity
        : leg?.miles,
    inboundMethod: stop?.inboundMethod ?? null,
    outboundMethod: stop?.outboundMethod ?? null,
    destinationAirport: stop?.destinationAirport ?? null,
    incomingCarrier: stop?.incomingCarrier ?? null,
    outboundCarrier: stop?.outboundCarrier ?? null,
    standardStopType: stop?.standardStopType ?? null,
    deadlineType: fields?.deadlineType ?? null,
    deadlineDate:
      isNil(fields) || isNil(fields.deadlineDate)
        ? null
        : new Date(fields.deadlineDate),
    deliveryDate:
      isNil(fields) || isNil(fields.deliveryDate)
        ? null
        : new Date(fields.deliveryDate),
    driverUuid: duplicateEverything ? undefined : driver?.uuid,
    appointmentTime:
      isNil(stop) || isNil(stop.appointmentTime)
        ? undefined
        : new Date(stop.appointmentTime),
    endAppointmentTime:
      isNil(stop) || isNil(stop.endAppointmentTime)
        ? undefined
        : new Date(stop.endAppointmentTime),
    deadlineTime: stop?.deadlineTime,
    completedAt: duplicateEverything ? undefined : stop?.completedAt,
    specialInstructions: stop?.specialInstructions ?? null,
    proofOfDeliverySignee: duplicateEverything
      ? undefined
      : (stop?.proofOfDeliverySignee ?? null),
    airportInfoUuid: shipment.airportInfo?.uuid ?? null,
    overridePackageWeight: shipment.overridePackageWeight,
    shouldUseDimWeight: shipment.shouldUseDimWeight,
    contactPerson: contactPersonValues,
    shipperContactPerson: shipperContactPersonValues,
    consigneeContactPerson: consigneeContactPersonValues,
    address: addressValues,
    hideFromBilling: shipment.hideFromBilling,
    hideFromDispatch: stop?.hideFromDispatch,
    status: duplicateEverything
      ? StopStatus.NotArrived
      : (stop?.status ?? StopStatus.NotArrived),
    uuid: duplicateEverything ? v4() : (stop?.uuid ?? v4()),
    legUuid: duplicateEverything ? v4() : (legUuid ?? v4()),
    standardShipmentFieldsUuid: shipment.fields?.uuid ?? null,
    expectedOutboundDate: duplicateEverything
      ? undefined
      : stop?.expectedOutboundDate,
    expectedInboundArrivalDate: duplicateEverything
      ? undefined
      : stop?.expectedInboundArrivalDate,
    destinationInInbound:
      !isEmpty(stop?.address.line1) || !isNil(stop?.terminal?.uuid),
    destinationInOutbound:
      !isEmpty(stop?.address.line1) || !isNil(stop?.terminal?.uuid),
    arrivedAt: stop?.arrivedAt,
    equipmentNames: stop?.routeSlot?.route?.equipments
      .map((equipment) => equipment.name)
      .join(', '),
    shipmentUuid: duplicateEverything ? v4() : shipment.uuid,
    documents: duplicateEverything
      ? []
      : shipment.documents.map((doc) => ({
          ...doc,
          name: doc.name ?? null,
          notes: doc.name ?? null,
          driverFormTemplateUuid: doc.driverFormTemplate?.uuid ?? null,
        })),
    isLocal: false,
    driverType:
      isNil(driver) || duplicateEverything
        ? undefined
        : (driver.driverType ?? undefined),
    driverName:
      isNil(driver) || duplicateEverything
        ? undefined
        : `${driver.firstName} ${driver.lastName}`,
    invoiceName: shipment.invoice?.name,
    invoiceUuid: shipment.invoice?.uuid,
    invoiceDate: shipment.invoice?.date,
    invoicePostedDate: shipment.invoice?.postedAt,
    serviceDate:
      isNil(stop) || isNil(stop.serviceDate)
        ? undefined
        : new Date(stop.serviceDate),
    serviceDateV2: isNil(stop?.serviceDateV2?.iso8601)
      ? undefined
      : Temporal.PlainDate.from(stop.serviceDateV2.iso8601),
    freightCharge,
    terminalUuid: stop?.terminal?.uuid ?? null,
    terminalsEnabled,
    paperworkMarkedComplete: duplicateEverything
      ? undefined
      : stop?.paperworkMarkedComplete,
    consigneeAddress: isNil(consigneeAddress)
      ? null
      : {
          uuid: duplicateEverything ? v4() : consigneeAddress.uuid,
          city: consigneeAddress.city,
          country: consigneeAddress.country,
          line1: consigneeAddress.line1,
          line2: consigneeAddress.line2 ?? null,
          name: consigneeAddress.name,
          state: consigneeAddress.state,
          zip: consigneeAddress.zip,
          longitude: consigneeAddress.longitude ?? null,
          latitude: consigneeAddress.latitude ?? null,
          specialInstructions: consigneeAddress.specialInstructions,
        },
    transferAddress: isNil(transferAddress)
      ? null
      : {
          uuid: duplicateEverything ? v4() : transferAddress.uuid,
          city: transferAddress.city,
          country: transferAddress.country,
          line1: transferAddress.line1,
          line2: transferAddress.line2 ?? null,
          name: transferAddress.name,
          state: transferAddress.state,
          zip: transferAddress.zip,
          longitude: transferAddress.longitude ?? null,
          latitude: transferAddress.latitude ?? null,
          specialInstructions: transferAddress.specialInstructions,
        },
    shipperAddress: isNil(shipperAddress)
      ? null
      : {
          uuid: duplicateEverything ? v4() : shipperAddress.uuid,
          city: shipperAddress.city,
          country: shipperAddress.country,
          line1: shipperAddress.line1,
          line2: shipperAddress.line2 ?? null,
          name: shipperAddress.name,
          state: shipperAddress.state,
          zip: shipperAddress.zip,
          longitude: shipperAddress.longitude ?? null,
          latitude: shipperAddress.latitude ?? null,
          specialInstructions: shipperAddress.specialInstructions,
        },
    stopDriverMaps,
    settlementBillLineItems,
    agentId: driver?.agent?.uuid,
    agentName: driver?.agent?.name,
    sentToAgentAt: stop?.sentToAgentAt,
    confirmedByAgentAt: stop?.confirmedByAgentAt,
    orderConsolidatedOnUuid: shipment.inputForConsolidatedOrder?.uuid,
    orderConsolidatedOnName: shipment.inputForConsolidatedOrder?.name,
  };
};

const convertOutstandingStopPayloadToFormData = ({
  shipment,
  terminalsEnabled,
  defaultFuelSurcharge,
  freightBillingMethod,
  fulfillmentType,
}: {
  shipment: OutstandingStandardShipmentFragment;
  terminalsEnabled: boolean;
  defaultFuelSurcharge?: number | null;
  freightBillingMethod: FreightBillingMethod;
  fulfillmentType: FulfillmentType;
}): StopValues => {
  const leg = shipment.legs[0];
  const stop = leg?.endStop;
  const address = stop?.address;
  const legUuid = leg?.uuid;
  const customCharges = shipment.customCharges.map((customCharge) =>
    convertCustomChargePayloadToFormData(customCharge),
  );
  const stopType = deriveLogicalStopType(
    stop?.stopType,
    shipment.legs[0]?.endStop.inboundMethod,
    shipment.legs[0]?.endStop.outboundMethod,
  );
  const freightCharge = isNil(shipment.freightCharge)
    ? getInitialFreightChargeValues({
        defaultFuelSurcharge,
        freightBillingMethod,
        context: {
          shipmentType: ShipmentType.Regular,
          stopType,
        },
        fulfillmentType,
      })
    : convertFreightChargePayloadToFormData(
        shipment.freightCharge,
        shipment.shipmentCharges,
      );

  const addressValues: AddressValues | null = isNil(address)
    ? null
    : {
        uuid: address.uuid,
        city: address.city,
        country: address.country,
        line1: address.line1,
        line2: address.line2 ?? null,
        name: address.name,
        state: address.state,
        zip: address.zip,
        longitude: address.longitude ?? null,
        latitude: address.latitude ?? null,
        internalNotes: address.internalNotes,
        iataCode: address.iataCode,
      };

  const driver = stop?.routeSlot?.route?.drivers[0];

  const attemptStopDriverMaps: StopDriverMapValues[] =
    leg?.previousStopAttempts
      .flatMap((stopAttempt) => stopAttempt.stopDriverMaps)
      .map((stopDriverMap) =>
        convertStopDriverMapPayloadToFormData({
          stopDriverMap,
          isAttempt: true,
          attemptedAt: stopDriverMap.stop?.completedAt,
        }),
      ) ?? [];
  const stopDriverMaps: StopDriverMapValues[] = [
    ...(stop?.stopDriverMaps ?? []).map((stopDriverMap) =>
      convertStopDriverMapPayloadToFormData({ stopDriverMap }),
    ),
    ...attemptStopDriverMaps,
  ];
  const settlementBillLineItems: IndependentSettlementBillLineItemValues[] =
    filterNotNil(
      shipment.independentSettlementBillLineItems?.map(
        (settlementBillLineItem) =>
          convertIndependentSettlementBillLineItemPayloadToFormData({
            settlementBillLineItem,
            duplicateEverything: false,
          }),
      ) ?? [],
    );

  const { fields } = shipment;

  return {
    shipmentStatus: shipment.status,
    shipmentType: shipment.shipmentType,
    stopType,
    customCharges,
    routeUuid: stop?.routeSlot?.route?.uuid,
    routeSlotUuid: stop?.routeSlot?.uuid,
    routeDate: stop?.routeSlot?.route?.date,
    isSpecial: stop?.isSpecial ?? undefined,
    miles:
      shipment.freightCharge?.billingMethod === FreightBillingMethod.PerMile
        ? shipment.freightCharge?.quantity
        : leg?.miles,
    inboundMethod: stop?.inboundMethod ?? null,
    outboundMethod: stop?.outboundMethod ?? null,
    standardStopType: stop?.standardStopType ?? null,
    deadlineType: fields?.deadlineType ?? null,
    deadlineDate:
      isNil(fields) || isNil(fields.deadlineDate)
        ? null
        : new Date(fields.deadlineDate),
    deliveryDate:
      isNil(fields) || isNil(fields.deliveryDate)
        ? null
        : new Date(fields.deliveryDate),
    appointmentTime:
      isNil(stop) || isNil(stop.appointmentTime)
        ? undefined
        : new Date(stop.appointmentTime),
    endAppointmentTime:
      isNil(stop) || isNil(stop.endAppointmentTime)
        ? undefined
        : new Date(stop.endAppointmentTime),
    driverUuid: driver?.uuid,
    driverType: driver?.driverType ?? undefined,
    deadlineTime: stop?.deadlineTime,
    completedAt: stop?.completedAt,
    specialInstructions: stop?.specialInstructions ?? null,
    proofOfDeliverySignee: stop?.proofOfDeliverySignee ?? null,
    overridePackageWeight: shipment.overridePackageWeight,
    shouldUseDimWeight: shipment.shouldUseDimWeight,
    address: addressValues,
    hideFromBilling: shipment.hideFromBilling,
    status: stop?.status ?? StopStatus.NotArrived,
    uuid: stop?.uuid ?? v4(),
    legUuid: legUuid ?? v4(),
    standardShipmentFieldsUuid: shipment.fields?.uuid ?? null,
    destinationInOutbound: true,
    arrivedAt: stop?.arrivedAt,
    equipmentNames: stop?.routeSlot?.route?.equipments
      .map((equipment) => equipment.name)
      .join(', '),
    shipmentUuid: shipment.uuid,
    documents: shipment.documents.map((doc) => ({
      ...doc,
      name: doc.name ?? null,
      notes: doc.name ?? null,
    })),
    isLocal: false,
    driverName: isNil(driver)
      ? undefined
      : `${driver.firstName} ${driver.lastName}`,
    serviceDate:
      isNil(stop) || isNil(stop.serviceDate)
        ? undefined
        : new Date(stop.serviceDate),
    serviceDateV2: isNil(stop?.serviceDateV2?.iso8601)
      ? undefined
      : Temporal.PlainDate.from(stop.serviceDateV2.iso8601),
    freightCharge,
    paperworkMarkedComplete: stop?.paperworkMarkedComplete,
    terminalUuid: stop?.terminal?.uuid ?? null,
    terminalsEnabled,
    settlementDeductionFlatRate: stop?.settlementDeductionFlatRate ?? undefined,
    settlementDeductionPercentageRate:
      stop?.settlementDeductionPercentageRate ?? undefined,
    settlementBillingMethod: isNil(stop?.settlementDeductionFlatRate)
      ? BillingMethod.Percentage
      : BillingMethod.FlatRate,
    settlementName: stop?.settlementDeductionName ?? null,
    contactPerson: null,
    shipperAddress: null,
    consigneeAddress: null,
    shipperContactPerson: null,
    consigneeContactPerson: null,
    transferAddress: null,
    stopDriverMaps,
    settlementBillLineItems,
    agentId: driver?.agent?.uuid,
    agentName: driver?.agent?.name,
    sentToAgentAt: stop?.sentToAgentAt,
    confirmedByAgentAt: stop?.confirmedByAgentAt,
    orderConsolidatedOnUuid: shipment.inputForConsolidatedOrder?.uuid,
    orderConsolidatedOnName: shipment.inputForConsolidatedOrder?.name,
  };
};

// This gets the type of the second stop.
export const getDummyStopTypeFromStop = (
  stopType: StopType,
  inboundMethod?: InboundMethod | null,
  outboundMethod?: OutboundMethod | null,
) => {
  switch (stopType) {
    case StopType.Delivery: {
      if (inboundMethod === InboundMethod.InboundDelivery) {
        return StopType.PartnerCarrierDropoff;
      }
      return StopType.None;
    }
    case StopType.Pickup: {
      if (outboundMethod === OutboundMethod.LocalDelivery) {
        return StopType.PartnerCarrierPickup;
      }
      if (outboundMethod === OutboundMethod.AirportTransfer) {
        return StopType.Transfer;
      }
      return StopType.None;
    }
    case StopType.Transfer:
    case StopType.PartnerCarrierPickup:
    case StopType.PartnerCarrierDropoff:
    case StopType.Recovery:
    case StopType.None: {
      return StopType.None;
    }
    default: {
      return exhaustive(stopType);
    }
  }
};

export const getInitialContactPersonValues = (): ContactPersonValues => {
  return {
    uuid: v4(),
    email: null,
    firstName: null,
    lastName: null,
    notes: null,
    phone: null,
  };
};

const getOrderName = async ({ contactUuid }: { contactUuid: string }) => {
  const res = await apolloClient.query<OrderNameQuery, OrderNameQueryVariables>(
    {
      query: OrderNameDocument,
      variables: {
        billingPartyContactUuid: contactUuid,
      },
    },
  );
  return res.data.orderName;
};

const getDummyStop = ({
  stop,
  stopType,
  fulfillmentType,
}: {
  stop?: StopValues;
  stopType: StopType;
  fulfillmentType: FulfillmentType;
}): StopValues => {
  return {
    stopType,
    inboundMethod: null,
    outboundMethod: null,
    destinationAirport: stop?.destinationAirport,
    incomingCarrier: stop?.incomingCarrier,
    outboundCarrier: stop?.outboundCarrier,
    expectedInboundArrivalDate: stop?.expectedInboundArrivalDate,
    expectedOutboundDate: stop?.expectedOutboundDate,
    standardStopType: StandardStopType.Commercial,
    deadlineType: DeadlineType.DueOn,
    deadlineDate: null,
    deliveryDate: null,
    appointmentTime: null,
    endAppointmentTime: null,
    completedAt: null,
    specialInstructions: null,
    proofOfDeliverySignee: null,
    airportInfoUuid: null,
    overridePackageWeight: false,
    shipmentStatus: ShipmentStatus.Created,
    shouldUseDimWeight: false,
    contactPerson: getInitialContactPersonValues(),
    freightCharge: getInitialFreightChargeValues({
      defaultFuelSurcharge: 0,
      freightBillingMethod: FreightBillingMethod.FlatRate,
      context: { shipmentType: ShipmentType.Regular, stopType },
      fulfillmentType,
    }),
    customCharges: null,
    address: null,
    hideFromBilling: false,
    status: StopStatus.NotArrived,
    uuid: v4(),
    legUuid: v4(),
    shipmentUuid: v4(),
    documents: [],
    isLocal: true,
    shipmentType: ShipmentType.Regular,
    shipperAddress: null,
    consigneeAddress: null,
    shipperContactPerson: null,
    consigneeContactPerson: null,
    transferAddress: null,
    standardShipmentFieldsUuid: null,
    terminalsEnabled: null,
    terminalUuid: null,
  };
};

// If there is only one stop, we need to render a second stop that represents an empty transfer, partner pickup, partner dropoff, or none spot.
export const getNewStopsArrFromSingleStop = (
  stop: StopValues,
  fulfillmentType: FulfillmentType,
): [StopValues, StopValues] => {
  const dummyStopType = getDummyStopTypeFromStop(
    stop.stopType,
    stop.inboundMethod,
    stop.outboundMethod,
  );

  switch (stop.stopType) {
    case StopType.Delivery:
    case StopType.Transfer:
    case StopType.PartnerCarrierPickup: {
      const dummyStop = getDummyStop({
        stop,
        stopType: dummyStopType,
        fulfillmentType,
      });
      return [dummyStop, stop];
    }
    case StopType.PartnerCarrierDropoff:
    case StopType.Pickup:
    case StopType.Recovery:
    case StopType.None: {
      const dummyStop = getDummyStop({
        stop,
        stopType: dummyStopType,
        fulfillmentType,
      });
      return [stop, dummyStop];
    }
    default: {
      return exhaustive(stop.stopType);
    }
  }
};

export const getInitialPackageValues = (defaults: {
  packageSpecId: string | null;
  type: PackageType;
}): PackageValues => {
  return {
    uuid: v4(),
    description: '',
    width: null,
    height: null,
    length: null,
    weight: null,
    quantity: 1,
    warehouseLocationName: null,
    warehouseLocationUuid: null,
    ...defaults,
  };
};

export const getInitialStopValues = ({
  defaultFuelSurcharge,
  freightBillingMethod,
  inboundMethod,
  outboundMethod,
  stopType,
  terminalsEnabled,
  fulfillmentType,
}: {
  defaultFuelSurcharge?: number | null;
  freightBillingMethod: FreightBillingMethod;
  inboundMethod?: InboundMethod;
  outboundMethod?: OutboundMethod;
  stopType: StopType;
  terminalsEnabled: boolean;
  fulfillmentType: FulfillmentType;
}): StopValues => {
  const serviceDate = getNoonOfDay(new Date());
  const serviceDateV2 = Temporal.Now.plainDateISO();

  return {
    stopType,
    inboundMethod,
    outboundMethod,
    destinationAirport: null,
    incomingCarrier: null,
    standardStopType: StandardStopType.Commercial,
    deadlineType: DeadlineType.DueOn,
    deadlineDate: null,
    deliveryDate: null,
    appointmentTime: null,
    endAppointmentTime: null,
    completedAt: null,
    specialInstructions: null,
    proofOfDeliverySignee: null,
    airportInfoUuid: null,
    overridePackageWeight: false,
    shouldUseDimWeight: false,
    contactPerson: getInitialContactPersonValues(),
    address: null,
    hideFromBilling: false,
    status: StopStatus.NotArrived,
    uuid: v4(),
    legUuid: v4(),
    shipmentStatus: ShipmentStatus.Created,
    shipmentUuid: v4(),
    documents: [],
    freightCharge: getInitialFreightChargeValues({
      defaultFuelSurcharge,
      freightBillingMethod,
      context: { shipmentType: ShipmentType.Regular, stopType },
      fulfillmentType,
    }),
    customCharges: null,
    /**
     * Ensure that inbound stop service date always defaults to today's date when the order is being created
     */
    serviceDate: isInboundStop(stopType) ? serviceDate : null,
    serviceDateV2: isInboundStop(stopType) ? serviceDateV2 : null,
    expectedInboundArrivalDate:
      stopType === StopType.PartnerCarrierDropoff ? serviceDate : null,
    isLocal: true,
    terminalUuid: null,
    terminalsEnabled,
    shipmentType: ShipmentType.Regular,
    shipperAddress: null,
    consigneeAddress: null,
    shipperContactPerson: null,
    consigneeContactPerson: null,
    transferAddress: null,
    standardShipmentFieldsUuid: null,
    agentId: null,
    agentName: null,
    sentToAgentAt: null,
    confirmedByAgentAt: null,
    orderConsolidatedOnUuid: null,
    orderConsolidatedOnName: null,
  };
};

export const getInitialLineHaulShipmentValues = ({
  defaultFuelSurcharge,
}: {
  defaultFuelSurcharge?: number | null;
}): LineHaulShipmentValues => {
  return {
    uuid: v4(),
    freightCharge: {
      uuid: v4(),
      billingMethod: FreightBillingMethod.Tariff,
      quantity: 0,
      rate: 0,
      totalCharge: 0,
      fuelCharge: getInitialFuelChargeValues({
        defaultFuelBillingMethod: FuelBillingMethod.AutoCalculate,
        defaultFuelSurcharge,
      }),
      deductionTotal: 0,
      description: null,
      authoCode: null,
      settlementPercentageRate: null,
      settlementFlatRate: null,
      settlementBillingMethod: null,
      tariffUuid: null,
      errorMessage: null,
      discountRate: null,
    },
    isLocal: true,
    dateForFuelCharge: null,
  };
};

export const getInitialOrderChargesShipmentValues = ({
  defaultFuelSurcharge,
  freightBillingMethod,
  fulfillmentType,
}: {
  defaultFuelSurcharge?: number | null;
  freightBillingMethod: FreightBillingMethod;
  fulfillmentType: FulfillmentType;
}): OrderChargesShipmentValues => {
  return {
    uuid: v4(),
    freightCharge: getInitialFreightChargeValues({
      defaultFuelSurcharge,
      freightBillingMethod,
      context: { shipmentType: ShipmentType.OrderCharges },
      fulfillmentType,
    }),
    customCharges: [],
    shipmentStatus: ShipmentStatus.Created,
    isLocal: true,
  };
};

export const initializeOrderToFormData = async ({
  contactUuid,
  companyConfiguration,
  contact,
  terminalsEnabled,
  recurringTemplate,
  defaultPackageSpec,
  fulfillmentType,
  ffNoRecoveryTransfer = false,
}: {
  contactUuid?: string;
  companyConfiguration: CompanyConfigurationFragment;
  contact: ShallowContactFragment | undefined;
  terminalsEnabled: boolean;
  recurringTemplate: boolean;
  defaultPackageSpec: DefaultPackageSpec | undefined;
  fulfillmentType: FulfillmentType;
  ffNoRecoveryTransfer?: boolean;
}): Promise<OrderFormValues> => {
  let name = '';
  if (!isNil(contactUuid)) {
    name = await getOrderName({
      contactUuid,
    });
  }
  const defaultDimFactor =
    contact?.__typename === 'CustomerContactEntity' &&
    !isNil(contact?.defaultDimFactor)
      ? contact?.defaultDimFactor
      : companyConfiguration?.defaultDimFactor;
  const defaultFuelSurcharge =
    (contact?.__typename === 'CustomerContactEntity' &&
    !isNil(contact?.defaultFuelSurcharge)
      ? contact?.defaultFuelSurcharge
      : companyConfiguration?.defaultFuelSurcharge) ?? 0;
  const defaultFuelBillingMethod =
    companyConfiguration?.defaultFuelSurchargeBillingMethod;
  const defaultFreightBillingMethod =
    contact?.__typename === 'CustomerContactEntity' &&
    contact.defaultUseTariff === true
      ? FreightBillingMethod.Tariff
      : FreightBillingMethod.FlatRate;
  const inboundStopType = ffNoRecoveryTransfer
    ? StopType.Recovery
    : StopType.Pickup;
  const stopValues: StopValues[] = [
    getInitialStopValues({
      defaultFuelSurcharge,
      freightBillingMethod:
        inboundStopType === StopType.Recovery
          ? FreightBillingMethod.FlatRate
          : defaultFreightBillingMethod,
      stopType: inboundStopType,
      terminalsEnabled,
      fulfillmentType,
    }),
    getInitialStopValues({
      defaultFuelSurcharge,
      freightBillingMethod: defaultFreightBillingMethod,
      inboundMethod: ffNoRecoveryTransfer
        ? InboundMethod.Recovery
        : InboundMethod.Other,
      stopType: StopType.Delivery,
      terminalsEnabled,
      fulfillmentType,
    }),
  ];
  const packageValues: PackageValues[] = isNil(defaultPackageSpec)
    ? []
    : [getInitialPackageValues(defaultPackageSpec)];

  const lineHaulShipmentValues: LineHaulShipmentValues =
    getInitialLineHaulShipmentValues({
      defaultFuelSurcharge,
    });

  const orderChargesShipmentValues: OrderChargesShipmentValues =
    getInitialOrderChargesShipmentValues({
      defaultFuelSurcharge,
      freightBillingMethod: defaultFreightBillingMethod,
      fulfillmentType,
    });

  const recurringOrderFrequency: RecurringOrderFrequencyValues = {
    uuid: v4(),
    startDate: new Date(),
    endDate: null,
    repeatInterval: 1,
    repeatFrequencyUnit: RepeatFrequencyUnit.Week,
    daysOfWeek: [DayOfWeekAll.Monday],
    daysOfMonth: [],
  };

  const orderValues: OrderFormValues = {
    orderSegmentType: OrderSegmentType.Cartage,
    contactUuid: contactUuid ?? '',
    deletedAutoAppliedAccessorials: [],
    status: OrderStatus.Created,
    detailedStatus: OrderDetailedStatus.Creating,
    name,
    shipperBillOfLadingNumber: '',
    masterAirwayBillOfLadingNumber: null,
    secondaryReferenceNumber: '',
    tertiaryReferenceNumber: null,
    units: Units.IMPERIAL,
    onHand: false,
    receivedDate: null,
    receivedAtOriginDate: null,
    paymentMethod: null,
    holdReasonUuid: null,
    holdReasonName: null,
    warehouseUuid: null,
    thirdPartyBrokerUuid: null,
    tags: [],
    documents: [],
    notes: null,
    stops: stopValues,
    packages: packageValues,
    pickedDate: null,
    pieceCount: null,
    piecesPicked: null,
    personName: null,
    personPhoneNumber: null,
    personEmail: null,
    dimFactor: defaultDimFactor,
    recurringOrderFrequency: recurringTemplate ? recurringOrderFrequency : null,
    refNumbers: [''],
    uuid: v4(),
    fieldsUuid: v4(),
    fulfillmentType: null,
    orderChargesShipment: orderChargesShipmentValues,
    lineHaulLaneUuid: null,
    lineHaulShipment: lineHaulShipmentValues,
    enableLineHaul: false,
    isUsingLineHaul: false,
    defaultFuelBillingMethod,
    defaultFuelSurcharge,
    defaultFreightBillingMethod,
    lockShipperBillOfLadingNumber: false,
    lockSecondaryReferenceNumber: false,
  };

  return orderValues;
};

export const convertStandardOrderPayloadToFormData = async ({
  standardOrder,
  terminalsEnabled,
  defaultPackageSpec,
  duplicate,
  contactUuid,
  companyConfiguration,
  contact,
  refetchOrderAfterSave,
  ffNoRecoveryTransfer,
  useCentimeters,
  useKilograms,
}: {
  standardOrder: StandardOrderFragmentFragment;
  terminalsEnabled: boolean;
  defaultPackageSpec?: DefaultPackageSpec | undefined;
  duplicate?: string | null | undefined;
  contactUuid?: string;
  companyConfiguration?: CompanyConfigurationFragment | null;
  contact?: ShallowContactFragment | undefined;
  refetchOrderAfterSave?: boolean;
  ffNoRecoveryTransfer?: boolean;
  useCentimeters: boolean | null | undefined;
  useKilograms: boolean | null | undefined;
}): Promise<OrderFormValues> => {
  const fulfillmentType =
    standardOrder.fulfillmentType ?? FulfillmentType.MultiTrip;
  if (duplicate === DUPLICATE_NOTHING && !isNil(companyConfiguration)) {
    return initializeOrderToFormData({
      contactUuid,
      companyConfiguration,
      contact,
      terminalsEnabled,
      defaultPackageSpec,
      recurringTemplate: false,
      fulfillmentType,
      ffNoRecoveryTransfer,
    });
  }

  const duplicateEverything = duplicate === DUPLICATE_EVERYTHING;

  const defaultFuelSurcharge =
    (contact?.__typename === 'CustomerContactEntity' &&
    !isNil(contact?.defaultFuelSurcharge)
      ? contact?.defaultFuelSurcharge
      : companyConfiguration?.defaultFuelSurcharge) ?? 0;
  const defaultFreightBillingMethod =
    contact?.__typename === 'CustomerContactEntity' &&
    contact.defaultUseTariff === true
      ? FreightBillingMethod.Tariff
      : FreightBillingMethod.FlatRate;
  const defaultFuelBillingMethod =
    companyConfiguration?.defaultFuelSurchargeBillingMethod;

  const serviceUuid =
    standardOrder.service?.uuid ??
    standardOrder.shipments.find(
      (shipment) => !isNil(shipment.fields?.service?.uuid),
    )?.fields?.service?.uuid;
  const stopValues = standardOrder.shipments
    .filter((shipment) => shipment.shipmentType === ShipmentType.Regular)
    .map((shipment) => {
      return convertStopPayloadToFormData({
        shipment,
        duplicateEverything,
        terminalsEnabled,
        defaultFuelSurcharge,
        freightBillingMethod: defaultFreightBillingMethod,
        fulfillmentType,
      });
    });

  const lineHaulShipment = standardOrder.shipments.find(
    (s) => s.shipmentType === ShipmentType.LineHaul,
  );

  const lineHaulShipmentValues = isNil(lineHaulShipment)
    ? getInitialLineHaulShipmentValues({
        defaultFuelSurcharge:
          stopValues[0]?.freightCharge?.fuelCharge.surchargeRate,
      })
    : convertLineHaulShipmentPayloadToFormData({
        shipment: lineHaulShipment,
        lineHaulCompletedDate: standardOrder.lineHaulCompletedDate,
      });

  const orderChargesShipment = standardOrder.shipments.find(
    (s) => s.shipmentType === ShipmentType.OrderCharges,
  );

  let stopsArr = stopValues;
  const singleStop = stopsArr[0];
  // if no stops but there is a line haul
  if (isEmpty(stopValues)) {
    stopsArr = [
      getDummyStop({
        stopType: StopType.None,
        fulfillmentType,
      }),
      getDummyStop({
        stopType: StopType.None,
        fulfillmentType,
      }),
    ];
  }
  if (stopValues.length === 1 && !isNil(singleStop)) {
    stopsArr = getNewStopsArrFromSingleStop(singleStop, fulfillmentType);
  }
  const packageValues = standardOrder.packages.map((pkg) =>
    convertPackagePayloadToFormData({
      package: pkg,
      useCentimeters,
      useKilograms,
    }),
  );

  let name = '';
  name =
    duplicateEverything && !isNil(contactUuid)
      ? await getOrderName({ contactUuid })
      : standardOrder.name;

  const { recurringOrderFrequency } = standardOrder;
  if (!isNil(recurringOrderFrequency)) {
    delete recurringOrderFrequency.__typename;
  }

  let orderChargesShipmentValues = isNil(orderChargesShipment)
    ? null
    : convertOrderChargesShipmentPayloadToFormData(orderChargesShipment);

  /*
   * Since we now allow order charges when either stop is a partner carrier,
   * we need to create a new order charges shipment in cases when a user goes to update an already
   * existing order and there wasn't an order charges shipment created in the first place.
   */
  const inboundStopType = stopsArr[INBOUND_STOP_IDX]?.stopType;
  const outboundStopType = stopsArr[OUTBOUND_STOP_IDX]?.stopType;
  if (
    (inboundStopType === StopType.PartnerCarrierDropoff ||
      outboundStopType === StopType.PartnerCarrierPickup) &&
    isNil(orderChargesShipmentValues)
  ) {
    orderChargesShipmentValues = getInitialOrderChargesShipmentValues({
      defaultFuelSurcharge,
      freightBillingMethod: defaultFreightBillingMethod,
      fulfillmentType,
    });
  }

  const orderValues: OrderFormValues = {
    updatedAt: duplicateEverything ? null : standardOrder.updatedAt,
    useKilograms,
    useCentimeters,
    deletedAutoAppliedAccessorials:
      standardOrder.deletedAutoAppliedAccessorials.map((a) => a.uuid),
    orderSegmentType: standardOrder.orderSegmentType,
    fulfillmentType: standardOrder.fulfillmentType,
    contactUuid: standardOrder.billingPartyContact.uuid,
    contactStationId: standardOrder.billingPartyContactStation?.id,
    serviceUuid,
    codCheckAmountInDollars: isNil(standardOrder.codCheckAmountCents)
      ? undefined
      : safeDivide(standardOrder.codCheckAmountCents, 100),
    codCheckNumber: standardOrder.codCheckNumber,
    isCollectOnDelivery: standardOrder.isCollectOnDelivery,
    name,
    scannedOrderResultUuid: standardOrder.scannedOrderResult?.uuid,
    shipperBillOfLadingNumber:
      duplicateEverything && refetchOrderAfterSave !== true
        ? ''
        : (standardOrder.standardOrderFields.shipperBillOfLadingNumber ?? ''),
    lockShipperBillOfLadingNumber: duplicateEverything
      ? false
      : standardOrder.standardOrderFields.lockShipperBillOfLadingNumber,
    masterAirwayBillOfLadingNumber:
      standardOrder.standardOrderFields.masterAirwayBillOfLadingNumber ?? '',
    secondaryReferenceNumber: standardOrder.secondaryRefNumber ?? null,
    lockSecondaryReferenceNumber: duplicateEverything
      ? false
      : standardOrder.lockSecondaryRefNumber,
    tertiaryReferenceNumber: standardOrder.tertiaryRefNumber ?? null,
    quaternaryReferenceNumber: standardOrder.quaternaryRefNumber ?? null,
    quinaryReferenceNumber: standardOrder.quinaryRefNumber ?? null,
    dimFactor: standardOrder.standardOrderFields.dimFactor,
    units:
      standardOrder.standardOrderFields.measurementUnits ===
      MeasurementUnits.Inches
        ? Units.IMPERIAL
        : Units.METRIC,
    onHand: duplicateEverything ? null : (standardOrder.onHand ?? null),
    receivedDate: duplicateEverything ? null : standardOrder.receivedDate,
    receivedAtOriginDate: duplicateEverything
      ? null
      : standardOrder.dateMarkedReceivedAtOrigin,
    pickedDate: duplicateEverything ? null : standardOrder.pickedDate,
    pieceCount: duplicateEverything ? null : (standardOrder.pieceCount ?? null),
    piecesPicked: duplicateEverything
      ? null
      : (standardOrder.piecesPicked ?? null),
    loadedDate: standardOrder.dateMarkedLoaded,
    personName: standardOrder.personName,
    personPhoneNumber: standardOrder.personPhoneNumber,
    personEmail: standardOrder.personEmail,
    paymentMethod: null,
    refusedBy: duplicateEverything ? null : (standardOrder.refusedBy ?? null),
    refusedDate: duplicateEverything
      ? null
      : (standardOrder.refusedDate ?? null),
    holdReasonUuid: duplicateEverything
      ? null
      : (standardOrder.holdReason?.uuid ?? null),
    holdReasonName: duplicateEverything
      ? null
      : (standardOrder.holdReason?.name ?? null),
    warehouseUuid: standardOrder.warehouse?.uuid ?? null,
    thirdPartyBrokerUuid: standardOrder.thirdPartyBrokers[0]?.uuid ?? null,
    documents: duplicateEverything
      ? []
      : standardOrder.documents.map((doc) => ({
          ...doc,
          name: doc.name ?? null,
          notes: doc.name ?? null,
          driverFormTemplateUuid: doc.driverFormTemplate?.uuid ?? null,
        })),
    tags: standardOrder.tags.map((tag) => ({
      uuid: tag.uuid,
      value: tag.value,
      color: tag.color,
    })),
    notes: standardOrder.notes,
    stops: stopsArr,
    packages: packageValues,
    uuid: duplicateEverything ? v4() : standardOrder.uuid,
    fieldsUuid: standardOrder.standardOrderFields.uuid,
    refNumbers: standardOrder.refNumbers,
    vehicleTypeUuid: standardOrder.vehicleType?.uuid,
    orderComments: duplicateEverything ? null : standardOrder.orderComments,
    detailedStatus: duplicateEverything
      ? OrderDetailedStatus.Creating
      : standardOrder.detailedStatusV2,
    status: duplicateEverything ? OrderStatus.Created : standardOrder.status,
    isCrossDock: standardOrder.isCrossDock,
    orderChargesShipment: orderChargesShipmentValues,
    lineHaulLaneUuid: standardOrder.lineHaulLane?.uuid,
    lineHaulShipment: lineHaulShipmentValues,
    recurringOrderFrequency:
      isNil(recurringOrderFrequency) || duplicateEverything
        ? null
        : recurringOrderFrequency,
    enableLineHaul: standardOrder.enableLineHaul,
    isUsingLineHaul: !isNil(standardOrder.lineHaulLane?.uuid),
    chargeableWeightCubicMeters: isNil(
      standardOrder.chargeableWeightCubicMeters,
    )
      ? null
      : standardOrder.chargeableWeightCubicMeters.value(),
    totalSkids: standardOrder.totalSkids,
    isReweighed: duplicateEverything ? null : standardOrder.isReweighed,
    driverQualificationIds: standardOrder.driverQualifications.map(
      (dq) => dq.id,
    ),
    osd: duplicateEverything ? null : standardOrder.osd,
    inBond: standardOrder.inBond,
    hazmat: standardOrder.hazmat,
    itTeNumber: standardOrder.itTeNumber,
    externalNotes: standardOrder.externalNotes,
    unNumber: standardOrder.unNumber,
    hazmatDescription: standardOrder.hazmatDescription,
    emergencyResponseNumber: standardOrder.emergencyResponseNumber,
    requiresPlacard: standardOrder.requiresPlacard,
    hazmatClass: standardOrder.hazmatClass,
    packageGroup: standardOrder.packageGroup,
    previousTotalAmountCents: standardOrder.totalAmountCents,
    defaultFreightBillingMethod,
    defaultFuelSurcharge,
    defaultFuelBillingMethod,
  };

  return orderValues;
};

export const convertOutstandingOrderPayloadToFormData = ({
  standardOrder,
  terminalsEnabled,
  useCentimeters,
  useKilograms,
}: {
  standardOrder: OutstandingOrderFragmentFragment;
  terminalsEnabled: boolean;
  useCentimeters: boolean | null | undefined;
  useKilograms: boolean | null | undefined;
}): OrderFormValues => {
  const fulfillmentType =
    standardOrder.fulfillmentType ?? FulfillmentType.MultiTrip;
  const serviceUuid =
    standardOrder.service?.uuid ??
    standardOrder.shipments.find(
      (shipment) => !isNil(shipment.fields?.service?.uuid),
    )?.fields?.service?.uuid;
  const stopValues = standardOrder.shipments
    .filter((shipment) => shipment.shipmentType === ShipmentType.Regular)
    .map((shipment) => {
      return convertOutstandingStopPayloadToFormData({
        shipment,
        defaultFuelSurcharge: 0,
        freightBillingMethod: FreightBillingMethod.FlatRate,
        terminalsEnabled,
        fulfillmentType,
      });
    });

  const lineHaulShipment = standardOrder.shipments.find(
    (s) => s.shipmentType === ShipmentType.LineHaul,
  );

  const lineHaulShipmentValues = isNil(lineHaulShipment)
    ? getInitialLineHaulShipmentValues({
        defaultFuelSurcharge:
          stopValues[0]?.freightCharge?.fuelCharge.surchargeRate,
      })
    : convertLineHaulShipmentPayloadToFormData({
        shipment: lineHaulShipment,
        lineHaulCompletedDate: standardOrder.lineHaulCompletedDate,
      });

  let stopsArr = stopValues;
  const singleStop = stopsArr[0];
  // if no stops but there is a line haul
  if (stopValues.length === 1 && !isNil(singleStop)) {
    stopsArr = getNewStopsArrFromSingleStop(singleStop, fulfillmentType);
  }

  const defaultFreightBillingMethod = FreightBillingMethod.FlatRate;

  const orderChargesShipment = standardOrder.shipments.find(
    (s) => s.shipmentType === ShipmentType.OrderCharges,
  );
  let orderChargesShipmentValues = isNil(orderChargesShipment)
    ? null
    : convertOrderChargesShipmentPayloadToFormData(orderChargesShipment);

  /*
   * Since we now allow order charges when either stop is a partner carrier,
   * we need to create a new order charges shipment in cases when a user goes to update an already
   * existing order and there wasn't an order charges shipment created in the first place.
   */
  const inboundStopType = stopsArr[INBOUND_STOP_IDX]?.stopType;
  const outboundStopType = stopsArr[OUTBOUND_STOP_IDX]?.stopType;
  if (
    (inboundStopType === StopType.PartnerCarrierDropoff ||
      outboundStopType === StopType.PartnerCarrierPickup) &&
    isNil(orderChargesShipmentValues)
  ) {
    orderChargesShipmentValues = getInitialOrderChargesShipmentValues({
      defaultFuelSurcharge: null,
      freightBillingMethod: defaultFreightBillingMethod,
      fulfillmentType,
    });
  }

  const packageValues = standardOrder.packages.map((pkg) =>
    convertPackagePayloadToFormData({
      package: pkg,
      useCentimeters,
      useKilograms,
    }),
  );

  return {
    updatedAt: standardOrder.updatedAt,
    orderSegmentType: standardOrder.orderSegmentType,
    fulfillmentType: standardOrder.fulfillmentType,
    contactUuid: standardOrder.billingPartyContact.uuid,
    deletedAutoAppliedAccessorials:
      standardOrder.deletedAutoAppliedAccessorials.map((a) => a.uuid),
    serviceUuid,
    name: standardOrder.name,
    shipperBillOfLadingNumber:
      standardOrder.standardOrderFields.shipperBillOfLadingNumber ?? '',
    masterAirwayBillOfLadingNumber:
      standardOrder.standardOrderFields.masterAirwayBillOfLadingNumber ?? null,
    secondaryReferenceNumber: standardOrder.secondaryRefNumber ?? null,
    tertiaryReferenceNumber: standardOrder.tertiaryRefNumber ?? null,
    quaternaryReferenceNumber: standardOrder.quaternaryRefNumber ?? null,
    quinaryReferenceNumber: standardOrder.quinaryRefNumber ?? null,
    dimFactor: standardOrder.standardOrderFields.dimFactor,
    units:
      standardOrder.standardOrderFields.measurementUnits ===
      MeasurementUnits.Inches
        ? Units.IMPERIAL
        : Units.METRIC,
    onHand: standardOrder.onHand ?? null,
    receivedDate: null,
    receivedAtOriginDate: null,
    pickedDate: null,
    pieceCount: null,
    piecesPicked: null,
    loadedDate: null,
    personName: standardOrder.personName,
    personPhoneNumber: standardOrder.personPhoneNumber,
    personEmail: standardOrder.personEmail,
    paymentMethod: null,
    refusedBy: null,
    refusedDate: null,
    holdReasonUuid: null,
    holdReasonName: null,
    warehouseUuid: null,
    thirdPartyBrokerUuid: standardOrder.thirdPartyBrokers[0]?.uuid,
    tags: standardOrder.tags.map((tag) => ({
      uuid: tag.uuid,
      value: tag.value,
      color: tag.color,
    })),
    documents: standardOrder.documents.map((doc) => ({
      ...doc,
      name: doc.name ?? null,
      notes: doc.name ?? null,
      driverFormTemplateUuid: doc.driverFormTemplate?.uuid ?? null,
    })),
    notes: standardOrder.notes,
    stops: stopsArr,
    packages: packageValues,
    uuid: standardOrder.uuid,
    fieldsUuid: standardOrder.standardOrderFields.uuid,
    refNumbers: standardOrder.refNumbers,
    vehicleTypeUuid: null,
    orderComments: standardOrder.orderComments,
    detailedStatus: standardOrder.detailedStatusV2,
    status: standardOrder.status,
    isCrossDock: standardOrder.isCrossDock,
    recurringOrderFrequency: null,
    orderChargesShipment: orderChargesShipmentValues,
    lineHaulLaneUuid: standardOrder.lineHaulLane?.uuid,
    lineHaulShipment: lineHaulShipmentValues,
    enableLineHaul: standardOrder.enableLineHaul,
    isUsingLineHaul: !isNil(lineHaulShipment),
    chargeableWeightCubicMeters: isNil(
      standardOrder.chargeableWeightCubicMeters,
    )
      ? null
      : standardOrder.chargeableWeightCubicMeters.value(),
    totalSkids: standardOrder.totalSkids,
    isReweighed: standardOrder.isReweighed,
    inBond: standardOrder.inBond,
    hazmat: standardOrder.hazmat,
    lockShipperBillOfLadingNumber:
      standardOrder.standardOrderFields.lockShipperBillOfLadingNumber,
    lockSecondaryReferenceNumber: standardOrder.lockSecondaryRefNumber,
  };
};

export const createPackageCreateInput = ({
  packageValues,
  useCentimeters,
  useKilograms,
  useAllCaps,
}: {
  packageValues: PackageValues;
  useCentimeters?: boolean;
  useKilograms?: boolean;
  useAllCaps: boolean;
}): PackageCreateInput | null => {
  if (isNil(packageValues.quantity)) {
    return null;
  }
  let { width, length, height, weight } = packageValues;
  if (useCentimeters === true) {
    width = convertToInches(width);
    length = convertToInches(length);
    height = convertToInches(height);
  }
  if (useKilograms === true) {
    weight = convertKilogramsToPounds(weight);
  }
  return {
    description: convertCasing(packageValues.description, useAllCaps) ?? '',
    width: width ?? null,
    height: height ?? null,
    length: length ?? null,
    weight: weight ?? null,
    quantity: packageValues.quantity,
    type: packageValues.type,
    packageSpecId: packageValues.packageSpecId,
    warehouseLocationUuid: packageValues.warehouseLocationUuid,
  };
};

export const createPackageArrayUpdateInput = ({
  packageValues,
  useAllCaps,
  useCentimeters,
  useKilograms,
}: {
  packageValues: PackageValues;
  useAllCaps: boolean;
  useCentimeters?: boolean;
  useKilograms?: boolean;
}): PackageArrayUpdateInput | null => {
  const { packageSpecId, quantity } = packageValues;
  if (isNil(quantity)) {
    return null;
  }
  let { width, length, height, weight } = packageValues;
  if (useCentimeters === true) {
    width = convertToInches(width);
    length = convertToInches(length);
    height = convertToInches(height);
  }
  if (useKilograms === true) {
    weight = convertKilogramsToPounds(weight);
  }
  return {
    packageUpsertInput: {
      packageSpecId,
      description: convertCasing(packageValues.description, useAllCaps) ?? '',
      width: width ?? null,
      height: height ?? null,
      length: length ?? null,
      weight: weight ?? null,
      quantity,
      type: packageValues.type,
      uuid: packageValues.uuid,
      warehouseLocationUuid: packageValues.warehouseLocationUuid,
    },
  };
};

const createFreightChargeCreateInput = ({
  freightChargeValues,
  updateTotalCharge,
}: {
  freightChargeValues: FreightChargeValues;
  updateTotalCharge?: boolean;
}): FreightChargeCreateInput => {
  return {
    billingMethod: freightChargeValues.billingMethod,
    rate: freightChargeValues.rate ?? 0,
    quantity: freightChargeValues.quantity,
    discountRate: freightChargeValues.discountRate,
    tariffUuid: freightChargeValues.tariffUuid,
    description: freightChargeValues.description,
    settlementFlatRate: freightChargeValues.settlementFlatRate ?? null,
    settlementPercentageRate:
      freightChargeValues.settlementPercentageRate ?? 100,
    fuelChargeCreateInput: isNil(freightChargeValues.fuelCharge.billingMethod)
      ? undefined
      : {
          description: freightChargeValues.fuelCharge.description,
          flatRate: freightChargeValues.fuelCharge.flatRateDollars,
          surchargeRate: freightChargeValues.fuelCharge.surchargeRate ?? 0,
          type: freightChargeValues.fuelCharge.billingMethod,
          settlementFlatRate:
            freightChargeValues.fuelCharge.settlementFlatRate ?? null,
          settlementPercentageRate:
            freightChargeValues.fuelCharge.settlementPercentageRate ?? 100,
          totalCharge:
            updateTotalCharge === true
              ? freightChargeValues.fuelCharge.totalCharge
              : undefined,
        },
    totalCharge:
      updateTotalCharge === true ? freightChargeValues.totalCharge : undefined,
  };
};

const createIndependentFreightCostCreateInput = ({
  freightCostValues,
  updateTotalCharge = false,
}: {
  freightCostValues: FreightCostValues | null | undefined;
  updateTotalCharge?: boolean;
}): IndependentFreightCostCreateInput | undefined => {
  if (isNil(freightCostValues)) {
    return undefined;
  }
  const rateInCents = new Decimal(freightCostValues.rate ?? 0).mul(100);
  return {
    billingMethod: freightCostValues.billingMethod,
    rateUsdCents:
      freightCostValues.billingMethod === FreightBillingMethod.FlatRate
        ? undefined
        : rateInCents,
    flatRateUsdCents:
      freightCostValues.billingMethod === FreightBillingMethod.FlatRate
        ? rateInCents
        : undefined,
    quantity: freightCostValues.quantity,
    discountRate: freightCostValues.discountRate,
    tariffUuid: freightCostValues.tariffUuid,
    description: freightCostValues.description,
    independentFuelCostCreateInput: {
      billingMethod: freightCostValues.fuelCost.billingMethod,
      flatRateUsdCents: isNil(freightCostValues.fuelCost.flatRateDollars)
        ? undefined
        : new Decimal(freightCostValues.fuelCost.flatRateDollars).mul(100),
      surchargeRate: new Decimal(freightCostValues.fuelCost.surchargeRate ?? 0),
      totalAmountUsdCents: updateTotalCharge
        ? new Decimal(freightCostValues.fuelCost.totalCharge).mul(100)
        : new Decimal(0),
    },
    totalAmountUsdCents: updateTotalCharge
      ? new Decimal(freightCostValues.totalCharge).mul(100)
      : new Decimal(0),
  };
};

const createFreightChargeUpsertInput = ({
  freightChargeValues,
  updateTotalCharge,
}: {
  freightChargeValues: FreightChargeValues;
  updateTotalCharge?: boolean;
}): FreightChargeUpsertInput => {
  return {
    billingMethod: freightChargeValues.billingMethod,
    rate: freightChargeValues.rate ?? 0,
    uuid: freightChargeValues.uuid,
    quantity: freightChargeValues.quantity,
    discountRate: freightChargeValues.discountRate,
    tariffUuid: freightChargeValues.tariffUuid,
    description: freightChargeValues.description,
    authoCode: freightChargeValues.authoCode,
    settlementFlatRate: freightChargeValues.settlementFlatRate ?? null,
    settlementPercentageRate:
      freightChargeValues.settlementPercentageRate ?? null,
    fuelChargeUpsertInput: isNil(freightChargeValues.fuelCharge.billingMethod)
      ? undefined
      : {
          flatRate: freightChargeValues.fuelCharge.flatRateDollars,
          description: freightChargeValues.fuelCharge.description,
          authoCode: freightChargeValues.fuelCharge.authoCode,
          surchargeRate: freightChargeValues.fuelCharge.surchargeRate ?? 0,
          type: freightChargeValues.fuelCharge.billingMethod,
          uuid: freightChargeValues.fuelCharge.uuid,
          settlementFlatRate:
            freightChargeValues.fuelCharge.settlementFlatRate ?? null,
          settlementPercentageRate:
            freightChargeValues.fuelCharge.settlementPercentageRate ?? null,
          totalCharge:
            updateTotalCharge === true
              ? freightChargeValues.fuelCharge.totalCharge
              : undefined,
        },
    totalCharge:
      updateTotalCharge === true ? freightChargeValues.totalCharge : undefined,
  };
};

const createCustomChargeCreateInput = ({
  customChargeValues,
  updateTotalCharge,
}: {
  customChargeValues: CustomChargeValues;
  updateTotalCharge?: boolean;
}): CustomChargeCreateInput | null => {
  if (isNil(customChargeValues.quantity) || isNil(customChargeValues.rate)) {
    return null;
  }
  return {
    name: customChargeValues.name ?? '',
    rate: customChargeValues.rate,
    quantity: customChargeValues.quantity,
    isAutoApplied: customChargeValues.isAutoApplied,
    accessorialTemplateUuid:
      customChargeValues.billingMethod === CustomChargeBillingMethod.Accessorial
        ? customChargeValues.accessorialUuid
        : null,
    zoneBasedAccessorialMatrixItemUuid:
      customChargeValues.zoneBasedAccessorialMatrixItemUuid,
    specialAccessorialMatrixItemUuid:
      customChargeValues.specialAccessorialMatrixItemUuid,
    billingMethod: customChargeValues.billingMethod,
    description: customChargeValues.description,
    settlementFlatRate: customChargeValues.settlementFlatRate ?? null,
    settlementPercentageRate:
      customChargeValues.settlementPercentageRate ?? 100,
    authoCode: customChargeValues.authoCode,
    fuelSurchargeRate: customChargeValues.fuelSurchargePercentageRate,
    totalCharge:
      updateTotalCharge === true ? customChargeValues.totalCharge : undefined,
  };
};

const createIndependentCustomCostCreateInput = ({
  customCostValues,
  updateTotalCharge,
}: {
  customCostValues: CustomCostValues;
  updateTotalCharge?: boolean;
}): IndependentCustomCostCreateInput | null => {
  if (isNil(customCostValues.quantity) || isNil(customCostValues.rate)) {
    return null;
  }

  return {
    name: customCostValues.name ?? '',
    billingMethod: customCostValues.billingMethod,
    description: customCostValues.description,
    quantity: customCostValues.quantity,
    rateUsdCents: new Decimal(customCostValues.rate).mul(100),
    accessorialTemplateUuid:
      customCostValues.billingMethod === CustomChargeBillingMethod.Accessorial
        ? customCostValues.accessorialUuid
        : null,
    zoneBasedAccessorialMatrixItemUuid:
      customCostValues.zoneBasedAccessorialMatrixItemUuid,
    specialAccessorialMatrixItemUuid:
      customCostValues.specialAccessorialMatrixItemUuid,
    fuelSurchargeRate: new Decimal(
      customCostValues.fuelSurchargePercentageRate ?? 0,
    ),
    totalAmountUsdCents:
      updateTotalCharge === true
        ? new Decimal(customCostValues.totalCharge).mul(100)
        : new Decimal(0),
  };
};

const createCustomChargeArrayUpdateInput = ({
  customChargeValues,
  updateTotalCharge,
}: {
  customChargeValues: CustomChargeValues;
  updateTotalCharge?: boolean;
}): CustomChargeArrayUpdateInput | null => {
  return {
    customChargeUpsertInput: {
      uuid: customChargeValues.uuid,
      name: customChargeValues.name ?? '',
      rate: customChargeValues.rate,
      quantity: customChargeValues.quantity ?? 1,
      isAutoApplied: customChargeValues.isAutoApplied,
      accessorialTemplateUuid:
        customChargeValues.billingMethod ===
        CustomChargeBillingMethod.Accessorial
          ? customChargeValues.accessorialUuid
          : null,
      zoneBasedAccessorialMatrixItemUuid:
        customChargeValues.zoneBasedAccessorialMatrixItemUuid,
      billingMethod: customChargeValues.billingMethod,
      specialAccessorialMatrixItemUuid:
        customChargeValues.specialAccessorialMatrixItemUuid,
      description: customChargeValues.description ?? '',
      authoCode: customChargeValues.authoCode ?? '',
      fuelSurchargeRate:
        customChargeValues?.fuelSurchargePercentageRate ?? null,
      settlementFlatRate: customChargeValues?.settlementFlatRate ?? null,
      settlementPercentageRate:
        customChargeValues?.settlementPercentageRate ?? 100,
      totalCharge:
        updateTotalCharge === true
          ? customChargeValues?.totalCharge
          : undefined,
    },
  };
};

const createContactPersonUpsertInput = ({
  contactPerson,
  useAllCaps,
}: {
  contactPerson: ContactPersonValues;
  useAllCaps: boolean;
}): ContactPersonValues => {
  return {
    ...contactPerson,
    firstName: convertCasing(contactPerson.firstName, useAllCaps),
    lastName: convertCasing(contactPerson.lastName, useAllCaps),
    email: convertCasing(contactPerson.email, useAllCaps),
  };
};

const createAddressUpsertInput = ({
  address,
  useAllCaps,
}: {
  address: AddressValues | AddressOptionalValues;
  useAllCaps: boolean;
}): AddressUpsertInput => {
  return {
    uuid: address.uuid ?? v4(),
    city: convertCasing(address.city, useAllCaps) ?? '',
    country: convertCasing(address.country, useAllCaps) ?? '',
    line1: convertCasing(address.line1, useAllCaps) ?? '',
    line2: convertCasing(address.line2, useAllCaps),
    name: convertCasing(address.name, useAllCaps) ?? '',
    state: convertCasing(address.state, useAllCaps) ?? '',
    zip: address.zip ?? '',
    longitude: address.longitude ?? null,
    latitude: address.latitude ?? null,
    preventCoordRecompute: address.preventCoordRecompute,
    specialInstructions: address.specialInstructions,
    internalNotes: address.internalNotes,
    iataCode: address.iataCode,
  };
};

const createIndependentSettlementBillLineItemCreateInput = ({
  settlementBillLineItem,
  updateTotalCharge,
}: {
  settlementBillLineItem: IndependentSettlementBillLineItemValues;
  updateTotalCharge?: boolean;
}): IndependentSettlementBillLineItemCreateInput => {
  return {
    driverUuid: settlementBillLineItem.driverUuid,
    settlementMode: settlementBillLineItem.settlementMode,
    totalAmountUsdCents:
      updateTotalCharge === true
        ? convertDollarsToDecimalCents(settlementBillLineItem.totalCharge)
        : new Decimal(0),
    independentFreightCostCreateInput: createIndependentFreightCostCreateInput({
      freightCostValues: settlementBillLineItem.freightCost,
      updateTotalCharge,
    }),
    independentCustomCostCreateInputs: filterNotNil(
      settlementBillLineItem.customCosts?.map((customCostValues) =>
        createIndependentCustomCostCreateInput({
          customCostValues,
          updateTotalCharge,
        }),
      ) ?? [],
    ),
  };
};

export const createShipmentCreateInput = ({
  stopValues,
  updateTotalCharge,
  useAllCaps,
  excludeFreightCharge = false,
  ffRobustSettlementEnabled,
}: {
  stopValues: StopValues;
  updateTotalCharge?: boolean;
  useAllCaps: boolean;
  excludeFreightCharge?: boolean;
  ffRobustSettlementEnabled: boolean;
}): StandardShipmentCreateInput | null => {
  if (stopValues.stopType === StopType.None) {
    return null;
  }
  const { address } = stopValues;
  const pickupOrDelivery = getPickupOrDelivery(stopValues.stopType);
  let deadlineDayJs = isNil(stopValues.deadlineDate)
    ? undefined
    : dayjs(stopValues.deadlineDate);
  if (!isNil(stopValues.deadlineDate) && !isNil(stopValues.deadlineTime)) {
    const deadlineTimeString = dayjs(stopValues.deadlineTime).format('hh:mm a');
    const deadlineString = `${dayjs(stopValues.deadlineDate).format(
      'MM/DD/YYYY',
    )} ${deadlineTimeString}`;
    deadlineDayJs = dayjs(deadlineString);
  }
  const customCharges = filterNotNil(
    stopValues.customCharges?.map((customCharge) =>
      createCustomChargeCreateInput({
        customChargeValues: customCharge,
        updateTotalCharge,
      }),
    ) ?? [],
  );

  const independentSettlementBillLineItemCreateInputs =
    stopValues.settlementBillLineItems?.map((settlementBillLineItem) =>
      createIndependentSettlementBillLineItemCreateInput({
        settlementBillLineItem,
        updateTotalCharge,
      }),
    );

  return {
    uuid: stopValues.shipmentUuid,
    destinationAirport: convertCasing(
      stopValues.destinationAirport,
      useAllCaps,
    ),
    hideFromBilling: getHideFromBilling({
      hideFromBilling: stopValues.hideFromBilling,
      stopType: stopValues.stopType,
    }),
    airportInfoUuid:
      stopValues.stopType === StopType.Recovery ||
      stopValues.stopType === StopType.Transfer
        ? stopValues.airportInfoUuid
        : undefined,
    standardShipmentFieldsCreateInput: {
      type: StandardShipmentType.SingleStop,
      pickupOrDelivery,
      deliveryDate: stopValues.deliveryDate,
      deadlineType: stopValues.deadlineType ?? undefined,
      deadlineDate: deadlineDayJs?.toDate(),
    },
    documentConnectInputs: filterNotNil(stopValues.documents ?? []).map(
      (document) => {
        return { uuid: document.uuid };
      },
    ),
    customChargeCreateInputs: customCharges,
    freightChargeCreateInput:
      !isNil(stopValues.freightCharge) && !excludeFreightCharge
        ? createFreightChargeCreateInput({
            freightChargeValues: stopValues.freightCharge,
            updateTotalCharge,
          })
        : undefined,
    legCreateInputs: [
      {
        endStopCreateInput: {
          destinationAirport: convertCasing(
            stopValues.destinationAirport,
            useAllCaps,
          ),
          appointmentManuallyConfirmed: stopValues.appointmentConfirmed,
          appointmentRequired: stopValues.appointmentRequired,
          deadlineTime: stopValues.deadlineTime,
          outboundMethod: stopValues.outboundMethod,
          inboundMethod: stopValues.inboundMethod,
          proofOfDeliverySignee: stopValues.proofOfDeliverySignee,
          standardStopType: stopValues.standardStopType,
          stopType: coalesceStopTypesForBackend(stopValues.stopType),
          expectedInboundArrivalDate: stopValues.expectedInboundArrivalDate,
          expectedOutboundDate: stopValues.expectedOutboundDate,
          inboundDeadlineDate: stopValues.inboundDeadlineDate,
          incomingCarrier: convertCasing(
            stopValues.incomingCarrier,
            useAllCaps,
          ),
          outboundCarrier: convertCasing(
            stopValues.outboundCarrier,
            useAllCaps,
          ),
          isSpecial: stopValues.isSpecial,
          driverUuid: stopValues.driverUuid,
          removedDriver: stopValues.removedDriver,
          specialInstructions: convertCasing(
            stopValues.specialInstructions,
            useAllCaps,
          ),
          hideFromDispatch: stopValues.hideFromDispatch,
          contactPersonUpsertInput: isNil(stopValues.contactPerson)
            ? undefined
            : createContactPersonUpsertInput({
                contactPerson: stopValues.contactPerson,
                useAllCaps,
              }),
          appointmentTime: stopValues.appointmentTime,
          endAppointmentTime: stopValues.endAppointmentTime,
          terminalUuid: stopValues.terminalUuid,
          serviceDate: stopValues.serviceDate,
          serviceDateIso8601: stopValues.serviceDateV2?.toString(),
          status:
            stopValues.status === StopStatus.Completed
              ? StopStatus.Completed
              : undefined,
          transferAddressUpsertInput: isNil(stopValues.transferAddress)
            ? undefined
            : createAddressUpsertInput({
                address: stopValues.transferAddress,
                useAllCaps,
              }),
          addressUpsertInput: isNil(address)
            ? {
                name: '',
                line1: '',
                city: '',
                state: '',
                zip: '',
                country: '',
                uuid: v4(),
              }
            : createAddressUpsertInput({
                address,
                useAllCaps,
              }),
        },
        miles: stopValues.miles,
      },
    ],
    independentSettlementBillLineItemCreateInputs: ffRobustSettlementEnabled
      ? independentSettlementBillLineItemCreateInputs
      : undefined,
    totalCharge:
      updateTotalCharge === true ? stopValues.totalCharge : undefined,
  };
};

export const createLineHaulShipmentCreateInput = ({
  lineHaulShipment,
  updateTotalCharge,
}: {
  lineHaulShipment: LineHaulShipmentValues;
  updateTotalCharge?: boolean;
}): StandardShipmentCreateInput | null => {
  const { freightCharge } = lineHaulShipment;
  const discountRate = freightCharge?.discountRate;

  return {
    // no legs since this is a dummy shipment
    legCreateInputs: [],
    shipmentType: ShipmentType.LineHaul,
    freightChargeCreateInput: isNil(freightCharge)
      ? undefined
      : {
          billingMethod: freightCharge.billingMethod,
          rate: freightCharge.rate ?? 0,
          quantity: freightCharge.quantity,
          discountRate,
          tariffUuid: freightCharge.tariffUuid,
          description: freightCharge.description,
          settlementFlatRate: freightCharge.settlementFlatRate ?? null,
          settlementPercentageRate:
            freightCharge.settlementPercentageRate ?? null,
          fuelChargeCreateInput: isNil(freightCharge.fuelCharge.billingMethod)
            ? undefined
            : {
                description: freightCharge.fuelCharge.description,
                flatRate: freightCharge.fuelCharge.flatRateDollars,
                surchargeRate: freightCharge.fuelCharge.surchargeRate ?? 0,
                type: freightCharge.fuelCharge.billingMethod,
                settlementFlatRate:
                  freightCharge.fuelCharge.settlementFlatRate ?? null,
                settlementPercentageRate:
                  freightCharge.fuelCharge.settlementPercentageRate ?? null,
                totalCharge:
                  updateTotalCharge === true
                    ? freightCharge.fuelCharge.totalCharge
                    : undefined,
              },
          totalCharge:
            updateTotalCharge === true ? freightCharge.totalCharge : undefined,
        },
    totalCharge:
      updateTotalCharge === true ? lineHaulShipment.totalCharge : undefined,
  };
};

export const createLineHaulShipmentUpdateInput = ({
  lineHaulShipment,
  updateTotalCharge,
}: {
  lineHaulShipment: LineHaulShipmentValues;
  updateTotalCharge?: boolean;
}): StandardShipmentArrayUpdateInput | null => {
  const freightCharge = lineHaulShipment?.freightCharge;

  if (lineHaulShipment.isLocal) {
    return {
      shipmentCreateInput: createLineHaulShipmentCreateInput({
        lineHaulShipment,
        updateTotalCharge,
      }),
    };
  }

  const discountRate = freightCharge?.discountRate;

  return {
    shipmentUpdateInput: {
      uuid: lineHaulShipment.uuid,
      freightChargeUpsertInput: isNil(freightCharge)
        ? undefined
        : {
            billingMethod: freightCharge.billingMethod,
            rate: freightCharge.rate ?? 0,
            uuid: freightCharge.uuid,
            quantity: freightCharge.quantity,
            discountRate,
            tariffUuid: freightCharge.tariffUuid,
            description: freightCharge.description,
            authoCode: freightCharge.authoCode,
            settlementFlatRate: freightCharge.settlementFlatRate ?? null,
            settlementPercentageRate:
              freightCharge.settlementPercentageRate ?? null,
            fuelChargeUpsertInput: isNil(freightCharge.fuelCharge.billingMethod)
              ? undefined
              : {
                  flatRate: freightCharge.fuelCharge.flatRateDollars,
                  description: freightCharge.fuelCharge.description,
                  authoCode: freightCharge.fuelCharge.authoCode,
                  surchargeRate: freightCharge.fuelCharge.surchargeRate ?? 0,
                  type: freightCharge.fuelCharge.billingMethod,
                  uuid: freightCharge.fuelCharge.uuid,
                  settlementFlatRate:
                    freightCharge.fuelCharge.settlementFlatRate ?? null,
                  settlementPercentageRate:
                    freightCharge.fuelCharge.settlementPercentageRate ?? null,
                  totalCharge:
                    updateTotalCharge === true
                      ? freightCharge.fuelCharge.totalCharge
                      : undefined,
                },
            totalCharge:
              updateTotalCharge === true
                ? freightCharge.totalCharge
                : undefined,
          },
      // standardShipmentFieldsUpdateInput: undefined,
      legUpsertInputs: [],
      totalCharge:
        updateTotalCharge === true ? lineHaulShipment.totalCharge : undefined,
    },
  };
};

export const createOrderChargesShipmentCreateInput = ({
  orderChargesShipmentValues,
  updateTotalCharge,
  excludeFreightCharge = false,
}: {
  orderChargesShipmentValues: OrderChargesShipmentValues;
  updateTotalCharge?: boolean;
  excludeFreightCharge?: boolean;
}): StandardShipmentCreateInput | null => {
  const freightChargeCreateInput =
    !isNil(orderChargesShipmentValues.freightCharge) && !excludeFreightCharge
      ? createFreightChargeCreateInput({
          freightChargeValues: orderChargesShipmentValues.freightCharge,
          updateTotalCharge,
        })
      : undefined;
  const customChargeCreateInputs = filterNotNil(
    orderChargesShipmentValues.customCharges?.map((customCharge) =>
      createCustomChargeCreateInput({
        customChargeValues: customCharge,
        updateTotalCharge,
      }),
    ) ?? [],
  );

  return {
    legCreateInputs: [],
    shipmentType: ShipmentType.OrderCharges,
    freightChargeCreateInput,
    customChargeCreateInputs,
    totalCharge:
      updateTotalCharge === true
        ? orderChargesShipmentValues.totalCharge
        : undefined,
  };
};

export const createOrderChargesShipmentUpdateInput = ({
  orderChargesShipmentValues,
  updateTotalCharge,
  excludeFreightCharge = false,
}: {
  orderChargesShipmentValues: OrderChargesShipmentValues;
  updateTotalCharge?: boolean;
  excludeFreightCharge?: boolean;
}) => {
  if (isNil(orderChargesShipmentValues)) {
    return null;
  }

  if (orderChargesShipmentValues.isLocal === true) {
    const shipmentCreateInput = createOrderChargesShipmentCreateInput({
      orderChargesShipmentValues,
      updateTotalCharge,
      excludeFreightCharge,
    });
    return {
      shipmentCreateInput,
    };
  }

  const freightChargeUpsertInput =
    !isNil(orderChargesShipmentValues.freightCharge) && !excludeFreightCharge
      ? createFreightChargeUpsertInput({
          freightChargeValues: orderChargesShipmentValues.freightCharge,
          updateTotalCharge,
        })
      : undefined;

  const customChargeArrayUpdateInputs = filterNotNil(
    orderChargesShipmentValues.customCharges?.map((customCharge) =>
      createCustomChargeArrayUpdateInput({
        customChargeValues: customCharge,
        updateTotalCharge,
      }),
    ) ?? [],
  );

  return {
    shipmentUpdateInput: {
      uuid: orderChargesShipmentValues.uuid,
      freightChargeUpsertInput,
      customChargeArrayUpdateInputs,
      totalCharge:
        updateTotalCharge === true
          ? orderChargesShipmentValues.totalCharge
          : undefined,
    },
  };
};

const createIndependentCustomCostUpsertInput = ({
  customCostValues,
  updateTotalCharge,
}: {
  customCostValues: CustomCostValues;
  updateTotalCharge?: boolean;
}): IndependentCustomCostUpsertInput | null => {
  if (isNil(customCostValues.quantity) || isNil(customCostValues.rate)) {
    return null;
  }

  return {
    id: customCostValues.uuid,
    name: customCostValues.name ?? '',
    billingMethod: customCostValues.billingMethod,
    description: customCostValues.description,
    quantity: customCostValues.quantity,
    rateUsdCents: new Decimal(customCostValues.rate).mul(100),
    accessorialTemplateUuid:
      customCostValues.billingMethod === CustomChargeBillingMethod.Accessorial
        ? customCostValues.accessorialUuid
        : null,
    zoneBasedAccessorialMatrixItemUuid:
      customCostValues.zoneBasedAccessorialMatrixItemUuid,
    specialAccessorialMatrixItemUuid:
      customCostValues.specialAccessorialMatrixItemUuid,
    fuelSurchargeRate: new Decimal(
      customCostValues.fuelSurchargePercentageRate ?? 0,
    ),
    totalAmountUsdCents:
      updateTotalCharge === true
        ? convertDollarsToDecimalCents(customCostValues.totalCharge)
        : undefined,
  };
};

const createIndependentFreightCostUpsertInput = ({
  freightCostValues,
  updateTotalCharge = false,
}: {
  freightCostValues: FreightCostValues | null | undefined;
  updateTotalCharge?: boolean;
}): IndependentFreightCostUpsertInput | undefined => {
  if (isNil(freightCostValues)) {
    return undefined;
  }
  const rateInCents = new Decimal(freightCostValues.rate ?? 0).mul(100);
  return {
    id: freightCostValues.uuid,
    billingMethod: freightCostValues.billingMethod,
    rateUsdCents:
      freightCostValues.billingMethod === FreightBillingMethod.FlatRate
        ? undefined
        : rateInCents,
    flatRateUsdCents:
      freightCostValues.billingMethod === FreightBillingMethod.FlatRate
        ? rateInCents
        : undefined,
    quantity: freightCostValues.quantity,
    discountRate: freightCostValues.discountRate,
    tariffUuid: freightCostValues.tariffUuid,
    description: freightCostValues.description,
    independentFuelCostUpsertInput: {
      id: freightCostValues.fuelCost.uuid,
      billingMethod: freightCostValues.fuelCost.billingMethod,
      flatRateUsdCents: isNil(freightCostValues.fuelCost.flatRateDollars)
        ? undefined
        : new Decimal(freightCostValues.fuelCost.flatRateDollars).mul(100),
      surchargeRate: new Decimal(freightCostValues.fuelCost.surchargeRate ?? 0),
      totalAmountUsdCents: updateTotalCharge
        ? convertDollarsToDecimalCents(freightCostValues.fuelCost.totalCharge)
        : undefined,
    },
    totalAmountUsdCents: updateTotalCharge
      ? convertDollarsToDecimalCents(freightCostValues.totalCharge)
      : undefined,
  };
};

const createIndependentSettlementBillLineItemUpsertInput = ({
  settlementBillLineItem,
  updateTotalCharge,
}: {
  settlementBillLineItem: IndependentSettlementBillLineItemValues;
  updateTotalCharge?: boolean;
}): IndependentSettlementBillLineItemUpsertInput => {
  return {
    id: settlementBillLineItem.id,
    driverUuid: settlementBillLineItem.driverUuid,
    settlementMode: SettlementMode.Independent,
    totalAmountUsdCents:
      updateTotalCharge === true
        ? convertDollarsToDecimalCents(settlementBillLineItem.totalCharge)
        : undefined,
    independentFreightCostUpsertInput: createIndependentFreightCostUpsertInput({
      freightCostValues: settlementBillLineItem.freightCost,
      updateTotalCharge,
    }),
    independentCustomCostUpsertInputs: filterNotNil(
      settlementBillLineItem.customCosts?.map((customCostValues) =>
        createIndependentCustomCostUpsertInput({
          customCostValues,
          updateTotalCharge,
        }),
      ) ?? [],
    ),
  };
};

export const createShipmentArrayUpdateInput = ({
  stopValues,
  updateTotalCharge,
  useAllCaps,
  ffRobustSettlementEnabled,
}: {
  stopValues: StopValues;
  updateTotalCharge?: boolean;
  useAllCaps: boolean;
  ffRobustSettlementEnabled: boolean;
}): StandardShipmentArrayUpdateInput | null => {
  if (stopValues.stopType === StopType.None) {
    return null;
  }
  const { address } = stopValues;

  const pickupOrDelivery = getPickupOrDelivery(stopValues.stopType);
  const deadlineDateString =
    !isNil(stopValues.deadlineDate) && !isNil(stopValues.deadlineTime)
      ? `${dayjs(stopValues.deadlineDate).format('MM/DD/YYYY')} ${dayjs(
          stopValues.deadlineTime,
        ).format('hh:mm a')}`
      : undefined;
  const deadlineDate =
    !isNil(stopValues.deadlineDate) && !isNil(stopValues.deadlineTime)
      ? dayjs(deadlineDateString).toDate()
      : stopValues.deadlineDate;
  const customChargeArrayUpdateInputs = filterNotNil(
    stopValues.customCharges?.map((customCharge) =>
      createCustomChargeArrayUpdateInput({
        customChargeValues: customCharge,
        updateTotalCharge,
      }),
    ) ?? [],
  );

  if (stopValues.isLocal) {
    return {
      shipmentCreateInput: createShipmentCreateInput({
        stopValues,
        updateTotalCharge,
        useAllCaps,
        ffRobustSettlementEnabled,
      }),
    };
  }

  const upsertStopDriverMapInputs = stopValues.stopDriverMaps?.map(
    (stopDriverMap) => ({
      stopDriverMapUpsertInput: {
        ...stopDriverMap,
        isAttempt: undefined,
        attemptedAt: undefined,
      },
    }),
  );

  const independentSettlementBillLineItemUpsertInputs =
    stopValues.settlementBillLineItems?.map((settlementBillLineItem) =>
      createIndependentSettlementBillLineItemUpsertInput({
        settlementBillLineItem,
        updateTotalCharge,
      }),
    );

  return {
    shipmentUpdateInput: {
      uuid: stopValues.shipmentUuid,
      destinationAirport: convertCasing(
        stopValues.destinationAirport,
        useAllCaps,
      ),
      customChargeArrayUpdateInputs,
      hideFromBilling: getHideFromBilling({
        hideFromBilling: stopValues.hideFromBilling,
        stopType: stopValues.stopType,
      }),
      freightChargeUpsertInput:
        !isNil(stopValues.freightCharge) &&
        !dummyStopTypes.includes(stopValues.stopType)
          ? createFreightChargeUpsertInput({
              freightChargeValues: stopValues.freightCharge,
              updateTotalCharge,
            })
          : undefined,
      airportInfoUuid:
        stopValues.stopType === StopType.Recovery ||
        stopValues.stopType === StopType.Transfer
          ? stopValues.airportInfoUuid
          : undefined,
      standardShipmentFieldsUpdateInput: isNil(
        stopValues.standardShipmentFieldsUuid,
      )
        ? undefined
        : {
            uuid: stopValues.standardShipmentFieldsUuid,
            isSpecial: stopValues.isSpecial,
            type: StandardShipmentType.SingleStop,
            pickupOrDelivery,
            deliveryDate: stopValues.deliveryDate,
            deadlineType: stopValues.deadlineType ?? undefined,
            deadlineDate,
          },
      legUpsertInputs: [
        {
          uuid: stopValues.legUuid,
          endStopUpsertInput: {
            arrivedAt: stopValues.arrivedAt,
            completedAt: stopValues.completedAt,
            appointmentManuallyConfirmed: stopValues.appointmentConfirmed,
            appointmentRequired: stopValues.appointmentRequired,
            settlementDeductionFlatRate:
              stopValues.settlementDeductionFlatRate ?? null,
            settlementDeductionPercentageRate:
              stopValues.settlementDeductionPercentageRate ?? null,
            settlementDeductionName: stopValues.settlementName ?? null,
            uuid: stopValues.uuid,
            proofOfDeliverySignee: stopValues.proofOfDeliverySignee,
            deadlineTime: stopValues.deadlineTime,
            outboundMethod: stopValues.outboundMethod,
            inboundMethod: stopValues.inboundMethod,
            standardStopType: stopValues.standardStopType,
            expectedOutboundDate: stopValues.expectedOutboundDate,
            expectedInboundArrivalDate: stopValues.expectedInboundArrivalDate,
            inboundDeadlineDate: stopValues.inboundDeadlineDate,
            incomingCarrier: convertCasing(
              stopValues.incomingCarrier,
              useAllCaps,
            ),
            outboundCarrier: convertCasing(
              stopValues.outboundCarrier,
              useAllCaps,
            ),
            driverUuid: stopValues.driverUuid,
            removedDriver: stopValues.removedDriver,
            contactPersonUpsertInput: isNil(stopValues.contactPerson)
              ? undefined
              : createContactPersonUpsertInput({
                  contactPerson: stopValues.contactPerson,
                  useAllCaps,
                }),
            appointmentTime: stopValues.appointmentTime,
            endAppointmentTime: stopValues.endAppointmentTime,
            specialInstructions: convertCasing(
              stopValues.specialInstructions,
              useAllCaps,
            ),
            terminalUuid: stopValues.terminalUuid ?? null,
            serviceDate: stopValues.serviceDate,
            serviceDateIso8601: stopValues.serviceDateV2?.toString(),
            isSpecial: stopValues.isSpecial ?? undefined,
            destinationAirport: convertCasing(
              stopValues.destinationAirport,
              useAllCaps,
            ),
            hideFromDispatch: stopValues.hideFromDispatch,
            stopType: coalesceStopTypesForBackend(stopValues.stopType),
            status:
              stopValues.status === StopStatus.Completed
                ? StopStatus.Completed
                : undefined,
            transferAddressUpsertInput: isNil(stopValues.transferAddress)
              ? undefined
              : createAddressUpsertInput({
                  address: stopValues.transferAddress,
                  useAllCaps,
                }),
            addressUpsertInput: isNil(address)
              ? {
                  name: '',
                  line1: '',
                  city: '',
                  state: '',
                  zip: '',
                  country: '',
                  uuid: v4(),
                }
              : createAddressUpsertInput({
                  address,
                  useAllCaps,
                }),
            upsertStopDriverMapInputs,
          },
          miles: stopValues.miles,
        },
      ],
      independentSettlementBillLineItemUpsertInputs: ffRobustSettlementEnabled
        ? independentSettlementBillLineItemUpsertInputs
        : undefined,
      totalCharge:
        updateTotalCharge === true ? stopValues.totalCharge : undefined,
    },
  };
};

// Throws an error if the mutation fails
export const executeOrderUpdateFn = async <
  TVars extends Record<string, unknown>,
  TData,
>({
  fn,
  vars,
}: {
  fn: MutationFunction<TData, TVars>;
  vars: Exact<TVars>;
}) => {
  const result = await fn({ variables: vars });
  if (
    result.errors ||
    ((result.data as { __typename?: string })?.__typename ?? '')
      ?.toString()
      .endsWith('ErrorOutput')
  ) {
    throw new Error(`Error executing ${fn.name}`);
  }
  return result;
};

export const updateOrder = async ({
  updateOrderMutation,
  additionalUpdateFns = [],
  orderValues,
  saveSnapshot,
  useAllCaps,
  newBillingStatus,
  ffRobustSettlementEnabled,
  ffLineHaulNetworks,
}: {
  updateOrderMutation: UpdateStandardOrderMutationFn;
  additionalUpdateFns?: OrderUpdateFn[];
  orderValues: OrderFormValues;
  saveSnapshot: (
    uuid: string | undefined,
  ) => Promise<StandardOrderQueryResult | null | undefined>;
  useAllCaps: boolean;
  newBillingStatus?: OrderBillingStatus;
  ffRobustSettlementEnabled: boolean;
  ffLineHaulNetworks: boolean;
}): Promise<{
  res: StandardOrderQueryResult | null | undefined;
  errorMessage: string | null;
}> => {
  try {
    const updateTotalCharge = shouldUpdateTotalCharge({ orderValues });
    const { newComment } = orderValues;
    const packages = orderValues.packages ?? [];
    const stops = orderValues.stops ?? [];

    const isUsingLineHaulDeprecated = orderValues.isUsingLineHaul;
    const useLineHaul = orderValues.enableLineHaul;

    const isUsingLineHaul = ffLineHaulNetworks
      ? useLineHaul
      : isUsingLineHaulDeprecated;

    const {
      expectedInboundShipmentsStatus,
      expectedOutboundShipmentsStatus,
      expectedLineHaulShipmentStatus,
      expectedOrderChargesShipmentStatus,
    } = getExpectedOrderComponentStatus({
      fulfillmentType: orderValues.fulfillmentType,
      inboundStop: orderValues.stops?.[INBOUND_STOP_IDX],
      outboundStop: orderValues.stops?.[OUTBOUND_STOP_IDX],
      lineHaulShipment: orderValues.lineHaulShipment,
      orderChargesShipment: orderValues.orderChargesShipment,
      isUsingLineHaul,
      debugLog: isDevelopment(),
    });

    const inboundStop = stops[INBOUND_STOP_IDX];
    const outboundStop = stops[OUTBOUND_STOP_IDX];
    const inboundStopUpdateInput =
      !isNil(inboundStop) && expectedInboundShipmentsStatus.present
        ? createShipmentArrayUpdateInput({
            stopValues: inboundStop,
            updateTotalCharge,
            useAllCaps,
            ffRobustSettlementEnabled,
          })
        : null;

    const outboundStopUpdateInput =
      !isNil(outboundStop) && expectedOutboundShipmentsStatus.present
        ? createShipmentArrayUpdateInput({
            stopValues: outboundStop,
            updateTotalCharge,
            useAllCaps,
            ffRobustSettlementEnabled,
          })
        : null;

    const shipmentArrayUpdateInputs: StandardShipmentArrayUpdateInput[] = [];
    if (!isNil(inboundStopUpdateInput)) {
      shipmentArrayUpdateInputs.push(inboundStopUpdateInput);
    }
    if (!isNil(outboundStopUpdateInput)) {
      shipmentArrayUpdateInputs.push(outboundStopUpdateInput);
    }

    const packageArrayUpdateInputs = filterNotNil(
      packages.map((packageValues) =>
        createPackageArrayUpdateInput({
          packageValues,
          useAllCaps,
          useCentimeters: orderValues.useCentimeters ?? undefined,
          useKilograms: orderValues.useKilograms ?? undefined,
        }),
      ),
    );
    // line haul shipment if necessary
    const lineHaulShipmentUpdateInput =
      !isNil(orderValues.lineHaulShipment) &&
      expectedLineHaulShipmentStatus.present
        ? createLineHaulShipmentUpdateInput({
            lineHaulShipment: orderValues.lineHaulShipment,
            updateTotalCharge,
          })
        : null;

    if (!isNil(lineHaulShipmentUpdateInput)) {
      shipmentArrayUpdateInputs.push(lineHaulShipmentUpdateInput);
    }

    const orderChargesShipmentUpdateInput =
      !isNil(orderValues.orderChargesShipment) &&
      expectedOrderChargesShipmentStatus.present
        ? createOrderChargesShipmentUpdateInput({
            orderChargesShipmentValues: orderValues.orderChargesShipment,
            updateTotalCharge,
          })
        : null;

    if (!isNil(orderChargesShipmentUpdateInput)) {
      shipmentArrayUpdateInputs.push(orderChargesShipmentUpdateInput);
    }

    const newCommentText =
      convertCasing(newComment?.comment, useAllCaps) ?? null;
    const newCommentUserUuid = newComment?.user?.uuid ?? null;
    const orderCommentCreateInputs: CreateOrderCommentInput[] =
      !isNil(newCommentText) && !isNil(newCommentUserUuid)
        ? [
            {
              comment: newCommentText,
              userUuid: newCommentUserUuid,
              orderUuid: undefined,
            },
          ]
        : [];

    const updateStandardOrderInput: UpdateStandardOrderInput = {
      updatedAt: orderValues.updatedAt,
      orderUpdateInput: {
        uuid: orderValues.uuid,
        billingPartyContactUuid: orderValues.contactUuid,
        billingPartyContactStationId: orderValues.contactStationId,
        codCheckAmountCents: isNil(orderValues.codCheckAmountInDollars)
          ? null
          : safeMultiply(orderValues.codCheckAmountInDollars, 100),
        codCheckNumber: orderValues.codCheckNumber,
        isCollectOnDelivery: orderValues.isCollectOnDelivery,
        deletedAutoAppliedAccessorialUuids:
          orderValues.deletedAutoAppliedAccessorials,
        shipmentArrayUpdateInputs,
        fulfillmentType: orderValues.fulfillmentType,
        tagUuids: orderValues.tags?.map((tag) => tag.uuid),
        thirdPartyBrokerUuids: isNil(orderValues.thirdPartyBrokerUuid)
          ? []
          : [orderValues.thirdPartyBrokerUuid],
        serviceUuid: orderValues.serviceUuid,
        standardOrderFieldsUpdateInput: isNil(orderValues.fieldsUuid)
          ? undefined
          : {
              uuid: orderValues.fieldsUuid,
              numberOfConsignees: NumberOfConsignees.One,
              numberOfShippers: NumberOfShippers.One,
              shipperBillOfLadingNumber: convertCasing(
                orderValues.shipperBillOfLadingNumber,
                useAllCaps,
              ),
              masterAirwayBillOfLadingNumber: convertCasing(
                orderValues.masterAirwayBillOfLadingNumber,
                useAllCaps,
              ),
              dimFactor: orderValues.dimFactor,
            },
        receivedDate: orderValues.receivedDate,
        recurringOrderFrequencyUpdateInput: isNil(
          orderValues.recurringOrderFrequency,
        )
          ? undefined
          : {
              ...orderValues.recurringOrderFrequency,
              repeatIntervalWeeks:
                orderValues.recurringOrderFrequency.repeatInterval,
            },
        refNumbers: isNil(orderValues.refNumbers)
          ? []
          : convertCasingStringArray(orderValues.refNumbers, useAllCaps),
        packageArrayUpdateInputs,
        name: convertCasing(orderValues.name, useAllCaps),
        secondaryRefNumber: convertCasing(
          orderValues.secondaryReferenceNumber,
          useAllCaps,
        ),
        holdReasonUuid: orderValues.holdReasonUuid,
        warehouseUuid: orderValues.warehouseUuid,
        notes: orderValues.notes,
        personName: orderValues.personName,
        personPhoneNumber: orderValues.personPhoneNumber,
        personEmail: orderValues.personEmail,
        vehicleTypeUuid: orderValues.vehicleTypeUuid,
        orderCommentCreateInputs,
        isCrossDock: isCrossDock(
          stops[INBOUND_STOP_IDX]?.stopType ?? StopType.None,
          stops[OUTBOUND_STOP_IDX]?.stopType ?? StopType.None,
        ),
        lineHaulLaneUuid: orderValues.lineHaulLaneUuid,
        chargeableWeightCubicMeters: isNil(
          orderValues.chargeableWeightCubicMeters,
        )
          ? null
          : cubicMeters(orderValues.chargeableWeightCubicMeters),
        totalSkids: orderValues.totalSkids,
        isReweighed: orderValues.isReweighed,
        driverQualificationIds: orderValues.driverQualificationIds,
        inBond: orderValues.inBond,
        hazmat: orderValues.hazmat,
        itTeNumber: convertCasing(orderValues.itTeNumber, useAllCaps),
        externalNotes: convertCasing(orderValues.externalNotes, useAllCaps),
        unNumber: convertCasing(orderValues.unNumber, useAllCaps),
        hazmatDescription: convertCasing(
          orderValues.hazmatDescription,
          useAllCaps,
        ),
        emergencyResponseNumber: isNilOrEmptyString(
          orderValues.emergencyResponseNumber,
        )
          ? null
          : orderValues.emergencyResponseNumber,
        requiresPlacard: orderValues.requiresPlacard,
        hazmatClass: orderValues.hazmatClass,
        packageGroup: orderValues.packageGroup,
        totalCharge: updateTotalCharge ? orderValues.totalCharge : undefined,
        billingStatus: newBillingStatus ?? null,
        refusedBy: orderValues.refusedBy,
        refusedDate: orderValues.refusedDate,
        piecesPicked: orderValues.piecesPicked,
        pieceCount: orderValues.pieceCount,
        pickedDate: orderValues.pickedDate,
        enableLineHaul: orderValues.enableLineHaul,
      },
    };
    const res = await updateOrderMutation({
      variables: {
        updateStandardOrderInput,
      },
    });
    const graphQlError = res?.errors?.[0];
    if (!isNil(graphQlError)) {
      if (graphQlError.extensions?.code === 'BAD_USER_INPUT') {
        return { res: null, errorMessage: graphQlError.message };
      }
      throw new Error(graphQlError.message);
    }
    const updatedOrderUuid = res.data?.updateStandardOrder.uuid;

    for (const { fn, vars } of additionalUpdateFns) {
      await executeOrderUpdateFn({ fn, vars });
    }

    // await this because it relies on fetch order not completing before
    // it since it uses the initialOrder state variable.
    const refetchedOrder = await saveSnapshot(updatedOrderUuid);
    return { res: refetchedOrder, errorMessage: null };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error updating order', error);
    return { res: null, errorMessage: 'Error updating order' };
  }
};

export const createOrderCreateInput = ({
  orderValues,
  packageValues,
  stopValues,
  useNewShipmentCreateInputs,
  useAllCaps,
  ffRobustSettlementEnabled,
  ffLineHaulNetworks,
}: {
  orderValues: OrderFormValues;
  packageValues: PackageValues[];
  stopValues: StopValues[];
  useNewShipmentCreateInputs: boolean;
  useAllCaps: boolean;
  ffRobustSettlementEnabled: boolean;
  ffLineHaulNetworks: boolean;
}): StandardOrderCreateInput => {
  const isUsingLineHaulDeprecated = orderValues.isUsingLineHaul;
  const useLineHaul = orderValues.enableLineHaul;

  const isUsingLineHaul = ffLineHaulNetworks
    ? useLineHaul
    : isUsingLineHaulDeprecated;

  const {
    expectedInboundShipmentsStatus,
    expectedOutboundShipmentsStatus,
    expectedLineHaulShipmentStatus,
    expectedOrderChargesShipmentStatus,
  } = getExpectedOrderComponentStatus({
    fulfillmentType: orderValues.fulfillmentType,
    inboundStop: orderValues.stops?.[INBOUND_STOP_IDX],
    outboundStop: orderValues.stops?.[OUTBOUND_STOP_IDX],
    lineHaulShipment: orderValues.lineHaulShipment,
    orderChargesShipment: orderValues.orderChargesShipment,
    isUsingLineHaul,
    debugLog: isDevelopment(),
  });

  const updateTotalCharge = true; // Should always set total charge when creating an order

  const shipmentCreateInputs = filterNotNil(
    stopValues.map((stop) =>
      createShipmentCreateInput({
        stopValues: stop,
        updateTotalCharge,
        useAllCaps,
        ffRobustSettlementEnabled,
      }),
    ),
  );
  let inboundShipmentCreateInput: StandardShipmentCreateInput | null = null;
  let outboundShipmentCreateInput: StandardShipmentCreateInput | null = null;
  const inboundStop = stopValues.find((stop) =>
    inboundStopTypes.includes(stop.stopType),
  );
  const outboundStop = stopValues.find((stop) =>
    outboundStopTypes.includes(stop.stopType),
  );
  if (!isNil(inboundStop) && expectedInboundShipmentsStatus.present) {
    inboundShipmentCreateInput = createShipmentCreateInput({
      stopValues: inboundStop,
      updateTotalCharge,
      useAllCaps,
      excludeFreightCharge:
        !expectedInboundShipmentsStatus.freightChargePresent,
      ffRobustSettlementEnabled,
    });
  }
  if (!isNil(outboundStop) && expectedOutboundShipmentsStatus.present) {
    outboundShipmentCreateInput = createShipmentCreateInput({
      stopValues: outboundStop,
      updateTotalCharge,
      useAllCaps,
      excludeFreightCharge:
        !expectedOutboundShipmentsStatus.freightChargePresent,
      ffRobustSettlementEnabled,
    });
  }

  // line haul shipment if necessary
  const lineHaulShipmentCreateInput =
    !isNil(orderValues.lineHaulShipment) &&
    expectedLineHaulShipmentStatus.present
      ? createLineHaulShipmentCreateInput({
          lineHaulShipment: orderValues.lineHaulShipment,
          updateTotalCharge,
        })
      : null;

  if (!isNil(lineHaulShipmentCreateInput)) {
    shipmentCreateInputs.push(lineHaulShipmentCreateInput);
  }

  const orderChargesShipmentCreateInput =
    !isNil(orderValues.orderChargesShipment) &&
    expectedOrderChargesShipmentStatus.present
      ? createOrderChargesShipmentCreateInput({
          orderChargesShipmentValues: orderValues.orderChargesShipment,
          updateTotalCharge,
        })
      : null;

  if (!isNil(orderChargesShipmentCreateInput)) {
    shipmentCreateInputs.push(orderChargesShipmentCreateInput);
  }

  const packageCreateInputs = filterNotNil(
    packageValues.map((package_) =>
      createPackageCreateInput({
        packageValues: package_,
        useAllCaps,
        useCentimeters: orderValues.useCentimeters ?? undefined,
        useKilograms: orderValues.useKilograms ?? undefined,
      }),
    ),
  );

  const orderCommentCreateInputs: CreateOrderCommentInput[] = filterNotNil(
    orderValues.orderComments?.map((comment) => {
      const userUuid = comment?.user?.uuid;
      if (isNil(userUuid)) {
        return null;
      }
      return {
        comment: comment?.comment ?? '',
        userUuid,
        orderUuid: undefined,
      };
    }) ?? [],
  );

  const orderCreateInput: StandardOrderCreateInput = {
    uuid: orderValues.uuid,
    codCheckAmountCents: isNil(orderValues.codCheckAmountInDollars)
      ? undefined
      : safeMultiply(orderValues.codCheckAmountInDollars, 100),
    codCheckNumber: orderValues.codCheckNumber,
    isCollectOnDelivery: orderValues.isCollectOnDelivery,
    deletedAutoAppliedAccessorialUuids:
      orderValues.deletedAutoAppliedAccessorials,
    orderSegmentType: orderValues.orderSegmentType,
    tagUuids: orderValues.tags?.map((tag) => tag.uuid) ?? [],
    thirdPartyBrokerUuids: isNil(orderValues.thirdPartyBrokerUuid)
      ? []
      : [orderValues.thirdPartyBrokerUuid],
    fulfillmentType: orderValues.fulfillmentType,
    billingPartyContactUuid: orderValues.contactUuid,
    billingPartyContactStationId: orderValues.contactStationId,
    source: isNil(orderValues.recurringOrderFrequency)
      ? OrderSource.ManuallyEntered
      : OrderSource.IsTemplate,
    shipmentCreateInputs: useNewShipmentCreateInputs
      ? null
      : shipmentCreateInputs,
    inboundShipmentCreateInput: useNewShipmentCreateInputs
      ? inboundShipmentCreateInput
      : null,
    outboundShipmentCreateInput: useNewShipmentCreateInputs
      ? outboundShipmentCreateInput
      : null,
    lineHaulShipmentCreateInput: useNewShipmentCreateInputs
      ? lineHaulShipmentCreateInput
      : null,
    orderChargesShipmentCreateInput: useNewShipmentCreateInputs
      ? orderChargesShipmentCreateInput
      : null,
    status: orderValues.status,
    onHand: orderValues.onHand,
    dateMarkedOnHand: orderValues.receivedDate,
    receivedDate: orderValues.receivedDate,
    dateMarkedReceivedAtOrigin: orderValues.receivedAtOriginDate,
    serviceUuid: orderValues.serviceUuid,
    standardOrderFieldsCreateInput: {
      numberOfConsignees: NumberOfConsignees.One,
      numberOfShippers: NumberOfShippers.One,
      shipperBillOfLadingNumber: convertCasing(
        orderValues.shipperBillOfLadingNumber,
        useAllCaps,
      ),
      masterAirwayBillOfLadingNumber: convertCasing(
        orderValues.masterAirwayBillOfLadingNumber,
        useAllCaps,
      ),
      dimFactor: orderValues.dimFactor,
    },
    recurringOrderFrequencyCreateInput: isNil(
      orderValues.recurringOrderFrequency,
    )
      ? null
      : {
          ...omit(orderValues.recurringOrderFrequency, ['uuid']),
          repeatIntervalWeeks:
            orderValues.recurringOrderFrequency.repeatInterval,
        },
    refNumbers: isNil(orderValues.refNumbers)
      ? []
      : convertCasingStringArray(orderValues.refNumbers, useAllCaps),
    secondaryRefNumber: convertCasing(
      orderValues.secondaryReferenceNumber,
      useAllCaps,
    ),
    packageCreateInputs,
    name: convertCasing(orderValues.name, useAllCaps),
    holdReasonUuid: orderValues.holdReasonUuid,
    warehouseUuid: orderValues.warehouseUuid,
    notes: orderValues.notes,
    personName: orderValues.personName,
    personPhoneNumber: orderValues.personPhoneNumber,
    personEmail: orderValues.personEmail,
    vehicleTypeUuid: orderValues.vehicleTypeUuid,
    orderCommentCreateInputs,
    isCrossDock: isCrossDock(
      stopValues[INBOUND_STOP_IDX]?.stopType ?? StopType.None,
      stopValues[OUTBOUND_STOP_IDX]?.stopType ?? StopType.None,
    ),
    enableLinehaul: orderValues.enableLineHaul,
    lineHaulLaneUuid: orderValues.isUsingLineHaul
      ? orderValues.lineHaulLaneUuid
      : null,
    chargeableWeightCubicMeters: isNil(orderValues.chargeableWeightCubicMeters)
      ? null
      : cubicMeters(orderValues.chargeableWeightCubicMeters),
    totalSkids: orderValues.totalSkids,
    driverQualificationIds: orderValues.driverQualificationIds,
    inBond: orderValues.inBond,
    hazmat: orderValues.hazmat,
    itTeNumber: convertCasing(orderValues.itTeNumber, useAllCaps),
    externalNotes: convertCasing(orderValues.externalNotes, useAllCaps),
    unNumber: convertCasing(orderValues.unNumber, useAllCaps),
    hazmatDescription: convertCasing(orderValues.hazmatDescription, useAllCaps),
    emergencyResponseNumber: isNilOrEmptyString(
      orderValues.emergencyResponseNumber,
    )
      ? null
      : orderValues.emergencyResponseNumber,
    requiresPlacard: orderValues.requiresPlacard,
    hazmatClass: orderValues.hazmatClass,
    packageGroup: orderValues.packageGroup,
    totalCharge: orderValues.totalCharge,
  };

  return orderCreateInput;
};

// Creates order
export const saveOrder = async ({
  timingData,
  createOrderMutation,
  orderValues,
  packageValues,
  stopValues,
  fetchOrder,
  refetchOrderAfterSave,
  useNewShipmentCreateInputs,
  ffRobustSettlementEnabled,
  ffLineHaulNetworks,
  useAllCaps,
}: {
  timingData?: TimingData | undefined;
  createOrderMutation: CreateStandardOrderMutationFn;
  orderValues: OrderFormValues;
  packageValues: PackageValues[];
  stopValues: StopValues[];
  fetchOrder?: ({
    uuid,
    refetchOrderAfterSave,
  }: {
    uuid: string;
    refetchOrderAfterSave?: boolean;
  }) => Promise<void>;
  refetchOrderAfterSave?: boolean;
  useNewShipmentCreateInputs: boolean;
  ffRobustSettlementEnabled: boolean;
  ffLineHaulNetworks: boolean;
  useAllCaps: boolean;
}) => {
  const orderCreateInput = createOrderCreateInput({
    orderValues,
    packageValues,
    stopValues,
    useNewShipmentCreateInputs,
    useAllCaps,
    ffRobustSettlementEnabled,
    ffLineHaulNetworks,
  });

  const res = await createOrderMutation({
    variables: {
      createStandardOrderInput: { orderCreateInput, timingData },
    },
  });
  const uuid = res.data?.createStandardOrder.uuid;
  if (!isNil(uuid) && !isNil(fetchOrder)) {
    // DON'T REMOVE THE AWAIT FROM THIS FETCH because this function has side effects
    // specifically it hydrates the form. If something is called after this callback
    // that could be overwritten by the form hydration
    await fetchOrder({ uuid, refetchOrderAfterSave });
  }
  return res;
};

export function calculateCustomChargeTotal({
  customCharges,
}: {
  customCharges: CustomChargeValues[];
}) {
  let total = currency(0);
  for (const customCharge of customCharges) {
    total = total.add(customCharge.totalCharge ?? 0);
  }
  return total.value;
}

export function calculateTotalCharge({
  freightChargeTotal,
  fuelChargeTotal,
  customCharges,
}: {
  freightChargeTotal?: number | null;
  fuelChargeTotal?: number | null;
  customCharges: CustomChargeValues[];
}) {
  const customChargeTotal = calculateCustomChargeTotal({ customCharges });
  return addMany([
    freightChargeTotal ?? 0,
    fuelChargeTotal ?? 0,
    customChargeTotal,
  ]);
}

export function calculateLineHaulShipmentTotalCharge({
  freightChargeTotal,
  fuelChargeTotal,
}: {
  freightChargeTotal: number;
  fuelChargeTotal: number;
}) {
  return addMany([freightChargeTotal, fuelChargeTotal]);
}

export function calculateOrderTotalCharge({
  stopChargesTotal,
  lineHaulChargeTotal,
  orderChargesTotal,
}: {
  stopChargesTotal: number;
  lineHaulChargeTotal: number;
  orderChargesTotal: number;
}) {
  return addMany([stopChargesTotal, lineHaulChargeTotal, orderChargesTotal]);
}

export const convertOrderStatusToOrderBillingStatus = (
  orderStatus: OrderStatus | null | undefined,
): OrderBillingStatus => {
  if (isNil(orderStatus)) {
    return OrderBillingStatus.NotReadyToInvoice;
  }
  switch (orderStatus) {
    case OrderStatus.Cancelled:
    case OrderStatus.Created:
    case OrderStatus.InProgress:
    case OrderStatus.Delivered:
    case OrderStatus.OnHold: {
      return OrderBillingStatus.NotReadyToInvoice;
    }
    case OrderStatus.Invoiced:
    case OrderStatus.Finalized: {
      return OrderBillingStatus.ReadyToInvoice;
    }
    case OrderStatus.HasIssue: {
      return OrderBillingStatus.HasIssues;
    }
    default: {
      return exhaustive(orderStatus);
    }
  }
};

export const convertOrderBillingStatusToOrderStatus = (
  orderBillingStatus: OrderBillingStatus,
): OrderStatus => {
  switch (orderBillingStatus) {
    case OrderBillingStatus.NotReadyToInvoice: {
      return OrderStatus.Delivered;
    }
    case OrderBillingStatus.ReadyToInvoice: {
      return OrderStatus.Finalized;
    }
    case OrderBillingStatus.HasIssues: {
      return OrderStatus.HasIssue;
    }
    default: {
      return exhaustive(orderBillingStatus);
    }
  }
};

export const addressIsEmpty = (
  address: AddressValues | null | undefined,
): boolean => {
  if (isNil(address)) {
    return true;
  }
  return (
    isEmpty(address.line1) &&
    isEmpty(address.line2) &&
    isEmpty(address.name) &&
    isEmpty(address.state) &&
    isEmpty(address.zip) &&
    isEmpty(address.longitude) &&
    isEmpty(address.specialInstructions) &&
    isEmpty(address.internalNotes)
  );
};
