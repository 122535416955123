import { isNil } from 'lodash';
import { useMemo } from 'react';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import {
  type GetOrderTableFieldValuesInput,
  type MeAsThirdPartyUserQuery,
  OrderSource,
  useGetOrderTableFieldValuesQuery,
} from '../../../../../generated/graphql';
import useContactsForMeAsThirdPartyUser from '../../../hooks/use-contacts-for-me-as-third-party-user';
import { type FindBookingRequestsFiltersInput } from '../booking-request-filters';
import { CUSTOMER_PORTAL_BOOKING_REQUESTS_TABLE_FIELDS } from '../constants';

type GetCustomerPortalBookingRequestsFiltersParams = {
  contacts: NonNullable<
    MeAsThirdPartyUserQuery['meAsThirdPartyUser']
  >['contacts'];
  ffWhiteLabelCustomerPortal: boolean;
  filters: FindBookingRequestsFiltersInput;
};

export const getCustomerPortalBookingRequestsFilters = ({
  contacts,
  ffWhiteLabelCustomerPortal,
  filters,
}: GetCustomerPortalBookingRequestsFiltersParams) => {
  // When ffWhiteLabelCustomerPortal is enabled, filtering on the selected
  // client ID happens automatically on the backend.
  const customerUuidFilter = ffWhiteLabelCustomerPortal
    ? {}
    : {
        customerUuidFilter: {
          in: contacts.map((c) => c.uuid),
        },
      };
  const baseFilters = {
    orderSourceFilter: {
      eq: OrderSource.CustomerPortal,
    },
    ...customerUuidFilter,
    ...filters,
  } satisfies GetOrderTableFieldValuesInput['filters'];
  // By default, the backend implicitly filters out draft orders unless the caller
  // provides a customerPortalOrderReviewStatusFilter. Since we're in the customer
  // portal, we still want to see draft orders even if we don't provide a
  // customerPortalOrderReviewStatusFilter.
  if (isNil(baseFilters.customerPortalOrderReviewStatusFilter)) {
    return {
      ...baseFilters,
      isDraftOrderFilter: {
        eq: true,
      },
    };
  }
  return baseFilters;
};

type CustomerPortalBookingRequestsVariables = Pick<
  GetOrderTableFieldValuesInput,
  'first' | 'last' | 'after' | 'before' | 'searchText'
> & {
  filters: FindBookingRequestsFiltersInput;
};

/**
 * Wraps the getOrderTableFieldValues query with a fixed set of filters and
 * fields to fetch pending customer portal orders.
 */
export const useCustomerPortalBookingRequests = ({
  first,
  last,
  after,
  before,
  searchText,
  filters: findBookingRequestsFiltersInput,
  ...queryHookOptions
}: CustomerPortalBookingRequestsVariables) => {
  const {
    contacts: contactsWithOrderEntryEnabled,
    loading: meAsThirdPartyUserLoading,
  } = useContactsForMeAsThirdPartyUser({
    filterByCustomerPortalOrderEntryEnabled: true,
  });

  const ffWhiteLabelCustomerPortal = useFeatureFlag(
    FeatureFlag.FF_WHITE_LABEL_CUSTOMER_PORTAL,
  );

  const filters = useMemo(
    () =>
      getCustomerPortalBookingRequestsFilters({
        contacts: contactsWithOrderEntryEnabled,
        ffWhiteLabelCustomerPortal,
        filters: findBookingRequestsFiltersInput,
      }),
    [
      contactsWithOrderEntryEnabled,
      findBookingRequestsFiltersInput,
      ffWhiteLabelCustomerPortal,
    ],
  );

  const ffEnableNewTableFunctions = useFeatureFlag(
    FeatureFlag.FF_ENABLE_NEW_TABLE_FUNCTIONS,
  );

  const queryResult = useGetOrderTableFieldValuesQuery({
    variables: {
      getOrderTableFieldValuesInput: {
        ...(ffEnableNewTableFunctions ? { filters } : filters),
        searchText,
        orderTableFields: CUSTOMER_PORTAL_BOOKING_REQUESTS_TABLE_FIELDS,
        totalCount: true,
        first,
        last,
        after,
        before,
      },
    },
    ...queryHookOptions,
    // Wait for contacts to load before fetching orders
    skip: meAsThirdPartyUserLoading,
    fetchPolicy: 'cache-and-network',
  });

  return queryResult;
};
