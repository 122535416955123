import { useState } from 'react';
import { useGetSettlementBillLineItems } from '../hooks/use-get-settlement-bill-line-items';
import { Button, CircularProgress, TablePagination } from '@mui/material';
import { Stack, TextField } from '@mui/material';
import SendToSettlementsModal from './send-to-settlements-modal';
import { SettlementBillLineItemsTableMode } from '../enums';
import PalletDateRangePicker from '../../../pallet-ui/date-range-picker/pallet-date-range-picker';
import DriverFilterButton from '../../../common/components/driver-filter-button';
import { SettlementBillLineItemsTable } from './settlement-bill-line-items-table';
import pluralize from 'pluralize';
import {
  DEFAULT_PAGE_SIZE,
  SETTLEMENT_BILL_LINE_ITEMS_ROW_PER_PAGE_OPTIONS,
} from '../contants';

const SettlementBillLineItemsTab = () => {
  const {
    refresh,
    selectedSettlementBillLineItemIds,
    setSelectedSettlementBillLineItemIds,
    driverOptions,
    rangeStart,
    rangeEnd,
    totalCount,
    page,
    rowsPerPage,
    handlePageChange,
    handleRowsPerPageChange,
    handleDriverOptionsChange,
    handleRangeChange,
    loadingSettlementBillLineItems,
    settlementBillLineItems,
    searchText,
    handleSearchTextChange,
  } = useGetSettlementBillLineItems();

  const [sendingToSettlements, setSendingToSettlements] = useState(false);

  const onSelectAllCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.checked) {
      setSelectedSettlementBillLineItemIds(
        new Set(settlementBillLineItems.map((item) => item.id)),
      );
    } else {
      setSelectedSettlementBillLineItemIds(new Set());
    }
  };

  const onCheckboxChange = (settlementBillLineItemId: string) => {
    setSelectedSettlementBillLineItemIds((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(settlementBillLineItemId)) {
        newSet.delete(settlementBillLineItemId);
      } else {
        newSet.add(settlementBillLineItemId);
      }
      return newSet;
    });
  };

  const [currentSearchText, setCurrentSearchText] =
    useState<string>(searchText);

  const handleSearch = () => {
    handleSearchTextChange(currentSearchText);
  };

  return (
    <Stack direction="column" height="100%" spacing={1}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        px={1}
        pt={1}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <DriverFilterButton
            selectedOptionsMultiselect={driverOptions}
            handleChangeMultiselect={handleDriverOptionsChange}
            prefixText="Driver"
          />

          <PalletDateRangePicker
            isClearable
            value={[rangeStart, rangeEnd]}
            label="Completed date"
            isRequired={false}
            sx={{ width: '225px' }}
            onAccept={handleRangeChange}
          />

          <TextField
            size="small"
            placeholder="Search"
            value={currentSearchText}
            sx={{ width: '200px' }}
            onChange={(e) => {
              setCurrentSearchText(e.target.value);
            }}
            onBlur={handleSearch}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSearch();
              }
            }}
          />
        </Stack>
        <Stack direction="row" spacing={1} alignItems="center">
          <Button
            variant="contained"
            disabled={selectedSettlementBillLineItemIds.size === 0}
            onClick={() => {
              setSendingToSettlements(true);
            }}
          >
            Send to{' '}
            {pluralize('settlement', selectedSettlementBillLineItemIds.size)}{' '}
            {selectedSettlementBillLineItemIds.size > 0 &&
              `(${selectedSettlementBillLineItemIds.size})`}
          </Button>
          <TablePagination
            rowsPerPageOptions={SETTLEMENT_BILL_LINE_ITEMS_ROW_PER_PAGE_OPTIONS}
            labelRowsPerPage="Show"
            component="div"
            count={totalCount}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={async (_, newPage) => {
              if (newPage === page) {
                return;
              }

              await handlePageChange({ newPage, forward: newPage > page });
            }}
            onRowsPerPageChange={async (event) => {
              const pageSize = Number(event.target.value);

              await (Number.isNaN(pageSize)
                ? handleRowsPerPageChange(DEFAULT_PAGE_SIZE)
                : handleRowsPerPageChange(pageSize));
            }}
          />
        </Stack>
      </Stack>
      {loadingSettlementBillLineItems ? (
        <CircularProgress />
      ) : (
        <SettlementBillLineItemsTable
          mode={SettlementBillLineItemsTableMode.LINE_ITEMS_TABLE}
          settlementBillLineItems={settlementBillLineItems}
          refresh={refresh}
          selectedSettlementBillLineItemIds={selectedSettlementBillLineItemIds}
          onSelectAllCheckboxChange={onSelectAllCheckboxChange}
          onCheckboxChange={onCheckboxChange}
        />
      )}
      <SendToSettlementsModal
        open={sendingToSettlements}
        selectedSettlementBillLineItemIds={selectedSettlementBillLineItemIds}
        settlementBillLineItems={settlementBillLineItems}
        refresh={refresh}
        onClose={() => {
          setSendingToSettlements(false);
        }}
      />
    </Stack>
  );
};

export default SettlementBillLineItemsTab;
