import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty, isNil } from 'lodash';
import pluralize from 'pluralize';
import { exhaustive } from 'shared/switch';
import { objectKeys } from 'tsafe';
import { v4 } from 'uuid';
import {
  type ValidationResponse,
  validateEmail,
  validateNotEmpty,
  validateString,
} from '../../../common/form/formValidators';
import {
  type AddressCreateInput,
  BillingCycleDuration,
  type ContactPersonCreateInput,
  type ContactQuery,
  type CustomerContactCreateInput,
  type CustomerContactUpdateInput,
} from '../../../generated/graphql';
import { type RootState } from '../../../redux/store';
import {
  type AddressFormField,
  addAddress,
  removeOneAddress,
  selectAddressById,
} from '../../addresses/redux/addresses-values-slice';
import {
  addressIsEmpty,
  createAddressCreateInput,
  getAddressErrorsResponse,
  upsertAddressValuesThunk,
} from '../../addresses/redux/addresses-values-thunks';
import {
  contactPersonIsEmpty,
  createContactPersonCreateInput,
  upsertContactPersonValuesThunk,
  validateContactPersonThunk,
} from '../../contact-persons/redux/contact-person-values-thunks';
import {
  type ContactPersonFormField,
  addContactPerson,
  removeOneContactPerson,
  selectContactPersonById,
} from '../../contact-persons/redux/contact-persons-values-slice';
import ContactPageMode from '../components/contact-page-mode';
import { NONE_SERVICE } from '../contants';
import { upsertOneContactErrors } from './contact-errors-slice';
import {
  type ContactValues,
  addOneContactValues,
  selectContactValuesById,
  selectContacts,
  updateOneContactValues,
  upsertOneContactValues,
} from './contact-values-slice';

type AddOneContactValuesThunkArg = {
  data: ContactQuery;
};

/**
 * Take the data from the contact query and store it in redux
 */
export const upsertOneContactValuesThunk = createAsyncThunk<
  ContactValues,
  AddOneContactValuesThunkArg,
  { state: RootState }
>(
  'contactValues/addOneContactValuesThunk',
  async (arg, thunkAPI): Promise<ContactValues> => {
    const contactValue: ContactValues = {
      addressUuids: arg.data.contact.addresses.map((address) => address.uuid),
      billingContactEmail:
        arg.data.contact.__typename === 'CustomerContactEntity'
          ? arg.data.contact.billingContactEmail
          : undefined,
      contactPersonUuids: arg.data.contact.contactPersons.map(
        (contactPerson) => contactPerson.uuid,
      ),
      defaultAddressUuid: arg.data.contact.defaultAddress?.uuid,
      contactComments: arg.data.contact.contactComments,
      defaultContactPersonUuid: arg.data.contact.defaultContactPerson?.uuid,
      displayName: arg.data.contact.displayName,
      ediApplicationId: arg.data.contact.ediApplicationId,
      ediContactCode: arg.data.contact.ediContactCode,
      identifier: arg.data.contact.identifier,
      isaId: arg.data.contact.isaId,
      contactReferenceNumber: arg.data.contact.contactReferenceNumber,
      isActive: arg.data.contact.isActive,
      shortCode: arg.data.contact.shortCode,
      stediPartnerId: arg.data.contact.stediPartnerId,
      masterAccountUuid: arg.data.contact.masterAccount?.uuid,
      thirdPartyContactType: undefined,
      type: arg.data.contact.type,
      uuid: arg.data.contact.uuid,
      serviceUuids: arg.data.contact.services.map((service) => service.uuid),
      showEdiReferenceNumbersOnOrderPage:
        arg.data.contact.__typename === 'CustomerContactEntity'
          ? arg.data.contact.showEdiReferenceNumbersOnOrderPage
          : undefined,
      defaultFuelSurcharge:
        arg.data.contact.__typename === 'CustomerContactEntity'
          ? arg.data.contact.defaultFuelSurcharge
          : undefined,
      defaultUseTariff:
        arg.data.contact.__typename === 'CustomerContactEntity'
          ? arg.data.contact.defaultUseTariff
          : undefined,
      defaultDimFactor:
        arg.data.contact.__typename === 'CustomerContactEntity'
          ? arg.data.contact.defaultDimFactor
          : undefined,
      requireDriverToCheckOffPackagesAtStop:
        arg.data.contact.requireDriverToCheckOffPackagesAtStop,
      defaultServiceUuid:
        arg.data.contact.__typename === 'CustomerContactEntity'
          ? arg.data.contact.defaultService?.uuid
          : undefined,
      defaultInboundEdiServiceUuid:
        arg.data.contact.__typename === 'CustomerContactEntity'
          ? arg.data.contact.defaultInboundEdiService?.uuid
          : undefined,
      defaultOutboundEdiServiceUuid:
        arg.data.contact.__typename === 'CustomerContactEntity'
          ? arg.data.contact.defaultOutboundEdiService?.uuid
          : undefined,
      defaultInboundOutboundEdiServiceUuid:
        arg.data.contact.__typename === 'CustomerContactEntity'
          ? arg.data.contact.defaultInboundOutboundEdiService?.uuid
          : undefined,
      businessDivisionUuid:
        arg.data.contact?.businessDivision?.uuid ?? undefined,
      isPrepaidOnly:
        arg.data.contact.__typename === 'CustomerContactEntity'
          ? arg.data.contact.isPrepaidOnly
          : false,
      enableCustomerPortalOrderEntry:
        arg.data.contact.__typename === 'CustomerContactEntity'
          ? arg.data.contact.enableCustomerPortalOrderEntry
          : false,
      useCustomPackageSpecs:
        arg.data.contact.__typename === 'CustomerContactEntity'
          ? arg.data.contact.useCustomPackageSpecs
          : false,
      packageSpecIds:
        arg.data.contact.__typename === 'CustomerContactEntity'
          ? arg.data.contact.packageSpecs.map((packageSpec) => packageSpec.id)
          : [],
      useCustomVehicleTypes:
        arg.data.contact.__typename === 'CustomerContactEntity'
          ? arg.data.contact.useCustomVehicleTypes
          : false,
      vehicleTypeUuids:
        arg.data.contact.__typename === 'CustomerContactEntity'
          ? arg.data.contact.vehicleTypes.map((vehicleType) => vehicleType.uuid)
          : [],
      referenceNumberLabels: isEmpty(arg.data.contact.referenceNumberLabels)
        ? ['']
        : arg.data.contact.referenceNumberLabels.map(
            (referenceNumberLabel) => referenceNumberLabel.name,
          ),
      defaultOrdinaryTariffChainId:
        arg.data.contact.__typename === 'CustomerContactEntity'
          ? (arg.data.contact.defaultOrdinaryTariffChain?.id ?? null)
          : null,
      defaultLineHaulTariffChainId:
        arg.data.contact.__typename === 'CustomerContactEntity'
          ? (arg.data.contact.defaultLineHaulTariffChain?.id ?? null)
          : null,
      defaultTransferTariffChainId:
        arg.data.contact.__typename === 'CustomerContactEntity'
          ? (arg.data.contact.defaultTransferTariffChain?.id ?? null)
          : null,
      defaultPointToPointTariffChainId:
        arg.data.contact.__typename === 'CustomerContactEntity'
          ? (arg.data.contact.defaultPointToPointTariffChain?.id ?? null)
          : null,
    };
    thunkAPI.dispatch(upsertOneContactValues(contactValue));
    await Promise.all(
      arg.data.contact.addresses.map(async (address) => {
        await thunkAPI.dispatch(upsertAddressValuesThunk({ address }));
      }),
    );
    await Promise.all(
      arg.data.contact.contactPersons.map(async (contactPerson) => {
        await thunkAPI.dispatch(
          upsertContactPersonValuesThunk({ contactPerson }),
        );
      }),
    );
    if (!isNil(arg.data.contact.defaultAddress)) {
      await thunkAPI.dispatch(
        upsertAddressValuesThunk({
          address: arg.data.contact.defaultAddress,
        }),
      );
    }
    if (!isNil(arg.data.contact.defaultContactPerson)) {
      await thunkAPI.dispatch(
        upsertContactPersonValuesThunk({
          contactPerson: arg.data.contact.defaultContactPerson,
        }),
      );
    }

    return contactValue;
  },
);

export const initNewContactValues = createAsyncThunk<
  ContactValues,
  void,
  { state: RootState }
>('contactValues/initNewContactValues', async (arg, thunkAPI) => {
  const defaultAddress: AddressFormField = {
    isLocal: true,
    uuid: v4(),
  };
  thunkAPI.dispatch(addAddress(defaultAddress));
  const defaultContactPerson: ContactPersonFormField = {
    uuid: v4(),
  };
  thunkAPI.dispatch(addContactPerson(defaultContactPerson));
  const contactValue: ContactValues = {
    defaultAddressUuid: defaultAddress.uuid,
    defaultContactPersonUuid: defaultContactPerson.uuid,
    addressUuids: [],
    billingContactEmail: undefined,
    contactPersonUuids: [],
    displayName: undefined,
    identifier: undefined,
    isActive: undefined,
    shortCode: undefined,
    masterAccountUuid: undefined,
    thirdPartyContactType: undefined,
    billingRunStart: new Date(),
    uuid: v4(),
    serviceUuids: [],
    defaultServiceUuid: undefined,
    defaultInboundEdiServiceUuid: undefined,
    defaultOutboundEdiServiceUuid: undefined,
    defaultInboundOutboundEdiServiceUuid: undefined,
    businessDivisionUuid: undefined,
    isPrepaidOnly: false,
    enableCustomerPortalOrderEntry: false,
    useCustomPackageSpecs: false,
    packageSpecIds: [],
    useCustomVehicleTypes: false,
    vehicleTypeUuids: [],
    referenceNumberLabels: [''],
    defaultOrdinaryTariffChainId: null,
    defaultLineHaulTariffChainId: null,
    defaultTransferTariffChainId: null,
    defaultPointToPointTariffChainId: null,
  };
  thunkAPI.dispatch(addOneContactValues(contactValue));

  return contactValue;
});

/**
 * @field mode - We validate the default address / contact differently during creation vs update
 */
type ValidateContactArg = {
  contactUuid: string;
  mode: ContactPageMode;
};

export const validateContact = createAsyncThunk<
  boolean,
  ValidateContactArg,
  { state: RootState }
>('contactValues/validateContactThunk', async (arg, thunkAPI) => {
  const contactValues = selectContactValuesById(
    thunkAPI.getState(),
    arg.contactUuid,
  );
  if (isNil(contactValues)) {
    throw new Error(`Invalid contact uuid: ${arg.contactUuid}`);
  }
  let isValid = true;
  await Promise.all(
    objectKeys(contactValues).map(async (field) => {
      let validationResponse: ValidationResponse | null | undefined;
      switch (field) {
        case 'billingContactEmail': {
          validationResponse = validateEmail(contactValues[field], true);
          break;
        }
        case 'displayName': {
          validationResponse = validateString(contactValues[field], true);
          break;
        }
        case 'identifier': {
          validationResponse = validateNotEmpty(contactValues.identifier, true);
          if (!validationResponse.valid) {
            break;
          }
          const allContactValues = selectContacts(thunkAPI.getState());
          const duplicateContactIdentifiers = allContactValues.filter(
            (contact) =>
              contact.uuid !== arg.contactUuid &&
              contactValues[field] === contact.identifier,
          );
          validationResponse =
            duplicateContactIdentifiers.length > 0
              ? {
                  valid: false,
                  explanation: `${duplicateContactIdentifiers.length} other ${pluralize('contact', duplicateContactIdentifiers.length)} ${pluralize('has', duplicateContactIdentifiers.length)} the identifier ${contactValues[field]}: ${duplicateContactIdentifiers.map((contact) => contact.displayName).join(', ')}`,
                }
              : {
                  valid: true,
                  explanation: null,
                };
          break;
        }
        case 'contactReferenceNumber': {
          break;
        }
        case 'isActive': {
          break;
        }
        case 'shortCode': {
          break;
        }
        case 'thirdPartyContactType': {
          break;
        }
        case 'type': {
          break;
        }
        case 'uuid': {
          break;
        }
        case 'defaultContactPersonUuid': {
          // Don't validate in the EDIT case since it comes from a select dropdown assume its valid
          const defaultContactPersonUuid = contactValues[field];
          if (
            arg.mode === ContactPageMode.CREATE &&
            !isNil(defaultContactPersonUuid)
          ) {
            validationResponse = await thunkAPI
              .dispatch(
                validateContactPersonThunk({
                  allowEmpty: true,
                  contactPersonId: defaultContactPersonUuid,
                }),
              )
              .unwrap();
          }
          break;
        }
        case 'defaultAddressUuid': {
          // Don't validate in the EDIT case since it comes from a select dropdown assume its valid
          const defaultAddressUuid = contactValues[field];
          if (
            arg.mode === ContactPageMode.CREATE &&
            !isNil(defaultAddressUuid)
          ) {
            const addressErrorsResponse = await thunkAPI
              .dispatch(
                getAddressErrorsResponse({
                  allowEmpty: true,
                  addressId: defaultAddressUuid,
                }),
              )
              .unwrap();
            isValid &&= addressErrorsResponse.isValid;
          }
          break;
        }
        case 'contactComments':
        case 'addressUuids':
        case 'contactPersonUuids':
        case 'ediPartnership':
        case 'serviceUuids':
        case 'defaultFuelSurcharge':
        case 'defaultUseTariff':
        case 'defaultDimFactor':
        case 'ediApplicationId':
        case 'billingRunStart':
        case 'apiName':
        case 'fuelChargeQuickbooksMappingId':
        case 'freightChargeQuickbooksMappingId':
        case 'quickbooksMappingId':
        case 'showChargesOnCustomerPortal':
        case 'billingPeriod':
        case 'billingCycle':
        case 'orders':
        case 'standardCarrierAlphaCode':
        case 'miscCustomChargeQuickbooksMappingId':
        case 'isaId':
        case 'stediPartnerId':
        case 'customerTariffs':
        case 'tariffZones':
        case 'services':
        case 'invoicePdfColumns':
        case 'numberOfOrdersPerInvoice':
        case 'invoiceTimePeriodSplit':
        case 'contactStatistics':
        case 'billingPeriodDates':
        case 'quickbooksFileMappingCustomerName':
        case 'quickbooksFileMappingFreightChargeName':
        case 'quickbooksFileMappingFuelChargeName':
        case 'quickbooksFileMappingPickupChargeName':
        case 'skipFuelCharge':
        case 'requireDriverToCheckOffPackagesAtStop':
        case 'isEfm':
        case 'defaultEfmHandlingStation':
        case 'isOverCreditLimit':
        case 'unappliedPayments':
        case 'unappliedCredits':
        case 'averageDaysToPay90':
        case 'averageDaysToPay180':
        case 'averageDaysToPay270':
        case 'averageDaysToPay360':
        case 'averageDaysToPay720':
        case 'averageDaysToPayAll':
        case 'unappliedDebits':
        case 'totalPayments':
        case 'totalDebits':
        case 'totalCredits':
        case 'contactOpenInvoiceValue':
        case 'postedInvoicesTotalOpenBalance':
        case 'postedInvoicesTotalClosedBalance':
        case 'balance':
        case 'defaultServiceUuid':
        case 'defaultInvoiceTerms':
        case 'businessDivision':
        case 'businessDivisionUuid':
        case 'quickbooksDesktopContactId':
        case 'contactOpenInvoiceValue0to30d':
        case 'contactOpenInvoiceValue31to38d':
        case 'contactOpenInvoiceValue39to45d':
        case 'contactOpenInvoiceValue46to60d':
        case 'contactOpenInvoiceValue61to75d':
        case 'contactOpenInvoiceValueOver75d':
        case 'totalInvoicesAmount':
        case 'totalPostedInvoicesAmount':
        case 'billingCycleDuration':
        case 'overdueInvoices':
        case 'company':
        case 'ediContactCode':
        case 'isPrepaidOnly':
        case 'enableCustomerPortalOrderEntry':
        case 'invoiceNotes':
        case 'masterAccountUuid':
        case 'useCustomPackageSpecs':
        case 'packageSpecIds':
        case 'useCustomVehicleTypes':
        case 'vehicleTypeUuids':
        case 'quickbooksDesktopAccountsReceivableId':
        case 'referenceNumberLabels':
        case 'paymentsAppliedToContactInvoices':
        case 'creditsAppliedToContactInvoices':
        case 'debitsAppliedToContactInvoices':
        case 'showEdiReferenceNumbersOnOrderPage':
        case 'ediPartnerships':
        case 'logo':
        case 'logoType':
        case 'defaultOrdinaryTariffChainId':
        case 'defaultLineHaulTariffChainId':
        case 'defaultTransferTariffChainId':
        case 'defaultPointToPointTariffChainId':
        case 'defaultInboundEdiServiceUuid':
        case 'defaultOutboundEdiServiceUuid':
        case 'defaultInboundOutboundEdiServiceUuid': {
          break;
        }
        default: {
          exhaustive(field);
        }
      }
      if (!isNil(validationResponse)) {
        if (validationResponse.valid) {
          thunkAPI.dispatch(
            upsertOneContactErrors({
              uuid: arg.contactUuid,
              [field]: undefined,
            }),
          );
        } else {
          isValid = false;
          thunkAPI.dispatch(
            upsertOneContactErrors({
              uuid: arg.contactUuid,
              [field]: validationResponse.explanation,
            }),
          );
        }
      }
    }),
  );

  return isValid;
});

type CreateCustomerContactCreateInputArg = {
  contactUuid: string;
};

export const createCustomerContactCreateInput = createAsyncThunk<
  CustomerContactCreateInput,
  CreateCustomerContactCreateInputArg,
  { state: RootState }
>(
  'contactValues/createCustomerContactCreateInput',
  async (arg, thunkAPI): Promise<CustomerContactCreateInput> => {
    const contactValues = selectContactValuesById(
      thunkAPI.getState(),
      arg.contactUuid,
    );

    if (isNil(contactValues)) {
      throw new Error(`Invalid contact uuid: ${arg.contactUuid}`);
    }

    let defaultAddressCreateInput: AddressCreateInput | undefined;
    if (!isNil(contactValues.defaultAddressUuid)) {
      const defaultAddressValues = selectAddressById(
        thunkAPI.getState(),
        contactValues.defaultAddressUuid,
      );
      if (
        !isNil(defaultAddressValues) &&
        !addressIsEmpty(defaultAddressValues)
      ) {
        defaultAddressCreateInput = await thunkAPI
          .dispatch(
            createAddressCreateInput({
              addressUuid: contactValues.defaultAddressUuid,
            }),
          )
          .unwrap();
      }
    }

    let defaultContactPersonCreateInput: ContactPersonCreateInput | undefined;
    if (!isNil(contactValues.defaultContactPersonUuid)) {
      const defaultContactPersonValues = selectContactPersonById(
        thunkAPI.getState(),
        contactValues.defaultContactPersonUuid,
      );
      if (
        !isNil(defaultContactPersonValues) &&
        !contactPersonIsEmpty(defaultContactPersonValues)
      ) {
        defaultContactPersonCreateInput = await thunkAPI
          .dispatch(
            createContactPersonCreateInput({
              contactPersonUuid: contactValues.defaultContactPersonUuid,
            }),
          )
          .unwrap();
      }
    }

    return {
      billingContactEmail: contactValues.billingContactEmail ?? '',
      defaultAddressCreateInput,
      defaultContactPersonCreateInput,
      defaultServicePickupsDeliveries: false,
      displayName: contactValues.displayName ?? '',
      ediApplicationId: contactValues.ediApplicationId,
      ediContactCode: contactValues.ediContactCode,
      identifier: contactValues.identifier,
      contactReferenceNumber: isEmpty(contactValues.contactReferenceNumber)
        ? null
        : contactValues.contactReferenceNumber,
      isActive: contactValues.isActive ?? true,
      isaId: contactValues.isaId,
      serviceUuids: contactValues.services?.map((service) => service.uuid),
      defaultFuelSurcharge: contactValues.defaultFuelSurcharge,
      defaultUseTariff: contactValues.defaultUseTariff,
      defaultDimFactor: contactValues.defaultDimFactor,
      billingRunStart: contactValues.billingRunStart ?? new Date(),
      requireDriverToCheckOffPackagesAtStop:
        contactValues.requireDriverToCheckOffPackagesAtStop ?? false,
      showEdiReferenceNumbersOnOrderPage:
        contactValues.showEdiReferenceNumbersOnOrderPage ?? false,
      standardCarrierAlphaCode: contactValues.standardCarrierAlphaCode,
      stediPartnerId: contactValues.stediPartnerId,
      defaultServiceUuid:
        contactValues.defaultServiceUuid === NONE_SERVICE
          ? null
          : contactValues.defaultServiceUuid,
      defaultInboundEdiServiceUuid:
        contactValues.defaultInboundEdiServiceUuid === NONE_SERVICE
          ? null
          : contactValues.defaultInboundEdiServiceUuid,
      defaultOutboundEdiServiceUuid:
        contactValues.defaultOutboundEdiServiceUuid === NONE_SERVICE
          ? null
          : contactValues.defaultOutboundEdiServiceUuid,
      defaultInboundOutboundEdiServiceUuid:
        contactValues.defaultInboundOutboundEdiServiceUuid === NONE_SERVICE
          ? null
          : contactValues.defaultInboundOutboundEdiServiceUuid,
      masterAccountUuid: contactValues.masterAccountUuid,
      businessDivisionUuid: contactValues.businessDivisionUuid,
      billingCycleDuration:
        contactValues.billingCycleDuration ?? BillingCycleDuration.Weekly,
      isPrepaidOnly: contactValues.isPrepaidOnly,
      enableCustomerPortalOrderEntry:
        contactValues.enableCustomerPortalOrderEntry,
      useCustomPackageSpecs: contactValues.useCustomPackageSpecs,
      packageSpecIds: contactValues.packageSpecIds,
      useCustomVehicleTypes: contactValues.useCustomVehicleTypes,
      vehicleTypeUuids: contactValues.vehicleTypeUuids,
      referenceNumberLabels: contactValues.referenceNumberLabels,
      defaultOrdinaryTariffChainId: contactValues.defaultOrdinaryTariffChainId,
      defaultLineHaulTariffChainId: contactValues.defaultLineHaulTariffChainId,
      defaultTransferTariffChainId: contactValues.defaultTransferTariffChainId,
      defaultPointToPointTariffChainId:
        contactValues.defaultPointToPointTariffChainId,
    };
  },
);

type CreateCustomerContactUpdateInputArg = {
  contactUuid: string;
};

export const createCustomerContactUpdateInput = createAsyncThunk<
  CustomerContactUpdateInput,
  CreateCustomerContactUpdateInputArg,
  { state: RootState }
>(
  'contactValues/createCustomerContactUpdateInput',
  async (arg, thunkAPI): Promise<CustomerContactUpdateInput> => {
    const contactValues = selectContactValuesById(
      thunkAPI.getState(),
      arg.contactUuid,
    );

    if (isNil(contactValues)) {
      throw new Error(`Invalid contact uuid: ${arg.contactUuid}`);
    }

    return {
      billingContactEmail: contactValues.billingContactEmail,
      defaultAddressUuid: contactValues.defaultAddressUuid,
      defaultContactPersonUuid: contactValues.defaultContactPersonUuid,
      displayName: contactValues.displayName,
      ediApplicationId: contactValues.ediApplicationId,
      ediContactCode: contactValues.ediContactCode,
      identifier: contactValues.identifier,
      isaId: contactValues.isaId,
      contactReferenceNumber: isEmpty(contactValues.contactReferenceNumber)
        ? null
        : contactValues.contactReferenceNumber,
      isActive: contactValues.isActive,
      standardCarrierAlphaCode: contactValues.standardCarrierAlphaCode,
      stediPartnerId: contactValues.stediPartnerId,
      uuid: contactValues.uuid,
      serviceUuids: contactValues.serviceUuids,
      showEdiReferenceNumbersOnOrderPage:
        contactValues.showEdiReferenceNumbersOnOrderPage,
      defaultFuelSurcharge: contactValues.defaultFuelSurcharge,
      defaultUseTariff: contactValues.defaultUseTariff,
      defaultDimFactor: contactValues.defaultDimFactor,
      billingRunStart: contactValues.billingRunStart,
      defaultServiceUuid:
        contactValues.defaultServiceUuid === NONE_SERVICE
          ? null
          : contactValues.defaultServiceUuid,
      defaultInboundEdiServiceUuid:
        contactValues.defaultInboundEdiServiceUuid === NONE_SERVICE
          ? null
          : contactValues.defaultInboundEdiServiceUuid,
      defaultOutboundEdiServiceUuid:
        contactValues.defaultOutboundEdiServiceUuid === NONE_SERVICE
          ? null
          : contactValues.defaultOutboundEdiServiceUuid,
      defaultInboundOutboundEdiServiceUuid:
        contactValues.defaultInboundOutboundEdiServiceUuid === NONE_SERVICE
          ? null
          : contactValues.defaultInboundOutboundEdiServiceUuid,
      masterAccountUuid: contactValues.masterAccountUuid,
      businessDivisionUuid: contactValues.businessDivisionUuid,
      isPrepaidOnly: contactValues.isPrepaidOnly,
      useCustomPackageSpecs: contactValues.useCustomPackageSpecs,
      packageSpecIds: contactValues.packageSpecIds,
      useCustomVehicleTypes: contactValues.useCustomVehicleTypes,
      vehicleTypeUuids: contactValues.vehicleTypeUuids,
      referenceNumberLabels: contactValues.referenceNumberLabels,
      defaultOrdinaryTariffChainId: contactValues.defaultOrdinaryTariffChainId,
      defaultLineHaulTariffChainId: contactValues.defaultLineHaulTariffChainId,
      defaultTransferTariffChainId: contactValues.defaultTransferTariffChainId,
      defaultPointToPointTariffChainId:
        contactValues.defaultPointToPointTariffChainId,
    };
  },
);

type AddNewContactPersonToContactArg = {
  contactUuid: string;
};

/**
 * Add a new empty contact person to a contact
 */
export const addNewContactPersonToContact = createAsyncThunk<
  ContactPersonFormField,
  AddNewContactPersonToContactArg,
  { state: RootState }
>(
  'contactValues/addNewContactPersonToContact',
  async (arg, thunkAPI): Promise<ContactPersonFormField> => {
    const contact = selectContactValuesById(
      thunkAPI.getState(),
      arg.contactUuid,
    );
    if (isNil(contact)) {
      throw new Error(`Invalid contact uuid: ${arg.contactUuid}`);
    }
    const newContactPerson: ContactPersonFormField = {
      createdAt: undefined,
      email: undefined,
      firstName: undefined,
      lastName: undefined,
      notes: undefined,
      phone: undefined,
      updatedAt: undefined,
      uuid: v4(),
    };

    thunkAPI.dispatch(addContactPerson(newContactPerson));
    thunkAPI.dispatch(
      updateOneContactValues({
        id: arg.contactUuid,
        changes: {
          contactPersonUuids: [
            ...contact.contactPersonUuids,
            newContactPerson.uuid,
          ],
        },
      }),
    );

    return newContactPerson;
  },
);

type RemoveContactPersonFromContactArg = {
  contactUuid: string;
  contactPersonUuid: string;
};

/**
 * Remove a contact person from a contact and delete the contact person (in redux only)
 */
export const removeContactPersonFromContact = createAsyncThunk<
  boolean,
  RemoveContactPersonFromContactArg,
  { state: RootState }
>(
  'contactValues/removeContactPersonFromContact',
  async (arg, thunkAPI): Promise<boolean> => {
    const contact = selectContactValuesById(
      thunkAPI.getState(),
      arg.contactUuid,
    );
    if (isNil(contact)) {
      throw new Error(`Invalid contact uuid: ${arg.contactUuid}`);
    }

    thunkAPI.dispatch(
      updateOneContactValues({
        id: arg.contactUuid,
        changes: {
          contactPersonUuids: contact.contactPersonUuids.filter(
            (c) => c !== arg.contactPersonUuid,
          ),
        },
      }),
    );
    thunkAPI.dispatch(removeOneContactPerson(arg.contactPersonUuid));

    return true;
  },
);

type AddNewAddressToContactArg = {
  contactUuid: string;
};

/**
 * Add a new empty contact person to a contact
 */
export const addNewAddressToContact = createAsyncThunk<
  AddressFormField,
  AddNewAddressToContactArg,
  { state: RootState }
>(
  'contactValues/addNewAddressToContact',
  async (arg, thunkAPI): Promise<AddressFormField> => {
    const contact = selectContactValuesById(
      thunkAPI.getState(),
      arg.contactUuid,
    );
    if (isNil(contact)) {
      throw new Error(`Invalid contact uuid: ${arg.contactUuid}`);
    }
    const newAddress: AddressFormField = {
      city: undefined,
      country: undefined,
      driverInstructions: undefined,
      isLocal: true,
      latitude: undefined,
      line1: undefined,
      line2: undefined,
      longitude: undefined,
      name: undefined,
      receivingHoursEnd: undefined,
      receivingHoursStart: undefined,
      state: undefined,
      updatedAt: undefined,
      zip: undefined,
      createdAt: undefined,
      uuid: v4(),
    };

    thunkAPI.dispatch(addAddress(newAddress));
    thunkAPI.dispatch(
      updateOneContactValues({
        id: arg.contactUuid,
        changes: {
          addressUuids: [...contact.addressUuids, newAddress.uuid],
        },
      }),
    );

    return newAddress;
  },
);

type RemoveAddressFromContactArg = {
  contactUuid: string;
  addressUuid: string;
};

/**
 * Remove a contact person from a contact and delete the contact person (in redux only)
 */
export const removeAddressFromContact = createAsyncThunk<
  boolean,
  RemoveAddressFromContactArg,
  { state: RootState }
>(
  'contactValues/removeAddressFromContact',
  async (arg, thunkAPI): Promise<boolean> => {
    const contact = selectContactValuesById(
      thunkAPI.getState(),
      arg.contactUuid,
    );
    if (isNil(contact)) {
      throw new Error(`Invalid contact uuid: ${arg.contactUuid}`);
    }

    thunkAPI.dispatch(
      updateOneContactValues({
        id: arg.contactUuid,
        changes: {
          addressUuids: contact.addressUuids.filter(
            (c) => c !== arg.addressUuid,
          ),
        },
      }),
    );
    thunkAPI.dispatch(removeOneAddress(arg.addressUuid));

    return true;
  },
);
