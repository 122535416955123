import { isNil } from 'lodash';
import { filterNotNilOrEmpty } from 'shared/array';
import { exhaustive } from 'shared/switch';
import MultiSelectFilterEditorWithOptions from '../../../../common/filters/editors/multi-select-filter-editor';
import SingleSelectFilterEditorWithOptions from '../../../../common/filters/editors/single-select-filter-editor';
import FilterPills from '../../../../common/filters/filter-pills';
import { type Option } from '../../../../common/filters/types';
import {
  type FilterConfig,
  type FilterGroup,
  type NullableFilterCondition,
} from '../../../../common/filters/types-v2';
import {
  isFilterCondition,
  isFilterGroup,
} from '../../../../common/filters/utils-v2';
import {
  type FindOrdersFiltersInput,
  CustomerPortalOrderReviewStatus,
} from '../../../../generated/graphql';

export type FindBookingRequestsFiltersInput = Pick<
  FindOrdersFiltersInput,
  'hasQuotesFilter' | 'customerPortalOrderReviewStatusFilter'
>;

type BookingRequestFilterField =
  | 'hasQuotes'
  | 'customerPortalOrderReviewStatus';

type BookingRequestFilterOperatorMap = {
  hasQuotes: {
    eq: boolean;
  };
  customerPortalOrderReviewStatus: {
    eq: CustomerPortalOrderReviewStatus;
    neq: CustomerPortalOrderReviewStatus;
    in: CustomerPortalOrderReviewStatus[];
    nin: CustomerPortalOrderReviewStatus[];
  };
};

type BookingRequestFilterType = keyof BookingRequestFilterOperatorMap;

const BOOKING_REQUEST_FILTER_FIELD_TO_TYPE_MAP = {
  hasQuotes: 'hasQuotes',
  customerPortalOrderReviewStatus: 'customerPortalOrderReviewStatus',
} as const;

type BookingRequestFilterFieldMap =
  typeof BOOKING_REQUEST_FILTER_FIELD_TO_TYPE_MAP;

export type BookingRequestFilters = FilterGroup<
  BookingRequestFilterField,
  BookingRequestFilterType,
  BookingRequestFilterOperatorMap,
  BookingRequestFilterFieldMap
>;

type NullableBookingRequestFiltersCondition = NullableFilterCondition<
  BookingRequestFilterField,
  BookingRequestFilterType,
  BookingRequestFilterOperatorMap,
  BookingRequestFilterFieldMap
>;

type BookingRequestFiltersConfig = FilterConfig<
  BookingRequestFilterField,
  BookingRequestFilterType,
  BookingRequestFilterOperatorMap,
  BookingRequestFilterFieldMap
>;

const DEFAULT_EMPTY_BOOKING_REQUEST_FILTER_CONDITION: BookingRequestFiltersConfig['defaultEmptyFilterCondition'] =
  {
    field: null,
    operator: null,
    value: null,
  };

const BOOKING_REQUEST_FILTER_FIELD_LABELS: Record<
  BookingRequestFilterField,
  string
> = {
  hasQuotes: 'Has Quotes',
  customerPortalOrderReviewStatus: 'Status',
};

const BOOKING_REQUEST_FIELD_OPERATOR_LABELS: BookingRequestFiltersConfig['filterOperatorLabels'] =
  {
    hasQuotes: {
      eq: 'is',
    },
    customerPortalOrderReviewStatus: {
      eq: 'is',
      neq: 'is not',
      in: 'is one of',
      nin: 'is not one of',
    },
  };

const BOOLEAN_OPTIONS: Array<Option<boolean>> = [
  { label: 'true', value: true },
  { label: 'false', value: false },
];

const CUSTOMER_PORTAL_ORDER_REVIEW_STATUS_OPTIONS: Array<
  Option<CustomerPortalOrderReviewStatus>
> = [
  { label: 'Pending', value: CustomerPortalOrderReviewStatus.Pending },
  { label: 'Approved', value: CustomerPortalOrderReviewStatus.Approved },
  { label: 'Rejected', value: CustomerPortalOrderReviewStatus.Rejected },
];

const FILTER_EDITOR_COMPONENTS: BookingRequestFiltersConfig['filterEditorComponents'] =
  {
    hasQuotes: {
      eq: SingleSelectFilterEditorWithOptions<boolean>(BOOLEAN_OPTIONS),
    },
    customerPortalOrderReviewStatus: {
      eq: SingleSelectFilterEditorWithOptions<CustomerPortalOrderReviewStatus>(
        CUSTOMER_PORTAL_ORDER_REVIEW_STATUS_OPTIONS,
      ),
      neq: SingleSelectFilterEditorWithOptions<CustomerPortalOrderReviewStatus>(
        CUSTOMER_PORTAL_ORDER_REVIEW_STATUS_OPTIONS,
      ),
      in: MultiSelectFilterEditorWithOptions<CustomerPortalOrderReviewStatus>(
        CUSTOMER_PORTAL_ORDER_REVIEW_STATUS_OPTIONS,
      ),
      nin: MultiSelectFilterEditorWithOptions<CustomerPortalOrderReviewStatus>(
        CUSTOMER_PORTAL_ORDER_REVIEW_STATUS_OPTIONS,
      ),
    },
  };

const renderBookingRequestFilterValue = (
  filterCondition: NullableBookingRequestFiltersCondition,
): string | null => {
  const { field, operator, value } = filterCondition;
  if (isNil(field) || isNil(operator) || isNil(value)) {
    return null;
  }

  switch (field) {
    case 'hasQuotes': {
      return value ? 'true' : 'false';
    }
    case 'customerPortalOrderReviewStatus': {
      switch (operator) {
        case 'eq':
        case 'neq': {
          return (
            CUSTOMER_PORTAL_ORDER_REVIEW_STATUS_OPTIONS.find(
              (option) => option.value === value,
            )?.label ?? null
          );
        }
        case 'in':
        case 'nin': {
          const renderedValues = value.map(
            (v) =>
              CUSTOMER_PORTAL_ORDER_REVIEW_STATUS_OPTIONS.find(
                (option) => option.value === v,
              )?.label ?? null,
          );
          return filterNotNilOrEmpty(renderedValues).join(', ');
        }
        default: {
          return exhaustive(operator);
        }
      }
    }
    default: {
      return exhaustive(field);
    }
  }
};

const renderFilterParts = (
  filterCondition: NullableBookingRequestFiltersCondition,
) => {
  const { field, operator, value } = filterCondition;
  if (isNil(field) || isNil(operator) || isNil(value)) {
    return null;
  }

  const renderedField = BOOKING_REQUEST_FILTER_FIELD_LABELS[field];
  const renderedOperator = (() => {
    switch (field) {
      case 'hasQuotes': {
        return BOOKING_REQUEST_FIELD_OPERATOR_LABELS.hasQuotes[operator];
      }
      case 'customerPortalOrderReviewStatus': {
        return BOOKING_REQUEST_FIELD_OPERATOR_LABELS
          .customerPortalOrderReviewStatus[operator];
      }
      default: {
        return exhaustive(field);
      }
    }
  })();
  const renderedValue = renderBookingRequestFilterValue(filterCondition);

  if (isNil(renderedValue)) {
    return null;
  }

  return {
    field: renderedField,
    operator: renderedOperator,
    value: renderedValue,
  };
};

const BOOKING_REQUEST_FILTER_CONFIG: BookingRequestFiltersConfig = {
  entityName: 'booking request',
  supportsNesting: false,
  filterFieldLabels: BOOKING_REQUEST_FILTER_FIELD_LABELS,
  filterOperatorLabels: BOOKING_REQUEST_FIELD_OPERATOR_LABELS,
  filterFieldToTypeMap: BOOKING_REQUEST_FILTER_FIELD_TO_TYPE_MAP,
  defaultEmptyFilterCondition: DEFAULT_EMPTY_BOOKING_REQUEST_FILTER_CONDITION,
  filterEditorComponents: FILTER_EDITOR_COMPONENTS,
  renderFilterParts,
};

type BookingRequestFilterPillsProps = {
  readonly filters: Partial<FindBookingRequestsFiltersInput>;
  readonly setFilters: (filters: FindBookingRequestsFiltersInput) => void;
  readonly wrap: boolean;
};

export const BookingRequestFilterPills = ({
  filters: findBookingRequestsFiltersInput,
  setFilters: setFindBookingRequestsFiltersInput,
  wrap,
}: BookingRequestFilterPillsProps) => {
  const filters = convertFindBookingRequestsFiltersInputToFilterGroup(
    findBookingRequestsFiltersInput,
  );

  const setFilters = (filters: BookingRequestFilters) => {
    setFindBookingRequestsFiltersInput(
      convertFilterGroupToFindBookingRequestsFiltersInput(filters),
    );
  };

  return (
    <FilterPills<
      BookingRequestFilterField,
      BookingRequestFilterType,
      BookingRequestFilterOperatorMap,
      BookingRequestFilterFieldMap
    >
      filters={filters}
      setFilters={setFilters}
      wrap={wrap}
      filterConfig={BOOKING_REQUEST_FILTER_CONFIG}
    />
  );
};

export const convertFindBookingRequestsFiltersInputToFilterGroup = (
  filters: FindBookingRequestsFiltersInput | null | undefined,
): BookingRequestFilters => {
  if (isNil(filters)) {
    return {
      operator: 'AND',
      conditions: [],
    };
  }

  const conditions: BookingRequestFilters['conditions'] = [];

  if (!isNil(filters.hasQuotesFilter?.eq)) {
    conditions.push({
      field: 'hasQuotes',
      operator: 'eq',
      value: filters.hasQuotesFilter.eq,
    });
  }

  const statusFilter = filters.customerPortalOrderReviewStatusFilter;
  if (!isNil(statusFilter)) {
    if (!isNil(statusFilter.eq)) {
      conditions.push({
        field: 'customerPortalOrderReviewStatus',
        operator: 'eq',
        value: statusFilter.eq,
      });
    }
    if (!isNil(statusFilter.neq)) {
      conditions.push({
        field: 'customerPortalOrderReviewStatus',
        operator: 'neq',
        value: statusFilter.neq,
      });
    }
    if (!isNil(statusFilter.in)) {
      conditions.push({
        field: 'customerPortalOrderReviewStatus',
        operator: 'in',
        value: statusFilter.in,
      });
    }
    if (!isNil(statusFilter.nin)) {
      conditions.push({
        field: 'customerPortalOrderReviewStatus',
        operator: 'nin',
        value: statusFilter.nin,
      });
    }
  }

  return {
    operator: 'AND',
    conditions: [
      {
        operator: 'AND',
        conditions,
      },
    ],
  };
};

const FIND_BOOKING_REQUEST_FILTERS_INPUT_PATHS = [
  ['hasQuotes', 'hasQuotesFilter'],
  ['customerPortalOrderReviewStatus', 'customerPortalOrderReviewStatusFilter'],
] satisfies Array<
  [BookingRequestFilterField, keyof FindBookingRequestsFiltersInput]
>;

export const convertFilterGroupToFindBookingRequestsFiltersInput = (
  filters: BookingRequestFilters,
): FindBookingRequestsFiltersInput => {
  const topLevelFilterGroup = filters.conditions[0];
  if (isNil(topLevelFilterGroup) || !isFilterGroup(topLevelFilterGroup)) {
    return {};
  }
  const conditions = topLevelFilterGroup.conditions.filter((f) =>
    isFilterCondition(f),
  );

  const findBookingRequestsFiltersInput: FindBookingRequestsFiltersInput = {};

  for (const [field, path] of FIND_BOOKING_REQUEST_FILTERS_INPUT_PATHS) {
    const condition = conditions.find((condition) => condition.field === field);
    if (isNil(condition)) {
      continue;
    }
    findBookingRequestsFiltersInput[path] = {
      [condition.operator]: condition.value,
    };
  }

  return findBookingRequestsFiltersInput;
};
