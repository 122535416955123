import { TextField } from '@mui/material';
import { Stack, Typography } from '@mui/material';
import AutocompleteFuzzy from '../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { type LineHaulManifest } from '../utils';
import {
  LineHaulManifestGroupsDocument,
  useEquipmentsQuery,
  useUpdateManifestVehicleMutation,
} from '../../../../../generated/graphql';
import { useState, useMemo } from 'react';

type VehicleOption = {
  label: string;
  value: string;
};

type ManifestVehicleProps = {
  readonly manifest: LineHaulManifest;
};

const ManifestVehicle = ({ manifest }: ManifestVehicleProps) => {
  const { data: equipmentsData, loading: equipmentsLoading } =
    useEquipmentsQuery({
      fetchPolicy: 'cache-and-network',
    });
  const [selectedVehicleOption, setSelectedVehicleOption] =
    useState<VehicleOption | null>({
      label: manifest.equipment?.name ?? '',
      value: manifest.equipment?.uuid ?? '',
    });
  const [updateManifestVehicle] = useUpdateManifestVehicleMutation({
    refetchQueries: [LineHaulManifestGroupsDocument],
  });

  const updateVehicleOnManifest = async (vehicleUuid: string) => {
    await updateManifestVehicle({
      variables: {
        updateManifestVehicleInput: {
          uuid: manifest.uuid,
          equipmentUuid: vehicleUuid,
        },
      },
    });
  };

  const sortedVehicleOptions = useMemo(() => {
    return [...(equipmentsData?.equipments ?? [])]
      ?.sort((a, b) => a.name.localeCompare(b.name))
      .map((equipment) => ({
        label: equipment.name,
        value: equipment.uuid,
      }));
  }, [equipmentsData]);

  return (
    <Stack direction="row" gap={2} alignItems="center" flex={1}>
      <Typography fontWeight={500}>Vehicle</Typography>
      <AutocompleteFuzzy
        sx={{ flex: 1 }}
        size="small"
        value={selectedVehicleOption}
        disabled={equipmentsLoading}
        renderInput={(params) => <TextField {...params} />}
        options={sortedVehicleOptions}
        onChange={(event, option) => {
          setSelectedVehicleOption(option ?? null);
          event.stopPropagation();
          void updateVehicleOnManifest(option?.value ?? '');
        }}
      />
    </Stack>
  );
};

export default ManifestVehicle;
