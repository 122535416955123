import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  type InputBaseComponentProps,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import React, { type FunctionComponent, useEffect } from 'react';
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { getHazmatClassDescription, getHazmatClassNumber } from 'shared/copy';
import { isNilOrEmptyString } from 'shared/string';
import PhoneInputMask from '../../../../../../common/components/phone/phone-input-mask';
import { FeatureFlag } from '../../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../../../common/react-hooks/use-me';
import useThirdPartyBrokers from '../../../../../../common/react-hooks/use-third-party-brokers';
import { validatePhoneNumber } from '../../../../../../common/utils/utils';
import {
  HazmatClass,
  PackageGroup,
  useDriverQualificationsQuery,
} from '../../../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { useOrderFormEditAccess } from '../../contexts/order-form-edit-access-context';
import { type OrderFormValues } from '../../forms/types';
import { OUTBOUND_STOP_IDX } from '../constants';
import { OrderFormCard } from '../order-form-card';
import { OrderFormCardTitle } from '../order-form-card-title';

const HAZMAT_CLASS_OPTIONS = Object.values(HazmatClass).map((hazmatClass) => ({
  value: hazmatClass,
  label: `${getHazmatClassNumber(hazmatClass)}`,
  description: `${getHazmatClassDescription(hazmatClass)}`,
}));

type LogisticsProps = {
  readonly showTitle?: boolean;
};

const Logistics: FunctionComponent<LogisticsProps> = ({ showTitle = true }) => {
  const ffShowCollectOnDelivery = useFeatureFlag(
    FeatureFlag.FF_SHOW_COLLECT_ON_DELIVERY,
  );
  const ffCourierV1 = useFeatureFlag(FeatureFlag.FF_COURIER_V1);
  const {
    data: driverQualificationsData,
    loading: loadingDriverQualifications,
  } = useDriverQualificationsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { control, setValue } = useFormContext<OrderFormValues>();
  const { errors } = useFormState({ control });
  const { useAllCaps } = useMe();
  const { thirdPartyBrokers: thirdPartyBrokerOptions } = useThirdPartyBrokers();
  const thirdPartyBrokerUuid = useWatch({
    control,
    name: 'thirdPartyBrokerUuid',
  });
  const outboundSpecialInstructions = useWatch({
    control,
    name: `stops.${OUTBOUND_STOP_IDX}.specialInstructions`,
  });
  const inBond = useWatch({ control, name: 'inBond' });
  const hazmat = useWatch({ control, name: 'hazmat' });

  const uppercaseInputProps: InputBaseComponentProps = useAllCaps
    ? { style: { textTransform: 'uppercase' } }
    : {};

  const { disabledIfFinalizedOrLater, disabledIfInvoicePosted } =
    useOrderFormEditAccess();

  useEffect(() => {
    if (inBond === false) {
      setValue('itTeNumber', null);
      setValue('thirdPartyBrokerUuid', null);
    }
  }, [inBond, setValue]);

  useEffect(() => {
    if (hazmat === false) {
      setValue('unNumber', null);
      setValue('hazmatClass', null);
      setValue('packageGroup', null);
      setValue('requiresPlacard', null);
      setValue('hazmatDescription', null);
      setValue('emergencyResponseNumber', null);
    }
  }, [hazmat, setValue]);

  return (
    <OrderFormCard>
      {showTitle && <OrderFormCardTitle title="Logistics" />}
      {ffCourierV1 && (
        <Controller
          name="driverQualificationIds"
          control={control}
          render={({ field: { onChange, value } }) => (
            <FormControl
              sx={{
                mt: '5px',
              }}
            >
              <InputLabel id="order-driver-qualification-ids-label">
                Driver Qualifications
              </InputLabel>
              <Select<string[]>
                multiple
                label="Driver Qualifications"
                labelId="order-driver-qualification-ids-label"
                size="small"
                sx={{
                  width: '340px',
                }}
                value={value ?? []}
                disabled={
                  loadingDriverQualifications || disabledIfInvoicePosted
                }
                onChange={onChange}
              >
                {driverQualificationsData?.driverQualifications.map(
                  ({ id, name }) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ),
                )}
              </Select>
            </FormControl>
          )}
        />
      )}
      <Grid
        container
        spacing={1}
        display="flex"
        flexDirection="row"
        alignItems="end"
        sx={{
          mt: '5px',
        }}
      >
        <Grid item xs={12}>
          <Controller
            name="externalNotes"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth>
                <TextField
                  multiline
                  label="External notes"
                  sx={{ width: '100%' }}
                  disabled={disabledIfInvoicePosted}
                  size="small"
                  value={value}
                  inputProps={uppercaseInputProps}
                  onChange={onChange}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="inBond"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Stack direction="row" alignItems="start" spacing={0.5}>
                <Checkbox
                  checked={value ?? false}
                  disabled={disabledIfFinalizedOrLater}
                  onChange={onChange}
                />
                <Stack direction="column">
                  <Typography noWrap>In Bond</Typography>
                  <Typography variant="caption" color="text.secondary">
                    International freight requiring customs clearance
                  </Typography>
                </Stack>
              </Stack>
            )}
          />
        </Grid>
        {inBond === true && (
          <>
            <Grid item md={6}>
              <Controller
                name="itTeNumber"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth>
                    <FormLabel>IT/TE #</FormLabel>
                    <TextField
                      sx={{ width: '100%' }}
                      disabled={disabledIfInvoicePosted}
                      size="small"
                      value={value}
                      inputProps={uppercaseInputProps}
                      onChange={onChange}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item md={6}>
              <FormLabel>Third Party / Broker</FormLabel>
              <AutocompleteFuzzy
                filterSelectedOptions
                options={thirdPartyBrokerOptions ?? []}
                matchSortOptions={{ keys: ['name'] }}
                getOptionLabel={(option) => option?.name ?? '-'}
                value={thirdPartyBrokerOptions?.find(
                  (broker) => broker.uuid === thirdPartyBrokerUuid,
                )}
                renderInput={(params) => <TextField {...params} size="small" />}
                disabled={disabledIfInvoicePosted}
                onChange={(_, option) => {
                  setValue('thirdPartyBrokerUuid', option?.uuid);
                }}
              />
            </Grid>
          </>
        )}
        {ffShowCollectOnDelivery && (
          <Grid item xs={12}>
            <Controller
              name="isCollectOnDelivery"
              control={control}
              render={({ field }) => (
                <Stack direction="row" alignItems="start" gap={0.5}>
                  <Checkbox
                    checked={field.value ?? false}
                    disabled={disabledIfInvoicePosted}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                      if (e.target.checked) {
                        setValue(
                          `stops.${OUTBOUND_STOP_IDX}.specialInstructions`,
                          `Collect on delivery\n${outboundSpecialInstructions ?? ''}`,
                        );
                      } else {
                        const newSpecialInstructions =
                          outboundSpecialInstructions?.replace(
                            'Collect on delivery\n',
                            '',
                          ) ?? '';
                        setValue(
                          `stops.${OUTBOUND_STOP_IDX}.specialInstructions`,
                          newSpecialInstructions,
                        );
                      }
                    }}
                  />
                  <Stack direction="column">
                    <Typography fontSize="15px" lineHeight={1}>
                      COD
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      Collect payment on delivery
                    </Typography>
                  </Stack>
                </Stack>
              )}
            />
          </Grid>
        )}
        <Grid item md={12}>
          <Controller
            name="hazmat"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Stack direction="row" alignItems="start" gap={0.5}>
                <Checkbox
                  checked={value ?? false}
                  disabled={disabledIfFinalizedOrLater}
                  onChange={onChange}
                />
                <Stack direction="column">
                  <Typography>Hazmat</Typography>
                  <Typography variant="caption" color="text.secondary">
                    This order contains hazardous materials
                  </Typography>
                </Stack>
              </Stack>
            )}
          />
        </Grid>
        {hazmat === true && (
          <>
            <Grid item md={2}>
              <Controller
                name="unNumber"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth>
                    <FormLabel>UN #</FormLabel>
                    <TextField
                      sx={{ width: '100%' }}
                      disabled={disabledIfInvoicePosted}
                      size="small"
                      value={value}
                      inputProps={uppercaseInputProps}
                      onChange={onChange}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item md={5}>
              <Controller
                name="hazmatClass"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <FormLabel>Hazmat Class</FormLabel>
                    <AutocompleteFuzzy
                      {...field}
                      size="small"
                      value={
                        HAZMAT_CLASS_OPTIONS.find(
                          (option) => option.value === field.value,
                        ) ?? null
                      }
                      options={HAZMAT_CLASS_OPTIONS}
                      matchSortOptions={{ keys: ['label', 'description'] }}
                      getOptionLabel={(option) =>
                        `${option.label} - ${option.description}`
                      }
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Stack>
                            <Typography>{option.label}</Typography>
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {option.description}
                            </Typography>
                          </Stack>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: '100%' }} />
                      )}
                      disabled={disabledIfInvoicePosted}
                      onChange={(_event, option) => {
                        field.onChange(option?.value ?? null);
                      }}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item md={2}>
              <Controller
                name="packageGroup"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <FormLabel>Pkg Group</FormLabel>
                    <AutocompleteFuzzy
                      {...field}
                      size="small"
                      value={field.value}
                      options={Object.values(PackageGroup)}
                      renderInput={(params) => (
                        <TextField {...params} sx={{ width: '100%' }} />
                      )}
                      disabled={disabledIfInvoicePosted}
                      onChange={(_event, option) => {
                        field.onChange(option ?? null);
                      }}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item md={3}>
              <Controller
                name="requiresPlacard"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Checkbox
                      checked={value ?? false}
                      disabled={disabledIfInvoicePosted}
                      onChange={onChange}
                    />
                    <Typography>Requires Placard</Typography>
                  </Stack>
                )}
              />
            </Grid>
            <Grid item md={7}>
              <Controller
                name="hazmatDescription"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth>
                    <FormLabel>Description of Material</FormLabel>
                    <TextField
                      multiline
                      sx={{ width: '100%' }}
                      disabled={disabledIfInvoicePosted}
                      size="small"
                      value={value}
                      inputProps={uppercaseInputProps}
                      onChange={onChange}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item md={5}>
              <Controller
                name="emergencyResponseNumber"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth>
                    <FormLabel>Emergency Response Number</FormLabel>
                    <TextField
                      sx={{ width: '100%' }}
                      size="small"
                      disabled={disabledIfInvoicePosted}
                      InputProps={{
                        inputComponent: PhoneInputMask as any,
                      }}
                      value={value}
                      error={
                        isNilOrEmptyString(value)
                          ? false
                          : !validatePhoneNumber(value)
                      }
                      onChange={async (e) => {
                        onChange({
                          ...e,
                          target: { ...e.target, value: e.target.value },
                        });
                      }}
                    />
                    <FormHelperText sx={{ color: '#D32F2F', p: 0, m: 0 }}>
                      {errors.emergencyResponseNumber?.message?.toString() ??
                        ''}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
          </>
        )}
      </Grid>
    </OrderFormCard>
  );
};

export default React.memo(Logistics);
