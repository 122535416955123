import {
  OrganizationType,
  type CreateOrganizationInput,
  type CreateOrUpdateCustomerInput,
  type OrganizationFragment,
  type UpdateOrganizationInput,
} from '../../../generated/graphql';
import {
  type AgentAndCustomerOrganizationFormValues,
  type AgentOnlyOrganizationFormValues,
  type CustomerOnlyOrganizationFormValues,
} from './organization-schema';
import { type OrganizationFormValues } from './types';

export const convertOrganizationToFormValues = (
  data: OrganizationFragment,
): OrganizationFormValues | null => {
  // we don't know the isAgent and isCustomer values at compile time so omit and then append
  const formValues: Omit<
    Required<AgentAndCustomerOrganizationFormValues>,
    'isAgent' | 'isCustomer'
  > = {
    uuid: data.uuid,
    name: data.name,
    types: data.types,
    referenceNumber: data.referenceNumber ?? null,
    email: data.email ?? '',
    defaultContactPersonUuid: data.defaultContactPerson?.uuid ?? null,
    isaId: data.isaId ?? null,
    ediContactCode: data.ediContactCode ?? null,
    stediPartnerId: data.stediPartnerId ?? null,
    ediApplicationId: data.ediApplicationId ?? null,
    defaultAddressUuid: data.customerFields?.defaultAddressUuid ?? null,
    businessDivisionUuid: data.customerFields?.businessDivisionUuid ?? null,
    invoiceOrOrderIdentifier:
      data.customerFields?.invoiceOrOrderIdentifier ?? '',
    defaultServiceUuid: data.customerFields?.defaultServiceUuid ?? null,
    defaultInboundEdiServiceUuid:
      data.customerFields?.defaultInboundEdiServiceUuid ?? null,
    defaultOutboundEdiServiceUuid:
      data.customerFields?.defaultOutboundEdiServiceUuid ?? null,
    defaultInboundOutboundEdiServiceUuid:
      data.customerFields?.defaultInboundOutboundEdiServiceUuid ?? null,
    defaultPointToPointTariffChainId:
      data.customerFields?.defaultPointToPointTariffChainId ?? null,
    defaultFuelSurcharge: data.customerFields?.defaultFuelSurcharge ?? null,
    defaultDimFactor: data.customerFields?.defaultDimFactor ?? null,
    defaultTransferTariffChainId:
      data.customerFields?.defaultTransferTariffChainId ?? null,
    defaultLineHaulTariffChainId:
      data.customerFields?.defaultLineHaulTariffChainId ?? null,
    defaultOrdinaryTariffChainId:
      data.customerFields?.defaultOrdinaryTariffChainId ?? null,
    showEdiReferenceNumbersOnOrderPage:
      data.customerFields?.showEdiReferenceNumbersOnOrderPage ?? null,
    isPrepaidOnly: data.customerFields?.isPrepaidOnly ?? null,
    defaultUseTariff: data.customerFields?.defaultUseTariff ?? null,
    serviceUuids:
      data.customerFields?.services.map((service) => service.uuid) ?? [],
    vehicleTypeUuids:
      data.customerFields?.customerVehicleTypes.map(
        (customerVehicleType) => customerVehicleType.vehicleTypeUuid,
      ) ?? [],
    useCustomVehicleTypes: data.customerFields?.useCustomVehicleTypes ?? null,
    referenceNumberLabels: data.customerFields?.referenceNumberLabels ?? [],
    enableCustomerPortalOrderEntry:
      data.customerFields?.enableCustomerPortalOrderEntry ?? null,
  };

  if (
    data.types.includes(OrganizationType.Customer) &&
    data.types.includes(OrganizationType.Agent)
  ) {
    const finalFormValues: AgentAndCustomerOrganizationFormValues = {
      ...formValues,
      isAgent: true,
      isCustomer: true,
    };
    return finalFormValues;
  }
  if (data.types.includes(OrganizationType.Customer)) {
    const finalFormValues: CustomerOnlyOrganizationFormValues = {
      ...formValues,
      isCustomer: true,
      isAgent: false,
    };
    return finalFormValues;
  }
  if (data.types.includes(OrganizationType.Agent)) {
    const finalFormValues: AgentOnlyOrganizationFormValues = {
      ...formValues,
      isAgent: true,
      isCustomer: false,
    };
    return finalFormValues;
  }
  return null;
};

export const convertFormValuesToOrganizationCreateInput = (
  data: OrganizationFormValues,
): CreateOrganizationInput => {
  const createInput: Required<CreateOrganizationInput> = {
    email: data.email,
    name: data.name,
    referenceNumber: data.referenceNumber,
    types: data.types,
    customerFields: data.isCustomer
      ? ({
          defaultUseTariff: data.defaultUseTariff ?? true,
          invoiceOrOrderIdentifier: data.invoiceOrOrderIdentifier ?? '',
          isPrepaidOnly: data.isPrepaidOnly ?? false,
          serviceUuids: data.serviceUuids ?? [],
          showEdiReferenceNumbersOnOrderPage:
            data.showEdiReferenceNumbersOnOrderPage ?? false,
          enableCustomerPortalOrderEntry:
            data.enableCustomerPortalOrderEntry ?? false,
          useCustomVehicleTypes: data.useCustomVehicleTypes ?? false,
          vehicleTypeUuids: data.vehicleTypeUuids ?? [],
          businessDivisionUuid: data.businessDivisionUuid ?? null,
          defaultAddressUuid: data.defaultAddressUuid ?? null,
          defaultInboundEdiServiceUuid:
            data.defaultInboundEdiServiceUuid ?? null,
          defaultInboundOutboundEdiServiceUuid:
            data.defaultInboundOutboundEdiServiceUuid ?? null,
          defaultLineHaulTariffChainId:
            data.defaultLineHaulTariffChainId ?? null,
          defaultOrdinaryTariffChainId:
            data.defaultOrdinaryTariffChainId ?? null,
          defaultPointToPointTariffChainId:
            data.defaultPointToPointTariffChainId ?? null,
          defaultTransferTariffChainId:
            data.defaultTransferTariffChainId ?? null,
          defaultServiceUuid: data.defaultServiceUuid ?? null,
          defaultFuelSurcharge: data.defaultFuelSurcharge ?? null,
          defaultDimFactor: data.defaultDimFactor ?? null,
          defaultOutboundEdiServiceUuid:
            data.defaultOutboundEdiServiceUuid ?? null,
          referenceNumberLabels:
            data.referenceNumberLabels?.map((label) => label.name) ?? [],
        } satisfies Required<CreateOrUpdateCustomerInput>)
      : null,
    ediApplicationId: data.ediApplicationId,
    ediContactCode: data.ediContactCode,
    isaId: data.isaId,
    stediPartnerId: data.stediPartnerId,
    defaultContactPersonUuid: data.defaultContactPersonUuid,
  };
  return createInput;
};

export const convertFormValuesToOrganizationUpdateInput = (
  data: OrganizationFormValues,
): UpdateOrganizationInput => {
  const updateInput: Required<UpdateOrganizationInput> = {
    uuid: data.uuid,
    email: data.email,
    name: data.name,
    referenceNumber: data.referenceNumber,
    types: data.types,
    customerFields: data.isCustomer
      ? ({
          defaultUseTariff: data.defaultUseTariff ?? true,
          invoiceOrOrderIdentifier: data.invoiceOrOrderIdentifier ?? '',
          isPrepaidOnly: data.isPrepaidOnly ?? false,
          serviceUuids: data.serviceUuids ?? [],
          showEdiReferenceNumbersOnOrderPage:
            data.showEdiReferenceNumbersOnOrderPage ?? false,
          enableCustomerPortalOrderEntry:
            data.enableCustomerPortalOrderEntry ?? false,
          useCustomVehicleTypes: data.useCustomVehicleTypes ?? false,
          vehicleTypeUuids: data.vehicleTypeUuids ?? [],
          businessDivisionUuid: data.businessDivisionUuid ?? null,
          defaultDimFactor: data.defaultDimFactor ?? null,
          defaultFuelSurcharge: data.defaultFuelSurcharge ?? null,
          defaultInboundEdiServiceUuid:
            data.defaultInboundEdiServiceUuid ?? null,
          defaultInboundOutboundEdiServiceUuid:
            data.defaultInboundOutboundEdiServiceUuid ?? null,
          defaultLineHaulTariffChainId:
            data.defaultLineHaulTariffChainId ?? null,
          defaultOrdinaryTariffChainId:
            data.defaultOrdinaryTariffChainId ?? null,
          defaultPointToPointTariffChainId:
            data.defaultPointToPointTariffChainId ?? null,
          defaultTransferTariffChainId:
            data.defaultTransferTariffChainId ?? null,
          defaultAddressUuid: data.defaultAddressUuid ?? null,
          defaultOutboundEdiServiceUuid:
            data.defaultOutboundEdiServiceUuid ?? null,
          defaultServiceUuid: data.defaultServiceUuid ?? null,
          referenceNumberLabels:
            data.referenceNumberLabels?.map((label) => label.name) ?? [],
        } satisfies Required<CreateOrUpdateCustomerInput>)
      : null,
    ediApplicationId: data.ediApplicationId,
    ediContactCode: data.ediContactCode,
    isaId: data.isaId,
    stediPartnerId: data.stediPartnerId,
    defaultContactPersonUuid: data.defaultContactPersonUuid,
  };
  return updateInput;
};
