import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { capitalCase, sentenceCase } from 'change-case';
import dayjs from 'dayjs';
import { isEmpty, isNil, sumBy, truncate } from 'lodash';
import { exhaustive } from 'shared/switch';
import { generateBarcode } from '../../../common/utils/barcode';
import {
  CHECKED_CHECKBOX_IMAGE_URL,
  UNCHECKED_CHECKBOX_IMAGE_URL,
} from '../../../common/utils/pdf-gen';
import {
  type CompanyConfigurationFragment,
  OrderEventSourceType,
  OrderIdentifier,
  Segment,
} from '../../../generated/graphql';
import { INBOUND_STOP_IDX } from '../../orders/components/order-form/components/constants';
import { type OrderCoverSheetData } from '../utils';
import DisplayAddress from './display-address';
import { getHazmatClassNumber } from 'shared/copy';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-regular-webfont.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/roboto-font@0.1.0/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 700,
    },
  ],
});

const styles = StyleSheet.create({
  page: {
    padding: 24,
    fontFamily: 'Roboto',
    fontSize: '11px',
    flexDirection: 'column',
  },
  titleBold: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  headerText: {
    fontSize: '12px',
    fontWeight: 'bold',
  },
  subHeaderTextSize: {
    fontSize: '10px',
  },
  subHeaderText: {
    fontSize: '10px',
    fontWeight: 'bold',
  },
  rowWithPadding: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '5px',
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  cell: {
    borderWidth: 1,
    borderColor: 'black',
    width: '100%',
    display: 'flex',
    padding: '5px',
  },
});

const APPT_TIME_FORMAT = 'MM/DD HH:mm';

const getOrderIdentifier = (
  order: OrderCoverSheetData,
  companyConfiguration: CompanyConfigurationFragment | null | undefined,
) => {
  const orderIdentifierConfig =
    companyConfiguration?.orderIdentifierForCoverSheet ??
    OrderIdentifier.ShipperBillOfLadingNumber;
  switch (orderIdentifierConfig) {
    case OrderIdentifier.OrderName: {
      return order.orderName;
    }
    case OrderIdentifier.ShipperBillOfLadingNumber: {
      return order.shipperBillOfLadingNumber;
    }
    default: {
      return exhaustive(orderIdentifierConfig);
    }
  }
};

const GeneratedOrderCoverSheetPdf = ({
  segment,
  companyConfiguration,
  order,
}: {
  readonly segment: Segment | undefined;
  readonly companyConfiguration:
    | CompanyConfigurationFragment
    | null
    | undefined;
  readonly order: OrderCoverSheetData;
}) => {
  const serviceDate =
    order.inboundShipment?.serviceDate ?? order.outboundShipment?.serviceDate;
  const shipments = [order.inboundShipment, order.outboundShipment];
  const totalPieces = sumBy(order.packages, 'quantity');
  const totalWeight = sumBy(order.packages, 'weight');
  const { logistics } = order;

  const orderIdentifier = getOrderIdentifier(order, companyConfiguration);
  const data = isEmpty(orderIdentifier) ? null : orderIdentifier;
  const barcodeData = isNil(data)
    ? null
    : generateBarcode({
        data,
        width: 3,
        height: 60,
        displayValue: false,
      });

  return (
    <Document
      title={`Order Cover Sheet - ${
        order.shipperBillOfLadingNumber ?? order.orderName
      }`}
    >
      <Page size="LETTER" style={styles.page}>
        <View>
          <View>
            <Text style={styles.titleBold}>{`PALLET ID:${order.uuid}`}</Text>
          </View>
          <View
            style={[
              {
                flexDirection: 'row',
                marginTop: '10px',
              },
              styles.cell,
            ]}
          >
            <View
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Text style={styles.titleBold}>{order.companyName}</Text>
              <DisplayAddress address={order.companyAddress} />
            </View>
            <View
              style={{
                width: '50%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <View
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row-reverse',
                }}
              >
                <Text>{dayjs().format(APPT_TIME_FORMAT)}</Text>
                <View style={{ marginRight: '3%' }}>
                  <Text style={{ fontWeight: 700 }}>Print Date:</Text>
                </View>
              </View>
              {!isNil(barcodeData) && (
                <>
                  `{' '}
                  <View>
                    <Image
                      source={{
                        uri: barcodeData,
                        method: 'GET',
                        headers: {},
                        body: '',
                      }}
                      style={{ width: '200px', height: '40px' }}
                    />
                  </View>
                  {}
                  {isNil(data) ? null : (
                    <View>
                      <Text style={{ fontSize: '8px' }}>{data}</Text>
                    </View>
                  )}
                  `
                </>
              )}
            </View>
          </View>
        </View>
        <View
          style={[styles.cell, { flexDirection: 'column', borderTopWidth: 0 }]}
        >
          <View style={styles.rowWithPadding}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100% ',
                justifyContent: 'space-between',
              }}
            >
              <Text style={styles.headerText}>{order.contactDisplayName}</Text>
              <Text style={styles.headerText}>
                {sentenceCase(order.serviceName ?? '')}
              </Text>
            </View>
          </View>
          <View style={styles.rowWithPadding}>
            <View
              style={{ width: '40%', display: 'flex', flexDirection: 'column' }}
            >
              <View style={styles.row}>
                <View style={{ width: '40%' }}>
                  <Text style={styles.subHeaderText}>Order</Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text style={{ fontSize: '10px' }}>{order.orderName}</Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={{ width: '40%' }}>
                  <Text style={styles.subHeaderText}>
                    {segment === Segment.Cartage ? 'HAWB #' : 'Pro #'}
                  </Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text style={{ fontSize: '10px' }}>
                    {order.shipperBillOfLadingNumber}
                  </Text>
                </View>
              </View>
              {segment === Segment.Cartage && (
                <View style={styles.row}>
                  <View style={{ width: '40%' }}>
                    <Text style={styles.subHeaderText}>MAWB #</Text>
                  </View>
                  <View style={{ width: '60%' }}>
                    <Text style={{ fontSize: '10px' }}>
                      {order.masterAirwayBillOfLadingNumber}
                    </Text>
                  </View>
                </View>
              )}
              <View style={styles.row}>
                <View style={{ width: '40%' }}>
                  <Text style={styles.subHeaderText}>Reference #s</Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text style={{ fontSize: '10px' }}>
                    {order.refNumbers?.join(', ')}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '40%',
                display: 'flex',
              }}
            >
              <View style={styles.row}>
                <View style={{ width: '40%' }}>
                  <Text style={styles.subHeaderText}>Service Date:</Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text style={{ fontSize: '10px' }}>
                    {isNil(serviceDate)
                      ? '-'
                      : dayjs(serviceDate).format('MM/DD/YY')}
                  </Text>
                </View>
              </View>
              <View style={styles.row}>
                <View style={{ width: '15%' }}>
                  <Text style={styles.subHeaderText}>Orig</Text>
                </View>
                <View style={{ width: '20%' }}>
                  <Text style={{ fontSize: '10px' }}>{order.originCode}</Text>
                </View>
                <View style={{ width: '15%' }}>
                  <Text style={styles.subHeaderText}>Dest</Text>
                </View>
                <View style={{ width: '20%' }}>
                  <Text style={{ fontSize: '10px' }}>
                    {order.destinationCode}
                  </Text>
                </View>
              </View>
            </View>
            <View
              style={{
                width: '30%',
                display: 'flex',
                flexDirection: 'column-reverse',
              }}
            >
              <View style={styles.rowWithPadding}>
                <View style={{ width: '25%' }}>
                  <Text style={styles.subHeaderText}>Weight:</Text>
                </View>
                <View style={{ width: '25%' }}>
                  <Text style={{ fontSize: '10px' }}>{totalWeight} lb</Text>
                </View>
              </View>
              <View style={styles.rowWithPadding}>
                <View style={{ width: '25%' }}>
                  <Text style={styles.subHeaderText}>Pieces:</Text>
                </View>
                <View style={{ width: '25%' }}>
                  <Text style={{ fontSize: '10px' }}>{totalPieces}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View
          style={[styles.cell, { flexDirection: 'row', borderTopWidth: 0 }]}
        >
          <View
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <View style={styles.rowWithPadding}>
              {shipments.map((shipment) => (
                <>
                  <View style={{ width: '50%', display: 'flex' }}>
                    <Text style={styles.subHeaderText}>Stop Type:</Text>
                  </View>
                  <View style={{ width: '80%', display: 'flex' }}>
                    <Text style={styles.subHeaderTextSize}>
                      {capitalCase(shipment?.stopType ?? '-')}
                    </Text>
                  </View>
                </>
              ))}
            </View>
            <View style={styles.rowWithPadding}>
              {shipments.map((shipment) => {
                return (
                  <>
                    <View style={{ width: '50%', display: 'flex' }}>
                      <Text style={styles.subHeaderText}>Address:</Text>
                    </View>
                    <View style={{ width: '80%', display: 'flex' }}>
                      <DisplayAddress
                        fontSize="10px"
                        address={shipment?.address}
                      />
                    </View>
                  </>
                );
              })}
            </View>
            <View style={styles.rowWithPadding}>
              {shipments.map((shipment) => {
                return (
                  <>
                    <View style={{ width: '50%', display: 'flex' }}>
                      <Text style={styles.subHeaderText}>Phone:</Text>
                    </View>
                    <View style={{ width: '80%', display: 'flex' }}>
                      <Text style={styles.subHeaderTextSize}>
                        {shipment?.contactPhone}
                      </Text>
                    </View>
                  </>
                );
              })}
            </View>
            <View style={styles.rowWithPadding}>
              {shipments.map((shipment) => {
                return (
                  <>
                    <View style={{ width: '50%', display: 'flex' }}>
                      <Text style={styles.subHeaderText}>Contact:</Text>
                    </View>
                    <View style={{ width: '80%', display: 'flex' }}>
                      <Text style={styles.subHeaderTextSize}>
                        {shipment?.contactPersonName}
                      </Text>
                    </View>
                  </>
                );
              })}
            </View>
            <View style={styles.rowWithPadding} />
            <View style={styles.rowWithPadding}>
              {shipments.map((shipment) => {
                return (
                  <>
                    <View style={{ width: '50%', display: 'flex' }}>
                      <Text style={styles.subHeaderText}>Route:</Text>
                    </View>
                    <View style={{ width: '80%', display: 'flex' }}>
                      <Text style={styles.subHeaderTextSize}>
                        {shipment?.routeName}
                      </Text>
                    </View>
                  </>
                );
              })}
            </View>
            <View style={styles.rowWithPadding}>
              {shipments.map((shipment) => {
                return (
                  <>
                    <View style={{ width: '50%', display: 'flex' }}>
                      <Text style={styles.subHeaderText}>Instructions:</Text>
                    </View>
                    <View style={{ width: '80%', display: 'flex' }}>
                      <Text
                        style={{ ...styles.subHeaderTextSize, fontSize: '6px' }}
                      >
                        {shipment?.instructions}
                      </Text>
                    </View>
                  </>
                );
              })}
            </View>
            <View style={styles.rowWithPadding}>
              {shipments.map((shipment) => {
                return (
                  <>
                    <View style={{ width: '50%', display: 'flex' }}>
                      <Text style={styles.subHeaderText}>Is Special?</Text>
                    </View>
                    <View style={{ width: '80%', display: 'flex' }}>
                      <Text style={styles.subHeaderTextSize}>
                        <Image
                          src={
                            shipment?.isSpecial === true
                              ? CHECKED_CHECKBOX_IMAGE_URL
                              : UNCHECKED_CHECKBOX_IMAGE_URL
                          }
                        />
                      </Text>
                    </View>
                  </>
                );
              })}
            </View>
          </View>
        </View>
        <View
          style={[styles.cell, { flexDirection: 'row', borderTopWidth: 0 }]}
        >
          {shipments.map((shipment, idx) => {
            const label = idx === INBOUND_STOP_IDX ? 'Inbound' : 'Outbound';
            return (
              <View
                style={{
                  borderRightWidth: idx === INBOUND_STOP_IDX ? 1 : 0,
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingLeft: idx === INBOUND_STOP_IDX ? '0px' : '10px',
                }}
              >
                <View style={styles.rowWithPadding}>
                  <View style={{ width: '50%', display: 'flex' }}>
                    <Text style={styles.subHeaderText}>{label} Appt:</Text>
                  </View>
                  <View style={{ width: '50%', display: 'flex' }}>
                    <Text style={styles.subHeaderTextSize}>
                      {shipment?.appointmentDateString}
                    </Text>
                  </View>
                </View>
                <View style={styles.rowWithPadding}>
                  <View style={{ width: '50%', display: 'flex' }}>
                    <Text style={styles.subHeaderText}>{label} Deadline:</Text>
                  </View>
                  <View style={{ width: '50%', display: 'flex' }}>
                    <Text style={styles.subHeaderTextSize}>
                      {isNil(shipment?.deadlineDate)
                        ? ''
                        : dayjs(shipment?.deadlineDate).format('MM/DD')}
                    </Text>
                  </View>
                </View>
                <View style={styles.rowWithPadding}>
                  <View style={{ width: '50%', display: 'flex' }}>
                    <Text style={styles.subHeaderText}>{label} Driver:</Text>
                  </View>
                  <View style={{ width: '50%', display: 'flex' }}>
                    <Text style={styles.subHeaderTextSize}>
                      {shipment?.driverName}
                    </Text>
                  </View>
                </View>
              </View>
            );
          })}
        </View>

        <View
          style={[styles.cell, { flexDirection: 'column', borderTopWidth: 0 }]}
        >
          <View style={styles.rowWithPadding}>
            <View style={{ width: '100%' }}>
              <Text style={styles.subHeaderText}>Description of goods</Text>
            </View>
          </View>
          {!isNil(logistics) && (
            <View style={{ width: '100%', display: 'flex' }}>
              <View style={styles.rowWithPadding}>
                <View
                  style={{
                    width: '15%',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Text
                    style={[
                      styles.subHeaderText,
                      { display: 'flex', flexDirection: 'row' },
                    ]}
                  >
                    Hazmat{'  '}
                    <Image
                      src={
                        logistics.hazmat === true
                          ? CHECKED_CHECKBOX_IMAGE_URL
                          : UNCHECKED_CHECKBOX_IMAGE_URL
                      }
                    />
                  </Text>
                </View>
                {logistics.hazmat === true && (
                  <>
                    <View style={{ width: '5%' }}>
                      <Text style={styles.subHeaderText}>UN #</Text>
                    </View>
                    <View style={{ width: '10%' }}>
                      <Text style={styles.subHeaderTextSize}>
                        {logistics.unNumber ?? '-'}
                      </Text>
                    </View>
                    <View style={{ width: '5%' }}>
                      <Text style={styles.subHeaderText}>Class</Text>
                    </View>
                    <View style={{ width: '4%' }}>
                      <Text style={styles.subHeaderTextSize}>
                        {isNil(logistics.hazmatClass)
                          ? '-'
                          : getHazmatClassNumber(logistics.hazmatClass)}
                      </Text>
                    </View>
                    <View style={{ width: '3%' }}>
                      <Text style={styles.subHeaderText}>PG</Text>
                    </View>
                    <View style={{ width: '3%' }}>
                      <Text style={styles.subHeaderTextSize}>
                        {logistics.packageGroup ?? '-'}
                      </Text>
                    </View>
                    <View
                      style={{
                        width: '15%',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <Text
                        style={[
                          styles.subHeaderText,
                          { display: 'flex', flexDirection: 'row' },
                        ]}
                      >
                        Req. Placard{'  '}
                        <Image
                          src={
                            logistics.requiresPlacard === true
                              ? CHECKED_CHECKBOX_IMAGE_URL
                              : UNCHECKED_CHECKBOX_IMAGE_URL
                          }
                        />
                      </Text>
                    </View>
                    <View style={{ width: '5%' }}>
                      <Text style={styles.subHeaderText}>ER #</Text>
                    </View>
                    <View style={{ width: '15%' }}>
                      <Text style={styles.subHeaderTextSize}>
                        {logistics.emergencyResponseNumber ?? '-'}
                      </Text>
                    </View>
                    <View style={{ width: '20%' }}>
                      <Text style={styles.subHeaderTextSize}>
                        {truncate(logistics.hazmatDescription ?? '-', {
                          length: 15,
                        })}
                      </Text>
                    </View>
                  </>
                )}
              </View>
            </View>
          )}
          <View style={styles.rowWithPadding}>
            <View
              style={{ width: '100%', display: 'flex', flexDirection: 'row' }}
            >
              <Text style={styles.subHeaderText}>Packages{'  '}</Text>
              {order.packages.length === 0 && (
                <Text style={styles.subHeaderTextSize}>None</Text>
              )}
            </View>
          </View>
          {order.packages.map((pkg) => (
            <View style={styles.rowWithPadding}>
              <View style={{ width: '30%' }}>
                <Text style={styles.subHeaderText}>{pkg.description}</Text>
              </View>
              <View style={{ width: '30%' }}>
                <Text style={styles.subHeaderTextSize}>
                  {pkg.quantity} @ {pkg.length ?? '-'} x {pkg.width ?? '-'} x{' '}
                  {pkg.height ?? '-'}
                </Text>
              </View>
              <View style={{ width: '40%' }}>
                <Text style={styles.subHeaderTextSize}>
                  Weight: {pkg.weight}lbs
                </Text>
              </View>
            </View>
          ))}
        </View>
        <View
          style={[styles.cell, { flexDirection: 'column', borderTopWidth: 0 }]}
        >
          <View style={[styles.rowWithPadding, { paddingBottom: 0 }]}>
            <View style={{ width: '15%' }}>
              <Text style={styles.subHeaderText}>Entered at:</Text>
            </View>
            <View style={{ width: '85%' }}>
              {!isNil(order.orderCreatedEvent) && (
                <Text style={styles.subHeaderTextSize}>
                  {dayjs(order.orderCreatedEvent.createdAt).format(
                    APPT_TIME_FORMAT,
                  )}{' '}
                  by{' '}
                  {order.orderCreatedEvent.sourceType ===
                  OrderEventSourceType.EdiTransaction
                    ? 'EDI'
                    : order.orderCreatedEvent.sourceUser?.email}
                </Text>
              )}
            </View>
          </View>
        </View>
        <View
          style={[
            styles.cell,
            {
              flexDirection: 'column',
              borderTopWidth: 0,
            },
          ]}
        >
          <View
            style={[
              styles.rowWithPadding,
              { paddingTop: '10px', paddingBottom: '20px' },
            ]}
          >
            <View style={{ width: '50%', flexDirection: 'row' }}>
              <View style={{ marginRight: '3%', width: '25%' }}>
                <Text style={styles.subHeaderText}>Printed Name:</Text>
              </View>
              <View
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: 'black',
                  width: '70%',
                }}
              />
            </View>
            <View style={{ width: '50%', flexDirection: 'row' }}>
              <View style={{ width: '15%' }}>
                <Text style={styles.subHeaderText}>Driver:</Text>
              </View>
              <View
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: 'black',
                  width: '80%',
                }}
              />
            </View>
          </View>
          <View style={styles.rowWithPadding}>
            <View style={{ width: '50%', flexDirection: 'row' }}>
              <View style={{ width: '25%' }}>
                <Text style={styles.subHeaderText}>Received By:</Text>
              </View>
              <View
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: 'black',
                  width: '70%',
                }}
              />
            </View>
            <View style={{ width: '25%', flexDirection: 'row' }}>
              <View style={{ width: '20%', marginRight: '5%' }}>
                <Text style={styles.subHeaderText}>Date:</Text>
              </View>
              <View
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: 'black',
                  width: '65%',
                }}
              />
            </View>
            <View style={{ width: '25%', flexDirection: 'row' }}>
              <View style={{ width: '20%', marginRight: '5%' }}>
                <Text style={styles.subHeaderText}>Time:</Text>
              </View>
              <View
                style={{
                  borderBottomWidth: 1,
                  borderBottomColor: 'black',
                  width: '65%',
                }}
              />
            </View>
          </View>
        </View>
        <View
          style={[styles.cell, { flexDirection: 'column', borderTopWidth: 0 }]}
        >
          <View
            style={[styles.rowWithPadding, { width: '100%', height: '10%' }]}
          >
            <Text style={styles.subHeaderText}>Notes</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default GeneratedOrderCoverSheetPdf;
