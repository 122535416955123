import { isNil, uniqBy } from 'lodash';
import { useMemo } from 'react';
import { filterNotNil } from 'shared/array';
import useMe from '../../../../../common/react-hooks/use-me';
import { useOutlookIntegrationsQuery } from '../../../../../generated/graphql';

export const useOutlookIntegrations = () => {
  const { companyUuid } = useMe();
  const { data, loading, error } = useOutlookIntegrationsQuery({
    variables: {
      getOutlookIntegrationsInput: {
        companyUuid,
      },
    },
  });

  const terminalsWithOutlookIntegrations = useMemo(
    () =>
      uniqBy(
        filterNotNil(
          data?.outlookIntegrations.map((outlookIntegration) => {
            const { terminal } = outlookIntegration;
            if (isNil(terminal)) {
              return null;
            }
            return {
              uuid: terminal?.uuid,
              code: terminal?.code,
            };
          }) ?? [],
        ),

        'uuid',
      ),
    [data],
  );

  return {
    outlookIntegrations: data?.outlookIntegrations ?? [],
    terminalsWithOutlookIntegrations,
    loading,
    error,
  };
};
