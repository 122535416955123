import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isArray, isEmpty, isNil, values } from 'lodash';
import { type Dispatch, type SetStateAction } from 'react';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Controller, type SubmitHandler } from 'react-hook-form';
import useContacts from '../../../common/react-hooks/use-contacts';
import useServices from '../../../common/react-hooks/use-services';
import {
  AutoApplyAccessorialConfigsDocument,
  AutoApplyAccessorialRuleType,
  PickupOrDelivery,
  useAccessorialNamesByBillingContactLazyQuery,
  useAccessorialNamesQuery,
  useAutoApplyAccessorialConfigQuery,
  useDeleteAutoApplyAccessorialConfigMutation,
  useUpdateAutoApplyAccessorialConfigMutation,
} from '../../../generated/graphql';
import { CONTACT_BASED_AUTO_APPLY_ACCESSORIAL_RULES } from '../contants';
import useEditAutoApplyAccessorialRuleForm, {
  type EditAutoApplyAccessorialRuleFormValues,
} from '../hooks/use-edit-auto-apply-accessorial-rule-form';
import { getRuleTypeLabel } from './add-auto-apply-accessorial-rule-modal';

const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type EditAutoApplyAccessorialRuleModalProps = {
  readonly open: boolean;
  readonly onClose: Dispatch<SetStateAction<boolean>>;

  readonly configUuid: string;
  readonly setShowEditedConfig: Dispatch<SetStateAction<boolean>>;

  readonly setFailedToEditConfig: Dispatch<SetStateAction<boolean>>;
};

const EditAutoApplyAccessorialRuleModal: React.FC<
  EditAutoApplyAccessorialRuleModalProps
> = ({
  open,
  onClose,
  setShowEditedConfig,
  setFailedToEditConfig,
  configUuid,
}: EditAutoApplyAccessorialRuleModalProps) => {
  const { contacts, getContactName } = useContacts();
  const { services } = useServices();
  const [accessorialsToUse, setAccessorialsToUse] = useState<
    Array<{ name: string; uuid: string }>
  >([]);

  const { data: autoApplyConfigData } = useAutoApplyAccessorialConfigQuery({
    variables: {
      uuid: configUuid,
    },
  });

  const [getAccessorialsForContact] =
    useAccessorialNamesByBillingContactLazyQuery();

  const { data: globalAccessorials } = useAccessorialNamesQuery({
    variables: {
      onlyShowCompanyAccessorials: true,
    },
  });

  const [
    editAutoApplyAccessorialConfig,
    { loading: editAutoApplyAccessorialConfigLoading },
  ] = useUpdateAutoApplyAccessorialConfigMutation({
    refetchQueries: [AutoApplyAccessorialConfigsDocument],
  });
  const [
    deleteAutoApplyAccessorialConfig,
    { loading: deleteAutoApplyAccessorialConfigLoading },
  ] = useDeleteAutoApplyAccessorialConfigMutation({
    refetchQueries: [AutoApplyAccessorialConfigsDocument],
  });

  const {
    formState: { errors },
    control,
    watch,
    setValue,
    handleSubmit,
  } = useEditAutoApplyAccessorialRuleForm();

  const ruleTypeValue = watch('ruleType');
  const stopTypeValue = watch('stopType');
  const contactValue = watch('contactUuid');

  const [accessorialPickerOpen, setAccessorialPickerOpen] = useState(false);

  const setContactAccessorials = useCallback(
    async (contactUuid: string) => {
      const contactAccessorials = await getAccessorialsForContact({
        variables: {
          billingPartyContactUuid: contactUuid,
        },
      });
      const contactAccessorialsData =
        contactAccessorials.data?.accessorialsByBillingContact;
      if (!isNil(contactAccessorialsData)) {
        setAccessorialsToUse(contactAccessorialsData);
      }
    },
    [getAccessorialsForContact, setAccessorialsToUse],
  );

  const handleDeleteConfig = async (uuid: string) => {
    await deleteAutoApplyAccessorialConfig({ variables: { uuid } });
    onClose(false);
  };

  useEffect(() => {
    if (
      !isNil(globalAccessorials) &&
      ruleTypeValue !== AutoApplyAccessorialRuleType.Contact
    ) {
      setAccessorialsToUse(globalAccessorials.accessorials);
    }
  }, [globalAccessorials, ruleTypeValue]);

  useEffect(() => {
    // if the contact values changed, get the contact accessorials, otherwise use the globals.
    if (!isNil(contactValue)) {
      setContactAccessorials(contactValue);
    }
  }, [contactValue, setContactAccessorials]);

  useEffect(() => {
    const config = autoApplyConfigData?.autoApplyAccessorialConfig;
    if (!isNil(config)) {
      const { contact } = config;
      if (!isNil(contact)) {
        setValue('contactUuid', contact.uuid);
      }
      const { service } = config;
      if (!isNil(service)) {
        setValue('serviceUuid', service.uuid);
      }
      const { accessorials } = config;
      if (!isEmpty(accessorials) && !isNil(contact)) {
        setContactAccessorials(contact.uuid);
      }
      setValue(
        'contactsToExcludeUuids',
        (config?.contactsToExcludeFromRule ?? []).map((c) => c.uuid),
      );
      setValue(
        'contactsToIncludeUuids',
        (config?.contactsToIncludeWithRule ?? []).map((c) => c.uuid),
      );
      setValue('stopType', config.stopType ?? PickupOrDelivery.Delivery);
      setValue('ruleType', config.ruleType);
      setValue(
        'accessorialUuids',
        config.accessorials.map((acc) => acc.uuid),
      );
    }
  }, [setValue, autoApplyConfigData, setContactAccessorials]);

  const onSubmit: SubmitHandler<
    EditAutoApplyAccessorialRuleFormValues
  > = async (formValues) => {
    const {
      accessorialUuids,
      ruleType,
      serviceUuid,
      stopType,
      contactUuid,
      contactsToIncludeUuids,
      contactsToExcludeUuids,
    } = formValues;
    try {
      if (accessorialUuids.length === 0) {
        setFailedToEditConfig(true);
        return;
      }
      await editAutoApplyAccessorialConfig({
        variables: {
          updateAutoApplyAccessorialConfigInput: {
            uuid: configUuid,
            accessorialUuid: accessorialUuids[0] ?? '',
            accessorialUuids,
            ruleType,
            serviceUuid:
              ruleType === AutoApplyAccessorialRuleType.Service ||
              ruleType === AutoApplyAccessorialRuleType.ContactAndService
                ? serviceUuid
                : undefined,
            stopType:
              ruleType === AutoApplyAccessorialRuleType.StopType
                ? stopType
                : undefined,
            contactUuid:
              ruleType === AutoApplyAccessorialRuleType.Contact ||
              ruleType === AutoApplyAccessorialRuleType.ContactAndService
                ? contactUuid
                : undefined,
            contactsToExcludeUuids,
            contactsToIncludeUuids,
          },
        },
      });
      setShowEditedConfig(true);
      onClose(false);
    } catch {
      setFailedToEditConfig(true);
      onClose(false);
    }
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <span>Edit auto apply accessorial rule</span>
          <Button
            variant="contained"
            color="error"
            disabled={deleteAutoApplyAccessorialConfigLoading}
            onClick={async () => handleDeleteConfig(configUuid)}
          >
            Delete
          </Button>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name="accessorialUuids"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel id="accessorials">Accessorial(s)</InputLabel>
                  <Select
                    multiple
                    multiline
                    labelId="accessorials"
                    size="small"
                    open={accessorialPickerOpen}
                    value={value}
                    sx={{ width: '100%' }}
                    error={!isNil(errors.accessorialUuids)}
                    MenuProps={MenuProps}
                    onChange={(e) => {
                      onChange(e);
                      setAccessorialPickerOpen(false);
                    }}
                    onOpen={() => {
                      setAccessorialPickerOpen(true);
                    }}
                    onClose={() => {
                      setAccessorialPickerOpen(false);
                    }}
                  >
                    {accessorialsToUse?.map((accessorial) => {
                      return (
                        <MenuItem
                          key={accessorial.uuid}
                          value={accessorial.uuid}
                        >
                          {accessorial.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {!isNil(errors.accessorialUuids) && (
                    <FormHelperText sx={{ color: '#D32F2F' }}>
                      {errors.accessorialUuids.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="ruleType"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <InputLabel id="age">Rule Type</InputLabel>
                  <Select
                    disabled
                    size="small"
                    value={value}
                    sx={{ width: '100%' }}
                    error={!isNil(errors.ruleType)}
                    onChange={onChange}
                  >
                    {values(AutoApplyAccessorialRuleType).map((rt) => {
                      return (
                        <MenuItem key={rt} value={rt}>
                          {getRuleTypeLabel(rt)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {!isNil(errors.ruleType) && (
                    <FormHelperText sx={{ color: '#D32F2F' }}>
                      {errors.ruleType.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
          {}
          {ruleTypeValue === AutoApplyAccessorialRuleType.Contact ? (
            <Grid item xs={12}>
              <Controller
                name="contactUuid"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <InputLabel id="age">Customer</InputLabel>
                    <Select
                      size="small"
                      value={value}
                      sx={{ width: '100%' }}
                      error={!isNil(errors.contactUuid)}
                      placeholder="Select contact"
                      MenuProps={MenuProps}
                      onChange={onChange}
                    >
                      {contacts?.map((contact) => {
                        return (
                          <MenuItem key={contact.uuid} value={contact.uuid}>
                            {contact.displayName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {!isNil(errors.contactUuid) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.contactUuid.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </Grid>
          ) : ruleTypeValue === AutoApplyAccessorialRuleType.StopType ? (
            <Grid item xs={12}>
              <Controller
                name="stopType"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <>
                      <InputLabel id="age">Stop Type</InputLabel>
                      <Select
                        size="small"
                        value={
                          // this was needed for some reason instead of just value, idk why
                          Object.values(PickupOrDelivery).includes(value)
                            ? value
                            : stopTypeValue
                        }
                        sx={{ width: '100%' }}
                        error={!isNil(errors.stopType)}
                        MenuProps={MenuProps}
                        onChange={() => {
                          onChange();
                        }}
                      >
                        {values(PickupOrDelivery).map((stopType) => {
                          return (
                            <MenuItem key={stopType} value={stopType}>
                              {sentenceCase(stopType)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      {!isNil(errors.stopType) && (
                        <FormHelperText sx={{ color: '#D32F2F' }}>
                          {errors.stopType.message}
                        </FormHelperText>
                      )}
                    </>
                  );
                }}
              />
            </Grid>
          ) : ruleTypeValue === AutoApplyAccessorialRuleType.Service ? (
            <Grid item xs={12}>
              <Controller
                name="serviceUuid"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <InputLabel id="age">Service</InputLabel>
                    <Select
                      size="small"
                      value={value}
                      sx={{ width: '100%' }}
                      error={!isNil(errors.serviceUuid)}
                      placeholder="Select service"
                      MenuProps={MenuProps}
                      onChange={onChange}
                    >
                      {services.map((service) => {
                        return (
                          <MenuItem key={service.uuid} value={service.uuid}>
                            {service.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {!isNil(errors.serviceUuid) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.serviceUuid.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </Grid>
          ) : ruleTypeValue ===
            AutoApplyAccessorialRuleType.ContactAndService ? (
            <Grid item xs={12}>
              <Controller
                name="contactUuid"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <InputLabel id="age">Customer</InputLabel>
                    <Select
                      size="small"
                      value={value}
                      sx={{ width: '100%' }}
                      error={!isNil(errors.contactUuid)}
                      placeholder="Select customer"
                      MenuProps={MenuProps}
                      onChange={onChange}
                    >
                      {contacts?.map((contact) => {
                        return (
                          <MenuItem key={contact.uuid} value={contact.uuid}>
                            {contact.displayName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {!isNil(errors.contactUuid) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.contactUuid.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
              <Controller
                name="serviceUuid"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <>
                    <InputLabel id="age">Service</InputLabel>
                    <Select
                      size="small"
                      value={value}
                      sx={{ width: '100%' }}
                      error={!isNil(errors.serviceUuid)}
                      placeholder="Select service"
                      MenuProps={MenuProps}
                      onChange={onChange}
                    >
                      {services.map((service) => {
                        return (
                          <MenuItem key={service.uuid} value={service.uuid}>
                            {sentenceCase(service.name)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {!isNil(errors.serviceUuid) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.serviceUuid.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </Grid>
          ) : null}
          {!CONTACT_BASED_AUTO_APPLY_ACCESSORIAL_RULES.includes(
            ruleTypeValue,
          ) && (
            <Grid item xs={12}>
              <Controller
                name="contactsToIncludeUuids"
                control={control}
                render={({ field: { value } }) => (
                  <>
                    <InputLabel id="age">
                      Customers included with this rule
                    </InputLabel>
                    <Select
                      multiple
                      size="small"
                      label="Customers to include"
                      MenuProps={MenuProps}
                      value={value ?? []}
                      sx={{ width: '100%' }}
                      renderValue={(selected) => {
                        if (isArray(selected)) {
                          return (
                            <Box>
                              {selected.map((contactUuid, idx) => {
                                if (idx < 4) {
                                  return (
                                    <Chip
                                      key={contactUuid}
                                      label={getContactName(contactUuid)}
                                    />
                                  );
                                }
                                if (idx === 4) {
                                  return (
                                    <Chip
                                      key={contactUuid}
                                      label={`+ ${selected.length - idx}`}
                                    />
                                  );
                                }
                                return null;
                              })}
                            </Box>
                          );
                        }
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        return <></>;
                      }}
                      onChange={(event) => {
                        setValue(
                          'contactsToIncludeUuids',
                          event.target.value as string[],
                        );
                      }}
                    >
                      {contacts?.map((contact) => (
                        <MenuItem key={contact.uuid} value={contact.uuid}>
                          {contact.displayName}
                        </MenuItem>
                      ))}
                    </Select>
                    {!isNil(errors.contactUuid) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.contactUuid.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
              <Controller
                name="contactsToExcludeUuids"
                control={control}
                render={({ field: { value } }) => (
                  <>
                    <InputLabel id="age">
                      Customers to exclude from this rule
                    </InputLabel>
                    <Select
                      multiple
                      size="small"
                      label="Contacts to exclude"
                      MenuProps={MenuProps}
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      value={value ?? []}
                      sx={{ width: '100%' }}
                      renderValue={(selected) => {
                        if (isArray(selected)) {
                          return (
                            <Box>
                              {selected.map((contactUuid, idx) => {
                                if (idx < 4) {
                                  return (
                                    <Chip
                                      key={contactUuid}
                                      label={getContactName(contactUuid)}
                                    />
                                  );
                                }
                                if (idx === 4) {
                                  return (
                                    <Chip
                                      key={contactUuid}
                                      label={`+ ${selected.length - idx}`}
                                    />
                                  );
                                }
                                return null;
                              })}
                            </Box>
                          );
                        }
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        return <></>;
                      }}
                      onChange={(event) => {
                        setValue(
                          'contactsToExcludeUuids',
                          event.target.value as string[],
                        );
                      }}
                    >
                      {contacts?.map((contact) => (
                        <MenuItem key={contact.uuid} value={contact.uuid}>
                          {contact.displayName}
                        </MenuItem>
                      ))}
                    </Select>
                    {!isNil(errors.contactUuid) && (
                      <FormHelperText sx={{ color: '#D32F2F' }}>
                        {errors.contactUuid.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            onClose(false);
          }}
        >
          Close
        </Button>
        <Button
          variant="contained"
          disabled={editAutoApplyAccessorialConfigLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAutoApplyAccessorialRuleModal;
