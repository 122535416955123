import {
  Box,
  Checkbox,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  type TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import pluralize from 'pluralize';
import {
  getManifestDimWeight,
  getManifestPieces,
  getManifestWeight,
  type LineHaulManifest,
  type NonEmptyArray,
} from '../utils';
import ManifestLaneGroupHeader from './manifest-lane-group-header';
import { type Dayjs } from 'dayjs';
import useLineHaulDispatchStore from '../../../store/line-haul-dispatch-store';
import ManifestStatusChip from '../../manifest-status-chip';
import { useSearchParams } from 'react-router-dom';
import theme from '../../../../../theme';

const CHECKBOX_TABLE_CELL_WIDTH = '30px';
const MANIFEST_TABLE_CELL_WIDTH = '125px';
const STATUS_TABLE_CELL_WIDTH = '100px';

const StyledTableRow = styled(TableRow)({
  '&:last-child td': {
    borderBottom: 'none !important',
  },
});

const Container = styled(Stack)(({ theme }) => ({
  border: `1px solid #E5E7EB`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.concreteGrey[20],
}));

const ManifestTableCell = ({ children, ...props }: TableCellProps) => {
  return (
    <TableCell sx={{ padding: '0px 4px 0px 4px' }} {...props}>
      <Box
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        width="100%"
      >
        {children}
      </Box>
    </TableCell>
  );
};

const HeaderTableCell = styled(ManifestTableCell)(() => ({
  color: '#757575',
  fontSize: '12px',
}));

const AggregateTableCell = styled(ManifestTableCell)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 700,
  color: theme.palette.concreteGrey[50],
}));

type ManifestLaneGroupProps = {
  readonly manifests: NonEmptyArray<LineHaulManifest>;
  readonly departDate: Dayjs;
};

const ManifestLaneGroup = ({
  manifests,
  departDate,
}: ManifestLaneGroupProps) => {
  const [
    selectedManifestUuids,
    selectManifestUuids,
    deselectAllManifestUuids,
    deselectManifestUuid,
  ] = useLineHaulDispatchStore((state) => [
    state.selectedManifestUuids,
    state.selectManifestUuids,
    state.deselectAllManifestUuids,
    state.deselectManifestUuid,
  ]);
  const [_, setSearchParams] = useSearchParams();

  const allSelected = manifests.every((m) =>
    selectedManifestUuids.includes(m.uuid),
  );
  const someSelected = manifests.some((m) =>
    selectedManifestUuids.includes(m.uuid),
  );

  const handleSelectAll = () => {
    if (allSelected) {
      deselectAllManifestUuids();
    } else {
      selectManifestUuids(manifests.map((m) => m.uuid));
    }
  };

  const handleSelectManifest = (uuid: string) => {
    if (selectedManifestUuids.includes(uuid)) {
      deselectManifestUuid(uuid);
    } else {
      selectManifestUuids([uuid]);
    }
  };

  return (
    <Container>
      <ManifestLaneGroupHeader manifests={manifests} departDate={departDate} />
      <TableContainer style={{ backgroundColor: 'white' }}>
        <Table size="small" style={{ tableLayout: 'fixed' }}>
          <TableHead>
            <StyledTableRow style={{ whiteSpace: 'nowrap' }}>
              <TableCell sx={{ padding: 0, width: CHECKBOX_TABLE_CELL_WIDTH }}>
                <Checkbox
                  color="primary"
                  checked={allSelected}
                  indeterminate={!allSelected && someSelected}
                  onChange={() => {
                    handleSelectAll();
                  }}
                />
              </TableCell>
              <HeaderTableCell sx={{ width: MANIFEST_TABLE_CELL_WIDTH }}>
                Manifest
              </HeaderTableCell>
              <HeaderTableCell align="right">Packages</HeaderTableCell>
              <HeaderTableCell align="right">Orders</HeaderTableCell>
              <HeaderTableCell align="right">Weight (lbs)</HeaderTableCell>
              <HeaderTableCell align="right">DIM wt.</HeaderTableCell>
              <HeaderTableCell sx={{ width: STATUS_TABLE_CELL_WIDTH }}>
                Status
              </HeaderTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {manifests.map((manifest) => (
              <StyledTableRow
                key={manifest.uuid}
                hover
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  setSearchParams({ manifestUuid: manifest.uuid });
                }}
              >
                <TableCell
                  sx={{ padding: 0, width: CHECKBOX_TABLE_CELL_WIDTH }}
                >
                  <Checkbox
                    color="primary"
                    checked={selectedManifestUuids.includes(manifest.uuid)}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(e) => {
                      handleSelectManifest(manifest.uuid);
                    }}
                  />
                </TableCell>
                <ManifestTableCell>
                  {manifest.referenceNumber}
                </ManifestTableCell>
                <ManifestTableCell align="right">
                  {getManifestPieces(manifest)}
                </ManifestTableCell>
                <ManifestTableCell align="right">
                  {manifest.orders.length}
                </ManifestTableCell>
                <ManifestTableCell align="right">
                  {getManifestWeight(manifest).toFixed(2)}
                </ManifestTableCell>
                <ManifestTableCell align="right">
                  {getManifestDimWeight(manifest).toFixed(2)}
                </ManifestTableCell>
                <TableCell sx={{ paddingLeft: theme.spacing(0.25) }}>
                  <ManifestStatusChip status={manifest.status} />
                </TableCell>
              </StyledTableRow>
            ))}
            <StyledTableRow>
              <AggregateTableCell />
              <AggregateTableCell sx={{ width: MANIFEST_TABLE_CELL_WIDTH }}>
                {manifests.length} {pluralize('manifest', manifests.length)}{' '}
                total
              </AggregateTableCell>
              <AggregateTableCell align="right">
                {manifests.reduce(
                  (sum, manifest) => sum + getManifestPieces(manifest),
                  0,
                )}
              </AggregateTableCell>
              <AggregateTableCell align="right">
                {manifests.reduce(
                  (sum, manifest) => sum + manifest.orders.length,
                  0,
                )}
              </AggregateTableCell>
              <AggregateTableCell align="right">
                {manifests
                  .reduce(
                    (sum, manifest) => sum + getManifestWeight(manifest),
                    0,
                  )
                  .toFixed(2)}
              </AggregateTableCell>
              <AggregateTableCell align="right">
                {manifests
                  .reduce(
                    (sum, manifest) => sum + getManifestDimWeight(manifest),
                    0,
                  )
                  .toFixed(2)}
              </AggregateTableCell>
              <AggregateTableCell align="right" />
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default ManifestLaneGroup;
