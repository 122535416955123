import { Stack, Tab, Tabs, useTheme } from '@mui/material';
import { type SyntheticEvent, useMemo, useState } from 'react';
import { PaddingTabPanel } from '../../common/components/tab-panel';
import {
  OrganizationType,
  useOrganizationQuery,
} from '../../generated/graphql';
import BillingPanel from '../contacts/components/billing-panel';
import NotificationsPanel from '../contacts/components/notifications/notifications-panel';
import ThirdPartyUsersPanel from '../contacts/components/third-party-users-panel';
import {
  OrganizationPageMode,
  type OrganizationPageProps,
  OrganizationPageTabs,
} from './enums';
import GeneralTab from './organization-tabs/general/general-tab';
import AddressBookTab from './organization-tabs/address-book/address-book-tab';
import { isNil } from 'lodash';
import PeopleTab from './organization-tabs/people-tab/people-tab';
import { useWarningOnExit } from '../../common/react-hooks/warning-on-exit';

const OrganizationPage = (props: OrganizationPageProps) => {
  const { data, loading: dataLoading } = useOrganizationQuery({
    skip: props.mode === OrganizationPageMode.CREATE,
    variables: {
      input: { uuid: props.uuid ?? '' },
    },
  });

  const [isGeneralTabFormDirty, setIsGeneralTabFormDirty] = useState(false);

  const [currentTab, setCurrentTab] = useState<OrganizationPageTabs>(
    OrganizationPageTabs.GENERAL,
  );
  const [pendingTabChange, setPendingTabChange] =
    useState<OrganizationPageTabs | null>(null);

  const theme = useTheme();

  const handleChangeTab = (
    event: SyntheticEvent,
    newValue: OrganizationPageTabs,
  ) => {
    if (
      isGeneralTabFormDirty &&
      currentTab === OrganizationPageTabs.GENERAL &&
      newValue !== OrganizationPageTabs.GENERAL
    ) {
      setPendingTabChange(newValue);
    } else {
      setCurrentTab(newValue);
    }
  };

  // show warning if the user is on the general tab and has made changes
  // and is trying to leave the page
  useWarningOnExit(
    isGeneralTabFormDirty &&
      currentTab === OrganizationPageTabs.GENERAL &&
      props.mode === OrganizationPageMode.EDIT,
    'Are you sure you want to leave? This organization has unsaved changes.',
  );

  const showCustomerOnlyTabs =
    props.mode === OrganizationPageMode.EDIT &&
    (data?.organization?.types ?? []).includes(OrganizationType.Customer);

  const tabVisibility = useMemo(() => {
    const isEditMode = props.mode === OrganizationPageMode.EDIT;
    const organizationTypes = data?.organization?.types ?? [];

    const isCustomer = organizationTypes.includes(OrganizationType.Customer);

    if (!isEditMode) {
      return {
        showBilling: false,
        showNotifications: false,
        showThirdPartyUsers: false,
        showPeople: false,
        showAddressBook: false,
      };
    }

    return {
      showBilling: isCustomer,
      showNotifications: isCustomer,
      showThirdPartyUsers: isCustomer,
      showPeople: true,
      showAddressBook: true,
    };
  }, [props.mode, data?.organization?.types]);

  return (
    <Stack
      width="100%"
      height="100%"
      flexDirection="column"
      p={1}
      bgcolor={theme.palette.concreteGrey[10]}
    >
      <Tabs
        value={currentTab}
        aria-label="add-contact-tabs"
        onChange={handleChangeTab}
      >
        <Tab
          // Leads to weird states if navigating away in the middle of creating a new contact or address
          label="General"
          value={OrganizationPageTabs.GENERAL}
        />
        {tabVisibility.showBilling && (
          <Tab label="Billing" value={OrganizationPageTabs.BILLING} />
        )}
        {tabVisibility.showNotifications && (
          <Tab
            label="Notifications"
            value={OrganizationPageTabs.NOTIFICATIONS}
          />
        )}
        {tabVisibility.showThirdPartyUsers && (
          <Tab
            label="Third party users"
            value={OrganizationPageTabs.THIRD_PARTY_USERS}
          />
        )}
        {tabVisibility.showPeople && (
          <Tab label="People" value={OrganizationPageTabs.PEOPLE} />
        )}
        {tabVisibility.showAddressBook && (
          <Tab label="Address book" value={OrganizationPageTabs.ADDRESS_BOOK} />
        )}
      </Tabs>
      <PaddingTabPanel
        panelValue={OrganizationPageTabs.GENERAL}
        selectedValue={currentTab}
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto',
        }}
      >
        <GeneralTab
          pageProps={props}
          organizationData={data?.organization ?? null}
          dataLoading={dataLoading}
          setPendingTabChange={setPendingTabChange}
          setIsGeneralTabFormDirty={setIsGeneralTabFormDirty}
          pendingTabChange={pendingTabChange}
          completePendingTabChange={() => {
            if (!isNil(pendingTabChange)) {
              setCurrentTab(pendingTabChange);
            }
            setPendingTabChange(null);
          }}
        />
      </PaddingTabPanel>
      <PaddingTabPanel
        panelValue={OrganizationPageTabs.BILLING}
        selectedValue={currentTab}
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
      >
        {props.mode === OrganizationPageMode.EDIT && (
          <BillingPanel contactUuid={props.uuid} />
        )}
      </PaddingTabPanel>
      <PaddingTabPanel
        panelValue={OrganizationPageTabs.NOTIFICATIONS}
        selectedValue={currentTab}
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
      >
        {props.mode === OrganizationPageMode.EDIT && (
          <NotificationsPanel contactUuid={props.uuid} />
        )}
      </PaddingTabPanel>
      <PaddingTabPanel
        panelValue={OrganizationPageTabs.THIRD_PARTY_USERS}
        selectedValue={currentTab}
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
      >
        {props.mode === OrganizationPageMode.EDIT && (
          <ThirdPartyUsersPanel contactUuid={props.uuid} />
        )}
      </PaddingTabPanel>
      <PaddingTabPanel
        panelValue={OrganizationPageTabs.PEOPLE}
        selectedValue={currentTab}
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
      >
        {props.mode === OrganizationPageMode.EDIT && (
          <PeopleTab organizationUuid={props.uuid} />
        )}
      </PaddingTabPanel>
      <PaddingTabPanel
        panelValue={OrganizationPageTabs.ADDRESS_BOOK}
        selectedValue={currentTab}
        sx={{ width: '100%', height: '100%', overflow: 'auto' }}
      >
        {props.mode === OrganizationPageMode.EDIT && (
          <AddressBookTab organizationUuid={props.uuid} />
        )}
      </PaddingTabPanel>
    </Stack>
  );
};

export default OrganizationPage;
