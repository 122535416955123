import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { v4 } from 'uuid';
import useMe from '../../../../../../../../common/react-hooks/use-me';
import {
  type AccessorialType,
  CustomChargeBillingMethod,
  ShipmentType,
  StopType,
  useAccessorialsForCustomChargeRowQuery,
} from '../../../../../../../../generated/graphql';
import { getAccessorialTypeFromTypename } from '../../../../../../../management/components/accessorials/common';
import { StyledTableRow } from '../../../../components/common/styled-table-row';
import {
  type CustomCostValues,
  type OrderFormFieldValues,
} from '../../../../forms/types';
import { type OrderRegularShipmentContext } from '../../../../types';
import CustomChargeRow from '../../../charges/components/custom-charge/custom-charge-row';
import { CUSTOM_CHARGE_KEY } from '../../../charges/components/custom-charge/labels';
import FreightChargeRow from '../../../charges/components/freight-charge-row';
import FuelChargeRow from '../../../charges/components/fuel-charges/fuel-charge-row';

export const DriverSettlementEditor = ({
  stopIdx,
  settlementBillLineItemIdx,
  editingDisabled,
}: {
  readonly stopIdx: number;
  readonly settlementBillLineItemIdx: number;
  readonly editingDisabled: boolean;
}) => {
  const theme = useTheme();
  const { companyConfiguration } = useMe();
  const { control, setValue } = useFormContext<OrderFormFieldValues>();

  const contactUuid: string = useWatch({ control, name: 'contactUuid' });
  const stopType = useWatch({ control, name: `stops.${stopIdx}.stopType` });
  const terminalUuid = useWatch({
    control,
    name: `stops.${stopIdx}.terminalUuid`,
  });
  const lineItem = useWatch({
    control,
    name: `stops.${stopIdx}.settlementBillLineItems.${settlementBillLineItemIdx}`,
  });
  const customCosts =
    useWatch({
      control,
      name: `stops.${stopIdx}.settlementBillLineItems.${settlementBillLineItemIdx}.customCosts`,
    }) ?? [];

  const { data: accessorialsForCustomChargeData } =
    useAccessorialsForCustomChargeRowQuery({
      fetchPolicy: 'cache-first',
      variables: {
        billingPartyContactUuid: contactUuid,
        terminalUuid,
      },
      skip: isNil(contactUuid),
    });
  const accessorialsForCustomCharge =
    accessorialsForCustomChargeData?.accessorialsByBillingContact ?? [];
  const defaultChargeToAccessorial =
    companyConfiguration?.defaultToAccessorial === true;

  const [newlyAddedCharge, setNewlyAddedCharge] = useState(false);

  const onAddCost = (accessorialOption?: {
    // The value is either the UUID of the accessorial or CUSTOM_CHARGE_KEY.
    value: string;
    label: string;
  }) => {
    let accessorialUuid: string | null = null;
    let accessorialType: AccessorialType | null = null;
    const isAccessorial =
      !isNil(accessorialOption) &&
      accessorialOption.value !== CUSTOM_CHARGE_KEY;
    if (isAccessorial) {
      accessorialUuid = accessorialOption.value;
      const accessorial = accessorialsForCustomCharge.find(
        (itrAccessorial) => itrAccessorial.uuid === accessorialUuid,
      );
      if (!isNil(accessorial?.__typename)) {
        accessorialType = getAccessorialTypeFromTypename(
          accessorial.__typename,
        );
      }
    }
    const newCustomCost: CustomCostValues = {
      quantity: 1,
      rate: 0,
      totalCharge: 0,
      isAutoApplied: false,
      accessorialUuid,
      accessorialType,
      specialAccessorialMatrixItemUuid: null,
      zoneBasedAccessorialMatrixItemUuid: null,
      fuelSurchargePercentageRate: 0,
      billingMethod:
        isAccessorial || defaultChargeToAccessorial
          ? CustomChargeBillingMethod.Accessorial
          : CustomChargeBillingMethod.AdHoc,
      uuid: v4(),
      isLocal: true,
      name: accessorialOption?.label ?? '',
      accessorialName: accessorialOption?.label ?? null,
      zoneUuid: null,
      chargeGroupUuid: null,
      accessorialRangeUuid: null,
      postedFuelSurchargeRate: null,
      description: null,
      authoCode: null,
      useAccessorialRate: true,
    };

    if (defaultChargeToAccessorial) {
      setNewlyAddedCharge(true);
    }

    setValue(
      `stops.${stopIdx}.settlementBillLineItems.${settlementBillLineItemIdx}.customCosts`,
      [...customCosts, newCustomCost],
    );
  };

  if (isNil(lineItem) || isNil(lineItem?.driverUuid)) {
    return null;
  }

  const context: OrderRegularShipmentContext = {
    shipmentType: ShipmentType.Regular,
    stopIdx,
    inSettlement: true,
    settlementBillLineItemIdx,
    settlementBillFinalizedDate: lineItem.settlementBillFinalizedDate,
  };

  return (
    <Stack spacing={1} paddingBottom={1}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <StyledTableRow>
              <TableCell>Type</TableCell>
              <TableCell>Rate</TableCell>
              <TableCell width="150">Quantity</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>Total</TableCell>
              <TableCell />
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {stopType !== StopType.Recovery && (
              <FreightChargeRow
                context={context}
                inBillingReview={false}
                hideFromBilling={false}
              />
            )}
            {
              <>
                {stopType !== StopType.Recovery && (
                  <FuelChargeRow context={context} idx={stopIdx} />
                )}
                {lineItem.customCosts?.map(({ uuid }, customChargeIdx) => (
                  <CustomChargeRow
                    // because of the way we access custom charges inside custom-charge-row (using the index syntax),
                    // we have to key on that index to avoid staleness when the array changes size
                    // eslint-disable-next-line react/no-array-index-key
                    key={customChargeIdx}
                    context={context}
                    customChargeIdx={customChargeIdx}
                    accessorials={accessorialsForCustomCharge}
                    inBillingReview={false}
                    newlyAddedCharge={newlyAddedCharge}
                    setNewlyAddedCharge={setNewlyAddedCharge}
                  />
                ))}
                <StyledTableRow isCompact>
                  <TableCell colSpan={5}>
                    <Button
                      disabled={editingDisabled}
                      onClick={() => {
                        onAddCost();
                      }}
                    >
                      + Add charge
                    </Button>
                  </TableCell>
                </StyledTableRow>
              </>
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
