import {
  Card,
  CardContent,
  Menu,
  Stack,
  styled,
  Tab,
  Tabs,
} from '@mui/material';
import type * as React from 'react';
import { type ReactNode, useState } from 'react';
import TabPanel from '../../../../../common/components/tab-panel/tab-panel';
import {
  type InvoiceSendJobBatchFragment,
  type ReportSendJobBatchFragment,
} from '../../../../../generated/graphql';
import ReportSendJobsBatchList from '../../../../reports/components/batch-send/report-send-jobs-batch-list';
import ReportSendJobsModal from '../../../../reports/components/batch-send/report-send-jobs-modal';
import useInvoicesStore from '../../../invoices-store';
import InvoiceSendJobsBatchList from './invoice-send-jobs-batch-list';
import InvoiceSendJobsModal from './invoice-send-jobs-modal';

type InvoiceSendMenuProps = {
  readonly open: boolean;
  readonly setOpen: (isOpen: boolean) => void;
  readonly anchorEl: React.RefObject<HTMLAnchorElement>;
};

const CardContentSpecialPadding = styled(CardContent)(`
  padding: 10px;
  padding-top: 0px;
  &:last-child {
    padding-bottom: 5px;
  }
`);

type TabContent = {
  label: string;
  component: ReactNode;
  tabVisibleCondition?: boolean | null;
  // Defaults to true.
  panelPadding?: boolean;
};

const InvoiceSendMenu = ({ open, setOpen, anchorEl }: InvoiceSendMenuProps) => {
  const [invoiceMenuTabIndex, setInvoiceMenuTabIndex] = useInvoicesStore(
    (state) => [state.invoiceMenuTabIndex, state.setInvoiceMenuTabIndex],
  );

  const [selectedInvoiceSendJobBatch, setSelectedInvoiceSendJobBatch] =
    useState<InvoiceSendJobBatchFragment | undefined>(undefined);

  const [selectedReportSendJobBatch, setSelectedReportSendJobBatch] = useState<
    ReportSendJobBatchFragment | undefined
  >(undefined);

  const TAB_COMPONENTS: TabContent[] = [
    {
      label: 'Invoices',
      component: (
        <>
          <InvoiceSendJobsBatchList
            open={open}
            setSelectedInvoiceSendJobBatch={setSelectedInvoiceSendJobBatch}
          />
          <InvoiceSendJobsModal
            selectedInvoiceSendJobBatch={selectedInvoiceSendJobBatch}
            setSelectedInvoiceSendJobBatch={setSelectedInvoiceSendJobBatch}
          />
        </>
      ),
    },
    {
      label: 'Statements',
      component: (
        <>
          <ReportSendJobsBatchList
            open={open}
            setSelectedReportSendJobBatch={setSelectedReportSendJobBatch}
          />
          <ReportSendJobsModal
            selectedReportSendJobBatch={selectedReportSendJobBatch}
            setSelectedReportSendJobBatch={setSelectedReportSendJobBatch}
          />
        </>
      ),
    },
  ];

  return (
    <Menu
      id="file-downloads-menu"
      anchorEl={anchorEl.current}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Card sx={{ border: 'none', boxShadow: 'none', width: '1000px' }}>
        <CardContentSpecialPadding>
          <Stack direction="column">
            <Tabs
              orientation="horizontal"
              variant="scrollable"
              value={invoiceMenuTabIndex}
              scrollButtons="auto"
              sx={{ pt: 3 }}
              onChange={(e, newIndex) => {
                setInvoiceMenuTabIndex(newIndex);
              }}
            >
              {TAB_COMPONENTS.map((tabComponent) => (
                <Tab
                  key={tabComponent.label}
                  sx={{ alignItems: 'start' }}
                  label={tabComponent.label}
                />
              ))}
            </Tabs>
            {TAB_COMPONENTS.map((tabComponent, idx) => (
              <TabPanel
                key={tabComponent.label}
                selectedValue={invoiceMenuTabIndex}
                panelValue={idx}
              >
                {tabComponent.component}
              </TabPanel>
            ))}
          </Stack>
        </CardContentSpecialPadding>
      </Card>
    </Menu>
  );
};

export default InvoiceSendMenu;
