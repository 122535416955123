import { Box, Button, Stack, useTheme } from '@mui/material';
import { isEmpty, isNil, noop } from 'lodash';
import { useFormContext, useFormState } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { GappedStackCard } from '../../../../common/components/gapped-stack-card';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import { useWarningOnExit } from '../../../../common/react-hooks/warning-on-exit';
import SaveButton from '../../../orders/components/order-form/components/save-button';
import { OrderFormEditAccessProvider } from '../../../orders/components/order-form/contexts/order-form-edit-access-context';
import { StopMethod } from '../../../orders/components/order-form/forms/stop-type';
import { OrderFormEditAccess } from '../../../orders/components/order-form/forms/use-order-form-edit-access';
import OrderPageToolbar from '../../../orders/components/order-form/order-page-toolbar';
import { CUSTOMER_PORTAL_ORDERS_PATH } from '../../constants';
import useContactsForMeAsThirdPartyUser from '../../hooks/use-contacts-for-me-as-third-party-user';
import { useCustomerPortalOrderFormContext } from './contexts/customer-portal-order-form-context';
import CustomerPortalCompanyField from './customer-portal-company-field';
import CustomerPortalContactField from './customer-portal-contact-field';
import CustomerPortalDocumentsField from './customer-portal-documents-field';
import CustomerPortalHandlingField from './customer-portal-handling-field';
import CustomerPortalOrderDetails from './customer-portal-order-details';
import CustomerPortalPackages from './customer-portal-packages';
import CustomerPortalStopDetails from './customer-portal-stop-details';
import { type CustomerPortalOrderFormValues } from './forms/types';

type CustomerPortalOrderFormProps = {
  readonly contactUuid: string;
  readonly rightPanelContent?: React.ReactNode;
} & (
  | {
      // Typed this way so that we don't have to come up with meaningful
      // values for setContactUuid and onSubmit when the form is disabled
      readonly disabled: true;
      readonly setContactUuid?: undefined;
      readonly onSubmit?: undefined;
    }
  | {
      readonly disabled?: false;
      readonly setContactUuid: (contactUuid: string) => void;
      readonly onSubmit: () => Promise<void>;
    }
);

const CustomerPortalOrderForm = ({
  contactUuid,
  setContactUuid,
  onSubmit,
  disabled = false,
  rightPanelContent,
}: CustomerPortalOrderFormProps) => {
  const { contacts: contactsWithOrderEntryEnabled } =
    useContactsForMeAsThirdPartyUser({
      filterByCustomerPortalOrderEntryEnabled: true,
    });

  const { control } = useFormContext<CustomerPortalOrderFormValues>();
  const { isSubmitting, isSubmitSuccessful, dirtyFields } =
    useFormState<CustomerPortalOrderFormValues>({ control });

  const ffWhiteLabelCustomerPortal = useFeatureFlag(
    FeatureFlag.FF_WHITE_LABEL_CUSTOMER_PORTAL,
  );
  // RHF sometimes sets isDirty when a field is `undefined` but not present in
  // the default values. This is closer to the behavior we want.
  // https://github.com/react-hook-form/react-hook-form/issues/4740
  const isDirty = !isEmpty(dirtyFields);

  const navigate = useNavigate();

  const { companyUuid, logisticsSectionEnabled } =
    useCustomerPortalOrderFormContext();

  const setCompany = (companyUuid: string) => {
    const firstContactForNewCompany = contactsWithOrderEntryEnabled.find(
      (c) => c.company.uuid === companyUuid,
    );
    if (isNil(firstContactForNewCompany)) {
      return;
    }
    setContactUuid?.(firstContactForNewCompany.uuid);
  };

  const theme = useTheme();

  useWarningOnExit(
    !disabled && isDirty && !isSubmitting && !isSubmitSuccessful,
    'Are you sure you want to leave? This order has unsaved changes.',
  );

  return (
    <OrderFormEditAccessProvider
      value={disabled ? OrderFormEditAccess.None : OrderFormEditAccess.All}
    >
      <Stack height="100%">
        <OrderPageToolbar>
          <Stack
            flexGrow={1}
            direction="row"
            gap={2}
            alignItems="center"
            flexWrap="wrap"
          >
            <Button
              variant="text"
              onClick={() => {
                navigate(CUSTOMER_PORTAL_ORDERS_PATH);
              }}
            >
              Back
            </Button>
            <Box sx={{ flexGrow: 1, maxWidth: '380px' }}>
              <CustomerPortalCompanyField
                required
                disabled={ffWhiteLabelCustomerPortal}
                companyUuid={companyUuid}
                label="Carrier"
                onChange={setCompany}
              />
            </Box>
            <CustomerPortalContactField
              contactUuid={contactUuid ?? null}
              onChange={setContactUuid ?? noop}
            />
          </Stack>
          {!isNil(onSubmit) && (
            <SaveButton
              isEditMode={false}
              saveAndExitCopy="Book order"
              onSubmit={onSubmit}
            />
          )}
        </OrderPageToolbar>
        <Stack height="100%" minHeight={0} direction="row">
          <GappedStackCard
            style={{
              flex: 2,
              borderRight: `1px solid ${theme.palette.borderColor.main}`,
              overflowY: 'auto',
            }}
          >
            <CustomerPortalOrderDetails disabled={disabled} />
            <Stack flexDirection="row" flexWrap="wrap" gap="1px">
              <CustomerPortalStopDetails stopMethod={StopMethod.Inbound} />
              <CustomerPortalStopDetails stopMethod={StopMethod.Outbound} />
            </Stack>
            <CustomerPortalPackages disabled={disabled} />
            {logisticsSectionEnabled && (
              <CustomerPortalHandlingField disabled={disabled} />
            )}
          </GappedStackCard>
          <Stack flex={1} sx={{ overflowY: 'auto' }}>
            {rightPanelContent}
            <Box p={2} sx={{ backgroundColor: 'white' }}>
              <CustomerPortalDocumentsField disabled={disabled} />
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </OrderFormEditAccessProvider>
  );
};

export default CustomerPortalOrderForm;
