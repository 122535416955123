import * as EmailValidator from 'email-validator';
import _, { isNil } from 'lodash';
import { type AddressFormField } from '../../domains/addresses/redux/addresses-values-slice';
import { type ContactPersonFormField } from '../../domains/contact-persons/redux/contact-persons-values-slice';
import { type Temporal } from 'temporal-polyfill';

export const FIELD_IS_REQUIRED = 'Field is required';
export const BOTH_ARE_REQUIRED = 'Both a start and end time are required';
export const NEGATIVE_NUMBER = 'Cannot be negative.';

export type ContactPersonErrorMessages = Partial<{
  [key in keyof Omit<ContactPersonFormField, 'uuid' | '__typename'>]: string;
}>;

export type AddressesErrorMessages = Partial<{
  [key in keyof Omit<AddressFormField, 'uuid' | '__typename'>]: string;
}>;

export type ValidationResponse = {
  valid: boolean;
  explanation: string | null;
};

export type ErrorResponse = {
  field: string;
  validationResponse: ValidationResponse;
};

export const validateEmail = (
  email: unknown | null | undefined,
  required: boolean,
): ValidationResponse => {
  if (!required) {
    return { valid: true, explanation: null };
  }
  if (typeof email !== 'string') {
    return { valid: false, explanation: FIELD_IS_REQUIRED };
  }

  const valid = EmailValidator.validate(email);
  return { valid, explanation: valid ? null : 'Email is invalid' };
};

export const validateNotEmpty = (
  value: unknown | undefined | null,
  required: boolean,
) => {
  if (required && (_.isNil(value) || (value as any).toString().length === 0)) {
    return { valid: false, explanation: FIELD_IS_REQUIRED };
  }
  return { valid: true, explanation: '' };
};

export const validateString = (
  value: string | undefined | null,
  required: boolean,
) => {
  if (
    required &&
    (value === undefined || value === null || value.length === 0)
  ) {
    return { valid: false, explanation: FIELD_IS_REQUIRED };
  }
  return { valid: true, explanation: '' };
};

export const validateNonNegativeNumber = (
  value: number | null | undefined,
  required: boolean,
) => {
  if (required && (Number.isNaN(value) || value === undefined)) {
    return { valid: false, explanation: FIELD_IS_REQUIRED };
  }
  if (!isNil(value) && value < 0) {
    return { valid: false, explanation: NEGATIVE_NUMBER };
  }
  return { valid: true, explanation: '' };
};

export const validateDate = (
  value: Date | null | undefined,
  required: boolean,
) => {
  let valid = true;
  if (required || !_.isNil(value)) {
    valid = _.isDate(value);
  }
  return { valid, explanation: 'Field is required' };
};

export const validatePlainDate = (
  value: Temporal.PlainDate | null | undefined,
  required: boolean,
): ValidationResponse => {
  if (required && isNil(value)) {
    return { valid: false, explanation: FIELD_IS_REQUIRED };
  }

  return { valid: true, explanation: '' };
};
