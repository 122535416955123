import { type ApolloQueryResult } from '@apollo/client';
import { Box, TablePagination } from '@mui/material';
import {
  type ColDef,
  type ColumnMovedEvent,
  type GridReadyEvent,
  type IRowNode,
  type IServerSideGetRowsParams,
  type SelectionChangedEvent,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { type AgGridReact } from 'ag-grid-react';
import { sentenceCase } from 'change-case';
import { isArray, isEmpty, isNil, keyBy, uniqBy } from 'lodash';
import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import useStateRef from 'react-usestateref';
import { filterNotNil } from 'shared/array';
import { isNilOrEmptyString } from 'shared/string';
import useLocalStorageState from 'use-local-storage-state';
import { shallow } from 'zustand/shallow';
import apolloClient from '../../../apollo-client';
import {
  isPrefixOption,
  isSingleCustomerOption,
  type PrefixOption,
} from '../../../common/components/customer-filter-button';
import {
  type DateOption,
  type DatePickerFilterType,
} from '../../../common/components/date-dropdown-picker';
import { FeatureFlag } from '../../../common/feature-flags';
import { type Option } from '../../../common/filters/types';
import {
  ORDERS_CHANGED_SORT_MODEL_KEY,
  ORDERS_CHANGED_TABLE_COLUMNS_KEY,
} from '../../../common/local-storage/keys';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useSelectedTerminalUuid from '../../../common/react-hooks/use-selected-terminal-uuid';
import { useTableFields } from '../../../common/react-hooks/use-table-fields';
import useTerminals from '../../../common/react-hooks/use-terminals';
import {
  FilterViewPage,
  type FindOrdersFiltersInput,
  type FormattedOrderFragment,
  GetOrderTableFieldValuesDocument,
  GetOrderTableFieldValuesForCountDocument,
  type GetOrderTableFieldValuesForCountQuery,
  type GetOrderTableFieldValuesForCountQueryVariables,
  GetOrderTableFieldValuesForSearchDocument,
  type GetOrderTableFieldValuesForSearchQuery,
  type GetOrderTableFieldValuesForSearchQueryVariables,
  type GetOrderTableFieldValuesQuery,
  type GetOrderTableFieldValuesQueryVariables,
  GetSavedFilterViewsDocument,
  type OrderSort,
  type OrderSortV2,
  OrderTableField,
  type OrderTableFieldHeaderFragment,
  type OrderTableFieldValuesFormattedOrderConnectionFragment,
  useUpdateSavedFilterViewMutation,
  useUpdateUserMutation,
} from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import {
  ExcludeFromHiding,
  type FilterModel,
} from '../../orders/components/enums/order-filters';
import { VIEW_ALL_ORDERS_PAGE_DEFAULT_ORDER_TABLE_FIELDS } from '../../orders/constants';
import { type PageSizes, VALID_PAGE_SIZES } from '../PageSizeSelector';
import useFilterStore from '../filter-store';
import PalletAgGridReact from '../pallet-ag-grid/pallet-ag-grid-react';
import { useNewTableFunctionsFeatureFlag } from '../use-new-table-functions-feature-flag';
import {
  changesBetweenTableFields,
  countChangesBetweenFilterModels,
  countChangesBetweenSortModels,
} from '../utils';
import ConfigureOrderTableHeaders from './components/columns/configure-order-columns';
import { OrdersTableFunctionsContainer } from './components/orders-table-functions-container';
import { OrdersTableFunctionsContainerNew } from './components/orders-table-functions-container-new';
import SelectAllOrders from './components/select-all-orders';
import {
  EXPENSIVE_TABLE_FIELDS,
  TERMINAL_ONLY_ORDER_FIELDS,
} from './constants';
import {
  OrdersTableDisplayedPagination,
  OrdersTablePaginationActions,
} from './orders-table-pagination';
import {
  type DefaultFilterTabsConfigs,
  type OrdersTableWithFiltersState,
  type OrderTableFilterModel,
} from './types';
import {
  getAGGridFilterModel,
  getAgGridSideBarDef,
  getAGGridSortModel,
  getFetchOrdersVariables,
  getOrderColumnSortStates,
  getOrderSortV2,
  getOrderTableFields,
  USER_FIELDS_FOR_TABLE_FIELDS,
} from './utils';

const DEFAULT_COL_DEF: ColDef<FormattedOrderFragment> = {
  resizable: true,
  suppressMenu: true,
  editable: false,
};

type OrdersTableProps<T> = {
  readonly columnDefinitions: Array<ColDef<FormattedOrderFragment>>;
  readonly pageSize: PageSizes;
  readonly pageType:
    | FilterViewPage.Orders
    | FilterViewPage.OrdersAudit
    | FilterViewPage.LineHaulOrders
    | FilterViewPage.Invoices;
  readonly defaultFilterTabsConfigs: DefaultFilterTabsConfigs<T>;
  // Default filters that is applied to the table query. These filters are not part of the filter model and not shown to the user.
  readonly defaultTableFilters?: FindOrdersFiltersInput;
  readonly shouldRefresh?: boolean;
  readonly openUploadPdfsModal?: () => void;
  readonly openUploadCsvsModal?: () => void;
  readonly openBillingPartyModal?: () => void;
  readonly shouldRefreshGrid?: boolean;
  readonly setShouldRefreshGrid?: (show: boolean) => void;
  readonly setShouldRefresh?: (show: boolean) => void; // TODO: Eliminate this.
  readonly topRightComponent?: ReactNode;
  readonly handleSelectOrders?: (orders: FormattedOrderFragment[]) => void;
  readonly handleSelectAllOrderUuids?: (orderUuids: string[]) => void;
  readonly handleSelectContact?: (uuid: string | undefined) => void;
  readonly handleSelectContacts?: (uuids: string[] | undefined) => void;
  readonly handleSelectOriginTerminal?: (uuid: string | undefined) => void;
  readonly handleSelectDestinationTerminal?: (uuid: string | undefined) => void;
  readonly handleSelectDate?: (option: DateOption) => void;
  readonly defaultDatePickerFilterType?: DatePickerFilterType;
  readonly bulkActionsEnabled?: boolean;
  readonly handleTabChange?: (tab: T) => void;
  readonly handleFilterChange?: (filters: FilterModel | undefined) => void;
  readonly rowSelectionEnabled?: boolean;
  readonly shouldShowDatePicker?: boolean;
  readonly shouldShowCustomerFilter?: boolean;
  readonly shouldShowCustomerFilterMultiselect?: boolean;
  readonly shouldShowTerminalFilter?: boolean;
  readonly shouldShowOriginTerminalFilter?: boolean;
  readonly shouldShowDestinationTerminalFilter?: boolean;
  readonly shouldShowNoTerminalOptionMultiselect?: boolean;
  readonly shouldAllowSavedViews?: boolean;
  readonly shouldRememberFilters?: boolean;
  readonly shouldShowGenerateReportButtons?: boolean;
  readonly controlBarSpacing?: boolean;
  readonly userOrderTableFields: OrderTableField[];
  readonly userUuid: string | undefined;
  readonly useMultiSearchText?: boolean;
  readonly handleSortChange?: (orderSorts: OrderSort[]) => void;
  readonly showSelectAllOrders?: boolean;
  readonly terminalFilterCacheId?: string;
  readonly originTerminalFilterCacheId?: string;
  readonly destinationTerminalFilterCacheId?: string;
  readonly shouldShowAddOrderButton?: boolean;
  readonly enableHeaderCheckboxSelection: boolean;
};

const sanitizeOrderTableFields = (orderTableFields: OrderTableField[]) => {
  const initialOrderFields = orderTableFields.filter(
    (header) => !EXPENSIVE_TABLE_FIELDS.has(header),
  );
  // if TotalChargesWithItemized is requested, replace it with Charges and load TotalChargesWithItemized asynchronously per cell
  if (
    orderTableFields.includes(OrderTableField.TotalChargesWithItemized) &&
    !orderTableFields.includes(OrderTableField.Charges)
  ) {
    initialOrderFields.push(OrderTableField.Charges);
  }

  return initialOrderFields;
};

export const OrdersTableWithFiltersAgGrid = <
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
  DefaultFilterTabsType extends any,
>({
  columnDefinitions,
  pageSize: initialPageSize,
  pageType,
  defaultFilterTabsConfigs,
  defaultTableFilters,
  shouldRefresh,
  setShouldRefresh,
  openUploadPdfsModal,
  openUploadCsvsModal,
  openBillingPartyModal,
  shouldRefreshGrid,
  setShouldRefreshGrid,
  topRightComponent,
  handleSelectOrders,
  handleSelectAllOrderUuids,
  handleSelectContact,
  handleSelectContacts,
  handleSelectOriginTerminal,
  handleSelectDestinationTerminal,
  handleSelectDate,
  handleTabChange,
  handleFilterChange,
  defaultDatePickerFilterType,
  bulkActionsEnabled = false,
  rowSelectionEnabled = false,
  shouldShowDatePicker = false,
  shouldShowCustomerFilter = false,
  shouldShowCustomerFilterMultiselect = false,
  shouldShowTerminalFilter = false,
  shouldShowOriginTerminalFilter = false,
  shouldShowDestinationTerminalFilter = false,
  shouldShowNoTerminalOptionMultiselect = false,
  shouldShowGenerateReportButtons = true,
  shouldAllowSavedViews = false,
  shouldRememberFilters = false,
  controlBarSpacing = true,
  userOrderTableFields,
  userUuid,
  useMultiSearchText,
  handleSortChange,
  showSelectAllOrders,
  terminalFilterCacheId,
  originTerminalFilterCacheId,
  destinationTerminalFilterCacheId,
  shouldShowAddOrderButton,
  enableHeaderCheckboxSelection,
}: OrdersTableProps<DefaultFilterTabsType>) => {
  const [, setSearchParams] = useSearchParams();
  const gridRef = useRef<AgGridReact<FormattedOrderFragment>>(null);

  const ignoreSelectedUserTerminal = pageType === FilterViewPage.LineHaulOrders;
  const { ffEnableNewTableFunctions } =
    useNewTableFunctionsFeatureFlag(pageType);
  const ffRemoveRefreshGridsExperiment = useFeatureFlag(
    FeatureFlag.FF_REMOVE_REFRESH_GRIDS_EXPERIMENT,
  );

  const { terminalsEnabled, getTerminalCode } = useTerminals({
    includeInactiveTerminals: false,
  });

  const { selectedTerminalUuid } = useSelectedTerminalUuid();

  const [currentPage, setCurrentPage] = useState(0);

  const [
    openedOrderUuid,
    setOpenedOrderUuid,
    orderUuidToRefetch,
    setOrderUuidToRefetch,
  ] = useGlobalStore(
    (state) => [
      state.currentOrderUuid,
      state.setCurrentOrderUuid,
      state.orderUuidToRefetch,
      state.setOrderUuidToRefetch,
    ],
    shallow,
  );

  const {
    setRememberedFilters,
    rememberedSearch,
    setRememberedSearch,
    rememberedDateOptions,
    setRememberedDateOption,
    rememberedTabs,
  } = useFilterStore((state) => {
    return {
      rememberedSearch: state.search,
      rememberedDateOptions: state.dateOptions,
      rememberedTabs: state.tabs,
      setRememberedFilters: state.setFilters,
      setRememberedSearch: state.setSearch,
      setRememberedDateOption: state.setDateOption,
    };
  });

  const [
    /**
     * This is non-nil if the current view has unsaved changes to the sort model
     * When loading the page, we first check `changedSortModel` for local changes
     * that should override the sort model saved in the DB, otherwise use the DB model
     */
    changedSortModel,
    setChangedSortModel,
  ] = useLocalStorageState<OrderSortV2[] | null>(
    `${ORDERS_CHANGED_SORT_MODEL_KEY}-${pageType}`,
    {
      defaultValue: null,
    },
  );

  const [changedOrderTableFields, setChangedOrderTableFields] =
    useLocalStorageState<OrderTableField[] | null>(
      `${ORDERS_CHANGED_TABLE_COLUMNS_KEY}-${pageType}`,
      {
        defaultValue: null,
      },
    );

  const [, setState, stateRef] = useStateRef<
    OrdersTableWithFiltersState<DefaultFilterTabsType>
  >({
    searchText: shouldRememberFilters ? (rememberedSearch[pageType] ?? '') : '',
    ordersTab: defaultFilterTabsConfigs.defaultTab,
    pageSize: initialPageSize,
    currentTabIsSavedView: false,
    currentTabIsNewView: false,
    currentCursor: null,
    previousCursor: null,
    usePreviousCursor: true,
    customFilterModelJson: {},
    orderTableFilterModel: {},
    customSortModelJson: [],
    orderTableFields:
      (ffEnableNewTableFunctions
        ? changedOrderTableFields
        : userOrderTableFields) ??
      VIEW_ALL_ORDERS_PAGE_DEFAULT_ORDER_TABLE_FIELDS,
    currentSavedViewName: null,
    currentSavedViewUuid: null,
    originTerminalOption: undefined,
    destinationTerminalOption: undefined,
    // Multi-select
    customerOptions: undefined,
    terminalOptions:
      ffEnableNewTableFunctions && !isNil(selectedTerminalUuid)
        ? [
            {
              label: getTerminalCode(selectedTerminalUuid),
              value: selectedTerminalUuid,
            },
          ]
        : undefined,
    dateOption: undefined,
    multipleSearches: [],
    totalCount: undefined,
    ordersByHawb: [],
    datasourceVersionId: 0,
    hasNextPage: false,
  });

  const [filterModelV2, setFilterModelV2] = useState<OrderTableFilterModel>({});
  const [filters, setFilters] =
    useState<GetOrderTableFieldValuesQueryVariables>();

  // Unfortunately these cannot be derived states because AG Grid column and filter
  // states aren't plugged into React state, we only access them in event handlers.
  const [numFiltersChanged, setNumFiltersChanged] = useState(0);
  const [numSortsChanged, setNumSortsChanged] = useState(0);
  const [numColumnsChanged, setNumColumnsChanged] = useState(0);

  const [savedViewSaveSuccessAlertText, setSavedViewSaveSuccessAlertText] =
    useState<string | undefined>(undefined);
  const [savedViewSaveFailedAlert, setSavedViewSaveFailedAlert] =
    useState(false);
  const [isHeaderCheckboxSelected, setIsHeaderCheckboxSelected] =
    useState(false);
  const [columnDefs, setColumnDefs] =
    useState<Array<ColDef<FormattedOrderFragment>>>(columnDefinitions);
  const [selectedUuids, setSelectedUuids] = useState<string[]>([]);
  const [updateUser] = useUpdateUserMutation();
  const { refetchTableFieldsData } = useTableFields();
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);

  /// /////////////////////////////////////////////////////////////////////////////
  // QUERIES
  /// /////////////////////////////////////////////////////////////////////////////
  const [showConfigureOrderHeaders, setShowConfigureOrderHeaders] =
    useState(false);
  const [updateSavedViewMutation] = useUpdateSavedFilterViewMutation({
    refetchQueries: [
      { query: GetSavedFilterViewsDocument, variables: { pageType } },
    ],
  });
  const ffDemoLoadManagement = useFeatureFlag(
    FeatureFlag.FF_DEMO_LOAD_MANAGEMENT,
  );

  /**
   * This ref stores the current list of table fields used by the table fetching code,
   * calculated from changedOrderTableFields and stateRef.current.orderTableFields.
   * You shouldn't need to write to this directly – instead, write to changedOrderTableFields
   * or stateRef.current.orderTableFields and let the ref compute itself.
   *
   * The reason we use a ref for the table headers and the feature flag throughout the functions below is because
   * AG grid seems to treat the closures we pass into the AgGridReact component differently than React
   * normally does. As such, we had issues with the closures such as onGridReady having staleness issues with
   * the variables being used inside it (one of which was orderTableHeaders.) Using a ref instead of a prop directly
   * overcomes that issues since the closure, stale or not, maintains the ref within its context and the underlying value
   * of the ref is something we can always control.
   */
  /**
   * This ref is only intended for use in AG Grid closures. Do not pass it to other components, instead use
   * the stateful `orderTableFields` value.
   */
  const orderTableFieldsRef = useRef<OrderTableField[]>([]);
  const { orderTableFields: stateRefOrderTableFields } = stateRef.current;
  const orderTableFields = useMemo(() => {
    const newOrderTableFields = ffEnableNewTableFunctions
      ? (changedOrderTableFields ?? stateRefOrderTableFields)
      : stateRefOrderTableFields;
    orderTableFieldsRef.current = newOrderTableFields;
    return newOrderTableFields;
  }, [
    ffEnableNewTableFunctions,
    changedOrderTableFields,
    stateRefOrderTableFields,
  ]);

  const filterModelV2Ref = useRef(filterModelV2);
  useEffect(() => {
    filterModelV2Ref.current = filterModelV2;
  }, [filterModelV2]);

  const ffEnableNewTableFunctionsRef = useRef(ffEnableNewTableFunctions);
  useEffect(() => {
    ffEnableNewTableFunctionsRef.current = ffEnableNewTableFunctions;
  }, [ffEnableNewTableFunctions]);

  /**
   * Given a list of order table field headers, updates the column defs for the AG grid to include the header names
   * for each order table field.
   * @param orderTableFieldHeaders
   */
  const setColumnDefinitionHeaderNames = ({
    orderTableFieldHeaders,
  }: {
    orderTableFieldHeaders: OrderTableFieldHeaderFragment[];
  }) => {
    const orderTableFieldHeadersMap = keyBy(
      orderTableFieldHeaders,
      'orderTableField',
    );

    setColumnDefs((prevColumnDefs) =>
      prevColumnDefs.map((columnDef) => {
        if (
          isNil(columnDef.field) ||
          columnDef.field === OrderTableField.TotalChargesWithItemized
        ) {
          return columnDef;
        }

        if (columnDef.field === ExcludeFromHiding.EMPTY_SPACE) {
          return {
            ...columnDef,
            headerCheckboxSelection: enableHeaderCheckboxSelection,
            lockPosition: 'left',
          };
        }

        const fieldHeader = orderTableFieldHeadersMap[columnDef.field];
        if (isNil(fieldHeader) || isEmpty(fieldHeader)) {
          return columnDef;
        }
        if (ffDemoLoadManagement && fieldHeader.header === 'HAWB') {
          return {
            ...columnDef,
            headerName: 'Auth',
            lockPinned: true,
          };
        }

        return {
          ...columnDef,
          headerName: fieldHeader.header,
          lockPinned: true,
        };
      }),
    );
  };

  const fetchOrdersByUuids = async ({ uuids }: { uuids: string[] }) => {
    setIsTableLoading(true);
    try {
      /**
       * The query pattern being followed here involves querying an initial set of fields as fast as possible.
       * We achieve this by excluding the more expensive fields listed in the array below and querying them in a second query.
       * This avoids the expensive table fields blocking page load.
       */
      const orderFieldsToUse = sanitizeOrderTableFields(
        orderTableFieldsRef.current,
      );

      const resOrderTableFieldValues = await apolloClient.query<
        GetOrderTableFieldValuesQuery,
        GetOrderTableFieldValuesQueryVariables
      >({
        query: GetOrderTableFieldValuesDocument,
        variables: {
          getOrderTableFieldValuesInput: {
            first: stateRef.current.pageSize,
            uuids,
            orderTableFields: orderFieldsToUse,
          },
        },
      });

      // eslint-disable-next-line no-console
      console.log(
        'Setting usePreviousCursor to true when fetching orders by uuids',
      );
      // If the retrieved rows don't meet the filter
      // critera after changes and are removed, ag-grid will invoke getRows
      // to "fill empty rows." In that case we want to fetch orders using the same
      // cursor we used on the last call to getRows
      setState((prevState) => {
        return {
          ...prevState,
          usePreviousCursor: true,
        };
      });
      const orders =
        resOrderTableFieldValues.data?.getOrderTableFieldValues?.formattedOrderConnection?.edges.map(
          (edge) => edge.node,
        );

      if (isNil(orders) || isEmpty(orders)) {
        gridRef.current?.api.applyServerSideTransaction({
          remove: uuids.map((uuid) => ({ uuid })),
        });
      } else {
        gridRef.current?.api.applyServerSideTransaction({
          update: orders,
        });
      }
      gridRef.current?.api.redrawRows();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(String(error));
    }
    setIsTableLoading(false);
  };

  const fetchAdditionalValues = async ({
    fetchDriverSettlement,
    variables,
    isSearchQuery,
  }: {
    fetchDriverSettlement: boolean;
    variables: GetOrderTableFieldValuesQueryVariables;
    isSearchQuery: boolean;
  }) => {
    let ordersData:
      | OrderTableFieldValuesFormattedOrderConnectionFragment
      | undefined;
    let orderTableFieldHeaders: OrderTableFieldHeaderFragment[] = [];
    setIsTableLoading(true);
    try {
      const additionaOrderTableFields = [];
      if (fetchDriverSettlement) {
        additionaOrderTableFields.push(OrderTableField.DriverSettlement);
      }

      if (isSearchQuery) {
        const resOrderTableFieldValues = await apolloClient.query<
          GetOrderTableFieldValuesForSearchQuery,
          GetOrderTableFieldValuesForSearchQueryVariables
        >({
          query: GetOrderTableFieldValuesForSearchDocument,
          variables: {
            getOrderTableFieldValuesInput: {
              ...variables.getOrderTableFieldValuesInput,
              orderTableFields: additionaOrderTableFields,
            },
          },
        });

        orderTableFieldHeaders = (await resOrderTableFieldValues).data
          .getOrderTableFieldValues.orderTableFieldHeaders;
        ordersData = (await resOrderTableFieldValues).data
          .getOrderTableFieldValues.formattedOrderConnection;
      } else {
        const resOrderTableFieldValues = await apolloClient.query<
          GetOrderTableFieldValuesQuery,
          GetOrderTableFieldValuesQueryVariables
        >({
          query: GetOrderTableFieldValuesDocument,
          variables: {
            getOrderTableFieldValuesInput: {
              ...variables.getOrderTableFieldValuesInput,
              orderTableFields: additionaOrderTableFields,
            },
          },
        });

        orderTableFieldHeaders = (await resOrderTableFieldValues).data
          .getOrderTableFieldValues.orderTableFieldHeaders;
        ordersData = (await resOrderTableFieldValues).data
          .getOrderTableFieldValues.formattedOrderConnection;
      }

      setColumnDefinitionHeaderNames({ orderTableFieldHeaders });

      const updatedOrdersWithCharges: FormattedOrderFragment[] = filterNotNil(
        ordersData?.edges.map(({ node: formattedOrder }) => {
          const existingOrderData: FormattedOrderFragment | undefined =
            gridRef.current?.api.getRowNode(formattedOrder.uuid)?.data;

          const { formattedOrderFields } = formattedOrder;

          const driverSettlementTableField = formattedOrderFields.find(
            (field) =>
              field.orderTableField === OrderTableField.DriverSettlement,
          );

          if (!isNil(existingOrderData)) {
            return {
              ...existingOrderData,
              formattedOrderFields: filterNotNil([
                ...existingOrderData.formattedOrderFields,
                driverSettlementTableField,
              ]),
            };
          }
          return null;
        }),
      );
      if (!isNil(updatedOrdersWithCharges)) {
        gridRef.current?.api.applyServerSideTransaction({
          update: updatedOrdersWithCharges,
        });
        gridRef.current?.api.redrawRows();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(String(error));
    }
    setIsTableLoading(false);
  };

  const fetchAndUpdateTotalCount = async (
    resTotalCount: Promise<
      ApolloQueryResult<GetOrderTableFieldValuesForCountQuery>
    >,
  ) => {
    const { datasourceVersionId } = stateRef.current;
    const totalOrderCount =
      (await resTotalCount).data?.getOrderTableFieldValues
        ?.formattedOrderConnection?.totalCount ?? undefined;

    if (datasourceVersionId !== stateRef.current.datasourceVersionId) {
      // eslint-disable-next-line no-console
      console.log('Total count datasourceVersionId mismatch', {
        datasourceVersionId,
        currentDatasourceVersionId: stateRef.current.datasourceVersionId,
      });
      return;
    }

    setState((prevState) => {
      return {
        ...prevState,
        totalCount: totalOrderCount,
      };
    });

    if (!isNil(totalOrderCount)) {
      gridRef.current?.api?.setRowCount(totalOrderCount);
    }
  };

  const fetchOrderTableValues = async ({
    params,
    variables,
    fetchCount,
  }: {
    params: IServerSideGetRowsParams<FormattedOrderFragment>;
    variables: GetOrderTableFieldValuesQueryVariables;
    // If true, also fetch and update the total count. Will happen separately from the order data
    fetchCount: boolean;
  }) => {
    setIsTableLoading(true);

    let ordersData:
      | OrderTableFieldValuesFormattedOrderConnectionFragment
      | undefined;
    let orderTableFieldHeaders: OrderTableFieldHeaderFragment[] = [];

    const isSearchQuery = !isNilOrEmptyString(
      variables.getOrderTableFieldValuesInput.searchText,
    );

    try {
      const { datasourceVersionId } = stateRef.current;

      const orderFieldsToUse = sanitizeOrderTableFields(
        orderTableFieldsRef.current,
      );

      const inputToFetchOrderTableValues = {
        ...variables.getOrderTableFieldValuesInput,
        orderTableFields: orderFieldsToUse,
        includeOrders: true,
        totalCount: false,
      };

      const resOrderTableFieldValues = isSearchQuery
        ? apolloClient.query<
            GetOrderTableFieldValuesForSearchQuery,
            GetOrderTableFieldValuesForSearchQueryVariables
          >({
            query: GetOrderTableFieldValuesForSearchDocument,
            variables: {
              getOrderTableFieldValuesInput: inputToFetchOrderTableValues,
            },
          })
        : apolloClient.query<
            GetOrderTableFieldValuesQuery,
            GetOrderTableFieldValuesQueryVariables
          >({
            query: GetOrderTableFieldValuesDocument,
            variables: {
              getOrderTableFieldValuesInput: inputToFetchOrderTableValues,
            },
          });

      if (fetchCount) {
        const resTotalCount = apolloClient.query<
          GetOrderTableFieldValuesForCountQuery,
          GetOrderTableFieldValuesForCountQueryVariables
        >({
          query: GetOrderTableFieldValuesForCountDocument,
          variables: {
            getOrderTableFieldValuesInput: {
              ...variables.getOrderTableFieldValuesInput,
              orderTableFields: [],
              totalCount: true,
              includeOrders: false,
            },
          },
        });
        void fetchAndUpdateTotalCount(resTotalCount);
      }

      const orderDataRes = (await resOrderTableFieldValues).data
        ?.getOrderTableFieldValues;
      ordersData = orderDataRes?.formattedOrderConnection;

      if (datasourceVersionId !== stateRef.current.datasourceVersionId) {
        // eslint-disable-next-line no-console
        console.log('Fetch rows datasourceVersionId mismatch', {
          datasourceVersionId,
          currentDatasourceVersionId: stateRef.current.datasourceVersionId,
          variables,
        });
        params.fail();
        return;
      }

      orderTableFieldHeaders = orderDataRes.orderTableFieldHeaders;

      setColumnDefinitionHeaderNames({ orderTableFieldHeaders });

      const orders = ordersData?.edges.map((edge) => edge.node) ?? [];

      params.success({
        rowData: orders ?? [],
      });
      // eslint-disable-next-line no-console
      console.log('Setting usePreviousCursor to false after fetching row data');
      setState((prevState) => {
        return {
          ...prevState,
          currentCursor: ordersData?.pageInfo.endCursor,
          previousCursor: variables.getOrderTableFieldValuesInput.after,
          usePreviousCursor: false,
          hasNextPage:
            ordersData?.pageInfo.hasNextPage ?? prevState.hasNextPage,
        };
      });

      // need to reselect all the nodes
      if (rowSelectionEnabled || bulkActionsEnabled) {
        const uuids = gridRef.current?.api
          .getSelectedRows()
          ?.map((rowData) => rowData.uuid);
        if (!isNil(uuids) && !isEmpty(uuids)) {
          gridRef.current?.api.forEachNode((node) => {
            const uuid = node.data?.uuid;
            if (!isNil(uuid) && uuids.includes(uuid)) {
              node.setSelected(true);
            }
          });
        }
      }

      const shouldFetchDriverSettlement = orderTableFieldsRef.current.includes(
        OrderTableField.DriverSettlement,
      );
      if (shouldFetchDriverSettlement) {
        await fetchAdditionalValues({
          variables,
          fetchDriverSettlement: shouldFetchDriverSettlement,
          isSearchQuery,
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(String(error));
    }
    setIsTableLoading(false);
  };

  const createServerSideDatasource = () => {
    return {
      getRows(params: IServerSideGetRowsParams<FormattedOrderFragment>) {
        let currentOrdersTab = stateRef.current.ordersTab;
        if (
          !defaultFilterTabsConfigs.tabs
            .map((tab) => tab.value)
            .includes(currentOrdersTab as DefaultFilterTabsType)
        ) {
          currentOrdersTab = defaultFilterTabsConfigs.baseTab;
        }
        const { sortModel, filterModel } = params.request;
        const agGridFilterModelV2 = getAGGridFilterModel(
          filterModelV2Ref.current ?? {},
        );

        const uiFilterOptions = {
          terminalOptions: ignoreSelectedUserTerminal
            ? undefined
            : stateRef.current.terminalOptions,
          customerOptions: stateRef.current.customerOptions,
          originTerminalOption: stateRef.current.originTerminalOption,
          destinationTerminalOption: stateRef.current.destinationTerminalOption,
          dateOption: stateRef.current.dateOption,
        };

        let variables = getFetchOrdersVariables({
          currentOrdersTab,
          defaultTabsConfigs: defaultFilterTabsConfigs,
          defaultTableFilters,
          filterModel: ffEnableNewTableFunctionsRef.current
            ? agGridFilterModelV2
            : filterModel,
          sortModel,
          uiFilterOptions,
          ffEnableNewTableFunctions: ffEnableNewTableFunctionsRef.current,
        });

        if (!isNil(handleSortChange)) {
          const sorts =
            !isNil(variables.getOrderTableFieldValuesInput.sorts) &&
            !isArray(variables.getOrderTableFieldValuesInput.sorts)
              ? [variables.getOrderTableFieldValuesInput.sorts]
              : variables.getOrderTableFieldValuesInput.sorts;
          handleSortChange(sorts ?? []);
        }

        setFilters(variables);
        const searchText = stateRef.current.searchText?.trim();
        const after = stateRef.current.usePreviousCursor
          ? stateRef.current.previousCursor
          : stateRef.current.currentCursor;

        variables = {
          getOrderTableFieldValuesInput: {
            ...variables.getOrderTableFieldValuesInput,
            first: stateRef.current.pageSize,
            after,
            searchText: useMultiSearchText === true ? undefined : searchText,
            multipleSearches:
              useMultiSearchText === true
                ? stateRef.current.multipleSearches
                : undefined,
            useMultiSearch: useMultiSearchText === true,
          },
        };

        fetchOrderTableValues({
          params,
          variables,
          // If after is not nil, we are fetching a non-first page of results so we already have the total count from initial load
          fetchCount: isNil(after),
        });
      },
    };
  };

  // eslint-disable-next-line no-console
  console.log('stateRef.current', stateRef.current);

  const handleRowSelected = useCallback(() => {
    const selectedRows = uniqBy(gridRef.current?.api.getSelectedRows(), 'uuid');
    setSelectedUuids(selectedRows.map((row) => row.uuid));
    if (!isNil(handleSelectOrders)) {
      handleSelectOrders(selectedRows);
    }
  }, [handleSelectOrders]);

  const deselectAll = useCallback(() => {
    gridRef.current?.api.deselectAll();
    handleRowSelected();
  }, [handleRowSelected]);

  const selectAllOrdersOnPage = () => {
    let nodes: Array<IRowNode<FormattedOrderFragment>> = [];
    // getRenderedNodes won't work here: if the page size is large enough
    // that the list is virtualized, it will only select the visible rows on the page
    gridRef.current?.api.forEachNode((node) => {
      nodes.push(node);
    });
    // If the user visited another page, we need to filter out nodes from that page
    const currentPage = gridRef.current?.api.paginationGetCurrentPage();
    if (!isNil(currentPage)) {
      const startIndex = currentPage * stateRef.current.pageSize;
      const endIndex = startIndex + stateRef.current.pageSize;
      nodes = nodes.slice(startIndex, endIndex);
    }
    // Newer versions of AG Grid have `setNodesSelected` that we can use instead
    for (const node of nodes) {
      node.setSelected(true);
    }
    handleRowSelected();
  };

  const handleSelectionChanged = (
    event: SelectionChangedEvent<FormattedOrderFragment>,
  ) => {
    if (event.source === 'uiSelectAll') {
      if (isHeaderCheckboxSelected) {
        deselectAll();
      } else {
        selectAllOrdersOnPage();
      }
      setIsHeaderCheckboxSelected((prev) => !prev);
    }
  };

  /** Selects all orders, including on other pages */
  const handleSelectAllOrders = (uuids: string[]) => {
    setSelectedUuids(uuids);
    if (!isNil(handleSelectAllOrderUuids)) {
      handleSelectAllOrderUuids(uuids);
    }
  };

  const handlePaginationChanged = () => {
    setCurrentPage(gridRef.current?.api.paginationGetCurrentPage() ?? 0);
  };

  // call this if we want to refresh the grid (filters change, etc.)
  const refreshGrid = useCallback(
    (shouldDeselectCheckboxes = true, refreshServerSide = true) => {
      if (!isNil(gridRef.current?.api)) {
        setState((prevState) => {
          // eslint-disable-next-line no-console
          console.log(
            'Increasing datasource version id',
            prevState.datasourceVersionId + 1,
          );

          return {
            ...prevState,
            totalCount: undefined,
            hasNextPage: false,
            datasourceVersionId: prevState.datasourceVersionId + 1,
          };
        });
        if (shouldDeselectCheckboxes) {
          deselectAll();
          if (isHeaderCheckboxSelected) {
            setIsHeaderCheckboxSelected(false);
          }
          if (!isNil(handleSelectOrders)) {
            handleSelectOrders([]);
          }
        }
        if (handleFilterChange) {
          handleFilterChange(gridRef.current?.api?.getFilterModel());
        }
        if (refreshServerSide) {
          gridRef.current?.api.refreshServerSide({ purge: true });
        }
        gridRef.current?.api.paginationGoToFirstPage();
        gridRef.current?.api.hideOverlay();
      }
    },
    [
      setState,
      deselectAll,
      isHeaderCheckboxSelected,
      handleSelectOrders,
      handleFilterChange,
    ],
  );

  const computeNumFiltersChanged = useCallback(
    (filterModel: OrderTableFilterModel | FilterModel | undefined) => {
      const currentFilterModel = ffEnableNewTableFunctions
        ? stateRef.current.orderTableFilterModel
        : stateRef.current.customFilterModelJson;

      const changesFromCurrentFilterModel = countChangesBetweenFilterModels(
        currentFilterModel,
        filterModel,
      );

      setNumFiltersChanged(changesFromCurrentFilterModel);
    },
    [ffEnableNewTableFunctions, stateRef],
  );

  const computeNumSortsChanged = useCallback(
    (sortModel: OrderSortV2[] | null): number => {
      if (!ffEnableNewTableFunctions) {
        return 0;
      }
      const changesFromCurrentSortModel = countChangesBetweenSortModels(
        stateRef.current.customSortModelJson,
        sortModel ?? [],
      );
      if (changesFromCurrentSortModel > 0) {
        setChangedSortModel(sortModel ?? null);
      } else {
        setChangedSortModel(null);
      }
      setNumSortsChanged(changesFromCurrentSortModel);
      return changesFromCurrentSortModel;
    },
    [stateRef, ffEnableNewTableFunctions, setChangedSortModel],
  );

  const computeNumColumnsChanged = useCallback(
    (tableFields: OrderTableField[] | null): number => {
      if (!ffEnableNewTableFunctions) {
        return 0;
      }
      const changesFromCurrentTableFields = changesBetweenTableFields(
        stateRef.current.orderTableFields,
        tableFields,
      );

      setNumColumnsChanged(changesFromCurrentTableFields);

      if (changesFromCurrentTableFields > 0) {
        setChangedOrderTableFields(tableFields);
      } else {
        setChangedOrderTableFields(null);
      }

      return changesFromCurrentTableFields;
    },
    [stateRef, ffEnableNewTableFunctions, setChangedOrderTableFields],
  );

  const applyFilterModel = useCallback(
    (orderTableFilterModel: OrderTableFilterModel | null) => {
      if (ffEnableNewTableFunctions) {
        setFilterModelV2(orderTableFilterModel ?? {});
        // We used to call this in handleFilterChanged, but since we're not storing
        // filters in AG Grid anymore, handleFilterChanged never runs
        if (shouldRememberFilters) {
          setRememberedFilters(
            JSON.stringify(orderTableFilterModel ?? {}),
            pageType,
          );
        }

        // eslint-disable-next-line no-console
        console.log(
          'Calling refreshGrid from applyFilterModel',
          orderTableFilterModel,
        );
        // TODO: We might be able to remove this
        refreshGrid();
      } else {
        const agGridFilterModel = isNil(orderTableFilterModel)
          ? {}
          : getAGGridFilterModel(orderTableFilterModel);
        gridRef.current?.api?.setFilterModel(agGridFilterModel);
      }
      computeNumFiltersChanged(orderTableFilterModel ?? undefined);
      setState((prevState) => {
        return {
          ...prevState,
          currentCursor: null,
        };
      });
    },
    [
      ffEnableNewTableFunctions,
      computeNumFiltersChanged,
      setState,
      shouldRememberFilters,
      refreshGrid,
      setRememberedFilters,
      pageType,
    ],
  );

  const legacyApplyFilterModel = useCallback(
    (filterModel: FilterModel | null) => {
      gridRef.current?.api?.setFilterModel(filterModel);

      computeNumFiltersChanged(filterModel ?? undefined);
    },
    [computeNumFiltersChanged],
  );

  const applySortModel = useCallback(
    (sortModel: OrderSortV2[]) => {
      gridRef.current?.columnApi?.applyColumnState({
        state: getOrderColumnSortStates(sortModel),
        defaultState: { sort: null },
      });
      computeNumSortsChanged(sortModel);
    },
    [computeNumSortsChanged],
  );

  /**
   * Restore tabs from Zustand store after switching pages (but
   * without falling back to local storage)
   */
  const handleRememberedTabs = () => {
    const rememberedTab = rememberedTabs[pageType];
    if (!isNil(rememberedTab)) {
      if (!isNil(rememberedTab.default)) {
        const parsedTab = JSON.parse(rememberedTab.default);
        setState((prevState) => ({
          ...prevState,
          currentTabIsSavedView: false,
          currentTabIsNewView: false,
          ordersTab: parsedTab.value as DefaultFilterTabsType,
          currentCursor: null,
          currentSavedViewUuid: null,
          currentSavedViewName: null,
        }));
      } else if (!isNil(rememberedTab.custom)) {
        const parsedTab = JSON.parse(rememberedTab.custom);
        setState((prevState) => ({
          ...prevState,
          currentTabIsSavedView: true,
          currentTabIsNewView: false,
          ordersTab: parsedTab.uuid,
          currentCursor: null,
          currentSavedViewUuid: parsedTab.uuid,
          currentSavedViewName: parsedTab.displayName,
        }));
      }
    }
  };

  useEffect(() => {
    if (ffRemoveRefreshGridsExperiment) {
      return;
    }
    setColumnDefs(columnDefinitions);
  }, [columnDefinitions, ffRemoveRefreshGridsExperiment]);

  const onGridReady = (params: GridReadyEvent) => {
    const datasource = createServerSideDatasource();
    params.api.setServerSideDatasource(datasource);
    params.api.closeToolPanel();
    if (shouldRememberFilters) {
      handleRememberedTabs();
      const dateOption = rememberedDateOptions[pageType];
      if (!isNil(dateOption)) {
        handleDatePickerChange(dateOption);
      }
    }
  };

  useEffect(() => {
    if (shouldRefreshGrid === true && !isNil(setShouldRefreshGrid)) {
      setState((prevState) => {
        return {
          ...prevState,
          currentCursor: null,
        };
      });
      // eslint-disable-next-line no-console
      console.log('Calling refreshGrid from shouldRefreshGrid');
      refreshGrid();
      setShouldRefreshGrid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefreshGrid]);

  const populateTableFromConfig = useCallback(
    async (newFields: OrderTableField[]) => {
      setColumnDefs((prevColumnDefs) => {
        // Initially hide ALL data columns
        const updatedColumnDefs = prevColumnDefs.map((columnDef) => ({
          ...columnDef,
          hide:
            columnDef.field !== ExcludeFromHiding.BUTTON &&
            columnDef.field !== ExcludeFromHiding.EMPTY_SPACE,
        }));
        const visibleColumns = filterNotNil(
          newFields.map((field) => {
            const prevIndex = prevColumnDefs.findIndex(
              (def) =>
                sentenceCase(def.field ?? '').toLowerCase() ===
                sentenceCase(field).toLowerCase(),
            );
            const updatedColumnDefForField = updatedColumnDefs[prevIndex];
            if (!isNil(updatedColumnDefForField)) {
              updatedColumnDefForField.hide = false;
            }
            return updatedColumnDefForField;
          }),
        );
        const hiddenColumns = updatedColumnDefs.filter(
          (columnDef) =>
            !visibleColumns.some(
              (tableCol) => tableCol.field === columnDef.field,
            ) && columnDef.checkboxSelection !== true,
        );
        const checkboxCol = updatedColumnDefs.find(
          (columnDef) => columnDef.checkboxSelection === true,
        );

        const newColumns = filterNotNil([
          checkboxCol,
          ...visibleColumns,
          // Move hidden columns to the end, but don't delete them from
          // columnDefs in case we need them later
          ...hiddenColumns,
        ]);

        return newColumns;
      });
    },
    [],
  );

  useEffect(() => {
    if (!terminalsEnabled && !isNil(gridRef.current?.columnApi)) {
      gridRef.current?.columnApi.setColumnsVisible(
        TERMINAL_ONLY_ORDER_FIELDS,
        false,
      );
    }
  }, [terminalsEnabled, gridRef.current?.columnApi]);

  useEffect(() => {
    populateTableFromConfig(orderTableFieldsRef.current);
    if (ffRemoveRefreshGridsExperiment) {
      return;
    }

    // eslint-disable-next-line no-console
    console.log(
      'Calling refreshGrid from columnDefinitions change',
      columnDefinitions,
    );
    refreshGrid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnDefinitions, ffRemoveRefreshGridsExperiment]);

  {
    // Legacy code, can be removed once ffEnableNewTableFunctions is enabled everywhere
    const { orderTableFields: currentOrderTableFields } = stateRef.current;
    useEffect(() => {
      if (ffEnableNewTableFunctions) {
        return;
      }
      populateTableFromConfig(currentOrderTableFields);
    }, [
      populateTableFromConfig,
      ffEnableNewTableFunctions,
      currentOrderTableFields,
    ]);
  }

  const setTableColumns = useCallback(
    (fields: OrderTableField[]) => {
      populateTableFromConfig(fields);
      computeNumColumnsChanged(fields);
      (setShouldRefreshGrid ?? setShouldRefresh)?.(true);
    },
    [
      populateTableFromConfig,
      computeNumColumnsChanged,
      setShouldRefresh,
      setShouldRefreshGrid,
    ],
  );

  const userFieldForTableFields = USER_FIELDS_FOR_TABLE_FIELDS[pageType];

  const { currentSavedViewUuid } = stateRef.current;
  const onSaveOrderTableColumns = useCallback(
    async (fields: OrderTableField[]) => {
      // We only want to update the user's personal list of order table fields
      // if the current view is a default view.
      if (
        (!ffEnableNewTableFunctions || isNil(currentSavedViewUuid)) &&
        !isNil(userUuid)
      ) {
        await updateUser({
          variables: {
            updateUserInput: {
              uuid: userUuid,
              [userFieldForTableFields]: fields,
            },
          },
        });
        refetchTableFieldsData();
        setState((prevState) => ({
          ...prevState,
          [userFieldForTableFields]: fields,
        }));
      }
      if (ffEnableNewTableFunctions) {
        if (!isNil(currentSavedViewUuid)) {
          // Default views all share the user's personal list of order table fields,
          // which is saved as soon as the order table fields are changed.
          setChangedOrderTableFields(fields);
        }
        setTableColumns(fields);
      }
      (setShouldRefreshGrid ?? setShouldRefresh)?.(true);
    },
    [
      ffEnableNewTableFunctions,
      setChangedOrderTableFields,
      setTableColumns,
      setState,
      refetchTableFieldsData,
      currentSavedViewUuid,
      userFieldForTableFields,
      userUuid,
      updateUser,
      setShouldRefreshGrid,
      setShouldRefresh,
    ],
  );

  useEffect(() => {
    if (shouldRefresh === true) {
      setState((prevState) => {
        return {
          ...prevState,
          currentCursor: null,
        };
      });
      // eslint-disable-next-line no-console
      console.log('Calling refreshGrid from shouldRefresh');
      refreshGrid();
      if (setShouldRefresh) {
        setShouldRefresh(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefresh]);

  useEffect(() => {
    if (!isNil(handleTabChange)) {
      setIsHeaderCheckboxSelected(false);
      handleTabChange(stateRef.current.ordersTab as DefaultFilterTabsType);
    }
    // eslint-disable-next-line no-console
    console.log('Calling refreshGrid from ordersTab change');
    refreshGrid(true, pageType !== FilterViewPage.Orders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRef.current.ordersTab]);

  useEffect(() => {
    if (isNil(openedOrderUuid) && !isNil(orderUuidToRefetch)) {
      fetchOrdersByUuids({ uuids: [orderUuidToRefetch] });
      setOrderUuidToRefetch(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openedOrderUuid, orderUuidToRefetch]);

  // callback which AG Grid calls when a column moves.
  const handleColumnMoved = async (e: ColumnMovedEvent) => {
    if (e.source === 'uiColumnMoved' && e.finished) {
      const updatedOrderFields = getOrderTableFields(gridRef.current);

      computeNumColumnsChanged(updatedOrderFields);
      setChangedOrderTableFields(updatedOrderFields);

      // We only want to update the user's personal list of order table fields
      // if the current view is a default view.
      if (
        (!ffEnableNewTableFunctions || isNil(currentSavedViewUuid)) &&
        !isNil(userUuid)
      ) {
        await updateUser({
          variables: {
            updateUserInput: {
              uuid: userUuid,
              [userFieldForTableFields]: updatedOrderFields,
            },
          },
        });
      }
    }
  };

  const handleUpdateExistingSavedView = useCallback(
    async ({ isAutoSave = false }: { isAutoSave?: boolean }) => {
      const filterModel: FilterModel | undefined = ffEnableNewTableFunctions
        ? (filterModelV2Ref.current ?? {})
        : gridRef.current?.api.getFilterModel();

      const orderSortV2 = getOrderSortV2(gridRef.current);
      try {
        if (isNil(currentSavedViewUuid)) {
          throw new Error(
            '[saving saved view] trying to save to null saved view UUID',
          );
        }
        await updateSavedViewMutation({
          variables: {
            updateSavedFilterViewInput: {
              uuid: currentSavedViewUuid,
              filterModelJson: JSON.stringify(filterModel ?? {}),
              sortModelJson: ffEnableNewTableFunctions
                ? (orderSortV2 ?? [])
                : [],
              orderTableFields: ffEnableNewTableFunctions
                ? orderTableFields
                : undefined,
            },
          },
          refetchQueries: [GetSavedFilterViewsDocument],
        });
        setNumFiltersChanged(0);
        setNumSortsChanged(0);
        setNumColumnsChanged(0);
        setChangedSortModel(null);
        setChangedOrderTableFields(null);
        setState((prevState) => {
          return {
            ...prevState,
            ...(ffEnableNewTableFunctions
              ? {
                  orderTableFilterModel:
                    (filterModel as OrderTableFilterModel) ?? {},
                  orderTableFields,
                }
              : { customFilterModelJson: filterModel ?? {} }),
            customSortModelJson: orderSortV2 ?? [],
          };
        });
        if (isAutoSave) {
          setSavedViewSaveSuccessAlertText('View auto-saved');
        } else {
          setSavedViewSaveSuccessAlertText('View saved');
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error saving filter view', error);
        setSavedViewSaveFailedAlert(true);
      }
    },
    [
      currentSavedViewUuid,
      orderTableFields,
      ffEnableNewTableFunctions,
      updateSavedViewMutation,
      setNumFiltersChanged,
      setNumSortsChanged,
      setNumColumnsChanged,
      setChangedSortModel,
      setChangedOrderTableFields,
      setState,
    ],
  );

  // callback which AG Grid calls when a filter changes.
  const handleFilterChanged = () => {
    const filterModel: FilterModel | undefined = ffEnableNewTableFunctions
      ? (filterModelV2Ref.current ?? {})
      : gridRef.current?.api.getFilterModel();

    if (shouldRememberFilters && !ffEnableNewTableFunctions) {
      setRememberedFilters(JSON.stringify(filterModel ?? {}), pageType);
    }

    if (stateRef.current.currentTabIsNewView) {
      handleUpdateExistingSavedView({ isAutoSave: true });
      stateRef.current.currentTabIsNewView = false;
    } else {
      computeNumFiltersChanged(filterModel);

      // null out cursor
      setState((prevState) => {
        return {
          ...prevState,
          currentCursor: null,
        };
      });
    }
    if (!isNil(handleFilterChange)) {
      handleFilterChange(filterModel);
    }
  };

  const handleSortChanged = () => {
    // eslint-disable-next-line no-console
    console.log('Calling refreshGrid from handleSortChanged');
    refreshGrid();
    const orderSortV2 = ffEnableNewTableFunctions
      ? getOrderSortV2(gridRef.current)
      : null;
    const changesFromOriginalSortModel = computeNumSortsChanged(
      orderSortV2 ?? null,
    );
    if (changesFromOriginalSortModel > 0) {
      setChangedSortModel(orderSortV2 ?? null);
    } else {
      setChangedSortModel(null);
    }
    setState((prevState) => {
      return {
        ...prevState,
        currentCursor: null,
      };
    });
  };

  /// /////////////////////////////////////////////////////////////////////////////

  const handleSearch = useCallback(
    (searchText?: string) => {
      if (shouldRememberFilters) {
        setRememberedSearch(searchText ?? '', pageType);
      }
      setState((prevState) => {
        return {
          ...prevState,
          searchText: searchText ?? '',
          currentCursor: null,
        };
      });
      if (
        stateRef.current.searchText.length > 0 &&
        !stateRef.current.multipleSearches.includes(
          stateRef.current.searchText,
        ) &&
        useMultiSearchText === true
      ) {
        setState((prevState) => {
          return {
            ...prevState,
            multipleSearches: [
              ...prevState.multipleSearches,
              stateRef.current.searchText,
            ],
          };
        });
      }
      // eslint-disable-next-line no-console
      console.log('Calling refreshGrid from handleSearch');
      refreshGrid(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldRememberFilters, pageType],
  );

  const handleCustomerChange = (option: Option | PrefixOption | undefined) => {
    setState((prevState) => {
      return {
        ...prevState,

        customerOptions: isPrefixOption(option)
          ? option
          : isNil(option)
            ? undefined
            : [option],
        currentCursor: null,
      };
    });
    if (!isNil(handleSelectContact)) {
      handleSelectContact(
        isSingleCustomerOption(option) ? option.value : undefined,
      );
    }
    // eslint-disable-next-line no-console
    console.log('Calling refreshGrid from handleCustomerChange');
    refreshGrid();
  };

  // Multi-select
  const handleCustomersChange = (
    options: Option[] | PrefixOption | null | undefined,
  ) => {
    setState((prevState) => {
      return {
        ...prevState,
        customerOptions: options,
        currentCursor: null,
      };
    });
    if (!isNil(handleSelectContacts)) {
      handleSelectContacts(
        isPrefixOption(options) ? [] : options?.map((option) => option.value),
      );
    }
    // eslint-disable-next-line no-console
    console.log('Calling refreshGrid from handleCustomersChange');
    refreshGrid();
  };

  const { terminalOptions: currentTerminalOptions } = stateRef.current;
  const currentTerminalOption = currentTerminalOptions?.[0];
  useEffect(() => {
    const terminalCode = getTerminalCode(selectedTerminalUuid);
    if (
      ffEnableNewTableFunctions &&
      (selectedTerminalUuid !== currentTerminalOption?.value ||
        terminalCode !== currentTerminalOption?.label)
    ) {
      setState((prevState) => {
        return {
          ...prevState,
          terminalOptions: isNil(selectedTerminalUuid)
            ? undefined
            : [
                {
                  label: terminalCode,
                  value: selectedTerminalUuid,
                },
              ],
          currentCursor: null,
        };
      });
      // eslint-disable-next-line no-console
      console.log('Calling refreshGrid from terminalCode useEffect');
      refreshGrid();
    }
  }, [
    selectedTerminalUuid,
    ffEnableNewTableFunctions,
    currentTerminalOption,
    getTerminalCode,
    setState,
    refreshGrid,
  ]);

  const handleTerminalsChange = (options: Option[] | null | undefined) => {
    setState((prevState) => {
      return {
        ...prevState,
        terminalOptions: options,
        currentCursor: null,
      };
    });
    // eslint-disable-next-line no-console
    console.log('Calling refreshGrid from handleTerminalsChange');
    refreshGrid();
  };

  const handleOriginTerminalChange = (option: Option | null | undefined) => {
    setState((prevState) => {
      return {
        ...prevState,
        originTerminalOption: option,
        currentCursor: null,
      };
    });
    if (!isNil(handleSelectOriginTerminal)) {
      handleSelectOriginTerminal(option?.value);
    }
    // eslint-disable-next-line no-console
    console.log('Calling refreshGrid from handleOriginTerminalChange');
    refreshGrid();
  };

  const handleDestinationTerminalChange = (
    option: Option | null | undefined,
  ) => {
    setState((prevState) => {
      return {
        ...prevState,
        destinationTerminalOption: option,
        currentCursor: null,
      };
    });
    if (!isNil(handleSelectDestinationTerminal)) {
      handleSelectDestinationTerminal(option?.value);
    }
    // eslint-disable-next-line no-console
    console.log('Calling refreshGrid from handleDestinationTerminalChange');
    refreshGrid();
  };

  const handleDatePickerChange = (option: DateOption) => {
    setState((prevState) => {
      return {
        ...prevState,
        dateOption: option,
        currentCursor: null,
      };
    });
    if (!isNil(handleSelectDate)) {
      handleSelectDate(option);
    }
    if (shouldRememberFilters) {
      setRememberedDateOption(option, pageType);
    }
    // eslint-disable-next-line no-console
    console.log('Calling refreshGrid from handleDatePickerChange');
    refreshGrid();
  };

  // TODO: Replace this with the right output for the table report (right now, the output shape is acceptable because it is the same shape as the query variables for the getOrderTableFieldValues query
  const buildOrderTableReportInput =
    (): GetOrderTableFieldValuesQueryVariables => {
      const agGridFilterModelV2 = getAGGridFilterModel(
        filterModelV2Ref.current ?? {},
      );
      const filterModel = gridRef.current?.api?.getFilterModel() ?? {};
      const sortModel = isNil(gridRef.current)
        ? undefined
        : getAGGridSortModel(gridRef.current);

      const uiFilterOptions = {
        terminalOptions: ignoreSelectedUserTerminal
          ? undefined
          : stateRef.current.terminalOptions,
        customerOptions: stateRef.current.customerOptions,
        originTerminalOption: stateRef.current.originTerminalOption,
        destinationTerminalOption: stateRef.current.destinationTerminalOption,
        dateOption: stateRef.current.dateOption,
      };

      const variables = getFetchOrdersVariables({
        currentOrdersTab: stateRef.current.ordersTab,
        defaultTabsConfigs: defaultFilterTabsConfigs,
        defaultTableFilters,
        filterModel: ffEnableNewTableFunctionsRef.current
          ? agGridFilterModelV2
          : filterModel,
        sortModel,
        uiFilterOptions,
        ffEnableNewTableFunctions: ffEnableNewTableFunctionsRef.current,
      });

      return variables;
    };

  const bulkActionsOverLimit =
    (isEmpty(selectedUuids) && isNil(stateRef.current.totalCount)) ||
    (isEmpty(selectedUuids)
      ? (stateRef.current.totalCount ?? 10_000)
      : selectedUuids.length) > 400;

  const onPageSizeChange = useCallback(
    (value: PageSizes) => {
      setState((prevState) => {
        return {
          ...prevState,
          pageSize: value,
          currentCursor: null,
        };
      });
      // eslint-disable-next-line no-console
      console.log('Calling refreshGrid from onPageSizeChange');
      refreshGrid();
    },
    [setState, refreshGrid],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      {showConfigureOrderHeaders && (
        <ConfigureOrderTableHeaders
          open={showConfigureOrderHeaders}
          setOpen={setShowConfigureOrderHeaders}
          initialFields={
            ffEnableNewTableFunctions
              ? orderTableFields
              : getOrderTableFields(gridRef.current)
          }
          onSaveOrderTableColumns={onSaveOrderTableColumns}
        />
      )}
      {ffEnableNewTableFunctions ? (
        <OrdersTableFunctionsContainerNew<DefaultFilterTabsType>
          gridRef={gridRef}
          stateRef={stateRef}
          setState={setState}
          defaultFilterTabsConfigs={defaultFilterTabsConfigs}
          columnDefs={columnDefs}
          orderTableFields={orderTableFields}
          defaultViewOrderTableFields={userOrderTableFields}
          getOrderTableFieldValuesInput={filters?.getOrderTableFieldValuesInput}
          changedSortModel={changedSortModel}
          setChangedSortModel={setChangedSortModel}
          changedOrderTableFields={changedOrderTableFields}
          setChangedOrderTableFields={setChangedOrderTableFields}
          refreshGrid={refreshGrid}
          applyFilterModel={applyFilterModel}
          applySortModel={applySortModel}
          setTableColumns={setTableColumns}
          controlBarSpacing={controlBarSpacing}
          pageType={pageType}
          topRightComponent={topRightComponent}
          handleUpdateExistingSavedView={handleUpdateExistingSavedView}
          numFiltersChanged={numFiltersChanged}
          setNumFiltersChanged={setNumFiltersChanged}
          numSortsChanged={numSortsChanged}
          setNumSortsChanged={setNumSortsChanged}
          numColumnsChanged={numColumnsChanged}
          setNumColumnsChanged={setNumColumnsChanged}
          savedViewSaveSuccessAlertText={savedViewSaveSuccessAlertText}
          setSavedViewSaveSuccessAlertText={setSavedViewSaveSuccessAlertText}
          savedViewSaveFailedAlert={savedViewSaveFailedAlert}
          setSavedViewSaveFailedAlert={setSavedViewSaveFailedAlert}
          shouldShowDatePicker={shouldShowDatePicker}
          defaultDatePickerFilterType={defaultDatePickerFilterType}
          handleDatePickerChange={handleDatePickerChange}
          handleSearch={handleSearch}
          multipleSearches={stateRef.current.multipleSearches}
          bulkActionsEnabled={bulkActionsEnabled}
          bulkActionsOverLimit={bulkActionsOverLimit}
          selectedUuids={selectedUuids}
          deselectAll={deselectAll}
          shouldAllowSavedViews={shouldAllowSavedViews}
          shouldRememberFilters={shouldRememberFilters}
          buildOrderTableReportInput={buildOrderTableReportInput}
          shouldShowGenerateReportButtons={shouldShowGenerateReportButtons}
          filterModelV2={filterModelV2}
          setFilterModelV2={applyFilterModel}
          shouldShowAddOrderButton={shouldShowAddOrderButton}
          onSaveOrderTableColumns={onSaveOrderTableColumns}
        />
      ) : (
        <OrdersTableFunctionsContainer<DefaultFilterTabsType>
          gridRef={gridRef}
          stateRef={stateRef}
          setState={setState}
          defaultFilterTabsConfigs={defaultFilterTabsConfigs}
          orderTableFields={orderTableFields}
          columnDefs={columnDefs}
          getOrderTableFieldValuesInput={filters?.getOrderTableFieldValuesInput}
          refreshGrid={refreshGrid}
          applyFilterModel={legacyApplyFilterModel}
          isTableLoading={isTableLoading}
          controlBarSpacing={controlBarSpacing}
          pageType={pageType}
          topRightComponent={topRightComponent}
          populateTableFromConfig={async () =>
            populateTableFromConfig(orderTableFields)
          }
          handleSaveFiltersToExistingFilterView={handleUpdateExistingSavedView}
          newFiltersApplied={numFiltersChanged}
          setNewFiltersApplied={setNumFiltersChanged}
          filterViewSaveSuccessAlertText={savedViewSaveSuccessAlertText}
          setFilterViewSaveSuccessAlertText={setSavedViewSaveSuccessAlertText}
          filterViewSaveFailedAlert={savedViewSaveFailedAlert}
          setFilterViewSaveFailedAlert={setSavedViewSaveFailedAlert}
          shouldShowDatePicker={shouldShowDatePicker}
          defaultDatePickerFilterType={defaultDatePickerFilterType}
          handleDatePickerChange={handleDatePickerChange}
          shouldShowCustomerFilter={shouldShowCustomerFilter}
          shouldShowCustomerFilterMultiselect={
            shouldShowCustomerFilterMultiselect
          }
          handleCustomerChange={handleCustomerChange}
          handleCustomersChange={handleCustomersChange}
          shouldShowTerminalFilter={shouldShowTerminalFilter}
          shouldShowNoTerminalOptionMultiselect={
            shouldShowNoTerminalOptionMultiselect
          }
          shouldShowOriginTerminalFilter={shouldShowOriginTerminalFilter}
          shouldShowDestinationTerminalFilter={
            shouldShowDestinationTerminalFilter
          }
          handleTerminalsChange={handleTerminalsChange}
          handleOriginTerminalChange={handleOriginTerminalChange}
          handleDestinationTerminalChange={handleDestinationTerminalChange}
          terminalFilterCacheId={terminalFilterCacheId}
          originTerminalFilterCacheId={originTerminalFilterCacheId}
          destinationTerminalFilterCacheId={destinationTerminalFilterCacheId}
          handleSearch={handleSearch}
          multipleSearches={stateRef.current.multipleSearches}
          bulkActionsEnabled={bulkActionsEnabled}
          bulkActionsOverLimit={bulkActionsOverLimit}
          selectedUuids={selectedUuids}
          deselectAll={deselectAll}
          setShowConfigureOrderHeaders={setShowConfigureOrderHeaders}
          shouldAllowSavedFilterViews={shouldAllowSavedViews}
          shouldRememberFilters={shouldRememberFilters}
          openUploadPdfsModal={openUploadPdfsModal}
          openUploadCsvsModal={openUploadCsvsModal}
          openBillingPartyModal={openBillingPartyModal}
          buildOrderTableReportInput={buildOrderTableReportInput}
          shouldShowGenerateReportButtons={shouldShowGenerateReportButtons}
        />
      )}
      {isHeaderCheckboxSelected && showSelectAllOrders === true && (
        <SelectAllOrders
          setSelectedUuids={setSelectedUuids}
          queryVariables={
            // TODO (Luke): Clean this up. This is a temporary solution to unbreak the 'Select all orders' functionality
            // that is currently entirely borked. The value being passed in here actually has a type coming from
            // FindOrdersBaseInput, whereas the orders query (orderUuids) in the component expects FindOrdersBaseArgs, which
            // is close but not identical: https://www.diffchecker.com/3StH6bTf/
            // Because all types are optional, this typechecks, but will not behave as expected if non-overlapping filters are
            // included. We need to fix this within the next few days by eliminating the duplicate args / input type on order
            // queries.
            buildOrderTableReportInput().getOrderTableFieldValuesInput
          }
          gridRef={gridRef}
          pageSize={stateRef.current.pageSize}
          setIsHeaderCheckboxSelected={setIsHeaderCheckboxSelected}
          handleSelectAllOrders={handleSelectAllOrders}
          count={gridRef?.current?.api?.getDisplayedRowCount()}
        />
      )}
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <PalletAgGridReact<FormattedOrderFragment>
          ref={gridRef}
          suppressPaginationPanel
          suppressCellFocus
          rowMultiSelectWithClick
          pagination
          pageType={pageType}
          rowHeight={25}
          headerHeight={25}
          columnDefs={columnDefs}
          rowSelection={
            rowSelectionEnabled || bulkActionsEnabled ? 'multiple' : undefined
          }
          defaultColDef={DEFAULT_COL_DEF}
          rowModelType="serverSide"
          cacheBlockSize={stateRef.current.pageSize}
          paginationPageSize={stateRef.current.pageSize}
          animateRows={false}
          sideBar={getAgGridSideBarDef(pageType)}
          getRowId={(params) => params.data.uuid}
          onColumnMoved={handleColumnMoved}
          onGridReady={onGridReady}
          onFilterChanged={handleFilterChanged}
          onRowSelected={(e) => {
            if (e.source === 'uiSelectAll') {
              // Handled by selectAllOrdersOnPage, this saves some re-rendering
              return;
            }
            handleRowSelected();
          }}
          onSelectionChanged={handleSelectionChanged}
          onPaginationChanged={handlePaginationChanged}
          onSortChanged={handleSortChanged}
          onCellClicked={(e) => {
            if (
              e.column.getColId() === ExcludeFromHiding.BUTTON ||
              e.column.getColId() === OrderTableField.Dims
            ) {
              e.node.setSelected(false);
            }
          }}
          onCellDoubleClicked={(e) => {
            if (
              e.column.getColId() === OrderTableField.PrimaryServiceLevel ||
              e.column.getColId() === OrderTableField.Dims
            )
              return;
            e.node.setSelected(false);
            const uuid = e.data?.uuid;
            if (!isNil(uuid)) {
              setOpenedOrderUuid(uuid);
              setSearchParams((sp) => {
                const newParams = new URLSearchParams(sp);
                newParams.set('orderUuid', uuid);
                return newParams;
              });
            }
          }}
        />
      </div>
      <TablePagination
        component="div"
        sx={{
          '.MuiTablePagination-toolbar': {
            height: '100%',
            minHeight: '100%',
          },
        }}
        // eslint-disable-next-line react/no-unstable-nested-components
        ActionsComponent={(props) => (
          <OrdersTablePaginationActions
            {...props}
            hasNextPage={stateRef.current.hasNextPage}
          />
        )}
        rowsPerPageOptions={VALID_PAGE_SIZES}
        count={stateRef.current.totalCount ?? -1}
        page={currentPage}
        rowsPerPage={stateRef.current.pageSize}
        labelDisplayedRows={OrdersTableDisplayedPagination}
        labelRowsPerPage="Show:"
        onPageChange={(_, newPage) => {
          setCurrentPage(newPage); // AG Grid is 0-indexed
          gridRef.current?.api.paginationGoToPage(newPage);
        }}
        onRowsPerPageChange={(e) => {
          onPageSizeChange(Number.parseInt(e.target.value, 10) as PageSizes);
        }}
      />
    </Box>
  );
};
