import {
  FormControl,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';

export const VALID_PAGE_SIZES = [25, 50, 100] as const;
export type PageSizes = (typeof VALID_PAGE_SIZES)[number];
export const MAX_PAGE_SIZE = Math.max(...VALID_PAGE_SIZES) as PageSizes;

type PageSizeSelectorProps = {
  readonly initialPageSize: PageSizes;
  readonly onPageSizeChange: (pageSize: PageSizes) => void;
};
const PageSizeSelect = Select<PageSizes>;

const PageSizeSelector = ({
  initialPageSize,
  onPageSizeChange,
}: PageSizeSelectorProps) => {
  const [selectedPageSize, setSelectedPageSize] =
    useState<PageSizes>(initialPageSize);

  const onChange = (event: SelectChangeEvent<PageSizes>) => {
    const newPageSize = event.target.value;
    if (typeof newPageSize === 'string') {
      return;
    }
    setSelectedPageSize(newPageSize);
    onPageSizeChange(newPageSize);
  };

  return (
    <FormControl sx={{ width: 'auto' }}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>Show</Typography>
        <PageSizeSelect
          displayEmpty
          sx={{
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            '.MuiSelect-select ': { height: 'fit-content' },
          }}
          value={selectedPageSize}
          size="small"
          onChange={onChange}
        >
          {VALID_PAGE_SIZES.map((size) => (
            <MenuItem key={size} value={size}>
              {size}
            </MenuItem>
          ))}
        </PageSizeSelect>
      </Stack>
    </FormControl>
  );
};

export default PageSizeSelector;
