import { type ApolloError } from '@apollo/client';
import isEqual from 'fast-deep-equal';
import { isNil } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { convertDollarsToCents } from 'shared/billing';
import { safeAdd } from 'shared/math';
import {
  OrderDetailedStatus,
  type RateOrderInput,
  type StopType,
  useRateOrderTotalDollarsLazyQuery,
} from '../../../../../generated/graphql';
import {
  type CustomerPortalInboundInformation,
  type CustomerPortalOutboundInformation,
  type CustomerPortalPackageInformation,
} from '../../../../customer-portal/components/customer-portal-order-form/forms/types';
import { QuoteFormStatus } from '../../../../customer-portal/components/customer-portal-quote-form/constants';
import { useSaveQuoteCustomerPortal } from '../../../../customer-portal/components/customer-portal-quote-form/hooks/use-save-quote-customer-portal';
import { type ThirdPartyUserCompany } from '../../../../customer-portal/types';
import {
  convertCustomerPortalStopToRateOrderStop,
  convertPriceToQuoteStatus,
  getCoalescedPrice,
  missingStopInfo,
} from '../utils';

export type UseRateQuoteContentsProps = {
  contactUuid: string;
  company: ThirdPartyUserCompany;
  dimFactor: number;
  serviceUuid: string;
  inboundInformation: CustomerPortalInboundInformation | null;
  outboundInformation: CustomerPortalOutboundInformation | null;
  packages: CustomerPortalPackageInformation;
  inboundStopType: StopType | null;
  outboundStopType: StopType | null;
  setLatestQuoteUuid: (quoteUuid: string | null) => void;
};

export const useRateQuoteContents = ({
  contactUuid,
  company,
  serviceUuid,
  dimFactor,
  packages,
  inboundInformation,
  outboundInformation,
  inboundStopType,
  outboundStopType,
  setLatestQuoteUuid,
}: UseRateQuoteContentsProps): {
  rateQuote: () => Promise<void>;
  price: number | null;
  loading: boolean;
  error: ApolloError | null;
  quoteStatus: QuoteFormStatus;
} => {
  const { saveQuote } = useSaveQuoteCustomerPortal({
    companyUuid: company.uuid,
    contactUuid,
  });

  const [previousRateOrderInput, setPreviousRateOrderInput] =
    useState<RateOrderInput | null>(null);

  const prevRateOrderInputRef = useRef<RateOrderInput | null>(null);

  // For quoting, we require customers to fill out a service date and deadline date for either stop,
  // and an address or terminal of at least one stop.
  const missingServiceDate =
    isNil(inboundInformation?.serviceDate) &&
    isNil(outboundInformation?.serviceDate);
  const missingDeadlineDate =
    isNil(inboundInformation?.deadlineDate) &&
    isNil(outboundInformation?.deadlineDate);
  const missingInboundInfo =
    isNil(inboundInformation) ||
    missingStopInfo(inboundInformation, inboundStopType);
  const missingOutboundInfo =
    isNil(outboundInformation) ||
    missingStopInfo(outboundInformation, outboundStopType);
  const missingInfo =
    missingServiceDate ||
    missingDeadlineDate ||
    (missingInboundInfo && missingOutboundInfo);

  const rateOrderInput: RateOrderInput | null = useMemo(() => {
    if (missingInfo) {
      return null;
    }

    let totalPackageQuantity = 0;
    for (const pkg of packages) {
      totalPackageQuantity = safeAdd(totalPackageQuantity, pkg.quantity);
    }
    const newRateOrderInput: RateOrderInput = {
      companyUuid: company.uuid,
      contactUuid,
      serviceUuid,
      inboundShipment:
        isNil(inboundInformation) || isNil(inboundStopType)
          ? null
          : convertCustomerPortalStopToRateOrderStop(
              inboundInformation,
              inboundStopType,
              totalPackageQuantity,
            ),
      outboundShipment:
        isNil(outboundInformation) || isNil(outboundStopType)
          ? null
          : convertCustomerPortalStopToRateOrderStop(
              outboundInformation,
              outboundStopType,
              totalPackageQuantity,
            ),
      packages:
        packages?.map((pkg) => ({
          weight: pkg.weight,
          length: pkg.length,
          width: pkg.width,
          height: pkg.height,
          quantity: pkg.quantity,
        })) ?? [],
      dimFactor,
      detailedStatus: OrderDetailedStatus.Creating,
    };

    if (isEqual(prevRateOrderInputRef.current, newRateOrderInput)) {
      return prevRateOrderInputRef.current;
    }

    // Changes to the quote would mean that the quote is no longer the latest quote.
    setLatestQuoteUuid(null);

    prevRateOrderInputRef.current = newRateOrderInput;
    return newRateOrderInput;
  }, [
    company,
    contactUuid,
    serviceUuid,
    inboundInformation,
    outboundInformation,
    packages,
    dimFactor,
    inboundStopType,
    outboundStopType,
    missingInfo,
    setLatestQuoteUuid,
  ]);

  const [
    rateOrderTotalDollars,
    { data: rateOrderTotalDollarsData, loading, error },
  ] = useRateOrderTotalDollarsLazyQuery({
    onCompleted: async (data) => {
      if (
        convertPriceToQuoteStatus({
          price: getCoalescedPrice(data),
          isStale: false,
          missingInfo: false,
        }) === QuoteFormStatus.Quoted &&
        data.rateOrderContents?.__typename === 'RateOrderSuccessOutput'
      ) {
        const quoteRateInfo = {
          inboundTotalAmountCents: convertDollarsToCents(
            safeAdd(
              data.rateOrderContents?.inboundShipmentCharges?.freightCharge
                ?.totalDollars.value ?? 0,
              data.rateOrderContents?.inboundShipmentCharges?.fuelCharge
                ?.totalDollars.value ?? 0,
            ),
          ),
          outboundTotalAmountCents: convertDollarsToCents(
            safeAdd(
              data.rateOrderContents?.outboundShipmentCharges?.freightCharge
                ?.totalDollars.value ?? 0,
              data.rateOrderContents?.outboundShipmentCharges?.fuelCharge
                ?.totalDollars.value ?? 0,
            ),
          ),
        };

        const quoteUuid = await saveQuote({ quoteRateInfo });
        setLatestQuoteUuid(quoteUuid);
      } else {
        setLatestQuoteUuid(null);
      }
    },
  });

  const rateQuote = async () => {
    if (isNil(rateOrderInput)) {
      return;
    }

    await rateOrderTotalDollars({
      variables: {
        rateOrderInput,
      },
    });

    // Reset the stale state when a new quote is generated; the form is no longer stale.
    setPreviousRateOrderInput(rateOrderInput);
  };

  const coalescedPrice = getCoalescedPrice(rateOrderTotalDollarsData);
  const quoteStatus = convertPriceToQuoteStatus({
    price: coalescedPrice,
    isStale: previousRateOrderInput !== rateOrderInput,
    missingInfo,
  });
  return {
    rateQuote,
    price: coalescedPrice,
    loading,
    error: error ?? null,
    quoteStatus,
  };
};
