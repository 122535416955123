import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { getDocument } from 'pdfjs-dist';
import pluralize from 'pluralize';
import { useEffect, useState } from 'react';
import DocumentThumbnails from '../../../common/components/upload/document-thumbnails';
import DropzoneUpdate from '../../../common/components/upload/dropzone-update';
import {
  type UploadDocument,
  extractPdfPage,
} from '../../../common/components/upload/pdf-document-thumbnail';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import { isNilOrEmptyString } from '../../../common/utils/utils';
import {
  CompletedStopsNotOnRoutesDocument,
  DocumentType,
  DocumentsByOrderDocument,
  DocumentsByShipmentDocument,
  OrderWithPaperworkDocument,
  ShallowRoutesWithDocumentsDocument,
  StandardOrderDocument,
  useUpdateDocumentMutation,
} from '../../../generated/graphql';
import PalletButton from '../../../pallet-ui/button/pallet-button';
import { type DocViewerDocument } from '../types/doc-viewer-document';

const FILE_TYPE_ACCEPT = {
  'application/pdf': [],
  'image/png': [],
  'image/jpeg': [],
  'message/rfc822': [], // .eml
};

const styles = {
  fileCountText: {
    color: 'gray',
    fontSize: '14px',
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  dropDialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  footerDialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: 0,
  },
};

const createPDFUrlAndFileFromUploadDocument = async (
  page: UploadDocument,
): Promise<File> => {
  const singlePageFileName = `${page.file.name}_page_${page.attributes.pageNumber}.pdf`;
  const pdfBytes = await extractPdfPage(
    page.file,
    page.attributes.pageNumber ?? 1,
  );
  return new File([pdfBytes], singlePageFileName, {
    type: 'application/pdf',
  });
};

export type HandleNewDocumentParams = {
  file: File;
  fileName: string;
  documentType: DocumentType;
  name: string | null;
  fileType: string;
  /** The S3 GET URL of the document, if getAwsUrl provides it */
  s3Url: string | undefined;
};

/**
 * Some implementations might return a GET URL in addition to a PUT URL
 * to make fetching / displaying the document after upload easier.
 */
export type GetDocumentS3UrlResult =
  | { putUrl: string }
  | { getUrl: string; putUrl: string };

type MultipleDocumentUploadModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly getAwsUrl: (
    fileName: string,
    fileType: string,
  ) => Promise<GetDocumentS3UrlResult | undefined>;
  readonly handleNewDocument?: (params: HandleNewDocumentParams) => void;
  readonly fetchShipmentDocuments?: () => void;
  readonly existingDocuments?: DocViewerDocument[];
  readonly onDeleteDocument?: (uuid: string) => void;
  readonly onSave?: (
    documentType: DocumentType,
    existingDocument: DocViewerDocument,
    existingDocumentIndex: number,
  ) => void;
};

const MultipleDocumentUploadModal = ({
  isOpen,
  onClose,
  getAwsUrl,
  handleNewDocument,
  fetchShipmentDocuments,
  existingDocuments,
  onDeleteDocument,
  onSave: onSaveCallback,
}: MultipleDocumentUploadModalProps) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [droppedDocuments, setDroppedDocuments] = useState<UploadDocument[]>(
    [],
  );

  const ffDefaultDocumentType = useFeatureFlag(
    FeatureFlag.FF_DEMO_DEFAULT_DOCUMENT_TYPE,
  );

  const DEFAULT_DOCUMENT_TYPE = ffDefaultDocumentType
    ? DocumentType.DigitalProofOfDelivery
    : DocumentType.DeliveryReceipt;

  const [updateDocument] = useUpdateDocumentMutation({
    refetchQueries: [
      StandardOrderDocument,
      OrderWithPaperworkDocument,
      DocumentsByShipmentDocument,
      DocumentsByOrderDocument,
      CompletedStopsNotOnRoutesDocument,
      ShallowRoutesWithDocumentsDocument,
    ],
  });

  useEffect(() => {
    if (isNil(existingDocuments)) return;
    const convertedDocuments = existingDocuments.map(async (document) => {
      try {
        const res = await fetch(document.preSignedGetUrl);
        const blob = await res.blob();
        const downloadedFile = new File([blob], document.fileName ?? '', {
          type: document.fileType,
        });

        let pages: Promise<UploadDocument[]>;

        if (document.fileType === 'application/pdf') {
          const pdf = getDocument(URL.createObjectURL(downloadedFile));
          pages = pdf.promise.then((pdfDocumentProxy): UploadDocument[] => {
            const pagesCount = pdfDocumentProxy.numPages;
            const pdfPages: UploadDocument[] = [];
            for (let i = 0; i < pagesCount; i += 1) {
              const pdfPage: UploadDocument = {
                file: downloadedFile,
                attributes: {
                  isSelected: true,
                  documentType: document.docType,
                  url: URL.createObjectURL(downloadedFile),
                  pageNumber: i + 1,
                  pageCount: pagesCount,
                },
              };
              pdfPages.push(pdfPage);
            }
            return pdfPages;
          });
          return await pages.then((resolvedPages): UploadDocument => {
            return {
              file: downloadedFile,
              attributes: {
                isSelected: true,
                documentType: document.docType,
                url: URL.createObjectURL(downloadedFile),
                pageCount: resolvedPages.length,
                pages: resolvedPages,
                existingDocument: {
                  uuid: document.uuid,
                },
              },
            };
          });
        }

        return {
          file: downloadedFile,
          attributes: {
            isSelected: true,
            url: document.preSignedGetUrl,
            documentType: document.docType,
            existingDocument: {
              uuid: document.uuid,
            },
          },
        };
      } catch {
        return {
          file: new File([], ''),
          attributes: {
            isSelected: true,
            url: document.preSignedGetUrl,
            documentType: document.docType,
            existingDocument: {
              uuid: document.uuid,
            },
          },
        };
      }
    });
    Promise.all(convertedDocuments).then((res) => {
      setDroppedDocuments(res);
    });
  }, []);

  const auxOnClose = () => {
    setDroppedDocuments((prev) => {
      return prev.filter((document) =>
        Boolean(document.attributes.existingDocument),
      );
    });
    onClose();
  };

  const addDocumentToAWSAndHandle = async (
    urlResult: GetDocumentS3UrlResult,
    file: File,
    fileName: string,
    documentUploadType: DocumentType,
  ) => {
    const { getUrl, putUrl } =
      'getUrl' in urlResult
        ? urlResult
        : {
            getUrl: undefined,
            putUrl: urlResult.putUrl,
          };
    const options = { headers: { 'Content-Type': file.type } };
    const awsRes = await fetch(putUrl, {
      method: 'PUT',
      body: file,
      ...options,
    });
    if (awsRes.status === 200) {
      handleNewDocument?.({
        file,
        fileName,
        documentType: documentUploadType,
        name: file.name,
        fileType: file.type,
        s3Url: getUrl,
      });
      fetchShipmentDocuments?.();
      auxOnClose();
    } else {
      setShowSnackbar(true);
    }
  };

  const onSinglePageDocumentSave = async (
    file: File,
    documentType: DocumentType,
  ) => {
    const urlResult = await getAwsUrl(file.name, file.type);
    if (isNilOrEmptyString(urlResult)) return;

    await addDocumentToAWSAndHandle(urlResult, file, file.name, documentType);
  };

  const onMultiplePagesDocumentSave = async (document: UploadDocument) => {
    if (document.attributes.documentType !== 'MULTIPLE_PAGES') return;
    document.attributes.pages?.forEach(async (page) => {
      const {
        attributes: { isSelected, documentType },
      } = page;
      if (!isSelected || documentType === 'MULTIPLE_PAGES') return;

      const singlePageFile = await createPDFUrlAndFileFromUploadDocument(page);
      onSinglePageDocumentSave(singlePageFile, documentType);
    });
  };

  const onSave = async () => {
    setIsSaving(true);
    try {
      const savePromises = droppedDocuments.map(async (document) => {
        if (!isNil(document.attributes.existingDocument)) {
          const existingDocument = existingDocuments?.find(
            (doc) => doc.uuid === document.attributes.existingDocument?.uuid,
          );
          const existingDocumentIndex = existingDocuments?.findIndex(
            (doc) => doc.uuid === document.attributes.existingDocument?.uuid,
          );
          if (!existingDocument) return;
          if (existingDocument?.docType !== document.attributes.documentType) {
            if (document.attributes.documentType === 'MULTIPLE_PAGES') {
              await onMultiplePagesDocumentSave(document);
              return;
            }
            if (!isNil(existingDocumentIndex)) {
              onSaveCallback?.(
                document.attributes.documentType,
                existingDocument,
                existingDocumentIndex,
              );
            }
            await updateDocument({
              variables: {
                updateDocumentInput: {
                  documentUpdateInput: {
                    uuid: document.attributes.existingDocument.uuid,
                    type: document.attributes.documentType,
                  },
                },
              },
            });
          }
          if (!document.attributes.isSelected) {
            onDeleteDocument?.(document.attributes.existingDocument.uuid);
          }
        } else if (document.attributes.documentType === 'MULTIPLE_PAGES') {
          await onMultiplePagesDocumentSave(document);
        } else {
          await onSinglePageDocumentSave(
            document.file,
            document.attributes.documentType,
          );
        }
      });

      await Promise.all(savePromises);
      auxOnClose();
    } catch {
      setShowSnackbar(true);
    } finally {
      setIsSaving(false);
    }
  };

  const onFileDrop = async (acceptedFiles: File[]) => {
    const newDocuments = acceptedFiles.map(async (file) => {
      if (file.type === 'application/pdf') {
        const pdf = getDocument(URL.createObjectURL(file));
        const pages = pdf.promise.then((pdfDocumentProxy): UploadDocument[] => {
          const pagesCount = pdfDocumentProxy.numPages;
          const pdfPages: UploadDocument[] = [];
          for (let i = 0; i < pagesCount; i += 1) {
            const pdfPage: UploadDocument = {
              file,
              attributes: {
                isSelected: true,
                documentType: DEFAULT_DOCUMENT_TYPE,
                url: URL.createObjectURL(file),
                pageNumber: i + 1,
                pageCount: pagesCount,
              },
            };
            pdfPages.push(pdfPage);
          }
          return pdfPages;
        });
        return pages.then((resolvedPages): UploadDocument => {
          return {
            file,
            attributes: {
              isSelected: true,
              documentType: DEFAULT_DOCUMENT_TYPE,
              url: URL.createObjectURL(file),
              pageCount: resolvedPages.length,
              pages: resolvedPages,
            },
          };
        });
      }
      return {
        file,
        attributes: {
          isSelected: true,
          url: URL.createObjectURL(file),
          documentType: DEFAULT_DOCUMENT_TYPE,
        },
      };
    });
    const result = await Promise.all(newDocuments);
    setDroppedDocuments([...droppedDocuments, ...result]);
  };

  const updateDocumentAttributes = (
    attribute: UploadDocument['attributes'],
    documentIndex: number,
    pageIndex?: number,
  ) => {
    const updatedDocuments = droppedDocuments.map((document, i) => {
      if (i !== documentIndex) return document;
      // Document being updated is a single page document within a multiple page document
      if (pageIndex !== undefined) {
        const updatedPages = document.attributes.pages?.map((page, j) => {
          if (j !== pageIndex) return page;
          return {
            ...page,
            attributes: {
              ...page.attributes,
              ...attribute,
            },
          };
        });
        return {
          ...document,
          attributes: {
            ...document.attributes,
            pages: updatedPages,
          },
        };
      }

      return {
        ...document,
        attributes: {
          ...document.attributes,
          ...attribute,
        },
      };
    });
    setDroppedDocuments(updatedDocuments);
  };

  return (
    <Dialog fullWidth open={isOpen} maxWidth="lg" onClose={auxOnClose}>
      <IconButton
        aria-label="close"
        sx={styles.closeButton}
        onClick={auxOnClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>Upload files</DialogTitle>
      <DialogContent sx={styles.dropDialogContent}>
        <DropzoneUpdate
          accept={FILE_TYPE_ACCEPT}
          disabled={isSaving}
          onDrop={onFileDrop}
        />
        <DocumentThumbnails
          updateDocumentAttributes={updateDocumentAttributes}
          thumbnailDocuments={droppedDocuments}
        />
      </DialogContent>
      <DialogContent dividers sx={styles.footerDialogContent}>
        <Typography style={styles.fileCountText}>
          {droppedDocuments.length}{' '}
          {pluralize('files', droppedDocuments.length)}
        </Typography>
        <PalletButton variant="text" loading={isSaving} onClick={onSave}>
          Save
        </PalletButton>
      </DialogContent>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={4000}
        open={showSnackbar}
        onClose={() => {
          setShowSnackbar(false);
        }}
      >
        <Alert
          severity="error"
          onClose={() => {
            setShowSnackbar(false);
          }}
        >
          Error uploading file
        </Alert>
      </Snackbar>
    </Dialog>
  );
};

export default MultipleDocumentUploadModal;
