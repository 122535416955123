import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import {
  type AutocompleteRenderInputParams,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import { isEmpty, isNil, uniq, values } from 'lodash';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { filterNotNil } from 'shared/array';
import { MAX_INTEGER } from 'shared/math';
import { isNilOrEmptyString } from 'shared/string';
import { exhaustive } from 'shared/switch';
import { v4 } from 'uuid';
import GeneralLedgerCodeAutocomplete from '../../../../common/components/general-ledger-code-autocomplete';
import { FeatureFlag } from '../../../../common/feature-flags';
import { validateString } from '../../../../common/form/formValidators';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useServices from '../../../../common/react-hooks/use-services';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import {
  AccessorialType,
  type CreateAccessorialMutationVariables,
  type SpecialAccessorialChargeGroupArrayUpdateInput,
  type SpecialAccessorialChargeGroupCreateInput,
  type SpecialAccessorialMatrixItemArrayUpdateInput,
  type SpecialAccessorialMatrixItemCreateInput,
  type SpecialAccessorialMileRangeCreateInput,
  SpecialAccessorialType,
  SpecialDayOfWeek,
  type TariffZoneType,
  type UpdateAccessorialMutationVariables,
  useAccessorialDateRangeConfigByUuidLazyQuery,
  useAccessorialDateRangeConfigsLazyQuery,
  useAccessorialLazyQuery,
  useCreateAccessorialMutation,
  useFuelProfilesQuery,
  useTariffZoneGroupsQuery,
  useTariffZonesForGroupLazyQuery,
  useTariffZonesLazyQuery,
  useUpdateAccessorialMutation,
} from '../../../../generated/graphql';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { useIsEditingDisabled } from '../../../orders/hooks/use-page-mode';
import { muiStyles } from '../accessorial.styles';
import { type RangeCellValues } from '../common/range-cell';
import AccessorialPrices from './accessorial-prices/accessorial-prices';
import {
  ALL_TERMINALS,
  FormMode,
  getBackUrl,
  getNewSpecialAccessorialMatrixItem,
  NO_FUEL_PROFILE,
  type SpecialAccessorialChargeGroup,
  type SpecialAccessorialChargeGroupParams,
  type SpecialAccessorialFormErrors,
  type SpecialAccessorialFormValues,
  type SpecialAccessorialMatrixItem,
  type SpecialAccessorialTariffZoneFragment,
} from './common';
import { SpecialAccessorialChargeGroupField } from './special-accessorials/special-accessorial-charge-group-field';
import { SpecialAccessorialMatrixTableField } from './special-accessorials/special-accessorial-matrix-table-field';
import { SpecialAccessorialMileRangeField } from './special-accessorials/special-accessorial-mile-range-field';

const useFormValues = (
  uuid: string | undefined,
  tariffZonesData: SpecialAccessorialTariffZoneFragment[],
  isForContactAccessorial = false,
): [
  SpecialAccessorialFormValues,
  React.Dispatch<React.SetStateAction<SpecialAccessorialFormValues>>,
] => {
  const [formValues, setFormValues] = useState<SpecialAccessorialFormValues>({
    name: '',
    serviceLevelUuid: null,
    specialAccessorialType: SpecialAccessorialType.ZoneBased,
    chargeGroups: [],
    matrixItems: [],
    mileRangeEnds: [],
    percentForSettlement: 100,
    terminalUuid: ALL_TERMINALS,
    tariffZoneGroupId: null,
    fuelProfileUuid: NO_FUEL_PROFILE,
    code: null,
    ediCode: null,
    invoiceDisplayName: null,
    generalLedgerCodeId: null,
  });
  const [accessorialQuery] = useAccessorialLazyQuery();

  const [getAccessorialDateRangeConfigs] =
    useAccessorialDateRangeConfigsLazyQuery();
  const [getAccessorialDateRangeConfigByUuid] =
    useAccessorialDateRangeConfigByUuidLazyQuery();

  /**
   * All of the form logic in this file is legacy & should be deprecated eventually.
   * For the current form logic based around date range configs, see the AccessorialPrices component.
   * In the meantime, the function below (& logic in its callsite in useFormValues) is a way to patch
   * awareness of date-range configs into the legacy form logic. This is needed for creation of contact
   * specific accessorials, where the data is prepopulated from the template global accessorial. Without
   * awareness of date ranges, the form would include ALL charge groups and matrix items across all date
   * ranges. This would lead to duplicate charge groups and potentially incorrect matrix item entries,
   * as there would (in general) be multiple possible entries for a given zone/charge group pair (potentially
   * one for every date range config) and the rates could differ.
   */
  const getCurrentChargeGroupsAndMatrixItemsForAccessorial = useCallback(
    async (accessorialUuid: string) => {
      const dateRangeConfigs = await getAccessorialDateRangeConfigs({
        variables: {
          findAccessorialDateRangeConfigsInput: { accessorialUuid },
        },
      });
      const currentDateRangeConfig =
        dateRangeConfigs.data?.accessorialDateRangeConfigs?.accessorialDateRangeConfigs?.find(
          (config) => isNil(config.endDate),
        );
      if (isNil(currentDateRangeConfig)) {
        return null;
      }
      const fullDateRangeConfig = await getAccessorialDateRangeConfigByUuid({
        variables: {
          uuid: currentDateRangeConfig.uuid,
        },
      });
      return {
        chargeGroups:
          fullDateRangeConfig.data?.accessorialDateRangeConfigByUuid
            .specialChargeGroups,
        matrixItems:
          fullDateRangeConfig.data?.accessorialDateRangeConfigByUuid.specialMatrixItems.map(
            (item) => ({
              ...item,
              zoneUuid: item.tariffZone.uuid,
            }),
          ),
      };
    },
    [getAccessorialDateRangeConfigByUuid, getAccessorialDateRangeConfigs],
  );

  useEffect(() => {
    if (uuid !== undefined) {
      accessorialQuery({ variables: { uuid: uuid ?? '' } }).then(
        async (response) => {
          const accessorial = response.data?.accessorial;

          if (!isNil(accessorial)) {
            const {
              name,
              code,
              ediCode,
              percentForSettlement,
              invoiceDisplayName,
              generalLedgerCode,
            } = accessorial;
            const isSpecial =
              accessorial.__typename === 'SpecialAccessorialEntity';
            let serviceLevelUuid: string | null = null;
            let tariffZoneGroupId: string | null = null;
            let terminalUuid: string | null = null;
            let fuelProfileUuid: string | null = null;
            let specialAccessorialType: SpecialAccessorialType | null = null;
            let chargeGroups: Array<{
              uuid: string;
              dayOfWeek: SpecialDayOfWeek;
              startTime: Date | null;
              endTime: Date | null;
            }> = [];
            let matrixItems: Array<{
              zoneUuid: string;
              chargeGroupUuid: string;
              uuid: string;
              rate: number;
            }> = [];

            if (isSpecial) {
              serviceLevelUuid = accessorial?.serviceLevel?.uuid ?? null;
              chargeGroups = accessorial.chargeGroups ?? null;
              matrixItems = accessorial.matrixItems;
              tariffZoneGroupId = accessorial.tariffZoneGroup?.id ?? null;
              terminalUuid = accessorial?.terminal?.uuid ?? null;
              fuelProfileUuid = accessorial?.fuelProfile?.uuid ?? null;
              specialAccessorialType =
                accessorial.specialAccessorialType ?? null;

              if (isForContactAccessorial) {
                try {
                  const dataForCurrentDateRangeConfig =
                    await getCurrentChargeGroupsAndMatrixItemsForAccessorial(
                      uuid,
                    );
                  if (
                    !isNil(dataForCurrentDateRangeConfig) &&
                    !isNil(dataForCurrentDateRangeConfig.chargeGroups) &&
                    !isNil(dataForCurrentDateRangeConfig.matrixItems)
                  ) {
                    chargeGroups = dataForCurrentDateRangeConfig.chargeGroups;
                    matrixItems = dataForCurrentDateRangeConfig.matrixItems;
                  }
                } catch (error) {
                  // eslint-disable-next-line no-console
                  console.error(
                    'Error getting current charge groups and matrix items for accessorial',
                    error,
                  );
                }
              }
            }

            const newFormValues: SpecialAccessorialFormValues = {
              name,
              serviceLevelUuid,
              specialAccessorialType,
              chargeGroups: chargeGroups.map((chargeGroup) => ({
                ...chargeGroup,
                toBeCreated: null,
                isUpdated: null,
              })),
              matrixItems: [],
              mileRangeEnds: [],
              percentForSettlement: percentForSettlement ?? null,
              terminalUuid: isEmpty(terminalUuid)
                ? ALL_TERMINALS
                : terminalUuid,
              tariffZoneGroupId,
              fuelProfileUuid: isEmpty(fuelProfileUuid)
                ? NO_FUEL_PROFILE
                : fuelProfileUuid,
              code: code ?? null,
              ediCode: ediCode ?? null,
              invoiceDisplayName: invoiceDisplayName ?? null,
              generalLedgerCodeId: generalLedgerCode?.id ?? null,
            };

            const matrixFormItems: SpecialAccessorialMatrixItem[][] = [];

            for (const zone of tariffZonesData) {
              const currRowItems: SpecialAccessorialMatrixItem[] = [];
              for (const chargeGroup of chargeGroups) {
                const zoneRate = matrixItems.find(
                  (item) =>
                    item.zoneUuid === zone.uuid &&
                    item.chargeGroupUuid === chargeGroup?.uuid,
                );
                if (!isNil(zoneRate)) {
                  currRowItems.push({
                    uuid: zoneRate.uuid,
                    rate: zoneRate.rate,
                    toBeCreated: null,
                    isUpdated: null,
                  });
                }
              }
              matrixFormItems.push(currRowItems);
            }

            setFormValues({ ...newFormValues, matrixItems: matrixFormItems });
          }
        },
      );
    }
  }, [
    uuid,
    tariffZonesData,
    isForContactAccessorial,
    accessorialQuery,
    getCurrentChargeGroupsAndMatrixItemsForAccessorial,
  ]);

  return [formValues, setFormValues];
};

const makeCreateInput = (
  formValues: SpecialAccessorialFormValues,
  tariffZonesData: Array<{ name: string; type: TariffZoneType; uuid: string }>,
  contactUuid: string | undefined,
  isAuthoCodeRequired: boolean,
  isDefaultGlobalSpecial: boolean,
  ffEnableMileageBasedSpecials: boolean,
  templateUuid?: string,
): { variables: CreateAccessorialMutationVariables } => {
  const chargeGroups: SpecialAccessorialChargeGroupCreateInput[] =
    formValues.chargeGroups.map(
      (chargeGroup: SpecialAccessorialChargeGroup) =>
        ({
          dayOfWeek: chargeGroup?.dayOfWeek,
          startTime: chargeGroup?.startTime,
          endTime: chargeGroup?.endTime,
        }) as SpecialAccessorialChargeGroupCreateInput,
    );

  // Because the end of one range is the start of the next range,
  // prepending 0 to mileRangeEnds gives us mileRangeStarts
  const mileRangeStarts = [0, ...filterNotNil(formValues.mileRangeEnds)];
  const mileRangeCreateInputs: SpecialAccessorialMileRangeCreateInput[] =
    mileRangeStarts.map((mileRangeStart, i) => ({
      uuid: v4(),
      start: mileRangeStart,
      end:
        i === mileRangeStarts.length - 1
          ? MAX_INTEGER
          : mileRangeStarts[i + 1]!,
    }));

  const matrixItems: SpecialAccessorialMatrixItemCreateInput[] = [];
  for (const [zoneIdx, matrixItemRow] of formValues.matrixItems.entries()) {
    const tariffZoneUuid =
      (formValues.specialAccessorialType === SpecialAccessorialType.ZoneBased ||
      formValues.specialAccessorialType === null
        ? tariffZonesData[zoneIdx]?.uuid
        : mileRangeCreateInputs[zoneIdx]?.uuid) ?? '';
    for (const [chargeGroupIdx, matrixItem] of matrixItemRow.entries()) {
      const currChargeGroup = formValues?.chargeGroups[chargeGroupIdx];

      let { rate } = matrixItem;

      // This should never happen because of the checks in validateMatrixItems
      // but is added as a failsafe to prevent saving a matrix item without a rate
      // which would cause no rate to be created for the (zone, charge group) pair
      if (isNil(matrixItem.rate) || Number.isNaN(matrixItem.rate)) {
        rate = 0;
      }

      matrixItems.push({
        chargeGroupDayOfWeek:
          currChargeGroup?.dayOfWeek ?? SpecialDayOfWeek.Weekday,
        chargeGroupStartTime: currChargeGroup?.startTime,
        chargeGroupEndTime: currChargeGroup?.endTime,
        tariffZoneUuid,
        rate,
      });
    }
  }

  return {
    variables: {
      input: {
        accessorialCreateInput: {
          specialAccessorialCreateInput: {
            name: formValues.name,
            specialAccessorialType: formValues.specialAccessorialType,
            serviceUuid: formValues.serviceLevelUuid,
            specialChargeGroups: chargeGroups,
            specialMileRanges: ffEnableMileageBasedSpecials
              ? mileRangeCreateInputs
              : [],
            specialMatrixItems: matrixItems,
            tariffZoneGroupId: formValues.tariffZoneGroupId,
          },
          matchingGlobalAccessorial: templateUuid,
          contactUuid,
          percentForSettlement: formValues.percentForSettlement,
          terminalUuid:
            formValues.terminalUuid === ALL_TERMINALS
              ? null
              : formValues.terminalUuid,
          code: formValues.code,
          ediCode: formValues.ediCode,
          invoiceDisplayName: formValues.invoiceDisplayName,
          fuelProfileUuid:
            formValues.fuelProfileUuid === NO_FUEL_PROFILE
              ? null
              : formValues.fuelProfileUuid,
          isAuthoCodeRequired,
          isDefaultGlobalSpecial,
          generalLedgerCodeId: formValues.generalLedgerCodeId,
        },
      },
    },
  };
};

const makeUpdateInput = (
  formValues: SpecialAccessorialFormValues,
  tariffZonesData: Array<{ name: string; type: TariffZoneType; uuid: string }>,
  uuid: string,
  isAuthoCodeRequired: boolean,
  isDefaultGlobalSpecial: boolean,
): { variables: UpdateAccessorialMutationVariables } => {
  // TODO: Handle error if UUID is undefined (this shouldn't happen.)
  if (uuid === undefined) {
    throw new Error('Special accessorial UUID is undefined');
  }

  const chargeGroupUpdates:
    | SpecialAccessorialChargeGroupArrayUpdateInput[]
    | null = [];
  for (const chargeGroup of formValues.chargeGroups) {
    if (chargeGroup?.toBeCreated === true) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      chargeGroupUpdates.push({
        specialAccessorialChargeGroupCreateInput: {
          dayOfWeek: chargeGroup?.dayOfWeek ?? SpecialDayOfWeek.Weekday,
          startTime: chargeGroup?.startTime,
          endTime: chargeGroup?.endTime,
        },
      });
    } else if (chargeGroup?.uuid !== null) {
      chargeGroupUpdates.push({
        specialAccessorialChargeGroupUpdateInput: {
          dayOfWeek: chargeGroup?.dayOfWeek,
          startTime: chargeGroup?.startTime,
          endTime: chargeGroup?.endTime,
          uuid: chargeGroup.uuid,
        },
      });
    }
  }

  const matrixItemsArrayUpdateInput:
    | SpecialAccessorialMatrixItemArrayUpdateInput[]
    | null
    | undefined = [];

  for (const [zoneIdx, matrixItemRow] of formValues.matrixItems.entries()) {
    const tariffZoneUuid = tariffZonesData[zoneIdx]?.uuid ?? '';
    for (const [chargeGroupIdx, matrixItem] of matrixItemRow.entries()) {
      if (
        matrixItem?.toBeCreated === true &&
        tariffZonesData.length >= zoneIdx
      ) {
        const currChargeGroup = formValues?.chargeGroups[chargeGroupIdx];
        matrixItemsArrayUpdateInput.push({
          matrixItemCreateInput: {
            chargeGroupDayOfWeek:
              currChargeGroup?.dayOfWeek ?? SpecialDayOfWeek.Weekday,
            chargeGroupStartTime: currChargeGroup?.startTime,
            chargeGroupEndTime: currChargeGroup?.endTime,
            tariffZoneUuid,
            rate: matrixItem.rate,
          },
        });
      } else if (matrixItem.uuid !== null) {
        matrixItemsArrayUpdateInput.push({
          matrixItemUpdateInput: {
            uuid: matrixItem.uuid,
            rate: matrixItem.rate,
          },
        });
      }
    }
  }

  return {
    variables: {
      input: {
        accessorialUpdateInput: {
          specialAccessorialUpdateInput: {
            uuid,
            name: formValues.name,
            serviceUuid: formValues.serviceLevelUuid,
            // specialAccessorialType and specialMileRanges can only be set
            // when creating, users can't edit them
          },
          percentForSettlement: formValues.percentForSettlement,
          terminalUuid:
            formValues.terminalUuid === ALL_TERMINALS
              ? null
              : formValues.terminalUuid,
          code: formValues.code,
          ediCode: formValues.ediCode,
          invoiceDisplayName: formValues.invoiceDisplayName,
          fuelProfileUuid:
            formValues.fuelProfileUuid === NO_FUEL_PROFILE
              ? null
              : formValues.fuelProfileUuid,
          isAuthoCodeRequired,
          isDefaultGlobalSpecial,
          generalLedgerCodeId: formValues.generalLedgerCodeId,
        },
      },
    },
  };
};

export const validateMatrixItems = ({
  matrixItems,
  specialAccessorialType,
}: {
  matrixItems: SpecialAccessorialFormValues['matrixItems'];
  specialAccessorialType: SpecialAccessorialType | null;
}): string[] | undefined => {
  if (matrixItems.length === 0) {
    return undefined;
  }

  for (const matrixItemRow of matrixItems) {
    for (const matrixItem of matrixItemRow) {
      if (isNil(matrixItem.rate) || Number.isNaN(matrixItem.rate)) {
        if (specialAccessorialType === SpecialAccessorialType.ZoneBased) {
          return ['All zone ranges must have a rate'];
        }
        return ['All mileage ranges must have a rate'];
      }
    }
  }

  return undefined;
};

export const validateMileRanges = ({
  mileRangeEnds,
  specialAccessorialType,
}: {
  mileRangeEnds: SpecialAccessorialFormValues['mileRangeEnds'];
  specialAccessorialType: SpecialAccessorialType | null;
}): string[] | undefined => {
  if (specialAccessorialType !== SpecialAccessorialType.MileageBased) {
    return undefined;
  }
  if (isEmpty(mileRangeEnds)) {
    return ['Enter at least 1 mile range'];
  }
  // All mile ranges must be non-nil except the last, which must be undefined
  const definedMileRangeEnds = mileRangeEnds.slice(0, -1);
  if (definedMileRangeEnds.some(isNil) || mileRangeEnds.at(-1) !== undefined) {
    return ['Mile ranges must not be empty'];
  }
  if (
    (definedMileRangeEnds as number[]).some(
      (mileRange, idx) =>
        (idx > 0 ? definedMileRangeEnds[idx - 1]! : 0) >= mileRange,
    )
  ) {
    return ['Mile ranges must be strictly ascending'];
  }
  return undefined;
};

const getSpecialAccessorialTypeLabel = (type: SpecialAccessorialType) => {
  switch (type) {
    case SpecialAccessorialType.ZoneBased: {
      return 'Zone-based';
    }
    case SpecialAccessorialType.MileageBased: {
      return 'Mileage-based';
    }
    default: {
      return exhaustive(type);
    }
  }
};

type SpecialAccessorialFormProps = {
  readonly mode: FormMode;
  readonly uuid: string | undefined;
  readonly contactUuid: string | undefined;
  readonly templateUuid?: string | undefined;
  readonly isAuthoCodeRequired: boolean;
  readonly isDefaultGlobalSpecial: boolean;
};

const SpecialAccessorialForm = ({
  mode,
  uuid,
  contactUuid,
  templateUuid,
  isAuthoCodeRequired,
  isDefaultGlobalSpecial,
}: SpecialAccessorialFormProps) => {
  const navigate = useNavigate();

  // These are only used for zone-based specials
  // For zone-based specials, we're fetching a list of zones that already exist
  // For mileage-based specials, the tariff zones for mile ranges are created
  // when we create the accessorial.
  const [tariffZones, { loading: loadingTariffZonesWithoutGroup }] =
    useTariffZonesLazyQuery();
  const [tariffZonesForGroup, { loading: loadingTariffZonesWithGroup }] =
    useTariffZonesForGroupLazyQuery();
  const loadingTariffZones =
    loadingTariffZonesWithoutGroup || loadingTariffZonesWithGroup;
  const [tariffZonesData, setTariffZonesData] = useState<
    SpecialAccessorialTariffZoneFragment[]
  >([]);

  const [formValues, setFormValues] = useFormValues(
    templateUuid !== undefined ? templateUuid : uuid,
    tariffZonesData,
    contactUuid !== undefined,
  );
  const [formErrors, setFormErrors] = useState<SpecialAccessorialFormErrors>(
    {},
  );
  const [showFormErrorsModal, setShowFormErrorsModal] = useState(false);

  const { terminalsEnabled, terminals, terminalsLoading } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { data: fuelProfilesData, loading: fuelProfilesLoading } =
    useFuelProfilesQuery();

  const ffEnableMileageBasedSpecials = useFeatureFlag(
    FeatureFlag.FF_ENABLE_MILEAGE_BASED_SPECIALS,
  );

  const { data: zoneGroupsData, loading: zoneGroupsLoading } =
    useTariffZoneGroupsQuery({
      variables: {
        findTariffZoneGroupsInput: {
          isArchived: false,
        },
      },
    });

  const [createAccessorialMutation] = useCreateAccessorialMutation({});
  const [updateAccessorialMutation] = useUpdateAccessorialMutation({});
  // const [removeSpecialAccessorialMutation] =
  //   useRemoveSpecialAccessorialMutation({});
  const editingDisabled = useIsEditingDisabled();
  const [serviceInputName, setServiceInputName] = useState<
    string | undefined
  >();
  const { services, getServiceName } = useServices();

  const updateMatrixItemsForNewTariffZones = useCallback(
    (newTariffZones: SpecialAccessorialTariffZoneFragment[]) => {
      setFormValues((prev) => ({
        ...prev,
        matrixItems: newTariffZones.map(() =>
          prev.chargeGroups.map(() => getNewSpecialAccessorialMatrixItem()),
        ),
      }));
    },
    [setFormValues],
  );

  // Fetch tariff zones for zone-based specials
  useEffect(() => {
    if (
      formValues.specialAccessorialType !== SpecialAccessorialType.ZoneBased &&
      formValues.specialAccessorialType !== null
    ) {
      return;
    }
    if (isNil(formValues.tariffZoneGroupId)) {
      setTariffZonesData([]);
      updateMatrixItemsForNewTariffZones([]);
    } else {
      tariffZonesForGroup({
        variables: {
          tariffZoneGroupId: formValues.tariffZoneGroupId,
        },
      }).then((res) => {
        const newTariffZonesData = res.data?.tariffZoneGroup.tariffZones ?? [];
        setTariffZonesData(newTariffZonesData);
        updateMatrixItemsForNewTariffZones(newTariffZonesData);
      });
    }
  }, [
    tariffZones,
    tariffZonesForGroup,
    updateMatrixItemsForNewTariffZones,
    contactUuid,
    formValues.tariffZoneGroupId,
    formValues.specialAccessorialType,
  ]);

  const onAddChargeGroup = () => {
    let newMatrixItems = formValues.matrixItems;
    if (formValues.matrixItems.length === 0) {
      for (let i = 0; i < tariffZonesData.length; i++) {
        newMatrixItems.push([getNewSpecialAccessorialMatrixItem()]);
      }
    } else {
      newMatrixItems = newMatrixItems.map((matrixItem) => [
        ...matrixItem,
        getNewSpecialAccessorialMatrixItem(),
      ]);
    }
    setFormValues({
      ...formValues,
      chargeGroups: [
        ...formValues.chargeGroups,
        {
          dayOfWeek: null,
          startTime: null,
          endTime: null,
          uuid: null,
          toBeCreated: true,
          isUpdated: null,
        },
      ],
      matrixItems: newMatrixItems,
    });
  };

  const onRemoveChargeGroup = (idx: number) => {
    setFormValues({
      ...formValues,
      chargeGroups: formValues.chargeGroups.filter(
        (_, chargeGroupIdx) => chargeGroupIdx !== idx,
      ),
      matrixItems: formValues.matrixItems.map((matrixItem) =>
        matrixItem.filter((_, matrixItemIdx) => matrixItemIdx !== idx),
      ),
    });
  };

  const onChargeGroupChange = (
    dayOfWeek: SpecialDayOfWeek | null,
    startTime: Date | null,
    endTime: Date | null,
    chargeGroupIdx: number,
  ) => {
    const newChargeGroups = formValues.chargeGroups ?? [];
    const currChargeGroup = newChargeGroups[chargeGroupIdx];
    if (!isNil(currChargeGroup)) {
      currChargeGroup.dayOfWeek = dayOfWeek;
      currChargeGroup.startTime = startTime;
      currChargeGroup.endTime = endTime;
      if (currChargeGroup.uuid !== null) {
        currChargeGroup.isUpdated = true;
      }
      newChargeGroups[chargeGroupIdx] = currChargeGroup;
      setFormValues({ ...formValues, chargeGroups: newChargeGroups });
    }
  };

  const onAddMileRange = () => {
    setFormValues((prev) => ({
      ...prev,
      mileRangeEnds: [...prev.mileRangeEnds, undefined],
      matrixItems: [
        ...prev.matrixItems,
        Array.from({ length: prev.chargeGroups.length }, () =>
          getNewSpecialAccessorialMatrixItem(),
        ),
      ],
    }));
  };

  const onMileRangeChange = (newMileRanges: RangeCellValues) => {
    setFormValues((prev) => ({ ...prev, mileRangeEnds: newMileRanges }));
  };

  const onDeleteMileRange = (idx: number) => {
    setFormValues((prev) => {
      const newMileRangeEnds = prev.mileRangeEnds.filter((_, i) => i !== idx);
      newMileRangeEnds[newMileRangeEnds.length - 1] = undefined;
      return {
        ...prev,
        mileRangeEnds: newMileRangeEnds,
        matrixItems: prev.matrixItems.filter((_, i) => i !== idx),
      };
    });
  };

  const onMatrixItemChange = (
    rate: number,
    zoneIdx: number,
    chargeGroupIdx: number,
  ) => {
    const newMatrixItems = formValues.matrixItems ?? [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newMatrixItems[zoneIdx][chargeGroupIdx] = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...newMatrixItems[zoneIdx][chargeGroupIdx],
      rate,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!isNil(newMatrixItems[zoneIdx][chargeGroupIdx]?.uuid)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newMatrixItems[zoneIdx][chargeGroupIdx].isUpdated = true;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    else if (newMatrixItems[zoneIdx][chargeGroupIdx] !== null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      newMatrixItems[zoneIdx][chargeGroupIdx].toBeCreated = true;
    }
    setFormValues({ ...formValues, matrixItems: newMatrixItems });
  };

  // TODO: validate that weekend/Saturday/Sunday charge groups aren't overlapping
  const validateChargeGroup = ({
    chargeGroup,
  }: {
    chargeGroup: SpecialAccessorialChargeGroup | null;
  }) => {
    if (isNil(chargeGroup)) {
      return ['Rate group invalid'];
    }
    if (
      isNil(chargeGroup.dayOfWeek) ||
      isNil(chargeGroup.startTime) ||
      isNil(chargeGroup.endTime)
    ) {
      return ['Rate group missing required fields'];
    }
    return [];
  };

  const validateAllFields = () => {
    const {
      name,
      tariffZoneGroupId,
      chargeGroups,
      mileRangeEnds,
      specialAccessorialType,
    } = formValues;
    let newFormErrors: SpecialAccessorialFormErrors = {};
    if (isNilOrEmptyString(name)) {
      newFormErrors = {
        ...newFormErrors,
        name: ['Name is required'],
      };
    }
    if (
      (specialAccessorialType === SpecialAccessorialType.ZoneBased ||
        specialAccessorialType === null) &&
      isNilOrEmptyString(tariffZoneGroupId)
    ) {
      newFormErrors = {
        ...newFormErrors,
        tariffZoneGroupId: ['Zone group is required'],
      };
    }
    if (isEmpty(chargeGroups)) {
      newFormErrors = {
        ...newFormErrors,
        chargeGroups: ['Enter at least 1 rate group'],
      };
    } else {
      for (const chargeGroup of chargeGroups) {
        const newChargeGroupErrors = validateChargeGroup({ chargeGroup });
        if (isEmpty(newChargeGroupErrors)) {
          continue;
        }
        newFormErrors = {
          ...newFormErrors,
          chargeGroups: [
            ...(newFormErrors.chargeGroups ?? []),
            ...newChargeGroupErrors,
          ],
        };
      }
    }
    const matrixItemsErrors = validateMatrixItems({
      matrixItems: formValues.matrixItems,
      specialAccessorialType: formValues.specialAccessorialType,
    });
    if (!isEmpty(matrixItemsErrors)) {
      newFormErrors = {
        ...newFormErrors,
        matrixItems: matrixItemsErrors,
      };
    }
    if (
      mode === FormMode.CREATE &&
      ffEnableMileageBasedSpecials &&
      specialAccessorialType === SpecialAccessorialType.MileageBased
    ) {
      const mileageRangeEndsErrors = validateMileRanges({
        mileRangeEnds,
        specialAccessorialType,
      });
      if (!isEmpty(mileageRangeEndsErrors)) {
        newFormErrors = {
          ...newFormErrors,
          mileRangeEnds: mileageRangeEndsErrors,
        };
      }
    }
    return newFormErrors;
  };

  const onSave = async () => {
    const newFormErrors = validateAllFields();
    if (isEmpty(newFormErrors)) {
      if (mode === FormMode.CREATE) {
        const input = makeCreateInput(
          formValues,
          tariffZonesData,
          contactUuid,
          isAuthoCodeRequired,
          isDefaultGlobalSpecial,
          ffEnableMileageBasedSpecials,
          templateUuid,
        );
        // TODO: handle the errors from this don't just assume it passes
        await createAccessorialMutation(input);
        navigate(getBackUrl(contactUuid));
      } else if (mode === FormMode.EDIT) {
        // This should never happen
        if (isNil(uuid)) {
          throw new Error('uuid is not defined but in edit mode');
        }
        const input = makeUpdateInput(
          formValues,
          tariffZonesData,
          uuid,
          isAuthoCodeRequired,
          isDefaultGlobalSpecial,
        );

        // TODO: handle the errors from this don't just assume it passes
        await updateAccessorialMutation(input);
        navigate(getBackUrl(contactUuid));
      }
    } else {
      setFormErrors(newFormErrors);
      setShowFormErrorsModal(true);
    }
  };

  const specialAccessorialTypeController: JSX.Element = (
    <Grid container spacing={2} xs={4}>
      <Grid item xs={12} sx={muiStyles.centeredRow}>
        <Typography variant="h6">Special Type</Typography>
      </Grid>
      <Grid item xs={12} sx={muiStyles.centeredRow}>
        <Select<SpecialAccessorialType | null>
          value={
            formValues.specialAccessorialType ??
            SpecialAccessorialType.ZoneBased
          }
          onChange={async (e) => {
            const newSpecialAccessorialType =
              (e.target.value as SpecialAccessorialType | null) ??
              SpecialAccessorialType.ZoneBased;
            setFormValues((prev) => ({
              ...prev,
              specialAccessorialType: newSpecialAccessorialType,
              mileRangeEnds: [],
              matrixItems: [],
              tariffZoneGroupId: null,
            }));
            setFormErrors((prev) => ({
              ...prev,
              matrixItems: [],
            }));
          }}
        >
          {values(SpecialAccessorialType).map((type) => (
            <MenuItem key={type} value={type}>
              {getSpecialAccessorialTypeLabel(type)}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  );

  const chargeGroupController: JSX.Element = (
    <Grid container spacing={2} xs={4}>
      <Grid item xs={12} sx={muiStyles.centeredRow}>
        <Typography variant="h6">Rate Groups</Typography>
      </Grid>
      {formValues.chargeGroups.map(
        (chargeGroup: SpecialAccessorialChargeGroup, idx: number) => (
          <Grid key={chargeGroup.uuid} item xs={12} sx={muiStyles.centeredRow}>
            <SpecialAccessorialChargeGroupField
              mode={mode}
              onChange={(e: SpecialAccessorialChargeGroupParams) => {
                onChargeGroupChange(e.dayOfWeek, e.startTime, e.endTime, idx);
              }}
              chargeGroupParams={{
                dayOfWeek: chargeGroup.dayOfWeek ?? null,
                startTime: chargeGroup.startTime ?? null,
                endTime: chargeGroup.endTime ?? null,
              }}
            />
            {isNil(contactUuid) && (
              <CloseIcon
                onClick={() => {
                  onRemoveChargeGroup(idx);
                }}
                sx={{ marginTop: '10px', marginLeft: '4px' }}
              />
            )}
          </Grid>
        ),
      )}
      {isNil(contactUuid) && (
        <Grid item xs={12} sx={muiStyles.centeredRow}>
          <Button
            variant="outlined"
            color="primary"
            onClick={onAddChargeGroup}
            disabled={
              !isEmpty(
                formValues.chargeGroups.filter(
                  (chargeGroup) =>
                    isNil(chargeGroup.dayOfWeek) ||
                    isNil(chargeGroup.startTime) ||
                    isNil(chargeGroup.endTime),
                ),
              )
            }
          >
            Add Rate Group
          </Button>
        </Grid>
      )}
    </Grid>
  );

  const mileRangeController: JSX.Element = (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={muiStyles.centeredRow}>
        <Typography variant="h6">Mile Ranges</Typography>
      </Grid>
      {formValues.mileRangeEnds.length > 0 && (
        <Grid item xs={12} sx={muiStyles.centeredRow}>
          <SpecialAccessorialMileRangeField
            mode={mode}
            range={formValues.mileRangeEnds}
            onChange={onMileRangeChange}
            onDeleteMileRange={onDeleteMileRange}
          />
        </Grid>
      )}
      <Grid item xs={12} sx={muiStyles.centeredRow}>
        <Button variant="outlined" color="primary" onClick={onAddMileRange}>
          Add Mile Range
        </Button>
      </Grid>
    </Grid>
  );

  const accessorialMatrixController: JSX.Element = (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={muiStyles.centeredRow}>
        <Typography variant="h6">Accessorial Matrix</Typography>
      </Grid>
      <Grid item xs={12} sx={muiStyles.centeredRow}>
        <SpecialAccessorialMatrixTableField
          mode={mode}
          specialAccessorialType={formValues.specialAccessorialType}
          chargeGroups={formValues.chargeGroups}
          mileRangeEnds={formValues.mileRangeEnds}
          tariffZones={tariffZonesData}
          loadingTariffZones={loadingTariffZones}
          matrixItems={formValues.matrixItems}
          onMatrixItemChange={onMatrixItemChange}
        />
      </Grid>
    </Grid>
  );

  const formErrorsDialog = (
    <Dialog
      onClose={() => {
        setShowFormErrorsModal(false);
      }}
      open={showFormErrorsModal}
    >
      <DialogTitle>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <ErrorIcon color="error" sx={{ mr: 1 }} />
          Errors saving this accessorial
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          {(formErrors.name?.length ?? 0) > 0 && (
            <Typography my={1}>{formErrors.name?.[0]}</Typography>
          )}
          {(formErrors.tariffZoneGroupId?.length ?? 0) > 0 && (
            <Typography my={1}>{formErrors.tariffZoneGroupId?.[0]}</Typography>
          )}
          {(formErrors.chargeGroups?.length ?? 0) > 0 && (
            <>
              <Typography variant="h6">Rate groups</Typography>
              <List>
                {formErrors.chargeGroups?.map((error) => (
                  <Typography key={error}>{error}</Typography>
                ))}
              </List>
            </>
          )}
          {ffEnableMileageBasedSpecials &&
            (formErrors.mileRangeEnds?.length ?? 0) > 0 && (
              <>
                <Typography variant="h6">Mile ranges</Typography>
                <List>
                  {formErrors.mileRangeEnds?.map((error) => (
                    <Typography key={error}>{error}</Typography>
                  ))}
                </List>
              </>
            )}
          {(formErrors.matrixItems?.length ?? 0) > 0 && (
            <>
              <Typography variant="h6">Matrix items</Typography>
              <List>
                {uniq(formErrors.matrixItems).map((error: string) => (
                  <Typography key={error}>{error}</Typography>
                ))}
              </List>
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button
          variant="contained"
          onClick={() => {
            setShowFormErrorsModal(false);
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box sx={muiStyles.pageContainer}>
      <Box sx={muiStyles.buttonRow}>
        <Button onClick={onSave} variant="contained">
          Save
        </Button>
      </Box>
      {showFormErrorsModal && formErrorsDialog}
      <Box sx={muiStyles.pageContent}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <TextField
              disabled={!isNil(contactUuid)}
              value={formValues.name}
              error={!isNil(formErrors.name)}
              type="text"
              onBlur={() => validateString(formValues.name, true)}
              onChange={(e) => {
                setFormValues({ ...formValues, name: e.target.value });
              }}
              name="name"
              label="Name"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              disabled={!isNil(contactUuid)}
              value={formValues.percentForSettlement}
              error={!isNil(formErrors.percentForSettlement)}
              type="number"
              onChange={(e) => {
                if (!Number.isNaN(e.target.value)) {
                  setFormValues({
                    ...formValues,
                    percentForSettlement: Number.parseFloat(e.target.value),
                  });
                } else {
                  setFormValues({
                    ...formValues,
                    percentForSettlement: null,
                  });
                }
              }}
              name="percentForSettlement"
              label="Settlement Rate"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl error={!isNil(formErrors?.serviceLevelUuid)} fullWidth>
              <AutocompleteFuzzy
                disabled={editingDisabled}
                value={
                  !isNil(formValues.serviceLevelUuid) &&
                  serviceInputName === undefined
                    ? {
                        label: sentenceCase(
                          getServiceName(formValues.serviceLevelUuid),
                        ),
                        value: formValues.serviceLevelUuid,
                      }
                    : { label: serviceInputName ?? '', value: undefined }
                }
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                options={(
                  services?.map((service) => ({
                    value: service.uuid,
                    label: sentenceCase(service.name),
                  })) ?? []
                ).sort((a, b) => a.label.localeCompare(b.label))}
                matchSortOptions={{ keys: ['label'] }}
                onInputChange={(e, newInputValue) => {
                  setServiceInputName(newInputValue);
                }}
                onChange={(e, option) => {
                  setFormValues({
                    ...formValues,
                    serviceLevelUuid: option?.value ?? null,
                  });
                }}
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField
                    data-testid="stop-service-level-autocomplete"
                    {...params}
                    error={!isNil(formErrors?.serviceLevelUuid)}
                    helperText={formErrors?.serviceLevelUuid ?? ''}
                    onChange={(e) => {
                      setServiceInputName(e.target.value);
                    }}
                    label="Service level"
                    size="small"
                    fullWidth
                  />
                )}
              />
            </FormControl>
          </Grid>
          {terminalsEnabled && (
            <Grid item xs={3}>
              <FormControl
                sx={{ width: '100%' }}
                error={!isNil(formErrors.terminalUuid)}
              >
                <InputLabel
                  shrink={
                    !isNil(formValues.terminalUuid) &&
                    !isEmpty(formValues.terminalUuid)
                  }
                  id="select-terminal-label"
                >
                  Terminal
                </InputLabel>
                <Select
                  labelId="select-terminal-label"
                  id="select-terminal"
                  label="Terminal"
                  value={formValues.terminalUuid}
                  onChange={(event) => {
                    if (typeof event.target.value === 'string') {
                      setFormValues({
                        ...formValues,
                        terminalUuid: event.target.value,
                      });
                    }
                  }}
                  disabled={terminalsLoading}
                  size="small"
                  fullWidth
                >
                  <MenuItem key={ALL_TERMINALS} value={ALL_TERMINALS}>
                    {ALL_TERMINALS}
                  </MenuItem>
                  {terminals?.map((terminal) => (
                    <MenuItem key={terminal.uuid} value={terminal.uuid}>
                      {`${terminal.name} (${terminal.code})`}
                    </MenuItem>
                  ))}
                </Select>
                {!isNil(formErrors.terminalUuid) && (
                  <FormHelperText error>
                    {formErrors.terminalUuid}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          {formValues.specialAccessorialType !==
            SpecialAccessorialType.MileageBased && (
            <Grid item xs={3}>
              <TextField
                label="Zone Group"
                id="select-zone-group"
                value={formValues.tariffZoneGroupId ?? ''}
                onChange={(event) => {
                  if (typeof event.target.value === 'string') {
                    setFormValues({
                      ...formValues,
                      tariffZoneGroupId: event.target.value,
                    });
                  }
                }}
                size="small"
                // Zone group cannot be changed after creation
                disabled={zoneGroupsLoading || mode === FormMode.EDIT}
                fullWidth
                select
                InputProps={
                  !(zoneGroupsLoading || mode === FormMode.EDIT)
                    ? {
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            sx={{ marginRight: '20px' }}
                          >
                            <IconButton
                              onClick={() => {
                                setFormValues({
                                  ...formValues,
                                  tariffZoneGroupId: null,
                                });
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }
                    : undefined
                }
              >
                {zoneGroupsData?.tariffZoneGroups?.map((zoneGroup) => (
                  <MenuItem key={zoneGroup.id} value={zoneGroup.id}>
                    {zoneGroup.name ?? 'Unnamed'}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          )}
          {mode === FormMode.CREATE && (
            <Grid item xs={3}>
              <FormControl
                sx={{ width: '100%' }}
                error={!isNil(formErrors.fuelProfileUuid)}
              >
                <InputLabel
                  shrink={
                    !isNil(formValues.fuelProfileUuid) &&
                    !isEmpty(formValues.fuelProfileUuid)
                  }
                  id="select-fuel-profile-label"
                >
                  Fuel Profile
                </InputLabel>
                <Select
                  labelId="select-fuel-profile-label"
                  id="select-fuel-profile"
                  label="Fuel Profile"
                  value={formValues.fuelProfileUuid}
                  onChange={(event) => {
                    if (typeof event.target.value === 'string') {
                      setFormValues({
                        ...formValues,
                        fuelProfileUuid: event.target.value,
                      });
                    }
                  }}
                  disabled={fuelProfilesLoading}
                  size="small"
                  fullWidth
                >
                  <MenuItem key={NO_FUEL_PROFILE} value={NO_FUEL_PROFILE}>
                    {NO_FUEL_PROFILE}
                  </MenuItem>
                  {fuelProfilesData?.fuelProfiles?.map((fuelProfile) => (
                    <MenuItem key={fuelProfile.uuid} value={fuelProfile.uuid}>
                      {fuelProfile.name}
                    </MenuItem>
                  ))}
                </Select>
                {!isNil(formErrors.fuelProfileUuid) && (
                  <FormHelperText error>
                    {formErrors.fuelProfileUuid}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}
          <Grid item xs={3}>
            <TextField
              disabled={!isNil(contactUuid)}
              value={formValues.code ?? ''}
              error={!isNil(formErrors.code)}
              type="text"
              onChange={(e) => {
                setFormValues({ ...formValues, code: e.target.value });
              }}
              name="code"
              label="Accessorial Code"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              disabled={!isNil(contactUuid)}
              value={formValues.ediCode ?? ''}
              error={!isNil(formErrors.ediCode)}
              type="text"
              onChange={(e) => {
                setFormValues({ ...formValues, ediCode: e.target.value });
              }}
              name="ediCode"
              label="EDI Code"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              disabled={!isNil(contactUuid)}
              value={formValues.invoiceDisplayName ?? ''}
              error={!isNil(formErrors.invoiceDisplayName)}
              type="text"
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  invoiceDisplayName: e.target.value,
                });
              }}
              name="invoiceDisplayName"
              label="Display on invoices as"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <GeneralLedgerCodeAutocomplete
              value={formValues.generalLedgerCodeId}
              formError={formErrors.generalLedgerCodeId?.join(', ') ?? null}
              setValue={(newValue: string | null) => {
                setFormValues({
                  ...formValues,
                  generalLedgerCodeId: newValue,
                });
              }}
              disabled={!isNil(contactUuid)}
              size="small"
            />
          </Grid>
          {mode === FormMode.CREATE && (
            <>
              {ffEnableMileageBasedSpecials && (
                <Grid item xs={12} sx={muiStyles.centeredRow}>
                  {specialAccessorialTypeController}
                </Grid>
              )}
              <Grid item xs={12} sx={muiStyles.centeredRow}>
                {chargeGroupController}
              </Grid>
              {ffEnableMileageBasedSpecials &&
                formValues.specialAccessorialType ===
                  SpecialAccessorialType.MileageBased && (
                  <Grid item xs={12} sx={muiStyles.centeredRow}>
                    {mileRangeController}
                  </Grid>
                )}
              <Grid item xs={12}>
                {accessorialMatrixController}
              </Grid>
            </>
          )}
          {mode === FormMode.EDIT && !isNil(uuid) && (
            <Grid item xs={12}>
              <AccessorialPrices
                accessorialUuid={uuid}
                accessorialType={AccessorialType.Special}
                specialAccessorialType={formValues.specialAccessorialType}
                contactUuid={contactUuid}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default SpecialAccessorialForm;
