import { isNil } from 'lodash';
import { FormProvider } from 'react-hook-form';
import { Navigate, useParams } from 'react-router-dom';
import { isNilOrEmptyString } from 'shared/string';
import CenteredCircularProgress from '../../../common/components/centered-circular-progress';
import { FeatureFlag } from '../../../common/feature-flags';
import { CUSTOMER_PORTAL_ORDERS_PATH } from '../constants';
import useContactsForMeAsThirdPartyUser from '../hooks/use-contacts-for-me-as-third-party-user';
import { type ThirdPartyUserCompany } from '../types';
import CustomerPortalFeatureFlagGuard from './common/customer-portal-feature-flag-guard';
import { CustomerPortalOrderFormContextProvider } from './customer-portal-order-form/contexts/customer-portal-order-form-context';
import CustomerPortalOrderForm from './customer-portal-order-form/customer-portal-order-form';
import { useViewCustomerPortalOrderForm } from './customer-portal-order-form/hooks/use-view-customer-portal-order-form';

type CustomerPortalViewPendingOrderWithOrderUuidProps = {
  readonly orderUuid: string;
};

const CustomerPortalViewPendingOrderWithOrderUuid = ({
  orderUuid,
}: CustomerPortalViewPendingOrderWithOrderUuidProps) => {
  const {
    loading: loadingForm,
    form,
    contactUuid,
  } = useViewCustomerPortalOrderForm(orderUuid);

  const {
    contacts: contactsWithOrderEntryEnabled,
    loading: thirdPartyUserLoading,
  } = useContactsForMeAsThirdPartyUser({
    filterByCustomerPortalOrderEntryEnabled: true,
  });

  if (loadingForm || thirdPartyUserLoading) {
    return <CenteredCircularProgress />;
  }

  // To avoid polluting the form schema, we derive the company from
  // the selected contact UUID.
  const company: ThirdPartyUserCompany | null =
    contactsWithOrderEntryEnabled.find((c) => c.uuid === contactUuid)
      ?.company ?? null;

  if (isNil(company) || isNil(contactUuid)) {
    return <Navigate to={CUSTOMER_PORTAL_ORDERS_PATH} />;
  }

  return (
    <FormProvider {...form}>
      <CustomerPortalOrderFormContextProvider company={company}>
        <CustomerPortalOrderForm disabled contactUuid={contactUuid} />
      </CustomerPortalOrderFormContextProvider>
    </FormProvider>
  );
};

const CustomerPortalViewPendingOrder = () => {
  const { orderUuid } = useParams();

  if (isNilOrEmptyString(orderUuid)) {
    return <Navigate to={CUSTOMER_PORTAL_ORDERS_PATH} />;
  }

  return (
    <CustomerPortalFeatureFlagGuard
      featureFlag={FeatureFlag.FF_CUSTOMER_PORTAL_ORDER_ENTRY}
      navigateTo={CUSTOMER_PORTAL_ORDERS_PATH}
    >
      <CustomerPortalViewPendingOrderWithOrderUuid orderUuid={orderUuid} />
    </CustomerPortalFeatureFlagGuard>
  );
};

export { CustomerPortalViewPendingOrder };
