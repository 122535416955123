import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { isNil, lowerCase, uniq } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { filterNotNil } from 'shared/array';
import { getPermissionsFlags } from 'shared/roles';
import useUserRoles from '../../../../../../common/react-hooks/use-user-roles';
import {
  DayOfWeekAll,
  PermissionResource,
  RepeatFrequencyUnit,
} from '../../../../../../generated/graphql';
import { getDayOfWeekAllAbbreviation } from '../../../../../management/components/recurring-order-frequency-selection';
import { type OrderFormValues } from '../../forms/types';
import { OrderFormCard } from '../order-form-card';
import { OrderFormCardTitle } from '../order-form-card-title';

const RecurringOrderFrequency = () => {
  const { control, setValue, setError } = useFormContext<OrderFormValues>();
  const { errors } = useFormState({ control });
  const { userPermissions } = useUserRoles();

  const { canWrite: canWriteOrders } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Orders,
  );
  const recurringOrderFrequency = useWatch({
    control,
    name: 'recurringOrderFrequency',
  });
  const repeatFrequencyUnit = useWatch({
    control,
    name: 'recurringOrderFrequency.repeatFrequencyUnit',
  });
  const daysOfMonth = useWatch({
    control,
    name: 'recurringOrderFrequency.daysOfMonth',
  });
  const [daysOfMonthInput, setDaysOfMonthInput] = useState<string>('');
  useEffect(() => {
    setDaysOfMonthInput(daysOfMonth?.join(', ') ?? '');
  }, [daysOfMonth]);

  const DaysOfWeekComponent = (
    <Controller
      name="recurringOrderFrequency.daysOfWeek"
      control={control}
      render={({ field: { onChange, value } }) => (
        <FormControl sx={{ width: '100%' }}>
          <FormLabel>Repeat on</FormLabel>
          <Select
            multiple
            displayEmpty
            size="small"
            value={value ?? []}
            renderValue={(selected) =>
              selected.map((dow) => getDayOfWeekAllAbbreviation(dow)).join(', ')
            }
            label=""
            onChange={(event) => {
              const v = event.target.value;
              const parsedArr = typeof v === 'string' ? v.split(', ') : v;
              onChange(
                filterNotNil(
                  parsedArr.map((dayOfWeek) =>
                    parsedArr.filter(
                      (x) => x.toUpperCase() === dayOfWeek.toUpperCase(),
                    ).length === 2
                      ? null
                      : dayOfWeek.toUpperCase(),
                  ),
                ).sort(
                  (a, b) =>
                    Object.values(DayOfWeekAll).indexOf(a as DayOfWeekAll) -
                    Object.values(DayOfWeekAll).indexOf(b as DayOfWeekAll),
                ),
              );
            }}
          >
            {Object.keys(DayOfWeekAll).map((identifier) => (
              <MenuItem key={identifier} id={identifier} value={identifier}>
                <Checkbox
                  checked={
                    isNil(value)
                      ? false
                      : value.some(
                          (day) =>
                            day.toLowerCase() === identifier.toLowerCase(),
                        )
                  }
                />
                <ListItemText primary={identifier} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  );

  const DaysOfMonthComponent = (
    <Controller
      name="recurringOrderFrequency.daysOfMonth"
      control={control}
      render={({ field: { onChange } }) => (
        <FormControl fullWidth>
          <FormLabel>Repeat on</FormLabel>
          <TextField
            sx={{ width: '100%' }}
            disabled={!canWriteOrders}
            size="small"
            placeholder="1, 15"
            value={daysOfMonthInput}
            error={!isNil(errors.recurringOrderFrequency?.daysOfMonth)}
            onChange={(e) => {
              setDaysOfMonthInput(e.target.value);
            }}
            onBlur={(e) => {
              const daysListStr = e.target.value.trim();
              if (daysListStr === '') {
                onChange([]);
                setDaysOfMonthInput('');
                return;
              }
              const daysList = daysListStr
                .split(',')
                .map((x) => Number.parseInt(x.trim(), 10));
              if (
                daysList.every((x) => !Number.isNaN(x)) &&
                daysList.every((x) => x > 0 && x < 32)
              ) {
                onChange(uniq(daysList));
                setDaysOfMonthInput(daysList.join(', '));
                setError('recurringOrderFrequency.daysOfMonth', {
                  message: '',
                });
              } else {
                setError('recurringOrderFrequency.daysOfMonth', {
                  message: 'Invalid input',
                });
              }
            }}
          />
          <FormHelperText sx={{ color: '#D32F2F' }}>
            {errors.recurringOrderFrequency?.daysOfMonth?.message?.toString() ??
              ''}
          </FormHelperText>
        </FormControl>
      )}
    />
  );

  if (isNil(recurringOrderFrequency)) {
    return null;
  }

  return (
    <OrderFormCard>
      <OrderFormCardTitle title="Recurring Order Frequency" />
      <Grid container spacing={1}>
        <Grid item xs={1}>
          <Controller
            name="recurringOrderFrequency.repeatInterval"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth>
                <FormLabel>Repeat every</FormLabel>
                <TextField
                  sx={{ width: '100%' }}
                  disabled={!canWriteOrders}
                  size="small"
                  value={value}
                  error={!isNil(errors.recurringOrderFrequency?.repeatInterval)}
                  inputProps={{ pattern: '[0-9]*' }}
                  onChange={(e) => {
                    const parsed = Number.parseInt(e.target.value, 10);
                    if (Number.isNaN(parsed)) {
                      setError('recurringOrderFrequency.repeatInterval', {
                        message: 'Enter a valid number',
                      });
                      onChange(null);
                    } else if (parsed < 1) {
                      setError('recurringOrderFrequency.repeatInterval', {
                        message: 'Must be greater than 0',
                      });
                      onChange(null);
                    } else {
                      onChange(parsed);
                    }
                  }}
                />
                <FormHelperText sx={{ color: '#D32F2F' }}>
                  {errors.recurringOrderFrequency?.repeatInterval?.message?.toString() ??
                    ''}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={1}>
          <Controller
            name="recurringOrderFrequency.repeatFrequencyUnit"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth>
                <FormLabel sx={{ visibility: 'hidden' }}>...</FormLabel>
                <Select
                  value={value ?? RepeatFrequencyUnit.Week}
                  size="small"
                  renderValue={(val) => {
                    return (
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography>{lowerCase(val)}</Typography>{' '}
                      </Stack>
                    );
                  }}
                  onChange={(e) => {
                    if (e.target.value === RepeatFrequencyUnit.Week) {
                      setDaysOfMonthInput('');
                      setValue('recurringOrderFrequency.daysOfMonth', []);
                      setValue('recurringOrderFrequency.daysOfWeek', [
                        DayOfWeekAll.Monday,
                      ]);
                    } else if (e.target.value === RepeatFrequencyUnit.Month) {
                      setValue('recurringOrderFrequency.daysOfWeek', []);
                      setValue('recurringOrderFrequency.daysOfMonth', [1]);
                    }
                    onChange(e);
                  }}
                >
                  {Object.values(RepeatFrequencyUnit).map((type) => (
                    <MenuItem key={type} value={type}>
                      {lowerCase(type)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={3}>
          {repeatFrequencyUnit === RepeatFrequencyUnit.Week &&
            DaysOfWeekComponent}
          {repeatFrequencyUnit === RepeatFrequencyUnit.Month &&
            DaysOfMonthComponent}
        </Grid>
        <Grid item xs={3}>
          <Controller
            name="recurringOrderFrequency.startDate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Stack>
                <FormControl fullWidth>
                  <FormLabel>Start Date</FormLabel>
                  <DatePicker
                    disabled={!canWriteOrders}
                    renderInput={(props) => (
                      <TextField
                        size="small"
                        {...props}
                        error={
                          !isNil(errors.recurringOrderFrequency?.startDate)
                        }
                        onBlur={(e) => {
                          const date = e.target.value;
                          const newDate = dayjs(date);
                          if (newDate.isValid()) {
                            onChange(newDate.toDate());
                          }
                        }}
                      />
                    )}
                    value={value}
                    onChange={(newDate) => {
                      onChange(newDate);
                    }}
                  />
                  <FormHelperText sx={{ color: '#D32F2F' }}>
                    {errors.recurringOrderFrequency?.startDate?.message?.toString() ??
                      ''}
                  </FormHelperText>
                </FormControl>
              </Stack>
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="recurringOrderFrequency.endDate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Stack>
                <FormControl fullWidth>
                  <FormLabel>End Date</FormLabel>
                  <Stack direction="row" alignItems="center">
                    <DatePicker
                      disabled={!canWriteOrders}
                      renderInput={(props) => (
                        <TextField
                          size="small"
                          {...props}
                          error={
                            !isNil(errors.recurringOrderFrequency?.endDate)
                          }
                          onBlur={(e) => {
                            const date = e.target.value;
                            const newDate = dayjs(date);
                            if (newDate.isValid()) {
                              onChange(newDate.toDate());
                            }
                          }}
                        />
                      )}
                      value={value}
                      onChange={(newDate) => {
                        onChange(newDate);
                      }}
                    />
                    <Checkbox
                      checked={isNil(value)}
                      onChange={(e) => {
                        e.target.checked
                          ? onChange(null)
                          : onChange(new Date());
                      }}
                    />
                    <Typography sx={{ width: '100px' }}>No end date</Typography>
                  </Stack>
                </FormControl>
              </Stack>
            )}
          />
        </Grid>
      </Grid>
    </OrderFormCard>
  );
};

export default RecurringOrderFrequency;
