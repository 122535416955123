import {
  Button,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  Typography,
  TableHead,
  TableCell,
  Table,
  TableContainer,
  TableBody,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import useMe from '../../../common/react-hooks/use-me';
import {
  type NotificationFragment,
  NotificationsDocument,
  Segment,
  useAcknowledgeNotificationMutation,
} from '../../../generated/graphql';
import { InboundMessagesTabs } from '../../orders/components/inbound-messages/inbound-messages-page';

const useStyles = () => ({
  notificationContainer: {
    padding: 2,
    borderBottom: '1px solid #F7F7F7',
  },
  buttonRow: {
    textAlign: 'end',
  },
});

type OrdersAwaitingApprovalNotificationProps = {
  readonly notifications: NotificationFragment[];
};

const OrdersAwaitingApprovalNotification = ({
  notifications,
}: OrdersAwaitingApprovalNotificationProps) => {
  const styles = useStyles();
  const { segment } = useMe();
  const navigate = useNavigate();

  const [acknowledgeNotification, { loading: acknowledgeNotificationLoading }] =
    useAcknowledgeNotificationMutation({
      refetchQueries: [NotificationsDocument],
    });

  const handleAcknowledge = async () => {
    await Promise.all(
      notifications.map(async (notification) =>
        acknowledgeNotification({ variables: { uuid: notification.uuid } }),
      ),
    );
  };

  const goToApproveOrder = (orderUuid: string) => {
    navigate(
      `/orders/inbound-messages-requests/?tab=${InboundMessagesTabs.REVIEW_ORDERS}&orderUuid=${orderUuid}`,
    );
  };

  const bolString = segment === Segment.Cartage ? 'HAWB' : 'Pro #';

  return (
    <Grid container spacing={2} sx={styles.notificationContainer}>
      <Grid item xs={12}>
        <Typography sx={{ fontWeight: 'bold' }}>
          Orders awaiting approval
        </Typography>
        <TableContainer sx={{ maxHeight: 400, overflowY: 'scroll' }}>
          <Table size="small">
            <TableHead>
              <TableCell>Name</TableCell>
              <TableCell>{bolString}</TableCell>
              <TableCell>Received At</TableCell>
              <TableCell />
            </TableHead>
            <TableBody>
              {notifications.map((notification) => (
                <TableRow key={notification.uuid}>
                  <TableCell>{notification.order?.name}</TableCell>
                  <TableCell>
                    {
                      notification.order?.standardOrderFields
                        .shipperBillOfLadingNumber
                    }
                  </TableCell>
                  <TableCell>
                    {dayjs(notification.order?.createdAt).format(
                      'MM/DD/YY hh:mm a',
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        goToApproveOrder(notification.order?.uuid ?? '');
                      }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid item xs={12} sx={styles.buttonRow}>
        <Button
          disabled={
            Boolean(acknowledgeNotificationLoading) ||
            Boolean(notifications[0]?.acknowledged)
          }
          onClick={handleAcknowledge}
        >
          Acknowledge
        </Button>
      </Grid>
    </Grid>
  );
};

export default OrdersAwaitingApprovalNotification;
