import { Divider, Stack } from '@mui/material';
import ManifestDetailsHeader from './manifest-details-header';
import ManifestDetailsBody from './manifest-details-body';
import ManifestOrdersTable from './manifest-orders-table';
import { useLineHaulManifestSuspenseQuery } from '../../../../../generated/graphql';
import { useSearchParams } from 'react-router-dom';
import { isNil } from 'lodash';
import { isNilOrEmptyString } from 'shared/string';
import { isNotNil } from 'shared/optional';

type ManifestDetailsProps = {
  readonly isRefetching: boolean;
};

const ManifestDetails = ({ isRefetching }: ManifestDetailsProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const manifestUuid = searchParams.get('manifestUuid');

  const { data, error } = useLineHaulManifestSuspenseQuery(
    isNilOrEmptyString(manifestUuid)
      ? { skip: true }
      : {
          fetchPolicy: 'cache-and-network',
          variables: { uuid: manifestUuid },
          errorPolicy: 'all',
        },
  );

  if (isNil(data?.lineHaulManifest) || isNotNil(error)) {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('manifestUuid');
    setSearchParams(newSearchParams);
    return null;
  }

  return (
    <Stack height="100%">
      <ManifestDetailsHeader
        manifest={data.lineHaulManifest}
        isRefetching={isRefetching}
      />
      <Divider />
      <ManifestDetailsBody manifest={data.lineHaulManifest} />
      <ManifestOrdersTable manifest={data.lineHaulManifest} />
    </Stack>
  );
};

export default ManifestDetails;
