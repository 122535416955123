import {
  type GridReadyEvent,
  type IServerSideGetRowsParams,
} from 'ag-grid-community';
import { type AgGridReact } from 'ag-grid-react';
import {
  useCallback,
  type Dispatch,
  type RefObject,
  type SetStateAction,
} from 'react';
import apolloClient from '../../../../apollo-client';
import {
  ClientQuotesDocument,
  type ClientQuoteFragment,
  type ClientQuotesQuery,
  type ClientQuotesQueryVariables,
  type FindQuoteFiltersInput,
} from '../../../../generated/graphql';
import { type ReadOnlyRefObject } from '../../../ag-grid/orders/types';
import { type QuotesTableState } from './quotes-table';

export const QUOTES_PER_PAGE = 20;

/**
 * @description A hook that encapsulates the grid functions for the quotes table, including data retrieval, grid event handlers, etc.
 * @param stateRef - A ref object that contains the current state of the quotes table.
 * @param setState - A function that sets the state of the quotes table.
 * @returns An object containing the data retrieval, grid event handlers, etc.
 */
export const useQuotesTableGridFunctions = ({
  gridRef,
  stateRef,
  setState,
  filtersRef,
}: {
  gridRef: RefObject<AgGridReact<ClientQuoteFragment>>;
  stateRef: ReadOnlyRefObject<QuotesTableState>;
  setState: Dispatch<SetStateAction<QuotesTableState>>;
  filtersRef: ReadOnlyRefObject<FindQuoteFiltersInput>;
}) => {
  const fetchQuotes = useCallback(
    async ({
      variables,
    }: {
      variables: ClientQuotesQueryVariables['findClientQuotesInput'];
    }) => {
      const res = await apolloClient.query<
        ClientQuotesQuery,
        ClientQuotesQueryVariables
      >({
        query: ClientQuotesDocument,
        variables: {
          findClientQuotesInput: {
            ...variables,
          },
        },
      });
      return res.data;
    },
    [],
  );

  const createServerSideDatasource = (): {
    getRows: (params: IServerSideGetRowsParams<ClientQuoteFragment>) => void;
  } => {
    return {
      getRows: (params: IServerSideGetRowsParams<ClientQuoteFragment>) => {
        const { datasourceVersionId } = stateRef.current;
        fetchQuotes({
          variables: {
            first: QUOTES_PER_PAGE,
            after: stateRef.current.currentCursor,
            filters: filtersRef.current,
            searchText: stateRef.current.searchText,
          },
        })
          .then((data) => {
            if (stateRef.current.datasourceVersionId !== datasourceVersionId) {
              params.fail();
              return;
            }

            setState((prevState) => {
              return {
                ...prevState,
                currentCursor: data?.clientQuotes?.pageInfo.endCursor,
              };
            });

            const totalCount = data?.clientQuotes?.totalCount ?? undefined;
            params.success({
              rowData: data?.clientQuotes?.edges.map((edge) => edge.node) ?? [],
              rowCount: totalCount,
            });
          })
          .catch(() => {
            params.fail();
          });
      },
    };
  };

  const onGridReady = (params: GridReadyEvent) => {
    const datasource = createServerSideDatasource();
    params.api.setServerSideDatasource(datasource);
    params.api.closeToolPanel();
  };

  const refreshGrid = useCallback(() => {
    if (gridRef?.current?.api !== undefined) {
      gridRef.current?.api.refreshServerSide({ purge: true });
    }
  }, [gridRef]);

  return {
    onGridReady,
    refreshGrid,
    createServerSideDatasource,
  };
};
