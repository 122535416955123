import { type PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { shallow } from 'zustand/shallow';
import CenteredCircularProgress from '../../../../common/components/centered-circular-progress';
import { type FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useMeAsThirdPartyUser from '../../../../common/react-hooks/use-me-as-third-party-user';
import useGlobalStore from '../../../../layouts/dashboard/global-store';

type CustomerPortalFeatureFlagGuardProps = PropsWithChildren<{
  readonly featureFlag: FeatureFlag;
  readonly navigateTo: string;
}>;

const CustomerPortalFeatureFlagGuard = ({
  featureFlag,
  navigateTo,
  children,
}: CustomerPortalFeatureFlagGuardProps) => {
  const featureFlagEnabled = useFeatureFlag(featureFlag);
  const { loading: thirdPartyUserLoading } = useMeAsThirdPartyUser();
  const [statsigLoading] = useGlobalStore(
    (state) => [state.statsigLoading],
    shallow,
  );

  // Also wait for third party user to load so we can check its feature flags
  if (statsigLoading || thirdPartyUserLoading) {
    return <CenteredCircularProgress />;
  }

  if (!featureFlagEnabled) {
    return <Navigate to={navigateTo} />;
  }

  return children;
};

export default CustomerPortalFeatureFlagGuard;
