import { Typography } from '@mui/material';
import { isNil } from 'lodash';

import React from 'react';

export const buildBooleanDifferenceBlockComponent = (
  oldValue: boolean | null | undefined,
  newValue: boolean | null | undefined,
  isBeforeChange: boolean,
  wasEntireEntityDeleted: boolean,
  wasEntireEntityAdded: boolean,
  fontSize: number,
) => {
  const oldValueString =
    oldValue === true ? 'yes' : oldValue === false ? 'no' : '-';
  const newValueString =
    newValue === true ? 'yes' : newValue === false ? 'no' : '-';
  if (isBeforeChange) {
    if (wasEntireEntityDeleted) {
      return (
        <Typography
          style={{
            textDecoration: 'line-through',
            color: 'red',
            fontSize,
            maxWidth: '150px', // percentage also works
            wordWrap: 'break-word',
          }}
        >
          {oldValueString}
        </Typography>
      );
    }
    if (wasEntireEntityAdded) {
      return (
        <Typography
          style={{
            fontSize,
          }}
        >
          None
        </Typography>
      );
    }
    if (oldValue !== newValue) {
      return (
        <Typography
          style={{
            textDecoration: 'line-through',
            color: 'red',
            fontSize,
            maxWidth: '150px', // percentage also works
            wordWrap: 'break-word',
          }}
        >
          {oldValueString}
        </Typography>
      );
    }
    return (
      <Typography
        fontSize={fontSize}
        style={{
          maxWidth: '150px', // percentage also works
          wordWrap: 'break-word',
        }}
      >
        {oldValueString}
      </Typography>
    );
  }
  if (wasEntireEntityDeleted) {
    return (
      <Typography
        style={{
          fontSize,
        }}
      >
        None
      </Typography>
    );
  }
  if (wasEntireEntityAdded) {
    return (
      <Typography
        style={{
          color: 'green',
          padding: 0,
          fontSize,
          maxWidth: '150px', // percentage also works
          wordWrap: 'break-word',
        }}
      >
        {newValueString}
      </Typography>
    );
  }
  if (oldValue !== newValue) {
    return (
      <Typography
        style={{
          color: 'green',
          padding: 0,
          fontSize,
          maxWidth: '150px', // percentage also works
          wordWrap: 'break-word',
        }}
      >
        {newValueString}
      </Typography>
    );
  }
  return (
    <Typography
      fontSize={fontSize}
      style={{
        maxWidth: '150px', // percentage also works
        wordWrap: 'break-word',
      }}
    >
      {newValueString}
    </Typography>
  );
};

export const buildStringDifferenceBlockComponent = (
  oldValue: string | null | undefined,
  newValue: string | null | undefined,
  isBeforeChange: boolean,
  wasEntireEntityDeleted: boolean,
  wasEntireEntityAdded: boolean,
  fontSize: number,
) => {
  if (isBeforeChange) {
    if (wasEntireEntityDeleted) {
      return (
        <Typography
          style={{
            textDecoration: 'line-through',
            color: 'red',
            fontSize,
            maxWidth: '150px', // percentage also works
            wordWrap: 'break-word',
          }}
        >
          {isNil(oldValue) ? 'None' : oldValue}
        </Typography>
      );
    }
    if (wasEntireEntityAdded) {
      return (
        <Typography
          style={{
            fontSize,
          }}
        >
          None
        </Typography>
      );
    }
    if (oldValue !== newValue) {
      return (
        <Typography
          style={{
            textDecoration: 'line-through',
            color: 'red',
            fontSize,
            maxWidth: '150px', // percentage also works
            wordWrap: 'break-word',
          }}
        >
          {isNil(oldValue) ? 'None' : oldValue}
        </Typography>
      );
    }
    return (
      <Typography
        fontSize={fontSize}
        style={{
          maxWidth: '150px', // percentage also works
          wordWrap: 'break-word',
        }}
      >
        {isNil(oldValue) ? 'None' : oldValue}
      </Typography>
    );
  }
  if (wasEntireEntityDeleted) {
    return (
      <Typography
        style={{
          fontSize,
        }}
      >
        None
      </Typography>
    );
  }
  if (wasEntireEntityAdded) {
    return (
      <Typography
        style={{
          color: 'green',
          padding: 0,
          fontSize,
          maxWidth: '150px', // percentage also works
          wordWrap: 'break-word',
        }}
      >
        {isNil(newValue) ? 'None' : newValue}
      </Typography>
    );
  }
  if (oldValue !== newValue) {
    return (
      <Typography
        style={{
          color: 'green',
          padding: 0,
          fontSize,
          maxWidth: '150px', // percentage also works
          wordWrap: 'break-word',
        }}
      >
        {isNil(newValue) ? 'None' : newValue}
      </Typography>
    );
  }
  return (
    <Typography
      fontSize={fontSize}
      style={{
        maxWidth: '150px', // percentage also works
        wordWrap: 'break-word',
      }}
    >
      {isNil(newValue) ? 'None' : newValue}
    </Typography>
  );
};
