import type { WatchQueryFetchPolicy } from '@apollo/client';
import { useTableFieldsQuery } from '../../generated/graphql';

export const useTableFields = ({
  fetchPolicy = 'cache-and-network',
}: { fetchPolicy?: WatchQueryFetchPolicy } = {}) => {
  const {
    data: tableFieldsData,
    loading,
    refetch: refetchTableFieldsData,
  } = useTableFieldsQuery({
    fetchPolicy,
  });

  const tableFields = tableFieldsData?.tableFields;

  return {
    loading,
    orderTableFields: tableFields?.orderTableFields,
    billingReviewOrderTableFields: tableFields?.billingReviewOrderTableFields,
    lineHaulTableFields: tableFields?.linehaulDispatchTableFields,
    dispatchTableFields: tableFields?.dispatchTableFields,
    routeCardStopTableFields: tableFields?.routeCardStopTableFields,
    routeCardStopTableColumnWidths: tableFields?.routeCardStopTableColumnWidths,
    refetchTableFieldsData,
  };
};

export type TableFields = ReturnType<typeof useTableFields>;
