import HandymanIcon from '@mui/icons-material/Handyman';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import {
  Avatar,
  AvatarGroup,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { memo, useMemo, useState } from 'react';
import DispatchableDriversAutocomplete from '../../../../common/components/dispatchable-drivers-autocomplete';
import { DispatchMultiplayerAction } from '../../../../common/multiplayer/types/dispatch';
import useMultiplayer from '../../../../common/multiplayer/use-multiplayer';
import useMe from '../../../../common/react-hooks/use-me';
import useSelectedTerminalUuid from '../../../../common/react-hooks/use-selected-terminal-uuid';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import { stringToColor } from '../../../../common/utils/colors';
import { type RouteFragment } from '../../../../generated/graphql';
import { getDriverIdentifier } from '../../utils';
import RouteHelpersModal from './modals/route-helpers-modal';

type RouteDriverFieldProps = {
  readonly route: RouteFragment;
  readonly isEditing?: boolean;
  readonly selectedDriverUuid: string | null;
  readonly setSelectedDriverUuid: (driverUuid: string | null) => void;
};
const RouteDriverField = ({
  route,
  isEditing = false,
  selectedDriverUuid,
  setSelectedDriverUuid,
}: RouteDriverFieldProps) => {
  const { selectedTerminalUuid } = useSelectedTerminalUuid();
  const { companyConfiguration } = useMe();
  const useDriverNumberForDispatch =
    companyConfiguration?.useDriverNumberForDispatch;
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });

  const [showRouteHelpersModal, setShowRouteHelpersModal] =
    useState<boolean>(false);
  const { sendDispatchUserLocationEvent } = useMultiplayer();

  const driversAndHelperNames = useMemo(
    () =>
      route.drivers
        .map((driver) =>
          getDriverIdentifier(driver, useDriverNumberForDispatch),
        )
        .concat(
          route.helpers.map((helper) =>
            getDriverIdentifier(helper, useDriverNumberForDispatch),
          ),
        )
        .join(', '),
    [route.drivers, route.helpers, useDriverNumberForDispatch],
  );

  // if any stop on the route has been tendered to an agent, we do not want to allow changing the driver
  const routeHasBeenSentToAgent = useMemo(() => {
    // first check if the route is assigned to an agent
    if (isNil(route.drivers[0]?.agentId)) {
      return false;
    }
    return route.slots.some((slot) =>
      slot.stops.some((stop) => !isNil(stop.sentToAgentAt)),
    );
  }, [route.drivers, route.slots]);

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      {(!isEditing || route.locked) && isEmpty(route.drivers) && (
        <Typography variant="caption">No driver</Typography>
      )}
      {(!isEditing || route.locked) && (
        <Typography noWrap variant="caption">
          {driversAndHelperNames}
        </Typography>
      )}
      {isEditing && !route.locked && (
        <>
          <PeopleOutlineIcon sx={{ fontSize: 17 }} color="primary" />
          <DispatchableDriversAutocomplete
            disabled={routeHasBeenSentToAgent}
            selectedDriverUuid={selectedDriverUuid}
            terminalUuid={selectedTerminalUuid ?? null}
            driverTooltipText={
              routeHasBeenSentToAgent
                ? 'This route has been tendered to an agent and cannot be edited.'
                : null
            }
            showTerminalNameForCompanyDrivers={terminalsEnabled}
            textFieldProps={{
              variant: 'standard',
              size: 'small',
              label: null,
            }}
            onChangeDriver={(newDriver) => {
              setSelectedDriverUuid(newDriver?.uuid ?? null);
            }}
            onFocus={() => {
              sendDispatchUserLocationEvent({
                action: DispatchMultiplayerAction.EDITING,
                routeUuid: route.uuid,
              });
            }}
          />
        </>
      )}

      {isEditing && (
        <AvatarGroup
          max={3}
          sx={{
            '& .MuiAvatar-root': { width: 18, height: 18, fontSize: 10 },
          }}
        >
          {route.helpers.map((helper) => (
            <Tooltip
              key={helper.uuid}
              title={`${helper.firstName ?? ''} ${helper.lastName ?? ''}`}
            >
              <Avatar
                sx={{
                  bgcolor: stringToColor(helper.uuid),
                  fontSize: '10px',
                  height: '18px',
                  width: '18px',
                }}
              >
                {useDriverNumberForDispatch === true
                  ? helper.driverReferenceNumber
                  : `${helper.firstName[0]}${helper.lastName[0]}`}
              </Avatar>
            </Tooltip>
          ))}
        </AvatarGroup>
      )}

      {isEditing && !route.locked && (
        <Tooltip title="Edit Helpers">
          <IconButton
            size="small"
            onClick={() => {
              setShowRouteHelpersModal(true);
            }}
          >
            <HandymanIcon sx={{ width: '14px', height: '14px' }} />
          </IconButton>
        </Tooltip>
      )}
      <RouteHelpersModal
        open={showRouteHelpersModal}
        setOpen={setShowRouteHelpersModal}
        route={route}
      />
    </Stack>
  );
};

export default memo(RouteDriverField);
