import { Stack, Tab, Tabs } from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import TabPanel from '../../../common/components/tab-panel/tab-panel';
import {
  type FindQuoteFiltersInput,
  QuoteSource,
  QuoteStatus,
} from '../../../generated/graphql';
import QuotesTable from '../components/quotes-table/quotes-table';

enum QuotesPageTabs {
  PendingQuotes = 'Pending Quotes',
  ApprovedQuotes = 'Approved Quotes',
  CustomerPortalLeads = 'Customer Portal Leads',
}

type QuoteTabConfig = {
  label: QuotesPageTabs;
  defaultTableFilters: FindQuoteFiltersInput;
};

const QUOTE_TABS_CONFIG = Object.freeze<[QuoteTabConfig, ...QuoteTabConfig[]]>([
  {
    label: QuotesPageTabs.PendingQuotes,
    defaultTableFilters: {
      statusFilter: {
        eq: QuoteStatus.Pending,
      },
    },
  },
  {
    label: QuotesPageTabs.ApprovedQuotes,
    defaultTableFilters: {
      statusFilter: {
        eq: QuoteStatus.Approved,
      },
    },
  },
  {
    label: QuotesPageTabs.CustomerPortalLeads,
    defaultTableFilters: {
      sourceFilter: {
        eq: QuoteSource.CustomerPortal,
      },
    },
  },
]);

const DEFAULT_TAB = QuotesPageTabs.PendingQuotes;
const DEFAULT_FILTERS: FindQuoteFiltersInput =
  QUOTE_TABS_CONFIG.find((t) => t.label === DEFAULT_TAB)?.defaultTableFilters ??
  {};

const QuotesPageNew = () => {
  const [currentTab, setCurrentTab] = useState(DEFAULT_TAB);
  const [filters, setFilters] =
    useState<Partial<FindQuoteFiltersInput>>(DEFAULT_FILTERS);

  const onChangeTab = (tab: QuotesPageTabs) => {
    setCurrentTab(tab);
    const tabConfig = QUOTE_TABS_CONFIG.find((t) => t.label === tab);
    if (!isNil(tabConfig)) {
      setFilters(tabConfig.defaultTableFilters);
    }
  };

  return (
    <Stack direction="column">
      <Tabs
        value={currentTab}
        sx={{
          paddingLeft: 2,
        }}
        onChange={(_, value: QuotesPageTabs) => {
          onChangeTab(value);
        }}
      >
        {QUOTE_TABS_CONFIG.map((tab) => (
          <Tab key={tab.label} label={tab.label} value={tab.label} />
        ))}
      </Tabs>
      {QUOTE_TABS_CONFIG.map((tab) => (
        <TabPanel
          key={tab.label}
          panelValue={tab.label}
          selectedValue={currentTab}
          sx={{
            paddingTop: 1,
            paddingLeft: 3,
            paddingRight: 3,
          }}
        >
          <QuotesTable filters={filters} setFilters={setFilters} />
        </TabPanel>
      ))}
    </Stack>
  );
};

export default QuotesPageNew;
