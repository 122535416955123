import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import { isEmpty, isNil, noop } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FormProvider,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { filterNotNil } from 'shared/array';
import { getPermissionsFlags } from 'shared/roles';
import { isNilOrEmptyString } from 'shared/string';
import { shallow } from 'zustand/shallow';
import { GappedStackCard } from '../../../../common/components/gapped-stack-card';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../common/react-hooks/use-me';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import { useWarningOnExit } from '../../../../common/react-hooks/warning-on-exit';
import {
  FreightBillingMethod,
  OrderDetailedStatus,
  PermissionResource,
} from '../../../../generated/graphql';
import useGlobalStore from '../../../../layouts/dashboard/global-store';
import useBillingReviewStore from '../../../invoices/billing-review-store';
import useOrderFormStore from '../../order-form-store';
import { type AutocompletePerson } from '../standard/components/autocomplete-person';
import ScannedOrderDocumentsViewer from '../standard/scanned-order-documents-viewer';
import Charges from './components/charges';
import { ContactStationsField } from './components/contact-station';
import Details from './components/details';
import DriverSettlement from './components/driver-settlement';
import CustomerPortalOrderActions from './components/header/customer-portal-order-actions';
import DocScanOrderActions from './components/header/doc-scan-order-actions';
import OrderActions from './components/header/order-actions';
import Logistics from './components/logistics/logistics';
import { OrderCustomerField } from './components/order-customer-field';
import { OrderFormCard } from './components/order-form-card';
import OrderFormErrorDialog, {
  OrderFormType,
} from './components/order-form-error-dialog';
import { OrderNameField } from './components/order-name-field';
import OrderOverLimitDialog from './components/order-over-limit-dialog';
import Documents from './components/overview/documents';
import Packages from './components/packages';
import { DemoPackages } from './components/packages/demo/demo-packages';
import RecurringOrderFrequency from './components/recurring-order-frequency/recurring-order-frequency';
import RightSidebar from './components/right-sidebar';
import Stops from './components/stops/stops';
import Tags from './components/tags';
import { OrderFormEditAccessProvider } from './contexts/order-form-edit-access-context';
import type { OrderViewContextValue } from './contexts/order-view-context';
import { OrderViewContextProvider } from './contexts/order-view-context';
import { type OrderFormValues } from './forms/types';
import useOrderForm from './forms/use-order-form';
import {
  getDisabledForEditAccess,
  useGetOrderFormEditAccess,
} from './forms/use-order-form-edit-access';
import useDocScan from './hooks/use-doc-scan';
import { useLoadOrderForm } from './hooks/use-load-order-form';
import { useOrderFormContact } from './hooks/use-order-form-contact';
import { useSaveOrderForm } from './hooks/use-save-order-form';
import OrderPageToolbar from './order-page-toolbar';
import { type OnSubmitParams, type TimingData } from './types';
import {
  getInboundStop,
  getOutboundStop,
  shouldShowFulfillmentWarning,
  shouldShowTariffWarning,
} from './utils';

enum OrderFormTopTab {
  // Note that this ESLint error is a false positive that's fixed in a more recent version of ESLint.

  Packages,
  Handling,
}

enum OrderFormBottomTab {
  Charges,

  DriverSettlement,
}

export type OrderPageProps = {
  readonly modalMode?: boolean;
  readonly rebillMode?: boolean;
  readonly orderUuid: string | null;
  readonly modalOnClose?: () => void | Promise<void>;
  readonly fromEdiReview?: boolean;
  readonly fromRecurringTemplatePage?: boolean;
  readonly completeRebillAfterSave?: ({
    newContactUuid,
  }: {
    newContactUuid: string;
  }) => void;
  readonly inInboundEmailReview?: boolean;
  readonly inCustomerPortalReview?: true;
  readonly draggableId?: string;
};

const OrderPageForm = ({
  modalMode,
  rebillMode,
  orderUuid,
  modalOnClose,
  fromEdiReview,
  fromRecurringTemplatePage = false,
  completeRebillAfterSave,
  inInboundEmailReview,
  inCustomerPortalReview,
  draggableId,
}: OrderPageProps) => {
  const { companyConfiguration } = useMe();
  // We use this instead of form.isSubmitSuccessful to not block navigation
  // when the order is deleted
  const [wasSubmitSuccessful, setWasSubmitSuccessful] = useState(false);
  const { control, getValues, handleSubmit, clearErrors } =
    useFormContext<OrderFormValues>();
  const { isDirty, isSubmitting } = useFormState<OrderFormValues>({ control });

  const navigate = useNavigate();
  const ffOrderFormTabs = useFeatureFlag(FeatureFlag.FF_ORDER_FORM_TABS);
  const ffUseStations = useFeatureFlag(FeatureFlag.FF_USE_STATIONS);
  const ffDemoSterlingLineHaul = useFeatureFlag(
    FeatureFlag.FF_DEMO_STERLING_LINE_HAUL,
  );
  const ffRobustSettlementEnabled = useFeatureFlag(
    FeatureFlag.FF_ROBUST_SETTLEMENT,
  );

  const [setOpenedOrderUuid, setSuccessMessage, setShowSuccessMessage] =
    useGlobalStore(
      (state) => [
        state.setCurrentOrderUuid,
        state.setSuccessMessage,
        state.setShowSuccessMessage,
      ],
      shallow,
    );

  const [isSavingDocScan, setIsSavingDocScan, setJustFinalizedCharges] =
    useOrderFormStore(
      (state) => [
        state.isSavingDocScan,
        state.setIsSavingDocScan,
        state.setJustFinalizedCharges,
      ],
      shallow,
    );

  const [setOpenedOrderDataLoading] = useBillingReviewStore(
    (state) => [state.setOpenedOrderDataLoading],
    shallow,
  );

  const [searchParams] = useSearchParams();

  const duplicate = searchParams.get('duplicate');
  const pageOrderUuid = searchParams.get('orderUuid');
  const startTimeUnix = Number(searchParams.get('startTime'));
  const isDocumentScan =
    !isNil(searchParams.get('isDocumentScan')) || Boolean(inInboundEmailReview);
  const scannedOrderResultUuid =
    useWatch({ control, name: 'scannedOrderResultUuid' }) ??
    searchParams.get('scannedOrderResultUuid');
  const enterManuallyFromDocScan =
    searchParams.get('enterManuallyFromDocScan') === 'true';

  const isEditMode =
    (!isNil(pageOrderUuid) || !isNil(orderUuid)) && isNil(duplicate);
  const orderUuidToUse = orderUuid ?? pageOrderUuid;

  const [selectedTopTab, setSelectedTopTab] = useState<OrderFormTopTab>(
    OrderFormTopTab.Packages,
  );
  const [selectedBottomTab, setSelectedBottomTab] =
    useState<OrderFormBottomTab>(OrderFormBottomTab.Charges);
  const { companyData } = useMe();
  const { userPermissions } = useUserRoles();
  const existingContactUuid = useWatch({ control, name: 'contactUuid' });

  const { canWrite: hasDeleteOrdersPermission } = getPermissionsFlags(
    userPermissions,
    PermissionResource.DeleteOrders,
  );

  const { initialOrder, initializeForm } = useLoadOrderForm();
  const orderViewContextValue = useMemo<OrderViewContextValue>(
    () => ({ order: initialOrder }),
    [initialOrder],
  );
  const { saveOrderForm, refetchOrderStatus } = useSaveOrderForm({
    isEditMode,
    modalOnClose,
    completeRebillAfterSave,
  });
  const { contactUuid } = useOrderFormContact();

  const {
    scannedOrderResult,
    loading: scannedOrderResultLoading,
    approveScannedOrderResult,
    rejectScannedOrderResult,
  } = useDocScan({
    orderUuid: orderUuidToUse,
    scannedOrderResultUuid,
    inInboundEmailReview,
  });

  const theme = useTheme();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [customErrorMessage, setCustomErrorMessage] = useState<
    string | undefined
  >();
  const confirm = useConfirm();

  const detailedStatus = useWatch({ control, name: 'detailedStatus' });
  const { getOrderFormEditAccess } = useGetOrderFormEditAccess();
  const editAccess = getOrderFormEditAccess({ detailedStatus });
  const { disabledIfInvoicePosted } = getDisabledForEditAccess({ editAccess });

  const recurringOrderFrequency = useWatch({
    control,
    name: 'recurringOrderFrequency',
  });

  const recurringTemplate =
    !isNil(recurringOrderFrequency) || fromRecurringTemplatePage;

  const successCallback = useCallback(
    async (args: OnSubmitParams): Promise<boolean> => {
      const orderValues = getValues();
      const stopValues = orderValues.stops ?? [];

      const latestOrder = await refetchOrderStatus(orderValues.uuid);

      if (
        !isNil(latestOrder) &&
        latestOrder.detailedStatusV2 === OrderDetailedStatus.InvoicePosted &&
        orderValues.detailedStatus !== OrderDetailedStatus.InvoicePosted
      ) {
        setCustomErrorMessage(
          'This order is now on a posted invoice. Refresh the page to edit the latest version.',
        );
        setShowErrorModal(true);
        return false;
      }

      const shouldShowWarning = shouldShowFulfillmentWarning({
        orderValues,
        stopValues,
      });

      const inboundStop = getInboundStop(stopValues);
      const outboundStop = getOutboundStop(stopValues);

      if (shouldShowWarning) {
        await confirm({
          title: 'Warning: Inbound date is not the same as outbound date',
          confirmationText: `Save`,
        })
          .then(async () => {
            await saveOrderForm(args);
          })
          // Handle rejection when user cancels or closes dialog.
          // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
          .catch(noop);
        return true;
      }
      if (shouldShowTariffWarning({ inboundStop, outboundStop })) {
        await confirm({
          title: 'Unable to calculate freight charges',
          description: (
            <>
              <Typography mb="10px">
                Could not find a valid tariff for:{' '}
              </Typography>
              {inboundStop?.freightCharge?.billingMethod ===
                FreightBillingMethod.Tariff &&
                isNil(inboundStop.freightCharge.tariffUuid) && (
                  <Typography fontWeight={600}>
                    Inbound stop ({inboundStop.stopType})
                  </Typography>
                )}
              {outboundStop?.freightCharge?.billingMethod ===
                FreightBillingMethod.Tariff &&
                isNil(outboundStop.freightCharge.tariffUuid) && (
                  <Typography fontWeight={600}>
                    Outbound stop ({outboundStop.stopType})
                  </Typography>
                )}
              <Typography mt="10px"> Save this order anyway?</Typography>
            </>
          ),
          confirmationText: `Save`,
        })
          .then(async () => {
            await (isDocumentScan
              ? saveOrderForm({ isDocScan: true })
              : saveOrderForm(args));
          })
          // Catch block handles rejection when user cancels or closes dialog.
          // This ensures the promise chain completes and subsequent code executes.
          // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
          .catch(noop);
        return true;
      }

      // TODO: show error if the save fails
      await saveOrderForm(args);
      setSuccessMessage('Order saved');
      setShowSuccessMessage(true);
      return true;
    },
    [
      confirm,
      getValues,
      isDocumentScan,
      saveOrderForm,
      refetchOrderStatus,
      setSuccessMessage,
      setShowSuccessMessage,
    ],
  );

  const onSubmit = useCallback(
    async (args: OnSubmitParams) => {
      clearErrors();
      let success = false;
      await handleSubmit(
        async () => {
          try {
            const endTimeUnix = Date.now();
            const durationMs = endTimeUnix - startTimeUnix;
            const timingData: TimingData = {
              startTimeUnix,
              endTimeUnix,
              durationMs,
            };
            // Ideally we'd also wait for the save mutation (called by
            // successCallback) to complete before setting wasSubmitSuccessful
            // to true, but successCallback also navigates away :(
            setWasSubmitSuccessful(true);
            success = await successCallback({ ...args, timingData });
          } catch (error: unknown) {
            // eslint-disable-next-line no-console
            console.error(`Error in successCallback: ${JSON.stringify(error)}`);

            // Type-safe error handling
            if (error instanceof Error) {
              setCustomErrorMessage(error.message);
            } else {
              setCustomErrorMessage('An unexpected error occurred');
            }
            setShowErrorModal(true);
            setWasSubmitSuccessful(false);
            success = false;
          }
        },
        async (err) => {
          setWasSubmitSuccessful(false);

          // eslint-disable-next-line no-console
          console.warn(`Error creating/saving order: ${JSON.stringify(err)}`);
          setShowErrorModal(true);
          success = false;
        },
      )();
      return success;
    },
    [clearErrors, handleSubmit, successCallback, startTimeUnix],
  );

  const onApproveDocScan = async () => {
    setIsSavingDocScan(true);
    const success = await onSubmit({
      noRedirect: true,
      isDocScan: true,
    });
    if (success) {
      await approveScannedOrderResult();
    }
    setIsSavingDocScan(false);
  };

  useEffect(() => {
    if (!isNil(companyData?.uuid)) {
      void initializeForm({
        orderUuid: orderUuidToUse,
        recurringTemplate,
        duplicate,
      });
    }
    // We just use companyData as a proxy for the company being loaded
    // (this is tech debt and should be refactored).
    // We only depend on the UUID instead of using the entire companyData object
    // to be absolutely sure that the dependency is stable.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderUuidToUse, companyData?.uuid, recurringTemplate, duplicate]);

  useWarningOnExit(
    isDirty && !wasSubmitSuccessful,
    'Are you sure you want to leave? This order has unsaved changes.',
  );

  const [autocompletePerson, setAutocompletePerson] =
    useState<AutocompletePerson | null>(null);

  const showRightSidebar =
    fromEdiReview !== true &&
    !recurringTemplate &&
    inCustomerPortalReview !== true;

  const deleteEnabled =
    isEditMode &&
    modalMode !== true &&
    !isDocumentScan &&
    fromEdiReview !== true &&
    !recurringTemplate &&
    hasDeleteOrdersPermission;

  return (
    <OrderViewContextProvider value={orderViewContextValue}>
      <OrderFormErrorDialog
        handleClose={() => {
          setShowErrorModal(false);
          setCustomErrorMessage(undefined);
        }}
        open={showErrorModal}
        additionalErrors={filterNotNil([customErrorMessage])}
        type={OrderFormType.Order}
      />
      {!isEditMode && (
        <OrderOverLimitDialog
          contactUuid={
            typeof contactUuid === 'string'
              ? (contactUuid ?? existingContactUuid)
              : existingContactUuid
          }
          modalMode={modalMode}
        />
      )}
      <OrderFormEditAccessProvider value={editAccess}>
        <Stack sx={{ maxHeight: '100%' }}>
          <OrderPageToolbar
            sx={
              fromEdiReview === true
                ? {
                    cursor: isNil(draggableId) ? undefined : 'move',
                    position: 'sticky',
                    top: '-10px',
                    zIndex: 3,
                  }
                : {
                    cursor: isNil(draggableId) ? undefined : 'move',
                  }
            }
            id={draggableId}
          >
            <Stack
              flexGrow={1}
              direction="row"
              gap={2}
              alignItems="center"
              flexWrap="wrap"
            >
              {modalMode === true
                ? fromEdiReview !== true && (
                    <IconButton
                      aria-label="close"
                      sx={{
                        color: theme.palette.grey[500],
                        zIndex: 9999,
                      }}
                      onClick={() => {
                        setOpenedOrderUuid(undefined);
                        setJustFinalizedCharges(false);
                        void modalOnClose?.();
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )
                : inInboundEmailReview !== true &&
                  inCustomerPortalReview !== true && (
                    <Button
                      variant="text"
                      onClick={() => {
                        navigate(-1);
                        setOpenedOrderDataLoading(true);
                        setOpenedOrderUuid(undefined);
                        setJustFinalizedCharges(false);
                        void modalOnClose?.();
                      }}
                    >
                      Back
                    </Button>
                  )}
              <OrderCustomerField disabled={disabledIfInvoicePosted} />
              {ffUseStations && (
                <ContactStationsField disabled={disabledIfInvoicePosted} />
              )}
              <OrderNameField
                disabled={
                  disabledIfInvoicePosted ||
                  companyConfiguration?.restrictEditingOrderName === true
                }
              />
              <Tags />
            </Stack>
            {isDocumentScan ? (
              <DocScanOrderActions
                isSavingDocScan={isSavingDocScan}
                onAccept={onApproveDocScan}
                onReject={rejectScannedOrderResult}
              />
            ) : inCustomerPortalReview ? (
              <CustomerPortalOrderActions
                orderUuid={orderUuidToUse}
                isSubmitting={isSubmitting}
                onSubmit={onSubmit}
              />
            ) : (
              <OrderActions
                isEditMode={isEditMode}
                isDuplicate={!isEmpty(duplicate)}
                deleteEnabled={deleteEnabled}
                recurringTemplate={recurringTemplate}
                setWasSubmitSuccessful={setWasSubmitSuccessful}
                rebillMode={rebillMode}
                fromEdiReview={fromEdiReview}
                onSubmit={onSubmit}
              />
            )}
          </OrderPageToolbar>
          <Stack
            sx={{
              overflow: 'hidden',
              backgroundColor: theme.palette.grey[100],
              flexDirection: 'row',
            }}
          >
            <GappedStackCard
              style={{
                overflow: 'auto',
                flexBasis: isDocumentScan || showRightSidebar ? '67%' : '100%',
                flexShrink: 0,
                borderRight:
                  isDocumentScan || showRightSidebar
                    ? `1px solid ${theme.palette.borderColor.main}`
                    : 'none',
              }}
            >
              <RecurringOrderFrequency />
              <Details
                isEditMode={isEditMode}
                setAutocompletePerson={setAutocompletePerson}
              />
              <Stops
                isQuotePage={false}
                recurringTemplate={recurringTemplate}
                showDocumentComponent={isDocumentScan}
                isEditMode={isEditMode}
                autocompletePerson={autocompletePerson}
                setAutocompletePerson={setAutocompletePerson}
              />
              {ffOrderFormTabs ? (
                <>
                  {companyConfiguration?.logisticsSectionEnabled === true ? (
                    // Display Packages and Handling tabs only if we'll be showing both tabs.
                    <>
                      <Tabs
                        value={selectedTopTab}
                        sx={{
                          px: 2,
                          backgroundColor: 'white',
                        }}
                        onChange={(_, newTab: OrderFormTopTab) => {
                          setSelectedTopTab(newTab);
                        }}
                      >
                        <Tab
                          value={OrderFormTopTab.Packages}
                          label="Packages"
                        />
                        {companyConfiguration?.logisticsSectionEnabled && (
                          <Tab
                            value={OrderFormTopTab.Handling}
                            label="Handling"
                          />
                        )}
                      </Tabs>
                      {selectedTopTab === OrderFormTopTab.Packages &&
                        (ffDemoSterlingLineHaul ? (
                          <DemoPackages showTitle={false} />
                        ) : (
                          <Packages showTitle={false} />
                        ))}
                      {selectedTopTab === OrderFormTopTab.Handling && (
                        <Logistics showTitle={false} />
                      )}
                    </>
                  ) : ffDemoSterlingLineHaul ? (
                    <DemoPackages />
                  ) : (
                    <Packages />
                  )}
                  {isEditMode && !recurringTemplate ? (
                    // Display Charges and Driver Settlement tabs only if we'll be showing both tabs.
                    <>
                      <Tabs
                        value={selectedBottomTab}
                        sx={{
                          px: 2,
                          backgroundColor: 'white',
                        }}
                        onChange={(_, newTab: OrderFormBottomTab) => {
                          setSelectedBottomTab(newTab);
                        }}
                      >
                        <Tab
                          value={OrderFormBottomTab.Charges}
                          label="Charges"
                        />
                        {companyConfiguration?.showDriverSettlementInBilling ===
                          true && (
                          <Tab
                            value={OrderFormBottomTab.DriverSettlement}
                            label="Driver Settlement"
                          />
                        )}
                      </Tabs>
                      {selectedBottomTab === OrderFormBottomTab.Charges && (
                        <Charges showTitle={false} onSubmit={onSubmit} />
                      )}
                      {selectedBottomTab ===
                        OrderFormBottomTab.DriverSettlement && (
                        <DriverSettlement showTitle={false} />
                      )}
                    </>
                  ) : (
                    <Charges onSubmit={onSubmit} />
                  )}
                </>
              ) : (
                <>
                  {companyConfiguration?.logisticsSectionEnabled === true && (
                    <Logistics />
                  )}
                  {ffDemoSterlingLineHaul ? <DemoPackages /> : <Packages />}
                  <Charges onSubmit={onSubmit} />
                  {(isEditMode || ffRobustSettlementEnabled) &&
                    !recurringTemplate &&
                    companyConfiguration?.showDriverSettlementInBilling ===
                      true && <DriverSettlement />}
                </>
              )}
              {(isDocumentScan || !showRightSidebar) && !recurringTemplate && (
                <OrderFormCard>
                  <Documents isEditMode={isEditMode} />
                </OrderFormCard>
              )}
            </GappedStackCard>
            {isDocumentScan || enterManuallyFromDocScan ? (
              <ScannedOrderDocumentsViewer
                scannedOrderResult={scannedOrderResult}
                loading={scannedOrderResultLoading}
                styling={{
                  flexGrow: 1,
                  overflowY: 'auto',
                }}
              />
            ) : (
              showRightSidebar && (
                <Box
                  sx={{
                    flexGrow: 1,
                    overflowY: 'auto',
                  }}
                >
                  <RightSidebar
                    isEditMode={isEditMode}
                    orderUuid={orderUuidToUse}
                    onSubmit={onSubmit}
                  />
                </Box>
              )
            )}
          </Stack>
        </Stack>
      </OrderFormEditAccessProvider>
    </OrderViewContextProvider>
  );
};

const OrderPage = (props: OrderPageProps) => {
  const { form } = useOrderForm();

  return (
    <FormProvider {...form}>
      <OrderPageForm {...props} />
    </FormProvider>
  );
};

export default OrderPage;
