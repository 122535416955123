import {
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import { type Control, Controller, type FieldPath } from 'react-hook-form';
import { OrganizationPageMode } from '../enums';
import { type OrganizationFormValues } from '../form/types';
import useServices from '../../../common/react-hooks/use-services';
import { isNil } from 'lodash';
import { sentenceCase } from 'change-case';

type ServiceLevelSelectProps = {
  readonly name: FieldPath<OrganizationFormValues>;
  readonly control: Control<OrganizationFormValues>;
  readonly label: string;
  readonly mode?: OrganizationPageMode;
};

const ServiceLevelSelect = ({
  name,
  control,
  label,
  mode,
}: ServiceLevelSelectProps) => {
  const { services } = useServices();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <FormControl sx={{ flex: 1 }}>
          <FormLabel>{label}</FormLabel>
          <Select
            {...field}
            disabled={mode === OrganizationPageMode.VIEW}
            value={field.value ?? 'None'}
          >
            <MenuItem value="None">None</MenuItem>
            {services?.map((service) => (
              <MenuItem key={service.uuid} value={service.uuid}>
                {sentenceCase(service.name)}
              </MenuItem>
            ))}
          </Select>
          {!isNil(error) && <FormHelperText>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};

export default ServiceLevelSelect;
