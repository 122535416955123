import { Alert, Button, Tooltip } from '@mui/material';
import { Typography } from '@mui/material';
import { Stack } from '@mui/material';
import theme from '../../../../../theme';
import {
  INVALID_QUOTE_RATING_ATTEMPT_MESSAGE,
  QUOTE_RATING_DISCLAIMER,
  QuoteFormStatus,
  STALE_QUOTE_MESSAGE,
} from '../constants';
import { exhaustive } from 'shared/switch';

type CustomerPortalQuoteStatusPanelProps = {
  readonly price: number | null;
  readonly onRate: () => void;
  readonly quoteStatus: QuoteFormStatus;
};

const quoteStatusToContent = (
  quoteStatus: QuoteFormStatus,
  price: number | null,
  onRate: () => void,
) => {
  switch (quoteStatus) {
    case QuoteFormStatus.MissingInfo:
    case QuoteFormStatus.Creating: {
      return (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Tooltip
            title={
              quoteStatus === QuoteFormStatus.MissingInfo &&
              'More information is required to calculate a quote'
            }
          >
            <span>
              <Button
                variant="contained"
                sx={{
                  minWidth: 'unset',
                }}
                disabled={quoteStatus === QuoteFormStatus.MissingInfo}
                onClick={() => {
                  onRate();
                }}
              >
                Calculate quote
              </Button>
            </span>
          </Tooltip>
        </Stack>
      );
    }
    case QuoteFormStatus.Stale:
    case QuoteFormStatus.Quoted: {
      return (
        <Stack gap={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            height={24}
          >
            <Typography fontSize={12} color={theme.palette.greyBlackAlpha[60]}>
              Quote
            </Typography>
            {quoteStatus === QuoteFormStatus.Stale && (
              <Button
                onClick={() => {
                  onRate();
                }}
              >
                Update quote
              </Button>
            )}
          </Stack>
          {quoteStatus === QuoteFormStatus.Stale && (
            <Alert severity="warning">
              <Typography fontSize={14} fontWeight={500}>
                {STALE_QUOTE_MESSAGE}
              </Typography>
            </Alert>
          )}
          <Typography
            fontSize={18}
            fontWeight={500}
            color={
              quoteStatus === QuoteFormStatus.Stale
                ? theme.palette.greyBlackAlpha[60]
                : undefined
            }
          >
            {price === null ? '-' : `$${price}`}
          </Typography>
        </Stack>
      );
    }
    case QuoteFormStatus.Invalid: {
      return (
        <Stack gap={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            height={24}
          >
            <Typography fontSize={12} color={theme.palette.greyBlackAlpha[60]}>
              Quote
            </Typography>
            <Button
              onClick={() => {
                onRate();
              }}
            >
              Recalculate
            </Button>
          </Stack>
          <Alert severity="warning">
            <Typography fontSize={14} fontWeight={500}>
              {INVALID_QUOTE_RATING_ATTEMPT_MESSAGE}
            </Typography>
          </Alert>
        </Stack>
      );
    }
    default: {
      exhaustive(quoteStatus);
    }
  }
};

const CustomerPortalQuoteStatusPanel = ({
  price,
  onRate,
  quoteStatus,
}: CustomerPortalQuoteStatusPanelProps) => {
  return (
    <Stack
      p={2}
      gap={1}
      borderBottom={1}
      borderColor={theme.palette.concreteGrey[30]}
    >
      {quoteStatusToContent(quoteStatus, price, onRate)}
      <Typography fontSize={12} color={theme.palette.concreteGrey[50]}>
        {QUOTE_RATING_DISCLAIMER}
      </Typography>
    </Stack>
  );
};

export default CustomerPortalQuoteStatusPanel;
