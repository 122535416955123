import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Stack,
  TextField,
  useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { type Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import { Controller, type SubmitHandler } from 'react-hook-form';
import { shallow } from 'zustand/shallow';
import { getStopMarkAsTestIds } from '../../../../utils';
import { INBOUND_STOP_IDX } from '../../../domains/orders/components/order-form/components/constants';
import { useUpdateAndRefetchOrder } from '../../../domains/orders/components/order-form/hooks/use-update-and-refetch-order';
import { type OnSubmitParams } from '../../../domains/orders/components/order-form/types';
import { useCompleteStopBulkMutation } from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import useMarkStopAsCompletedForm, {
  type MarkStopAsCompletedFormValues,
} from '../../form/stops/use-mark-stop-as-completed-form';
import useMe from '../../react-hooks/use-me';
import TimePickerComponent from '../time-picker-component';

const shouldShowPodSigneeField = (
  requirePODPhotoAndName: boolean | undefined,
  requirePODPhotoAndNameForPickups: boolean | undefined,
  allStopAreDeliveries: boolean,
  allStopArePartnerStops: boolean,
) => {
  if (allStopArePartnerStops) {
    return true;
  }

  return (
    requirePODPhotoAndName === true &&
    (requirePODPhotoAndNameForPickups === true || allStopAreDeliveries)
  );
};

const requirePODSigneeField = (
  requirePODPhotoAndName: boolean | undefined,
  requirePODPhotoAndNameForPickups: boolean | undefined,
  allStopAreDeliveries: boolean,
  allStopArePartnerStops: boolean,
) => {
  if (allStopArePartnerStops) {
    return false;
  }

  return (
    requirePODPhotoAndName === true &&
    (requirePODPhotoAndNameForPickups === true || allStopAreDeliveries)
  );
};

type MarkStopAsCompletedDialogProps = {
  readonly handleClose: () => void;
  readonly open: boolean;
  readonly stopUuids: string[];
  readonly routeDate?: Date;
  /** If null, we are not in form context so we don't perform the save before these updates */
  readonly onSave: ((params: OnSubmitParams) => Promise<boolean>) | null;
  readonly idx?: number;
  readonly allStopsAreDeliveries: boolean;
  readonly allStopsArePartnerStops: boolean;
};

export const MarkStopAsCompletedDialog = ({
  handleClose,
  open,
  stopUuids,
  routeDate,
  onSave,
  idx,
  allStopsAreDeliveries,
  allStopsArePartnerStops,
}: MarkStopAsCompletedDialogProps) => {
  const theme = useTheme();

  const { companyConfiguration } = useMe();
  const requirePODPhotoAndName = companyConfiguration?.requirePODPhotoAndName;
  const requirePODPhotoAndNameForPickups =
    companyConfiguration?.requirePODPhotoAndNameForPickups ??
    requirePODPhotoAndName;

  const showPodSigneeField = shouldShowPodSigneeField(
    requirePODPhotoAndName,
    requirePODPhotoAndNameForPickups,
    allStopsAreDeliveries,
    allStopsArePartnerStops,
  );

  const requirePODSignee = requirePODSigneeField(
    requirePODPhotoAndName,
    requirePODPhotoAndNameForPickups,
    allStopsAreDeliveries,
    allStopsArePartnerStops,
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useMarkStopAsCompletedForm({
    context: {
      requirePODSignee,
      defaultDate: isNil(routeDate)
        ? undefined
        : dayjs(routeDate).startOf('day').toDate(),
    },
  });

  const [completeStopBulk, { loading: completeStopBulkLoading }] =
    useCompleteStopBulkMutation();
  const { updateAndRefetchOrder } = useUpdateAndRefetchOrder();

  const [setErrorMessage, setShowErrorMessage] = useGlobalStore(
    (state) => [state.setErrorMessage, state.setShowErrorMessage],
    shallow,
  );

  const onSubmit: SubmitHandler<MarkStopAsCompletedFormValues> = async (
    data,
  ) => {
    try {
      const completeStopBulkInput = {
        completeStopInputs: stopUuids.map((stopUuid) => {
          return {
            stopUuid,
            sentFromDriverMobileApplication: false,
            proofOfDeliverySignee: data.podSigneeName,
            completedAt: data.completedDate,
          };
        }),
      };
      if (isNil(onSave)) {
        // We are not in form context so we don't perform the save before these updates
        await completeStopBulk({
          variables: {
            completeStopBulkInput,
          },
        });
      } else {
        await updateAndRefetchOrder({
          additionalUpdateFns: [
            {
              fn: completeStopBulk,
              vars: {
                completeStopBulkInput,
              },
            },
          ],
          onSubmit: onSave,
          actionString: 'completing stops',
        });
      }
    } catch {
      setErrorMessage('Error marking stop as completed');
      setShowErrorMessage(true);
    }
    handleClose();
    reset();
  };

  const {
    stopMarkCompletedDialogPodSigneeNameInputTestId,
    stopMarkCompletedDialogDateInputTestId,
    stopMarkCompletedDialogTimeInputTestId,
    stopMarkCompletedDialogMarkCompletedButtonTestId,
  } = getStopMarkAsTestIds({ stopIdx: idx ?? INBOUND_STOP_IDX });

  return (
    <Dialog
      fullWidth
      open={open}
      PaperProps={{
        sx: { maxHeight: 350, minHeight: 350, minWidth: 650, maxWidth: 650 },
      }}
      onClose={handleClose}
    >
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Mark Stop as Completed
        </Box>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ marginTop: '5px' }}>
          {showPodSigneeField && (
            <Controller
              name="podSigneeName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  size="small"
                  label="Full Signee Name"
                  inputProps={{
                    'data-testid':
                      stopMarkCompletedDialogPodSigneeNameInputTestId,
                  }}
                  value={value}
                  required={requirePODSignee}
                  error={!isNil(errors.podSigneeName)}
                  helperText={errors?.podSigneeName?.message}
                  sx={{ width: '50%' }}
                  onChange={onChange}
                />
              )}
            />
          )}
          <Controller
            name="completedDate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Stack direction="row" spacing={2}>
                <DatePicker
                  renderInput={(props) => (
                    <TextField
                      size="small"
                      {...props}
                      inputProps={{
                        // eslint-disable-next-line react/prop-types
                        ...props?.inputProps,
                        'data-testid': stopMarkCompletedDialogDateInputTestId,
                      }}
                    />
                  )}
                  value={value}
                  onChange={(newDate) => {
                    onChange(newDate);
                  }}
                />
                <TimePickerComponent
                  hideClearable
                  appointmentTime={value}
                  testId={stopMarkCompletedDialogTimeInputTestId}
                  updateAppointmentTime={(time: Dayjs | undefined | null) => {
                    if (!isNil(time)) {
                      const newTimeString = `${dayjs(value).format(
                        'MM/DD/YYYY',
                      )} ${time.format('hh:mm a')}`;
                      const newTime = dayjs(newTimeString);
                      if (newTime.isValid()) {
                        onChange(newTime.toDate());
                      }
                    }
                  }}
                />
                {!isNil(errors.completedDate) && (
                  <FormHelperText sx={{ color: '#B00020' }}>
                    {errors.completedDate.message}
                  </FormHelperText>
                )}
                {!isNil(routeDate) && typeof value !== 'object' && (
                  <Button
                    onClick={() => {
                      onChange(dayjs(routeDate).startOf('day').toDate());
                    }}
                  >
                    Use Route Date
                  </Button>
                )}
              </Stack>
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: theme.spacing(2) }}>
        <Button
          variant="outlined"
          onClick={() => {
            handleClose();
            reset();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          data-testid={stopMarkCompletedDialogMarkCompletedButtonTestId}
          disabled={completeStopBulkLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Mark stop as completed
        </Button>
      </DialogActions>
    </Dialog>
  );
};
