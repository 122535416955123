import { Box } from '@mui/material';
import { chain, isNil } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { isNotNil } from 'shared/optional';
import {
  useAddLineItemsToSettlementBillsMutation,
  useDriverSettlementBillsQuery,
  type ExistingSettlementBillInput,
  type NewSettlementBillInput,
} from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import PalletButton from '../../../pallet-ui/button/pallet-button';
import PalletModal from '../../../pallet-ui/modal/pallet-modal';
import { type SettlementBillLineItem } from '../types';
import SettlementPreviewCardV2 from './settlement-preview-card-v2';

type SendToSettlementsModalProps = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly settlementBillLineItems: SettlementBillLineItem[];
  readonly selectedSettlementBillLineItemIds: Set<string>;
  readonly refresh: () => Promise<void>;
};

const SendToSettlementsModal = ({
  open,
  onClose,
  settlementBillLineItems,
  selectedSettlementBillLineItemIds,
  refresh,
}: SendToSettlementsModalProps) => {
  const [addLineItemsToSettlementBills, { loading }] =
    useAddLineItemsToSettlementBillsMutation({
      onCompleted: async (data) => {
        if (
          data?.addLineItemsToSettlementBills.__typename ===
          'MutationErrorOutput'
        ) {
          setErrorMessage(String(data.addLineItemsToSettlementBills.message));
          setShowErrorMessage(true);
          return;
        }

        setSelectedSettlementBillUuids({});
        onClose();
        void refresh();
      },
    });

  const { setErrorMessage, setShowErrorMessage } = useGlobalStore();

  const onSendToSettlements = () => {
    const newSettlements: NewSettlementBillInput[] = [];
    const existingSettlements: ExistingSettlementBillInput[] = [];

    for (const [driverUuid, { lineItems }] of Object.entries(
      driverLineItemGroups,
    )) {
      if (isNil(selectedSettlementBillUuids[driverUuid])) {
        newSettlements.push({
          driverUuid,
          settlementBillLineItemIds: lineItems.map((item) => item.id),
        });
      } else {
        existingSettlements.push({
          driverSettlementBillUuid: selectedSettlementBillUuids[driverUuid],
          settlementBillLineItemIds: lineItems.map((item) => item.id),
        });
      }
    }

    void addLineItemsToSettlementBills({
      variables: {
        addLineItemsToSettlementBillsInput: {
          settlementBillCreateInputs: newSettlements,
          settlementBillUpdateInputs: existingSettlements,
        },
      },
    });
  };

  // Dictionary of driver UUIDs to selected settlement bill UUIDs
  // if the user decides to send line items to an existing settlement bill
  const [selectedSettlementBillUuids, setSelectedSettlementBillUuids] =
    useState<Record<string, string | null>>({});

  const setSelectedSettlementBillUuidForDriver = useCallback(
    (driverUuid: string) => {
      return (selection: string | null) => {
        setSelectedSettlementBillUuids((prev) => ({
          ...prev,
          [driverUuid]: selection,
        }));
      };
    },
    [],
  );

  // Set of unique driver UUIDs for the selected line items
  const selectedDriverUuids = useMemo(
    () =>
      settlementBillLineItems
        .filter((item) => selectedSettlementBillLineItemIds.has(item.id))
        .map((item) => item.driver?.uuid)
        // eslint-disable-next-line unicorn/no-array-callback-reference
        .filter(isNotNil),
    [settlementBillLineItems, selectedSettlementBillLineItemIds],
  );

  // Fetch existing unfinalized settlement bills for the selected drivers
  // To populate the dropdown for the user to select an existing settlement bill
  const driverSettlementBills = useDriverSettlementBillsQuery({
    variables: {
      driverUuids: selectedDriverUuids,
      isFinalized: false,
    },
    skip: selectedDriverUuids.length === 0 || !open,
  });

  const existingSettlementBillsByDriverUuid = useMemo(
    () =>
      chain(driverSettlementBills.data?.driverSettlementBills.edges)
        .map((edge) => ({
          uuid: edge.node.uuid,
          name: edge.node.name,
          driverUuid: edge.node.driver.uuid,
        }))
        .groupBy((node) => node.driverUuid)
        .value(),
    [driverSettlementBills.data?.driverSettlementBills.edges],
  );

  const driverLineItemGroups = useMemo(
    () =>
      chain(settlementBillLineItems)
        .filter((item) => selectedSettlementBillLineItemIds.has(item.id))
        .groupBy((item) => item.driver.uuid)
        .mapValues((items, _) => ({
          lineItems: items,
        }))
        .value(),
    [settlementBillLineItems, selectedSettlementBillLineItemIds],
  );

  return (
    <PalletModal
      hideCloseButton
      open={open}
      title="Review Settlements"
      pinnedElements={{
        topRight: (
          <PalletButton
            variant="contained"
            loading={loading}
            onClick={async () => {
              onSendToSettlements();
            }}
          >
            Confirm
          </PalletButton>
        ),
      }}
      onClose={onClose}
    >
      <Box style={{ height: '700px', overflow: 'auto' }}>
        {Object.entries(driverLineItemGroups).map(
          ([driverUuid, { lineItems }]) => (
            <SettlementPreviewCardV2
              key={driverUuid}
              settlementBillLineItems={lineItems}
              existingSettlementBills={
                existingSettlementBillsByDriverUuid[driverUuid] ?? []
              }
              selectedSettlementBillUuid={
                selectedSettlementBillUuids[driverUuid]
              }
              onSettlementBillSelect={setSelectedSettlementBillUuidForDriver(
                driverUuid,
              )}
            />
          ),
        )}
      </Box>
    </PalletModal>
  );
};

export default SendToSettlementsModal;
