import {
  Box,
  Stack,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import TrafficJamIcon from '../../icons/traffic_jam.svg?react';
import { AgentTenderedJobStatus } from './utils';
type AgentTenderedJobStatusProps = {
  readonly status: AgentTenderedJobStatus;
  readonly agentName: string | null | undefined;
  readonly children?: React.ReactNode;
};

const AgentTenderedJobStatusBanner = ({
  status,
  agentName,
  children,
}: AgentTenderedJobStatusProps) => {
  const theme = useTheme();

  const getBackgroundColor = () => {
    if (status === AgentTenderedJobStatus.COMPLETED) {
      return theme.palette.airfreightBlue[60];
    }
    if (status === AgentTenderedJobStatus.AWAITING_CONFIRMATION) {
      return theme.palette.warningYellow[10];
    }
    return theme.palette.airfreightBlue[20];
  };

  const getTextColor = () => {
    if (status === AgentTenderedJobStatus.COMPLETED) {
      return 'white';
    }
    if (status === AgentTenderedJobStatus.AWAITING_CONFIRMATION) {
      return theme.palette.warningYellow[70];
    }
    return theme.palette.airfreightBlue[60];
  };

  return (
    <TableRow
      sx={{
        backgroundColor: getBackgroundColor(),
        width: '100%',
      }}
    >
      <TableCell
        component="th"
        colSpan={100}
        sx={{
          border: 'none',
          color: getTextColor(),
          padding: 1,
          width: '100%',
          '& > div': {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
        }}
      >
        <Box>
          <Stack direction="row" alignItems="center" gap={1}>
            <TrafficJamIcon color={getTextColor()} />
            {status === AgentTenderedJobStatus.AWAITING_CONFIRMATION && (
              <Typography fontSize={14}>
                Awaiting confirmation from{' '}
                <Typography component="span" fontWeight={600}>
                  {agentName}
                </Typography>
              </Typography>
            )}
            {status === AgentTenderedJobStatus.CONFIRMED && (
              <Typography fontSize={14}>
                Awaiting fulfillment by{' '}
                <Typography component="span" fontWeight={600}>
                  {agentName}
                </Typography>
              </Typography>
            )}
            {status === AgentTenderedJobStatus.ASSIGNED_NOT_SENT && (
              <Typography
                fontSize={14}
                fontWeight={500}
                color={theme.palette.greyBlackAlpha[80]}
              >
                {agentName}
              </Typography>
            )}
            {status === AgentTenderedJobStatus.COMPLETED && (
              <Typography fontSize={14} fontWeight={500} color="white">
                {agentName}
              </Typography>
            )}
          </Stack>
          {children}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default AgentTenderedJobStatusBanner;
