import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import currency from 'currency.js';
import { SettlementTableCell } from './common';
import { type SettlementBillLineItemCostSnapshotSchema } from 'shared/settlement-bill-snapshots/settlement-bill-snapshot.schema';

type SettlementBillLineItemCostsSummaryProps = {
  readonly costs: SettlementBillLineItemCostSnapshotSchema[];
};

/**
 * A table that displays the costs of a settlement bill line item.
 * If you're coming from an unfinalized unsnapshotted bill, this requires converting the costs to the snapshot format.
 */
const SettlementBillLineItemCostsTable = ({
  costs,
}: SettlementBillLineItemCostsSummaryProps) => {
  return (
    <TableContainer>
      <Table size="small" sx={{ tableLayout: 'fixed' }}>
        <colgroup>
          <col style={{ width: '15%' }} />
          <col style={{ width: '35%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '10%' }} />
          <col style={{ width: '20%' }} />
        </colgroup>
        <TableHead>
          <TableRow>
            <SettlementTableCell>Type</SettlementTableCell>
            <SettlementTableCell>Name</SettlementTableCell>
            <SettlementTableCell>Rate</SettlementTableCell>
            <SettlementTableCell>Qty</SettlementTableCell>
            <SettlementTableCell>Fuel %</SettlementTableCell>
            <SettlementTableCell>Total</SettlementTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {costs.map((cost) => (
            // eslint-disable-next-line react/jsx-key
            <TableRow>
              <SettlementTableCell>{cost.type}</SettlementTableCell>
              <SettlementTableCell>{cost.name}</SettlementTableCell>
              <SettlementTableCell>{cost.rate}</SettlementTableCell>
              <SettlementTableCell>{cost.quantity}</SettlementTableCell>
              <SettlementTableCell>{cost.fuelSurcharge}</SettlementTableCell>
              <SettlementTableCell>
                {currency(cost.totalDollars).format()}
              </SettlementTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SettlementBillLineItemCostsTable;
