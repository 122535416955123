import { isNil } from 'lodash';
import { useCallback } from 'react';
import { useUpdateUserMutation } from '../../generated/graphql';
import useMe from './use-me';

const useSelectedTerminalUuid = () => {
  const { user } = useMe({
    fetchPolicy: 'cache-first',
  });

  const [updateUser] = useUpdateUserMutation({
    // Set fetch policy so that we also write the actual mutation response to the cache
    fetchPolicy: 'network-only',
  });

  // This is intentionally not async since we do the optimistic update, which
  // should update React state immediately.
  const setSelectedTerminalUuid = useCallback(
    (terminalUuid: string | undefined) => {
      if (isNil(user)) {
        return;
      }

      void updateUser({
        variables: {
          updateUserInput: {
            uuid: user.uuid,
            terminalUuid: terminalUuid ?? null,
          },
        },
        optimisticResponse: {
          // Optimistically update the cache so that we're not waiting for the
          // mutation and refetch to complete.
          updateUser: {
            ...user,
            terminal: isNil(terminalUuid)
              ? null
              : {
                  __typename: 'TerminalEntity',
                  uuid: terminalUuid,
                },
          },
        },
      });
    },
    [user, updateUser],
  );

  return {
    selectedTerminalUuid: user?.terminal?.uuid,
    setSelectedTerminalUuid,
  };
};

export default useSelectedTerminalUuid;
