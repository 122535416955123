import { ArrowRightAlt } from '@mui/icons-material';
import {
  Box,
  Table,
  TableBody,
  TableRow,
  Typography,
  useTheme,
  Card,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import { isNil } from 'lodash';
import { type StopDifferenceFragment } from '../../../../../../../../../generated/graphql';
import AddressDifferenceBlock from './address-difference-block';
import BooleanDifferenceBlock from './boolean-difference-block';
import { TableCellCustom, TableRowCustom } from './common-table-components';
import CustomChargeDifferenceBlock from './custom-charge-difference-block';
import StringDifferenceRow from './string-difference-row';
import StringDifferenceBlock from './string-difference-block';
import IndependentSettlementBillLineItemsDifferenceBlock from './independent-settlement-bill-line-items-difference-block';
import useFeatureFlag from '../../../../../../../../../common/react-hooks/use-feature-flag';
import { FeatureFlag } from '../../../../../../../../../common/feature-flags';

type StopDifferenceBlockProps = {
  readonly stopDifferences: StopDifferenceFragment | null;
  readonly isInbound: boolean;
};

const StopDifferenceBlock = ({
  stopDifferences,
  isInbound,
}: StopDifferenceBlockProps) => {
  const theme = useTheme();

  const stopTypeString = isInbound ? 'Inbound' : 'Outbound';

  const ffRobustSettlement = useFeatureFlag(FeatureFlag.FF_ROBUST_SETTLEMENT);

  if (isNil(stopDifferences)) return null;
  const { wasAdded, wasDeleted } = stopDifferences;
  return (
    <Card variant="outlined">
      <Box sx={{ backgroundColor: theme.palette.grey[100], padding: 1 }}>
        {stopDifferences.wasAdded && (
          <Typography>{`${stopTypeString} stop added`}</Typography>
        )}
        {stopDifferences.wasDeleted && (
          <Typography>{`${stopTypeString} stop removed`}</Typography>
        )}
        {!stopDifferences.wasDeleted && !stopDifferences.wasAdded && (
          <Typography>{`${stopTypeString} stop changed`}</Typography>
        )}
      </Box>
      <Table size="small" style={{ border: 'none' }}>
        <colgroup>
          <col width="25%" />
          <col width="36%" />
          <col width="3%" />
          <col width="36%" />
        </colgroup>

        <TableBody sx={{ padding: 1 }}>
          {/* STOP TYPE */}
          {!isNil(stopDifferences.stopType) && (
            <StringDifferenceRow
              oldValue={
                sentenceCase(stopDifferences.stopType.oldValue ?? '') ??
                undefined
              }
              newValue={
                sentenceCase(stopDifferences.stopType.newValue ?? '') ??
                undefined
              }
              fieldName="Stop type"
              wasEntireEntityDeleted={wasDeleted}
              wasEntireEntityAdded={wasAdded}
            />
          )}
          {!isNil(stopDifferences.address) && (
            <TableRowCustom>
              <TableCellCustom style={{ border: 'none' }}>
                Address
              </TableCellCustom>
              <TableCellCustom>
                <AddressDifferenceBlock
                  isOldAddress
                  addressDifference={stopDifferences.address}
                  stopRemoved={wasDeleted}
                  stopAdded={wasAdded}
                />
              </TableCellCustom>
              <TableCellCustom>
                <ArrowRightAlt />
              </TableCellCustom>
              <TableCellCustom>
                <AddressDifferenceBlock
                  addressDifference={stopDifferences.address}
                  isOldAddress={false}
                  stopRemoved={wasDeleted}
                  stopAdded={wasAdded}
                />
              </TableCellCustom>
            </TableRowCustom>
          )}
          {!isNil(stopDifferences.terminal) && (
            <TableRowCustom>
              <TableCellCustom>Terminal</TableCellCustom>
              <TableCellCustom>
                <StringDifferenceBlock
                  isOldValueBeingDisplayed
                  oldValue={stopDifferences.terminal.oldValue}
                  newValue={stopDifferences.terminal.newValue}
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
              <TableCellCustom>
                <ArrowRightAlt />
              </TableCellCustom>
              <TableCellCustom>
                <StringDifferenceBlock
                  oldValue={stopDifferences.terminal.oldValue}
                  newValue={stopDifferences.terminal.newValue}
                  isOldValueBeingDisplayed={false}
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
            </TableRowCustom>
          )}
          {!isNil(stopDifferences.serviceDate) && (
            <TableRowCustom>
              <TableCellCustom>Service date</TableCellCustom>
              <TableCellCustom>
                <StringDifferenceBlock
                  isOldValueBeingDisplayed
                  oldValue={stopDifferences.serviceDate.oldValue}
                  newValue={stopDifferences.serviceDate.newValue}
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
              <TableCellCustom>
                <ArrowRightAlt />
              </TableCellCustom>
              <TableCellCustom>
                <StringDifferenceBlock
                  oldValue={stopDifferences.serviceDate.oldValue}
                  newValue={stopDifferences.serviceDate.newValue}
                  isOldValueBeingDisplayed={false}
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
            </TableRowCustom>
          )}
          {!isNil(stopDifferences.isSpecial) && (
            <TableRowCustom>
              <TableCellCustom>Special</TableCellCustom>
              <TableCellCustom>
                <BooleanDifferenceBlock
                  isOldValueBeingDisplayed
                  oldValue={stopDifferences.isSpecial.oldValue}
                  newValue={stopDifferences.isSpecial.newValue}
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
              <TableCellCustom>
                <ArrowRightAlt />
              </TableCellCustom>
              <TableCellCustom>
                <BooleanDifferenceBlock
                  oldValue={stopDifferences.isSpecial.oldValue}
                  newValue={stopDifferences.isSpecial.newValue}
                  isOldValueBeingDisplayed={false}
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
            </TableRowCustom>
          )}
          {!isNil(stopDifferences.appointmentRequired) && (
            <TableRowCustom>
              <TableCellCustom>Appointment required</TableCellCustom>
              <TableCellCustom>
                <BooleanDifferenceBlock
                  isOldValueBeingDisplayed
                  oldValue={stopDifferences.appointmentRequired.oldValue}
                  newValue={stopDifferences.appointmentRequired.newValue}
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
              <TableCellCustom>
                <ArrowRightAlt />
              </TableCellCustom>
              <TableCellCustom>
                <BooleanDifferenceBlock
                  oldValue={stopDifferences.appointmentRequired.oldValue}
                  newValue={stopDifferences.appointmentRequired.newValue}
                  isOldValueBeingDisplayed={false}
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
            </TableRowCustom>
          )}
          {!isNil(stopDifferences.appointmentManuallyConfirmed) && (
            <TableRowCustom>
              <TableCellCustom>Appointment confirmed</TableCellCustom>
              <TableCellCustom>
                <BooleanDifferenceBlock
                  isOldValueBeingDisplayed
                  oldValue={
                    stopDifferences.appointmentManuallyConfirmed.oldValue
                  }
                  newValue={
                    stopDifferences.appointmentManuallyConfirmed.newValue
                  }
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
              <TableCellCustom>
                <ArrowRightAlt />
              </TableCellCustom>
              <TableCellCustom>
                <BooleanDifferenceBlock
                  oldValue={
                    stopDifferences.appointmentManuallyConfirmed.oldValue
                  }
                  newValue={
                    stopDifferences.appointmentManuallyConfirmed.newValue
                  }
                  isOldValueBeingDisplayed={false}
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
            </TableRowCustom>
          )}
          {!isNil(stopDifferences.freightCharge?.totalAmountCents) && (
            <TableRowCustom>
              <TableCellCustom>Freight charge</TableCellCustom>
              <TableCellCustom>
                <StringDifferenceBlock
                  isOldValueBeingDisplayed
                  oldValue={
                    isNil(
                      stopDifferences.freightCharge?.totalAmountCents?.oldValue,
                    )
                      ? null
                      : currency(
                          stopDifferences.freightCharge?.totalAmountCents
                            .oldValue ?? 0,
                          {
                            fromCents: true,
                          },
                        ).format()
                  }
                  newValue={
                    isNil(
                      stopDifferences.freightCharge?.totalAmountCents?.newValue,
                    )
                      ? null
                      : currency(
                          stopDifferences.freightCharge?.totalAmountCents
                            .newValue ?? 0,
                          {
                            fromCents: true,
                          },
                        ).format()
                  }
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
              <TableCellCustom>
                <ArrowRightAlt />
              </TableCellCustom>
              <TableCellCustom>
                <StringDifferenceBlock
                  oldValue={
                    isNil(
                      stopDifferences.freightCharge?.totalAmountCents?.oldValue,
                    )
                      ? null
                      : currency(
                          stopDifferences.freightCharge?.totalAmountCents
                            .oldValue ?? 0,
                          {
                            fromCents: true,
                          },
                        ).format()
                  }
                  newValue={
                    isNil(
                      stopDifferences.freightCharge?.totalAmountCents?.newValue,
                    )
                      ? null
                      : currency(
                          stopDifferences.freightCharge?.totalAmountCents
                            .newValue ?? 0,
                          {
                            fromCents: true,
                          },
                        ).format()
                  }
                  isOldValueBeingDisplayed={false}
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
            </TableRowCustom>
          )}
          {!isNil(
            stopDifferences.freightCharge?.fuelCharge?.totalAmountCents,
          ) && (
            <TableRowCustom>
              <TableCellCustom>Fuel charge</TableCellCustom>
              <TableCellCustom>
                <StringDifferenceBlock
                  isOldValueBeingDisplayed
                  oldValue={
                    isNil(
                      stopDifferences.freightCharge?.fuelCharge
                        ?.totalAmountCents?.oldValue,
                    )
                      ? null
                      : currency(
                          stopDifferences.freightCharge?.fuelCharge
                            ?.totalAmountCents?.oldValue ?? 0,
                          {
                            fromCents: true,
                          },
                        ).format()
                  }
                  newValue={
                    isNil(
                      stopDifferences.freightCharge?.fuelCharge
                        ?.totalAmountCents?.newValue,
                    )
                      ? null
                      : currency(
                          stopDifferences.freightCharge?.fuelCharge
                            ?.totalAmountCents?.newValue ?? 0,
                          {
                            fromCents: true,
                          },
                        ).format()
                  }
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
              <TableCellCustom>
                <ArrowRightAlt />
              </TableCellCustom>
              <TableCellCustom>
                <StringDifferenceBlock
                  oldValue={
                    isNil(
                      stopDifferences.freightCharge?.fuelCharge
                        ?.totalAmountCents?.oldValue,
                    )
                      ? null
                      : currency(
                          stopDifferences.freightCharge?.fuelCharge
                            ?.totalAmountCents?.oldValue ?? 0,
                          {
                            fromCents: true,
                          },
                        ).format()
                  }
                  newValue={
                    isNil(
                      stopDifferences.freightCharge?.fuelCharge
                        ?.totalAmountCents?.newValue,
                    )
                      ? null
                      : currency(
                          stopDifferences.freightCharge?.fuelCharge
                            ?.totalAmountCents?.newValue ?? 0,
                          {
                            fromCents: true,
                          },
                        ).format()
                  }
                  isOldValueBeingDisplayed={false}
                  wasEntireEntityAdded={wasAdded}
                  wasEntireEntityDeleted={wasDeleted}
                />
              </TableCellCustom>
            </TableRowCustom>
          )}
          {!isNil(stopDifferences.customCharges) && (
            <TableRow sx={{ border: 'none' }}>
              <TableCellCustom>Other charges</TableCellCustom>
              <TableCellCustom>
                <CustomChargeDifferenceBlock
                  isOldCustomCharges
                  customChargeDifferences={stopDifferences.customCharges}
                />
              </TableCellCustom>
              <TableCellCustom>
                <ArrowRightAlt />
              </TableCellCustom>
              <TableCellCustom>
                <CustomChargeDifferenceBlock
                  customChargeDifferences={stopDifferences.customCharges}
                  isOldCustomCharges={false}
                />
              </TableCellCustom>
            </TableRow>
          )}
          {ffRobustSettlement && (
            <TableRowCustom>
              <TableCellCustom colSpan={4}>
                <IndependentSettlementBillLineItemsDifferenceBlock
                  lineItems={stopDifferences.independentSettlementBillLineItems}
                />
              </TableCellCustom>
            </TableRowCustom>
          )}
        </TableBody>
      </Table>
    </Card>
  );
};

export default StopDifferenceBlock;
