import {
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import { shallow } from 'zustand/shallow';
import ClearTextFieldButton from '../../../../common/components/clear-text-field-button';
import CustomerFilterButton from '../../../../common/components/customer-filter-button';
import TerminalFilterButton from '../../../../common/components/terminal-filter-button';
import useTerminals from '../../../../common/react-hooks/use-terminals';
import useIngestedEmailsStore from './ingested-emails-store';
import { useOutlookIntegrations } from './hooks/use-outlook-integrations';

const IngestedEmailsFilters = () => {
  const [
    customerOption,
    setCustomerOption,
    terminalOption, // Filters by outlook integration terminal in buildSourceFilterPrismaWhereInput
    setTerminalOption,
    originTerminalOption,
    setOriginTerminalOption,
    destinationTerminalOption,
    setDestinationTerminalOption,
    searchText,
    setSearchText,
    ingestedEmailOrdersLoading,
  ] = useIngestedEmailsStore(
    (state) => [
      state.customerOption,
      state.setCustomerOption,
      state.terminalOption,
      state.setTerminalOption,
      state.originTerminalOption,
      state.setOriginTerminalOption,
      state.destinationTerminalOption,
      state.setDestinationTerminalOption,
      state.searchText,
      state.setSearchText,
      state.ingestedEmailOrdersLoading,
    ],
    shallow,
  );
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { terminalsWithOutlookIntegrations } = useOutlookIntegrations();

  return (
    <Stack direction="row" spacing={1}>
      <CustomerFilterButton<false>
        cacheId="CUSTOMER_FILTER"
        selectedOption={customerOption}
        handleChange={(option) => {
          setCustomerOption(option);
        }}
      />
      {terminalsEnabled && terminalsWithOutlookIntegrations.length > 1 && (
        <TerminalFilterButton
          displayCode
          options={terminalsWithOutlookIntegrations.map((terminal) => ({
            label: terminal.code ?? '',
            value: terminal.uuid,
          }))}
          selectedOption={terminalOption}
          handleChange={(option) => {
            setTerminalOption(option ?? undefined);
          }}
          prefixText="Terminal"
          includeInactiveTerminals={false}
          aria-label="Select terminal"
          cacheId="INGESTED_EMAIL_TERMINAL_FILTER"
        />
      )}
      {terminalsEnabled && (
        <TerminalFilterButton
          displayCode
          selectedOption={originTerminalOption}
          handleChange={(option) => {
            setOriginTerminalOption(option ?? undefined);
          }}
          prefixText="Orig"
          includeInactiveTerminals={false}
          cacheId="INGESTED_EMAIL_ORIGIN_TERMINAL_FILTER"
        />
      )}
      {terminalsEnabled && (
        <TerminalFilterButton
          displayCode
          selectedOption={destinationTerminalOption}
          handleChange={(option) => {
            setDestinationTerminalOption(option ?? undefined);
          }}
          prefixText="Dest"
          includeInactiveTerminals={false}
          cacheId="INGESTED_EMAIL_DESTINATION_TERMINAL_FILTER"
        />
      )}
      <TextField
        size="small"
        label="Search HAWB"
        value={searchText}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {ingestedEmailOrdersLoading ? (
                <CircularProgress size={15} />
              ) : (
                <ClearTextFieldButton
                  searchText={searchText}
                  handleClearSearchText={() => {
                    setSearchText('');
                  }}
                />
              )}
            </InputAdornment>
          ),
        }}
        sx={{ minWidth: '200px' }}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />
    </Stack>
  );
};

export default IngestedEmailsFilters;
