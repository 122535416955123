import { Stack } from '@mui/material';
import { type Dayjs } from 'dayjs';
import { type LineHaulManifestGroupsQuery } from '../../../../../generated/graphql';
import useLineHaulDispatchStore from '../../../store/line-haul-dispatch-store';
import ManifestLaneGroup from './manifest-lane-group';
import { type LineHaulManifest, type NonEmptyArray } from '../utils';

type ManifestLaneGroupsProps = {
  readonly groups: NonNullable<
    LineHaulManifestGroupsQuery['lineHaulManifestsGroups']['groups']
  >;
  readonly departDate: Dayjs;
};

const ManifestLaneGroups = ({
  groups,
  departDate,
}: ManifestLaneGroupsProps) => {
  const [selectedManifestUuids] = useLineHaulDispatchStore((state) => [
    state.selectedManifestUuids,
  ]);

  // Filter out groups with empty manifests arrays
  const nonEmptyGroups = groups.filter(
    (
      group,
    ): group is typeof group & { manifests: NonEmptyArray<LineHaulManifest> } =>
      group.manifests.length > 0,
  );

  return (
    <Stack gap={1} pb={selectedManifestUuids.length > 0 ? 12 : 2}>
      {nonEmptyGroups.map((group, index) => (
        <ManifestLaneGroup
          // eslint-disable-next-line react/no-array-index-key -- This is the only key available.
          key={index}
          manifests={group.manifests}
          departDate={departDate}
        />
      ))}
    </Stack>
  );
};

export default ManifestLaneGroups;
