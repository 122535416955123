import { useState } from 'react';
import { shallow } from 'zustand/shallow';
import useGlobalStore from '../../../../../layouts/dashboard/global-store';
import { type OnSubmitParams, type OrderUpdateFn } from '../types';

export const useUpdateAndRefetchOrder = () => {
  const [
    setSuccessMessage,
    setShowSuccessMessage,
    setErrorMessage,
    setShowErrorMessage,
  ] = useGlobalStore(
    (state) => [
      state.setSuccessMessage,
      state.setShowSuccessMessage,
      state.setErrorMessage,
      state.setShowErrorMessage,
    ],
    shallow,
  );

  const [loading, setLoading] = useState(false);

  /**
   * Saves, updates, and refetches an order by performing the standard update then any additional provided functions.
   *
   * @param {Object} params - The parameters for the function.
   * @param {OrderUpdateFn[]} params.additionalUpdateFns - Additional update functions to execute after save (e.g. mark as on hand).
   * @param {function} params.onSubmit - The function that performs validation and saves the order.
   *   It should return a Promise that resolves to `true` if the save succeeds or `false` otherwise.
   * @param {string} [params.actionString] - A custom action description for the error message, e.g., "canceling order".
   * @param {boolean} [params.showSuccess] - If true, show a success notification.
   *
   * @returns {Promise<boolean>} Returns a promise that resolves to `true` if the update and refetch are successful, or `false` otherwise.
   * @throws {Error} Throws an error if the update, additional mutation, or refetch fails.
   *
   * @example
   * // Example usage:
   * const [markOrderAsCancelled] = useMarkOrderAsCancelledMutation();
   *
   * await updateAndRefetchOrder({
   *   onSubmit: ...,
   *   additionalUpdateFns: [{ fn: markOrderAsCancelled, vars: { uuid: 'order-1234' } }],
   *   actionString: 'cancelling order',
   * });
   */
  const updateAndRefetchOrder = async ({
    additionalUpdateFns,
    onSubmit,
    actionString,
    showSuccess = false,
  }: {
    additionalUpdateFns: OrderUpdateFn[];
    onSubmit: (params: OnSubmitParams) => Promise<boolean>;
    actionString?: string;
    showSuccess?: boolean;
  }): Promise<boolean> => {
    setLoading(true);
    try {
      const saveOrderSuccess = await onSubmit({
        noRedirect: true,
        additionalUpdateFns,
      });
      if (!saveOrderSuccess) {
        setLoading(false);
        throw new Error('Error saving order');
      }
      if (showSuccess) {
        setSuccessMessage(`Success ${actionString ?? 'updating order'}`);
        setShowSuccessMessage(true);
      }
      setLoading(false);
      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error updating and refetching order:`, error);
      setErrorMessage(`Error ${actionString ?? 'updating order'}`);
      setShowErrorMessage(true);
      setLoading(false);
      return false;
    }
  };

  return { updateAndRefetchOrder, loading };
};
