import { CircularProgress, Stack } from '@mui/material';
import { useEffect } from 'react';
import {
  type OrderConsolidationMethod,
  usePreviewProratedConsolidationV2Query,
} from '../../../../../generated/graphql';
import ProratedOrderDetails from './prorated-order-details';
import { type ChargesToConsolidate } from './consolidation-options-panel';

const ConsolidatedOrderInformation = ({
  primaryOrderUuid,
  ordersToConsolidateWithUuids,
  selectedChargesToConsolidate,
  orderUuidsToRefresh,
  setDisableConsolidateButton,
  consolidationMethod,
}: {
  readonly primaryOrderUuid: string;
  readonly ordersToConsolidateWithUuids: string[];
  readonly selectedChargesToConsolidate: Record<ChargesToConsolidate, boolean>;
  readonly orderUuidsToRefresh: string[];
  readonly setDisableConsolidateButton: (disabled: boolean) => void;
  readonly consolidationMethod: OrderConsolidationMethod;
}) => {
  const {
    data: previewedConsolidationData,
    loading,
    refetch: refetchPreviewedConsolidation,
  } = usePreviewProratedConsolidationV2Query({
    variables: {
      previewProratedConsolidationV2Input: {
        primaryOrderUuid,
        ordersToConsolidateWithUuids,
        shouldProrateInbound: selectedChargesToConsolidate.INBOUND,
        shouldProrateOutbound: selectedChargesToConsolidate.OUTBOUND,
        consolidationMethod,
      },
    },
    onCompleted: ({ previewProratedConsolidationV2: previewResult }) => {
      if (previewResult.__typename === 'QueryErrorOutput') {
        setDisableConsolidateButton(true);
      } else {
        setDisableConsolidateButton(false);
      }
    },
  });

  useEffect(() => {
    refetchPreviewedConsolidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderUuidsToRefresh.length]);

  // disable the consolidate button if the preview is loading
  useEffect(() => {
    if (loading) {
      setDisableConsolidateButton(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <Stack gap={2}>
      {loading && <CircularProgress />}
      {!loading && (
        <ProratedOrderDetails
          consolidationOutput={
            previewedConsolidationData?.previewProratedConsolidationV2
          }
        />
      )}
    </Stack>
  );
};

export default ConsolidatedOrderInformation;
