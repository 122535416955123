import { StatsigProvider } from '@statsig/react-bindings';
import { isNil } from 'lodash';
import { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import useMe from '../common/react-hooks/use-me';
import useGlobalStore from '../layouts/dashboard/global-store';
import useFeatureFlagPolling from './hooks/use-feature-flag-pollilng';
import useStatsigClient, {
  type StatsigUserDetails,
} from './hooks/use-statsig-client';
import useMeAsThirdPartyUser from '../common/react-hooks/use-me-as-third-party-user';
import { getUserIdentifier } from 'shared/roles';
import { exhaustive } from 'shared/switch';

export enum UserType {
  REGULAR_USER = 'REGULAR_USER',
  THIRD_PARTY_USER = 'THIRD_PARTY_USER',
  NOT_LOGGED_IN = 'NOT_LOGGED_IN',
}

/**
 * Props for the AsyncStatsigProvider component
 */
type AsyncStatsigProviderProps = {
  /** React children to be wrapped by the provider */
  readonly children: React.ReactNode;
  /** Type of user accessing the application. Defaults to REGULAR_USER */
  readonly userType?: UserType;
};

const AsyncStatsigProvider = ({
  children,
  userType = UserType.REGULAR_USER,
}: AsyncStatsigProviderProps) => {
  const [setStatsigLoading] = useGlobalStore(
    (state) => [state.setStatsigLoading],
    shallow,
  );
  const { userUuid, email, companyUuid, phoneNumber } = useMe({
    skip: userType !== UserType.REGULAR_USER,
  });

  const { uuid: thirdPartyUserUuid, email: thirdPartyUserEmail } =
    useMeAsThirdPartyUser({ skip: userType !== UserType.THIRD_PARTY_USER });

  const userDetails: StatsigUserDetails = (() => {
    switch (userType) {
      case UserType.THIRD_PARTY_USER: {
        return {
          userUuid: thirdPartyUserUuid,
          userEmail: thirdPartyUserEmail,
          companyUuid: undefined,
        };
      }
      case UserType.REGULAR_USER: {
        return {
          userUuid,
          userEmail: getUserIdentifier({
            email,
            phoneNumber,
            uuid: userUuid ?? '',
          }),
          companyUuid,
        };
      }
      case UserType.NOT_LOGGED_IN: {
        // This is before a user has logged in, so we don't have a userUuid or email to use for feature flags
        return {
          userUuid: undefined,
          userEmail: undefined,
          companyUuid: undefined,
        };
      }
      default: {
        return exhaustive(userType);
      }
    }
  })();
  const client = useStatsigClient(userDetails);
  useFeatureFlagPolling(client, userDetails);

  useEffect(() => {
    if (isNil(client)) {
      return;
    }
    const initializeStatsig = async () => {
      setStatsigLoading(true);
      try {
        await client.initializeAsync();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error initializing statsig', error);
      } finally {
        setStatsigLoading(false);
      }
    };
    initializeStatsig();
  }, [client, setStatsigLoading]);

  if (isNil(client)) {
    return children;
  }
  return <StatsigProvider client={client}>{children}</StatsigProvider>;
};

export default AsyncStatsigProvider;
