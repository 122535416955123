import { isNil } from 'lodash';
import { isNotNil } from 'shared/optional';

export enum AgentTenderedJobStatus {
  NOT_ASSIGNED = 'NOT_ASSIGNED',
  ASSIGNED_NOT_SENT = 'ASSIGNED_NOT_SENT',
  AWAITING_CONFIRMATION = 'AWAITING_CONFIRMATION',
  CONFIRMED = 'CONFIRMED',
  COMPLETED = 'COMPLETED',
}

export const getAgentTenderedJobStatus = ({
  agentName,
  sentToAgentAt,
  confirmedByAgentAt,
  completedAt,
  ffShowAgentStatusBanner,
}: {
  agentName: string | null | undefined;
  sentToAgentAt: Date | null | undefined;
  confirmedByAgentAt: Date | null | undefined;
  completedAt: Date | null | undefined;
  ffShowAgentStatusBanner: boolean;
}): AgentTenderedJobStatus => {
  if (!ffShowAgentStatusBanner) {
    return AgentTenderedJobStatus.NOT_ASSIGNED;
  }
  if (isNil(agentName)) return AgentTenderedJobStatus.NOT_ASSIGNED;
  if (isNotNil(completedAt)) return AgentTenderedJobStatus.COMPLETED;
  if (isNil(sentToAgentAt)) return AgentTenderedJobStatus.ASSIGNED_NOT_SENT;
  if (isNil(confirmedByAgentAt))
    return AgentTenderedJobStatus.AWAITING_CONFIRMATION;
  return AgentTenderedJobStatus.CONFIRMED;
};
