import { Stack, Typography } from '@mui/material';
import useGlobalStore from '../global-store';
import { type DashboardTabs } from '../types';
import { NavLink } from 'react-router-dom';
import theme from '../../../theme';

type SubpageListRowProps = {
  readonly tab: DashboardTabs;
};

const SubpageListRow = ({ tab }: SubpageListRowProps) => {
  const setOpenedOrderUuid = useGlobalStore(
    (state) => state.setCurrentOrderUuid,
  );

  return (
    <NavLink to={tab.url}>
      {({ isActive }) => (
        <Stack
          direction="row"
          paddingLeft={3}
          height={28}
          alignItems="center"
          borderRadius={1}
          sx={{
            backgroundColor: isActive
              ? theme.palette.airfreightBlue[70]
              : 'default',
            '&:hover': {
              backgroundColor: theme.palette.airfreightBlue[60],
            },
          }}
        >
          <Typography
            variant="caption"
            fontSize="14px"
            color={theme.palette.concreteGrey[10]}
            sx={{
              cursor: 'pointer',
              opacity: isActive ? 1 : 0.8,
            }}
            onClick={() => {
              setOpenedOrderUuid(undefined);
            }}
          >
            {tab.name}
          </Typography>
        </Stack>
      )}
    </NavLink>
  );
};

export default SubpageListRow;
