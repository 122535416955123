import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import currency from 'currency.js';
import { useState } from 'react';
import {
  DriverSettlementBillByUuidV2Document,
  DriverSettlementBillsV2Document,
  type DriverSettlementPaymentEntity,
  type SettlementAdjustmentFragment,
  SettlementAdjustmentType,
  useRemoveSettlementAdjustmentMutation,
} from '../../../generated/graphql';
import CreateOrUpdateAdjustmentModal from './create-or-update-adjustment-modal';
import { shallow } from 'zustand/shallow';
import useGlobalStore from '../../../layouts/dashboard/global-store';

const SettlementBillPaymentSummary = ({
  isFinalized,
  settlementBillUuid,
  settlementAdjustments,
  paymentInformation,
}: {
  readonly isFinalized: boolean;
  readonly settlementBillUuid: string;
  readonly settlementAdjustments: SettlementAdjustmentFragment[];
  readonly paymentInformation?: DriverSettlementPaymentEntity;
}) => {
  const [setErrorMessage, setShowErrorMessage] = useGlobalStore(
    (state) => [state.setErrorMessage, state.setShowErrorMessage],
    shallow,
  );

  const theme = useTheme();
  const DEDUCTION_COLOR = theme.palette.errorRed[70];
  const ADJUSTMENT_COLOR = theme.palette.successGreen[70];

  const [isAddAdjustmentModalOpen, setIsAddAdjustmentModalOpen] =
    useState<boolean>(false);
  const [editingAdjustmentId, setEditingAdjustmentId] = useState<string | null>(
    null,
  );

  const [removeSettlementAdjustment] = useRemoveSettlementAdjustmentMutation({
    onError: () => {
      setErrorMessage('Error deleting adjustment');
      setShowErrorMessage(true);
    },
  });

  const deleteAdjustment = async (uuid: string) => {
    await removeSettlementAdjustment({
      variables: {
        uuid,
      },
      refetchQueries: [
        DriverSettlementBillsV2Document,
        DriverSettlementBillByUuidV2Document,
      ],
    });
  };

  return (
    <>
      <CreateOrUpdateAdjustmentModal
        modalState={{ state: 'create', settlementBillUuid }}
        isOpen={isAddAdjustmentModalOpen}
        onClose={() => {
          setIsAddAdjustmentModalOpen(false);
        }}
      />

      <TableContainer sx={{ overflow: 'auto' }}>
        <Table
          size="small"
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: 'none',
            },
          }}
        >
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography variant="body1" fontWeight="500">
                  Eligible pay
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1" fontWeight="500">
                  {currency(
                    paymentInformation?.eligiblePay.value ?? 0,
                  ).format()}
                </Typography>
              </TableCell>
            </TableRow>
            {settlementAdjustments?.map((adjustment) => (
              <TableRow key={adjustment.uuid}>
                <TableCell>
                  <Stack direction="row" spacing={1}>
                    <CreateOrUpdateAdjustmentModal
                      modalState={{ state: 'update', adjustment }}
                      isOpen={editingAdjustmentId === adjustment.uuid}
                      onClose={() => {
                        setEditingAdjustmentId(null);
                      }}
                    />
                    <Tooltip title={adjustment.name}>
                      <Typography
                        fontSize={14}
                        color={
                          adjustment.adjustmentType ===
                          SettlementAdjustmentType.Deduction
                            ? DEDUCTION_COLOR
                            : ADJUSTMENT_COLOR
                        }
                        sx={{
                          cursor: 'default',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: 300,
                        }}
                      >
                        {adjustment.name}
                      </Typography>
                    </Tooltip>
                    <Stack direction="row">
                      {!isFinalized && (
                        <IconButton
                          size="small"
                          disabled={isFinalized}
                          onClick={() => {
                            setEditingAdjustmentId(adjustment.uuid);
                          }}
                        >
                          <EditIcon sx={{ fontSize: '14px' }} />
                        </IconButton>
                      )}
                      {!isFinalized && (
                        <IconButton
                          size="small"
                          disabled={isFinalized}
                          onClick={async () =>
                            deleteAdjustment(adjustment.uuid)
                          }
                        >
                          <DeleteIcon sx={{ fontSize: '14px' }} />
                        </IconButton>
                      )}
                    </Stack>
                  </Stack>
                </TableCell>
                <TableCell align="right">
                  <Typography
                    fontSize={14}
                    color={
                      adjustment.adjustmentType ===
                      SettlementAdjustmentType.Deduction
                        ? DEDUCTION_COLOR
                        : ADJUSTMENT_COLOR
                    }
                  >
                    {currency(adjustment.totalDollars.value).format()}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={2}>
                {!isFinalized && (
                  <Button
                    size="small"
                    sx={{ padding: 0 }}
                    disabled={isFinalized}
                    onClick={() => {
                      setIsAddAdjustmentModalOpen(true);
                    }}
                  >
                    + Add adjustment
                  </Button>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="body1" fontWeight="500">
                  Net adjustments
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1" fontWeight="500">
                  {currency(
                    paymentInformation?.netAdjustments.value ?? 0,
                  ).format()}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography variant="body1" fontWeight="500">
                  Final pay
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body1" fontWeight="500">
                  {currency(paymentInformation?.finalPay.value ?? 0).format()}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SettlementBillPaymentSummary;
