import { isArray, isEmpty, isNil } from 'lodash';
import { isNilOrEmptyString } from 'shared/string';
import {
  type FilterConstructionFilterType,
  type FilterConstructionOperatorType,
  type FilterConstructionType,
  type FilterConstructionValueType,
  type FilterOperatorTypeV2,
  type SingleFilterConstructionType,
} from '../../../../../common/filters/types';
import {
  filterEmptyFilterConstructionTypes,
  filterToInputType,
  isGroupFilterConstructionType,
} from '../../../../../common/filters/utils';
import {
  DateFilterOptionV2,
  type DateFilterValueInput,
  FilterViewPage,
} from '../../../../../generated/graphql';
import { OrderFilterFieldV2 } from '../../../../orders/components/enums/order-filters';
import { type OrderTableFilterModel } from '../../types';

export type BaseFilterInputProps = {
  filterValueInput: FilterConstructionValueType;
  filterOperationInput: FilterConstructionOperatorType;
  filterNameInput: FilterConstructionFilterType;
  setFilter: ({ filter, op, value }: SingleFilterConstructionType) => void;
};

export const BaseFilterInputInputPropsSx = {
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  backgroundColor: 'white',
} as const;

export function filterConstructionTypeToOrderTableFilterModel(
  filterConstructionType: FilterConstructionType[],
): OrderTableFilterModel {
  return filterEmptyFilterConstructionTypes(
    filterConstructionType,
  ).reduce<OrderTableFilterModel>((acc, singleFilterConstructionType) => {
    if (isGroupFilterConstructionType(singleFilterConstructionType)) {
      const op: 'and' | 'or' = isEmpty(singleFilterConstructionType.and)
        ? 'or'
        : 'and';
      const filteredGroup =
        (singleFilterConstructionType[op] ?? []).map((f) =>
          filterConstructionTypeToOrderTableFilterModel([f]),
        ) ?? [];
      if (!isEmpty(filteredGroup)) {
        acc[op] = filteredGroup;
      }

      return acc;
    }

    const { filter, op, value } = singleFilterConstructionType;
    if (isNilOrEmptyString(filter) || isNilOrEmptyString(op)) {
      return acc;
    }

    if (isNil(acc[filter])) {
      acc[filter] = {};
    }
    const filterType = filterToInputType(filter);
    const filterTypeSupportsBlank =
      filterType === 'date' || filterType === 'text' || filterType === 'select';
    const filterTypeSupportsEmpty = filterType === 'uuidList';
    if (op === 'isBlank' && filterTypeSupportsBlank) {
      acc[filter] = { isBlank: true };
    } else if (op === 'isNotBlank' && filterTypeSupportsBlank) {
      acc[filter] = { isNotBlank: true };
    } else if (op === 'isEmpty' && filterTypeSupportsEmpty) {
      acc[filter] = { isEmpty: true };
    } else if (op === 'isNotEmpty' && filterTypeSupportsEmpty) {
      acc[filter] = { isNotEmpty: true };
    } else {
      // Ignoring, op is all possible operators and acc[filter] is one distinct type of filter not the union
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      acc[filter][op] = value;
    }

    return acc;
  }, {});
}

export function isOrderFilterFieldV2(
  field: string,
): field is keyof typeof OrderFilterFieldV2 {
  return Object.keys(OrderFilterFieldV2).includes(field as OrderFilterFieldV2);
}

export function orderTableFilterModelToFilterConstructionType(
  filterModel: OrderTableFilterModel,
): FilterConstructionType[] {
  const filters: FilterConstructionType[] = [];
  for (const [field, currentFilter] of Object.entries(filterModel)) {
    if (!isOrderFilterFieldV2(field) && field !== 'and' && field !== 'or') {
      continue;
    }
    if (isNil(currentFilter)) {
      continue;
    }
    if (field === 'and' || field === 'or') {
      if (!isArray(currentFilter)) {
        continue;
      }
      filters.push({
        [field]: currentFilter.flatMap((f) =>
          orderTableFilterModelToFilterConstructionType(f),
        ),
      });
    } else {
      const filterType = filterToInputType(field);
      for (const op of Object.keys(currentFilter)) {
        const typedOp = op as FilterOperatorTypeV2;
        const preFilteredValue =
          currentFilter[typedOp as keyof typeof currentFilter] ?? '';
        // Temporary measure to convert deprecated eq date operators to eqV2
        if (typedOp === 'eq' && filterType === 'date') {
          const filterDateObject = {
            date: preFilteredValue,
            dateFilterOption: DateFilterOptionV2.Static,
            offsetDays: 0,
          };

          filters.push({
            filter: field as FilterConstructionFilterType,
            op: 'eqV2',
            // FilterOperatorTypeV2 is all possible operators, but not all filters
            // support all possible operators, so we need to cast
            value: filterDateObject,
          });
        } else {
          filters.push({
            filter: field as FilterConstructionFilterType,
            op: typedOp,
            // FilterOperatorTypeV2 is all possible operators, but not all filters
            // support all possible operators, so we need to cast
            value: preFilteredValue,
          });
        }
      }
    }
  }
  return filters;
}

// It is very unideal to maintain this and the @see buildEmptySingleFilterConstructionType
// but it is a temporary measure to support using default filters on different pages.
// TODO: Reconcile these two functions to ensure that we are using the same logic
export const buildEmptySingleFilterConstructionTypeForDisplay = ({
  pageType,
}: {
  pageType: FilterViewPage;
}): SingleFilterConstructionType[] => {
  if (pageType === FilterViewPage.LineHaulOrders) {
    return [
      {
        filter: 'ORDER_SERVICE_DATE',
        op: 'eqV2',
        value: {
          date: null,
          dateFilterOption: DateFilterOptionV2.Static,
          offsetDays: 0,
        },
      },
      {
        filter: 'INBOUND_TERMINAL',
        op: 'eq',
        value: 'Any',
      },
      {
        filter: 'OUTBOUND_TERMINAL',
        op: 'eq',
        value: 'Any',
      },
    ];
  }

  return [
    {
      filter: 'CUSTOMER_NAME',
      op: 'eq',
      value: 'Any',
    },
  ];
};

export const buildEmptySingleFilterConstructionType = ({
  pageType,
}: {
  pageType: FilterViewPage;
}): SingleFilterConstructionType[] => {
  if (pageType === FilterViewPage.LineHaulOrders) {
    return [
      {
        filter: 'ORDER_SERVICE_DATE',
        op: 'eqV2',
        value: {
          date: null,
          dateFilterOption: DateFilterOptionV2.Static,
          offsetDays: 0,
        },
      },
      {
        filter: 'INBOUND_TERMINAL',
        op: 'eq',
        value: null,
      },
      {
        filter: 'OUTBOUND_TERMINAL',
        op: 'eq',
        value: null,
      },
    ];
  }

  return [
    {
      filter: 'CUSTOMER_NAME',
      op: 'eq',
      value: null,
    },
  ];
};

export const isDateFilterValueInput = (
  value: unknown,
): value is DateFilterValueInput => {
  if (isNil(value) || typeof value !== 'object') {
    return false;
  }

  const candidate = value as DateFilterValueInput;

  // offsetDays is required
  if (typeof candidate.offsetDays !== 'number') {
    return false;
  }

  // date is optional
  // date really should be a Date, but our GraphQL scalar serializes as a string and types it as any :/
  if (
    !isNil(candidate.date) &&
    !(typeof candidate.date === 'string' || candidate.date instanceof Date)
  ) {
    return false;
  }

  // dateFilterOption is optional but must be valid enum if present
  if (
    !isNil(candidate.dateFilterOption) &&
    !Object.values(DateFilterOptionV2).includes(candidate.dateFilterOption)
  ) {
    return false;
  }

  return true;
};
