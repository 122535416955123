import { Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import {
  getManifestDimWeight,
  getManifestPieces,
  getManifestWeight,
  type LineHaulManifest,
} from '../utils';
import pluralize from 'pluralize';

type LoadSummaryProps = {
  readonly manifest: LineHaulManifest;
};

const LoadSummary = ({ manifest }: LoadSummaryProps) => {
  const { pieces, weight, dimWeight } = useMemo(() => {
    return {
      pieces: getManifestPieces(manifest),
      weight: getManifestWeight(manifest),
      dimWeight: getManifestDimWeight(manifest),
    };
  }, [manifest]);

  // Fields to display in the load summary
  const fields = [
    { label: pluralize('pc', pieces), value: pieces },
    {
      label: pluralize('order', manifest.orders.length),
      value: manifest.orders.length,
    },
    { label: pluralize('lb', weight), value: weight.toFixed(2) },
    { label: 'DIM wt.', value: dimWeight.toFixed(2) },
  ] as const;

  return (
    <Stack
      flex="1"
      direction="row"
      alignItems="center"
      justifyContent="space-around"
      fontWeight={600}
    >
      {fields.map((field) => (
        <Stack key={field.label} direction="row" alignItems="center" gap={1}>
          <Typography fontWeight="inherit">{field.value}</Typography>
          <Typography sx={{ opacity: 0.5 }}>{field.label}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};

export default LoadSummary;
