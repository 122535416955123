import SearchIcon from '@mui/icons-material/Search';
import {
  Button,
  Dialog,
  FormControl,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import type { ColDef, GetRowIdParams } from 'ag-grid-community';
import { type AgGridReact } from 'ag-grid-react';
import React, {
  useEffect,
  useRef,
  useState,
  type Dispatch,
  type SetStateAction,
} from 'react';
import useStateRef from 'react-usestateref';
import ClearTextFieldButton from '../../../../common/components/clear-text-field-button';
import {
  FilterViewPage,
  type ClientQuoteFragment,
  type FindQuoteFiltersInput,
} from '../../../../generated/graphql';
import PalletAgGridReact from '../../../ag-grid/pallet-ag-grid/pallet-ag-grid-react';
import ApproveQuoteModal from '../../view-quotes/components/approve-quote-area';
import { QuoteFilterPills } from './quote-filters';
import { useQuotesTableColumnDefs } from './use-quotes-table-column-defs';
import {
  QUOTES_PER_PAGE,
  useQuotesTableGridFunctions,
} from './use-quotes-table-grid-functions';
import BillingPartyContactModal from '../../../orders/components/standard/billing-party-contact-modal';

const DEFAULT_COL_DEF: Readonly<ColDef<ClientQuoteFragment>> = {
  resizable: true,
  suppressMenu: true,
  editable: false,
  sortable: false,
};

export type QuotesTableState = {
  datasourceVersionId: number;
  currentCursor: string | null | undefined;
  searchText: string | undefined;
};

type QuotesTableProps = {
  readonly filters: FindQuoteFiltersInput;
  readonly setFilters: Dispatch<SetStateAction<FindQuoteFiltersInput>>;
};

const QuotesTable = ({ filters, setFilters }: QuotesTableProps) => {
  const [showQuotesModal, setShowQuotesModal] = useState(false);
  const [quote, setQuote] = useState<ClientQuoteFragment | undefined>(
    undefined,
  );
  const [showContactModal, setShowContactModal] = useState(false);
  const gridRef = useRef<AgGridReact<ClientQuoteFragment>>(null);

  /**
   * AG Grid has serious issues with stale closures, so we use this ref everywhere :/
   * @see OrdersTableWithFiltersAgGrid for more details
   */
  const [, setState, stateRef] = useStateRef<QuotesTableState>({
    datasourceVersionId: 0,
    currentCursor: null,
    searchText: undefined,
  });

  // See above – all my homies hate stale closures
  const filtersRef = useRef<FindQuoteFiltersInput>(filters);
  useEffect(() => {
    filtersRef.current = filters;
  }, [filters]);

  const columnDefs = useQuotesTableColumnDefs({
    setQuote,
    setShowQuotesModal,
  });
  const { onGridReady, refreshGrid } = useQuotesTableGridFunctions({
    setState,
    stateRef,
    gridRef,
    filtersRef,
  });

  const SEARCH_TEXT_COMPONENT = (
    <FormControl>
      <TextField
        size="small"
        placeholder="Search quotes"
        InputProps={{
          style: { backgroundColor: 'white' },
          sx: { '& input': { textOverflow: 'ellipsis' } },
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <ClearTextFieldButton
              searchText={stateRef.current?.searchText ?? ''}
              handleClearSearchText={() => {
                setState((prevState) => ({
                  ...prevState,
                  searchText: undefined,
                }));
              }}
            />
          ),
        }}
        value={stateRef.current?.searchText ?? ''}
        sx={{
          width: '200px',
        }}
        onKeyDown={async (e) => {
          if (e.key === 'Enter') {
            refreshGrid();
          }
        }}
        onChange={(e) => {
          setState((prevState) => ({
            ...prevState,
            searchText: e.target.value,
          }));
        }}
      />
    </FormControl>
  );

  const handleSetFilters = (filters: FindQuoteFiltersInput) => {
    setFilters(filters);
    refreshGrid();
  };

  const FILTER_PILLS_COMPONENT = (
    <QuoteFilterPills wrap filters={filters} setFilters={handleSetFilters} />
  );

  return (
    <>
      <BillingPartyContactModal
        isFromQuotesPage
        open={showContactModal}
        setIsOpen={setShowContactModal}
      />
      {showQuotesModal && quote !== undefined && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={showQuotesModal}
          onClose={() => {
            setShowQuotesModal(false);
          }}
        >
          <ApproveQuoteModal
            open={showQuotesModal}
            setOpen={setShowQuotesModal}
            quoteUuid={quote.uuid}
            billingPartyContactUuid={quote.billingPartyContact.uuid}
            isFromQuoteProfile={false}
          />
        </Dialog>
      )}
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" gap={1}>
          {SEARCH_TEXT_COMPONENT}
          {FILTER_PILLS_COMPONENT}
        </Stack>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setShowContactModal(true);
          }}
        >
          Create Quote
        </Button>
      </Stack>
      <PalletAgGridReact<ClientQuoteFragment>
        ref={gridRef}
        pagination
        suppressCellFocus
        rowMultiSelectWithClick={false}
        columnDefs={columnDefs}
        defaultColDef={DEFAULT_COL_DEF}
        pageType={FilterViewPage.Quotes}
        cacheBlockSize={QUOTES_PER_PAGE}
        paginationPageSize={QUOTES_PER_PAGE}
        getRowId={(params: GetRowIdParams<ClientQuoteFragment>) =>
          params.data.uuid
        }
        headerHeight={35}
        rowHeight={35}
        rowSelection="multiple"
        rowModelType="serverSide"
        sideBar={false}
        domLayout="autoHeight"
        onGridReady={onGridReady}
      />
    </>
  );
};

export default React.memo(QuotesTable);
