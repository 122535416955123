import {
  Checkbox,
  Stack,
  type SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  type Theme,
  TableSortLabel,
  Typography,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import type React from 'react';
import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import ReviewCustomerPortalOrdersTableRow from './review-customer-portal-orders-table-row';
import { isNilOrEmptyString } from 'shared/string';
import { useCustomerPortalOrdersForReview } from './hooks/use-customer-portal-orders-for-review';
import CenteredCircularProgress from '../../../../../common/components/centered-circular-progress';

const styles = {
  headerRow: {
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
  firstColumnTableCell: {
    paddingLeft: '10px',
    paddingRight: '4px',
  },
} satisfies Record<string, SxProps<Theme>>;

const DEFAULT_PAGE_SIZE = 10;
// Empty array so we have a stable reference
const EMPTY_ROWS_PER_PAGE_OPTIONS: number[] = [];

type ReviewCustomerPortalOrdersTableProps = {
  readonly selectedOrderUuid: string | null;
  readonly setSelectedOrderUuid: Dispatch<SetStateAction<string | null>>;
};

const ReviewCustomerPortalOrdersTable = ({
  selectedOrderUuid,
  setSelectedOrderUuid,
}: ReviewCustomerPortalOrdersTableProps) => {
  const [page, setPage] = useState<number>(0);

  const {
    edges,
    pageInfo,
    totalCount,
    loading,
    refetch: fetchCustomerPortalOrdersForReview,
  } = useCustomerPortalOrdersForReview({
    first: DEFAULT_PAGE_SIZE,
    // nextFetchPolicy is required so that onCompleted is called on refetch
    nextFetchPolicy: 'cache-and-network',
    onCompleted: ({ getOrderTableFieldValues }) => {
      const newUuids = new Set(
        getOrderTableFieldValues.formattedOrderConnection.edges.map(
          (e) => e.node.uuid,
        ),
      );
      setSelectedOrderUuid((current) =>
        current === null ? null : newUuids.has(current) ? current : null,
      );
    },
  });

  const ordersToReview = useMemo(
    () => edges?.map((e) => e.node) ?? [],
    [edges],
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsOrderUuid = searchParams.get('orderUuid');

  // If the user navigates to the page with an orderUuid in the search params,
  // select the order and remove the orderUuid from the search params
  useEffect(() => {
    if (isNilOrEmptyString(searchParamsOrderUuid)) {
      return;
    }
    setSelectedOrderUuid(searchParamsOrderUuid);
    setSearchParams((currSearchParams) => {
      currSearchParams.delete('orderUuid');
      return currSearchParams;
    });
  }, [searchParamsOrderUuid, setSelectedOrderUuid, setSearchParams]);

  const prev = async () => {
    await fetchCustomerPortalOrdersForReview({
      last: DEFAULT_PAGE_SIZE,
      before: pageInfo?.startCursor ?? undefined,
    });
  };

  const next = async () => {
    await fetchCustomerPortalOrdersForReview({
      first: DEFAULT_PAGE_SIZE,
      after: pageInfo?.endCursor ?? undefined,
    });
  };

  const handlePageChange = (
    _e: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    if (newPage > page) {
      void next();
    } else if (newPage < page) {
      void prev();
    }
    setPage(newPage);
  };

  return (
    <Stack gap={1}>
      <Stack
        direction="row"
        gap={2}
        alignItems="center"
        justifyContent="space-between"
        px={1}
      >
        <Typography fontWeight="500">Customer Portal Orders</Typography>
        <TablePagination
          rowsPerPageOptions={EMPTY_ROWS_PER_PAGE_OPTIONS}
          labelRowsPerPage="Show"
          component="div"
          count={totalCount ?? 0}
          rowsPerPage={DEFAULT_PAGE_SIZE}
          page={page}
          slotProps={{
            actions: {
              previousButton: {
                disabled: loading || page === 0,
              },
              nextButton: {
                disabled:
                  loading ||
                  totalCount === 0 ||
                  page + 1 === Math.ceil((totalCount ?? 0) / DEFAULT_PAGE_SIZE),
              },
            },
          }}
          onPageChange={handlePageChange}
        />
      </Stack>
      <TableContainer>
        <Table
          stickyHeader
          padding="none"
          aria-label="customer-portal-preview-table"
          sx={{ tableLayout: 'fixed' }}
        >
          <TableHead>
            <TableRow>
              <TableCell sx={styles.firstColumnTableCell}>Order</TableCell>
              <TableCell sx={styles.headerRow}>HAWB</TableCell>
              <TableCell sx={styles.headerRow}>Customer</TableCell>
              <TableCell sx={styles.headerRow}>
                <TableSortLabel
                  active
                  disabled
                  direction="desc"
                  hideSortIcon={false}
                >
                  Received At
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <CenteredCircularProgress />
          ) : (
            <TableBody>
              {ordersToReview.map((order) => (
                <ReviewCustomerPortalOrdersTableRow
                  key={order.uuid}
                  order={order}
                  rowIsSelected={selectedOrderUuid === order.uuid}
                  selectRow={() => {
                    setSelectedOrderUuid(order.uuid);
                  }}
                  unselectRow={() => {
                    setSelectedOrderUuid(null);
                  }}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Stack>
  );
};

export default ReviewCustomerPortalOrdersTable;
