import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import TabPanel from '../../../common/components/tab-panel/tab-panel';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import Billing from './billing/billing';
import CreditTypes from './billing/credit-types/credit-types';
import GeneralLedger from './billing/general-ledger/general-ledger';
import MasterAccountsTable from './billing/master-accounts/master-accounts-table';
import SettlementDeductionTypes from './billing/settlement-deduction-types';

const BillingTab = () => {
  const [tabIndex, setTabIndex] = useState<number>(0);

  const ffEnableGeneralLedger = useFeatureFlag(
    FeatureFlag.FF_ENABLE_GENERAL_LEDGER_CONFIGURATION_PAGE,
  );

  return (
    <Box sx={{ px: 2 }}>
      <Tabs
        orientation="horizontal"
        variant="scrollable"
        value={tabIndex}
        aria-label="billing tabs"
        sx={{
          marginTop: '-10px',
        }}
        onChange={(e, newIndex) => {
          setTabIndex(newIndex);
        }}
      >
        <Tab value={0} label="General" />
        <Tab value={1} label="Credit/Debit types" />
        {ffEnableGeneralLedger && <Tab value={2} label="General ledger" />}
        <Tab value={3} label="Settlement deduction types" />
        <Tab value={4} label="Master accounts" />
      </Tabs>
      <TabPanel selectedValue={tabIndex} panelValue={0}>
        <Billing />
      </TabPanel>
      <TabPanel selectedValue={tabIndex} panelValue={1}>
        <CreditTypes />
      </TabPanel>
      {ffEnableGeneralLedger && (
        <TabPanel selectedValue={tabIndex} panelValue={2}>
          <GeneralLedger />
        </TabPanel>
      )}
      <TabPanel selectedValue={tabIndex} panelValue={3}>
        <SettlementDeductionTypes />
      </TabPanel>
      <TabPanel selectedValue={tabIndex} panelValue={4}>
        <MasterAccountsTable />
      </TabPanel>
    </Box>
  );
};

export default BillingTab;
