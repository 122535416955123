import { Fade, styled, TableCell, TableRow } from '@mui/material';
import { useState } from 'react';
import ButtonLink from '../../../../common/components/buttons/button-link';
import {
  type FormattedOrderFragment,
  OrderTableField,
} from '../../../../generated/graphql';
import { getOrderTableFieldValue } from '../../../orders/components/utils';
import { CUSTOMER_PORTAL_BOOKING_REQUESTS_PATH } from '../../constants';

export const CUSTOMER_PORTAL_BOOKING_REQUESTS_TABLE_ROW_HEIGHT = 40;

const TruncatedTableCell = styled(TableCell)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  height: CUSTOMER_PORTAL_BOOKING_REQUESTS_TABLE_ROW_HEIGHT,
});

type CustomerPortalBookingRequestsTableRowProps = {
  readonly order: FormattedOrderFragment;
};

const CustomerPortalBookingRequestsTableRow = ({
  order,
}: CustomerPortalBookingRequestsTableRowProps) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <TableRow
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <TruncatedTableCell>
        {getOrderTableFieldValue({
          order,
          orderTableField: OrderTableField.Name,
        })}
      </TruncatedTableCell>
      <TruncatedTableCell>
        {getOrderTableFieldValue({
          order,
          orderTableField: OrderTableField.BillOfLadingNumber,
        })}
      </TruncatedTableCell>
      <TruncatedTableCell>
        {getOrderTableFieldValue({
          order,
          orderTableField: OrderTableField.Company,
        })}
      </TruncatedTableCell>
      <TruncatedTableCell>
        {getOrderTableFieldValue({
          order,
          orderTableField: OrderTableField.CreatedAt,
        })}
      </TruncatedTableCell>
      <TruncatedTableCell>
        {getOrderTableFieldValue({
          order,
          orderTableField: OrderTableField.Charges,
        })}
      </TruncatedTableCell>
      <TableCell sx={{ py: 0 }}>
        <Fade in={isHovering}>
          <ButtonLink
            href={`${CUSTOMER_PORTAL_BOOKING_REQUESTS_PATH}/${order.uuid}`}
            variant="contained"
            size="small"
            sx={{ float: 'right' }}
          >
            Open
          </ButtonLink>
        </Fade>
      </TableCell>
    </TableRow>
  );
};

export default CustomerPortalBookingRequestsTableRow;
