import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import fileDownload from 'js-file-download';
import { isEmpty, isNil } from 'lodash';
import pluralize from 'pluralize';
import { useState } from 'react';
import { shallow } from 'zustand/shallow';
import {
  useDriverSettlementBillExportPdfMutation,
  useDriverSettlementBillExportZipMutation,
} from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import PalletModal from '../../../pallet-ui/modal/pallet-modal';

enum SettlementBillsDownloadType {
  ZIP = 'Separate files',
  PDF = 'Combined file',
}

type SettlementBillsDownloadModalProps = {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly settlementBillIds: string[];
};

const SettlementBillsDownloadModal = ({
  isOpen,
  onClose,
  settlementBillIds,
}: SettlementBillsDownloadModalProps) => {
  const [
    setSuccessMessage,
    setShowSuccessMessage,
    setErrorMessage,
    setShowErrorMessage,
  ] = useGlobalStore(
    (state) => [
      state.setSuccessMessage,
      state.setShowSuccessMessage,
      state.setErrorMessage,
      state.setShowErrorMessage,
    ],
    shallow,
  );

  const [downloadType, setDownloadType] = useState<SettlementBillsDownloadType>(
    SettlementBillsDownloadType.PDF,
  );
  const [includeHeaders, setIncludeHeaders] = useState<boolean>(false);
  const [includeLineItemDetails, setIncludeLineItemDetails] =
    useState<boolean>(false);
  const [includeLineItemTotals, setIncludeLineItemTotals] =
    useState<boolean>(false);

  const [getExportPdf, { loading: pdfLoading }] =
    useDriverSettlementBillExportPdfMutation();
  const [getExportZip, { loading: zipLoading }] =
    useDriverSettlementBillExportZipMutation();
  const loading = pdfLoading || zipLoading;

  const resetFilters = () => {
    setDownloadType(SettlementBillsDownloadType.PDF);
    setIncludeHeaders(false);
    setIncludeLineItemDetails(false);
    setIncludeLineItemTotals(false);
  };

  const getBillBlob = async (
    billIds: string[],
  ): Promise<
    | {
        blob: Blob;
        fileName: string;
      }
    | { blob: null; fileName: null }
  > => {
    let presignedGetUrl = null;
    let fileName = null;
    let errors = null;
    try {
      switch (downloadType) {
        case SettlementBillsDownloadType.PDF: {
          const pdfRes = await getExportPdf({
            variables: {
              driverSettlementBillExportPdfInput: {
                driverSettlementBillUuids: billIds,
                includeHeaders,
                includeLineItemDetails,
                includeLineItemTotals,
              },
            },
          });
          presignedGetUrl = pdfRes.data?.driverSettlementBillExportPdf.url;
          fileName = pdfRes.data?.driverSettlementBillExportPdf.fileName;
          errors = pdfRes.data?.driverSettlementBillExportPdf.errors;
          break;
        }
        case SettlementBillsDownloadType.ZIP: {
          const zipRes = await getExportZip({
            variables: {
              input: {
                driverSettlementBillUuids: billIds,
                includeHeaders,
                includeLineItemDetails,
                includeLineItemTotals,
              },
            },
          });
          presignedGetUrl = zipRes.data?.driverSettlementBillExportZip.url;
          fileName = zipRes.data?.driverSettlementBillExportZip.fileName;
          errors = zipRes.data?.driverSettlementBillExportZip.errors;
          break;
        }
      }
      if (
        isNil(errors) &&
        isEmpty(errors) &&
        !isNil(presignedGetUrl) &&
        !isNil(fileName)
      ) {
        const getFileRes = await fetch(presignedGetUrl, {
          cache: 'no-cache',
        });

        return { blob: await getFileRes.blob(), fileName };
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error generating driver settlement bill pdf', error);
    }
    return { blob: null, fileName: null };
  };

  const handleDownload = async () => {
    const { blob, fileName } = await getBillBlob(settlementBillIds);
    if (isNil(blob) || isNil(fileName)) {
      setErrorMessage(
        `Error downloading settlement ${pluralize(
          'bill',
          settlementBillIds.length,
        )}`,
      );
      setShowErrorMessage(true);
    } else {
      fileDownload(blob, fileName);
      setSuccessMessage(
        `Successfully downloaded settlement ${pluralize('bill', settlementBillIds.length)}`,
      );
      setShowSuccessMessage(true);
    }
    resetFilters();
    onClose();
  };

  const modalTitle = `Download settlement ${pluralize(
    'bill',
    settlementBillIds.length,
  )}`;
  return (
    <PalletModal
      hideCloseButton
      open={isOpen}
      title={modalTitle}
      pinnedElements={{
        bottomRight: (
          <Button
            variant="contained"
            color="info"
            disabled={loading}
            onClick={handleDownload}
          >
            Download
          </Button>
        ),
        bottomLeft:
          settlementBillIds.length > 1 ? (
            <FormControl>
              <InputLabel id="settlement-download-type-selection-label">
                Download as...
              </InputLabel>
              <Select
                labelId="settlement-download-type-selection-label"
                value={downloadType}
                label="Download as..."
                size="small"
                sx={{
                  backgroundColor: 'white',
                }}
                disabled={loading}
                onChange={(e) => {
                  setDownloadType(
                    (e.target.value as SettlementBillsDownloadType) ??
                      SettlementBillsDownloadType.PDF,
                  );
                }}
              >
                {[
                  SettlementBillsDownloadType.PDF,
                  SettlementBillsDownloadType.ZIP,
                ].map((downloadType) => (
                  <MenuItem
                    key={downloadType}
                    id={downloadType}
                    value={downloadType}
                  >
                    {downloadType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : undefined,
      }}
      onClose={onClose}
    >
      <Stack direction="column">
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={includeHeaders}
                onChange={(e) => {
                  setIncludeHeaders(e.target.checked);
                }}
              />
            }
            label="Include headers"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={includeLineItemDetails}
                onChange={(e) => {
                  setIncludeLineItemDetails(e.target.checked);
                }}
              />
            }
            label="Include shipment details"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={includeLineItemTotals}
                onChange={(e) => {
                  setIncludeLineItemTotals(e.target.checked);
                }}
              />
            }
            label="Include driver pay"
          />
        </FormControl>
      </Stack>
    </PalletModal>
  );
};

export default SettlementBillsDownloadModal;
