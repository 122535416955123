import { Box, Stack } from '@mui/material';
import { type CSSProperties, type ReactNode } from 'react';
import GlobalSnackbars from '../../../common/components/global-snackbars/global-snackbars';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import { type CustomerPortalPage } from '../../../layouts/dashboard/types';
import useCustomerPortalDrawerItems from '../hooks/use-customer-portal-drawer-items';
import CustomerPortalAppBar from './customer-portal-app-bar';
import CustomerPortalSidebar from './customer-portal-sidebar';

const DASHBOARD_STYLES: Record<string, CSSProperties> = {
  dashboardBox: {
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    minWidth: 0,
    overflow: 'initial',
  },
};

type CustomerPortalDashboardLayoutProps = {
  readonly children: ReactNode;
  readonly page: CustomerPortalPage;
};

const CustomerPortalDashboardLayout = ({
  children,
  page,
}: CustomerPortalDashboardLayoutProps) => {
  const ffDemoCustomerPortal = useFeatureFlag(
    FeatureFlag.FF_DEMO_CUSTOMER_PORTAL,
  );

  const { drawerItems } = useCustomerPortalDrawerItems();

  const selectedDrawerItem = drawerItems.find((item) => item.page === page);

  if (ffDemoCustomerPortal) {
    return children;
  }

  return (
    <Stack
      sx={{
        backgroundColor: '#F7F7F7',
        minWidth: '100vw',
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack direction="row" flex="1" minHeight="0">
        <GlobalSnackbars />
        <CustomerPortalSidebar />
        <Box sx={DASHBOARD_STYLES.dashboardBox}>
          <CustomerPortalAppBar selectedDrawerItem={selectedDrawerItem} />
          <Box flex={1} minHeight={0}>
            {children}
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
};

export default CustomerPortalDashboardLayout;
