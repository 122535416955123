import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { useMemo } from 'react';
import {
  Controller,
  type FieldError,
  get,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import { useTerminalsForThirdPartyUserQuery } from '../../../../generated/graphql';
import { BaseTerminalAutocomplete } from '../../../orders/components/order-form/components/terminal-autocomplete';
import { useOrderFormEditAccess } from '../../../orders/components/order-form/contexts/order-form-edit-access-context';
import {
  isPartnerCarrierStop,
  StopMethod,
} from '../../../orders/components/order-form/forms/stop-type';
import { useCustomerPortalOrderFormContext } from './contexts/customer-portal-order-form-context';
import { type CustomerPortalOrderFormValues } from './forms/types';
import { getCustomerPortalOrderFormStopField } from './forms/utils';
import ServiceLevelDeterminesStopTypeTooltip from './service-level-determines-stop-type-tooltip';

type CustomerPortalOrderPartnerCarrierFieldProps = {
  readonly stopMethod: StopMethod;
};

export const CustomerPortalOrderPartnerCarrierField = ({
  stopMethod,
}: CustomerPortalOrderPartnerCarrierFieldProps) => {
  const { disabledIfNoAccess } = useOrderFormEditAccess();
  const { companyUuid, terminalsEnabled, getStopType } =
    useCustomerPortalOrderFormContext();
  const stopType = getStopType(stopMethod);
  const stopFieldPath = getCustomerPortalOrderFormStopField(stopMethod);

  const {
    control,
    formState: { errors },
  } = useFormContext<CustomerPortalOrderFormValues>();
  const terminalId = useWatch({
    control,
    name: `${stopFieldPath}.terminalId`,
  });
  const error =
    // RHF errors are hard to work with when the field doesn't always exist :/
    get(errors, `${stopFieldPath}.terminalId`) as FieldError | undefined;

  const partnerCarrierEnabled =
    !isNil(stopType) && isPartnerCarrierStop(stopType);

  const otherServiceLabel =
    stopMethod === StopMethod.Inbound
      ? 'Order freight starts at your warehouse'
      : 'Order freight will be picked up from your warehouse';

  const { data: terminalsData } = useTerminalsForThirdPartyUserQuery(
    !partnerCarrierEnabled || !terminalsEnabled
      ? { skip: true }
      : {
          variables: {
            findTerminalsForThirdPartyUserInput: {
              isActive: true,
              companyUuid,
            },
          },
          fetchPolicy: 'cache-and-network',
        },
  );
  const terminals = useMemo(
    () => terminalsData?.terminalsForThirdPartyUser ?? [],
    [terminalsData?.terminalsForThirdPartyUser],
  );

  return (
    <Stack>
      <ServiceLevelDeterminesStopTypeTooltip disabled={disabledIfNoAccess}>
        <FormControlLabel
          disabled
          control={<Checkbox disabled checked={partnerCarrierEnabled} />}
          label={otherServiceLabel}
        />
      </ServiceLevelDeterminesStopTypeTooltip>
      {partnerCarrierEnabled && (
        <Stack gap={0.5} p={1}>
          {terminalsEnabled ? (
            <Controller
              name={`${stopFieldPath}.terminalId`}
              control={control}
              render={({ field: { onChange } }) => (
                <BaseTerminalAutocomplete
                  terminals={terminals}
                  terminalUuid={terminalId}
                  disabled={disabledIfNoAccess}
                  error={error}
                  onChange={(_event, option) => {
                    onChange(option?.value);
                  }}
                />
              )}
            />
          ) : (
            <Typography variant="body2" color="error">
              Partner carrier stops are not supported for single-terminal
              carriers. Please contact the carrier directly
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
};
