import { useMatches } from 'react-router-dom';

export type PageTitleHandle = {
  pageTitle: string;
};

const isPageTitleHandle = (handle: unknown): handle is PageTitleHandle => {
  return typeof handle === 'object' && handle !== null && 'pageTitle' in handle;
};

/**
 * Some routes (e.g. add order) don't have an associated drawer item
 * (which is how we usually set page titles to be used in the app bar)
 *
 * For these, we use React Router's `handle` option to set the page title
 * and have it be colocated with the route definition.
 */
export const usePageTitleFromHandle = (): string | undefined => {
  const matches = useMatches();
  const lastMatch = matches.at(-1);
  const lastMatchHandle = lastMatch?.handle;
  if (isPageTitleHandle(lastMatchHandle)) {
    return lastMatchHandle.pageTitle;
  }
  return undefined;
};
