import { isEmpty, isNil, noop } from 'lodash';
import { useFormContext, useFormState } from 'react-hook-form';
import { AccessorialType } from '../../../../../generated/graphql';
import { type AccessorialRange, FormMode } from '../common';
import { type AccessorialDateRangeConfigFormValues } from '../forms/use-accessorial-date-range-config-form';
import RangeBasedAccessorialRateEditor from '../range-based-accessorial-rate-editor';

const WeightBasedAccessorialRateEditor = () => {
  const { watch, setValue, control } =
    useFormContext<AccessorialDateRangeConfigFormValues>();
  const { errors } = useFormState({ control });
  const ranges = watch('ranges');

  return (
    <RangeBasedAccessorialRateEditor
      accessorialType={AccessorialType.Weight}
      mode={FormMode.EDIT}
      formValues={{ ranges }}
      formErrors={
        isEmpty(errors.ranges) || isNil(errors.ranges?.map)
          ? {}
          : // Note that we don't want to compact/filter nil errors here so that
            // we preserve the indexes (e.g. error for the second range is still
            // index 1 rather than being compacted to 0)
            { ranges: errors.ranges.map((error) => error?.message ?? '') }
      }
      validateFieldsAndSetErrors={noop}
      onChangeFormValues={(newData: { ranges: AccessorialRange[] }) => {
        setValue('ranges', newData.ranges);
      }}
    />
  );
};

export default WeightBasedAccessorialRateEditor;
