import { type Dayjs } from 'dayjs';
import PalletDatePicker from '../../../pallet-ui/date-picker/pallet-date-picker';
import { type FilterEditorProps } from '../types-v2';
import { BaseFilterInputInputPropsSx } from '../../../domains/ag-grid/orders/components/filters/filter-utils';

const {
  backgroundColor: baseFilterInputInputPropsBackgroundColor,
  ...fieldsetSx
} = BaseFilterInputInputPropsSx;

const DateFilterEditor = ({ value, onChange }: FilterEditorProps<Dayjs>) => {
  return (
    <PalletDatePicker
      value={value}
      sx={{
        '& fieldset': fieldsetSx,
        backgroundColor: baseFilterInputInputPropsBackgroundColor,
        marginLeft: '-2px',
        width: '100%',
      }}
      onChange={(date) => {
        onChange(date);
      }}
    />
  );
};

export default DateFilterEditor;
