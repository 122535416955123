import { useState } from 'react';
import { type LineHaulManifest } from '../utils';
import { Box, styled } from '@mui/material';
import {
  Checkbox,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import theme from '../../../../../theme';
import PalletLink from '../../../../../pallet-ui/links/link/pallet-link';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import {
  LineHaulManifestGroupsDocument,
  useRemoveOrdersFromManifestV2Mutation,
} from '../../../../../generated/graphql';
import * as BulkActionsPopover from '../../../../../pallet-ui/bulk-actions-popover/bulk-actions-popover';
import useLineHaulDispatchStore from '../../../store/line-haul-dispatch-store';
import { shallow } from 'zustand/shallow';

const CompactRow = styled(TableRow)({
  '> *': {
    padding: '0px 5px !important',
  },
  '& .delete-button': {
    visibility: 'hidden',
    position: 'absolute',
    right: '8px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  '&:hover .delete-button': {
    visibility: 'visible',
  },
  position: 'relative',
});

type TagsCellProps = {
  readonly tags: Array<{ value: string }>;
};

const TagsCell = ({ tags }: TagsCellProps) => {
  if (tags.length === 0) {
    return <Typography>-</Typography>;
  }
  return <Typography>{tags.map((tag) => tag.value).join(', ')}</Typography>;
};

const CHECKBOX_CELL_WIDTH = '20px';

type ManifestOrdersTableProps = {
  readonly manifest: LineHaulManifest;
};

const ManifestOrdersTable = ({ manifest }: ManifestOrdersTableProps) => {
  // Order search
  const [searchText, setSearchText] = useState('');
  const filteredOrders = manifest.orders.filter(
    (order) =>
      order.name.toLowerCase().includes(searchText.toLowerCase()) ||
      order.standardOrderFields.shipperBillOfLadingNumber?.includes(searchText),
  );

  // Order selection
  const [selectedOrderUuids, setSelectedOrderUuids] = useState<Set<string>>(
    new Set(),
  );
  const onCheckboxChange = (orderUuid: string) => {
    setSelectedOrderUuids((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(orderUuid)) {
        newSet.delete(orderUuid);
      } else {
        newSet.add(orderUuid);
      }
      return newSet;
    });
  };

  const onSelectAllCheckboxChange = () => {
    setSelectedOrderUuids((prev) => {
      if (prev.size === filteredOrders.length) {
        return new Set();
      }
      return new Set(filteredOrders.map(({ uuid }) => uuid));
    });
  };

  // Remove orders from manifest
  const [removeOrdersFromManifestV2] = useRemoveOrdersFromManifestV2Mutation();
  const [setSnackbarErrorMessage, setShouldRefreshGrid] =
    useLineHaulDispatchStore(
      (state) => [state.setSnackbarErrorMessage, state.setShouldRefreshGrid],
      shallow,
    );

  const onRemoveOrderFromManifest = async (orderUuids: string[]) => {
    await removeOrdersFromManifestV2({
      variables: {
        input: {
          manifestUuid: manifest.uuid,
          orderUuids,
        },
      },
      onCompleted: (data) => {
        if (
          data.removeOrdersFromManifestV2.__typename ===
          'RemoveOrdersFromManifestV2SuccessOutput'
        ) {
          setSelectedOrderUuids(new Set());
          setShouldRefreshGrid(true);
        } else {
          setSnackbarErrorMessage(data.removeOrdersFromManifestV2.message);
        }
      },
      refetchQueries: [LineHaulManifestGroupsDocument],
    });
  };

  return (
    <Stack position="relative" height="100%">
      <Box p={2}>
        <TextField
          fullWidth
          variant="standard"
          size="small"
          label="Search orders"
          autoComplete="off"
          sx={{ minWidth: 60 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
        />
      </Box>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead sx={{ backgroundColor: theme.palette.background.default }}>
            <CompactRow>
              <TableCell style={{ width: CHECKBOX_CELL_WIDTH }}>
                <Checkbox
                  checked={
                    selectedOrderUuids.size === filteredOrders.length &&
                    filteredOrders.length > 0
                  }
                  indeterminate={
                    selectedOrderUuids.size > 0 &&
                    selectedOrderUuids.size < filteredOrders.length
                  }
                  onChange={onSelectAllCheckboxChange}
                />
              </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>HAWB</TableCell>
              <TableCell>MAWB</TableCell>
              <TableCell>Packages</TableCell>
              <TableCell>Tags</TableCell>
            </CompactRow>
          </TableHead>
          <TableBody>
            {filteredOrders.map((order) => (
              <CompactRow key={order.uuid}>
                <TableCell style={{ width: CHECKBOX_CELL_WIDTH }}>
                  <Checkbox
                    checked={selectedOrderUuids.has(order.uuid)}
                    onChange={() => {
                      onCheckboxChange(order.uuid);
                    }}
                  />
                </TableCell>
                <TableCell>
                  <PalletLink
                    href={`/orders?orderUuid=${order.uuid}&goBackAfterSave=true`}
                    target="_blank"
                    underline="hover"
                  >
                    {order.name}
                  </PalletLink>
                </TableCell>
                <TableCell>
                  {order.standardOrderFields.shipperBillOfLadingNumber}
                </TableCell>
                <TableCell>
                  {order.standardOrderFields.masterAirwayBillOfLadingNumber}
                </TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>
                  {order.packages
                    .map(
                      (pkg) =>
                        `${pkg.length ?? '–'} × ${pkg.width ?? '–'} × ${pkg.height ?? '–'} / ${pkg.weight ?? '–'} lbs`,
                    )
                    .join(', ')}
                </TableCell>
                <TableCell>
                  <TagsCell tags={order.tags} />
                </TableCell>
                <IconButton
                  className="delete-button"
                  sx={{ width: '30px', aspectRatio: '1/1' }}
                  onClick={async () => {
                    await onRemoveOrderFromManifest([order.uuid]);
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </CompactRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {manifest.orders.length === 0 && (
        <Box p={2} alignSelf="center" mt={2}>
          <Typography fontSize={16} fontWeight={500}>
            Add orders to this manifest by selecting them from the table on the
            left.
          </Typography>
        </Box>
      )}
      {selectedOrderUuids.size > 0 && (
        <BulkActionsPopover.Root>
          <BulkActionsPopover.Container>
            <BulkActionsPopover.Count count={selectedOrderUuids.size} />
            <BulkActionsPopover.Option
              icon={<DeleteIcon />}
              label="Remove"
              onClick={async () => {
                await onRemoveOrderFromManifest([...selectedOrderUuids]);
              }}
            />
            <BulkActionsPopover.Close
              onClick={() => {
                setSelectedOrderUuids(new Set());
              }}
            />
          </BulkActionsPopover.Container>
        </BulkActionsPopover.Root>
      )}
    </Stack>
  );
};

export default ManifestOrdersTable;
