import {
  LineHaulManifestStatus,
  type TerminalEntity,
  type FormattedOrderFragment,
} from '../../../../../generated/graphql';
import { Stack, Typography } from '@mui/material';
import TerminalBadge from './terminal-badge';
import {
  isEmptyArray,
  type NonEmptyArray,
  type LineHaulManifest,
} from '../../../../line-haul/components/manifests-section/utils';
import { isNil } from 'lodash';
import LineHaulManifestsTooltip from './tooltip';

const createKey = (
  status1: LineHaulManifestStatus | null,
  status2: LineHaulManifestStatus | null,
): string => {
  return `${status1},${status2}`;
};

/**
 * Map valid pairs of statuses to a single status.
 */
// prettier-ignore
const TerminalStatusPairsToSingleStatus: Readonly<Record<string, LineHaulManifestStatus | 'Awaiting'>> = {
  [createKey(null, LineHaulManifestStatus.Planning)]: LineHaulManifestStatus.Planning,
  [createKey(null, LineHaulManifestStatus.Departed)]: LineHaulManifestStatus.Departed,
  [createKey(null, LineHaulManifestStatus.Arrived)]: LineHaulManifestStatus.Departed,
  [createKey(LineHaulManifestStatus.Planning, null)]: 'Awaiting',
  [createKey(LineHaulManifestStatus.Planning, LineHaulManifestStatus.Planning)]: LineHaulManifestStatus.Planning,
  [createKey(LineHaulManifestStatus.Departed, null)]: 'Awaiting',
  [createKey(LineHaulManifestStatus.Departed, LineHaulManifestStatus.Planning)]: LineHaulManifestStatus.Planning,
  [createKey(LineHaulManifestStatus.Arrived, null)]: LineHaulManifestStatus.Arrived,
  [createKey(LineHaulManifestStatus.Arrived, LineHaulManifestStatus.Planning)]: LineHaulManifestStatus.Planning,
  [createKey(LineHaulManifestStatus.Arrived, LineHaulManifestStatus.Departed)]: LineHaulManifestStatus.Departed,
  [createKey(LineHaulManifestStatus.Arrived, LineHaulManifestStatus.Arrived)]: LineHaulManifestStatus.Departed,
};

type TerminalStatusPairs = Array<{
  terminal: Pick<TerminalEntity, 'uuid' | 'code'>;
  statuses: [LineHaulManifestStatus | null, LineHaulManifestStatus | null];
}>;

/**
 * Generate a list of statuses for each terminal. For a start terminal, the statuses are the status of the previous manifest and the current manifest.
 * For an end terminal, the statuses are the status of the current manifest and the next manifest.
 */
const getStatusPairsForTerminals = (
  manifests: NonEmptyArray<
    Pick<LineHaulManifest, 'startTerminal' | 'endTerminal' | 'status'>
  >,
): TerminalStatusPairs => {
  const [firstManifest, ...restManifests] = manifests;
  const res: TerminalStatusPairs = [
    {
      terminal: firstManifest.startTerminal,
      statuses: [null, firstManifest.status],
    },
    {
      terminal: firstManifest.endTerminal,
      statuses: [firstManifest.status, restManifests[0]?.status ?? null],
    },
  ];

  for (const [i, currManifest] of restManifests.entries()) {
    res.push({
      terminal: currManifest.endTerminal,
      statuses: [currManifest.status, restManifests[i + 1]?.status ?? null],
    });
  }

  return res;
};

type NonNullLineHaulManifestsField = NonNullable<
  FormattedOrderFragment['lineHaulManifestsField']
>;

type LineHaulManifestsCellProps = {
  readonly currentTerminal: NonNullLineHaulManifestsField['currentTerminal'];
  readonly lineHaulManifests: NonNullLineHaulManifestsField['lineHaulManifests'];
  readonly startTerminal: NonNullLineHaulManifestsField['startTerminal'];
  readonly endTerminal: NonNullLineHaulManifestsField['endTerminal'];
};

const LineHaulManifestsCell = ({
  currentTerminal,
  lineHaulManifests,
  startTerminal,
  endTerminal,
}: LineHaulManifestsCellProps) => {
  if (isEmptyArray(lineHaulManifests)) {
    return (
      <LineHaulManifestsTooltip
        terminalsWithStatuses={[
          {
            status: 'Awaiting',
            terminal: startTerminal,
          },
        ]}
      >
        <TerminalBadge
          code={startTerminal.code}
          status="Awaiting"
          isCurrentTerminal={startTerminal.uuid === currentTerminal?.uuid}
          isDestinationTerminal={false}
        />
      </LineHaulManifestsTooltip>
    );
  }

  try {
    const terminalsWithStatuses = getStatusPairsForTerminals(
      lineHaulManifests,
    ).map(({ terminal, statuses }) => {
      const status =
        TerminalStatusPairsToSingleStatus[createKey(statuses[0], statuses[1])];

      if (isNil(status)) {
        throw new Error(`Invalid status: ${JSON.stringify(statuses)}`);
      }
      return {
        status,
        terminal,
      };
    });

    return (
      <LineHaulManifestsTooltip terminalsWithStatuses={terminalsWithStatuses}>
        <Stack direction="row" height="100%" gap={0.25}>
          {terminalsWithStatuses.map(({ status, terminal }, index) => (
            <TerminalBadge
              key={terminal.uuid}
              isCurrentTerminal={terminal.uuid === currentTerminal?.uuid}
              code={terminal.code}
              status={status}
              isDestinationTerminal={
                endTerminal.uuid === terminal.uuid &&
                index === terminalsWithStatuses.length - 1
              }
            />
          ))}
        </Stack>
      </LineHaulManifestsTooltip>
    );
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return (
      <Typography
        color="error"
        fontSize="12px"
        height="100%"
        display="flex"
        alignItems="center"
      >
        Error loading line haul manifests
      </Typography>
    );
  }
};

export default LineHaulManifestsCell;
