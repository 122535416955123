import * as BulkActionsPopover from '../../../../pallet-ui/bulk-actions-popover/bulk-actions-popover';
import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import ForwardRoundedIcon from '@mui/icons-material/ForwardRounded';
import { useState } from 'react';
import PrintLineHaulManifestsModal from './print-line-haul-manifests-modal';
import {
  LineHaulManifestGroupsDocument,
  LineHaulManifestStatus,
  useUpdateManifestsStatusMutation,
} from '../../../../generated/graphql';
import useLineHaulDispatchStore from '../../store/line-haul-dispatch-store';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import DeleteManifestsConfirmationModal from './delete-manifests-confirmation-modal';
import { ManifestsUpdateStatusSuccessMessage } from './utils';
import { type LineHaulManifest } from './utils';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';

type ManifestsBulkUpdateProps = {
  readonly selectedManifests: LineHaulManifest[];
};
const ManifestsBulkUpdate = ({
  selectedManifests,
}: ManifestsBulkUpdateProps) => {
  const selectedManifestUuids = selectedManifests.map(
    (manifest) => manifest.uuid,
  );

  // Modal states
  const [showDeleteTruckloadModal, setShowDeleteTruckloadModal] =
    useState(false);
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  // Line haul dispatch store
  const departDate = useLineHaulDispatchStore((state) => state.departDate);
  const deselectAllManifestUuids = useLineHaulDispatchStore(
    (state) => state.deselectAllManifestUuids,
  );
  const setSnackbarErrorMessage = useLineHaulDispatchStore(
    (state) => state.setSnackbarErrorMessage,
  );
  const setSnackbarSuccessMessage = useLineHaulDispatchStore(
    (state) => state.setSnackbarSuccessMessage,
  );

  // Update manifests status
  const [
    updateManifestsStatusMutation,
    { loading: updateManifestsStatusLoading },
  ] = useUpdateManifestsStatusMutation({
    refetchQueries: [LineHaulManifestGroupsDocument],
    onCompleted: (data) => {
      if (data.updateManifestsStatus.__typename === 'MutationErrorOutput') {
        setSnackbarErrorMessage(data.updateManifestsStatus.message);
      } else if (
        data.updateManifestsStatus.__typename ===
        'UpdateManifestsStatusSuccessOutput'
      ) {
        setSnackbarSuccessMessage(ManifestsUpdateStatusSuccessMessage);
        deselectAllManifestUuids();
      }
    },
    onError: (error) => {
      setSnackbarErrorMessage(error.message);
    },
  });

  const updateManifestsStatus = async (status: LineHaulManifestStatus) => {
    await updateManifestsStatusMutation({
      variables: {
        input: {
          manifestUuids: selectedManifestUuids,
          status,
        },
      },
    });
  };

  const onDeleteComplete = () => {
    setShowDeleteTruckloadModal(false);
    deselectAllManifestUuids();
    setSnackbarSuccessMessage('Manifests deleted successfully');
  };

  const allSelectedManifestsArePlanning = selectedManifests.every(
    (manifest) => manifest.status === LineHaulManifestStatus.Planning,
  );
  const allSelectedManifestsAreDeparted = selectedManifests.every(
    (manifest) => manifest.status === LineHaulManifestStatus.Departed,
  );

  if (selectedManifestUuids.length === 0) {
    return null;
  }

  return (
    <>
      <BulkActionsPopover.Root bottomOffset={28}>
        <BulkActionsPopover.Container>
          <BulkActionsPopover.Count count={selectedManifestUuids.length} />
          <BulkActionsPopover.Option
            icon={<PrintRoundedIcon />}
            label="Print"
            disabled={updateManifestsStatusLoading}
            onClick={() => {
              setIsPrintModalOpen(true);
            }}
          />
          {allSelectedManifestsArePlanning && (
            <BulkActionsPopover.BulkActionsOption
              icon={<ForwardRoundedIcon />}
              label="Mark departed"
              disabled={updateManifestsStatusLoading}
              onClick={async () =>
                updateManifestsStatus(LineHaulManifestStatus.Departed)
              }
            />
          )}
          {allSelectedManifestsAreDeparted && (
            <BulkActionsPopover.BulkActionsOption
              icon={<WhereToVoteIcon />}
              label="Mark arrived"
              disabled={updateManifestsStatusLoading}
              onClick={async () =>
                updateManifestsStatus(LineHaulManifestStatus.Arrived)
              }
            />
          )}
          <BulkActionsPopover.BulkActionsOption
            icon={<DeleteRoundedIcon />}
            label="Delete"
            disabled={updateManifestsStatusLoading}
            onClick={() => {
              setShowDeleteTruckloadModal(true);
            }}
          />
          <BulkActionsPopover.Close
            onClick={() => {
              deselectAllManifestUuids();
            }}
          />
        </BulkActionsPopover.Container>
      </BulkActionsPopover.Root>
      <PrintLineHaulManifestsModal
        open={isPrintModalOpen}
        setOpen={setIsPrintModalOpen}
        manifests={selectedManifests}
        departDate={departDate}
      />
      <DeleteManifestsConfirmationModal
        open={showDeleteTruckloadModal}
        setOpen={setShowDeleteTruckloadModal}
        manifestUuids={selectedManifestUuids}
        onDeleteComplete={onDeleteComplete}
      />
    </>
  );
};

export default ManifestsBulkUpdate;
