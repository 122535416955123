import { Button, Stack, Typography } from '@mui/material';
import {
  type LineHaulManifest,
  type NonEmptyArray,
  getCreateManifestsSuccessMessage,
} from '../utils';
import ArrowRightAltRoundedIcon from '@mui/icons-material/ArrowRightAltRounded';
import AddIcon from '@mui/icons-material/Add';
import pluralize from 'pluralize';
import {
  LineHaulManifestGroupsDocument,
  useCreateLineHaulManifestsMutation,
} from '../../../../../generated/graphql';
import { type Dayjs } from 'dayjs';
import { useErrors } from '../../../../../common/react-hooks/use-errors';
import { ErrorsAlert } from '../../../../../common/components/errors-alert';
import useLineHaulDispatchStore from '../../../store/line-haul-dispatch-store';

type ManifestLaneGroupHeaderProps = {
  readonly manifests: NonEmptyArray<LineHaulManifest>;
  readonly departDate: Dayjs;
};

const ManifestLaneGroupHeader = ({
  manifests,
  departDate,
}: ManifestLaneGroupHeaderProps) => {
  const { errors, onError, clearErrors } = useErrors();

  const setSnackbarSuccessMessage = useLineHaulDispatchStore(
    (state) => state.setSnackbarSuccessMessage,
  );
  const setSnackbarErrorMessage = useLineHaulDispatchStore(
    (state) => state.setSnackbarErrorMessage,
  );

  const [createManifest, { loading }] = useCreateLineHaulManifestsMutation({
    refetchQueries: [LineHaulManifestGroupsDocument],
    onError,
    onCompleted: ({ createLineHaulManifests }) => {
      if (
        createLineHaulManifests.__typename ===
        'CreateLineHaulManifestsSuccessOutput'
      ) {
        setSnackbarSuccessMessage(
          getCreateManifestsSuccessMessage(
            createLineHaulManifests.lineHaulManifests.length,
          ),
        );
      } else if (createLineHaulManifests.__typename === 'MutationErrorOutput') {
        setSnackbarErrorMessage(createLineHaulManifests.message);
      }
    },
  });

  const handleAddManifest = async () => {
    await createManifest({
      variables: {
        input: {
          lanes: [
            {
              startTerminalUuid: manifests[0].startTerminal.uuid,
              endTerminalUuid: manifests[0].endTerminal.uuid,
              count: 1,
            },
          ],
          departDate: departDate.toISOString(),
        },
      },
    });
  };

  return (
    <>
      <ErrorsAlert errors={errors} onClear={clearErrors} />
      <Stack py={1} px={2} direction="row" gap={1} alignItems="center">
        <Stack direction="row" gap={0.5} alignItems="center" fontWeight="700">
          <Typography fontWeight="700">
            {manifests[0].startTerminal.code}
          </Typography>
          <ArrowRightAltRoundedIcon style={{ fontSize: '16px' }} />
          <Typography fontWeight="700">
            {manifests[0].endTerminal.code}
          </Typography>
        </Stack>
        <Typography
          color="#838384"
          fontSize="12px"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          {manifests.length} {pluralize('manifest', manifests.length)} loaded
        </Typography>
        <Button
          sx={{ marginLeft: 'auto', whiteSpace: 'nowrap' }}
          startIcon={<AddIcon />}
          disabled={loading}
          onClick={() => {
            void handleAddManifest();
          }}
        >
          Add manifest
        </Button>
      </Stack>
    </>
  );
};

export default ManifestLaneGroupHeader;
