import { DragDropContext } from '@hello-pangea/dnd';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { flatten, isEmpty, isNil } from 'lodash';
import { LngLat, LngLatBounds, type MapLayerMouseEvent } from 'mapbox-gl';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import ReactMapGL, {
  type MapRef,
  NavigationControl,
  type ViewStateChangeEvent,
} from 'react-map-gl';
import useLocalStorageState from 'use-local-storage-state';
import { shallow } from 'zustand/shallow';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import useSelectedTerminalUuid from '../../../common/react-hooks/use-selected-terminal-uuid';
import useTerminals from '../../../common/react-hooks/use-terminals';
import useWarehouses from '../../../common/react-hooks/use-warehouses';
import {
  type StopFragment,
  type StopsQueryVariables,
} from '../../../generated/graphql';
import useDispatchStore from '../dispatch-store';
import useAssignStopsRouteActions from '../hooks/use-assign-stops-route-actions';
import useFetchRoutes from '../hooks/use-fetch-routes';
import useFetchStops from '../hooks/use-fetch-stops';
import useRouteActions from '../hooks/use-route-actions';
import { useRouteCardsColumnWidths } from '../hooks/use-route-cards-column-widths';
import RouteCard from '../routes/route-card';
import RouteStopsList from '../routes/route-stops-list';
import {
  calculateBoundsFromSlotsToShowAllMarkers,
  isPointInSquare,
  sortRoutes,
} from '../utils';
import { MapControls } from './components/map-controls';
import { MapRoutePath } from './components/map-route-path';
import RoutesMapToolbar from './components/routes-map-toolbar';
import Square from './components/square';
import StopMarker from './components/stop-marker';
import TerminalMarker from './components/terminal-marker';
import RoutesMapSidebar from './routes-map-sidebar';
import { pinTextForStopType } from './util';

const CARD_WIDTH = 275;
const ROUTES_PER_PAGE = 2;

const Map = () => {
  const theme = useTheme();
  const { warehouses } = useWarehouses();
  const { terminals, terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { columnWidths, setColumnWidths } = useRouteCardsColumnWidths();
  const routeStopsContainerRef = useRef<HTMLDivElement | null>(null);
  const [mapRef, setMapRef] = useState<MapRef | null>(null);
  const [viewState, setViewState] = useLocalStorageState(
    'route_map_initial_view_state',
    {
      defaultValue: {
        longitude: 0,
        latitude: 0,
        zoom: 0,
      },
    },
  );
  const ffDispatchTrackParity = useFeatureFlag(
    FeatureFlag.FF_DISPATCH_TRACK_PARITY,
  );
  const { selectedTerminalUuid } = useSelectedTerminalUuid();
  const [
    showMap,
    showUnassignedStopsInMap,
    setSelectedStopUuids,
    sortAsc,
    sortType,
    selectedRoutes,
    routes,
    openedRouteUuid,
    getRouteByUuid,
  ] = useDispatchStore(
    (state) => [
      state.showMap,
      state.showUnassignedStopsInMap,
      state.setSelectedStopUuids,
      state.sortAsc,
      state.sortType,
      state.routes.filter((route) =>
        state.selectedMapRouteUuids.includes(route.uuid),
      ),
      state.routes,
      state.openedRouteUuid,
      state.getRouteByUuid,
    ],
    shallow,
  );

  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [currentPos, setCurrentPos] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [showSquare, setShowSquare] = useState(false);
  const [unassignedStops, setUnassignedStops] = useState<StopFragment[]>();
  const { onDragStart, onDragEnd } = useRouteActions();
  const { fetchStops } = useFetchStops();
  const { fetchRoute } = useFetchRoutes();
  const { assignStop } = useAssignStopsRouteActions();

  const getStopsInSquare = (stops: StopFragment[] | undefined) => {
    if (!showUnassignedStopsInMap) return [];
    return stops?.filter((stop) => {
      const lat = stop?.stop?.address?.latitude;
      const long = stop?.stop?.address?.longitude;
      if (!isNil(lat) && !isNil(long)) {
        return isPointInSquare(
          startPos.x,
          startPos.y,
          currentPos.x,
          currentPos.y,
          lat,
          long,
        );
      }
      return false;
    });
  };

  const selectStopsInSquare = (stops: StopFragment[] | undefined) => {
    const stopsToSelect = getStopsInSquare(stops);
    if (!isNil(stopsToSelect)) {
      setSelectedStopUuids(stopsToSelect.map((stop) => stop.stop.uuid));
    }
  };

  const assignStopsInSquareToRoute = async (
    routeUuid: string,
    stops: StopFragment[] | undefined,
  ) => {
    const stopsToAssign = getStopsInSquare(stops);
    if (!isNil(stopsToAssign)) {
      const routeIsLocked = getRouteByUuid(routeUuid)?.locked === true;
      if (routeIsLocked) return;

      await assignStop({
        routeUuid,
        stopUuids: stopsToAssign.map((stop) => stop.stop.uuid),
        emitMultiplayerEvent: true,
      });

      setSelectedStopUuids([]);
      fetchRoute(routeUuid);
    }
  };

  const onDragFinished = (stops: StopFragment[] | undefined) => {
    if (isNil(openedRouteUuid)) {
      selectStopsInSquare(stops);
    } else {
      assignStopsInSquareToRoute(openedRouteUuid, stops);
    }
  };

  const fetchStopsForMap = async (variables: StopsQueryVariables) => {
    const data = await fetchStops({
      variables,
      first: 100,
      useCache: true,
    });
    // The assertion as StopFragment is ok because the query does not include recurring run headers.
    const stops = data.stops.edges.map((edge) => edge.node as StopFragment);
    setUnassignedStops(stops);
    return stops;
  };

  const onMouseDown = (event: MapLayerMouseEvent) => {
    if (!event.originalEvent.shiftKey) return; // Only start drag if shift key is pressed
    setIsDragging(true);
    setShowSquare(true);
    setStartPos({
      x: event.lngLat.lat, // x position within the element.
      y: event.lngLat.lng, // y position within the element.
    });
  };

  const onMouseMove = (event: MapLayerMouseEvent) => {
    if (!isDragging) return;
    setCurrentPos({
      x: event.lngLat.lat,
      y: event.lngLat.lng,
    });
  };

  const onMouseUp = async () => {
    if (isDragging) {
      onDragFinished(unassignedStops);
    }
    setIsDragging(false);
  };

  const handleMapMove = (e: ViewStateChangeEvent) => {
    setViewState({ ...viewState, ...e.viewState });
  };

  const handleRef = useCallback((ref: MapRef | null) => {
    setMapRef(ref);
  }, []);

  const resetZoom = () => {
    if (!isNil(mapRef)) {
      const stops = flatten(
        selectedRoutes?.map((route) =>
          route.slots.map((slot) => slot.stops[0]),
        ),
      );
      if (!isEmpty(stops)) {
        const bounds = calculateBoundsFromSlotsToShowAllMarkers(stops ?? []);
        const sw: LngLat = new LngLat(bounds.bottomRightX, bounds.bottomRightY);
        const ne = new LngLat(bounds.topLeftX, bounds.topLeftY);
        const boundsObject = new LngLatBounds(sw, ne);
        mapRef
          .getMap()
          .fitBounds(boundsObject, {
            padding: {
              left: 50,
              right: 50,
              top: 100,
              bottom: 50,
            },
          })
          .resize({ innerWidth: 1000, innerHeight: 1000 });
      }
    }
  };

  useEffect(() => {
    resetZoom();
    mapRef?.getMap().resize({ innerWidth: 1000, innerHeight: 1000 });
    // do not add resetZoom to deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoutes.length, showMap, mapRef]);

  return (
    <Splitter
      gutterSize={7}
      style={{ height: '100%', border: 0 }}
      stateKey="route-splitter-map"
      stateStorage="local"
      onResizeEnd={() => {
        mapRef?.getMap().resize({ innerWidth: 1000, innerHeight: 1000 });
      }}
    >
      <SplitterPanel
        className="flex align-items-center justify-content-center"
        size={10}
        style={{ height: '100%' }}
      >
        <Stack direction="row" sx={{ height: '100%' }}>
          {ffDispatchTrackParity ? (
            <RoutesMapSidebar
              onTableFiltersChanged={(variables: StopsQueryVariables) => {
                setShowSquare(false);
                fetchStopsForMap(variables);
              }}
            />
          ) : (
            <Stack
              sx={{
                borderRight: 1,
                borderRightColor: theme.palette.borderColor.main,
                boxShadow: 3,
              }}
            >
              <RoutesMapToolbar />
              <Box
                sx={{
                  height: '100%',
                  overflowY: 'scroll',
                  p: 1,
                }}
              >
                {sortRoutes([...routes], sortType, sortAsc)?.map((route) => {
                  return (
                    <Box key={route.uuid} sx={{ mt: 1 }}>
                      <RouteCard
                        selectMode
                        isMapView
                        route={route}
                        width={CARD_WIDTH}
                        columnWidths={columnWidths}
                        setColumnWidths={setColumnWidths}
                      />
                    </Box>
                  );
                })}
              </Box>
            </Stack>
          )}
          {!ffDispatchTrackParity && !isEmpty(selectedRoutes) && (
            <Stack
              spacing={1}
              sx={{
                borderRight: 1,
                borderRightColor: theme.palette.borderColor.main,
                boxShadow: `3px 0 5px -2px ${theme.palette.borderColor.main}`,
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ pl: 1, pr: 1, pt: 1 }}
              >
                <Typography sx={{ fontSize: '14px' }}>
                  Showing {selectedRoutes.length} of {routes.length} routes
                </Typography>
                {selectedRoutes.length > ROUTES_PER_PAGE && (
                  <Stack direction="row">
                    <IconButton
                      onClick={() => {
                        if (!isNil(routeStopsContainerRef.current)) {
                          routeStopsContainerRef.current.scrollLeft -=
                            (CARD_WIDTH + 31) * ROUTES_PER_PAGE;
                        }
                      }}
                    >
                      <ChevronLeftIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        if (!isNil(routeStopsContainerRef.current)) {
                          routeStopsContainerRef.current.scrollLeft +=
                            (CARD_WIDTH + 31) * ROUTES_PER_PAGE;
                        }
                      }}
                    >
                      <ChevronRightIcon />
                    </IconButton>
                  </Stack>
                )}
              </Stack>
              <Divider />
              <Stack
                ref={routeStopsContainerRef}
                direction="row"
                sx={{
                  maxWidth: (CARD_WIDTH + 15) * (ROUTES_PER_PAGE + 0.3),
                  overflowX: 'scroll',
                  pl: 1,
                }}
              >
                <DragDropContext
                  onDragEnd={onDragEnd}
                  onDragStart={onDragStart}
                >
                  {selectedRoutes.map((route) => {
                    return (
                      <Stack key={`route_column_${route.uuid}`}>
                        <Box sx={{ pr: 1 }}>
                          <RouteCard
                            isMapView
                            route={route}
                            width={CARD_WIDTH + 15}
                            columnWidths={columnWidths}
                            setColumnWidths={setColumnWidths}
                          />
                        </Box>
                        <Box
                          sx={{
                            height: '100%',
                            overflowY: 'scroll',
                            p: 1,
                            pl: 0,
                          }}
                        >
                          <RouteStopsList route={route} width={CARD_WIDTH} />
                        </Box>
                      </Stack>
                    );
                  })}
                </DragDropContext>
              </Stack>
            </Stack>
          )}
        </Stack>
      </SplitterPanel>
      <SplitterPanel className="flex align-items-center justify-content-center">
        <ReactMapGL
          ref={handleRef}
          latitude={viewState.latitude}
          longitude={viewState.longitude}
          zoom={viewState.zoom}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
          doubleClickZoom={false}
          boxZoom={!ffDispatchTrackParity}
          dragPan={!isDragging}
          dragRotate={!isDragging}
          onMove={handleMapMove}
          onMouseDown={ffDispatchTrackParity ? onMouseDown : undefined}
          onMouseMove={ffDispatchTrackParity ? onMouseMove : undefined}
          onMouseUp={ffDispatchTrackParity ? onMouseUp : undefined}
          onClick={() => {
            if (showSquare) {
              setSelectedStopUuids([]);
              setShowSquare(false);
            }
          }}
          onLoad={() => {
            resetZoom();
          }}
          onResize={() => {
            resetZoom();
          }}
        >
          <MapControls selectedRoutes={selectedRoutes} resetZoom={resetZoom} />
          <NavigationControl position="bottom-right" />
          {!terminalsEnabled &&
            warehouses?.map((warehouse) => (
              <TerminalMarker
                key={warehouse.uuid}
                address={warehouse.address}
                text={warehouse.name}
                color="darkblue"
              />
            ))}
          {terminals
            .filter(
              (t) =>
                (isNil(selectedTerminalUuid) ||
                  (!isNil(selectedTerminalUuid) &&
                    selectedTerminalUuid === t.uuid)) &&
                routes.some((r) => r.terminal?.uuid === t.uuid),
            )
            .map((terminal) => (
              <TerminalMarker
                key={terminal.uuid}
                address={terminal.address}
                text={terminal.code}
                color="darkblue"
              />
            ))}
          {selectedRoutes.map((route) => (
            <MapRoutePath key={route.uuid} route={route} />
          ))}
          {showUnassignedStopsInMap &&
            unassignedStops?.map((stop) => (
              <StopMarker
                key={stop.stop.uuid}
                color="#000000"
                stop={stop}
                ordinal={0}
                text={pinTextForStopType(stop.stop.stopType)}
              />
            ))}
          {showSquare && (
            <Square
              id="square_box"
              coordinates={[
                [
                  [startPos.y, startPos.x],
                  [startPos.y, currentPos.x],
                  [currentPos.y, currentPos.x],
                  [currentPos.y, startPos.x],
                  [startPos.y, startPos.x],
                ],
              ]}
              color="#cbcdd1"
            />
          )}
        </ReactMapGL>
      </SplitterPanel>
    </Splitter>
  );
};

export default memo(Map);
