import { useFormContext, useWatch } from 'react-hook-form';
import { type OrderFormFieldValues } from '../forms/types';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';

/**
 * Temporary source of truth for whether an user is using line haul
 * until we have fully migrated to the new line haul networks
 *
 */
export const useIsUsingLinehaul = () => {
  const { control } = useFormContext<OrderFormFieldValues>();

  const ffLineHaulNetworks = useFeatureFlag(FeatureFlag.FF_LINE_HAUL_NETWORKS);
  const isUsingLineHaulDeprecated = useWatch({
    control,
    name: 'isUsingLineHaul',
  });
  const useLineHaul = useWatch({ control, name: 'enableLineHaul' });

  const isUsingLineHaul = ffLineHaulNetworks
    ? useLineHaul
    : isUsingLineHaulDeprecated;

  return isUsingLineHaul;
};
