import { z } from 'zod';
import { freightCostSchema } from './freight-cost-schema';
import { customCostSchema } from './custom-cost-schema';
import { SettlementMode } from '../../../../../generated/graphql';
import { zDateOrDatetimeString } from './zod-utils';

// This is currently only built for Independent line items. We will likely need a new schema for Dependent line items.
export const independentSettlementBillLineItemSchema = z.object({
  id: z.string().uuid(),
  settlementMode: z.literal(SettlementMode.Independent),
  // TODO. Eventually make this nullish when agent settlement is supported
  driverUuid: z.string().uuid(),
  driverSettlementBillUuid: z.string().uuid().nullish(),
  driverSettlementBillName: z.string().nullish(),
  settlementBillFinalizedDate: zDateOrDatetimeString().nullish(),
  freightCost: freightCostSchema.nullish(),
  customCosts: z.array(customCostSchema),
  totalCharge: z.number(), // In dollars
  isLocal: z.boolean(), // True if added locally and doesn't exist in the database yet, false otherwise
});
