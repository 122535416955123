import LockIcon from '@mui/icons-material/Lock';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { orange } from '@mui/material/colors';
import { isEmpty, isEqual, isNil, round } from 'lodash';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { filterNotNil } from 'shared/array';
import { convertMetersToMiles } from 'shared/distance';
import { shallow } from 'zustand/shallow';
import { DISPATCH_PAGE_ROUTE_CARD_TEST_ID_PREFIX } from '../../../../constants';
import CenteredCircularProgress from '../../../common/components/centered-circular-progress';
import { ColoredChip } from '../../../common/components/colored-chip';
import { FeatureFlag } from '../../../common/feature-flags';
import useMultiplayerStore from '../../../common/multiplayer/multiplayer-store';
import {
  DispatchMultiplayerAction,
  type DispatchUserLocationPayload,
} from '../../../common/multiplayer/types/dispatch';
import useMultiplayer from '../../../common/multiplayer/use-multiplayer';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import { useIsMac } from '../../../common/react-hooks/use-is-mac';
import { useOnScreen } from '../../../common/react-hooks/use-on-screen';
import { Size } from '../../../common/types';
import { chooseForegroundColor } from '../../../common/utils/colors';
import {
  DriversWithRoutesDocument,
  NumberOfStopsDocument,
  RoutesDocument,
  useUpdateRouteDriversMutation,
  type OrderForDocumentAttachmentsDownloadFragment,
  type RouteFragment,
} from '../../../generated/graphql';
import useDispatchStore from '../dispatch-store';
import { useDispatchViewSettings } from '../hooks/use-dispatch-view-settings';
import { useMapRouteColor } from '../hooks/use-map-route-color';
import useRouteActions from '../hooks/use-route-actions';
import type { RouteCardsColumnWidths } from '../hooks/use-route-cards-column-widths';
import { useStopsOnRoute } from '../hooks/use-stops-on-route';
import { MAX_CARD_WIDTH } from '../types/dimensions';
import {
  getLinkedStopIndicesOutOfOrder,
  getRouteTotalTimeString,
  getStopsWithoutAddressesWarningText,
} from '../utils';
import ConfirmUnsignedPodsDownloadModal from './components/modals/confirm-unsigned-pods-download-modal';
import DeleteRoutesModal from './components/modals/delete-routes-modal';
import OptimizeRouteModal from './components/modals/optimize-route-modal';
import PrintManifestModal from './components/modals/print-manifest-modal';
import RouteDateModal from './components/modals/route-date-modal';
import TextDriversModal from './components/modals/text-drivers-modal';
import RouteCardHoverMenu from './components/route-card-hover-menu';
import { RouteCardWarningText } from './components/route-card-warning-text';
import { type OpenModalsActions } from './components/route-context-menu';
import RouteDriverField from './components/route-driver-field';
import RouteLoadTimeField from './components/route-load-time-field';
import RouteNameField from './components/route-name-field';
import RouteStartTimeField from './components/route-start-time-field';
import RouteStatusChip from './components/route-status-chip';
import { RouteTagsField } from './components/route-tags-field';
import RouteVehicleField from './components/route-vehicle-field';
import UserEditingPopup from './components/user-editing-popup';
import RouteCardStopsGrid from './route-card-stops-ag-grid';
import RouteCardStopsList from './route-card-stops-list';
import { calculateRouteTotalCharge } from './route-card.utils';
import TrafficJamIcon from '../../../icons/traffic_jam.svg?react';
const CardContentSpecialPadding = styled(CardContent)(`
  padding: 10px;
   &:last-child {
    padding-top: 5px;
  }
`);

const RENDER_BATCH_SIZE = 3;
const ROW_HEIGHT = 25;

const NoStops = ({ routeUuid }: { readonly routeUuid: string }) => {
  const [markRouteAsRendered] = useDispatchStore(
    (state) => [state.markRouteAsRendered],
    shallow,
  );

  useEffect(() => {
    markRouteAsRendered(routeUuid);
  }, [markRouteAsRendered, routeUuid]);

  return (
    <Typography
      variant="caption"
      color="text.secondary"
      sx={{ textAlign: 'center' }}
    >
      No Stops
    </Typography>
  );
};

type RouteCardProps = RouteCardsColumnWidths & {
  readonly route: RouteFragment;
  readonly numberOfStops?: number;
  readonly width?: number;
  readonly selectMode?: boolean;
  readonly isMapView?: boolean;
  readonly idx?: number;
};

/**
 * Explaining the widths:
 * width - will be removed soon once migrating to new dispatch page, optional width passed in that can override saved widths, only used for old map
 * cardPixelWidth - effective pixel width
 * routeCardWidth - the width from the slider in the settings page, in the old dispatch page its in pixels but for new one it's in percentage
 *
 */

const RouteCard = ({
  route,
  numberOfStops = 0,
  width,
  selectMode = false,
  isMapView = false,
  columnWidths,
  setColumnWidths,
  idx,
}: RouteCardProps) => {
  const theme = useTheme();
  const isMac = useIsMac();
  const doneButtonRef = useRef<HTMLButtonElement>(null);
  const cardRef = useRef<HTMLDivElement | null>(null);
  let { width: cardPixelWidth } = useResizeDetector({
    refreshMode: 'debounce',
    targetRef: cardRef,
    refreshRate: 250,
  });
  const isCardOnScreen = useOnScreen(cardRef);
  cardPixelWidth ??= 0;
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const ffUseNewDispatchPage = useFeatureFlag(FeatureFlag.FF_NEW_DISPATCH_PAGE);
  const ffDispatchTrackParity = useFeatureFlag(
    FeatureFlag.FF_DISPATCH_TRACK_PARITY,
  );
  const { routeCardWidth } = useDispatchViewSettings();

  const { sendDispatchUserLocationEvent } = useMultiplayer();
  const userEditLocationsForRoute: DispatchUserLocationPayload[] =
    useMultiplayerStore((state) =>
      state.dispatchUserPresenceData
        .filter(
          (userPresenceData) =>
            userPresenceData.data.routeUuid === route.uuid &&
            userPresenceData.data.connectionId !== state.dispatchConnectionId,
        )
        .map((userPresenceData) => userPresenceData.data),
    );

  const [showRouteDateModal, setShowRouteDateModal] = useState<boolean>(false);
  const [showTextDriversModal, setShowTextDriversModal] =
    useState<boolean>(false);
  const [showPrintManifestModal, setShowPrintManifestModal] =
    useState<boolean>(false);
  const [
    showConfirmUnsignedPodsDownloadModal,
    setShowConfirmUnsignedPodsDownloadModal,
  ] = useState<boolean>(false);
  const [ordersWithoutUnsignedPods, setOrdersWithoutUnsignedPods] = useState<
    OrderForDocumentAttachmentsDownloadFragment[]
  >([]);
  const [showOptimizeRouteModal, setShowOptimizeRouteModal] =
    useState<boolean>(false);
  const [showDeleteRouteModal, setShowDeleteRouteModal] =
    useState<boolean>(false);
  const mapRouteColor = useMapRouteColor({ routeUuid: route.uuid });
  const [
    hidden,
    selectingRoutes,
    planningDate,
    isSelected,
    arrangeStopsAndRoutesVertically,
    shouldRenderStops,
  ] = useDispatchStore(
    (state) => [
      state.filteredRoutes?.every((r) => r.uuid !== route.uuid) === true,
      state.selectingRoutes,
      state.planningDate,
      isMapView
        ? state.selectedMapRouteUuids.includes(route.uuid)
        : state.selectedRouteUuids.includes(route.uuid),
      state.arrangeStopsAndRoutesVertically && !state.showMap,
      (!state.routeUuidsLoadingStops.includes(route.uuid) ||
        state.routeUuidsLoadingStops
          .slice(0, RENDER_BATCH_SIZE) // render in batches to avoid hogging event loop, we remove the routes off the queue once rendered
          .includes(route.uuid)) &&
        !state.unrenderAllStops,
    ],
    shallow,
  );

  const [
    selectRouteUuid,
    selectMapRouteUuid,
    deselectRouteUuid,
    deselectMapRouteUuid,
  ] = useDispatchStore(
    (state) => [
      state.selectRouteUuid,
      state.selectMapRouteUuid,
      state.deselectRouteUuid,
      state.deselectMapRouteUuid,
    ],
    shallow,
  );
  const { unlockRoute, deleteRoute } = useRouteActions();

  useEffect(() => {
    if (selectingRoutes || planningDate) {
      setIsEditing(false);
      setIsHovering(false);
    }
  }, [selectingRoutes, planningDate]);

  const routeTimeString = useMemo(() => {
    return getRouteTotalTimeString(route.totalTimeInSeconds);
  }, [route.totalTimeInSeconds]);

  const linkedStopIndicesOutOfOrder = useMemo(() => {
    return getLinkedStopIndicesOutOfOrder(route);
  }, [route]);

  const stopIndicesWithoutAddresses = filterNotNil(
    route.slots.map((slot, i) => {
      const address = slot.stops[0]?.address;
      if (isNil(address?.latitude) || isNil(address?.longitude)) {
        return i;
      }
      return null;
    }),
  );

  const [selectedDriverUuid, setSelectedDriverUuid] = useState<string | null>(
    route.drivers[0]?.uuid ?? null,
  );
  const isAssignedToAgent = !isNil(route.drivers[0]?.agentId);

  const closeEditing = async () => {
    setIsEditing(false);
    await sendDispatchUserLocationEvent({
      action: DispatchMultiplayerAction.LEAVE,
    });
  };

  const [updateRouteDrivers] = useUpdateRouteDriversMutation({
    refetchQueries: [
      RoutesDocument,
      NumberOfStopsDocument,
      DriversWithRoutesDocument,
    ],
    onCompleted: async () => {
      await closeEditing();
    },
  });

  const onDone = async () => {
    // eslint-disable-next-line unicorn/prefer-ternary
    if (route.drivers[0]?.uuid === selectedDriverUuid) {
      await closeEditing();
    } else {
      await updateRouteDrivers({
        variables: {
          updateRouteDriversInput: {
            uuid: route.uuid,
            driverUuids: isNil(selectedDriverUuid) ? [] : [selectedDriverUuid],
          },
        },
      });
    }
  };

  // When route drivers change, set the selected driver to the first driver. We do this to ensure that the selected driver is always the first driver.
  // TODO: Nolan to fix
  useEffect(() => {
    setSelectedDriverUuid(route.drivers[0]?.uuid ?? null);
  }, [route.drivers, setSelectedDriverUuid]);

  const cardHeaderBackgroundColor = useMemo(() => {
    if (isMapView) {
      return isSelected ? mapRouteColor : theme.palette.background.default;
    }
    if (isSelected) {
      return theme.palette.primary.main;
    }
    if (isEditing) {
      return theme.palette.primary.light;
    }
    return theme.palette.background.default;
  }, [isSelected, isEditing, mapRouteColor, isMapView, theme]);

  const cardHeaderForegroundColor = chooseForegroundColor(
    cardHeaderBackgroundColor,
  );

  const { routeInfo } = route;

  const cardWidth =
    routeCardWidth === MAX_CARD_WIDTH && (ffDispatchTrackParity || !isMapView)
      ? '100%'
      : (width ?? routeCardWidth);

  const allFieldsInOneRow = cardPixelWidth > 1100;
  const routeStatsInSeparateRow = cardPixelWidth <= 390;

  const stopsData = useStopsOnRoute({ route });
  const selectRoute = isMapView ? selectMapRouteUuid : selectRouteUuid;
  const deselectRoute = isMapView ? deselectMapRouteUuid : deselectRouteUuid;

  const routeTotalCharge = calculateRouteTotalCharge({ stops: stopsData });

  const tableHeight =
    Math.max(
      Math.min(numberOfStops, stopsData.length) * ROW_HEIGHT + ROW_HEIGHT,
      ROW_HEIGHT * 2.5,
    ) + (isMac ? 0 : ROW_HEIGHT);

  const routeSelectCheckbox = (
    <Checkbox
      sx={{ p: 0 }}
      style={{
        color: isSelected ? 'white' : undefined,
      }}
      size="small"
      checked={isSelected}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onChange={(e) => {
        if (e.target.checked) {
          selectRoute(route.uuid);
        } else {
          deselectRoute(route.uuid);
        }
      }}
    />
  );

  const routeLockedButton = (
    <Tooltip title="This route is locked. Click to unlock">
      <IconButton
        size="small"
        onClick={async () => {
          await unlockRoute(route.uuid);
        }}
      >
        <LockIcon sx={{ fontSize: '16px' }} />
      </IconButton>
    </Tooltip>
  );

  const routeStats = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="right"
      spacing={0.5}
    >
      <Typography variant="caption">
        {route.routeInfo.pieceCount} pcs
      </Typography>
      <Divider flexItem orientation="vertical" />
      <Typography variant="caption">
        {round(route.routeInfo.totalWeight, 1)} lbs
      </Typography>
      <Divider flexItem orientation="vertical" />
      <Typography variant="caption">
        {isNil(route.totalDistanceInMeters)
          ? '-'
          : convertMetersToMiles(route.totalDistanceInMeters).toFixed(1)}{' '}
        mi
      </Typography>
      <Divider flexItem orientation="vertical" />
      <Typography variant="caption">{`$${routeTotalCharge}`}</Typography>
    </Stack>
  );

  const doneButton = (
    <Button
      ref={doneButtonRef}
      size="small"
      sx={{
        p: 0,
        borderRadius: 1,
        color: isSelected
          ? chooseForegroundColor(
              cardHeaderBackgroundColor,
              theme.palette.primary.main,
            )
          : undefined,
      }}
      onClick={onDone}
    >
      Done
    </Button>
  );

  const openModalsActions: OpenModalsActions = useMemo(
    () => ({
      setShowDeleteRouteModal,
      setShowRouteDateModal,
      setShowTextDriversModal,
      setShowPrintManifestModal,
      setShowConfirmUnsignedPodsDownloadModal,
      setOrdersWithoutUnsignedPods,
      setShowOptimizeRouteModal,
    }),
    [],
  );

  const memodRoute = useMemo(() => [route], [route]);

  // Modals are rendered from this component so that they don't disappear when
  // the hover menu loses focus (when the user mouses out of the window) and closes
  const contextMenuModals = (
    <>
      {showDeleteRouteModal && (
        <DeleteRoutesModal
          open={showDeleteRouteModal}
          setOpen={setShowDeleteRouteModal}
          routeUuids={[route.uuid]}
          onDeleteRoute={async () => {
            await deleteRoute(route.uuid);
          }}
        />
      )}
      {showRouteDateModal && (
        <RouteDateModal
          open={showRouteDateModal}
          setOpen={setShowRouteDateModal}
          routeUuid={route.uuid}
          currentDate={route.date}
        />
      )}
      {showTextDriversModal && (
        <TextDriversModal
          open={showTextDriversModal}
          setOpen={setShowTextDriversModal}
          routes={memodRoute}
        />
      )}
      {showPrintManifestModal && (
        <PrintManifestModal
          open={showPrintManifestModal}
          setOpen={setShowPrintManifestModal}
          routes={memodRoute}
        />
      )}
      {showConfirmUnsignedPodsDownloadModal && (
        <ConfirmUnsignedPodsDownloadModal
          open={showConfirmUnsignedPodsDownloadModal}
          setOpen={setShowConfirmUnsignedPodsDownloadModal}
          routes={memodRoute}
          ordersWithoutUnsignedPods={ordersWithoutUnsignedPods}
        />
      )}
      {showOptimizeRouteModal && (
        <OptimizeRouteModal
          open={showOptimizeRouteModal}
          setOpen={setShowOptimizeRouteModal}
          route={route}
        />
      )}
    </>
  );

  return (
    <Card
      ref={cardRef}
      className="route-card"
      id={route.uuid}
      variant="outlined"
      style={{
        width: cardWidth,
        borderColor:
          isSelected && selectMode ? theme.palette.primary.main : undefined,
        borderTopLeftRadius: isEmpty(userEditLocationsForRoute) ? undefined : 0,
        display: hidden ? 'none' : undefined,
      }}
      data-testid={`${DISPATCH_PAGE_ROUTE_CARD_TEST_ID_PREFIX}-${idx}`}
    >
      {contextMenuModals}
      <UserEditingPopup
        anchorRef={cardRef}
        userEditLocations={userEditLocationsForRoute}
        backgroundColor={isSelected ? 'white' : cardHeaderBackgroundColor}
      />
      <Box
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
      >
        {!isEditing && isHovering && !selectingRoutes && (
          <RouteCardHoverMenu
            anchorRef={cardRef}
            route={route}
            setIsEditing={setIsEditing}
            openModalsActions={openModalsActions}
            open={!isEditing && isHovering && !selectingRoutes}
            stops={stopsData}
          />
        )}
        <CardContent
          sx={{
            backgroundColor: cardHeaderBackgroundColor,
            py: 0.5,
            px: 1,
          }}
          onClick={(e) => {
            if (
              (e.target as Node).contains(doneButtonRef?.current) ||
              !selectMode
            )
              return;

            if (isSelected) {
              deselectRoute(route.uuid);
            } else {
              selectRoute(route.uuid);
            }
          }}
        >
          {allFieldsInOneRow ? (
            <Grid container>
              <Grid item md={8}>
                <Stack
                  direction={isEditing ? 'column' : 'row'}
                  alignItems="center"
                  spacing={2}
                >
                  {isAssignedToAgent && (
                    <TrafficJamIcon color={theme.palette.airfreightBlue[60]} />
                  )}
                  {selectMode && routeSelectCheckbox}
                  {route.locked && routeLockedButton}
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{ width: '100%' }}
                  >
                    <RouteNameField
                      color={cardHeaderForegroundColor}
                      route={route}
                      forceEdit={isEditing}
                      onEnter={onDone}
                    />
                    <RouteDriverField
                      route={route}
                      isEditing={isEditing}
                      selectedDriverUuid={selectedDriverUuid}
                      setSelectedDriverUuid={setSelectedDriverUuid}
                    />
                    <RouteVehicleField
                      route={route}
                      forceEdit={isEditing}
                      arrangeStopsAndRoutesVertically={
                        arrangeStopsAndRoutesVertically
                      }
                    />
                    <RouteStartTimeField
                      route={route}
                      baseDate={planningDate}
                      forceEdit={isEditing}
                    />
                    <RouteLoadTimeField
                      isStart
                      route={route}
                      forceEdit={isEditing}
                    />
                    <RouteLoadTimeField
                      route={route}
                      isStart={false}
                      forceEdit={isEditing}
                    />
                    {isEditing ? (
                      <RouteTagsField route={route} />
                    ) : (
                      route.tags.map((tag) => (
                        <ColoredChip
                          key={tag.uuid}
                          size={Size.xs}
                          label={tag.value}
                          color={tag.color}
                        />
                      ))
                    )}
                  </Stack>
                </Stack>
              </Grid>
              <Grid item md={4}>
                <Stack direction="row" justifyContent="flex-end" spacing={1}>
                  {routeStats}
                  <RouteStatusChip routeInfo={routeInfo} />
                  {isEditing && doneButton}
                </Stack>
              </Grid>
            </Grid>
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                sx={{
                  maxWidth: cardPixelWidth * 0.5,
                }}
              >
                {isAssignedToAgent && (
                  <TrafficJamIcon color={theme.palette.airfreightBlue[60]} />
                )}
                {selectMode && routeSelectCheckbox}
                {route.locked && routeLockedButton}
                <RouteNameField
                  color={cardHeaderForegroundColor}
                  route={route}
                  forceEdit={isEditing}
                  onEnter={onDone}
                />
                {route.tags.map((t) => (
                  <ColoredChip
                    key={t.value}
                    dot
                    label={t.value}
                    color={t.color}
                  />
                ))}
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                sx={{ width: '100%' }}
                spacing={1}
              >
                {isEditing && doneButton}
                {!isEditing && <RouteStatusChip routeInfo={routeInfo} />}
              </Stack>
            </Stack>
          )}
        </CardContent>
        {((isMapView && isSelected) ||
          !isMapView ||
          !ffDispatchTrackParity) && (
          <CardContentSpecialPadding>
            <Grid container alignItems="center">
              {allFieldsInOneRow ? null : (
                <>
                  <Grid item xs={isEditing ? 12 : 7}>
                    <RouteDriverField
                      route={route}
                      isEditing={isEditing}
                      selectedDriverUuid={selectedDriverUuid}
                      setSelectedDriverUuid={setSelectedDriverUuid}
                    />
                  </Grid>
                  <Grid item xs={isEditing ? 12 : 5}>
                    <RouteVehicleField route={route} forceEdit={isEditing} />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      direction={isEditing ? 'column' : 'row'}
                      alignItems={isEditing ? undefined : 'center'}
                      justifyContent="space-between"
                    >
                      <Stack
                        direction={isEditing ? 'column' : 'row'}
                        alignItems={isEditing ? undefined : 'center'}
                        spacing={isEditing ? 1 : 0}
                      >
                        <RouteStartTimeField
                          route={route}
                          baseDate={planningDate}
                          forceEdit={isEditing}
                        />
                        <RouteLoadTimeField
                          isStart
                          route={route}
                          forceEdit={isEditing}
                        />
                        <RouteLoadTimeField
                          route={route}
                          isStart={false}
                          forceEdit={isEditing}
                        />
                        {isEditing && <RouteTagsField route={route} />}
                      </Stack>
                      <Stack direction="row" alignItems="center" gap={0.5}>
                        <Typography
                          variant="caption"
                          sx={{ color: theme.palette.grey[600] }}
                        >
                          Totals
                        </Typography>
                        {!routeStatsInSeparateRow && routeStats}
                        <Divider flexItem orientation="vertical" />
                        <Typography variant="caption">
                          {routeTimeString}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  {routeInfo.totalStopsFailed > 0 ? (
                    <Typography variant="caption" color={orange['600']}>
                      {routeInfo.totalStopsFailed} attempted
                    </Typography>
                  ) : (
                    <Box />
                  )}
                  {routeStatsInSeparateRow && routeStats}
                </Stack>
              </Grid>
              <Grid item xs={9} />
              {!isEmpty(linkedStopIndicesOutOfOrder) && (
                <Grid item xs={12}>
                  <RouteCardWarningText
                    text={`Stop pairs ${linkedStopIndicesOutOfOrder.map((value) => `(${value[0]}, ${value[1]})`)?.join(', ')} out of order`}
                  />
                </Grid>
              )}
              {!isEmpty(stopIndicesWithoutAddresses) && (
                <Grid item xs={12}>
                  <RouteCardWarningText
                    text={getStopsWithoutAddressesWarningText(
                      stopIndicesWithoutAddresses,
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </CardContentSpecialPadding>
        )}
      </Box>
      {numberOfStops > 0 && ((isMapView && isSelected) || !isMapView) && (
        <Stack>
          {ffUseNewDispatchPage ? (
            <Grid xs={12} id={`${route.uuid}-ag-grid-wrapper`}>
              {!isEmpty(stopsData) && (
                <div
                  className="unassigned-stops unassigned-stops-v2 ag-theme-material"
                  style={{
                    width: '100%',
                    marginTop: '0px',
                    paddingTop: '0px',
                    marginBottom: '0px',
                    height: tableHeight,
                  }}
                >
                  {isCardOnScreen ? (
                    <RouteCardStopsGrid
                      route={route}
                      stops={stopsData}
                      numberOfStops={numberOfStops}
                      columnWidths={columnWidths}
                      setColumnWidths={setColumnWidths}
                      isMapView={isMapView}
                    />
                  ) : (
                    <CenteredCircularProgress size={12} />
                  )}
                </div>
              )}
              {isEmpty(stopsData) && (
                <div
                  style={{
                    textAlign: 'center',
                    height: tableHeight,
                    width: '100%',
                  }}
                >
                  {isCardOnScreen ? (
                    <NoStops routeUuid={route.uuid} />
                  ) : (
                    <CenteredCircularProgress size={12} />
                  )}
                </div>
              )}
            </Grid>
          ) : (
            <>
              <Divider />
              <Box sx={{ p: 1 }}>
                <RouteCardStopsList
                  route={route}
                  stops={stopsData}
                  numberOfStops={numberOfStops}
                />
              </Box>
            </>
          )}
        </Stack>
      )}
    </Card>
  );
};

export default memo(RouteCard, isEqual);
