import {
  Accordion,
  Typography,
  Stack,
  TableBody,
  TableHead,
  Table,
  AccordionDetails,
  TableContainer,
  Tooltip,
  TextField,
  AccordionSummary,
} from '@mui/material';
import { type SettlementBillLineItem } from '../types';
import { capitalCase, sentenceCase } from 'change-case';
import currency from 'currency.js';
import { CompactRow, SettlementTableCell } from './common';
import { isNil } from 'lodash';
import dayjs from 'dayjs';
import theme from '../../../theme';
import { useMemo } from 'react';
import AutocompleteFuzzy from '../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type SettlementPreviewCardV2Props = {
  // Settlement bill line items for a single driver
  readonly settlementBillLineItems: SettlementBillLineItem[];
  readonly existingSettlementBills: Array<{
    uuid: string;
    name: string | null | undefined;
  }>;
  readonly selectedSettlementBillUuid: string | null | undefined;
  readonly onSettlementBillSelect: (selection: string | null) => void;
};

const SettlementPreviewCardV2 = ({
  settlementBillLineItems,
  existingSettlementBills,
  selectedSettlementBillUuid,
  onSettlementBillSelect,
}: SettlementPreviewCardV2Props) => {
  const driver = settlementBillLineItems[0]?.driver;

  const autocompleteOptions = useMemo(
    () => [
      { label: 'Create new', value: null },
      ...existingSettlementBills.map((settlementBill) => ({
        label: settlementBill.name,
        value: settlementBill.uuid,
      })),
    ],
    [existingSettlementBills],
  );

  const selectedValue = useMemo(() => {
    if (isNil(selectedSettlementBillUuid)) {
      return { label: 'Create new settlement bill', value: null };
    }

    return (
      existingSettlementBills
        .map((bill) => ({
          label: bill.name,
          value: bill.uuid,
        }))
        .find((option) => option.value === selectedSettlementBillUuid) ?? {
        label: 'Create new settlement bill',
        value: null,
      }
    );
  }, [existingSettlementBills, selectedSettlementBillUuid]);

  return (
    <Accordion
      defaultExpanded
      elevation={0}
      sx={{
        border: '1px solid',
        flex: 1,
        borderColor: theme.palette.borderColor.main,
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row" spacing={2} alignItems="center" sx={{ ml: 1 }}>
          <Stack>
            <Typography>{driver?.user.name}</Typography>
            <Typography color="text.secondary" variant="caption">
              {capitalCase(driver?.driverType ?? '')}
            </Typography>
          </Stack>
          <Stack
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <AutocompleteFuzzy
              disableClearable
              size="small"
              sx={{ backgroundColor: 'white', width: '250px' }}
              options={autocompleteOptions}
              value={selectedValue}
              matchSortOptions={{ keys: ['label'] }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              )}
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              onClick={(e) => {
                e.stopPropagation();
              }}
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              onChange={(_, options) => {
                onSettlementBillSelect(options?.value ?? null);
              }}
            />
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="column" sx={{ width: '100%' }} spacing={2}>
          <TableContainer sx={{ minWidth: 1200 }}>
            <Table aria-label="stop-driver-maps-table" size="small">
              <TableHead>
                <CompactRow>
                  <SettlementTableCell>Completed At</SettlementTableCell>
                  <SettlementTableCell>Driver</SettlementTableCell>
                  <SettlementTableCell>Customer</SettlementTableCell>
                  <SettlementTableCell>Stop Type</SettlementTableCell>
                  <SettlementTableCell>HAWB</SettlementTableCell>
                  <SettlementTableCell>Order</SettlementTableCell>
                  <SettlementTableCell>Ref #s</SettlementTableCell>
                  <SettlementTableCell>Total payout</SettlementTableCell>
                </CompactRow>
              </TableHead>
              <TableBody>
                {settlementBillLineItems.map((settlementBillLineItem) => {
                  const { shipment } = settlementBillLineItem;
                  const order = shipment?.order;
                  const stop = shipment?.legs[0]?.endStop;

                  const formattedCompletedAt = isNil(stop?.completedAt)
                    ? 'Incomplete'
                    : // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                      dayjs(stop?.completedAt).format('MM/DD/YY');
                  const refNumbers = order?.refNumbers.join(', ');

                  return (
                    <CompactRow key={settlementBillLineItem.id}>
                      <SettlementTableCell>
                        {formattedCompletedAt}
                      </SettlementTableCell>
                      <SettlementTableCell>
                        {settlementBillLineItem.driver?.user.name}
                      </SettlementTableCell>
                      <SettlementTableCell>
                        <Tooltip
                          title={order?.billingPartyContact?.displayName}
                        >
                          <span>{order?.billingPartyContact?.displayName}</span>
                        </Tooltip>
                      </SettlementTableCell>
                      <SettlementTableCell>
                        {sentenceCase(
                          settlementBillLineItem.shipment?.legs[0]?.endStop
                            ?.stopType ?? '-',
                        )}
                      </SettlementTableCell>
                      <SettlementTableCell>
                        <Tooltip
                          title={
                            order?.standardOrderFields
                              ?.shipperBillOfLadingNumber
                          }
                        >
                          <span>
                            {
                              order?.standardOrderFields
                                ?.shipperBillOfLadingNumber
                            }
                          </span>
                        </Tooltip>
                      </SettlementTableCell>
                      <SettlementTableCell>
                        {settlementBillLineItem.shipment?.order?.name}
                      </SettlementTableCell>
                      <SettlementTableCell>
                        <Tooltip title={refNumbers}>
                          <span>{refNumbers}</span>
                        </Tooltip>
                      </SettlementTableCell>
                      <SettlementTableCell>
                        {currency(
                          settlementBillLineItem.totalAmountUsdDollars ?? 0,
                        ).format()}
                      </SettlementTableCell>
                    </CompactRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default SettlementPreviewCardV2;
