export const QUOTE_RATING_DISCLAIMER =
  'Quoted prices are estimates and may change due to final dimensions, fuel costs, accessorials, and/or route adjustments.';

export const INVALID_QUOTE_RATING_ATTEMPT_MESSAGE =
  'We couldn’t quote this order. Try entering details for another order, or contact us.';

export const STALE_QUOTE_MESSAGE =
  'This quote is outdated. Please re-calculate this quote for a more accurate estimate.';

export enum QuoteFormStatus {
  Creating = 'creating',
  Quoted = 'quoted',
  Invalid = 'invalid',
  Stale = 'stale',
  MissingInfo = 'missing_info',
}

// TODO: Consolidate duplicate labels into a single state
export const QuoteFormStatusToLabel: Record<QuoteFormStatus, string> = {
  [QuoteFormStatus.Creating]: 'Creating...',
  [QuoteFormStatus.Quoted]: 'Quoted',
  [QuoteFormStatus.Invalid]: 'Invalid quote',
  [QuoteFormStatus.Stale]: 'Quoted',
  [QuoteFormStatus.MissingInfo]: 'Creating...',
};
