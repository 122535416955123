import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { isNil, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { BillingMethod } from '../../../common/types';
import {
  DriverSettlementBillByUuidV2Document,
  DriverSettlementBillsV2Document,
  SettlementAdjustmentType,
  useCreateSettlementAdjustmentMutation,
  useUpdateSettlementAdjustmentMutation,
  type SettlementAdjustmentFragment,
} from '../../../generated/graphql';
import { safeDivide } from 'shared/math';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import { shallow } from 'zustand/shallow';

type ModalState =
  | { state: 'create'; settlementBillUuid: string }
  | {
      state: 'update';
      adjustment: SettlementAdjustmentFragment;
    };

const CreateOrUpdateAdjustmentModal = ({
  modalState,
  isOpen,
  onClose,
}: {
  readonly modalState: ModalState;
  readonly isOpen: boolean;
  readonly onClose: () => void;
}) => {
  const [setErrorMessage, setShowErrorMessage] = useGlobalStore(
    (state) => [state.setErrorMessage, state.setShowErrorMessage],
    shallow,
  );

  const [adjustmentType, setAdjustmentType] =
    useState<SettlementAdjustmentType>(SettlementAdjustmentType.Adjustment);
  const [name, setName] = useState<string>('');
  const [amount, setAmount] = useState<number>(0);
  const [rateType, setRateType] = useState<BillingMethod>(
    BillingMethod.FlatRate,
  );
  const [updateSettlementAdjustment] = useUpdateSettlementAdjustmentMutation({
    onError: () => {
      setErrorMessage('Error updating adjustment');
      setShowErrorMessage(true);
    },
  });
  const [createSettlementAdjustment] = useCreateSettlementAdjustmentMutation({
    onError: () => {
      setErrorMessage('Error creating adjustment');
      setShowErrorMessage(true);
    },
  });

  const save = async () => {
    switch (modalState.state) {
      case 'update': {
        await updateSettlementAdjustment({
          variables: {
            settlementAdjustmentUpdateInput: {
              uuid: modalState.adjustment.uuid,
              adjustmentType,
              name,
              flatRate: rateType === BillingMethod.FlatRate ? amount : null,
              percentageDecimalRate:
                rateType === BillingMethod.Percentage
                  ? safeDivide(amount, 100)
                  : null,
            },
          },
          refetchQueries: [
            DriverSettlementBillsV2Document,
            DriverSettlementBillByUuidV2Document,
          ],
        });
        onClose();
        break;
      }
      case 'create': {
        await createSettlementAdjustment({
          variables: {
            settlementAdjustmentCreateInput: {
              driverSettlementBillUuid: modalState.settlementBillUuid,
              adjustmentType,
              name,
              flatRate: rateType === BillingMethod.FlatRate ? amount : null,
              percentageDecimalRate:
                rateType === BillingMethod.Percentage
                  ? safeDivide(amount, 100)
                  : null,
            },
          },
          refetchQueries: [
            DriverSettlementBillsV2Document,
            DriverSettlementBillByUuidV2Document,
          ],
        });
        onClose();
        break;
      }
    }
  };

  useEffect(() => {
    if (modalState.state === 'update') {
      // `adjustmentType` will never be null. It is only made nullable
      // in the Graphql type to maintain backwards compatibility.
      setAdjustmentType(modalState.adjustment.adjustmentType);
      setName(modalState.adjustment.name);
      if (!isNil(modalState.adjustment.flatRate)) {
        setAmount(modalState.adjustment.flatRate);
        setRateType(BillingMethod.FlatRate);
      }
      if (!isNil(modalState.adjustment.percentageDecimalRate)) {
        setAmount(modalState.adjustment.percentageDecimalRate * 100);
        setRateType(BillingMethod.Percentage);
      }
    }
    if (modalState.state === 'create') {
      setAdjustmentType(SettlementAdjustmentType.Adjustment);
      setName('');
      setAmount(0);
      setRateType(BillingMethod.FlatRate);
    }
  }, [modalState]);

  if (!isOpen) return null;

  return (
    <Stack
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <Stack
        sx={{
          backgroundColor: 'white',
          borderRadius: '6px',
          padding: '20px',
          width: '350px',
          maxWidth: '90%',
        }}
        spacing={2}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ fontSize: '24px' }} />
          </IconButton>
          <Typography variant="h6">
            {modalState.state === 'create'
              ? 'Add Adjustment'
              : 'Edit Adjustment'}
          </Typography>
          <Button
            variant="contained"
            size="small"
            disabled={isEmpty(name)}
            onClick={save}
          >
            Save
          </Button>
        </Stack>

        <FormControl fullWidth size="small">
          <InputLabel id="adjustment-type-label">Adjustment type</InputLabel>
          <Select
            labelId="adjustment-type-label"
            label="Adjustment type"
            value={adjustmentType}
            onChange={(e) => {
              setAdjustmentType(e.target.value as SettlementAdjustmentType);
            }}
          >
            <MenuItem value={SettlementAdjustmentType.Adjustment}>
              Adjustment (+)
            </MenuItem>
            <MenuItem value={SettlementAdjustmentType.Deduction}>
              Deduction (-)
            </MenuItem>
          </Select>
        </FormControl>

        <Stack spacing={1}>
          <TextField
            fullWidth
            required
            label="Name"
            value={name}
            size="small"
            inputProps={{ maxLength: 100 }}
            helperText={`${name.length}/100 characters`}
            error={name.length === 0}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <TextField
              fullWidth
              label="Amount"
              type="number"
              value={amount}
              size="small"
              InputProps={
                rateType === BillingMethod.FlatRate
                  ? {
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }
                  : {
                      endAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }
              }
              onChange={(e) => {
                const value = Number(e.target.value);
                if (value >= 0 && value <= 9_999_999) {
                  setAmount(value);
                }
              }}
            />
          </Stack>

          <FormControl fullWidth size="small">
            <InputLabel id="rate-type-label">Rate Type</InputLabel>
            <Select
              labelId="rate-type-label"
              label="Rate Type"
              value={rateType}
              onChange={(e) => {
                setRateType(e.target.value as BillingMethod);
              }}
            >
              <MenuItem value={BillingMethod.FlatRate}>Flat rate</MenuItem>
              <MenuItem value={BillingMethod.Percentage}>Percentage</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CreateOrUpdateAdjustmentModal;
