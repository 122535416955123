import { TextField } from '@mui/material';
import { isNil } from 'lodash';
import { type Option } from '../../../common/filters/types';
import AutocompleteFuzzy from '../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { type FilterEditorProps } from '../types-v2';
import { BaseFilterEditorInputPropsSx } from './base-filter-editor-props';

type MultiSelectFilterEditorProps<TValue extends string> = FilterEditorProps<
  TValue[]
> & {
  readonly options: Array<Option<TValue>>;
};

const MultiSelectFilterEditor = <TValue extends string>({
  value,
  onChange,
  options,
}: MultiSelectFilterEditorProps<TValue>) => {
  return (
    <AutocompleteFuzzy<Option<TValue>, /* Multiple = */ true>
      multiple
      sx={{ backgroundColor: 'white' }}
      value={
        isNil(value)
          ? []
          : options.filter((option) => value.includes(option.value))
      }
      options={options}
      matchSortOptions={{ keys: ['label'] }}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          placeholder="Values"
          InputProps={{
            ...params.InputProps,
            sx: {
              ...BaseFilterEditorInputPropsSx,
              marginLeft: '-2px',
            },
          }}
        />
      )}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onChange={(_, options) => {
        onChange(options.map((option) => option.value));
      }}
    />
  );
};

const MultiSelectFilterEditorWithOptions = <TValue extends string>(
  options: Array<Option<TValue>>,
) => {
  return ({ value, onChange }: FilterEditorProps<TValue[]>) => {
    return (
      <MultiSelectFilterEditor
        value={value}
        options={options}
        onChange={onChange}
      />
    );
  };
};

export default MultiSelectFilterEditorWithOptions;
