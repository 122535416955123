import React, { useState } from 'react';
import ContactPageMode from '../../../contacts/components/contact-page-mode';
import PeoplePanel from '../../../contacts/components/people-panel';
import { Typography } from '@mui/material';

type PeopleTabConfigProps = {
  readonly organizationUuid: string;
};

const PeopleTabConfig = ({ organizationUuid }: PeopleTabConfigProps) => {
  const [mode, setMode] = useState<ContactPageMode>(ContactPageMode.VIEW);

  return (
    <>
      <Typography variant="h6">Order contacts</Typography>
      <PeoplePanel
        contactUuid={organizationUuid}
        mode={mode}
        handleChangeMode={setMode}
      />
    </>
  );
};

export default PeopleTabConfig;
