import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { isNil, sum, truncate, uniqBy } from 'lodash';
import { safeMultiply } from 'shared/math';
import { type DateOption } from '../../../../../common/components/date-dropdown-picker';
import { type Option } from '../../../../../common/filters/types';
import {
  type CompanyFragment,
  type PaymentForPaymentApplicationReportFragment,
  type PaymentRail,
  PaymentType,
} from '../../../../../generated/graphql';
import { formatPaymentRail } from '../../customers/utils';
import { formatDateOption } from './utils';

const TEXT_PADDING = '3px';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    paddingLeft: 23,
    paddingRight: 23,
    fontFamily: 'Roboto',
  },
  header1: {
    fontSize: '24px',
  },
  header2: {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  rowWithSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f0eded',
    paddingVertical: 4,
    fontSize: '9px',
  },
  paymentsTable: {
    width: '100%',
    borderBottom: '2px solid #CCC',
    paddingVertical: 3,
  },
  paymentsTableRow: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1px solid #EEE',
    paddingTop: 2,
    paddingBottom: 2,
    justifyContent: 'space-between',
    fontSize: '8px',
    width: '100%',
    textAlign: 'center',
  },
  paymentsTableHeader: {
    borderTop: 'none',
    fontWeight: 'bold',
    borderBottom: '1px solid black',
    fontSize: '9px',
  },
  invoicesTableHeader: {
    borderTop: 'none',
    fontWeight: 'bold',
    borderBottom: '1px solid grey',
  },
  invoicesTable: {
    paddingLeft: 5,
  },
  paymentsTableRowLeftSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '70%',
    textAlign: 'left',
  },
  paymentsTableRowRightSection: {
    display: 'flex',
    flexDirection: 'row',
    width: '30%',
    textAlign: 'right',
  },
  dateCol: {
    width: '15%',
    paddingRight: TEXT_PADDING,
  },
  referenceInfoCol: {
    width: '20%',
    paddingRight: TEXT_PADDING,
  },
  journalNumCol: {
    width: '15%',
    paddingRight: TEXT_PADDING,
  },
  customerCol: {
    width: '50%',
    paddingRight: TEXT_PADDING,
  },
  numberCol: {
    width: '33%',
    paddingRight: TEXT_PADDING,
  },
  totals: {
    fontSize: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: '5px',
    width: '100%',
  },
});

const CELL_MARGIN = '10px';
const PAGE_BLOCK_SIZE = '300px';

export type GeneratedPaymentApplicationReportProps = {
  readonly companyData: CompanyFragment | undefined;
  readonly terminalsEnabled: boolean;
  readonly dateOption: DateOption;
  readonly terminalOption: Option | undefined;
  readonly customerOption: Option | undefined;
  readonly payments: PaymentForPaymentApplicationReportFragment[];
  readonly paymentRail: PaymentRail | undefined;
};

const GeneratedPaymentApplicationReport = ({
  companyData,
  terminalsEnabled,
  dateOption,
  terminalOption,
  customerOption,
  payments,
  paymentRail,
}: GeneratedPaymentApplicationReportProps) => {
  const paymentsTransformed = payments
    .sort((a, b) => dayjs(a.paymentDate).diff(dayjs(b.paymentDate)))
    .map((payment) =>
      payment.paymentType === PaymentType.Debit
        ? {
            ...payment,
            totalAmount: safeMultiply(payment.totalAmount, -1),
            unappliedAmount: safeMultiply(payment.unappliedAmount, -1),
            orders: payment.orders.map((order) => ({
              ...order,
              appliedAmount: safeMultiply(order.appliedAmount, -1),
            })),
          }
        : payment,
    );

  const total = sum(paymentsTransformed.map((payment) => payment.totalAmount));
  const totalUnapplied = sum(
    paymentsTransformed.map((payment) => payment.unappliedAmount),
  );

  return (
    <Document title="Payment Application Report (Summary)">
      <Page size="LETTER" style={styles.page}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            fontSize: '12px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Text style={styles.header1}>
            Payment Application Report (Summary)
          </Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '10px',
            fontSize: '12px',
          }}
        >
          <View>
            <Text style={styles.header2}>{companyData?.name}</Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: CELL_MARGIN,
              justifyContent: 'space-between',
            }}
          >
            <View style={{ display: 'flex', flexDirection: 'column' }}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: PAGE_BLOCK_SIZE,
                }}
              >
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={{ fontWeight: 'bold' }}>Date</Text>
                  <Text style={{ marginLeft: '10px' }}>
                    {formatDateOption(dateOption)}
                  </Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={{ fontWeight: 'bold' }}>Payment method</Text>
                  <Text style={{ marginLeft: '10px' }}>
                    {isNil(paymentRail)
                      ? 'All'
                      : formatPaymentRail(paymentRail)}
                  </Text>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={{ fontWeight: 'bold' }}>Customer</Text>
                  <Text style={{ marginLeft: '10px' }}>
                    {isNil(customerOption) ? 'All' : customerOption.label}
                  </Text>
                </View>
                {terminalsEnabled && (
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text style={{ fontWeight: 'bold' }}>Terminal</Text>
                    <Text style={{ marginLeft: '10px' }}>
                      {isNil(terminalOption) ? 'All' : terminalOption.label}
                    </Text>
                  </View>
                )}
              </View>
            </View>
          </View>
          <View
            style={{
              marginTop: '10px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {paymentsTransformed.map((payment) => {
              const invoices = uniqBy(
                payment.orders.map((order) => ({
                  uuid: order.invoiceUuid,
                  name: order.invoiceName,
                  journalNumber: order.invoiceJournalNumber,
                  date: order.invoiceDate,
                  total: order.invoiceTotal,
                  balance: order.invoiceBalance,
                  contactDisplayName: order.invoiceContactDisplayName,
                  appliedAmount: payment.orders.reduce(
                    (prev, o) =>
                      o.invoiceUuid === order.invoiceUuid
                        ? prev + o.appliedAmount
                        : prev,
                    0,
                  ),
                })),
                'uuid',
              ).filter((invoice) => !isNil(invoice.uuid));

              return (
                <View key={payment.paymentUuid} style={styles.paymentsTable}>
                  <View
                    style={[
                      styles.paymentsTableRow,
                      styles.paymentsTableHeader,
                    ]}
                  >
                    <View style={styles.paymentsTableRowLeftSection}>
                      <Text style={styles.dateCol}>Pmt Date</Text>
                      <Text style={styles.referenceInfoCol}>Ref No</Text>
                      <Text style={styles.journalNumCol}>Method</Text>
                      <Text style={styles.customerCol}>Comment</Text>
                    </View>
                    <View style={styles.paymentsTableRowRightSection}>
                      <Text style={styles.numberCol}>Total</Text>
                      <Text style={styles.numberCol}>Unapplied</Text>
                      <Text style={styles.numberCol}>Applied</Text>
                    </View>
                  </View>
                  <View style={[styles.paymentsTableRow, styles.sectionHeader]}>
                    <View style={styles.paymentsTableRowLeftSection}>
                      <Text style={styles.dateCol}>
                        {dayjs(payment.paymentDate).format('MM/DD/YY')}
                      </Text>
                      <Text style={styles.referenceInfoCol}>
                        {payment.referenceNumber}
                      </Text>
                      <Text style={styles.journalNumCol}>
                        {isNil(payment.paymentRail)
                          ? '-'
                          : formatPaymentRail(payment.paymentRail)}
                      </Text>
                      <Text style={styles.customerCol}>
                        {truncate(payment.comment ?? '', { length: 45 })}
                      </Text>
                    </View>
                    <View style={styles.paymentsTableRowRightSection}>
                      <Text style={styles.numberCol}>
                        {currency(payment.totalAmount).format()}
                      </Text>
                      <Text style={styles.numberCol}>
                        {currency(payment.unappliedAmount).format()}
                      </Text>
                      <Text style={styles.numberCol}>
                        {currency(payment.totalAmount)
                          .subtract(payment.unappliedAmount)
                          .format()}
                      </Text>
                    </View>
                  </View>
                  <View style={[styles.invoicesTable]}>
                    <View
                      style={[
                        styles.paymentsTableRow,
                        styles.invoicesTableHeader,
                      ]}
                    >
                      <View style={styles.paymentsTableRowLeftSection}>
                        <Text style={styles.dateCol}>Inv Date</Text>
                        <Text style={styles.referenceInfoCol}>Inv Name</Text>
                        <Text style={styles.journalNumCol}>Journal No</Text>
                        <Text style={styles.customerCol}>Customer</Text>
                      </View>
                      <View style={styles.paymentsTableRowRightSection}>
                        <Text style={styles.numberCol}>Total</Text>
                        <Text style={styles.numberCol}>Bal</Text>
                        <Text style={styles.numberCol}>Applied</Text>
                      </View>
                    </View>
                    {invoices.map((invoice) => {
                      return (
                        <View
                          key={invoice.uuid}
                          style={styles.paymentsTableRow}
                        >
                          <View style={styles.paymentsTableRowLeftSection}>
                            <Text style={styles.dateCol}>
                              {dayjs(invoice.date).format('MM/DD/YY')}
                            </Text>
                            <Text style={styles.referenceInfoCol}>
                              {invoice.name}
                            </Text>
                            <Text style={styles.journalNumCol}>
                              {invoice.journalNumber}
                            </Text>
                            <Text style={styles.customerCol}>
                              {truncate(invoice.contactDisplayName ?? '', {
                                length: 45,
                              })}
                            </Text>
                          </View>
                          <View style={styles.paymentsTableRowRightSection}>
                            <Text style={styles.numberCol}>
                              {currency(invoice.total ?? 0).format()}
                            </Text>
                            <Text style={styles.numberCol}>
                              {currency(invoice.balance ?? 0).format()}
                            </Text>
                            <Text style={styles.numberCol}>
                              {currency(invoice.appliedAmount).format()}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                  </View>
                </View>
              );
            })}
            <View
              wrap={false}
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: CELL_MARGIN,
                color: '#181818',
                borderTop: '1pt solid black',
                width: '100%',
              }}
            >
              <View style={[styles.totals]}>
                <Text style={{ fontWeight: 'bold', width: '100px' }}>
                  Payment Total:
                </Text>
                <Text style={{ width: '75px', textAlign: 'right' }}>
                  {currency(total).format()}
                </Text>
              </View>
              <View style={[styles.totals]}>
                <Text style={{ fontWeight: 'bold', width: '100px' }}>
                  Unapplied Total:
                </Text>
                <Text style={{ width: '75px', textAlign: 'right' }}>
                  {currency(totalUnapplied).format()}
                </Text>
              </View>
              <View style={[styles.totals]}>
                <Text style={{ fontWeight: 'bold', width: '100px' }}>
                  Applied Total:
                </Text>
                <Text style={{ width: '75px', textAlign: 'right' }}>
                  {currency(total).subtract(totalUnapplied).format()}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default GeneratedPaymentApplicationReport;
