import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, Stack } from '@mui/material';
import { isNil } from 'lodash';
import React, { type Dispatch, type SetStateAction, useState } from 'react';
import { useFormState } from 'react-hook-form';
import PalletButton from '../../../../../../pallet-ui/button/pallet-button';
import { type OrderFormValues } from '../../forms/types';
import { type OnSubmitParams } from '../../types';
import DeleteOrderModal from '../delete-order-modal';
import ContextMenu from '../right-sidebar/context-menu';
import PrintMenu from '../right-sidebar/print-menu';
import SaveButton from '../save-button';

const OrderActions = ({
  isEditMode,
  isDuplicate,
  recurringTemplate,
  onSubmit,
  deleteEnabled,
  setWasSubmitSuccessful,
  rebillMode,
  fromEdiReview,
}: {
  readonly isEditMode: boolean;
  readonly isDuplicate: boolean;
  readonly recurringTemplate: boolean;
  readonly onSubmit: (params: OnSubmitParams) => Promise<boolean>;
  readonly deleteEnabled: boolean;
  readonly setWasSubmitSuccessful: Dispatch<SetStateAction<boolean>>;
  readonly rebillMode?: boolean;
  readonly fromEdiReview?: boolean;
}) => {
  const { isSubmitting } = useFormState<OrderFormValues>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [printButtonAnchorEl, setPrintButtonAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [extrasButtonAnchorEl, setExtrasButtonAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  return (
    <>
      {!isNil(showDeleteModal) && (
        <DeleteOrderModal
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          setWasSubmitSuccessful={setWasSubmitSuccessful}
        />
      )}
      {!isNil(printButtonAnchorEl) && (
        <PrintMenu
          showPrintMenu={!isNil(printButtonAnchorEl)}
          buttonRef={printButtonAnchorEl}
          isEditMode={isEditMode}
          onClose={() => {
            setPrintButtonAnchorEl(null);
          }}
        />
      )}
      {!isNil(extrasButtonAnchorEl) && (
        <ContextMenu
          showContextMenu={!isNil(extrasButtonAnchorEl)}
          buttonRef={extrasButtonAnchorEl}
          onClose={() => {
            setExtrasButtonAnchorEl(null);
          }}
          onSubmit={onSubmit}
          onDelete={
            deleteEnabled
              ? () => {
                  setShowDeleteModal(true);
                }
              : undefined
          }
        />
      )}
      <Stack direction="row" gap={1}>
        <PalletButton
          variant="outlined"
          endIcon={<ArrowDropDownIcon />}
          loading={isSubmitting}
          onClick={(e) => {
            if (isEditMode) {
              setPrintButtonAnchorEl(e.currentTarget);
            } else {
              onSubmit({
                saveAndPrint: true,
                refetchOrderAfterSave: isDuplicate,
              });
            }
          }}
        >
          {isEditMode ? 'Print' : 'Save + Print'}
        </PalletButton>
        <SaveButton
          isEditMode={isEditMode}
          rebillMode={rebillMode}
          hideSaveButton={fromEdiReview}
          onSubmit={async (params) => {
            // Ignore the return value, onSubmit expects void
            await onSubmit(params);
          }}
        />
        {!recurringTemplate && (
          <IconButton
            size="small"
            onClick={(e) => {
              setExtrasButtonAnchorEl(e.currentTarget);
            }}
          >
            <MoreVertIcon />
          </IconButton>
        )}
      </Stack>
    </>
  );
};

export default React.memo(OrderActions);
