import {
  type LineHaulOrderFragment,
  type SegmentedLineHaulOrderFragment,
  type ShallowLineHaulSegmentFragment,
} from '../../generated/graphql';

export type LineHaulOrder = LineHaulOrderFragment & {
  upcomingLineHaulSegment?: ShallowLineHaulSegmentFragment;
};

export type SegmentedLineHaulOrder = SegmentedLineHaulOrderFragment & {
  upcomingLineHaulSegment?: ShallowLineHaulSegmentFragment;
  hasNoneOrCompletedInboundStop?: boolean;
  lastCompletedLineHaulSegment?: ShallowLineHaulSegmentFragment;
};

export enum LineHaulOrdersTab {
  All = '',
}
