import { Chip } from '@mui/material';
import { type LineHaulManifestStatus } from '../../../generated/graphql';
import { CHIP_COLORS } from './utils';

type ManifestStatusChipProps = {
  readonly status: LineHaulManifestStatus;
};
const ManifestStatusChip = ({ status }: ManifestStatusChipProps) => {
  return (
    <Chip
      size="small"
      sx={{
        backgroundColor: CHIP_COLORS[status],
        color: 'white',
        fontWeight: 400,
        minWidth: 80,
      }}
      label={status}
    />
  );
};

export default ManifestStatusChip;
