import { Box } from '@mui/material';
import { flatten, isNil, uniqBy } from 'lodash';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { filterNotNil } from 'shared/array';
import {
  type DocumentType,
  OrderWithPaperworkDocument,
  StopType,
  useCreateShipmentDocumentMutation,
  useDeleteDocumentMutation,
  useGenerateShipmentPreSignedPutUrlMutation,
} from '../../../../../../../generated/graphql';
import { type HandleNewDocumentParams } from '../../../../../../end-of-day/components/multiple-document-upload-modal';
import { type DocViewerDocument } from '../../../../../../end-of-day/types/doc-viewer-document';
import { useOrderFormEditAccess } from '../../../contexts/order-form-edit-access-context';
import { type DocumentValues, type StopValues } from '../../../forms/types';
import { INBOUND_STOP_IDX, OUTBOUND_STOP_IDX } from '../../constants';
import { OrderFormCardTitle } from '../../order-form-card-title';
import DocumentsList from './documents-list';

type DocumentsProps = {
  readonly isEditMode: boolean;
  readonly showHeader?: boolean;
};

const Documents = ({ isEditMode, showHeader = true }: DocumentsProps) => {
  const { control, setValue, getValues } = useFormContext();

  const [generateShipmentPreSignedPutUrl] =
    useGenerateShipmentPreSignedPutUrlMutation();
  const [deleteDocumentMutation] = useDeleteDocumentMutation({
    refetchQueries: [OrderWithPaperworkDocument],
  });
  const [addShipmentDocument] = useCreateShipmentDocumentMutation({
    refetchQueries: [OrderWithPaperworkDocument],
  });

  const { disabledIfNoAccess } = useOrderFormEditAccess();

  const stopsKey = 'stops';
  const documentsKey = 'documents';
  const documents = useWatch({ control, name: documentsKey });
  const stops: Array<NonNullable<StopValues>> = filterNotNil(
    useWatch({ control, name: stopsKey }) ?? [],
  );
  const allDocuments = uniqBy(
    filterNotNil(
      flatten(stops.map((stop) => stop.documents).concat(documents)),
    ),
    (d) => d.uuid,
  );

  const deleteDocument = async (documentId: string) => {
    const stopIdx = stops.findIndex(
      (stop) =>
        stop.documents?.findIndex((doc) => doc?.uuid === documentId) !== -1,
    );
    const documentArray = stops[stopIdx]?.documents;
    const stopDocumentsKey = `stops.${stopIdx}.documents`;
    if (!isNil(documentArray)) {
      /**
       * We have to filter out the deleted document in both the stop documents array as well as the order-level documents array.
       * TODO: @vidhur2k - We should refactor documents in the order form to only have one source of documents.
       */
      setValue(
        stopDocumentsKey,
        documentArray.filter((doc) => doc?.uuid !== documentId),
      );
      setValue(
        documentsKey,
        allDocuments.filter((doc) => doc.uuid !== documentId),
      );
    }

    await deleteDocumentMutation({
      variables: { uuid: documentId },
    });
  };

  let stopIdx = stops.findIndex(
    (stop) =>
      stop.stopType === StopType.Delivery || stop.stopType === StopType.Pickup,
  );
  if (stopIdx === -1) {
    stopIdx = stops.findIndex(
      (stop) =>
        stop.stopType === StopType.Recovery ||
        stop.stopType === StopType.Transfer,
    );
  }
  if (stopIdx === -1) {
    stopIdx = stops.findIndex(
      (stop) =>
        stop.stopType === StopType.PartnerCarrierDropoff ||
        stop.stopType === StopType.PartnerCarrierPickup,
    );
  }
  const stop = stops[stopIdx];
  const getShipmentAwsUrl = async (fileName: string, fileType: string) => {
    if (!isNil(stop)) {
      const res = await generateShipmentPreSignedPutUrl({
        variables: {
          generateShipmentPreSignedPutUrlInput: {
            fileName,
            fileType,
            shipmentUuid: stop.shipmentUuid,
          },
        },
      });
      const putUrl = res.data?.generateShipmentPreSignedPutUrl;
      return isNil(putUrl) ? undefined : { putUrl };
    }
  };

  const updateShipmentWithDocument = async ({
    fileName,
    documentType,
    name,
    fileType,
  }: HandleNewDocumentParams) => {
    const stopDocumentsKey = `stops.${stopIdx}.documents`;
    if (!isNil(stop)) {
      const res = await addShipmentDocument({
        variables: {
          createShipmentDocumentInput: {
            documentType,
            fileName,
            fileType,
            name,
            shipmentUuid: stop.shipmentUuid,
            isNewlyCreatedShipment: !isEditMode,
          },
        },
      });
      if (!isNil(res.data)) {
        const document: DocumentValues = {
          ...res.data.createShipmentDocument,
          name: res.data.createShipmentDocument.name ?? null,
          notes: res.data.createShipmentDocument.notes ?? null,
        };

        const stopDocuments = stops[stopIdx]?.documents;
        if (!isNil(stopDocuments))
          setValue(stopDocumentsKey, [...stopDocuments, document]);
      }
    }
  };

  const onSave = (
    documentType: DocumentType,
    existingDocument: DocViewerDocument,
    existingDocumentIndex: number,
  ) => {
    if (!isNil(existingDocumentIndex)) {
      const inboundDocuments: Array<{ uuid: string }> = getValues(
        `stops.${INBOUND_STOP_IDX}.documents`,
      );
      const outboundDocuments: Array<{ uuid: string }> = getValues(
        `stops.${OUTBOUND_STOP_IDX}.documents`,
      );

      const inboundIndex = inboundDocuments.findIndex(
        (doc) => doc.uuid === existingDocument.uuid,
      );
      const outboundIndex = outboundDocuments.findIndex(
        (doc) => doc.uuid === existingDocument.uuid,
      );

      const isInboundDocument = inboundIndex !== -1;
      const realIndex = isInboundDocument ? inboundIndex : outboundIndex;
      const stopIndex = isInboundDocument
        ? INBOUND_STOP_IDX
        : OUTBOUND_STOP_IDX;
      setValue(`stops.${stopIndex}.documents.${realIndex}.type`, documentType);
    }
  };

  return (
    <Box>
      {showHeader && <OrderFormCardTitle title="Files" />}
      <DocumentsList
        getAwsUrl={getShipmentAwsUrl}
        docs={filterNotNil(allDocuments).map((doc) => ({
          fileType: doc.fileType,
          fileName: doc.fileName,
          uuid: doc.uuid,
          preSignedGetUrl: doc.preSignedGetUrl,
          docType: doc.type,
          driverFormTemplateUuid: doc.driverFormTemplateUuid,
          notes: doc.notes,
        }))}
        uploadDisabled={disabledIfNoAccess}
        canModifyDocuments={!disabledIfNoAccess}
        onUploadDocument={updateShipmentWithDocument}
        onDeleteDocument={deleteDocument}
        onSave={onSave}
      />
    </Box>
  );
};

export default React.memo(Documents);
