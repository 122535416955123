import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { isNil } from 'lodash';
import { useMemo, useState } from 'react';
import { exhaustive } from 'shared/switch';
import useMe from '../../../../../common/react-hooks/use-me';
import {
  OrderConsolidationMethod,
  type OrderForConsolidationFlowFragment,
  type ProrateOrdersMutationVariables,
  useOrderInConsolidationFlowLazyQuery,
  useOrderInConsolidationFlowQuery,
} from '../../../../../generated/graphql';
import { OrderDialog } from '../../../../orders/components/order-dialog';
import useBillingReviewActions from '../../../hooks/use-billing-review-actions';
import ConsolidatedOrderInformation from './consolidated-order-information';
import ConsolidatedOrderLeftSidebar from './consolidated-order-left-sidebar';
import ConsolidationOptionsPanel, {
  type ChargesToConsolidate,
} from './consolidation-options-panel';
import ConfirmProrateModal from './confirm-prorate-modal';

const ConsolidateOrderFlow = ({
  initialOrderUuid,
  onClose,
}: {
  readonly initialOrderUuid: string;
  readonly onClose: ({
    orderUuidsToRefetch,
  }: {
    orderUuidsToRefetch: string[];
  }) => Promise<void>;
}) => {
  const [openedOrderUuid, setOpenedOrderUuid] = useState<string | null>(null);

  // a running list of which order uuids have been edited since opening the
  // consolidate flow. This can be used to trigger re-loading the previewed
  // prorate in the child and also to tell the billing review component
  // that it needs to refresh these items in cache.
  const [orderUuidsToRefresh, setOrderUuidsToRefresh] = useState<string[]>([]);
  const [selectedChargesToConsolidate, setSelectedChargesToConsolidate] =
    useState<Record<ChargesToConsolidate, boolean>>({
      INBOUND: false,
      OUTBOUND: false,
    });
  const theme = useTheme();
  const [searchedOrders, setSearchedOrders] = useState<
    OrderForConsolidationFlowFragment[]
  >([]);
  const [checkedNonPrimaryOrderUuids, setCheckedNonPrimaryOrderUuids] =
    useState<string[]>([]);

  const [disableConsolidateButton, setDisableConsolidateButton] =
    useState<boolean>(true);

  const [isClosingAfterProrate, setIsClosingAfterProrate] = useState(false);
  const [openConfirmProrateModal, setOpenConfirmProrateModal] = useState(false);

  const [consolidationMethod, setConsolidationMethod] =
    useState<OrderConsolidationMethod>(OrderConsolidationMethod.ByWeight);

  const [refetchOrder, { loading: orderFetchLoading }] =
    useOrderInConsolidationFlowLazyQuery();

  const { fetchOrderCacheFirst } = useBillingReviewActions();

  const handleCloseConsolidateFlow = async () => {
    setIsClosingAfterProrate(true);
    try {
      // callback to tell underlying BRM to refetch orders that were checked since charges would change.
      await onClose({
        orderUuidsToRefetch: [...checkedNonPrimaryOrderUuids, initialOrderUuid],
      });
    } finally {
      setIsClosingAfterProrate(false);
    }
  };

  const { companyConfiguration } = useMe();

  const {
    data: initialOrderData,
    loading: initialOrderLoading,
    refetch: refetchInitialOrder,
  } = useOrderInConsolidationFlowQuery({
    variables: { uuid: initialOrderUuid },
  });

  // when we want to refetch an order in the consolidate flow
  // we need to refetch it and make sure it gets set in the state variables
  // (like searchedOrders)
  const refetchOrderData = async (orderUuid: string) => {
    // this refetches the order in billing review so the cached
    // value is overwritten.
    fetchOrderCacheFirst({ orderUuid, bypassCache: true });

    if (orderUuid === initialOrderUuid) {
      await refetchInitialOrder();
    }
    // if the order opened was a searched one, refetch and set it in searched orders
    else if (searchedOrders.map((o) => o.uuid).includes(orderUuid)) {
      const res = await refetchOrder({
        variables: {
          uuid: orderUuid,
        },
      });

      const standardOrder = res.data?.standardOrder;
      if (!isNil(standardOrder)) {
        setSearchedOrders((searchedOrders_) =>
          searchedOrders_.map((o) => {
            if (o.uuid === openedOrderUuid) return standardOrder;
            return o;
          }),
        );
      }
    }
  };

  // when closing the order form, this callback is called
  const handleCloseOrderFormModal = async () => {
    if (!isNil(openedOrderUuid)) {
      // setOrderUuidsToRefresh tells the child component to refresh the prorating preview.
      setOrderUuidsToRefresh([...orderUuidsToRefresh, openedOrderUuid]);
      await refetchOrderData(openedOrderUuid);
    }
    setOpenedOrderUuid(null);
  };

  const checkedNonPrimaryOrders = useMemo(() => {
    return searchedOrders.filter(
      (o) => o?.uuid != null && checkedNonPrimaryOrderUuids.includes(o.uuid),
    );
  }, [checkedNonPrimaryOrderUuids, searchedOrders]);

  const handleClickConsolidate = () => {
    setOpenConfirmProrateModal(true);
  };

  const handleProrateSuccess = async () => {
    setOpenConfirmProrateModal(false);
    await handleCloseConsolidateFlow();
  };

  const prorateOrdersMutationVariables: ProrateOrdersMutationVariables = {
    input: {
      primaryOrderUuid: initialOrderUuid,
      ordersToConsolidateWithUuids: checkedNonPrimaryOrders.map((o) => o.uuid),
      shouldProrateInbound: selectedChargesToConsolidate?.INBOUND ?? false,
      shouldProrateOutbound: selectedChargesToConsolidate?.OUTBOUND ?? false,
      consolidationMethod,
    },
  };

  return (
    <>
      <OrderDialog
        open={!isNil(openedOrderUuid)}
        orderUuid={openedOrderUuid}
        onClose={handleCloseOrderFormModal}
      />
      {!isNil(initialOrderData?.standardOrder) && (
        <ConfirmProrateModal
          mutationVariables={prorateOrdersMutationVariables}
          open={openConfirmProrateModal}
          primaryOrder={initialOrderData?.standardOrder}
          otherOrders={checkedNonPrimaryOrders}
          customerName={
            initialOrderData?.standardOrder?.billingPartyContact.displayName
          }
          onProrateSuccess={handleProrateSuccess}
          onCancel={() => {
            setOpenConfirmProrateModal(false);
          }}
        />
      )}
      <Box flex={1} flexDirection="column" gap="10px" p="20px" overflow="auto">
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6" fontSize={18}>
            Consolidate{' '}
            {initialOrderData?.standardOrder?.billingPartyContact.displayName ??
              '-'}{' '}
            orders
          </Typography>
          <Stack direction="row" gap={2} pb={1}>
            <Button
              sx={{ color: theme.palette.grey[500] }}
              onClick={handleCloseConsolidateFlow}
            >
              Cancel
            </Button>
            <Button
              disabled={disableConsolidateButton || isClosingAfterProrate}
              variant="contained"
              onClick={handleClickConsolidate}
            >
              Consolidate
            </Button>
          </Stack>
        </Stack>
        <Stack direction="row" bgcolor={theme.palette.grey[200]}>
          {!isNil(initialOrderData?.standardOrder) && (
            <>
              {(initialOrderLoading || orderFetchLoading) && (
                <CircularProgress />
              )}
              {!initialOrderLoading &&
                !orderFetchLoading &&
                !isNil(initialOrderData?.standardOrder) && (
                  <Stack direction="column" flexGrow={1}>
                    <ConsolidatedOrderLeftSidebar
                      primaryOrder={initialOrderData?.standardOrder}
                      searchedOrders={searchedOrders}
                      setSearchedOrders={setSearchedOrders}
                      checkedOrderUuids={checkedNonPrimaryOrderUuids}
                      setCheckedOrderUuids={setCheckedNonPrimaryOrderUuids}
                      setOpenedOrderUuid={(uuid: string) => {
                        setOpenedOrderUuid(uuid);
                      }}
                      lineHaulEnabled={
                        companyConfiguration?.lineHaulEnabled ?? false
                      }
                    />
                    {!isNil(selectedChargesToConsolidate) &&
                      !isNil(selectedChargesToConsolidate) && (
                        <ConsolidationOptionsPanel
                          selectedChargesToConsolidate={
                            selectedChargesToConsolidate
                          }
                          setSelectedChargesToConsolidate={
                            setSelectedChargesToConsolidate
                          }
                          lineHaulEnabled={
                            companyConfiguration?.lineHaulEnabled ?? false
                          }
                          primaryOrder={initialOrderData?.standardOrder}
                          consolidationMethod={consolidationMethod}
                          setConsolidationMethod={setConsolidationMethod}
                        />
                      )}
                  </Stack>
                )}
              <Stack width="33%" padding={2} gap={2}>
                {!isNil(selectedChargesToConsolidate) && (
                  <ConsolidatedOrderInformation
                    consolidationMethod={consolidationMethod}
                    primaryOrderUuid={initialOrderUuid}
                    ordersToConsolidateWithUuids={checkedNonPrimaryOrders.map(
                      (o) => o.uuid,
                    )}
                    selectedChargesToConsolidate={selectedChargesToConsolidate}
                    orderUuidsToRefresh={orderUuidsToRefresh}
                    setDisableConsolidateButton={(disabled: boolean) => {
                      setDisableConsolidateButton(disabled);
                    }}
                  />
                )}
              </Stack>
            </>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default ConsolidateOrderFlow;
