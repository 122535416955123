export enum AppEnvironment {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
  RELEASE = 'release',
}

/**
 * Prefix environment variables with VITE_
 */
export const EnvironmentVariables = {
  VITE_BACKEND_URL: import.meta.env.VITE_BACKEND_URL,

  VITE_SLOW_BACKEND_URL: import.meta.env.VITE_SLOW_BACKEND_URL,

  VITE_DOCUMENT_GENERATION_BACKEND_URL: import.meta.env
    .VITE_DOCUMENT_GENERATION_BACKEND_URL,

  VITE_FRONTEND_URL: import.meta.env.VITE_FRONTEND_URL,

  VITE_APP_ENV: import.meta.env.VITE_APP_ENV,

  VITE_SMARTY_ACCESS_TOKEN: import.meta.env.VITE_SMARTY_ACCESS_TOKEN,

  VITE_MAPBOX_ACCESS_TOKEN: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,

  VITE_GOOGLE_MAPS_API_KEY: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,

  VITE_NEW_DATE_FILTERS: import.meta.env.VITE_NEW_DATE_FILTERS,

  VITE_RENDER_GIT_COMMIT: import.meta.env.VITE_RENDER_GIT_COMMIT,

  VITE_APRYSE_WEBVIEWER_LICENSE_KEY: import.meta.env
    .VITE_APRYSE_WEBVIEWER_LICENSE_KEY,

  RUM_REPORT_MEMORY_POLL_SECONDS: import.meta.env
    .RUM_REPORT_MEMORY_POLL_SECONDS,

  FEATURE_FLAG_POLL_INTERVAL_SECONDS: import.meta.env
    .FEATURE_FLAG_POLL_INTERVAL_SECONDS,

  VITE_STATSIG_API_KEY: import.meta.env.VITE_STATSIG_API_KEY,

  VITE_MUI_X_PRO_LICENSE_KEY: import.meta.env.VITE_MUI_X_PRO_LICENSE_KEY,

  VITE_SESSION_COOKIE_KEY: import.meta.env.VITE_SESSION_COOKIE_KEY,
};

export const isDevelopment = () => {
  return EnvironmentVariables.VITE_APP_ENV === AppEnvironment.DEVELOPMENT;
};

export const isStaging = () => {
  return EnvironmentVariables.VITE_APP_ENV === AppEnvironment.STAGING;
};

export const isProduction = () => {
  return EnvironmentVariables.VITE_APP_ENV === AppEnvironment.PRODUCTION;
};

export const isRelease = () => {
  return EnvironmentVariables.VITE_APP_ENV === AppEnvironment.RELEASE;
};
