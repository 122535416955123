import { sentenceCase } from 'change-case';
import { isNil, values } from 'lodash';
import { useMemo } from 'react';
import { exhaustive } from 'shared/switch';
import { shallow } from 'zustand/shallow';
import { type FilterConstructionFilterType } from '../../../../../common/filters/types';
import useHoldReasons from '../../../../../common/react-hooks/use-hold-reasons';
import useLineHaulLanes from '../../../../../common/react-hooks/use-line-haul-lanes';
import useTags from '../../../../../common/react-hooks/use-tags';
import type { Option } from '../../../../../common/types';
import {
  OrderSource,
  OrderStatus,
  StandardStopType,
  StopType,
  useBusinessDivisionsQuery,
} from '../../../../../generated/graphql';
import useGlobalStore from '../../../../../layouts/dashboard/global-store';
import { OrderFilterFieldV2 } from '../../../../orders/components/enums/order-filters';

const orderStatusOptions = values(OrderStatus).map((orderStatusOption) => ({
  label: sentenceCase(orderStatusOption),
  value: orderStatusOption,
}));

const orderSourceOptions = values(OrderSource).map((orderSourceOption) => ({
  label: sentenceCase(orderSourceOption),
  value: orderSourceOption,
}));

const addressTypeOptions = values(StandardStopType)
  .filter((o) => o !== StandardStopType.Dummy)
  .map((addressTypeOption) => ({
    label: sentenceCase(addressTypeOption),
    value: addressTypeOption,
  }));

const inboundStopTypeOptions = [
  StopType.Pickup,
  StopType.Recovery,
  StopType.PartnerCarrierDropoff,
].map((stopType) => ({
  label: sentenceCase(stopType),
  value: stopType.toString(),
}));

const outboundStopTypeOptions = [
  StopType.Delivery,
  StopType.Transfer,
  StopType.PartnerCarrierPickup,
].map((stopType) => ({
  label: sentenceCase(stopType),
  value: stopType.toString(),
}));

/**
 * Custom hook to generate filter options for the orders table. Accepts name of filter and returns an Option[]
 * @param filterType - name of filter
 */
const useFilterOptions = (
  filterType: FilterConstructionFilterType,
): Option[] | null => {
  const { drivers, customers, terminals, serviceLevels } = useGlobalStore(
    (state) => {
      return {
        drivers: state.drivers,
        customers: state.contacts,
        terminals: state.terminals,
        serviceLevels: state.services,
      };
    },
    shallow,
  );

  const serviceLevelOptions = useMemo(
    () =>
      serviceLevels.map((serviceLevel) => {
        return {
          label: serviceLevel.name,
          value: serviceLevel.name,
        };
      }),
    [serviceLevels],
  );
  const driverOptions = useMemo(
    () =>
      drivers.map((driver) => {
        const driverName = `${driver.firstName} ${driver.lastName}`;
        return {
          label: driverName,
          value: driverName,
        };
      }),
    [drivers],
  );

  const customerOptions = useMemo(
    () =>
      customers.map((customer) => ({
        label: customer.displayName,
        value: customer.displayName,
      })),
    [customers],
  );

  const terminalOptions = useMemo(
    () =>
      terminals.map((terminal) => ({
        label: terminal.code,
        value: terminal.code,
      })),
    [terminals],
  );

  const terminalUuidOptions = useMemo(
    () =>
      terminals.map((terminal) => ({
        label: terminal.code,
        value: terminal.uuid,
      })),
    [terminals],
  );

  const { data: businessDivisionsData } = useBusinessDivisionsQuery({
    fetchPolicy: 'cache-first',
  });

  const businessDivisions =
    businessDivisionsData?.businessDivisions.businessDivisions;
  const businessDivisionOptions = useMemo(
    () =>
      businessDivisions?.map((businessDivision) => ({
        label: businessDivision.name,
        value: businessDivision.name,
      })),
    [businessDivisions],
  );

  const { holdReasons } = useHoldReasons();
  const holdReasonOptions = useMemo(
    () =>
      holdReasons?.map((holdReason) => ({
        label: holdReason.name,
        value: holdReason.name,
      })),
    [holdReasons],
  );

  const { tags } = useTags();
  const tagOptions = useMemo(
    () =>
      tags?.map((tag) => ({
        label: tag.value,
        value: tag.value,
      })),
    [tags],
  );

  const { lanes } = useLineHaulLanes({
    includeInactiveTerminals: true,
  });
  const lineHaulLaneOptions = useMemo(
    () =>
      lanes?.map((lane) => ({
        value: lane.uuid,
        label: `${lane.name}${lane.isActive ? '' : ' (archived)'}`,
      })) ?? [],
    [lanes],
  );

  // New filters should be added here: null if they don't require options, or under the corresponding switch if they do
  const filterOptionMappings: Option[] | null = useMemo(() => {
    if (isNil(filterType)) {
      return null;
    }
    const typedFilterName: OrderFilterFieldV2 = OrderFilterFieldV2[filterType];
    switch (typedFilterName) {
      case OrderFilterFieldV2.ACTIVE_TERMINAL:
      case OrderFilterFieldV2.OUTBOUND_TERMINAL:
      case OrderFilterFieldV2.INBOUND_TERMINAL: {
        return terminalOptions;
      }
      case OrderFilterFieldV2.LAST_LINE_HAUL_TERMINAL:
      case OrderFilterFieldV2.LINE_HAUL_TERMINALS:
      case OrderFilterFieldV2.ON_HAND_TERMINAL: {
        return terminalUuidOptions;
      }
      case OrderFilterFieldV2.BUSINESS_DIVISION: {
        return businessDivisionOptions ?? [];
      }
      case OrderFilterFieldV2.CUSTOMER_NAME: {
        return customerOptions;
      }
      case OrderFilterFieldV2.INBOUND_DRIVER_NAME:
      case OrderFilterFieldV2.OUTBOUND_DRIVER_NAME: {
        return driverOptions;
      }
      case OrderFilterFieldV2.ORDER_SOURCE: {
        return orderSourceOptions;
      }
      case OrderFilterFieldV2.INBOUND_STOP_TYPE: {
        return inboundStopTypeOptions;
      }
      case OrderFilterFieldV2.OUTBOUND_STOP_TYPE: {
        return outboundStopTypeOptions;
      }
      case OrderFilterFieldV2.SERVICE_LEVEL: {
        return serviceLevelOptions;
      }
      case OrderFilterFieldV2.INBOUND_ADDRESS_TYPE:
      case OrderFilterFieldV2.OUTBOUND_ADDRESS_TYPE: {
        return addressTypeOptions;
      }
      case OrderFilterFieldV2.ORDER_STATUS: {
        return orderStatusOptions;
      }
      case OrderFilterFieldV2.HOLD_REASON: {
        return holdReasonOptions;
      }
      case OrderFilterFieldV2.TAGS: {
        return tagOptions;
      }
      case OrderFilterFieldV2.LINE_HAUL_LANE: {
        return lineHaulLaneOptions;
      }
      case OrderFilterFieldV2.ASSIGNED_TO_A_ROUTE:
      case OrderFilterFieldV2.COMPLETED_AT:
      case OrderFilterFieldV2.HAWB:
      case OrderFilterFieldV2.MAWB:
      case OrderFilterFieldV2.TOTAL_WEIGHT:
      case OrderFilterFieldV2.DIM_WEIGHT:
      case OrderFilterFieldV2.TOTAL_PIECES:
      case OrderFilterFieldV2.ORDER_NAME:
      case OrderFilterFieldV2.DATE_ATTEMPTED:
      case OrderFilterFieldV2.DATE_CREATED:
      case OrderFilterFieldV2.DATE_RECEIVED:
      case OrderFilterFieldV2.DEADLINE_DATE:
      case OrderFilterFieldV2.INBOUND_SERVICE_DATE:
      case OrderFilterFieldV2.OUTBOUND_SERVICE_DATE:
      case OrderFilterFieldV2.INBOUND_COMPLETED_DATE:
      case OrderFilterFieldV2.OUTBOUND_COMPLETED_DATE:
      case OrderFilterFieldV2.INBOUND_COMPLETED:
      case OrderFilterFieldV2.OUTBOUND_COMPLETED:
      case OrderFilterFieldV2.INVOICE_DATE:
      case OrderFilterFieldV2.IS_HAZMAT:
      case OrderFilterFieldV2.IS_IN_BOND:
      case OrderFilterFieldV2.IS_FINALIZED:
      case OrderFilterFieldV2.IS_PICKED:
      case OrderFilterFieldV2.IS_CANCELLED:
      case OrderFilterFieldV2.IS_REFUSED:
      case OrderFilterFieldV2.IS_REWEIGHED:
      case OrderFilterFieldV2.IS_SPECIAL:
      case OrderFilterFieldV2.ON_HAND:
      case OrderFilterFieldV2.ON_HOLD:
      case OrderFilterFieldV2.ON_INVOICE:
      case OrderFilterFieldV2.ORDER_SERVICE_DATE:
      case OrderFilterFieldV2.PAPERWORK_COMPLETED:
      case OrderFilterFieldV2.REQUIRES_RECOVERY:
      case OrderFilterFieldV2.SECONDARY_REFERENCE_NUMBER:
      case OrderFilterFieldV2.UN_NUMBER:
      case OrderFilterFieldV2.INBOUND_ADDRESS:
      case OrderFilterFieldV2.OUTBOUND_ADDRESS:
      case OrderFilterFieldV2.INBOUND_ROUTE_NAME:
      case OrderFilterFieldV2.OUTBOUND_ROUTE_NAME:
      case OrderFilterFieldV2.INBOUND_APPOINTMENT_REQUIRED:
      case OrderFilterFieldV2.OUTBOUND_APPOINTMENT_REQUIRED:
      case OrderFilterFieldV2.DESTINATION_DETAILS:
      case OrderFilterFieldV2.INBOUND_APPOINTMENT_CONFIRMED:
      case OrderFilterFieldV2.OUTBOUND_APPOINTMENT_CONFIRMED:
      case OrderFilterFieldV2.ROUTING_LOCATION:
      case OrderFilterFieldV2.INBOUND_ZIPCODE:
      case OrderFilterFieldV2.OUTBOUND_ZIPCODE:
      case OrderFilterFieldV2.EXTERNAL_NOTES:
      case OrderFilterFieldV2.TOTAL_SKIDS:
      case OrderFilterFieldV2.OSD_REASON:
      case OrderFilterFieldV2.TOTAL_CHARGES:
      case OrderFilterFieldV2.INBOUND_CONTACT_NAME:
      case OrderFilterFieldV2.OUTBOUND_CONTACT_NAME:
      case OrderFilterFieldV2.IS_LINE_HAUL:
      case OrderFilterFieldV2.INBOUND_APPOINTMENT_DATE:
      case OrderFilterFieldV2.OUTBOUND_APPOINTMENT_DATE:
      case OrderFilterFieldV2.RECEIVED_AT_ORIGIN:
      case OrderFilterFieldV2.CAN_DISPATCH:
      case OrderFilterFieldV2.INBOUND_ROUTING:
      case OrderFilterFieldV2.OUTBOUND_ROUTING:
      case OrderFilterFieldV2.PROOF_OF_DELIVERY_SIGNEE:
      case OrderFilterFieldV2.TRANSFER_PENDING:
      case OrderFilterFieldV2.REQUIRES_ROUTING:
      case OrderFilterFieldV2.INBOUND_CITY:
      case OrderFilterFieldV2.OUTBOUND_CITY:
      case OrderFilterFieldV2.HAS_CONTACT_INFORMATION:
      case OrderFilterFieldV2.INBOUND_NOT_ARRIVED:
      case OrderFilterFieldV2.APPOINTMENT_REQUIRED:
      case OrderFilterFieldV2.APPOINTMENT_SCHEDULED:
      case OrderFilterFieldV2.INBOUND_ROUTE_DATE:
      case OrderFilterFieldV2.OUTBOUND_ROUTE_DATE: {
        return null;
      }
      default: {
        return exhaustive(typedFilterName);
      }
    }
  }, [
    businessDivisionOptions,
    customerOptions,
    driverOptions,
    filterType,
    serviceLevelOptions,
    terminalOptions,
    terminalUuidOptions,
    holdReasonOptions,
    tagOptions,
    lineHaulLaneOptions,
  ]);

  return filterOptionMappings;
};
export default useFilterOptions;
