import { createAsyncThunk } from '@reduxjs/toolkit';
import { isNil } from 'lodash';
import { objectKeys } from 'tsafe';
import { v4 } from 'uuid';
import {
  type ErrorResponse,
  FIELD_IS_REQUIRED,
  validateDate,
  validateNotEmpty,
  validatePlainDate,
  validateString,
  type ValidationResponse,
} from '../../../common/form/formValidators';
import {
  AppointmentTextStatus,
  OrderSegmentType,
  Segment,
  StandardStopType,
  type StopCreateInput,
  StopStatus,
  StopType,
  type StopUpsertInput,
  type PickupOrDelivery,
} from '../../../generated/graphql';
import type { RootState } from '../../../redux/store';
import { selectAddressById } from '../../addresses/redux/addresses-values-slice';
import {
  type AddressErrorsResponse,
  createAddressUpsertInput,
  getAddressErrorsResponse,
} from '../../addresses/redux/addresses-values-thunks';
import { createContactPersonUpsertInput } from '../../contact-persons/redux/contact-person-values-thunks';
import { selectContactPersonById } from '../../contact-persons/redux/contact-persons-values-slice';
import { isPartnerCarryShipment } from '../../orders/components/utils';
import { upsertOneStopErrors } from './stop-errors-slice';
import {
  addOneStopValues,
  selectStopValuesById,
  type StopValues,
} from './stop-values-slice';

type ShallowCopyStopArg = {
  stopUuid: string;
};

export const shallowCopyStop = createAsyncThunk<
  string,
  ShallowCopyStopArg,
  {
    state: RootState;
  }
>('stops/shallowCopyStop', async (arg, thunkAPI) => {
  const stopValues = selectStopValuesById(thunkAPI.getState(), arg.stopUuid);

  if (isNil(stopValues)) {
    throw new Error(`invalid stop uuid: ${arg.stopUuid}`);
  }

  const uuid = v4();
  thunkAPI.dispatch(
    addOneStopValues({
      addressUuid: stopValues.addressUuid,
      appointmentManuallyConfirmed: stopValues.appointmentManuallyConfirmed,
      appointmentTextStatus: stopValues.appointmentTextStatus,
      appointmentTime: stopValues.appointmentTime,
      consigneeAddressUuid: stopValues.consigneeAddressUuid,
      consigneeContactPersonUuid: stopValues.consigneeContactPersonUuid,
      contactPersonUuid: stopValues.contactPersonUuid,
      contactPersonUuids: stopValues.contactPersonUuids,
      endAppointmentTime: stopValues.endAppointmentTime,
      inboundMethod: stopValues.inboundMethod,
      incomingCarrier: stopValues.incomingCarrier,
      outboundMethod: stopValues.outboundMethod,
      routeUuid: stopValues.routeUuid,
      serviceDate: stopValues.serviceDate,
      serviceDateV2: stopValues.serviceDateV2,
      shipperAddressUuid: stopValues.shipperAddressUuid,
      transferAddressUuid: stopValues.transferAddressUuid,
      shipperContactPersonUuid: stopValues.shipperContactPersonUuid,
      specialInstructions: stopValues.specialInstructions,
      standardStopType: stopValues.standardStopType,
      stopType: stopValues.stopType,
      terminalUuid: stopValues.terminalUuid,
      status: stopValues.status,
      uuid,
    }),
  );

  return uuid;
});

type CreateStandardStopCreateInputArg = {
  stopUuid: string;
  pickupOrDelivery: PickupOrDelivery | undefined;
};

export const createStandardStopCreateInput = createAsyncThunk<
  StopCreateInput,
  CreateStandardStopCreateInputArg,
  {
    state: RootState;
  }
>(
  'stops/createStopCreateInput',
  async (arg, thunkAPI): Promise<StopCreateInput> => {
    const stopValues = selectStopValuesById(thunkAPI.getState(), arg.stopUuid);

    if (isNil(stopValues)) {
      throw new Error(`invalid stop uuid: ${arg.stopUuid}`);
    }

    if (isNil(stopValues.standardStopType)) {
      throw new Error(`standard stop type cannot be nil: ${arg.stopUuid}`);
    }

    if (isNil(stopValues.addressUuid)) {
      throw new Error(
        `[createStopCreateInput] address uuid was null for stop: ${arg.stopUuid}`,
      );
    }

    const contactPerson = selectContactPersonById(
      thunkAPI.getState(),
      stopValues?.contactPersonUuid ?? '',
    );
    const shouldCreatedUpsertInput =
      !isNil(contactPerson) &&
      ((!isNil(contactPerson.firstName) &&
        contactPerson.firstName.length > 0) ||
        (!isNil(contactPerson.lastName) && contactPerson.lastName.length > 0) ||
        (!isNil(contactPerson.phone) && contactPerson.phone.length > 0));

    const contactPersonUpsertInputs = shouldCreatedUpsertInput
      ? await Promise.all(
          [stopValues?.contactPersonUuid].map(async (uuid) =>
            thunkAPI
              .dispatch(
                createContactPersonUpsertInput({
                  contactPersonUuid: uuid ?? '',
                }),
              )
              .unwrap(),
          ) ?? [],
        )
      : null;

    return {
      addressUpsertInput: await thunkAPI
        .dispatch(
          createAddressUpsertInput({ addressUuid: stopValues.addressUuid }),
        )
        .unwrap(),
      appointmentTime: stopValues.appointmentTime,
      endAppointmentTime: stopValues.endAppointmentTime,
      contactPersonUpsertInput: shouldCreatedUpsertInput
        ? await thunkAPI
            .dispatch(
              createContactPersonUpsertInput({
                contactPersonUuid: stopValues.contactPersonUuid ?? '',
              }),
            )
            .unwrap()
        : undefined,
      serviceDate: stopValues.serviceDate,
      serviceDateIso8601: stopValues.serviceDateV2?.toString(),
      standardStopType: stopValues.standardStopType,
      specialInstructions: stopValues.specialInstructions,
      appointmentTextStatus: stopValues.appointmentTextStatus,
      notes: stopValues.notes,
      appointmentRequired: stopValues.appointmentRequired ?? false,
      contactPersonUpsertInputs,
      appointmentManuallyConfirmed: stopValues.appointmentManuallyConfirmed,
      stopType: stopValues.stopType ?? StopType.Delivery,
      incomingCarrier: stopValues.incomingCarrier,
      outboundCarrier: stopValues.outboundCarrier,
      inboundMethod: stopValues.inboundMethod,
      outboundMethod: stopValues.outboundMethod,
      destinationAirport: stopValues.destinationAirport,
      isSpecial: stopValues.isSpecial,
      terminalUuid: stopValues.terminalUuid,
      hideFromDispatch: isPartnerCarryShipment({
        stopFromRedux: stopValues,
      }),
      transferAddressUpsertInput: isNil(stopValues.transferAddressUuid)
        ? null
        : await thunkAPI
            .dispatch(
              createAddressUpsertInput({
                addressUuid: stopValues.transferAddressUuid,
              }),
            )
            .unwrap(),
    };
  },
);

type CreateStopUpsertInputArg = {
  stopUuid: string;
};

export const createStopUpsertInput = createAsyncThunk<
  StopUpsertInput,
  CreateStopUpsertInputArg,
  {
    state: RootState;
  }
>(
  'stops/createStopUpsertInput',
  async (arg, thunkAPI): Promise<StopUpsertInput> => {
    const stopValues = selectStopValuesById(thunkAPI.getState(), arg.stopUuid);

    if (isNil(stopValues)) {
      throw new Error(`invalid stop uuid: ${arg.stopUuid}`);
    }

    if (isNil(stopValues.addressUuid)) {
      throw new Error(
        `[createStopUpsertInput] address uuid was null for stop: ${arg.stopUuid}`,
      );
    }

    const contactPerson = selectContactPersonById(
      thunkAPI.getState(),
      stopValues?.contactPersonUuid ?? '',
    );
    const shouldCreatedUpsertInput =
      !isNil(contactPerson) &&
      ((!isNil(contactPerson.firstName) &&
        contactPerson.firstName.length > 0) ||
        (!isNil(contactPerson.lastName) && contactPerson.lastName.length > 0) ||
        (!isNil(contactPerson.phone) && contactPerson.phone.length > 0));

    const contactPersonUpsertInput = shouldCreatedUpsertInput
      ? await thunkAPI
          .dispatch(
            createContactPersonUpsertInput({
              contactPersonUuid: stopValues.contactPersonUuid ?? '',
            }),
          )
          .unwrap()
      : null;

    const contactPersonUpsertInputs = shouldCreatedUpsertInput
      ? await Promise.all(
          [stopValues?.contactPersonUuid].map(async (uuid) =>
            thunkAPI
              .dispatch(
                createContactPersonUpsertInput({
                  contactPersonUuid: uuid ?? '',
                }),
              )
              .unwrap(),
          ) ?? [],
        )
      : null;

    return {
      addressUpsertInput: await thunkAPI
        .dispatch(
          createAddressUpsertInput({ addressUuid: stopValues.addressUuid }),
        )
        .unwrap(),
      appointmentTime: stopValues.appointmentTime,
      contactPersonUpsertInput,
      serviceDate: stopValues.serviceDate,
      serviceDateIso8601: stopValues.serviceDateV2?.toString(),
      standardStopType: stopValues.standardStopType,
      endAppointmentTime: stopValues.endAppointmentTime,
      specialInstructions: stopValues.specialInstructions,
      appointmentTextStatus: stopValues.appointmentTextStatus,
      uuid: arg.stopUuid,
      notes: stopValues.notes,
      appointmentRequired: stopValues.appointmentRequired ?? false,
      proofOfDeliverySignee: stopValues.proofOfDeliverySignee,
      contactPersonUpsertInputs,
      appointmentManuallyConfirmed: stopValues.appointmentManuallyConfirmed,
      stopType: stopValues.stopType,
      inboundMethod: stopValues.inboundMethod,
      expectedInboundArrivalDate: stopValues.expectedInboundArrivalDate,
      terminalUuid: stopValues.terminalUuid,
      outboundMethod: stopValues.outboundMethod,
      incomingCarrier: stopValues.incomingCarrier,
      destinationAirport: stopValues.destinationAirport,
      isSpecial: stopValues.isSpecial,
    };
  },
);

type ValidateStopValuesArg = {
  stopUuid: string;
  shipmentUuid: string;
  segment?: Segment;
  orderSegmentType?: OrderSegmentType;
  shouldValidateStopAddress?: boolean;

  forceValidateAddress?: boolean;
};
export type StopErrorsResponse = {
  isValid: boolean;
  errors: ErrorResponse[];
  addressErrorsResponse: AddressErrorsResponse | null;
};

export const getStopErrors = createAsyncThunk<
  StopErrorsResponse,
  ValidateStopValuesArg,
  {
    state: RootState;
  }
>('stops/getStopErrors', async (arg, thunkAPI): Promise<StopErrorsResponse> => {
  const {
    stopUuid,
    segment,
    orderSegmentType,
    shouldValidateStopAddress,
    forceValidateAddress,
  } = arg;
  const stopValues = selectStopValuesById(thunkAPI.getState(), stopUuid);

  if (isNil(stopValues)) {
    throw new Error(`Invalid stop uuid: ${arg.stopUuid}`);
  }

  const stopErrorsResponse: StopErrorsResponse = {
    addressErrorsResponse: null,
    errors: [],
    isValid: true,
  };

  await Promise.all(
    objectKeys(stopValues).map(async (field) => {
      let validationResponse: ValidationResponse | null | undefined;
      let userFacingFieldName = '';

      switch (field) {
        case 'addressUuid': {
          if (
            shouldValidateStopAddress === false &&
            forceValidateAddress !== true
          ) {
            break;
          }
          if (stopValues.addressUuid === undefined) {
            userFacingFieldName = 'Address';
            validationResponse = {
              valid: false,
              explanation: FIELD_IS_REQUIRED,
            };
          } else {
            const stopAddress = selectAddressById(
              thunkAPI.getState(),
              stopValues.addressUuid,
            );
            if (stopAddress === undefined) {
              userFacingFieldName = 'Stop address';
              validationResponse = {
                valid: false,
                explanation: FIELD_IS_REQUIRED,
              };
            } else {
              const addressErrorsResponse = await thunkAPI
                .dispatch(
                  getAddressErrorsResponse({
                    allowEmpty: isPartnerCarryShipment({
                      stopFromRedux: stopValues,
                    }),
                    addressId: stopAddress.uuid,
                  }),
                )
                .unwrap();
              stopErrorsResponse.isValid =
                stopErrorsResponse.isValid && addressErrorsResponse.isValid;
              stopErrorsResponse.addressErrorsResponse = addressErrorsResponse;
            }
          }
          break;
        }
        case 'appointmentTime': {
          userFacingFieldName = 'Appointment time';
          validationResponse = validateDate(
            isNil(stopValues.appointmentTime)
              ? null
              : new Date(stopValues.appointmentTime),
            false,
          );
          break;
        }
        case 'endAppointmentTime': {
          userFacingFieldName = 'End appointment time';
          validationResponse = validateDate(
            isNil(stopValues.endAppointmentTime)
              ? null
              : new Date(stopValues.endAppointmentTime),
            false,
          );
          break;
        }
        case 'contactPersonUuid': {
          break;
        }
        case 'incomingCarrier': {
          break;
        }
        case 'destinationAirport': {
          break;
        }
        case 'appointmentRequired': {
          break;
        }
        case 'standardStopType': {
          userFacingFieldName = 'Stop type';
          validationResponse = validateNotEmpty(
            stopValues.standardStopType,
            false,
          );
          break;
        }
        case 'serviceDate': {
          if (
            (stopValues.stopType === StopType.Pickup ||
              stopValues.stopType === StopType.Delivery) &&
            shouldValidateStopAddress === true
          ) {
            userFacingFieldName = 'Service date';
            validationResponse = validateDate(
              isNil(stopValues.serviceDate)
                ? null
                : new Date(stopValues.serviceDate),
              true,
            );
          }
          break;
        }
        case 'serviceDateV2': {
          if (
            (stopValues.stopType === StopType.Pickup ||
              stopValues.stopType === StopType.Delivery) &&
            shouldValidateStopAddress === true
          ) {
            userFacingFieldName = 'Service date';
            validationResponse = validatePlainDate(
              stopValues.serviceDateV2,
              true,
            );
          }
          break;
        }
        case 'inboundMethod': {
          let inboundRequired = false;
          userFacingFieldName = 'Inbound Method';
          if (
            stopValues.stopType === StopType.Delivery &&
            segment === Segment.Cartage &&
            orderSegmentType === OrderSegmentType.Cartage
          ) {
            inboundRequired = true;
          }
          validationResponse = validateString(
            stopValues.inboundMethod,
            inboundRequired,
          );
          break;
        }
        case 'outboundMethod': {
          let outboundRequired = false;
          userFacingFieldName = 'Outbound Method';
          if (
            stopValues.stopType === StopType.Pickup &&
            orderSegmentType === OrderSegmentType.Cartage &&
            segment === Segment.Cartage
          ) {
            outboundRequired = true;
          }
          validationResponse = validateString(
            stopValues.outboundMethod,
            outboundRequired,
          );
          break;
        }
      }
      if (!isNil(validationResponse)) {
        if (validationResponse.valid) {
          // updateOne didn't seem to work here, not sure why
          thunkAPI.dispatch(
            upsertOneStopErrors({
              uuid: stopUuid,
              [field]: undefined,
            }),
          );
        } else {
          stopErrorsResponse.isValid = false;
          stopErrorsResponse.errors.push({
            field: userFacingFieldName,
            validationResponse,
          });
          thunkAPI.dispatch(
            upsertOneStopErrors({
              uuid: stopUuid,
              [field]: validationResponse.explanation,
            }),
          );
        }
      }
    }),
  );

  return stopErrorsResponse;
});

type InitNewStopArgs = {
  defaultStandardStopType?: StandardStopType | undefined;
  defaultManuallyConfirmed?: boolean | undefined;
};

export const initNewStop = createAsyncThunk<
  StopValues,
  InitNewStopArgs,
  {
    state: RootState;
  }
>(
  'stops/initNewStop',
  async (arg, thunkAPI) =>
    thunkAPI.dispatch(
      addOneStopValues({
        // Make it undefined because we use an autocomplete to select from a list of existing addresses
        addressUuid: undefined,
        billOfLadingNumber: undefined,
        appointmentTime: undefined,
        endAppointmentTime: undefined,
        routeUuid: undefined,
        contactPersonUuid: undefined,
        contactPersonUuids: undefined,
        serviceDate: undefined,
        serviceDateV2: undefined,
        specialInstructions: undefined,
        appointmentTextStatus: AppointmentTextStatus.NotSent,
        standardStopType:
          arg.defaultStandardStopType ?? StandardStopType.Residential,
        status: StopStatus.NotArrived,
        uuid: v4(),
        appointmentManuallyConfirmed: arg.defaultManuallyConfirmed,
        inboundMethod: null,
        expectedInboundArrivalDate: null,
        outboundMethod: null,
        incomingCarrier: null,
        destinationAirport: null,
        terminalUuid: null,
        stopType: null,
      }),
    ).payload,
);
