import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Button,
  Chip,
  Collapse,
  Fade,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Menu,
  MenuItem,
  Tooltip,
  Stack,
} from '@mui/material';
import { capitalCase } from 'change-case';
import currency from 'currency.js';
import { isEmpty, isNil, uniq } from 'lodash';
import React, { type Dispatch, type SetStateAction, useState } from 'react';
import { filterNotNil } from 'shared/array';
import { FeatureFlag } from '../../../../common/feature-flags';
import useFeatureFlag from '../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../common/react-hooks/use-me';
import { chooseForegroundColor } from '../../../../common/utils/colors';
import { transformDateToDateString } from '../../../../common/utils/prettyPrintUtils';
import {
  type PaymentFragment,
  useAllInvoicesForPaymentApplicationLazyQuery,
  useDeletePaymentMutation,
  usePaymentForMemoReportLazyQuery,
} from '../../../../generated/graphql';
import PalletLink from '../../../../pallet-ui/links/link/pallet-link';
import useInvoiceTotals from '../../hooks/use-invoice-totals';
import { getPaymentTypeColorForChip } from '../../utils';
import { CreateOrEdit } from '../invoices/enums';
import CreatePaymentModal from './create-payment-modal';
import {
  downloadMemoReport,
  formatPaymentRail,
  getPaymentRailColorForChip,
} from './utils';

type ContactPaymentsListRowProps = {
  readonly payment: PaymentFragment;
  readonly refetchPayments: () => Promise<void>;
  readonly setPaymentsListLoading: Dispatch<SetStateAction<boolean>>;
};

const ContactPaymentListRow = ({
  payment,
  refetchPayments,
  setPaymentsListLoading,
}: ContactPaymentsListRowProps) => {
  const ffHideInvoicesFilter = useFeatureFlag(
    FeatureFlag.FF_HIDE_INVOICES_FILTER_IN_PAYMENT_APPLICATION,
  );

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState(false);
  const [showEditPaymentsModal, setShowEditPaymentsModal] = useState(false);
  const [getPaymentForMemoReport] = usePaymentForMemoReportLazyQuery();
  const { fetchInvoiceTotalsByUuids } = useInvoiceTotals();
  const { companyConfiguration, meData } = useMe();
  const [deletePayment] = useDeletePaymentMutation();
  const masterAccountUuid = payment.contact.masterAccount?.uuid;

  const [
    fetchAllInvoicesForPaymentApplication,
    { data: allInvoicesForPaymentApplication },
  ] = useAllInvoicesForPaymentApplicationLazyQuery({
    variables: {
      masterAccountUuid,
      billingPartyContactUuid: isNil(masterAccountUuid)
        ? payment.contact.uuid
        : undefined,
    },
  });

  const handleClickModifyApplication = () => {
    setShowEditPaymentsModal(true);
    if (ffHideInvoicesFilter) return; // If the invoices filter is hidden, no need to fetch invoices for it
    fetchAllInvoicesForPaymentApplication();
  };

  const startDownloadMemoReport = async () => {
    const res = await getPaymentForMemoReport({
      variables: {
        uuid: payment.uuid,
      },
    });

    const paymentForMemoReportData = res.data?.paymentByUuid;
    if (!isNil(paymentForMemoReportData)) {
      downloadMemoReport({
        companyData: meData,
        payment: paymentForMemoReportData,
      });
    }
  };

  const handleDelete = async () => {
    await deletePayment({
      variables: {
        uuid: payment.uuid,
      },
    });
    const invoiceUuids = filterNotNil(
      uniq(
        payment.paymentToOrderMappings.map((pom) => pom.order.invoice?.uuid),
      ),
    );
    await fetchInvoiceTotalsByUuids(invoiceUuids);

    setPaymentsListLoading(true);
    refetchPayments();
  };

  const handleClosePaymentModal = () => {
    refetchPayments();
    setShowEditPaymentsModal(false);
  };

  const creditTypeComponent: JSX.Element | null = isNil(
    payment.creditType,
  ) ? null : (
    <Chip
      size="small"
      sx={{
        backgroundColor: payment.creditType?.color,
        color: isNil(payment.creditType?.color)
          ? undefined
          : chooseForegroundColor(payment.creditType?.color),
      }}
      color={isNil(payment.creditType?.color) ? 'default' : undefined}
      label={payment.creditType?.name}
    />
  );

  const paymentRailComponent: JSX.Element | null = isNil(
    payment.paymentRail,
  ) ? null : (
    <Chip
      size="small"
      color={getPaymentRailColorForChip(payment.paymentRail)}
      label={formatPaymentRail(payment.paymentRail)}
    />
  );

  return (
    <>
      {showEditPaymentsModal && (
        // This pattern is actually really wonky. The PaymentApplicationModal is nested inside CreatePaymentModal
        <CreatePaymentModal
          open={showEditPaymentsModal}
          contactUuid={payment.contact.uuid}
          paymentUuid={payment.uuid}
          createOrEdit={CreateOrEdit.Edit}
          setPaymentsListLoading={setPaymentsListLoading}
          type={payment.paymentType}
          allInvoicesForPaymentApplication={allInvoicesForPaymentApplication}
          onClose={handleClosePaymentModal}
        />
      )}
      <TableRow
        sx={{
          backgroundColor: isExpanded ? '#F7F7F7' : undefined,
        }}
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
      >
        <TableCell width={70}>
          <IconButton
            size="small"
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          >
            {isExpanded ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </TableCell>
        <TableCell>
          {transformDateToDateString(new Date(payment.date).toISOString())}
        </TableCell>
        <TableCell align="center">
          <Chip
            size="small"
            color={getPaymentTypeColorForChip(payment.paymentType)}
            label={capitalCase(payment.paymentType)}
          />
        </TableCell>
        <TableCell align="center">
          {isNil(creditTypeComponent) && isNil(paymentRailComponent) ? (
            '-'
          ) : (
            <>
              {creditTypeComponent}
              {paymentRailComponent}
            </>
          )}
        </TableCell>
        <TableCell>
          {isEmpty(payment.referenceNumber) ? 'None' : payment.referenceNumber}
        </TableCell>
        <TableCell
          sx={{
            maxWidth: 200,
          }}
        >
          <Tooltip title={payment.comment}>
            <Typography
              fontSize={14}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {payment.comment}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell>
          {currency(payment.amountInCents, { fromCents: true }).format({
            separator: '',
          })}
        </TableCell>
        <TableCell>
          {currency(payment.unappliedAmountInCents, { fromCents: true }).format(
            {
              separator: '',
            },
          )}
        </TableCell>
        <TableCell align="center">
          {!isNil(payment.glTerminal?.name) &&
          !isEmpty(payment.glTerminal?.name)
            ? payment.glTerminal?.name
            : '-'}
        </TableCell>
        <TableCell>
          <Fade in={isHovering}>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
              <Button
                variant="contained"
                onClick={handleClickModifyApplication}
              >
                Modify application
              </Button>
              <>
                <IconButton
                  id="more-menu-button"
                  aria-controls={menuAnchorEl ? 'more-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={menuAnchorEl ? 'true' : undefined}
                  onClick={(e) => {
                    setMenuAnchorEl(e.currentTarget);
                  }}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="more-menu"
                  anchorEl={menuAnchorEl}
                  open={Boolean(menuAnchorEl)}
                  MenuListProps={{
                    'aria-labelledby': 'more-menu-button',
                  }}
                  onClose={() => {
                    setMenuAnchorEl(null);
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      startDownloadMemoReport();
                      setMenuAnchorEl(null); // Close the menu
                    }}
                  >
                    Download memo
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDelete();
                      setMenuAnchorEl(null); // Close the menu
                    }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </>
            </Box>
          </Fade>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ py: 0 }} colSpan={7}>
          <Collapse unmountOnExit in={isExpanded} timeout="auto">
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableCell>Order HAWB</TableCell>
                  <TableCell>
                    Invoice{' '}
                    {companyConfiguration?.useJournalNumberForInvoice === true
                      ? 'journal number'
                      : 'name'}
                  </TableCell>
                  <TableCell>Order total</TableCell>
                  <TableCell>Applied amount</TableCell>
                </TableHead>
                <TableBody>
                  {payment.paymentToOrderMappings.map((mapping) => (
                    <TableRow key={mapping.order.uuid}>
                      <TableCell>
                        {mapping.order.standardOrderFields
                          .shipperBillOfLadingNumber ?? ''}
                      </TableCell>
                      <TableCell>
                        {isNil(mapping.order.invoice) ? (
                          '-'
                        ) : (
                          <PalletLink
                            sx={{
                              cursor: 'pointer',
                              maxWidth: '100px',
                            }}
                            href={`/accounting/invoices?invoiceUuid=${mapping.order.invoice.uuid}&invoiceName=${mapping.order.invoice.name}`}
                            target="_blank"
                            underline="hover"
                          >
                            <Stack alignItems="center" direction="row">
                              {companyConfiguration?.useJournalNumberForInvoice ===
                              true
                                ? mapping.order.invoice.journalNumber
                                : mapping.order.invoice.name}
                            </Stack>
                          </PalletLink>
                        )}
                      </TableCell>
                      <TableCell>
                        {currency(mapping.order.totalCharge).format({
                          separator: '',
                        })}
                      </TableCell>
                      <TableCell>
                        {currency(mapping.amountInCents, {
                          fromCents: true,
                        }).format({
                          separator: '',
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {payment.paymentToOrderMappings.length === 0 && (
              <Typography
                color="text.secondary"
                sx={{ width: '100%', textAlign: 'center', m: 1 }}
              >
                No payments applied to orders
              </Typography>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ContactPaymentListRow;
