import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Menu, Stack, Typography } from '@mui/material';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import {
  TableConfigPillIcon,
  TableConfigPillIconButton,
  TableConfigPillText,
} from '../../domains/ag-grid/table-configuration-pills';
import { EditFilters } from './edit-filters';
import {
  type FilterConfig,
  type FilterFieldToTypeMap,
  type FilterGroup,
  type FilterTypeToOperatorMap,
  type NullableFilterCondition,
} from './types-v2';
import {
  isFilterCondition,
  isFilterGroup,
  isFilterGroupEmpty,
} from './utils-v2';

type FilterPillProps<
  TFilterField extends string,
  TFilterType extends string,
  TOperatorMap extends FilterTypeToOperatorMap<TFilterType>,
  TFieldMap extends FilterFieldToTypeMap<TFilterField, TFilterType>,
> = {
  readonly filterCondition: NullableFilterCondition<
    TFilterField,
    TFilterType,
    TOperatorMap,
    TFieldMap
  >;
  readonly filterGroupOperator: 'AND' | 'OR';
  readonly roundLeft: boolean;
  readonly showSeparator: boolean;
  readonly wrap: boolean;
  readonly handleClick: (e: React.MouseEvent<HTMLElement>) => void;
  readonly renderFilterParts: FilterConfig<
    TFilterField,
    TFilterType,
    TOperatorMap,
    TFieldMap
  >['renderFilterParts'];
};

const FilterPill = <
  TFilterField extends string,
  TFilterType extends string,
  TOperatorMap extends FilterTypeToOperatorMap<TFilterType>,
  TFieldMap extends FilterFieldToTypeMap<TFilterField, TFilterType>,
>({
  filterCondition,
  filterGroupOperator,
  roundLeft,
  showSeparator,
  wrap,
  handleClick,
  renderFilterParts,
}: FilterPillProps<TFilterField, TFilterType, TOperatorMap, TFieldMap>) => {
  const renderedFilterParts = renderFilterParts(filterCondition);
  if (isNil(renderedFilterParts)) {
    return null;
  }

  const {
    field: renderedField,
    operator: renderedOperator,
    value: renderedValue,
  } = renderedFilterParts;

  return (
    <Stack direction="row" alignItems="center">
      <TableConfigPillText
        roundLeft={roundLeft}
        roundRight={!showSeparator}
        sx={{ maxWidth: wrap ? '50vw' : '25vw' }}
        onClick={handleClick}
      >
        {renderedField} {renderedOperator}{' '}
        <Typography component="span" fontWeight="bold" fontSize="inherit">
          {renderedValue}
        </Typography>
      </TableConfigPillText>
      {showSeparator && (
        <TableConfigPillIcon color="secondary">
          {filterGroupOperator === 'AND' ? '&' : 'or'}
        </TableConfigPillIcon>
      )}
    </Stack>
  );
};

type FilterPillsProps<
  TFilterField extends string,
  TFilterType extends string,
  TOperatorMap extends FilterTypeToOperatorMap<TFilterType>,
  TFieldMap extends FilterFieldToTypeMap<TFilterField, TFilterType>,
> = {
  readonly wrap: boolean;
  readonly filters: FilterGroup<
    TFilterField,
    TFilterType,
    TOperatorMap,
    TFieldMap
  >;
  readonly setFilters: (
    filters: FilterGroup<TFilterField, TFilterType, TOperatorMap, TFieldMap>,
  ) => void;
  readonly filterConfig: FilterConfig<
    TFilterField,
    TFilterType,
    TOperatorMap,
    TFieldMap
  >;
};

const FilterPills = <
  TFilterField extends string,
  TFilterType extends string,
  TOperatorMap extends FilterTypeToOperatorMap<TFilterType>,
  TFieldMap extends FilterFieldToTypeMap<TFilterField, TFilterType>,
>({
  wrap,
  filters,
  setFilters,
  filterConfig,
}: FilterPillsProps<TFilterField, TFilterType, TOperatorMap, TFieldMap>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { defaultFilters, renderFilterParts } = filterConfig;
  const showDefaultFilters =
    !isNil(defaultFilters) && isFilterGroupEmpty(filters);

  const handlePillClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack direction="row" alignItems="start" columnGap={0.5}>
        <TableConfigPillIcon roundLeft roundRight>
          <FilterListIcon />
        </TableConfigPillIcon>
        <Stack
          direction="row"
          flexWrap={wrap ? 'wrap' : 'nowrap'}
          rowGap={1}
          columnGap={0.5}
        >
          {filters.conditions.map((filterGroup, filterGroupIndex) => {
            if (!isFilterGroup(filterGroup)) {
              return null;
            }

            return (
              <React.Fragment
                // TODO: frontend-only UUIDs for filters
                // eslint-disable-next-line react/no-array-index-key
                key={`${filterGroup.operator}-${filterGroupIndex}`}
              >
                <Stack
                  direction="row"
                  flexWrap={wrap ? 'wrap' : 'nowrap'}
                  rowGap={1}
                >
                  {filterGroup.conditions
                    // TODO: eventually we'll support more than 1 layer of nesting
                    .filter((f) => isFilterCondition(f))
                    .map((filter, filterIndex) => (
                      <FilterPill<
                        TFilterField,
                        TFilterType,
                        TOperatorMap,
                        TFieldMap
                      >
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${filter.field}-${filter.operator?.toString()}-${filterGroupIndex}-${filterIndex}`}
                        wrap={wrap}
                        filterCondition={filter}
                        filterGroupOperator={filterGroup.operator}
                        roundLeft={filterIndex === 0}
                        showSeparator={
                          filterIndex < filterGroup.conditions.length - 1
                        }
                        handleClick={handlePillClick}
                        renderFilterParts={renderFilterParts}
                      />
                    ))}
                </Stack>
                {filterGroupIndex < filters.conditions.length - 1 && (
                  <TableConfigPillIcon roundLeft roundRight>
                    {filters.operator === 'AND' ? '&' : 'or'}
                  </TableConfigPillIcon>
                )}
              </React.Fragment>
            );
          })}
          {showDefaultFilters &&
            defaultFilters?.conditions.map((filter, filterIndex) => (
              <FilterPill<TFilterField, TFilterType, TOperatorMap, TFieldMap>
                // eslint-disable-next-line react/no-array-index-key
                key={`${filter.field}-${filter.operator?.toString()}-${filterIndex}`}
                roundLeft
                filterCondition={filter}
                filterGroupOperator={defaultFilters.operator}
                wrap={wrap}
                showSeparator={false}
                handleClick={handlePillClick}
                renderFilterParts={renderFilterParts}
              />
            ))}
          <TableConfigPillIconButton onClick={handlePillClick}>
            <AddIcon />
          </TableConfigPillIconButton>
        </Stack>
      </Stack>
      <Menu
        open={!isNil(anchorEl)}
        anchorEl={anchorEl}
        MenuListProps={{ sx: { py: 0 } }}
        onClose={handleClose}
      >
        <EditFilters<TFilterField, TFilterType, TOperatorMap, TFieldMap>
          filterGroup={filters}
          setFilterGroup={setFilters}
          filterConfig={filterConfig}
          onClose={handleClose}
        />
      </Menu>
    </>
  );
};

// React.memo doesn't work with generic props types
const typedMemo: <T>(c: T) => T = React.memo;

export default typedMemo(FilterPills);
