import { Stack, Typography } from '@mui/material';
import { isNilOrEmptyString } from 'shared/string';
import PalletLogo from '../../../layouts/dashboard/icons/pallet-logo.svg?react';

type CustomerPortalSidebarLogoProps = {
  readonly companyName?: string | null;
  readonly companyLandscapeLogoUrl?: string | null;
};

export const CustomerPortalSidebarLogo = ({
  companyName,
  companyLandscapeLogoUrl,
}: CustomerPortalSidebarLogoProps) => {
  if (!isNilOrEmptyString(companyLandscapeLogoUrl)) {
    return (
      <Stack width="100px" pl={1}>
        <img
          src={companyLandscapeLogoUrl}
          alt="Company logo"
          style={{
            backgroundColor: 'white',
            borderRadius: '4px',
            objectFit: 'contain',
          }}
        />
      </Stack>
    );
  }

  if (!isNilOrEmptyString(companyName)) {
    return (
      <Stack width="100px">
        <Typography color="white" fontWeight={500} textAlign="left">
          {companyName.length > 30
            ? `${companyName.slice(0, 27)}...`
            : companyName}
        </Typography>
      </Stack>
    );
  }

  return <PalletLogo color="white" />;
};
