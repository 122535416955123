import { isNil } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Navigate, useLocation } from 'react-router-dom';
import { isNilOrEmptyString } from 'shared/string';
import { FeatureFlag } from '../../../common/feature-flags';
import { CUSTOMER_PORTAL_ORDERS_PATH } from '../constants';
import useContactsForMeAsThirdPartyUser from '../hooks/use-contacts-for-me-as-third-party-user';
import { type ThirdPartyUserCompany } from '../types';
import CustomerPortalFeatureFlagGuard from './common/customer-portal-feature-flag-guard';
import { CustomerPortalOrderFormContextProvider } from './customer-portal-order-form/contexts/customer-portal-order-form-context';
import CustomerPortalOrderForm from './customer-portal-order-form/customer-portal-order-form';
import { useCreateCustomerPortalOrderForm } from './customer-portal-order-form/hooks/use-create-customer-portal-order-form';
import { useSaveOrderCustomerPortal } from './customer-portal-order-form/hooks/use-save-order-customer-portal';
import CustomerPortalRatingQuoteFormPanel from './customer-portal-quote-form/customer-portal-quote-rating-panel';

type CustomerPortalAddOrderPageWithContactUuidProps = {
  readonly contactUuid: string;
  readonly setContactUuid: (contactUuid: string) => void;
  readonly company: ThirdPartyUserCompany;
};

// Separate component so that we can guarantee that contactUuid and company
// are always available
const CustomerPortalAddOrderPageWithContactUuid = ({
  contactUuid,
  setContactUuid,
  company,
}: CustomerPortalAddOrderPageWithContactUuidProps) => {
  // This is used to store the latest quote UUID from the quote form
  // so that we can attach it to the order when the form submits
  const [latestQuoteUuid, setLatestQuoteUuid] = useState<string | null>(null);

  const { saveOrder: onSubmit } = useSaveOrderCustomerPortal({
    companyUuid: company.uuid,
    contactUuid,
    latestQuoteUuid,
  });
  return (
    <CustomerPortalOrderForm
      contactUuid={contactUuid}
      setContactUuid={setContactUuid}
      rightPanelContent={
        <CustomerPortalRatingQuoteFormPanel
          contactUuid={contactUuid}
          company={company}
          setLatestQuoteUuid={setLatestQuoteUuid}
        />
      }
      onSubmit={onSubmit}
    />
  );
};

const CustomerPortalAddOrderPage = () => {
  const form = useCreateCustomerPortalOrderForm();

  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const navigationContactUuid: string | null =
    typeof location.state?.contactUuid === 'string'
      ? location.state?.contactUuid
      : null;

  // State management is tricky here:
  // - We want to load the contact UUID from the navigation state
  // - We want to update the contact UUID in the form if navigation changes
  //   (e.g. user navigates to the same page with a different contact)
  // - We want to minimize duplicate states / useEffects (e.g. not also putting the contact
  //   UUID in the form state)
  // eslint-disable-next-line react/hook-use-state
  const [contactUuid, rawSetContactUuid] = useState<string | null>(
    navigationContactUuid,
  );

  // If the navigation state changes, read the new contact UUID
  // We can't use the setContactUuid function here because it would
  // cause this effect to run every time the contact UUID changes
  // and reset to the contact UUID stored in the navigation state
  useEffect(() => {
    if (isNilOrEmptyString(navigationContactUuid)) {
      return;
    }
    rawSetContactUuid(navigationContactUuid);
    form.reset();
  }, [navigationContactUuid, rawSetContactUuid, form]);

  const { contacts: contactsWithOrderEntryEnabled } =
    useContactsForMeAsThirdPartyUser({
      filterByCustomerPortalOrderEntryEnabled: true,
    });

  // We can derive the company from the selected contact UUID (so no need to
  // store it in the form state)
  const company: ThirdPartyUserCompany | null =
    contactsWithOrderEntryEnabled.find((c) => c.uuid === contactUuid)
      ?.company ?? null;

  const setContactUuid = useCallback(
    (newContactUuid: string) => {
      const newCompanyUuid = contactsWithOrderEntryEnabled.find(
        (c) => c.uuid === newContactUuid,
      )?.company.uuid;
      if (newCompanyUuid !== company?.uuid) {
        // The form may have company-specific values (e.g. service level or
        // terminal UUIDs) that we need to reset when switching companies.
        form.reset();
      }
      rawSetContactUuid(newContactUuid);
    },
    [contactsWithOrderEntryEnabled, company, form],
  );

  if (isNilOrEmptyString(contactUuid) || isNil(company)) {
    return <Navigate to={CUSTOMER_PORTAL_ORDERS_PATH} />;
  }

  return (
    <CustomerPortalFeatureFlagGuard
      featureFlag={FeatureFlag.FF_CUSTOMER_PORTAL_ORDER_ENTRY}
      navigateTo={CUSTOMER_PORTAL_ORDERS_PATH}
    >
      <FormProvider {...form}>
        <CustomerPortalOrderFormContextProvider company={company}>
          <CustomerPortalAddOrderPageWithContactUuid
            contactUuid={contactUuid}
            setContactUuid={setContactUuid}
            company={company}
          />
        </CustomerPortalOrderFormContextProvider>
      </FormProvider>
    </CustomerPortalFeatureFlagGuard>
  );
};

export default CustomerPortalAddOrderPage;
