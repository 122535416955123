import { z } from 'zod';
import { OrganizationType } from '../../../generated/graphql';

const commonFields = {
  uuid: z.string().uuid(),
  name: z.string().min(1, 'Name is required'),
  referenceNumber: z.string().nullable(),
  types: z
    .array(z.nativeEnum(OrganizationType))
    .min(1, 'At least one type must be selected'),
  email: z.string().email('Invalid email address'),
  defaultContactPersonUuid: z.string().uuid().nullable(),
  defaultInboundEdiServiceUuid: z.string().uuid().nullable(),
  defaultOutboundEdiServiceUuid: z.string().uuid().nullable(),
  defaultInboundOutboundEdiServiceUuid: z.string().uuid().nullable(),
  isaId: z.string().nullable(),
  ediApplicationId: z.string().nullable(),
  stediPartnerId: z.string().nullable(),
  ediContactCode: z.string().nullable(),
};

export enum OrganizationFormVariant {
  AGENT = 'agent',
  CUSTOMER = 'customer',
  AGENT_AND_CUSTOMER = 'agent_and_customer',
}
const baseOrganizationSchema = z.object(commonFields);

const agentFields = {
  isAgent: z.literal(true),
  isCustomer: z.literal(false),
};

const customerFields = {
  isCustomer: z.literal(true),
  isAgent: z.literal(false),
  defaultAddressUuid: z.string().uuid().nullable(),
  businessDivisionUuid: z.string().uuid().nullable(),
  invoiceOrOrderIdentifier: z.string().min(1, 'Identifier is required'),
  defaultServiceUuid: z.string().uuid().nullable(),
  defaultPointToPointTariffChainId: z.string().uuid().nullable(),
  defaultFuelSurcharge: z.coerce.number().nullable(),
  defaultDimFactor: z.coerce.number().nullable(),
  defaultLineHaulTariffChainId: z.string().uuid().nullable(),
  defaultOrdinaryTariffChainId: z.string().uuid().nullable(),
  defaultTransferTariffChainId: z.string().uuid().nullable(),
  defaultUseTariff: z.boolean().nullable(),
  isPrepaidOnly: z.boolean().nullable(),
  enableCustomerPortalOrderEntry: z.boolean().nullable(),
  showEdiReferenceNumbersOnOrderPage: z.boolean().nullable(),
  serviceUuids: z.array(z.string().uuid()).nullable(),
  vehicleTypeUuids: z.array(z.string()).nullable(),
  useCustomVehicleTypes: z.boolean().nullable(),
  referenceNumberLabels: z.array(z.object({ name: z.string() })).nullable(),
};

const agentOnlyOrganizationSchema = baseOrganizationSchema.extend(agentFields);
export type AgentOnlyOrganizationFormValues = z.infer<
  typeof agentOnlyOrganizationSchema
>;

const customerOnlyOrganizationSchema =
  baseOrganizationSchema.extend(customerFields);
export type CustomerOnlyOrganizationFormValues = z.infer<
  typeof customerOnlyOrganizationSchema
>;

export const agentAndCustomerOrganizationSchema = baseOrganizationSchema.extend(
  {
    ...agentFields,
    ...customerFields,
    isAgent: z.literal(true),
    isCustomer: z.literal(true),
  },
);
export type AgentAndCustomerOrganizationFormValues = z.infer<
  typeof agentAndCustomerOrganizationSchema
>;

export const getOrganizationSchema = (
  organizationTypes: OrganizationType[],
) => {
  if (
    organizationTypes.includes(OrganizationType.Agent) &&
    organizationTypes.includes(OrganizationType.Customer)
  ) {
    return {
      schema: agentAndCustomerOrganizationSchema,
      variant: OrganizationFormVariant.AGENT_AND_CUSTOMER,
      isAgent: true,
      isCustomer: true,
    };
  }
  if (organizationTypes.includes(OrganizationType.Agent)) {
    return {
      schema: agentOnlyOrganizationSchema,
      variant: OrganizationFormVariant.AGENT,
      isAgent: true,
      isCustomer: false,
    };
  }
  if (organizationTypes.includes(OrganizationType.Customer)) {
    return {
      schema: customerOnlyOrganizationSchema,
      variant: OrganizationFormVariant.CUSTOMER,
      isAgent: false,
      isCustomer: true,
    };
  }
  // by default return this but our form validation
  // should fail because you need a type selected.
  return {
    schema: customerOnlyOrganizationSchema,
    variant: OrganizationFormVariant.CUSTOMER,
    isAgent: false,
    isCustomer: true,
  };
};
