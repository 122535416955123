import { ChevronRight, QrCode } from '@mui/icons-material';
import {
  Chip,
  Link,
  Stack,
  type StepIconProps,
  StepLabel,
  Typography,
  styled,
} from '@mui/material';
import dayjs from 'dayjs';
import { isEmpty, isNil } from 'lodash';
import {
  LineHaulManifestStatus,
  type TerminalLineHaulStatusFragment,
} from '../../../../../../../generated/graphql';
import theme from '../../../../../../../theme';
import LineHaulPartialPiecesWarning from '../../../../../../line-haul/components/common/line-haul-partial-pieces-warning';

const QontoStepIconRoot = styled('div')<{
  ownerState: { active?: boolean; completed?: boolean };
}>(({ ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(!isNil(ownerState.completed) &&
    ownerState.completed && {
      color: 'black',
    }),
  ...(!isNil(ownerState.active) &&
    ownerState.active && {
      color: 'black',
    }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

const QontoStepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active, completed }} className={className}>
      <div className="QontoStepIcon-circle" />
    </QontoStepIconRoot>
  );
};

const ManifestLink = styled(Link)`
  cursor: pointer;
  direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0070e0;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const StatusChip = styled(Chip)<{ terminalStatus?: LineHaulManifestStatus }>`
  width: fit-content;
  font-size: 12px;
  margin: auto;
  ${({ terminalStatus }) => {
    switch (terminalStatus) {
      case LineHaulManifestStatus.Departed: {
        return `
            background-color: #b8dbf7;
            color: #0d47a1;
            border-color: #3c82f6;
        `;
      }
      case LineHaulManifestStatus.Arrived: {
        return `
            background-color: #ffecb3;
            color: #e65200;
            border-color: #ffc403;
        `;
      }
      default: {
        return `
            background-color: #f0f0f0;
            color: black;
        `;
      }
    }
  }}
`;

const LineHaulTerminalStepLabel = ({
  status,
  expectedPieceCount,
}: {
  readonly status: TerminalLineHaulStatusFragment;
  readonly expectedPieceCount: number;
}) => {
  const getScanIndicator = (isDeparture: boolean) => {
    if (
      (isDeparture && isEmpty(status.departureScans)) ||
      (!isDeparture && isEmpty(status.arrivalScans))
    ) {
      return null;
    }
    const scans = isDeparture ? status.departureScans : status.arrivalScans;
    return (
      <Stack direction="row" alignItems="center" gap={1}>
        <QrCode sx={{ color: 'gray' }} fontSize="small" />
        <Typography variant="caption" color="gray">
          {`${dayjs(scans.at(-1)?.scannedAt).format('MM/DD hh:mm:ss A')}`}
        </Typography>
      </Stack>
    );
  };
  const getStatusContent = () => {
    const getManifestLink = () => {
      const linkDateText = isNil(
        status.manifestDepartingFromThisTerminal?.startedAt,
      ) ? null : (
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '120px',
            fontSize: 12,
          }}
        >{`${dayjs(status.manifestDepartingFromThisTerminal?.startedAt).format('MM/DD h:mma')}`}</Typography>
      );
      const linkManifestText = (
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '120px',
            fontSize: 12,
          }}
        >{`${status.manifestDepartingFromThisTerminal?.referenceNumber ?? ''}`}</Typography>
      );
      if (status.manifestDepartingFromThisTerminal?.uuid == null) {
        if (!isNil(status.markedArrivedAt)) {
          return (
            <Typography sx={{ fontSize: 12 }}>
              {dayjs(status.markedArrivedAt).format('MM/DD h:mma')}
            </Typography>
          );
        }
        return null;
      }
      return (
        <Stack>
          <ManifestLink
            href={`/dispatch/line-haul?manifestUuid=${status.manifestDepartingFromThisTerminal.uuid}`}
            target="_blank"
          >
            <Stack>
              {linkManifestText}
              {status.departureScans.length > 0 ||
              status.arrivalScans.length > 0
                ? null
                : linkDateText}
            </Stack>
            <ChevronRight sx={{ cursor: 'pointer' }} fontSize="small" />
          </ManifestLink>
          {!isNil(status.manifestDepartingFromThisTerminal?.driver) && (
            <Typography sx={{ fontSize: 12 }} color={theme.palette.grey[700]}>
              Driver:{' '}
              {status.manifestDepartingFromThisTerminal?.driver?.firstName}{' '}
              {status.manifestDepartingFromThisTerminal?.driver?.lastName}
            </Typography>
          )}
        </Stack>
      );
    };
    if (status.departureScans.length > 0) {
      return (
        <>
          <StatusChip
            label="DEPARTED"
            terminalStatus={LineHaulManifestStatus.Departed}
            variant="outlined"
          />
          <Stack alignItems="flex-start" margin="auto" gap={1}>
            {getManifestLink()}
            {getScanIndicator(true)}
            <LineHaulPartialPiecesWarning
              totalScans={status.departureScans.length}
              totalPieces={expectedPieceCount}
            />
          </Stack>
        </>
      );
    }
    if (status.arrivalScans.length > 0) {
      return (
        <>
          <StatusChip
            label="ARRIVED"
            terminalStatus={LineHaulManifestStatus.Arrived}
            variant="outlined"
          />
          <Stack alignItems="flex-start" margin="auto" gap={1}>
            {getManifestLink()}
            <LineHaulPartialPiecesWarning
              totalScans={status.arrivalScans.length}
              totalPieces={expectedPieceCount}
            />
          </Stack>
        </>
      );
    }
    if (
      status.manifestDepartingFromThisTerminal?.status ===
        LineHaulManifestStatus.Departed ||
      status.manifestDepartingFromThisTerminal?.status ===
        LineHaulManifestStatus.Arrived
    ) {
      return (
        <>
          <StatusChip
            label="DEPARTED"
            terminalStatus={LineHaulManifestStatus.Departed}
            variant="outlined"
          />
          <Stack alignItems="flex-start" margin="auto" gap={1}>
            {getManifestLink()}
            {getScanIndicator(true)}
            <LineHaulPartialPiecesWarning
              totalScans={status.departureScans.length}
              totalPieces={expectedPieceCount}
            />
          </Stack>
        </>
      );
    }
    if (
      status.manifestDepartingFromThisTerminal?.status ===
      LineHaulManifestStatus.Planning
    ) {
      return (
        <>
          <StatusChip
            label="PLANNING"
            terminalStatus={LineHaulManifestStatus.Planning}
          />
          {getManifestLink()}
        </>
      );
    }
    if (!isNil(status.markedArrivedAt)) {
      return (
        <>
          <StatusChip
            label="ARRIVED"
            terminalStatus={LineHaulManifestStatus.Arrived}
            variant="outlined"
          />
          <Stack alignItems="flex-start" margin="auto" gap={1}>
            {getManifestLink()}
            {getScanIndicator(false)}
            <LineHaulPartialPiecesWarning
              totalScans={status.departureScans.length}
              totalPieces={expectedPieceCount}
            />
          </Stack>
        </>
      );
    }

    return (
      <>
        <StatusChip
          label="AWAITING"
          terminalStatus={LineHaulManifestStatus.Planning}
        />
        {getManifestLink()}
      </>
    );
  };
  return (
    <StepLabel
      sx={{ padding: 0, marginTop: 0 }}
      StepIconComponent={QontoStepIcon}
    >
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="flex-start"
        sx={{ fontSize: 12 }}
        gap={1}
      >
        <Typography>{status.terminal.code}</Typography>
        {getStatusContent()}
      </Stack>
    </StepLabel>
  );
};

export default LineHaulTerminalStepLabel;
