import { ReceiptOutlined as OrdersIcon } from '@mui/icons-material';
import { isEmpty } from 'lodash';
import { FeatureFlag } from '../../../common/feature-flags';
import useFeatureFlag from '../../../common/react-hooks/use-feature-flag';
import { useWmsContactsQuery } from '../../../generated/graphql';
import {
  CustomerPortalPage,
  type DashboardDrawerItem,
} from '../../../layouts/dashboard/types';
import {
  CUSTOMER_PORTAL_BOOKING_REQUESTS_PATH,
  CUSTOMER_PORTAL_ORDERS_PATH,
} from '../constants';

const ICON_WIDTH = 16;

const useCustomerPortalDrawerItems = () => {
  const { data: wmsContacts } = useWmsContactsQuery({
    fetchPolicy: 'cache-and-network',
  });
  const hasInventory = !isEmpty(wmsContacts?.wmsContacts ?? []);
  const ffCustomerPortalOrderEntry = useFeatureFlag(
    FeatureFlag.FF_CUSTOMER_PORTAL_ORDER_ENTRY,
  );

  const drawerItems: Array<DashboardDrawerItem<CustomerPortalPage>> = [
    {
      page: CustomerPortalPage.CUSTOMER_PORTAL,
      name: 'Your Freight Portal',
      url: '/customer-portal',
      icon: <OrdersIcon sx={{ width: ICON_WIDTH, aspectRatio: 1 }} />,
      tabs: [
        {
          url: CUSTOMER_PORTAL_ORDERS_PATH,
          name: 'Orders',
        },
        ...(ffCustomerPortalOrderEntry
          ? [
              {
                url: CUSTOMER_PORTAL_BOOKING_REQUESTS_PATH,
                name: 'Booking requests',
              },
            ]
          : []),
        ...(hasInventory
          ? [
              {
                url: '/customer-portal/inventory',
                name: 'Inventory',
              },
            ]
          : []),
      ],
    },
  ];

  return {
    drawerItems,
  };
};

export default useCustomerPortalDrawerItems;
