import { ArrowRightAlt } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Stack,
  Typography,
  useTheme,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Card,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import currency from 'currency.js';
import { isNil } from 'lodash';
import React from 'react';
import { type IndependentSettlementBillLineItemDifferenceFragment } from '../../../../../../../../../generated/graphql';
import { TableCellCustom, TableRowCustom } from './common-table-components';
import StringDifferenceBlock from './string-difference-block';
import CustomChargeDifferenceBlock from './custom-charge-difference-block';
import StringDifferenceRow from './string-difference-row';

type IndependentSettlementBillLineItemsDifferenceBlockProps = {
  readonly lineItems:
    | IndependentSettlementBillLineItemDifferenceFragment[]
    | null
    | undefined;
};

const IndependentSettlementBillLineItemsDifferenceBlock = ({
  lineItems,
}: IndependentSettlementBillLineItemsDifferenceBlockProps) => {
  const theme = useTheme();

  if (isNil(lineItems) || lineItems.length === 0) return null;

  return (
    <Box paddingY={1}>
      {lineItems.map((item, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Accordion key={idx} disableGutters>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              paddingX: '8px',
            }}
          >
            {item.wasAdded ? (
              <Typography color="success.main" fontSize="14px">
                Added settlement for {item.driverName?.newValue ?? ''}
              </Typography>
            ) : item.wasDeleted ? (
              <Typography color="error.main" fontSize="14px">
                Removed settlement for {item.driverName?.oldValue ?? ''}
              </Typography>
            ) : item.driverName?.oldValue === item.driverName?.newValue ? (
              <Typography fontSize="14px">
                Changed settlement for {item.driverName?.newValue ?? ''}
              </Typography>
            ) : (
              <Stack direction="row" alignItems="center" spacing={1}>
                <StringDifferenceBlock
                  isOldValueBeingDisplayed
                  oldValue={item.driverName?.oldValue}
                  newValue={item.driverName?.newValue}
                  wasEntireEntityAdded={item.wasAdded}
                  wasEntireEntityDeleted={item.wasDeleted}
                />
                <ArrowRightAlt />
                <StringDifferenceBlock
                  isOldValueBeingDisplayed={false}
                  oldValue={item.driverName?.oldValue}
                  newValue={item.driverName?.newValue}
                  wasEntireEntityAdded={item.wasAdded}
                  wasEntireEntityDeleted={item.wasDeleted}
                />
              </Stack>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <Table size="small" sx={{ border: 'none' }}>
              <colgroup>
                <col width="25%" />
                <col width="36%" />
                <col width="3%" />
                <col width="36%" />
              </colgroup>

              <TableBody>
                {!isNil(item.freightCost?.totalAmountCents) && (
                  <StringDifferenceRow
                    fieldName="Freight cost"
                    oldValue={
                      isNil(item.freightCost.totalAmountCents?.oldValue)
                        ? null
                        : currency(
                            item.freightCost.totalAmountCents.oldValue ?? 0,
                            {
                              fromCents: true,
                            },
                          ).format()
                    }
                    newValue={
                      isNil(item.freightCost.totalAmountCents?.newValue)
                        ? null
                        : currency(
                            item.freightCost.totalAmountCents.newValue ?? 0,
                            {
                              fromCents: true,
                            },
                          ).format()
                    }
                    wasEntireEntityAdded={item.wasAdded}
                    wasEntireEntityDeleted={item.wasDeleted}
                  />
                )}

                {!isNil(item.freightCost?.fuelCost?.totalAmountCents) && (
                  <StringDifferenceRow
                    fieldName="Fuel cost"
                    oldValue={
                      isNil(
                        item.freightCost.fuelCost.totalAmountCents?.oldValue,
                      )
                        ? null
                        : currency(
                            item.freightCost.fuelCost.totalAmountCents
                              .oldValue ?? 0,
                            {
                              fromCents: true,
                            },
                          ).format()
                    }
                    newValue={
                      isNil(
                        item.freightCost.fuelCost.totalAmountCents?.newValue,
                      )
                        ? null
                        : currency(
                            item.freightCost.fuelCost.totalAmountCents
                              .newValue ?? 0,
                            {
                              fromCents: true,
                            },
                          ).format()
                    }
                    wasEntireEntityAdded={item.wasAdded}
                    wasEntireEntityDeleted={item.wasDeleted}
                  />
                )}

                {!isNil(item.customCosts) && (
                  <TableRowCustom>
                    <TableCellCustom>Other costs</TableCellCustom>
                    <TableCellCustom>
                      <CustomChargeDifferenceBlock
                        isOldCustomCharges
                        customChargeDifferences={item.customCosts}
                      />
                    </TableCellCustom>
                    <TableCellCustom>
                      <ArrowRightAlt />
                    </TableCellCustom>
                    <TableCellCustom>
                      <CustomChargeDifferenceBlock
                        customChargeDifferences={item.customCosts}
                        isOldCustomCharges={false}
                      />
                    </TableCellCustom>
                  </TableRowCustom>
                )}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default IndependentSettlementBillLineItemsDifferenceBlock;
