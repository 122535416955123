import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { captureException } from '@sentry/react';
import { isEmpty, isNil } from 'lodash';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { type FileRejection } from 'react-dropzone';
import TerminalPicker from '../../../common/components/terminal-picker';
import ConfirmationMessage from '../../../common/components/upload/confirmation-message';
import Dropzone from '../../../common/components/upload/dropzone';
import { type ImageInformation } from '../../../common/components/upload/image-grid';
import useSelectedTerminalUuid from '../../../common/react-hooks/use-selected-terminal-uuid';
import useTerminals from '../../../common/react-hooks/use-terminals';
import { grabFileType } from '../../../common/utils/file';
import useUploadDocuments, { type EoDFile } from '../hooks/useUploadDocuments';
import { EoDImageGrid } from './eod-image-grid';

const FILE_TYPE = new Set(['application/pdf']);

const styles = {
  outerBox: {
    borderRadius: '10px',
    marginTop: '25px',
    padding: '40px',
    backgroundColor: 'white',
  },
} as Record<string, React.CSSProperties>;

const UploadContent = ({
  moveToConfirmationMessage,
}: {
  readonly moveToConfirmationMessage: (numberOfFiles: number) => void;
}) => {
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { selectedTerminalUuid, setSelectedTerminalUuid } =
    useSelectedTerminalUuid();
  const [images, setImages] = useState<ImageInformation[]>([]);
  const [files, setFiles] = useState<EoDFile[]>([]);
  const [shouldShowLoader, setShouldShowLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [terminalUuid, setTerminalUuid] = useState<string | undefined>();
  const { uploadDocuments, loading: submitIsLoading } = useUploadDocuments();

  useEffect(() => {
    setTerminalUuid(selectedTerminalUuid);
  }, [selectedTerminalUuid]);

  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        const newErrorMessage =
          'Please upload a PDF file. You uploaded a file type we do not accept.';
        setErrorMessage(newErrorMessage);
        return [];
      }
      let fileFinishedNumber = 0;
      setShouldShowLoader(true);
      acceptedFiles.map((file) => {
        setErrorMessage('');
        if (!FILE_TYPE.has(file.type)) {
          const fileType = grabFileType(file.name);
          const newErrorMessage =
            fileType !== undefined && fileType.length > 0
              ? `Please upload a PDF file. We do not accept ${fileType} files`
              : `Please upload a PDF file. You uploaded a file type we do not accept.`;
          setErrorMessage(newErrorMessage);
          setShouldShowLoader(false);
          return [];
        }

        const reader = new FileReader();
        reader.addEventListener('load', (e) => {
          fileFinishedNumber += 1;
          if (fileFinishedNumber === acceptedFiles.length) {
            setShouldShowLoader(false);
          }
          setImages((prevState) => [
            ...prevState,
            {
              src: e?.target?.result ?? '',
              name: file.name,
              type: file.type,
            },
          ]);
        });
        reader.readAsDataURL(file);
        setFiles((prevState) => [
          ...prevState,
          { file, uploadedAt: new Date() },
        ]);
        return file;
      });
      setShouldShowLoader(false);
      return [];
    },
    [],
  );

  return (
    <Stack direction="column">
      <Box sx={{ width: '250px' }}>
        {terminalsEnabled && (
          <TerminalPicker
            terminalUuid={terminalUuid}
            setTerminalUuid={setTerminalUuid}
            includeInactiveTerminals={false}
          />
        )}
      </Box>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-evenly',
        }}
      >
        <Dropzone errorMessage={errorMessage} onDrop={onDrop} />
        <EoDImageGrid
          shouldShowLoader={shouldShowLoader}
          images={images}
          setImages={setImages}
          files={files}
          setFiles={setFiles}
        />
      </Box>
      <Box sx={{ justifyContent: 'right', display: 'flex' }}>
        {!isEmpty(images) && (
          <Tooltip
            title={
              terminalsEnabled && isNil(terminalUuid)
                ? 'Terminal is required'
                : undefined
            }
          >
            <span>
              <Button
                startIcon={submitIsLoading && <CircularProgress size={15} />}
                variant="contained"
                className="button"
                disabled={
                  shouldShowLoader ||
                  submitIsLoading ||
                  (terminalsEnabled && isNil(terminalUuid))
                }
                style={{ marginTop: '20px' }}
                onClick={async () => {
                  try {
                    await uploadDocuments({
                      files,
                      onFinish: moveToConfirmationMessage,
                      terminalUuid,
                    });
                    setSelectedTerminalUuid(terminalUuid);
                  } catch (error) {
                    captureException(error);
                    setErrorMessage(
                      'Error in uploading documents. Please contact support.',
                    );
                  }
                }}
              >
                Upload Documents
              </Button>
            </span>
          </Tooltip>
        )}
      </Box>
      {!isNil(errorMessage) && !isEmpty(errorMessage) && (
        <Typography
          sx={{
            color: 'red',
            textAlign: 'center',
            fontSize: '18px',
            mt: '10px',
          }}
        >
          {errorMessage}
        </Typography>
      )}
    </Stack>
  );
};

const UploadEODDocumentsModal = ({
  open,
  setOpen,
}: {
  readonly open: boolean;
  readonly setOpen: (isOpen: boolean) => void;
}) => {
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const [numberOfFiles, setNumberOfFiles] = useState(0);
  const moveToConfirmationMessage = (updatedNumberOfFiles: number) => {
    setNumberOfFiles(updatedNumberOfFiles);
    setShowConfirmationMessage(true);
  };
  const closeModal = () => {
    setOpen(false);
    setShowConfirmationMessage(false);
  };
  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="md"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack sx={styles.outerBox}>
        <IconButton
          sx={{
            position: 'absolute',
            top: '1%',
            right: '1%',
            cursor: 'pointer',
          }}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
        {showConfirmationMessage ? (
          <ConfirmationMessage
            numberOfFiles={numberOfFiles}
            closeModal={closeModal}
          />
        ) : (
          <UploadContent
            moveToConfirmationMessage={moveToConfirmationMessage}
          />
        )}
      </Stack>
    </Dialog>
  );
};

export default UploadEODDocumentsModal;
