// eslint-disable-next-line no-restricted-imports
import { Button, Grid } from '@mui/material';
import { useState } from 'react';
import { shallow } from 'zustand/shallow';
import {
  TariffType,
  TariffZoneType,
  TariffGroupType,
} from '../../../../generated/graphql';
import useTariffGroupStore from './store/tariff-group-state-store';
import TariffGroupFlatRateEditor from './tariff-group-flat-rate-editor';
import TariffGroupGridEditor from './tariff-group-grid-editor';
import TariffMileRangeModal from './tariff-mile-range-modal';
import TariffUniversalRangeModal from './tariff-universal-range-modal';
import TariffZoneGroupRangeModal from './tariff-zone-group-range-modal';
import TariffZoneRangeModal from './tariff-zone-range-modal';

const TariffGroupEditorContainer = () => {
  const [mileRangeModalOpen, setMileRangeModalOpen] = useState(false);
  const [zoneRangeModalOpen, setZoneRangeModalOpen] = useState(false);
  const [universalRangeModalOpen, setUniversalRangeModalOpen] = useState(false);

  const [zoneType, tariffType, tariffGroupType, tariffZoneGroupId] =
    useTariffGroupStore(
      (state) => [
        state.zoneType,
        state.tariffType,
        state.tariffGroupType,
        state.tariffZoneGroupId,
      ],
      shallow,
    );

  const useZoneGroupRangeModal = tariffGroupType === TariffGroupType.Ordinary;

  return (
    <Grid item sx={{ minHeight: '50vh' }} xs={10}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {zoneType === TariffZoneType.Miles && (
            <Button
              onClick={() => {
                setMileRangeModalOpen(true);
              }}
            >
              Edit Mile Ranges
            </Button>
          )}
          {zoneType === TariffZoneType.Location && (
            <Button
              onClick={() => {
                setZoneRangeModalOpen(true);
              }}
            >
              Edit Zone Ranges
            </Button>
          )}
          {zoneType === TariffZoneType.Universal && (
            <Button
              onClick={() => {
                setUniversalRangeModalOpen(true);
              }}
            >
              Edit Universal Range
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          {zoneType === TariffZoneType.Universal &&
            tariffType === TariffType.NoUnits && <TariffGroupFlatRateEditor />}
          {(zoneType === TariffZoneType.Location ||
            zoneType === TariffZoneType.Miles ||
            tariffType === TariffType.PerPiece ||
            tariffType === TariffType.PerHundredPounds) && (
            <TariffGroupGridEditor />
          )}
        </Grid>
      </Grid>
      <TariffUniversalRangeModal
        universalRangeModalOpen={universalRangeModalOpen}
        setUniversalRangeModalOpen={setUniversalRangeModalOpen}
      />
      {useZoneGroupRangeModal ? (
        <TariffZoneGroupRangeModal
          key={tariffZoneGroupId}
          zoneRangeModalOpen={zoneRangeModalOpen}
          setZoneRangeModalOpen={setZoneRangeModalOpen}
        />
      ) : (
        <TariffZoneRangeModal
          zoneRangeModalOpen={zoneRangeModalOpen}
          setZoneRangeModalOpen={setZoneRangeModalOpen}
        />
      )}
      <TariffMileRangeModal
        mileRangeModalOpen={mileRangeModalOpen}
        setMileRangeModalOpen={setMileRangeModalOpen}
      />
    </Grid>
  );
};

export default TariffGroupEditorContainer;
