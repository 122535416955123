import { type WatchQueryFetchPolicy } from '@apollo/client';
import {
  type DispatchViewsQuery,
  useDispatchViewsQuery,
} from '../../../generated/graphql';

export type DispatchView = DispatchViewsQuery['dispatchViews'][0];

const useDispatchViews = ({
  fetchPolicy = 'cache-and-network',
}: { fetchPolicy?: WatchQueryFetchPolicy } = {}) => {
  const {
    data: dispatchViewsData,
    loading,
    refetch: refetchDispatchViews,
  } = useDispatchViewsQuery({
    fetchPolicy,
  });

  return {
    dispatchViews: dispatchViewsData?.dispatchViews,
    loading,
    refetchDispatchViews,
  };
};

export { useDispatchViews };
