import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { isNil } from 'lodash';
import { useState } from 'react';
import { type SettlementBillLineItemSnapshotSchema } from 'shared/settlement-bill-snapshots/settlement-bill-snapshot.schema';
import { OrderDialog } from '../../orders/components/order-dialog';
import { SettlementBillSnapshotLineItemsRow } from './settlement-bill-snapshot-line-items-row';

type SettlementBillLineItemsTableProps = {
  readonly settlementBillLineItems: SettlementBillLineItemSnapshotSchema[];
};

const SettlementBillSnapshotLineItems = ({
  settlementBillLineItems,
}: SettlementBillLineItemsTableProps) => {
  const [openedOrderUuid, setOpenedOrderUuid] = useState<string | null>(null);

  return (
    <Stack direction="column" height="100%">
      <TableContainer sx={{ overflowY: 'auto', flex: 1 }}>
        <Table
          stickyHeader
          aria-label="stop-driver-maps-table"
          size="small"
          sx={{
            '& .MuiTableCell-sizeSmall': {
              padding: '5px 5px',
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Completed at</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Stop type</TableCell>
              <TableCell>HAWB</TableCell>
              <TableCell>Order #</TableCell>
              <TableCell>Total</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {settlementBillLineItems.map((settlementBillLineItem) => (
              <SettlementBillSnapshotLineItemsRow
                key={settlementBillLineItem.id}
                settlementBillLineItem={settlementBillLineItem}
                setOpenedOrderUuid={setOpenedOrderUuid}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <OrderDialog
        open={!isNil(openedOrderUuid)}
        orderUuid={openedOrderUuid}
        onClose={async () => {
          setOpenedOrderUuid(null);
        }}
      />
    </Stack>
  );
};

export default SettlementBillSnapshotLineItems;
