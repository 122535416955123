import dayjs, { type Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import React, { memo, useEffect, useState } from 'react';
import TimePickerComponent from '../../../../common/components/time-picker-component';
import { DispatchMultiplayerAction } from '../../../../common/multiplayer/types/dispatch';
import useMultiplayer from '../../../../common/multiplayer/use-multiplayer';
import {
  type StopOnRouteFragment,
  useUpdateShipmentMutation,
  useUpdateStopMutation,
} from '../../../../generated/graphql';
import useFetchRoutes from '../../hooks/use-fetch-routes';
import useRouteActions from '../../hooks/use-route-actions';

const RouteAppointmentTimeField = ({
  stop,
  baseDate,
  isStart,
}: {
  readonly stop: StopOnRouteFragment;
  readonly baseDate: Dayjs | undefined;
  readonly isStart: boolean;
}) => {
  const { sendDispatchUserLocationEvent } = useMultiplayer();
  const [appointmentTime, setAppointmentTime] = useState<
    string | null | undefined
  >();
  const { fetchRoute } = useFetchRoutes();
  const { calculateRouteEta } = useRouteActions();
  const [updateStop] = useUpdateStopMutation();
  const [updateShipment] = useUpdateShipmentMutation();

  useEffect(() => {
    const time = isStart ? stop.appointmentTime : stop.endAppointmentTime;
    if (isNil(time)) {
      setAppointmentTime(undefined);
    } else {
      setAppointmentTime(time);
    }
  }, [stop.appointmentTime, stop.endAppointmentTime]);

  const routeUuid = stop.routeSlot?.route?.uuid;

  const name = isStart ? 'Start' : 'End';

  const updateAppointment = async () => {
    const timeUpdateInput = isNil(appointmentTime)
      ? null
      : new Date(appointmentTime);
    const standardShipmentFieldsUuid =
      stop.shipment?.standardShipmentFields?.uuid;
    const shipmentUuid = stop.shipment?.uuid;
    if (!isNil(standardShipmentFieldsUuid) && !isNil(shipmentUuid)) {
      await updateShipment({
        variables: {
          updateShipmentInput: {
            shipmentUpdateInput: {
              uuid: shipmentUuid,
              standardShipmentFieldsUpdateInput: {
                uuid: standardShipmentFieldsUuid,
                deliveryDate: baseDate?.toDate(),
              },
            },
          },
        },
      });
    }
    await updateStop({
      variables: {
        updateStopInput: {
          stopUpdateInput: {
            uuid: stop.uuid,
            appointmentTime: isStart ? timeUpdateInput : undefined,
            endAppointmentTime: isStart ? undefined : timeUpdateInput,
          },
        },
      },
    });

    if (!isNil(routeUuid)) {
      await calculateRouteEta(routeUuid);
      await fetchRoute(routeUuid);
    }
  };

  return (
    <TimePickerComponent
      hideClearable
      hideError
      minimized
      placeholder={`${name} Appt`}
      appointmentTime={appointmentTime}
      minimizedWidth={65}
      updateAppointmentTime={(apptTime: Dayjs | null | undefined) => {
        if (isNil(apptTime)) {
          setAppointmentTime(null);
        } else {
          const newDate = baseDate ?? dayjs();
          setAppointmentTime(
            newDate
              .hour(apptTime.hour())
              .minute(apptTime.minute())
              .second(apptTime.second())
              .toISOString(),
          );
        }
      }}
      onFocus={() => {
        sendDispatchUserLocationEvent({
          action: DispatchMultiplayerAction.EDITING,
          stopUuid: stop.uuid,
        });
      }}
      onBlur={() => {
        updateAppointment();
      }}
    />
  );
};

export default memo(RouteAppointmentTimeField);
