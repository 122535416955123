import { isNil } from 'lodash';
import { z } from 'zod';
import { dayjsDate } from '../../../orders/components/order-form/forms/zod-utils';
import {
  ADDRESS_SCHEMA,
  PACKAGE_SCHEMA,
} from '../customer-portal-order-form/forms/customer-portal-order-schema';
import { CustomerPortalInformationLocationType } from '../customer-portal-order-form/forms/enums';

const COMMON_INFORMATION_QUOTE_SCHEMA = z.object({
  serviceDate: dayjsDate('Missing service date'),
  specialInstructions: z.string().trim().optional(),
});

const INFORMATION_WITH_ADDRESS_QUOTE_SCHEMA =
  COMMON_INFORMATION_QUOTE_SCHEMA.merge(
    z.object({
      locationType: z.literal(CustomerPortalInformationLocationType.Address),
      address: ADDRESS_SCHEMA,
    }),
  );

const INFORMATION_WITH_TERMINAL_ID_QUOTE_SCHEMA =
  COMMON_INFORMATION_QUOTE_SCHEMA.merge(
    z.object({
      locationType: z.literal(CustomerPortalInformationLocationType.Terminal),
      terminalId: z
        .string({ required_error: 'Missing terminal' })
        .uuid('Invalid terminal'),
    }),
  );

const INBOUND_INFORMATION_QUOTE_SCHEMA = z.discriminatedUnion('locationType', [
  INFORMATION_WITH_ADDRESS_QUOTE_SCHEMA,
  INFORMATION_WITH_TERMINAL_ID_QUOTE_SCHEMA,
]);

const OUTBOUND_INFORMATION_QUOTE_SCHEMA = z.discriminatedUnion('locationType', [
  INFORMATION_WITH_ADDRESS_QUOTE_SCHEMA,
  INFORMATION_WITH_TERMINAL_ID_QUOTE_SCHEMA,
]);

// Because we are using the same form for both quote and order, we
// use a distinct schema to verify that rating can occur.
export const CUSTOMER_PORTAL_QUOTE_SCHEMA = z
  .object({
    serviceId: z
      .string({ required_error: 'Missing service level' })
      .uuid('Missing service level'),
    packages: z.array(PACKAGE_SCHEMA).default([]),
    inboundInformation: INBOUND_INFORMATION_QUOTE_SCHEMA.optional(),
    outboundInformation: OUTBOUND_INFORMATION_QUOTE_SCHEMA.optional(),
  })
  .refine((data) => {
    if (isNil(data.inboundInformation) && isNil(data.outboundInformation)) {
      return false;
    }
    return true;
  }, 'At least one inbound or outbound stop must be provided for a quote');
