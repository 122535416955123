import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';
import { isNil } from 'lodash';
import { type ChangeEvent, useRef, useState } from 'react';
import { DocumentType } from '../../generated/graphql';
import theme from '../../theme';
import Dropzone from './upload/dropzone';

const modalInnerContainerStyle = {
  bgcolor: 'background.paper',
  boxShadow: 24,
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '20px',
  alignItems: 'center',
};

const DocumentUploadModal = ({
  onClose,
  isOpen,
  documentUploadType,
  handleNewDocument,
  getAwsUrl,
  hideUpload,
}: {
  readonly onClose: () => void;
  readonly isOpen: boolean;
  readonly documentUploadType: DocumentType;
  readonly handleNewDocument: (
    file: File,
    fileName: string,
    documentType: DocumentType,
    name: string | null,
    fileType: string,
  ) => Promise<void>;
  readonly getAwsUrl: (
    fileName: string,
    fileType: string,
  ) => Promise<string | undefined>;
  readonly hideUpload?: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [errorMessage, setErrorMessage] = useState('');
  const handleUploadClick = () => {
    // 👇 We redirect the click event onto the hidden input element
    inputRef.current?.click();
  };

  const addDocumentToAWSAndHandle = async (
    url: string,
    file: File,
    fileName: string,
  ) => {
    setIsLoading(true);

    const options = { headers: { 'Content-Type': file.type } };
    const awsRes = await fetch(url, {
      method: 'PUT',
      body: file,
      ...options,
    });
    if (awsRes.status === 200) {
      await handleNewDocument(
        file,
        fileName,
        documentUploadType,
        name,
        file.type,
      );
      onClose();
    }
    setIsLoading(false);
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (isNil(e.target.files)) {
      return;
    }
    setUploadedFile(e.target.files[0] ?? null);

    // If the document isn't 'Other', then we can just use the name of the file.
    if (documentUploadType !== DocumentType.Other) {
      setName(e.target.files[0]?.name ?? null);
    }
  };

  const handleDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setUploadedFile(file ?? null);

    // If the document isn't 'Other', then we can just use the name of the file.
    if (documentUploadType !== DocumentType.Other) {
      setName(file?.name ?? null);
    }
  };

  const onSave = async () => {
    if (isNil(uploadedFile) || isNil(name) || name.length === 0) {
      setErrorMessage('Name and uploaded file are both required.');
    } else {
      setName('');
      setUploadedFile(null);
      const awsUrl = await getAwsUrl(`${uploadedFile.name}`, uploadedFile.type);
      if (!isNil(awsUrl) && awsUrl.length > 0) {
        await addDocumentToAWSAndHandle(
          awsUrl,
          uploadedFile,
          uploadedFile.name,
        );

        // Resets for next file upload.
        setName(null);
        setUploadedFile(null);
        setIsLoading(false);
        setErrorMessage('');
      }
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={() => {
        setName('');
        setUploadedFile(null);
        onClose();
      }}
    >
      <Box sx={modalInnerContainerStyle}>
        <Typography variant="h5" sx={{ marginBottom: '10px' }}>
          Upload document
        </Typography>
        {documentUploadType === DocumentType.Other && (
          <FormControl>
            <Typography sx={{ textAlign: 'center', mb: '5px' }}>
              Name
            </Typography>
            <TextField
              required
              size="small"
              value={name}
              onChange={(event) => {
                setName(event.target.value);
              }}
            />
          </FormControl>
        )}
        <Typography sx={{ textAlign: 'center', mb: '5px', mt: '15px' }}>
          Document
        </Typography>
        <Typography sx={{ textAlign: 'center', mb: '5px', mt: '15px' }}>
          {uploadedFile?.name}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '10px',
          }}
        >
          <Dropzone
            errorMessage=""
            accept={{
              'application/pdf': [],
              'image/png': [],
              'image/jpeg': [],
              'message/rfc822': [], // .eml
            }}
            hideUpload={hideUpload}
            onDrop={handleDrop}
          />
          <Box display="flex" alignItems="center" gap={theme.spacing(2)}>
            <Button onClick={handleUploadClick}>Upload</Button>
            <input
              ref={inputRef}
              type="file"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <Button variant="contained" onClick={onSave}>
              Save
            </Button>
          </Box>
        </Box>
        <Typography sx={{ color: 'red', mt: '10px' }}>
          {errorMessage}
        </Typography>
        {isLoading && <CircularProgress />}
      </Box>
    </Dialog>
  );
};

export default DocumentUploadModal;
