import currency from 'currency.js';
import { useMemo } from 'react';
import { useContactBalancesLazyQuery } from '../../../generated/graphql';

const useContactWithBalances = () => {
  const [
    getContactPaymentsData,
    { data: contactPaymentsData, loading: contactPaymentsDataLoading },
  ] = useContactBalancesLazyQuery();

  const contact = contactPaymentsData?.contact;

  const customerBalance = useMemo(() => {
    return currency(contact?.balance ?? 0);
  }, [contact]);

  const loadContactWithBalances = (contactUuid: string) => {
    getContactPaymentsData({
      variables: {
        uuid: contactUuid,
      },
    });
  };

  return {
    customerBalance,
    contact,
    contactPaymentsDataLoading,
    creditHoldEnabled:
      contact?.__typename === 'CustomerContactEntity'
        ? contact.enableCreditHold === true
        : false,
    loadContactWithBalances,
  };
};

export default useContactWithBalances;
