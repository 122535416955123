// eslint-disable-next-line no-restricted-imports
import { Box, Grid, LinearProgress, Stack, Typography } from '@mui/material';
import { isNil } from 'lodash';
import React from 'react';
import ReactMapGL, { type ViewStateChangeEvent } from 'react-map-gl';
import useLocalStorageState from 'use-local-storage-state';
import { shallow } from 'zustand/shallow';
import { COLORS } from '../../../common/constants';
import useSelectedTerminalUuid from '../../../common/react-hooks/use-selected-terminal-uuid';
import useTerminals from '../../../common/react-hooks/use-terminals';
import useWarehouses from '../../../common/react-hooks/use-warehouses';
import { type GenerateRoutesQuery } from '../../../generated/graphql';
import StopMarker from '../map/components/stop-marker';
import TerminalMarker from '../map/components/terminal-marker';
import { GenerateRouteResultCard } from './generate-route-result-card';
import useGenerateRoutesStore from './use-generate-routes-store';

const getGenerateRoutesErrorMessage = (
  errorMessage: string | null | undefined,
) => {
  if (isNil(errorMessage)) return 'An error occurred - please try again';

  if (errorMessage === 'No solution')
    return 'Unable to generate a solution - try adding more drivers or altering the capacity/time constraints';

  return errorMessage;
};

const GenerateRoutesResult = ({
  generateRoutesData,
  loading,
}: {
  readonly generateRoutesData:
    | GenerateRoutesQuery['generateRoutes']
    | undefined;
  readonly loading: boolean;
}) => {
  const { warehouses } = useWarehouses();
  const { terminals, terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const { selectedTerminalUuid } = useSelectedTerminalUuid();
  const [selectedDriverUuid] = useGenerateRoutesStore(
    (state) => [state.selectedDriverUuid],
    shallow,
  );
  const [viewState, setViewState] = useLocalStorageState(
    'route_map_initial_view_state',
    {
      defaultValue: {
        longitude: 0,
        latitude: 0,
        zoom: 0,
      },
    },
  );

  const handleMapMove = (e: ViewStateChangeEvent) => {
    setViewState({ ...viewState, ...e.viewState });
  };

  if (loading) {
    return (
      <Stack
        sx={{ width: '100%', height: '90%' }}
        alignItems="center"
        justifyContent="center"
      >
        <LinearProgress sx={{ width: '30%' }} />
        <Typography variant="subtitle1">Generating routes...</Typography>
        <Typography variant="caption" color="text.secondary">
          (This can take up to 30 seconds)
        </Typography>
      </Stack>
    );
  }
  return (
    <Grid container sx={{ height: '100%', width: '100%' }} spacing={1}>
      <Grid item xs={4}>
        {isNil(generateRoutesData) ||
        !isNil(generateRoutesData.errorMessage) ? (
          <Typography variant="body2" color="error" sx={{ fontWeight: 'bold' }}>
            {getGenerateRoutesErrorMessage(generateRoutesData?.errorMessage)}
          </Typography>
        ) : (
          <Stack spacing={1}>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
              Routes ({generateRoutesData?.routes?.length})
            </Typography>
            <Box sx={{ height: '70vh', overflowY: 'scroll' }}>
              <Stack spacing={1}>
                {generateRoutesData?.routes?.map((route, idx) => (
                  <GenerateRouteResultCard
                    key={route.driver.driverReferenceNumber}
                    route={route}
                    headerColor={COLORS[idx] ?? 'black'}
                  />
                ))}
              </Stack>
            </Box>
          </Stack>
        )}
      </Grid>
      <Grid item xs={8}>
        <ReactMapGL
          latitude={viewState.latitude}
          longitude={viewState.longitude}
          zoom={viewState.zoom}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          mapboxAccessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN}
          doubleClickZoom={false}
          onMove={handleMapMove}
        >
          {generateRoutesData?.routes
            ?.filter(
              (r) =>
                isNil(selectedDriverUuid) ||
                selectedDriverUuid === r.driver.uuid,
            )
            .map((route) => {
              return route.stops.map((stop, idx) => {
                return (
                  <StopMarker
                    key={stop.stop.uuid}
                    color={
                      COLORS[
                        generateRoutesData?.routes?.findIndex(
                          (r) => r.driver.uuid === route.driver.uuid,
                        ) ?? 0
                      ] ?? 'black'
                    }
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    stop={stop.stop}
                    ordinal={idx + 1}
                  />
                );
              });
            })}
          {!terminalsEnabled &&
            warehouses?.map((warehouse) => (
              <TerminalMarker
                key={warehouse.uuid}
                address={warehouse.address}
                text={warehouse.name}
                color="darkblue"
              />
            ))}
          {terminals
            .filter(
              (t) =>
                isNil(selectedTerminalUuid) ||
                (!isNil(selectedTerminalUuid) &&
                  selectedTerminalUuid === t.uuid),
            )
            .map((terminal) => (
              <TerminalMarker
                key={terminal.uuid}
                address={terminal.address}
                text={terminal.code}
                color="darkblue"
              />
            ))}
        </ReactMapGL>
      </Grid>
    </Grid>
  );
};

export default React.memo(GenerateRoutesResult);
