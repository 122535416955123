import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { OrganizationPageMode } from '../../enums';
import { type OrganizationFormValues } from '../../form/types';
import { useStyles } from '../../styles';

type CustomerPortalConfigCardProps = {
  readonly mode: OrganizationPageMode;
};

const CustomerPortalConfigCard = ({ mode }: CustomerPortalConfigCardProps) => {
  const styles = useStyles();
  const { control } = useFormContext<OrganizationFormValues>();

  return (
    <Card sx={{ ...styles.card }}>
      <CardContent sx={styles.cardContent}>
        <Stack gap={2}>
          <Typography variant="h6">Customer Portal</Typography>
          <Controller
            name="enableCustomerPortalOrderEntry"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value ?? false}
                    disabled={mode === OrganizationPageMode.VIEW}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                    }}
                  />
                }
                label={
                  <>
                    <Typography>Enable customer portal order entry</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Allow this customer to get quotes and submit orders for
                      review through the customer portal
                    </Typography>
                  </>
                }
              />
            )}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

export default CustomerPortalConfigCard;
