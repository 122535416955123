import { type DropResult } from '@hello-pangea/dnd';
import { Box, Stack } from '@mui/material';
import { isNil } from 'lodash';
import React, { useLayoutEffect, useState } from 'react';

import {
  type FilterViewPage,
  type SavedFilterViewFragment,
} from '../../../../../generated/graphql';
import { type DefaultFilterTabsConfigs } from '../../types';
import AllViewsButton from './all-views-button';
import OrderTableTab, { TAB_MAX_WIDTH } from './order-table-tab';
import useTabOrder from './use-tab-order';
import useViewTabs from './use-view-tabs';

const ALL_VIEWS_BUTTON_WIDTH = '130px';
const HIDDEN_TAB_AND_VIEWS_BUTTON_GAP = '8px';

type OrderTableTabsNewProps<DefaultFilterTabsType> = {
  readonly pageType: FilterViewPage;
  readonly canWriteOrders: boolean;
  readonly savedViews: SavedFilterViewFragment[] | undefined;
  readonly currentTab: string | DefaultFilterTabsType;
  readonly defaultFilterTabsConfigs: DefaultFilterTabsConfigs<DefaultFilterTabsType>;
  readonly shouldAllowSavedViews: boolean;
  readonly handleEditSavedViewName: (uuid: string, displayName: string) => void;
  readonly handleSetIsViewShared: (uuid: string, isShared: boolean) => void;
  readonly handleDeleteSavedView: (uuid: string) => void;
  readonly showCreateSavedViewModal: (viewIsFromScratch: boolean) => void;
  readonly onChangeTab: ({
    newTab,
    newView,
  }: {
    newTab: string | DefaultFilterTabsType;
    newView: SavedFilterViewFragment | undefined;
  }) => void;
};

const OrderTableTabsNew = <DefaultFilterTabsType,>({
  pageType,
  canWriteOrders,
  savedViews,
  currentTab,
  defaultFilterTabsConfigs,
  shouldAllowSavedViews,
  handleEditSavedViewName,
  handleSetIsViewShared,
  handleDeleteSavedView,
  showCreateSavedViewModal,
  onChangeTab,
}: OrderTableTabsNewProps<DefaultFilterTabsType>) => {
  const { tabOrder, reorderTabs } = useTabOrder(
    defaultFilterTabsConfigs,
    savedViews,
  );

  const viewTabs = useViewTabs({
    shouldAllowSavedViews,
    savedViews,
    tabOrder,
    defaultFilterTabsConfigs,
    handleSetIsViewShared,
    handleDeleteSavedView,
    handleEditSavedViewName,
    pageType,
  });

  // State to track the index of the selected tab that is hidden from the visible tabs view
  const [selectedTabHiddenIndex, setSelectedTabHiddenIndex] = useState<
    number | null
  >(null);

  const onDragDropdownTab = (result: DropResult) => {
    if (isNil(result.destination)) {
      return;
    }
    reorderTabs(result.source.index, result.destination.index);
  };

  const onTabChange = (newTableValue: string | DefaultFilterTabsType) => {
    const savedViewTab = savedViews?.find(
      (view) => view.uuid === newTableValue,
    );
    onChangeTab({ newTab: newTableValue, newView: savedViewTab });
  };

  const onAddView = () => {
    if (!canWriteOrders) {
      return;
    }
    showCreateSavedViewModal(true);
  };

  const tabsContainerRef = React.useRef<HTMLDivElement>(null);
  const [visibleTabsWidth, setVisibleTabsWidth] = useState<number>(0);

  useLayoutEffect(() => {
    const checkIfItemsFitInContainer = () => {
      const tabsContainer = tabsContainerRef.current;
      if (isNil(tabsContainer)) {
        return;
      }
      const tabs = tabsContainer.querySelectorAll('.order-table-view-tab');
      if (tabs.length === 0) {
        return;
      }
      const containerWidth = tabsContainer.clientWidth;
      let widthIteratingLeftToRight = 0;
      let currentVisibleTabsWidth = 0;
      for (const [index, tab] of tabs.entries()) {
        widthIteratingLeftToRight += tab.clientWidth;
        if (widthIteratingLeftToRight <= containerWidth) {
          tab.classList.remove('tab-overflow-visibility-hidden');
          tab.classList.add('.tab-overflow-visibility-visible');
          currentVisibleTabsWidth += tab.clientWidth;

          const ariaSelected = tab.getAttribute('aria-selected');

          if (ariaSelected === 'true') {
            setSelectedTabHiddenIndex(null);
          }
        } else {
          tab.classList.add('tab-overflow-visibility-hidden');
          tab.classList.remove('.tab-overflow-visibility-visible');

          const ariaSelected = tab.getAttribute('aria-selected');
          if (ariaSelected === 'true') {
            setSelectedTabHiddenIndex(index);
          }
        }
      }
      setVisibleTabsWidth(currentVisibleTabsWidth);
    };
    window.addEventListener('resize', checkIfItemsFitInContainer);
    window.addEventListener('load', checkIfItemsFitInContainer);
    globalThis.addEventListener('DOMContentLoaded', checkIfItemsFitInContainer);
    checkIfItemsFitInContainer();
    return () => {
      window.removeEventListener('resize', checkIfItemsFitInContainer);
      window.removeEventListener('load', checkIfItemsFitInContainer);
      globalThis.removeEventListener(
        'DOMContentLoaded',
        checkIfItemsFitInContainer,
      );
    };
  }, [viewTabs]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      minWidth={0}
      position="relative"
      paddingRight={`calc(${HIDDEN_TAB_AND_VIEWS_BUTTON_GAP} + ${TAB_MAX_WIDTH} + ${ALL_VIEWS_BUTTON_WIDTH})`}
    >
      <Stack ref={tabsContainerRef} width="100%" flexDirection="row">
        {viewTabs.map((tab) => (
          <OrderTableTab
            key={String(tab.value)}
            className="order-table-view-tab"
            data-testid={tab.testId}
            label={tab.label}
            aria-selected={tab.value === currentTab}
            onClick={() => {
              onTabChange(tab.value);
            }}
          />
        ))}
      </Stack>
      {shouldAllowSavedViews && (
        <Stack
          position="absolute"
          left={`${visibleTabsWidth}px`}
          width={ALL_VIEWS_BUTTON_WIDTH}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          {!isNil(selectedTabHiddenIndex) && (
            <OrderTableTab
              aria-selected
              className="order-table-view-tab"
              data-testid="selected-tab"
              label={viewTabs[selectedTabHiddenIndex]?.label}
              sx={{ maxWidth: TAB_MAX_WIDTH }}
            />
          )}
          <Box paddingLeft={HIDDEN_TAB_AND_VIEWS_BUTTON_GAP}>
            <AllViewsButton<DefaultFilterTabsType | string>
              viewTabs={viewTabs}
              onChange={onTabChange}
              onDragEnd={onDragDropdownTab}
              onAddView={onAddView}
            />
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

// React.memo doesn't work with generic props types
const typedMemo: <T>(c: T) => T = React.memo;

export default typedMemo(OrderTableTabsNew);
