import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TableCell,
  TableRow,
  TextField,
  Typography,
  useTheme,
  Stack,
} from '@mui/material';
import { getFreightChargeDisplayRate } from 'shared/copy';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import { isEmpty, isNil } from 'lodash';
import { memo, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { safeAdd } from 'shared/math';
import { exhaustive } from 'shared/switch';
import { calculateTotalVolume, calculateTotalWeight } from 'shared/weight';
import { shallow } from 'zustand/shallow';
import {
  FREIGHT_CHARGE_NEW_TARIFF_AUTO_APPLIED_MESSAGE_TEST_ID,
  FREIGHT_CHARGE_TOTAL_TEST_ID,
} from '../../../../../../../../constants';
import {
  getOrderChargesFreightChargeTestIds,
  getStopFreightChargeTestIds,
} from '../../../../../../../../utils';
import {
  convertToInches,
  isNilOrEmptyString,
} from '../../../../../../../common/utils/utils';
import {
  FreightBillingMethod,
  OrderStatus,
  ShipmentType,
  TariffType,
  TariffZoneType,
  useTariffLazyQuery,
  useTariffQuery,
} from '../../../../../../../generated/graphql';
import useOrderFormStore from '../../../../../order-form-store';
import { useOrderFormChargesEditAccess } from '../../../contexts/order-form-charges-edit-access-context';
import { type OrderFormFieldValues } from '../../../forms/types';
import { type OrderShipmentContext } from '../../../types';
import { contextToFreightChargePrefix } from '../../../utils';
import { VALID_FREIGHT_CHARGE_BILLING_METHODS_FOR_DISCOUNTS } from '../constants';
import {
  buildNewTariffAppliedMessage,
  type Tariff,
  getTariffGroupNameCopy,
} from '../utils';
import AuthoCodeComponent, {
  MT_IF_HAS_AUTHO_CODE_STYLES,
} from './autho-code-component';
import DescriptionComponent, {
  MT_IF_HAS_DESCRIPTION_STYLES,
} from './description-component';
import MilesComponent from './miles-component';

const HIDE_FROM_BILLING = 'HIDE_FROM_BILLING';

export const QuantityField = ({
  billingMethod,
  tariff,
  context,
  disabled,
}: {
  readonly billingMethod: FreightBillingMethod | undefined;
  readonly tariff: Tariff | undefined;
  readonly context: OrderShipmentContext;
  readonly disabled: boolean;
}) => {
  const { control } = useFormContext<OrderFormFieldValues>();
  const useKilograms = useWatch({ control, name: 'useKilograms' });
  const useCentimeters = useWatch({ control, name: 'useCentimeters' });
  const packages = useWatch({ control, name: 'packages' });
  const dimFactor = useWatch({ control, name: 'dimFactor' });
  const totalWeight = calculateTotalWeight(
    useCentimeters === true
      ? (packages ?? []).map((pkg) => {
          return {
            ...pkg,
            length: convertToInches(pkg.length),
            width: convertToInches(pkg.width),
            height: convertToInches(pkg.height),
          };
        })
      : (packages ?? []),
    dimFactor ?? undefined,
    tariff?.useActualWeight ?? false,
  );
  const totalPieces = packages?.reduce(
    (prev, curr) => safeAdd(prev, curr.quantity ?? 0),
    0,
  );
  const totalVolume = calculateTotalVolume({
    packages:
      useCentimeters === true
        ? (packages ?? []).map((pkg) => {
            return {
              ...pkg,
              length: convertToInches(pkg.length),
              width: convertToInches(pkg.width),
              height: convertToInches(pkg.height),
            };
          })
        : (packages ?? []),
  });

  switch (billingMethod) {
    case FreightBillingMethod.Tariff: {
      if (tariff?.tariffType === TariffType.PerHundredPounds) {
        return (
          <Typography>
            {totalWeight} {useKilograms === true ? 'kgs' : 'lbs'}
          </Typography>
        );
      }
      if (tariff?.tariffType === TariffType.PerPiece) {
        return <Typography>{totalPieces} pieces</Typography>;
      }
      if (tariff?.tariffType === TariffType.PerCubicFoot) {
        return <Typography>{totalVolume} cubic feet</Typography>;
      }

      return <Typography>1</Typography>;
    }
    case FreightBillingMethod.Weight: {
      return <Typography>{totalWeight} lbs</Typography>;
    }
    case FreightBillingMethod.FlatRate: {
      return <Typography>1</Typography>;
    }
    case FreightBillingMethod.PerMile: {
      return (
        <MilesComponent
          shouldSetFreightChargeQuantity
          context={context}
          disabled={disabled}
        />
      );
    }
    case FreightBillingMethod.PerPiece: {
      return <Typography>{totalPieces} pieces</Typography>;
    }
    case null:
    case undefined: {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }
    default: {
      return exhaustive(billingMethod);
    }
  }
};

export const RateField = ({
  context,
  billingMethod,
  tariff,
  disabled,
  loading,
}: {
  readonly context: OrderShipmentContext;
  readonly billingMethod: FreightBillingMethod | undefined;
  readonly tariff: Tariff | undefined;
  readonly disabled: boolean;
  readonly loading: boolean;
}) => {
  const { control, setValue } = useFormContext<OrderFormFieldValues>();
  const namePrefix = contextToFreightChargePrefix(context);
  const rate = useWatch({
    control,
    name: `${namePrefix}.rate`,
  });
  const total = useWatch({
    control,
    name: `${namePrefix}.totalCharge`,
  });

  const tariffGroupName = tariff?.tariffGroup?.name;
  const tariffGroupScope = tariff?.tariffGroup?.tariffGroupScope;
  const tariffGroupType = tariff?.tariffGroup?.tariffGroupType;

  const tariffGroupNameCopy = getTariffGroupNameCopy({
    tariffGroupScope,
    tariffGroupName,
    tariffGroupType,
  });

  const { freightChargeRateSkeletonLoadingTestId } =
    context.shipmentType === ShipmentType.Regular
      ? getStopFreightChargeTestIds({ stopIdx: context.stopIdx })
      : getOrderChargesFreightChargeTestIds();

  const RateInput = (
    <Controller
      control={control}
      name={`${namePrefix}.rate`}
      render={({ field: { value } }) => (
        <FormControl fullWidth>
          <TextField
            disabled={disabled}
            size="small"
            type="number"
            value={value}
            label="Rate"
            InputProps={{
              endAdornment: billingMethod === FreightBillingMethod.Weight && (
                <InputAdornment position="end">/100 lbs</InputAdornment>
              ),
            }}
            onBlur={() => {
              if (!isNil(value)) {
                setValue(`${namePrefix}.rate`, currency(value).value);
              }
            }}
            onChange={(e) => {
              if (e.target.value === '') {
                setValue(`${namePrefix}.rate`, null);
              } else {
                const parsedFloat = Number.parseFloat(e.target.value);
                if (!Number.isNaN(parsedFloat)) {
                  setValue(`${namePrefix}.rate`, parsedFloat);
                }
              }
            }}
          />
        </FormControl>
      )}
    />
  );

  switch (billingMethod) {
    case FreightBillingMethod.Tariff: {
      return loading ? (
        <Skeleton data-testid={freightChargeRateSkeletonLoadingTestId} />
      ) : (
        <>
          <Typography>
            {getFreightChargeDisplayRate({ tariff, rate, total })}
          </Typography>
          {!isNilOrEmptyString(tariffGroupNameCopy) && (
            <Typography variant="caption">{tariffGroupNameCopy}</Typography>
          )}
        </>
      );
    }
    case FreightBillingMethod.Weight: {
      return RateInput;
    }
    case FreightBillingMethod.FlatRate: {
      return RateInput;
    }
    case FreightBillingMethod.PerMile: {
      return RateInput;
    }
    case FreightBillingMethod.PerPiece: {
      return RateInput;
    }
    case null:
    case undefined: {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }
    default: {
      return exhaustive(billingMethod);
    }
  }
};

const FreightChargeRow = ({
  context,
  inBillingReview,
  hideFromBilling,
  setHideFromBilling,
}: {
  readonly context: OrderShipmentContext;
  readonly inBillingReview: boolean;
  readonly hideFromBilling: boolean;
  readonly setHideFromBilling?: (hideFromBilling: boolean) => void;
}) => {
  const { inSettlement } = context;
  const namePrefix = contextToFreightChargePrefix(context);
  const [isOrderPageRating] = useOrderFormStore(
    (state) => [state.isOrderPageRating],
    shallow,
  );

  const theme = useTheme();

  const [searchParams] = useSearchParams();
  const isEditMode = searchParams.has('orderUuid');

  const [getTariffByUuid, { loading: loadingTariff }] = useTariffLazyQuery();
  const { control, setValue } = useFormContext<OrderFormFieldValues>();
  const freightCharge = useWatch({
    control,
    name: `${namePrefix}`,
  });
  const billingMethod = useWatch({
    control,
    name: `${namePrefix}.billingMethod`,
  });
  const tariffUuid = useWatch({
    control,
    name: `${namePrefix}.tariffUuid`,
  });
  const rate = useWatch({
    control,
    name: `${namePrefix}.rate`,
  });
  const discountRate = useWatch({
    control,
    name: `${namePrefix}.discountRate`,
  });
  const total = useWatch({
    control,
    name: `${namePrefix}.totalCharge`,
  });
  const authoCode = useWatch({
    control,
    name: `${namePrefix}.authoCode`,
  });
  const errorMessage = useWatch({
    control,
    name: `${namePrefix}.errorMessage`,
  });
  const status = useWatch({ control, name: 'status' });

  const { editingChargesDisabled } = useOrderFormChargesEditAccess({
    chargesContext: context,
  });

  const savedTariffUuid = useRef(tariffUuid);
  const savedTariff = useRef<Tariff | undefined>(undefined);
  const savedTariffRate = useRef(rate);
  const savedTariffTotal = useRef(total);

  const { data: tariffData } = useTariffQuery(
    isNilOrEmptyString(tariffUuid)
      ? { skip: true }
      : {
          variables: { uuid: tariffUuid },
          fetchPolicy: 'cache-and-network',
          onCompleted: (data) => {
            const retrievedTariff = data.tariff;
            if (tariffUuid === savedTariffUuid.current) {
              savedTariff.current = retrievedTariff;
              savedTariffRate.current = rate;
              savedTariffTotal.current = total;
            }
          },
        },
  );

  const tariff = tariffData?.tariff;
  // savedTariff.current = tariff;

  if (isNil(freightCharge)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  const freightChargeLoading = loadingTariff;

  const formatFreightChargeTotal = () => {
    if (!isEmpty(errorMessage) || freightChargeLoading) {
      return '-';
    }
    if (hideFromBilling) {
      return currency(0).format();
    }
    if (!isNil(total)) {
      return currency(total).format();
    }
    return '-';
  };

  const { freightChargeTypeSelectTestId } = getStopFreightChargeTestIds({
    stopIdx:
      context.shipmentType === ShipmentType.Regular ? context.stopIdx : -1,
  });

  // We aren't supporting per mile freight charges for settlement line items to avoid added complexity.
  // The same thing can be achieved via mileage-based tariffs.
  const billingMethodOptions = Object.values(FreightBillingMethod).filter(
    (method) => (inSettlement ? method !== FreightBillingMethod.PerMile : true),
  );

  return (
    <TableRow data-testid="freight-charge-row">
      <TableCell>
        <FormControl
          sx={{
            ...MT_IF_HAS_DESCRIPTION_STYLES,
            flex: 1,
            width: '100%',
          }}
        >
          <InputLabel>Freight</InputLabel>
          <Select
            data-testid={freightChargeTypeSelectTestId}
            disabled={
              editingChargesDisabled ||
              (!inSettlement &&
                (status === OrderStatus.Finalized ||
                  status === OrderStatus.Invoiced) &&
                hideFromBilling)
            }
            size="small"
            value={hideFromBilling ? HIDE_FROM_BILLING : (billingMethod ?? '')}
            label="Freight"
            onChange={(e) => {
              const parsedValue = e.target.value as FreightBillingMethod;
              if (e.target.value === HIDE_FROM_BILLING) {
                setValue(
                  `${namePrefix}.billingMethod`,
                  FreightBillingMethod.FlatRate,
                );
                setHideFromBilling?.(true);
                setValue(`${namePrefix}.rate`, 0);
                setValue(`${namePrefix}.quantity`, 0);
                setValue(`${namePrefix}.discountRate`, null);
                setValue(`${namePrefix}.totalCharge`, 0);
              } else {
                setValue(`${namePrefix}.billingMethod`, parsedValue);
                setHideFromBilling?.(false);
                if (
                  !VALID_FREIGHT_CHARGE_BILLING_METHODS_FOR_DISCOUNTS.includes(
                    parsedValue,
                  )
                ) {
                  setValue(`${namePrefix}.discountRate`, null);
                }
              }
            }}
          >
            {billingMethodOptions.map((method) => (
              <MenuItem key={method} value={method}>
                {sentenceCase(method)}
              </MenuItem>
            ))}
            {context.shipmentType === ShipmentType.Regular && !inSettlement && (
              // Order Charges shipments and settlement bill line items don't have the 'No Charge' option
              // because it's not possible to hide them from billing
              // (If they're meant to be hidden, the item shouldn't exist in the first place)
              <MenuItem
                key={HIDE_FROM_BILLING}
                value={HIDE_FROM_BILLING}
                disabled={
                  editingChargesDisabled ||
                  ((status === OrderStatus.Finalized ||
                    status === OrderStatus.Invoiced) &&
                    !hideFromBilling)
                }
              >
                Do not bill
              </MenuItem>
            )}
          </Select>
        </FormControl>
        {!hideFromBilling && !inSettlement && (
          <DescriptionComponent
            keyString={`${namePrefix}`}
            disabled={editingChargesDisabled}
          />
        )}
      </TableCell>
      <TableCell>
        {!hideFromBilling && (
          <RateField
            disabled={editingChargesDisabled}
            context={context}
            billingMethod={billingMethod}
            tariff={tariff}
            loading={freightChargeLoading}
          />
        )}
        {tariff?.tariffZone.type === TariffZoneType.Miles &&
          billingMethod === FreightBillingMethod.Tariff &&
          !freightChargeLoading &&
          !inSettlement && (
            <MilesComponent
              context={context}
              disabled={editingChargesDisabled || inBillingReview}
              shouldSetFreightChargeQuantity={false}
            />
          )}
        {isEditMode &&
          isOrderPageRating === false &&
          !isNil(savedTariff) &&
          !isNil(savedTariffUuid.current) &&
          savedTariffUuid.current !== tariffUuid &&
          !freightChargeLoading && (
            <Typography
              data-test-id={
                FREIGHT_CHARGE_NEW_TARIFF_AUTO_APPLIED_MESSAGE_TEST_ID
              }
              sx={{ color: theme.palette.primary.main, fontSize: '14px' }}
            >
              {buildNewTariffAppliedMessage({
                originalTariff: getFreightChargeDisplayRate({
                  tariff: savedTariff.current,
                  rate: savedTariffRate.current,
                  total: savedTariffTotal.current,
                }),
              })}
            </Typography>
          )}
      </TableCell>
      <TableCell>
        {!hideFromBilling && (
          <QuantityField
            disabled={editingChargesDisabled}
            billingMethod={billingMethod}
            tariff={tariff}
            context={context}
          />
        )}
      </TableCell>
      <TableCell>
        <Stack alignItems="flex-end" justifyContent="flex-end">
          <Typography
            sx={MT_IF_HAS_AUTHO_CODE_STYLES}
            data-testid={FREIGHT_CHARGE_TOTAL_TEST_ID}
          >
            {formatFreightChargeTotal()}
          </Typography>
          {!isEmpty(errorMessage) && (
            <Typography
              data-testid="freight-charge-error-message"
              sx={{ color: 'red', fontSize: '14px' }}
            >
              {errorMessage}
            </Typography>
          )}
          {!isNil(discountRate) && (
            <Typography
              sx={{ fontSize: '16px', color: theme.palette.grey[500] }}
            >
              {`Discount Rate: ${discountRate}`}
            </Typography>
          )}
          {!inSettlement && (
            <AuthoCodeComponent
              authoCode={authoCode ?? ''}
              keyString={`${namePrefix}`}
              disabled={editingChargesDisabled}
            />
          )}
        </Stack>
      </TableCell>
      <TableCell />
    </TableRow>
  );
};

export default memo(FreightChargeRow);
