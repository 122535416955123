import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { CircularProgress, IconButton, Stack } from '@mui/material';
import { type TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';

export const OrdersTableDisplayedPagination = ({
  from,
  to,
  count,
}: {
  readonly from: number;
  readonly to: number;
  readonly count: number;
}) => {
  const loadingCount = count === -1;
  return (
    <>
      {from.toLocaleString()}–{to.toLocaleString()} of{' '}
      {loadingCount ? `more` : count.toLocaleString()}
      {loadingCount && <CircularProgress size={10} sx={{ ml: 1 }} />}
    </>
  );
};

export const OrdersTablePaginationActions = (
  props: TablePaginationActionsProps & {
    readonly hasNextPage: boolean;
  },
) => {
  const { count, page, rowsPerPage, onPageChange, hasNextPage } = props;

  const totalPages = count === -1 ? null : Math.ceil(count / rowsPerPage);

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, page + 1);
  };

  return (
    <Stack direction="row" alignItems="center" sx={{ flexShrink: 0 }}>
      <IconButton
        disabled={page === 0}
        aria-label="previous-page-orders-table"
        onClick={handleBackButtonClick}
      >
        <KeyboardArrowLeft />
      </IconButton>
      Page {(page + 1).toLocaleString()} of{' '}
      {totalPages?.toLocaleString() ?? 'more'}
      <IconButton
        disabled={!hasNextPage}
        aria-label="next-page-orders-table"
        onClick={handleNextButtonClick}
      >
        <KeyboardArrowRight />
      </IconButton>
    </Stack>
  );
};
