import { isNil } from 'lodash';
import {
  LineHaulManifestStatus,
  type DetailedLineHaulManifestFragment,
  type ShallowLineHaulSegmentFragment,
} from '../../../generated/graphql';

export const isDepartableManifest = ({
  manifest,
}: {
  manifest: DetailedLineHaulManifestFragment;
}): { result: boolean; reason: string | null } => {
  const result =
    !isNil(manifest.driver) &&
    !isNil(manifest.equipment) &&
    manifest.orderSegments.length > 0;
  if (result) return { result, reason: null };
  let reason = 'The manifest is missing: ';
  if (isNil(manifest.driver)) reason += 'a driver, ';
  if (isNil(manifest.equipment)) reason += 'a vehicle, ';

  if (manifest.orderSegments.length === 0)
    reason = 'The manifest has no orders';

  return { result: false, reason };
};

export const canDeleteSelectedTruckloads = ({
  selectedManifests,
  allManifests,
  selectedUnmanifestedSegments,
}: {
  selectedManifests: DetailedLineHaulManifestFragment[];
  allManifests: DetailedLineHaulManifestFragment[];
  selectedUnmanifestedSegments: ShallowLineHaulSegmentFragment[];
}): { result: boolean; reason: string | null } => {
  const groupsInvalid = new Set<string>();
  const invalidSelectedManifests = selectedManifests.filter((ma) => {
    const selectedManifestsInSameSegment = new Set(
      selectedManifests.filter(
        (mb) =>
          ma.startTerminal.uuid === mb.startTerminal.uuid &&
          ma.endTerminal.uuid === mb.endTerminal.uuid,
      ),
    );

    const allManifestsInSameSegment = allManifests.find(
      (mc) =>
        ma.startTerminal.uuid === mc.startTerminal.uuid &&
        ma.endTerminal.uuid === mc.endTerminal.uuid,
    );

    // if we selected the first load, then it's invalid
    if (
      !isNil(allManifestsInSameSegment) &&
      selectedManifestsInSameSegment.has(allManifestsInSameSegment)
    ) {
      groupsInvalid.add(`${ma.startTerminal.code} - ${ma.endTerminal.code}`);
    }

    return (
      !isNil(allManifestsInSameSegment) &&
      selectedManifestsInSameSegment.has(allManifestsInSameSegment)
    );
  });

  const result =
    invalidSelectedManifests.length === 0 &&
    selectedUnmanifestedSegments.length === 0;

  const groups = [...groupsInvalid];
  return {
    result,
    reason: `Cannot delete first truckload for a segment (${groups.map(
      (g, idx) => `${g}${idx < groups.length - 1 ? ', ' : ''}`,
    )}${
      selectedUnmanifestedSegments.length > 0
        ? `${groups.length > 0 ? ', ' : ''}${selectedUnmanifestedSegments.map(
            (us, idx) =>
              `${us.startTerminal.code}-${us.endTerminal.code}${
                idx < selectedUnmanifestedSegments.length - 1 ? ', ' : ''
              }`,
          )}`
        : ''
    }).`,
  };
};

export const CHIP_COLORS = {
  [LineHaulManifestStatus.Planning]: '#c77c3c',
  [LineHaulManifestStatus.Departed]: '#3ca3c2',
  [LineHaulManifestStatus.Arrived]: '#6f7070',
} as const;
