import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Button,
  Collapse,
  Fade,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import { isNil } from 'lodash';
import { useState, type Dispatch, type SetStateAction } from 'react';
import { type SettlementBillLineItemSnapshotSchema } from 'shared/settlement-bill-snapshots/settlement-bill-snapshot.schema';
import { CHECKBOX_CELL_WIDTH, CompactRow, SettlementTableCell } from './common';
import SettlementBillLineItemCostsTable from './settlement-bill-line-item-costs-table';

type SettlementBillLineItemRowProps = {
  readonly settlementBillLineItem: SettlementBillLineItemSnapshotSchema;
  readonly setOpenedOrderUuid: Dispatch<SetStateAction<string | null>>;
};

export const SettlementBillSnapshotLineItemsRow = ({
  settlementBillLineItem,
  setOpenedOrderUuid,
}: SettlementBillLineItemRowProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const {
    orderUuid,
    orderHawb,
    orderName,
    stopType,
    completedDateString,
    customerName,
  } = settlementBillLineItem;

  return (
    <>
      <CompactRow
        sx={{
          backgroundColor:
            isExpanded || isHovered ? 'rgba(0, 0, 0, 0.02)' : 'transparent',
        }}
        onMouseEnter={() => {
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          setIsHovered(false);
        }}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <SettlementTableCell sx={{ width: CHECKBOX_CELL_WIDTH }}>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded(!isExpanded);
            }}
          >
            {isExpanded ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </IconButton>
        </SettlementTableCell>
        <SettlementTableCell>{completedDateString}</SettlementTableCell>
        <SettlementTableCell>
          <Tooltip title={customerName} placement="top">
            <span>{customerName}</span>
          </Tooltip>
        </SettlementTableCell>
        <SettlementTableCell sx={{ maxWidth: '25px' }}>
          {sentenceCase(stopType)}
        </SettlementTableCell>
        <SettlementTableCell>
          <Tooltip title={orderHawb} placement="top">
            <span>{orderHawb}</span>
          </Tooltip>
        </SettlementTableCell>
        <SettlementTableCell>{orderName}</SettlementTableCell>
        <SettlementTableCell>
          {currency(settlementBillLineItem.totalDollars).format()}
        </SettlementTableCell>
        <TableCell
          align="right"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Fade in={isHovered}>
            <Stack direction="row" sx={{ float: 'right' }} alignItems="center">
              {!isNil(orderUuid) && (
                <Button
                  variant="contained"
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenedOrderUuid(orderUuid);
                  }}
                >
                  Open
                </Button>
              )}
            </Stack>
          </Fade>
        </TableCell>
      </CompactRow>
      <TableRow>
        <SettlementTableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            border: 0,
          }}
          colSpan={8}
        >
          <Collapse unmountOnExit in={isExpanded} timeout="auto">
            <Stack gap={1} paddingLeft="40px">
              <SettlementBillLineItemCostsTable
                costs={settlementBillLineItem.costs}
              />
            </Stack>
          </Collapse>
        </SettlementTableCell>
      </TableRow>
    </>
  );
};
