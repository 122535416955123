import { type AlertColor } from '@mui/material';
import { saveAs } from 'file-saver';
import { isNil } from 'lodash';
import { PDFDocument } from 'pdf-lib';
import { getInvoiceEmailSubject } from 'shared/email';
import { isNilOrEmptyString } from 'shared/string';
import { v4 } from 'uuid';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import apolloClient from '../../apollo-client';
import createPagesForPdf from '../../common/utils/pdf-gen';
import {
  type ContactForInvoiceFragment,
  type DocumentType,
  type EmailSendStatus,
  InvoiceTransmissionMethod,
  InvoiceType,
  MeDocument,
  type MeQuery,
  type MeQueryVariables,
  type ShallowInvoiceWithoutShipmentsFragment,
} from '../../generated/graphql';
import { type DocumentFormatForInvoiceDownload } from '../invoice-old/utils';
import {
  type DownloadProgress,
  DownloadStatus,
  type FileDownload,
  type InvoiceConfig,
  type InvoiceDownload,
  type InvoiceToSendOption,
} from './types/types';

// per BKO-1314, we only want to allow sending PDF invoices until we support backend generation/sending of excel invoices
const ALLOWED_INVOICE_TYPES = new Set([
  InvoiceType.PdfItemized,
  InvoiceType.PdfSummarized,
]);

export enum FileDownloadStatus {
  Hidden = 'hidden',
  Downloading = 'downloading',
  Complete = 'complete',
}
type CreateFileDownloadCallbackArgs = {
  alertSeverity?: AlertColor;
  message?: string;
  autoHideDuration?: number;
};
export type FileDownloadState = {
  status: FileDownloadStatus;
  callbackArgs?: CreateFileDownloadCallbackArgs;
};
type InvoicesState = {
  invoiceDownloads: FileDownload[];
  invoicesToSendOptions: InvoiceToSendOption[];
  selectedInvoiceUuids: string[];
  selectedUnfinalizedInvoiceUuids: string[];
  shouldRefreshInvoiceList: boolean;
  invoiceTotalsCache: Record<string, string>;
  invoiceBalancesCache: Record<string, number>;
  invoiceEmailSendStatusesCache: Record<string, EmailSendStatus>;
  invoiceConfigsByContact: Record<string, InvoiceConfig>;
  selectedInvoiceOrderUuids: string[];
  fileDownloads: string[]; // list of uuids
  fileDownloadsSnackbarState: [FileDownloadState, () => void]; // [state, onClose]
  invoiceUuidsToEmail: string[];
  showInvoiceSendMenu: boolean;
  downloadProgresses: DownloadProgress[];
  orderToRebillUuid: string | undefined;
  invoiceMenuTabIndex: number;
  setInvoiceMenuTabIndex: (tabIndex: number) => void;
};

type InvoicesActions = {
  updateDownloadProgress: ({
    id,
    failed,
    progress,
  }: {
    id: string;
    failed?: boolean;
    progress?: number;
  }) => void;
  deleteDownloadProgress: (id: string) => void;
  downloadInvoiceBatchDocument: (
    document: {
      preSignedGetUrl: string;
      fileType: string;
    },
    fileName?: string,
  ) => void;
  createNewInvoiceDownload: (
    id: string,
    invoices: InvoiceToSendOption[],
    invoiceType: InvoiceType,
    shouldEmail?: boolean,
  ) => void;
  generatePdf: (
    id: string,
    documents: DocumentFormatForInvoiceDownload[],
    invoiceType: InvoiceType,
    shouldSetProgress: boolean,
  ) => Promise<InvoiceDownload>;
  createInvoicesToSendOptions: (
    invoices: ShallowInvoiceWithoutShipmentsFragment[],
    contacts: ContactForInvoiceFragment[] | undefined,
  ) => void;
  setInvoiceEmailSubject: (invoiceUuid: string, subject: string | null) => void;
  setInvoiceNote: (invoiceUuid: string, note: string | null) => void;
  setInvoiceSelectedEmails: (invoiceUuid: string, emails: string[]) => void;
  setInvoiceTransmissionMethod: (
    invoiceUuid: string,
    invoiceTransmissionMethod: InvoiceTransmissionMethod,
  ) => void;
  setInvoiceDownloadType: (
    invoiceUuid: string,
    invoiceDownloadType: InvoiceType,
  ) => void;
  setInvoiceAttachments: (
    invoiceUuid: string,
    invoiceAttachments: DocumentType[],
  ) => void;
  setOrderToRebillUuid: (uuid: string | undefined) => void;
  selectInvoiceUuid: (uuid: string, isUnfinalized: boolean) => void;
  unselectInvoiceUuid: (uuid: string) => void;
  selectAllInvoiceUuids: (
    uuids: Array<{ uuid: string; isUnfinalized: boolean }> | undefined,
  ) => void;
  deselectAllInvoiceUuids: () => void;
  selectInvoiceOrderUuid: (uuid: string) => void;
  setSelectedInvoiceOrderUuids: (uuids: string[]) => void;
  deselectInvoiceOrderUuid: (uuid: string) => void;
  deselectAllInvoiceOrderUuids: () => void;
  setShouldRefreshInvoiceList: (shouldRefresh: boolean) => void;
  upsertInvoiceTotal: (uuid: string, total: string) => void;
  upsertInvoiceBalance: (uuid: string, balance: number) => void;
  upsertInvoiceEmailSendStatus: (uuid: string, status: EmailSendStatus) => void;
  setInvoiceConfigByContact: ({
    contactUuid,
    invoiceConfig,
  }: {
    contactUuid: string;
    invoiceConfig: InvoiceConfig;
  }) => void;
  setShowInvoiceSendMenu: (show: boolean) => void;
  createFileDownload: (
    completeImmediately?: boolean,
  ) => (args?: CreateFileDownloadCallbackArgs) => void;
  setInvoiceMenuTabIndex: (tabIndex: number) => void;
};

const useInvoicesStore = create(
  immer<InvoicesState & InvoicesActions>((set, get) => ({
    invoiceDownloads: [],
    invoicesToSendOptions: [],
    selectedInvoiceUuids: [],
    selectedUnfinalizedInvoiceUuids: [],
    shouldRefreshInvoiceList: false,
    invoiceTotalsCache: {},
    invoiceConfigsByContact: {},
    invoiceBalancesCache: {},
    invoiceEmailSendStatusesCache: {},
    selectedInvoiceOrderUuids: [],
    fileDownloads: [],
    downloadProgresses: [],
    fileDownloadsSnackbarState: [
      {
        status: FileDownloadStatus.Hidden,
      },
      () => {
        set((state) => {
          state.fileDownloadsSnackbarState[0] = {
            status: FileDownloadStatus.Hidden,
          };
        });
      },
    ],
    invoiceUuidsToEmail: [],
    showInvoiceSendMenu: false,
    orderToRebillUuid: undefined,
    updateDownloadProgress: ({
      id,
      failed,
      progress,
    }: {
      id: string;
      failed?: boolean;
      progress?: number;
    }) => {
      set((state) => {
        const index = state.downloadProgresses.findIndex(
          (download) => download.id === id,
        );
        const download = state.downloadProgresses[index];
        if (!isNil(download)) {
          if (!isNil(failed)) {
            download.failed = failed;
          }
          if (!isNil(progress)) {
            download.progress = progress;
          }
        }
      });
    },
    deleteDownloadProgress: (id: string) => {
      set((state) => {
        const index = state.downloadProgresses.findIndex(
          (download) => download.id === id,
        );
        state.downloadProgresses.splice(index, 1);
      });
    },
    downloadInvoiceBatchDocument: async (
      document: {
        preSignedGetUrl: string;
        fileType: string;
      },
      fileName?: string,
    ) => {
      const id = v4();
      set((state) => {
        state.downloadProgresses.push({
          id,
          progress: 0,
        });
      });
      const response = await fetch(document.preSignedGetUrl);

      if (!response.ok) {
        get().updateDownloadProgress({
          id,
          failed: true,
        });
      }

      const contentLength = Number.parseInt(
        response.headers.get('Content-Length') ?? '0',
        10,
      );
      let downloadedBytes = 0;

      const reader = response.body?.getReader();
      const chunks: Uint8Array[] = [];
      async function read() {
        if (isNil(reader)) {
          return;
        }
        const { done, value } = await reader.read();

        if (done) {
          return;
        }

        // Update the progress
        downloadedBytes += value.length;
        const progress = (downloadedBytes / contentLength) * 100;
        get().updateDownloadProgress({
          id,
          progress,
        });
        chunks.push(value);
        // Continue reading the next chunk
        await read();
      }

      // Start reading chunks
      await read();

      const fileExtension =
        document.fileType === 'application/pdf' ? 'pdf' : 'zip';

      saveAs(
        new Blob(chunks, {
          type: response.headers.get('Content-Type') ?? undefined,
        }),
        isNilOrEmptyString(fileName)
          ? `invoices.${fileExtension}`
          : `${fileName}.${fileExtension}`,
      );
      setTimeout(() => {
        get().deleteDownloadProgress(id);
      }, 3000);
    },
    createNewInvoiceDownload: (
      id: string,
      invoices: InvoiceToSendOption[],
      invoiceType: InvoiceType,
      shouldEmail?: boolean,
    ) => {
      set((state) => {
        state.invoiceDownloads.push({
          invoices,
          id,
          singleUrl: undefined,
          multipleUrl: undefined,
          spreadsheets: undefined,
          invoiceType,
          status: DownloadStatus.InProgress,
          shouldEmail,
          progress: 0,
        });
      });
    },
    generatePdf: async (
      id: string,
      documents: DocumentFormatForInvoiceDownload[],
      invoiceType: InvoiceType,
      shouldSetProgress: boolean,
    ) => {
      const pdfDoc = await PDFDocument.create();
      const promises = [];

      for (let i = 0; i < documents.length; i += 1) {
        if (shouldSetProgress) {
          set((state) => {
            const index = state.invoiceDownloads.findIndex(
              (download) => download.id === id,
            );
            const download = state.invoiceDownloads[index];
            if (!isNil(download)) {
              download.progress = (i + 1) / documents.length;
            }
          });
        }
        const documentObj = documents[i];
        try {
          if (!isNil(documentObj)) {
            const { url, fileType, blob } = documentObj;
            if (!isNil(blob)) {
              promises.push(
                // eslint-disable-next-line no-await-in-loop
                createPagesForPdf(await blob.arrayBuffer(), fileType, pdfDoc),
              );
            } else if (!isNil(url)) {
              // eslint-disable-next-line no-await-in-loop
              const buffer = await fetch(url, { cache: 'no-cache' })
                .then(async (res) => res.arrayBuffer())
                .catch(() => 'error');
              if (!isNil(buffer) && typeof buffer !== 'string') {
                promises.push(createPagesForPdf(buffer, fileType, pdfDoc));
              }
            }
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(
            `Error while generating pdf for invoice ${documentObj?.invoiceUuid} at document ${documentObj?.url} ${documentObj?.fileType}`,
            error,
          );
        }
      }

      await Promise.all(promises);
      const pdfBytes = await pdfDoc.save();
      const file = new Blob([pdfBytes], {
        type: 'application/pdf',
      });

      return {
        downloadLink: URL.createObjectURL(file),
        firstDocumentInvoiceName: documents[0]?.invoiceName ?? '',
        invoiceUuid: documents[0]?.invoiceUuid,
        invoiceType,
      };
    },
    createInvoicesToSendOptions: async (
      invoices: ShallowInvoiceWithoutShipmentsFragment[],
      contacts: ContactForInvoiceFragment[] | undefined,
    ) => {
      const companyResponse = await apolloClient.query<
        MeQuery,
        MeQueryVariables
      >({
        query: MeDocument,
      });

      set((state) => {
        state.invoicesToSendOptions = invoices.map((invoice) => {
          const contact = (contacts ?? []).find(
            (c) => c.uuid === invoice.billToContact.uuid,
          );

          const emailOptions = invoice.emailOptions ?? [];
          const useJournalNumber =
            companyResponse.data.me?.company.configuration
              ?.useJournalNumberForInvoice ?? false;
          const contactDefaultInvoiceEmailSubject =
            contact?.__typename === 'CustomerContactEntity'
              ? (contact.defaultInvoiceEmailSubject ?? '')
              : '';
          const companyDefaultInvoiceEmailSubject =
            companyResponse.data.me?.company.defaultInvoiceEmailSubject ?? '';
          const invoiceEmailSubject = getInvoiceEmailSubject({
            contactDefaultInvoiceEmailSubject,
            companyDefaultInvoiceEmailSubject,
            contact: contact?.displayName ?? '',
            carrier: companyResponse.data.me?.company.name ?? '',
            invoices: [
              {
                name: invoice.name ?? 'invoice',
                journalNumber: invoice.journalNumber ?? 'invoice',
              },
            ],
            key: useJournalNumber ? 'journalNumber' : 'name',
          });

          return {
            invoiceUuid: invoice.uuid,
            invoiceName: invoice.name ?? 'invoice',
            invoiceEmailSubject,
            invoiceNote: null,
            invoiceEmailOptions: emailOptions,
            invoiceSelectedEmails: emailOptions,
            invoiceJournalNumber: invoice.journalNumber ?? 0,
            invoiceContactUuid: invoice.billToContact.uuid,
            invoiceContactDisplayName: invoice.billToContact.displayName,
            invoiceTransmissionMethod:
              !isNil(contact) && contact.__typename === 'CustomerContactEntity'
                ? contact.defaultInvoiceTransmissionMethod
                : InvoiceTransmissionMethod.Download,
            invoiceDownloadType:
              !isNil(contact) &&
              contact.__typename === 'CustomerContactEntity' &&
              ALLOWED_INVOICE_TYPES.has(contact.defaultInvoiceType)
                ? contact.defaultInvoiceType
                : InvoiceType.PdfSummarized,
            invoiceAttachments:
              !isNil(contact) && contact.__typename === 'CustomerContactEntity'
                ? contact?.defaultInvoiceDownloadDocuments
                : [],
          };
        });
      });
    },
    setInvoiceEmailSubject: (invoiceUuid: string, subject: string | null) => {
      set((state) => {
        const invoiceToSendOption = state.invoicesToSendOptions.find(
          (options) => options.invoiceUuid === invoiceUuid,
        );
        if (!isNil(invoiceToSendOption)) {
          invoiceToSendOption.invoiceEmailSubject = subject;
        }
      });
    },
    setInvoiceNote: (invoiceUuid: string, note: string | null) => {
      set((state) => {
        const invoiceToSendOption = state.invoicesToSendOptions.find(
          (options) => options.invoiceUuid === invoiceUuid,
        );
        if (!isNil(invoiceToSendOption)) {
          invoiceToSendOption.invoiceNote = note;
        }
      });
    },
    setInvoiceSelectedEmails: (invoiceUuid: string, emails: string[]) => {
      set((state) => {
        const invoiceToSendOption = state.invoicesToSendOptions.find(
          (options) => options.invoiceUuid === invoiceUuid,
        );

        if (!isNil(invoiceToSendOption)) {
          invoiceToSendOption.invoiceSelectedEmails = emails;
        }
      });
    },
    setInvoiceTransmissionMethod: (
      invoiceUuid: string,
      transmissionMethod: InvoiceTransmissionMethod,
    ) => {
      set((state) => {
        const invoiceToSendOption = state.invoicesToSendOptions.find(
          (options) => options.invoiceUuid === invoiceUuid,
        );
        if (!isNil(invoiceToSendOption)) {
          invoiceToSendOption.invoiceTransmissionMethod = transmissionMethod;
        }
      });
    },
    setInvoiceDownloadType: (
      invoiceUuid: string,
      invoiceDownloadType: InvoiceType,
    ) => {
      set((state) => {
        const invoiceToSendOption = state.invoicesToSendOptions.find(
          (options) => options.invoiceUuid === invoiceUuid,
        );
        if (!isNil(invoiceToSendOption)) {
          invoiceToSendOption.invoiceDownloadType = invoiceDownloadType;
        }
      });
    },
    setInvoiceAttachments: (
      invoiceUuid: string,
      invoiceAttachments: DocumentType[],
    ) => {
      set((state) => {
        const invoiceToSendOption = state.invoicesToSendOptions.find(
          (options) => options.invoiceUuid === invoiceUuid,
        );
        if (!isNil(invoiceToSendOption)) {
          invoiceToSendOption.invoiceAttachments = invoiceAttachments;
        }
      });
    },
    selectInvoiceUuid: (uuid: string, isUnfinalized: boolean) => {
      if (!get().selectedInvoiceUuids.includes(uuid)) {
        set((state) => {
          state.selectedInvoiceUuids.push(uuid);
        });
      }
      if (
        isUnfinalized &&
        !get().selectedUnfinalizedInvoiceUuids.includes(uuid)
      ) {
        set((state) => {
          state.selectedUnfinalizedInvoiceUuids.push(uuid);
        });
      }
    },
    unselectInvoiceUuid: (uuid: string) => {
      set((state) => {
        state.selectedInvoiceUuids = state.selectedInvoiceUuids.filter(
          (invoiceUuid) => invoiceUuid !== uuid,
        );
        state.selectedUnfinalizedInvoiceUuids =
          state.selectedUnfinalizedInvoiceUuids.filter(
            (invoiceUuid) => invoiceUuid !== uuid,
          );
      });
    },
    selectAllInvoiceUuids: (
      uuids: Array<{ uuid: string; isUnfinalized: boolean }> | undefined,
    ) => {
      set((state) => {
        if (uuids) {
          state.selectedInvoiceUuids = uuids.map(({ uuid }) => uuid);
          state.selectedUnfinalizedInvoiceUuids = uuids
            .filter(({ isUnfinalized }) => isUnfinalized)
            .map(({ uuid }) => uuid);
        }
      });
    },
    deselectAllInvoiceUuids: () => {
      set((state) => {
        state.selectedInvoiceUuids = [];
        state.selectedUnfinalizedInvoiceUuids = [];
      });
    },
    setShouldRefreshInvoiceList: (shouldRefresh: boolean) => {
      set((state) => {
        state.shouldRefreshInvoiceList = shouldRefresh;
      });
    },
    upsertInvoiceTotal: (uuid: string, total: string) => {
      set((state) => {
        state.invoiceTotalsCache[uuid] = total;
      });
    },
    upsertInvoiceBalance: (uuid: string, balance: number) => {
      set((state) => {
        state.invoiceBalancesCache[uuid] = balance;
      });
    },
    upsertInvoiceEmailSendStatus: (uuid: string, status: EmailSendStatus) => {
      set((state) => {
        state.invoiceEmailSendStatusesCache[uuid] = status;
      });
    },
    selectInvoiceOrderUuid: (uuid: string) => {
      set((state) => {
        state.selectedInvoiceOrderUuids.push(uuid);
      });
    },
    setSelectedInvoiceOrderUuids: (uuids: string[]) => {
      set((state) => {
        state.selectedInvoiceOrderUuids = uuids;
      });
    },
    deselectInvoiceOrderUuid: (uuid: string) => {
      set((state) => {
        state.selectedInvoiceOrderUuids =
          state.selectedInvoiceOrderUuids.filter(
            (orderUuid) => orderUuid !== uuid,
          );
      });
    },
    deselectAllInvoiceOrderUuids: () => {
      set((state) => {
        state.selectedInvoiceOrderUuids = [];
      });
    },
    setInvoiceConfigByContact: ({
      contactUuid,
      invoiceConfig,
    }: {
      contactUuid: string;
      invoiceConfig: InvoiceConfig;
    }) => {
      set((state) => {
        state.invoiceConfigsByContact[contactUuid] = invoiceConfig;
      });
    },
    setShowInvoiceSendMenu: (show: boolean) => {
      set((state) => {
        state.showInvoiceSendMenu = show;
      });
    },
    createFileDownload: (completeImmediately = false) => {
      const uuid = v4();
      set((state) => {
        if (completeImmediately && state.fileDownloads.length === 0) {
          state.fileDownloadsSnackbarState[0] = {
            status: FileDownloadStatus.Complete,
          };
          return;
        }
        state.fileDownloadsSnackbarState[0] = {
          status: FileDownloadStatus.Downloading,
        };
        state.fileDownloads.push(uuid);
      });
      // user calls this function when a download is finished
      const completeFileDownload = ({
        alertSeverity = 'success',
        message = 'Download complete',
        autoHideDuration = 5000,
      }: CreateFileDownloadCallbackArgs = {}) => {
        set((state) => {
          const idx = state.fileDownloads.indexOf(uuid);
          if (idx === -1) return;
          state.fileDownloads.splice(idx, 1);
          // TODO: properly display calback args for concurrent downloads
          if (state.fileDownloads.length === 0) {
            state.fileDownloadsSnackbarState[0] = {
              status: FileDownloadStatus.Complete,
              callbackArgs: {
                alertSeverity,
                message,
                autoHideDuration,
              },
            };
          }
        });
      };
      return completeFileDownload;
    },
    setOrderToRebillUuid: (uuid: string | undefined) => {
      set((state) => {
        state.orderToRebillUuid = uuid;
      });
    },
    invoiceMenuTabIndex: 0,
    setInvoiceMenuTabIndex: (tabIndex: number) => {
      set((state) => {
        state.invoiceMenuTabIndex = tabIndex;
      });
    },
  })),
);

export default useInvoicesStore;
