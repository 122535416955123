import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { FeatureFlag } from '../../../../../common/feature-flags';
import useFeatureFlag from '../../../../../common/react-hooks/use-feature-flag';
import useMe from '../../../../../common/react-hooks/use-me';
import { useAccessorialsQuery } from '../../../../../generated/graphql';
import { getQuoteSchema } from './quote-schema';
import { type QuoteFormValues } from './types';

const useQuoteForm = () => {
  const { companyData, loading: meLoading } = useMe();
  const ffRecoveryTransferAddressOnly = useFeatureFlag(
    FeatureFlag.FF_RECOVERY_TRANSFER_ADDRESS_ONLY,
  );

  // useOrderFormAccessorials can only be used within a form context :(
  const { data: accessorialsData, loading: accessorialsLoading } =
    useAccessorialsQuery({
      variables: {
        onlyShowCompanyAccessorials: true,
      },
    });
  const loading = meLoading || accessorialsLoading;
  const form = useForm<QuoteFormValues>({
    resolver: zodResolver(
      getQuoteSchema({
        accessorials: accessorialsData?.accessorials ?? [],
        ffRecoveryTransferAddressOnly,
        orderFormFields: companyData?.orderFormFields,
      }),
    ),
    mode: 'all',
    criteriaMode: 'all',
  });

  return { form, loading };
};

export default useQuoteForm;
