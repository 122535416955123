import { type Dispatch, type SetStateAction } from 'react';
import PalletModal from '../../../../pallet-ui/modal/pallet-modal';
import { Button, Stack, Typography } from '@mui/material';
import { LineHaulManifestGroupsDocument } from '../../../../generated/graphql';
import { useApolloClient } from '@apollo/client';
import { useRemoveLineHaulManifestMutation } from '../../../../generated/graphql';
import pluralize from 'pluralize';

type DeleteManifestsModalProps = {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<boolean>>;
  readonly manifestUuids: string[];
  readonly onDeleteComplete: () => void;
};

const DeleteManifestsConfirmationModal = ({
  open,
  setOpen,
  manifestUuids,
  onDeleteComplete,
}: DeleteManifestsModalProps) => {
  // Delete manifests
  const [removeSingleManifest, { loading: deleteLoading }] =
    useRemoveLineHaulManifestMutation();

  const client = useApolloClient();
  const deleteManifests = async () => {
    await Promise.all(
      [...manifestUuids].map(async (uuid) =>
        removeSingleManifest({
          variables: { uuid },
        }),
      ),
    );
    onDeleteComplete();
    // Refetch manifests after deleting
    await client.refetchQueries({
      include: [LineHaulManifestGroupsDocument],
    });
  };

  return (
    <PalletModal
      hideCloseButton
      open={open}
      title={`Are you sure you want to delete ${pluralize('this', manifestUuids.length)} ${pluralize('manifest', manifestUuids.length)}?`}
      pinnedElements={{
        bottomRight: (
          <Stack direction="row" gap={1}>
            <Button
              variant="outlined"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={deleteLoading}
              onClick={deleteManifests}
            >
              Yes, delete {pluralize('manifest', manifestUuids.length)}
            </Button>
          </Stack>
        ),
      }}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Typography>
        This will unassign all orders from{' '}
        {pluralize('this', manifestUuids.length)} line haul{' '}
        {pluralize('segment', manifestUuids.length)}
      </Typography>
    </PalletModal>
  );
};

export default DeleteManifestsConfirmationModal;
