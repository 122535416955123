import { type Dayjs } from 'dayjs';
import {
  type DeadlineType,
  type OrderAction,
  type StopType,
  type StopAction,
} from '../../../../generated/graphql';
import { type Temporal } from 'temporal-polyfill';

export enum StopTypeGroup {
  PickupRecovery = 'Pickup / Recovery',
  DeliveryTransfer = 'Delivery / Transfer',
}

export enum DownloadAction {
  DownloadPodReport = 'Download POD report',
  DownloadPodReportWithUnsignedPods = 'Download POD report with unsigned PODs',
  DownloadCoverSheet = 'Download cover sheet',
  DownloadOutboundLabels = 'Download outbound labels',
  DownloadLotLabels = 'Download lot labels',
}

/**
 * Used to pass data between the bulk actions modal and the preview.
 */
export type BulkActionData = {
  warehouseUuid: string | undefined;
  mawb: string | undefined;
  secondaryReferenceNumber: string | undefined;
  stopTypeGroup: StopTypeGroup | undefined;
  stopTypes: StopType[] | undefined;
  deadlineType: DeadlineType | undefined;
  deadlineDate: Dayjs | undefined;
  orderAction: OrderAction | undefined;
  stopAction: StopAction | undefined;
  downloadActions: DownloadAction[];
  serviceDate: Dayjs | undefined;
  serviceDateV2: Temporal.PlainDate | undefined;
};
