import { Stack, Tab, Tabs } from '@mui/material';
import { useState, useEffect } from 'react';
import TabPanel from '../../common/components/tab-panel/tab-panel';
import SettlementBillsTab from './components/settlement-bills-tab';
import SettlementBillLineItemsTab from './components/settlement-bill-line-items-tab';
import { useSearchParams } from 'react-router-dom';

enum SettlementTab {
  SETTLEMENT_BILL_LINE_ITEMS = 'settlement-bill-line-items',
  SETTLEMENT_BILLS = 'settlement-bills',
}

export const SettlementPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const [currentTab, setCurrentTab] = useState<SettlementTab>(
    SettlementTab.SETTLEMENT_BILL_LINE_ITEMS,
  );

  useEffect(() => {
    setSearchParams((sp) => {
      const newParams = new URLSearchParams(sp);
      newParams.set('tab', currentTab.toString());
      return newParams;
    });
  }, [currentTab, setSearchParams]);

  useEffect(() => {
    if (
      typeof tab === 'string' &&
      Object.values(SettlementTab).includes(tab as SettlementTab)
    ) {
      setCurrentTab(tab as SettlementTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack height="100%" spacing={1}>
      <Tabs
        value={currentTab}
        onChange={(_, value: SettlementTab) => {
          setCurrentTab(value);
        }}
      >
        <Tab
          label="Unsettled stops"
          value={SettlementTab.SETTLEMENT_BILL_LINE_ITEMS}
        />
        <Tab label="Settlement bills" value={SettlementTab.SETTLEMENT_BILLS} />
      </Tabs>
      <TabPanel
        selectedValue={currentTab}
        panelValue={SettlementTab.SETTLEMENT_BILL_LINE_ITEMS}
      >
        <SettlementBillLineItemsTab />
      </TabPanel>
      <TabPanel
        selectedValue={currentTab}
        panelValue={SettlementTab.SETTLEMENT_BILLS}
      >
        <SettlementBillsTab />
      </TabPanel>
    </Stack>
  );
};
