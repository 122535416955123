import { IconButton, Stack, type SxProps, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { type MouseEventHandler, type PropsWithChildren } from 'react';
import { isNil } from 'lodash';
import { type Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

type ArrowIconButtonProps = PropsWithChildren<{
  readonly onClick: MouseEventHandler<HTMLButtonElement>;
}>;
const ArrowIconButton = ({ children, onClick }: ArrowIconButtonProps) => (
  <IconButton
    size="small"
    style={{ backgroundColor: 'transparent' }}
    onClick={onClick}
  >
    {children}
  </IconButton>
);

type PalletDatePickerProps = {
  readonly value: Dayjs | null;
  readonly onChange: (date: Dayjs | null) => void;
  readonly showArrows?: boolean;
  readonly sx?: SxProps;
};

/**
 * A date picker component that is styled to match the pallet design system.
 * @param value - The date currently selected.
 * @param onChange - The callback function that is called when the user selects a date.
 * @param showArrows - Whether to show the left and right arrow icons. These are used to increment the date by one day.
 * @param sx - The style object to apply to the date picker.
 */
const PalletDatePicker = ({
  value,
  onChange,
  showArrows = false,
  sx,
}: PalletDatePickerProps) => {
  const onLeftArrowClick = () => {
    if (isNil(value)) {
      return;
    }
    onChange(value.subtract(1, 'day'));
  };

  const onRightArrowClick = () => {
    if (isNil(value)) {
      return;
    }
    onChange(value.add(1, 'day'));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack direction="row" alignItems="center">
        {showArrows && (
          <ArrowIconButton onClick={onLeftArrowClick}>
            <ChevronLeftRoundedIcon />
          </ArrowIconButton>
        )}
        <DatePicker
          renderInput={(props) => (
            <TextField size="small" placeholder="Value" {...props} sx={sx} />
          )}
          value={value}
          onChange={onChange}
        />
        {showArrows && (
          <ArrowIconButton onClick={onRightArrowClick}>
            <ChevronRightRoundedIcon />
          </ArrowIconButton>
        )}
      </Stack>
    </LocalizationProvider>
  );
};

export default PalletDatePicker;
