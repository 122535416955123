import { Stack } from '@mui/material';
import {
  type CustomerPortalPage,
  type DashboardDrawerItem,
  type DashboardPage,
} from '../types';
import SubpageListRow from './subpage-list-row';

type SubpageListProps = {
  readonly drawerItem: DashboardDrawerItem<DashboardPage | CustomerPortalPage>;
};

const SubpageList = ({ drawerItem }: SubpageListProps) => {
  return (
    <Stack>
      {drawerItem.tabs.map((tab) => (
        <SubpageListRow key={tab.url} tab={tab} />
      ))}
    </Stack>
  );
};

export default SubpageList;
