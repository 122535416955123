import { sentenceCase } from 'change-case';
import currency from 'currency.js';
import { isNil } from 'lodash';
import { filterNotNil } from 'shared/array';
import {
  CustomChargeBillingMethod,
  type SettlementBillLineItemWithCostsFragment,
} from '../../../generated/graphql';
import SettlementBillLineItemCostsTable from './settlement-bill-line-item-costs-table';
import { type SettlementBillLineItemCostSnapshotSchema } from 'shared/settlement-bill-snapshots/settlement-bill-snapshot.schema';

type SettlementBillLineItemCostsSummaryProps = {
  readonly lineItem: SettlementBillLineItemWithCostsFragment;
};

/**
 * For an unfinalized unsnapshotted bill, converts the coszts to a snapshot format to display in table.
 * TODO: Extract a shared conversion fn to turn a cost from the DB into a snapshot cost. This already exists in the backend.
 */
const SettlementBillLineItemCostsSummary = ({
  lineItem,
}: SettlementBillLineItemCostsSummaryProps) => {
  const { freightCost, customCosts } = lineItem;

  const fuelCost = freightCost?.fuelCost;
  const freightCostForDisplay: SettlementBillLineItemCostSnapshotSchema | null =
    isNil(freightCost)
      ? null
      : {
          type: 'Freight',
          name: sentenceCase(freightCost.billingMethod ?? '-'),
          rate: isNil(freightCost.rateUsdDollars)
            ? '-'
            : currency(freightCost.rateUsdDollars ?? 0).format(),
          quantity: freightCost.quantity?.toString() ?? '-',
          fuelSurcharge: '-',
          subtotal: currency(freightCost.totalAmountUsdDollars ?? 0).format(),
          eligible: '100%',
          totalDollars: freightCost.totalAmountUsdDollars ?? 0,
        };

  const fuelCostForDisplay: SettlementBillLineItemCostSnapshotSchema | null =
    isNil(fuelCost)
      ? null
      : {
          type: 'Fuel',
          name: sentenceCase(fuelCost.billingMethod ?? '-'),
          rate: isNil(fuelCost.surchargeRate)
            ? isNil(fuelCost.flatRateUsdDollars)
              ? '-'
              : currency(fuelCost.flatRateUsdDollars ?? 0).format()
            : `${fuelCost.surchargeRate}%`,
          quantity: '1',
          fuelSurcharge: '-',
          subtotal: currency(fuelCost.totalAmountUsdDollars ?? 0).format(),
          eligible: '100%',
          totalDollars: fuelCost.totalAmountUsdDollars ?? 0,
        };

  const customCostsForDisplay:
    | SettlementBillLineItemCostSnapshotSchema[]
    | null = customCosts?.map((customCost) => ({
    type:
      customCost.billingMethod === CustomChargeBillingMethod.Accessorial
        ? 'Accessorial'
        : 'Custom',
    name: customCost.name,
    rate: isNil(customCost.rateUsdDollars)
      ? '-'
      : currency(customCost.rateUsdDollars ?? 0).format(),
    quantity: customCost.quantity?.toString() ?? '-',
    fuelSurcharge: '-',
    subtotal: currency(customCost.totalAmountUsdDollars ?? 0).format(),
    eligible: '100%',
    totalDollars: customCost.totalAmountUsdDollars ?? 0,
  }));

  return (
    <SettlementBillLineItemCostsTable
      costs={filterNotNil([
        freightCostForDisplay,
        fuelCostForDisplay,
        ...(customCostsForDisplay ?? []),
      ])}
    />
  );
};

export default SettlementBillLineItemCostsSummary;
