import { FormHelperText, TextField } from '@mui/material';
import { isNil } from 'lodash';
import { type SyntheticEvent, useMemo } from 'react';
import { type Option } from '../../../../common/types';
import AutocompleteFuzzy from '../../../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import { useOrderFormEditAccess } from '../../../orders/components/order-form/contexts/order-form-edit-access-context';
import useContactsForMeAsThirdPartyUser from '../../hooks/use-contacts-for-me-as-third-party-user';

type CustomerPortalCompanyFieldProps = {
  readonly companyUuid: string | null;
  readonly onChange: (companyUuid: string) => void;
  readonly label?: string;
  readonly required?: boolean;
  readonly error?: boolean;
  readonly disabled?: boolean;
};

const CustomerPortalCompanyField = ({
  companyUuid,
  onChange,
  label,
  required = false,
  error = false,
  disabled = false,
}: CustomerPortalCompanyFieldProps) => {
  const { disabledIfNoAccess } = useOrderFormEditAccess();

  const { distinctCompanies } = useContactsForMeAsThirdPartyUser({
    filterByCustomerPortalOrderEntryEnabled: true,
  });

  const options: Option[] = useMemo(() => {
    return distinctCompanies.map((c) => ({
      value: c.uuid,
      label: c.name,
    }));
  }, [distinctCompanies]);

  const selectedOption = useMemo(() => {
    if (isNil(companyUuid)) return;
    const company = distinctCompanies.find((c) => c.uuid === companyUuid);
    return {
      value: companyUuid,
      label: company?.name ?? '',
    };
  }, [companyUuid, distinctCompanies]);

  const handleChange = (_event: SyntheticEvent, newValue: Option) => {
    onChange(newValue.value);
  };

  return (
    <>
      <AutocompleteFuzzy
        // Without key, the autocomplete doesn't display the selected option
        // when the form is populated (e.g. after fetching an existing order)
        key={selectedOption?.value}
        autoHighlight
        disableClearable
        value={selectedOption}
        matchSortOptions={{ keys: ['label'] }}
        options={options}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            size="small"
            required={required}
            error={error}
          />
        )}
        size="small"
        isOptionEqualToValue={(o, v) => o.value === v?.value}
        disabled={
          disabledIfNoAccess || distinctCompanies.length === 1 || disabled
        }
        onChange={handleChange}
      />
      {error && (
        <FormHelperText sx={{ color: '#D32F2F' }}>
          Carrier is required
        </FormHelperText>
      )}
    </>
  );
};

export default CustomerPortalCompanyField;
