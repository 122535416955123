import { DriveEtaOutlined } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  FormHelperText,
  Paper,
  type PaperProps,
  Stack,
  TextField,
  type TextFieldProps,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { isEmpty, isNil } from 'lodash';
import { useMemo } from 'react';
import { useDriverMinimalQuery } from '../../generated/graphql';
import AutocompleteFuzzy from '../../pallet-ui/autocomplete-fuzzy/autocomplete-fuzzy';
import useDispatchableDrivers, {
  type DispatchableDriverOption,
} from '../react-hooks/use-dispatchable-drivers';

const PaperComponent = (props: PaperProps) => {
  return <Paper sx={{ padding: 0, width: '375px' }} {...props} />;
};

const DispatchableDriversAutocomplete = ({
  disabled,
  selectedDriverUuid,
  terminalUuid,
  driverTooltipText,
  onChangeDriver,
  showTerminalNameForCompanyDrivers,
  textFieldProps,
  onFocus,
}: {
  readonly disabled: boolean;
  readonly selectedDriverUuid: string | null;
  readonly terminalUuid: string | null;
  readonly driverTooltipText: string | null;
  readonly onChangeDriver: (driver: DispatchableDriverOption | null) => void;
  readonly showTerminalNameForCompanyDrivers: boolean;
  readonly textFieldProps?: TextFieldProps;
  readonly onFocus?: () => void;
}) => {
  const { driverOptions: dispatchableDriverOptions, loading } =
    useDispatchableDrivers({
      terminalUuid,
    });

  const theme = useTheme();

  const shouldSkipExistingDriverQuery = useMemo(() => {
    return (
      isNil(selectedDriverUuid) ||
      dispatchableDriverOptions?.some((opt) => opt.uuid === selectedDriverUuid)
    );
  }, [selectedDriverUuid, dispatchableDriverOptions]);

  // in case the selected driver is not in the list of dispatchable drivers, we need to fetch the driver and append.
  const { data: existingDriverData } = useDriverMinimalQuery({
    variables: { uuid: selectedDriverUuid ?? '' },
    skip: shouldSkipExistingDriverQuery,
  });

  // append the existing driver (we don't consider the agent information here)

  const existingDriver: DispatchableDriverOption | null = useMemo(() => {
    if (isNil(existingDriverData?.driver)) {
      return null;
    }

    if (!isNil(existingDriverData.driver.agent)) {
      return {
        isAgent: true,
        uuid: existingDriverData.driver.uuid,
        optionLabel: existingDriverData.driver.user.name ?? '',
        driverType: existingDriverData.driver.driverType ?? null,
        agentId: existingDriverData.driver.agent?.uuid ?? '',
        agentName: existingDriverData.driver.agent?.name ?? '',
      };
    }

    return {
      isAgent: false,
      uuid: existingDriverData.driver.uuid,
      optionLabel: isEmpty(existingDriverData.driver.user.name)
        ? `${existingDriverData.driver.firstName} ${existingDriverData.driver.lastName}`
        : (existingDriverData.driver.user.name ?? ''),
      phoneNumber: existingDriverData.driver.phoneNumber,
      terminalName: existingDriverData.driver.terminal?.name ?? null,
      driverType: existingDriverData.driver.driverType ?? null,
    };
  }, [existingDriverData]);

  if (loading && isNil(dispatchableDriverOptions)) {
    return (
      <Stack direction="row" alignItems="center" gap={1}>
        <CircularProgress size={14} />
        <Typography
          sx={{ fontSize: '14px', color: theme.palette.concreteGrey[50] }}
        >
          Loading drivers...
        </Typography>
      </Stack>
    );
  }

  const finalDriverOptions = isNil(existingDriver)
    ? (dispatchableDriverOptions ?? [])
    : [existingDriver, ...(dispatchableDriverOptions ?? [])];

  const selectedDriver = finalDriverOptions.find(
    (opt) => opt.uuid === selectedDriverUuid,
  );

  return (
    <Box flexGrow={1}>
      <Tooltip title={driverTooltipText}>
        <Box>
          <AutocompleteFuzzy
            size="small"
            value={selectedDriver}
            options={finalDriverOptions}
            PaperComponent={PaperComponent}
            matchSortOptions={{
              keys: ['optionLabel'],
              // this ensures agents appear first
              baseSort: (a, b) => {
                if (a.item.isAgent !== b.item.isAgent) {
                  return a.item.isAgent ? -1 : 1;
                }
                // sorting company drivers by terminal name putting all terminal drivers at the end
                if ('terminalName' in a.item && 'terminalName' in b.item) {
                  const aHasTerminal = !isNil(a.item.terminalName);
                  const bHasTerminal = !isNil(b.item.terminalName);
                  if (aHasTerminal !== bHasTerminal) {
                    return aHasTerminal ? -1 : 1;
                  }
                  if (aHasTerminal && bHasTerminal) {
                    return (a.item.terminalName ?? '').localeCompare(
                      b.item.terminalName ?? '',
                    );
                  }
                }
                return 0;
              },
            }}
            getOptionLabel={(option) => option.optionLabel}
            disabled={disabled}
            groupBy={(option) => {
              if (option.isAgent) {
                return 'Partner agents';
              }
              return 'My drivers';
            }}
            renderOption={(props, option) => {
              if (option.isAgent) {
                return (
                  <li {...props} key={`agent-${option.uuid}`}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      gap={1}
                      width="100%"
                    >
                      <DriveEtaOutlined
                        sx={{
                          color: theme.palette.concreteGrey[50],
                          fontSize: '16px',
                        }}
                      />
                      <Typography
                        sx={{
                          fontSize: '14px',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                        }}
                      >
                        {option.optionLabel}
                      </Typography>
                    </Stack>
                  </li>
                );
              }

              return (
                <li {...props} key={`company-driver-${option.uuid}`}>
                  <Stack
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    sx={{ width: '100%' }}
                  >
                    <Stack sx={{ width: '65%' }}>
                      <Typography
                        sx={{
                          fontSize: '14px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {option.optionLabel}
                      </Typography>
                      {showTerminalNameForCompanyDrivers && (
                        <Typography
                          fontSize="12px"
                          color={theme.palette.concreteGrey[50]}
                        >
                          {option.terminalName ?? 'All terminals'}
                        </Typography>
                      )}
                    </Stack>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        color: theme.palette.concreteGrey[50],
                        flexShrink: 0,
                      }}
                    >
                      {option.phoneNumber}
                    </Typography>
                  </Stack>
                </li>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={params.inputProps}
                size="small"
                label="Driver"
                {...textFieldProps}
              />
            )}
            isOptionEqualToValue={(option, value) => {
              if (isNil(value)) {
                return false;
              }
              return option.uuid === value.uuid;
            }}
            onChange={(_, option) => {
              onChangeDriver(option);
            }}
            onFocus={onFocus}
          />
        </Box>
      </Tooltip>
      {!isNil(selectedDriverUuid) &&
        !isNil(dispatchableDriverOptions) &&
        !dispatchableDriverOptions.some(
          (opt) => opt.uuid === selectedDriverUuid,
        ) && (
          <FormHelperText sx={{ color: theme.palette.warning.main }}>
            Selected driver is no longer available for this terminal
          </FormHelperText>
        )}
    </Box>
  );
};

export default DispatchableDriversAutocomplete;
