import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Alert,
  Divider,
  FormControl,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import { shallow } from 'zustand/shallow';
import { useDriverSettlementBillByUuidV2Query } from '../../../generated/graphql';
import useGlobalStore from '../../../layouts/dashboard/global-store';
import SettlementBillPaymentSummary from './settlement-bill-payment-summary';
import SettlementBillSnapshotLineItems from './settlement-bill-snapshot-line-items';
import SettlementBillsDownloadModal from './settlement-bills-download-modal';
import {
  SETTLEMENT_BILL_SNAPSHOT_SCHEMA,
  SettlementBillSnapshotSchema,
} from 'shared/settlement-bill-snapshots/settlement-bill-snapshot.schema';
import SettlementBillUnfinalized from './settlement-bill-unfinalized';

const SettlementBillSnapshot = ({
  settlementUuid,
  onClose,
}: {
  readonly settlementUuid: string;
  readonly onClose: () => void;
}) => {
  const [setErrorMessage, setShowErrorMessage] = useGlobalStore(
    (state) => [state.setErrorMessage, state.setShowErrorMessage],
    shallow,
  );

  const [openDownloadModal, setOpenDownloadModal] = useState(false);
  const { data: settlementData, loading: dataLoading } =
    useDriverSettlementBillByUuidV2Query({
      variables: {
        uuid: settlementUuid,
      },
      onError: () => {
        setErrorMessage('Error loading settlement bill');
        setShowErrorMessage(true);
      },
    });

  const snapshotParseResult = SETTLEMENT_BILL_SNAPSHOT_SCHEMA.safeParse(
    settlementData?.driverSettlementBill.snapshot,
  );

  if (!snapshotParseResult.success) {
    // eslint-disable-next-line no-console
    console.error(
      {
        billData: settlementData?.driverSettlementBill,
        snapshotParseResult,
      },
      'Error parsing finalized settlement bill snapshot. Rendering unfinalized bill details instead.',
    );
    return (
      <SettlementBillUnfinalized
        settlementUuid={settlementUuid}
        onClose={onClose}
      />
    );
  }

  const settlementBillSnapshot = snapshotParseResult.data;

  return (
    <Stack height="100%" direction="column">
      <SettlementBillsDownloadModal
        isOpen={openDownloadModal}
        settlementBillIds={[settlementUuid]}
        onClose={() => {
          setOpenDownloadModal(false);
        }}
      />
      <Alert severity="info">
        This bill was finalized on {settlementBillSnapshot?.finalizedDateString}
        .
      </Alert>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        padding={1}
        paddingTop={2}
        gap={1}
      >
        <Stack direction="row" gap={1} alignItems="flex-start">
          <Stack direction="column" gap={1}>
            <Stack direction="row" gap={1} alignItems="center">
              <FormControl>
                <TextField
                  disabled
                  label="Name"
                  value={settlementBillSnapshot?.name ?? ''}
                  size="small"
                  style={{ width: 250 }}
                />
              </FormControl>
              <FormControl>
                <TextField
                  disabled
                  label="Settlement date"
                  value={settlementBillSnapshot?.settlementDateString ?? ''}
                  size="small"
                  style={{ width: 130 }}
                />
              </FormControl>
              <FormControl>
                <TextField
                  disabled
                  label="Pay period"
                  value={settlementBillSnapshot?.payPeriod ?? ''}
                  size="small"
                  style={{ width: 180 }}
                />
              </FormControl>
            </Stack>
            <SettlementBillPaymentSummary
              isFinalized
              settlementBillUuid={
                settlementData?.driverSettlementBill.uuid ?? ''
              }
              settlementAdjustments={
                settlementData?.driverSettlementBill.settlementAdjustments ?? []
              }
              paymentInformation={
                settlementData?.driverSettlementBill.paymentInformation
              }
            />
          </Stack>
          <Stack direction="row" gap={1} alignItems="center">
            <IconButton
              disabled={dataLoading}
              size="small"
              onClick={() => {
                setOpenDownloadModal(true);
              }}
            >
              <DownloadIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Stack direction="row" gap={1} alignItems="center">
          <IconButton disabled={dataLoading} size="small" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </Stack>
      <Divider />
      <SettlementBillSnapshotLineItems
        settlementBillLineItems={settlementBillSnapshot?.lineItems ?? []}
      />
    </Stack>
  );
};

export default SettlementBillSnapshot;
