import { Box, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import {
  CurrentBillingPeriodInvoiceDocument,
  type DocumentType,
  InvoicesByUuidsDocument,
  useCreateShipmentDocumentMutation,
  useDeleteDocumentMutation,
  useGenerateShipmentPreSignedPutUrlMutation,
} from '../../../generated/graphql';
import DocumentsCards from '../../end-of-day/components/documents-cards';
import { type DocumentFormField } from '../../orders/redux/documents-values-slice';

export enum DocumentAttachments {
  NoAttachments = 'No Attachments',
  IncludeUnsignedPoDs = 'Include Unsigned PODs',
  IncludeDriverPoDs = 'Include Driver PODs',
  IncludeAllPoDs = 'Include All PODs',
  IncludeAll = 'Include All',
}

export enum DocumentAttachmentsNew {
  NoAttachments = 'No attachments',
  IncludeDigitalPods = 'Include digital PODs',
  IncludeDigitalPodsAndDriverPod = 'Include digital PODs and driver POD',
  IncludeAllExceptShipmentPhotos = 'Include all except shipment photos',
  IncludeAll = 'Include all',
}

type DownloadDocumentsProps = {
  readonly shipmentUuid?: string;
  readonly invoiceUuid?: string;
  readonly documents: DocumentFormField[];
  readonly addDocumentToRedux?: ({
    documentType,
    fileName,
    fileType,
    name,
    bucket,
    region,
    key,
  }: {
    documentType: DocumentType;
    fileName: string;
    fileType: string;
    name: string | null;
    bucket: string;
    region: string;
    key: string;
  }) => void;
};

const DownloadDocuments = ({
  shipmentUuid,
  invoiceUuid,
  documents,
  addDocumentToRedux,
}: DownloadDocumentsProps) => {
  const [filteredDocuments, setFilteredDocuments] = useState<
    DocumentFormField[]
  >([]);
  const [generateShipmentPreSignedPutUrl] =
    useGenerateShipmentPreSignedPutUrlMutation();
  const [addShipmentDocument] = useCreateShipmentDocumentMutation({
    refetchQueries: [
      CurrentBillingPeriodInvoiceDocument,
      InvoicesByUuidsDocument,
    ],
  });
  const [deleteDocumentMutation] = useDeleteDocumentMutation({
    refetchQueries: [
      CurrentBillingPeriodInvoiceDocument,
      InvoicesByUuidsDocument,
    ],
  });

  useEffect(() => {
    setFilteredDocuments(documents);
  }, [documents]);

  const deleteDocument = async (documentId: string) => {
    setFilteredDocuments(
      filteredDocuments.filter((doc) => doc.uuid !== documentId),
    );
    await deleteDocumentMutation({
      variables: { uuid: documentId },
    });
  };

  const getShipmentAwsUrl = async (fileName: string, fileType: string) => {
    if (!isNil(shipmentUuid)) {
      const res = await generateShipmentPreSignedPutUrl({
        variables: {
          generateShipmentPreSignedPutUrlInput: {
            fileName,
            fileType,
            shipmentUuid,
          },
        },
      });
      const putUrl = res.data?.generateShipmentPreSignedPutUrl;
      return isNil(putUrl) ? undefined : { putUrl };
    }
  };

  const updateShipmentWithDocument = async (
    file: File,
    fileName: string,
    documentType: DocumentType,
    name: string | null,
    fileType: string,
  ) => {
    if (!isNil(shipmentUuid)) {
      const res = await addShipmentDocument({
        variables: {
          createShipmentDocumentInput: {
            documentType,
            fileName,
            fileType,
            name,
            shipmentUuid,
          },
        },
      });
      if (
        !isNil(res.data?.createShipmentDocument) &&
        !isNil(addDocumentToRedux)
      ) {
        addDocumentToRedux({
          documentType,
          fileName,
          fileType,
          name,
          bucket: res.data?.createShipmentDocument.bucket ?? '',
          region: res.data?.createShipmentDocument.region ?? 'us-west-1',
          key: res.data?.createShipmentDocument.key ?? '',
        });
      }
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          width: '100%',
          textAlign: 'left',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '20px',
            justifyContent: 'space-between',
          }}
        >
          {!isNil(shipmentUuid) && (
            <Typography align="left" sx={{ fontWeight: 'bold' }}>
              Order attachments ({filteredDocuments.length})
            </Typography>
          )}
          {!isNil(invoiceUuid) && (
            <Typography
              align="left"
              variant="h6"
              sx={{ marginBottom: '20px', mt: '30px' }}
            >
              Invoice Attachments
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            mt: '10px',
            display: 'flex',
            flexDirection: 'row',
            gap: '50px',
          }}
        >
          <DocumentsCards
            showAllDocumentsInOneSection
            getAwsUrl={getShipmentAwsUrl}
            colSize={4}
            docs={filteredDocuments.map((doc) => ({
              fileType: doc.fileType,
              fileName: doc.fileName,
              uuid: doc.uuid,
              preSignedGetUrl: doc.preSignedGetUrl ?? '',
              docType: doc.type,
              driverFormTemplateUuid: doc.driverFormTemplate?.uuid,
              notes: doc.notes,
            }))}
            canModifyDocuments={false}
            onUploadDocument={updateShipmentWithDocument}
            onDeleteDocument={deleteDocument}
          />
        </Box>
        {filteredDocuments.length === 0 && (
          <Typography sx={{ textAlign: 'center', fontSize: '18px' }}>
            No documents
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default DownloadDocuments;
