import { useParams } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import { isNotNilOrEmptyString } from 'shared/string';
import useUserRoles from '../common/react-hooks/use-user-roles';
import Accessorial from '../domains/management/components/accessorials/accessorial';
import Driver from '../domains/management/components/drivers/driver';
import Equipment from '../domains/management/components/equipment';
import ViewLineHaulLane from '../domains/management/components/line-haul/view-line-haul-lane-page';
import PackageSpec from '../domains/management/components/package-specs/package-spec';
import EditTerminal from '../domains/management/components/terminals/edit-terminal-page';
import VehicleType from '../domains/management/components/vehicle-types/vehicle-type';
import { PermissionResource } from '../generated/graphql';
import OrderPage from '../domains/orders/components/order-form/order-page';

export const AccessorialPage = () => {
  const { accessorialUuid } = useParams();
  if (isNotNilOrEmptyString(accessorialUuid)) {
    return <Accessorial uuid={accessorialUuid} contactUuid={null} />;
  }
  return null;
};

export const AddAccessorialPage = () => (
  <Accessorial uuid={null} contactUuid={null} />
);

export const DriverPage = () => {
  const { driverUuid } = useParams();
  const { userPermissions } = useUserRoles();
  const { canWrite } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyDrivers,
  );
  if (isNotNilOrEmptyString(driverUuid)) {
    return (
      <Driver
        pageMode={{
          mode: canWrite ? 'edit' : 'view',
          uuid: driverUuid,
        }}
      />
    );
  }
  return null;
};

export const AddDriverPage = () => <Driver pageMode={{ mode: 'create' }} />;

export const EquipmentPage = () => {
  const { equipmentUuid } = useParams();
  if (isNotNilOrEmptyString(equipmentUuid)) {
    return <Equipment equipmentUuid={equipmentUuid} />;
  }
  return null;
};

export const AddEquipmentPage = () => <Equipment equipmentUuid={null} />;

export const LineHaulLanePage = () => {
  const { laneUuid } = useParams();
  if (isNotNilOrEmptyString(laneUuid)) {
    return <ViewLineHaulLane uuid={laneUuid} />;
  }
  return null;
};

export const PackageSpecPage = () => {
  const { packageTypeId } = useParams();
  const { userPermissions } = useUserRoles();
  const { canWrite } = getPermissionsFlags(
    userPermissions,
    // TODO: Replace with a package-spec-specific permission.
    PermissionResource.CompanyEquipment,
  );
  if (isNotNilOrEmptyString(packageTypeId)) {
    return (
      <PackageSpec
        pageMode={{
          mode: canWrite ? 'edit' : 'view',
          id: packageTypeId,
        }}
      />
    );
  }
  return null;
};

export const AddPackageSpecPage = () => (
  <PackageSpec pageMode={{ mode: 'create' }} />
);

export const RecurringOrderTemplatePage = () => {
  const { templateUuid } = useParams();
  if (isNotNilOrEmptyString(templateUuid)) {
    return <OrderPage fromRecurringTemplatePage orderUuid={templateUuid} />;
  }
  return null;
};

export const AddRecurringOrderTemplatePage = () => (
  <OrderPage fromRecurringTemplatePage orderUuid={null} />
);

export const TerminalPage = () => {
  const { terminalUuid } = useParams();
  if (isNotNilOrEmptyString(terminalUuid)) {
    return <EditTerminal uuid={terminalUuid} />;
  }
  return null;
};

export const VehicleTypePage = () => {
  const { vehicleTypeUuid } = useParams();
  const { userPermissions } = useUserRoles();
  const { canWrite } = getPermissionsFlags(
    userPermissions,
    PermissionResource.CompanyEquipment,
  );
  if (isNotNilOrEmptyString(vehicleTypeUuid)) {
    return (
      <VehicleType
        pageMode={{
          mode: canWrite ? 'edit' : 'view',
          uuid: vehicleTypeUuid,
        }}
      />
    );
  }
  return null;
};

export const AddVehicleTypePage = () => (
  <VehicleType pageMode={{ mode: 'create' }} />
);
