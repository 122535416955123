import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Alert,
  Box,
  Button,
  // eslint-disable-next-line no-restricted-imports
  ButtonGroup,
  Chip,
  Divider,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  InputLabel,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Select,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { sentenceCase } from 'change-case';
import dayjs, { type Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { getPermissionsFlags } from 'shared/roles';
import { shallow } from 'zustand/shallow';
import useUserRoles from '../../../../common/react-hooks/use-user-roles';
import {
  CurrentBillingPeriodInvoiceDocument,
  InvoicesByUuidsDocument,
  InvoiceSource,
  InvoiceStatus,
  PermissionResource,
  type ShallowInvoiceWithoutShipmentsFragment,
  useFinalizeInvoicesMutation,
  useInvoiceForOrderSummaryReportLazyQuery,
  useInvoiceTermsQuery,
  useInvoiceV2LazyQuery,
  useMeQuery,
  useRemoveInvoiceMutation,
  useUnpostInvoiceMutation,
  useUpdateInvoiceLimitedMutation,
} from '../../../../generated/graphql';
import useInvoicesStore from '../../invoices-store';
import { downloadInvoiceOrdersSummaryReport } from '../../utils';
import DownloadInvoiceModal from './download/download-invoice-modal';
import InvoiceOrdersList from './invoice-orders-list';
import InvoicePaymentsList from './invoice-payments-list';
import PostAndSendModal from './post-and-send/post-and-send-modal';
import InvoiceTransmissionsList from './transmissions/invoice-transmissions-list';

type UpdateInput = {
  newName?: string;
  newDate?: Dayjs | null;
  newTermsUuid?: string | null;
};

const InvoiceDetails = ({
  invoiceUuid,
  setOpenedInvoiceUuid,
  setShowInvoiceDetails,
  setIsHeaderCheckboxSelected,
}: {
  readonly invoiceUuid: string;
  readonly setOpenedInvoiceUuid: Dispatch<SetStateAction<string | undefined>>;
  readonly setShowInvoiceDetails: Dispatch<SetStateAction<boolean>>;
  readonly setIsHeaderCheckboxSelected: Dispatch<SetStateAction<boolean>>;
}) => {
  const [, setSearchParams] = useSearchParams();
  const [downloadErrorSnackbarVisible, setDownloadErrorSnackbarVisible] =
    useState(false);
  const [setShouldRefreshInvoiceList, deselectAllInvoiceOrderUuids] =
    useInvoicesStore(
      (state) => [
        state.setShouldRefreshInvoiceList,
        state.deselectAllInvoiceOrderUuids,
      ],
      shallow,
    );
  const { userPermissions } = useUserRoles();
  const { canWrite: canWriteInvoices } = getPermissionsFlags(
    userPermissions,
    PermissionResource.Invoices,
  );
  const { data: companyData } = useMeQuery({
    fetchPolicy: 'cache-first',
  });
  const { data: netTermsData } = useInvoiceTermsQuery({
    fetchPolicy: 'cache-first',
  });
  const [finalizeInvoicesMutation] = useFinalizeInvoicesMutation({
    refetchQueries: [
      CurrentBillingPeriodInvoiceDocument,
      InvoicesByUuidsDocument,
    ],
  });
  const [getInvoiceData] = useInvoiceV2LazyQuery();
  const [getInvoiceForOrderSummaryReportData] =
    useInvoiceForOrderSummaryReportLazyQuery();
  const [updateInvoiceLimited] = useUpdateInvoiceLimitedMutation();
  const [unpostInvoice] = useUnpostInvoiceMutation();
  const [removeInvoice] = useRemoveInvoiceMutation();
  const postSendAnchorRef = React.useRef<HTMLButtonElement>(null);
  const [postSendDropdownOpen, setPostSendDropdownOpen] =
    useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [invoice, setInvoice] = useState<
    ShallowInvoiceWithoutShipmentsFragment | null | undefined
  >();
  const disableInvoiceEditing =
    invoice?.status !== InvoiceStatus.NotFinalized ||
    invoice.source === InvoiceSource.Migrated;
  const [name, setName] = useState<string | undefined>();
  const [termsUuid, setTermsUuid] = useState<string | null>(null);
  const [date, setDate] = useState<Dayjs | null | undefined>();
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [showPostAndSendModal, setShowPostAndSendModal] =
    useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState(0);
  const contactUuid = invoice?.billToContact.uuid;

  const fetchInvoice = async () => {
    const res = await getInvoiceData({
      variables: { uuid: invoiceUuid },
    });
    const currentInvoice = res.data?.invoice;
    setInvoice(currentInvoice);
    setName(currentInvoice?.name);
    setDate(dayjs(currentInvoice?.date));
    setTermsUuid(currentInvoice?.invoiceTerms?.uuid ?? null);
  };

  const update = async ({ newName, newDate, newTermsUuid }: UpdateInput) => {
    await updateInvoiceLimited({
      variables: {
        updateInvoiceLimitedInput: {
          uuid: invoiceUuid,
          name: newName,
          date: isNil(newDate) ? undefined : newDate.toDate(),
          invoiceTermsUuid: newTermsUuid,
        },
      },
    });
  };

  const post = async () => {
    await finalizeInvoicesMutation({
      variables: {
        finalizeInvoicesInput: {
          uuids: [invoiceUuid],
        },
      },
    });
    fetchInvoice();
    setShouldRefreshInvoiceList(true);
  };

  const unpost = async () => {
    await unpostInvoice({
      variables: {
        unpostInvoiceInput: {
          uuid: invoiceUuid,
        },
      },
    });
    fetchInvoice();
    setShouldRefreshInvoiceList(true);
  };

  const deleteInvoice = async () => {
    const res = await removeInvoice({
      variables: {
        uuid: invoiceUuid,
      },
    });
    if (isNil(res.errors)) {
      setShouldRefreshInvoiceList(true);
      setOpenedInvoiceUuid(undefined);
    }
  };

  const handlePostSendDropdownClose = (event: Event) => {
    if (
      postSendAnchorRef.current &&
      postSendAnchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setPostSendDropdownOpen(false);
  };

  const downloadInvoiceSummaryReport = async () => {
    const res = await getInvoiceForOrderSummaryReportData({
      variables: {
        uuid: invoiceUuid,
      },
    });

    const currentInvoice = res.data?.invoice;

    if (!isNil(currentInvoice)) {
      downloadInvoiceOrdersSummaryReport({
        companyData,
        invoice: currentInvoice,
      });
    }
  };

  useEffect(() => {
    if (isSaving) {
      setTimeout(() => {
        setIsSaving(false);
      }, 500);
    }
  }, [isSaving]);

  useEffect(() => {
    deselectAllInvoiceOrderUuids();
    fetchInvoice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoiceUuid]);

  return (
    <Grid container>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={downloadErrorSnackbarVisible}
      >
        <Alert
          severity="error"
          onClose={() => {
            setDownloadErrorSnackbarVisible(false);
          }}
        >
          Error downloading invoice
        </Alert>
      </Snackbar>
      <Grid item xs={12} sx={{ p: 2, paddingBottom: '10px' }}>
        <Grid container alignItems="center">
          <Grid item md={7} xs={12}>
            <Stack direction="row" spacing={2} alignItems="center">
              <FormControl>
                <TextField
                  label="Name"
                  value={name ?? ''}
                  size="small"
                  sx={{ width: '175px' }}
                  disabled={disableInvoiceEditing}
                  InputProps={{
                    endAdornment: !disableInvoiceEditing && (
                      <Typography
                        sx={{ fontSize: '12px', color: 'gray', mr: '1px' }}
                      >
                        {isSaving ? 'Saving...' : 'Saved'}
                      </Typography>
                    ),
                  }}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  onBlur={(e) => {
                    setIsSaving(true);
                    update({ newName: e.target.value });
                  }}
                />
              </FormControl>
              <FormControl>
                <InputLabel shrink id="invoice-terms-label">
                  Invoice Terms
                </InputLabel>
                <Select
                  notched
                  labelId="invoice-terms-label"
                  label="Invoice Terms"
                  disabled={disableInvoiceEditing}
                  value={termsUuid}
                  size="small"
                  sx={{ width: '100px' }}
                  onChange={(e) => {
                    setTermsUuid(e.target.value);
                    update({ newDate: date, newTermsUuid: e.target.value });
                  }}
                >
                  {[...(netTermsData?.invoiceTerms ?? [])]
                    .sort((a, b) => {
                      const termAWithoutPrefix = a?.terms.slice(4);
                      const termBWithoutPrefix = b?.terms.slice(4);

                      const termA =
                        Number.parseInt(termAWithoutPrefix, 10) || 0;

                      const termB =
                        Number.parseInt(termBWithoutPrefix, 10) || 0;
                      return termA - termB; // Compare numerically
                    })
                    .map((invoiceTerms) => (
                      <MenuItem
                        key={invoiceTerms.uuid}
                        value={invoiceTerms.uuid}
                      >
                        {sentenceCase(invoiceTerms.terms)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date"
                    value={date}
                    disabled={invoice?.status !== InvoiceStatus.NotFinalized} // Currently want to allow editing date on migrated unposted invoices
                    renderInput={(params) => (
                      <TextField
                        aria-label="date-picker"
                        sx={{ width: 150 }}
                        size="small"
                        {...params}
                      />
                    )}
                    onChange={(newValue) => {
                      setDate(newValue);
                      update({ newDate: newValue, newTermsUuid: termsUuid });
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
              {invoice?.source === InvoiceSource.Migrated && (
                <Chip label="Migrated" />
              )}
            </Stack>
          </Grid>
          <Grid item md={5} xs={12}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ float: 'right' }}
            >
              <Button
                size="small"
                variant="contained"
                color="info"
                onClick={() => {
                  setShowDownloadModal(true);
                }}
              >
                Download
              </Button>
              {invoice?.status === InvoiceStatus.NotFinalized ? (
                <Button
                  size="small"
                  variant="contained"
                  disabled={!canWriteInvoices}
                  onClick={() => {
                    setShowPostAndSendModal(true);
                  }}
                >
                  Post / Send
                </Button>
              ) : (
                <Button
                  size="small"
                  variant="contained"
                  color="error"
                  disabled={!canWriteInvoices}
                  onClick={unpost}
                >
                  Unpost
                </Button>
              )}
              <IconButton
                ref={postSendAnchorRef}
                size="small"
                aria-controls={
                  postSendDropdownOpen ? 'split-button-menu' : undefined
                }
                aria-expanded={postSendDropdownOpen ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                disabled={!canWriteInvoices}
                onClick={() => {
                  setPostSendDropdownOpen(!postSendDropdownOpen);
                }}
              >
                <MoreVertIcon />
              </IconButton>
              <Popper
                transition
                disablePortal
                sx={{
                  zIndex: 10,
                }}
                open={postSendDropdownOpen}
                anchorEl={postSendAnchorRef.current}
                role={undefined}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener
                        onClickAway={handlePostSendDropdownClose}
                      >
                        <MenuList autoFocusItem id="split-button-menu">
                          {invoice?.status !== InvoiceStatus.NotFinalized && (
                            <MenuItem
                              onClick={() => {
                                setShowPostAndSendModal(true);
                              }}
                            >
                              Post / Send
                            </MenuItem>
                          )}
                          <MenuItem onClick={post}>Post Only</MenuItem>
                          <MenuItem onClick={downloadInvoiceSummaryReport}>
                            Download Invoice Summary Report
                          </MenuItem>
                          <Divider />
                          <Tooltip
                            title={
                              isNil(invoice?.firstShipment)
                                ? null
                                : 'Invoice must be empty in order to delete'
                            }
                          >
                            <span>
                              <MenuItem
                                disabled={!isNil(invoice?.firstShipment)}
                                onClick={deleteInvoice}
                              >
                                <ListItemIcon>
                                  <DeleteIcon fontSize="small" />
                                </ListItemIcon>
                                Delete Invoice
                              </MenuItem>
                            </span>
                          </Tooltip>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              <Tooltip title="Close Invoice View">
                <IconButton
                  onClick={() => {
                    setSearchParams((sp) => {
                      const newParams = new URLSearchParams(sp);
                      newParams.delete('invoiceUuid');
                      newParams.delete('invoiceName');
                      return newParams;
                    });
                    setOpenedInvoiceUuid(undefined);
                    setShowInvoiceDetails(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ overflowY: 'scroll' }}>
          <ButtonGroup
            disableElevation
            variant="outlined"
            size="small"
            sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 1 }}
          >
            <Button
              variant={tabIndex === 0 ? 'contained' : 'outlined'}
              onClick={() => {
                setTabIndex(0);
              }}
            >
              Orders
            </Button>
            <Button
              variant={tabIndex === 1 ? 'contained' : 'outlined'}
              onClick={() => {
                setTabIndex(1);
              }}
            >
              Payments
            </Button>
            <Button
              variant={tabIndex === 2 ? 'contained' : 'outlined'}
              onClick={() => {
                setTabIndex(2);
              }}
            >
              Transmissions
            </Button>
          </ButtonGroup>
          {tabIndex === 0 && (
            <InvoiceOrdersList
              invoiceUuid={invoiceUuid}
              contactUuid={contactUuid}
              disableInvoiceEditing={disableInvoiceEditing}
            />
          )}
          {tabIndex === 1 && <InvoicePaymentsList invoiceUuid={invoiceUuid} />}
          {tabIndex === 2 && (
            <InvoiceTransmissionsList invoiceUuid={invoiceUuid} />
          )}
        </Box>
      </Grid>
      {!isNil(contactUuid) && (
        <DownloadInvoiceModal
          isOpen={showDownloadModal}
          setIsOpen={setShowDownloadModal}
          invoiceUuids={[invoiceUuid]}
          contactUuid={contactUuid}
          setDownloadErrorSnackbarVisible={setDownloadErrorSnackbarVisible}
        />
      )}
      <PostAndSendModal
        isOpen={showPostAndSendModal}
        setIsOpen={setShowPostAndSendModal}
        invoiceUuids={[invoiceUuid]}
        setIsHeaderCheckboxSelected={setIsHeaderCheckboxSelected}
        onPostAndSend={() => {
          setShouldRefreshInvoiceList(true);
          fetchInvoice();
        }}
      />
    </Grid>
  );
};

export default React.memo(InvoiceDetails);
