import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  // eslint-disable-next-line no-restricted-imports
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from '@mui/material';
import { sentenceCase } from 'change-case';
import fileDownload from 'js-file-download';
import { isEmpty, isNil } from 'lodash';
import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CSVLink } from 'react-csv';
import BusinessDivisionFilterButton, {
  type BusinessDivisionOption,
} from '../../../../../common/components/business-division-filter-button';
import CustomerFilterButton from '../../../../../common/components/customer-filter-button';
import DateDropdownPicker, {
  type DateOption,
  getDefaultPast1WeekDateRangeOption,
} from '../../../../../common/components/date-dropdown-picker';
import DownloadTypeSelection, {
  DownloadType,
} from '../../../../../common/components/download-type-selection';
import ReportGroupingSelection, {
  ReportGroupingType,
} from '../../../../../common/components/report-grouping-selection';
import SingleSelectFilterButton from '../../../../../common/components/single-select-filter-button';
import TerminalFilterButton from '../../../../../common/components/terminal-filter-button';
import { type Option } from '../../../../../common/filters/types';
import useMe from '../../../../../common/react-hooks/use-me';
import useTerminals from '../../../../../common/react-hooks/use-terminals';
import {
  InvoiceBillingSummaryDetailType,
  InvoiceBillingSummaryGroupByType,
  InvoiceStatus,
  useBusinessDivisionsQuery,
  useInvoiceBillingSummaryReportV2LazyQuery,
  useInvoiceBillingSummaryReportV2PdfLazyQuery,
} from '../../../../../generated/graphql';
import {
  throwIfFetchFails,
  useDownloadReport,
} from '../../../../reports/hooks/use-download-report';
import styles from '../../../styles';
import { InvoiceStatusTab } from '../../../types/types';
import { downloadInvoiceBillingSummaryV2Csv } from '../../../utils';
import ReportDateFilterTypeButton, {
  ReportDateFilterType,
} from './components/report-date-filter-type-button';

type DownloadInvoiceBillingSummaryModalProps = {
  readonly isOpen: boolean;
  readonly setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const DownloadInvoiceBillingSummaryModal = ({
  isOpen,
  setIsOpen,
}: DownloadInvoiceBillingSummaryModalProps) => {
  const [reportDateFilterType, setReportDateFilterType] =
    useState<ReportDateFilterType>(ReportDateFilterType.INVOICE_DATE);
  const [dateOption, setDateOption] = useState<DateOption>(
    getDefaultPast1WeekDateRangeOption(),
  );
  const [customerOption, setCustomerOption] = useState<Option | undefined>();
  const [terminalOption, setTerminalOption] = useState<Option | undefined>();
  const [businessDivisionOption, setBusinessDivisionOption] = useState<
    BusinessDivisionOption | undefined
  >();
  const { terminalsEnabled } = useTerminals({
    includeInactiveTerminals: false,
  });
  const [groupOrdersBy, setGroupOrdersBy] = useState<ReportGroupingType>(
    terminalsEnabled ? ReportGroupingType.TERMINAL : ReportGroupingType.CONTACT,
  );
  const [reportDetailType, setReportDetailType] =
    useState<InvoiceBillingSummaryDetailType>(
      InvoiceBillingSummaryDetailType.Detailed,
    );
  const [downloadType, setDownloadType] = useState<DownloadType>(
    DownloadType.PDF,
  );
  const [lineHaulOnly, setLineHaulOnly] = useState<boolean>(false);

  const [getBillingSummaryData, { loading: billingSummaryLoading }] =
    useInvoiceBillingSummaryReportV2LazyQuery();

  const [getBillingSummaryPdf] = useInvoiceBillingSummaryReportV2PdfLazyQuery();
  const [statusTab, setStatusTab] = useState<InvoiceStatusTab>(
    InvoiceStatusTab.ALL,
  );
  const { companyConfiguration } = useMe();
  const [csvReportData, setCSVReportData] = useState<{
    fileName: string;
    csvData: Array<string[] | Array<string | number | undefined>>;
  }>({ fileName: '', csvData: [] });
  const csvLink = useRef<{ link: HTMLAnchorElement }>(null);

  const { data: businessDivisionsData } = useBusinessDivisionsQuery({
    fetchPolicy: 'cache-first',
  });
  const businessDivisions =
    businessDivisionsData?.businessDivisions?.businessDivisions ?? [];

  const resetFilters = () => {
    setDateOption(getDefaultPast1WeekDateRangeOption());
    setTerminalOption(undefined);
    setBusinessDivisionOption(undefined);
    setCustomerOption(undefined);
    setStatusTab(InvoiceStatusTab.ALL);
  };

  const filterStatuses = useMemo(() => {
    let statuses = [
      InvoiceStatus.NotFinalized,
      InvoiceStatus.ReadyToInvoice,
      InvoiceStatus.Invoiced,
    ];

    if (statusTab === InvoiceStatusTab.NOT_POSTED) {
      statuses = [InvoiceStatus.NotFinalized];
    } else if (statusTab === InvoiceStatusTab.POSTED) {
      statuses = [InvoiceStatus.ReadyToInvoice, InvoiceStatus.Invoiced];
    }

    return statuses;
  }, [statusTab]);

  const billingSummaryInputs = useMemo(() => {
    return {
      statuses: filterStatuses,

      invoiceStartDate:
        reportDateFilterType === ReportDateFilterType.INVOICE_DATE
          ? dateOption.startDate
          : null,

      invoiceEndDate:
        reportDateFilterType === ReportDateFilterType.INVOICE_DATE
          ? dateOption.endDate
          : null,

      serviceDateFilter:
        // TODO: Switch to using the filter construction functions once they are defined.
        reportDateFilterType === ReportDateFilterType.SERVICE_DATE
          ? {
              gte: dateOption.startDate,
              lte: dateOption.endDate,
            }
          : null,
      terminalUuid: terminalOption?.value ?? undefined,
      billingPartyContactUuid: customerOption?.value ?? undefined,
      businessDivisionUuid: businessDivisionOption?.value ?? undefined,
      reportGroupByType:
        groupOrdersBy === ReportGroupingType.CONTACT
          ? InvoiceBillingSummaryGroupByType.Contact
          : InvoiceBillingSummaryGroupByType.Terminal,
      reportDetailType,
      onlyLineHaul: lineHaulOnly,
    };
  }, [
    reportDateFilterType,
    dateOption,
    terminalOption,
    customerOption,
    businessDivisionOption,
    reportDetailType,
    groupOrdersBy,
    lineHaulOnly,
    filterStatuses,
  ]);

  const startDownload = async () => {
    // Can't close modal until download is complete so CSV link can be clicked
    if (downloadType !== DownloadType.CSV) setIsOpen(false);

    if (downloadType === DownloadType.CSV) {
      const res = await getBillingSummaryData({
        variables: {
          invoiceBillingSummaryV2Input: billingSummaryInputs,
        },
      });
      throwIfFetchFails(res);
      const billingSummaryData = res.data?.invoiceBillingSummaryReportV2;
      if (isNil(billingSummaryData)) {
        throw new Error('Failed to download Invoice billing summary report');
      }
      const csvDataRes = await downloadInvoiceBillingSummaryV2Csv({
        startDate: dateOption.startDate,
        endDate: dateOption.endDate,
        billingSummaryData,
        businessDivisionOption,
        customerOption,
        reportDetailType,
        groupOrdersBy:
          groupOrdersBy === ReportGroupingType.CONTACT
            ? InvoiceBillingSummaryGroupByType.Contact
            : InvoiceBillingSummaryGroupByType.Terminal,
      });
      setCSVReportData(csvDataRes);
    }
    if (downloadType === DownloadType.PDF) {
      const pdfRes = await getBillingSummaryPdf({
        variables: {
          invoiceBillingSummaryV2Input: billingSummaryInputs,
        },
      });
      throwIfFetchFails(pdfRes);

      const presignedGetUrl = pdfRes.data?.invoiceBillingSummaryReportV2Pdf.url;
      const fileName = pdfRes.data?.invoiceBillingSummaryReportV2Pdf.fileName;

      if (isNil(presignedGetUrl) || isNil(fileName)) {
        throw new Error('Failed to download Invoice billing summary report');
      }
      const getFileRes = await fetch(presignedGetUrl, { cache: 'no-cache' });

      const blob = await getFileRes.blob();

      fileDownload(blob, fileName);
    }

    resetFilters();
  };

  const startDownloadWithMeasurement = useDownloadReport({
    name: 'Invoice billing summary',
    rumLabel: 'download-invoice-billing-summary',
    filters: billingSummaryInputs,
    downloadType,
    downloadReport: startDownload,
  });

  useEffect(() => {
    if (!isEmpty(csvReportData)) {
      csvLink.current?.link.click();
      setIsOpen(false);
      resetFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvReportData]);

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      onClose={() => {
        if (!billingSummaryLoading || downloadType !== DownloadType.CSV) {
          setIsOpen(false);
        }
      }}
    >
      <Box sx={[styles.modal, { height: 'fit-content', width: '600px' }]}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <Typography sx={{ fontWeight: 'bold' }}>
              Download Invoice Billing Summary
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <IconButton
              sx={{ float: 'right' }}
              disabled={
                billingSummaryLoading && downloadType === DownloadType.CSV
              }
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <ReportDateFilterTypeButton
              value={reportDateFilterType}
              onChange={setReportDateFilterType}
            />
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="row" gap={1}>
            <DateDropdownPicker
              filterTitle={reportDateFilterType}
              dateOption={dateOption}
              setDateOption={setDateOption}
            />
            <FormControl sx={{ width: '35%' }}>
              <InputLabel id="invoice-status-label">Invoice Status</InputLabel>
              <Select
                required
                labelId="invoice-status-label"
                label="Invoice Status"
                value={statusTab}
                size="small"
                sx={{ backgroundColor: 'white' }}
                onChange={(e) => {
                  setStatusTab(e.target.value as InvoiceStatusTab);
                }}
              >
                {Object.values(InvoiceStatusTab).map((status) => (
                  <MenuItem key={status} id={status} value={status}>
                    {sentenceCase(status)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            gap={1}
            flexWrap="wrap"
          >
            <CustomerFilterButton
              selectedOption={customerOption}
              handleChange={(option: Option | undefined) => {
                setCustomerOption(option);
              }}
            />
            {terminalsEnabled && (
              <TerminalFilterButton
                displayCode
                prefixText="Terminal"
                selectedOption={terminalOption}
                handleChange={(option: Option | null | undefined) => {
                  setTerminalOption(option ?? undefined);
                }}
                includeInactiveTerminals={false}
              />
            )}
            {!isEmpty(businessDivisions) && (
              <BusinessDivisionFilterButton
                prefixText="Business division"
                selectedOption={businessDivisionOption}
                handleChange={(option: BusinessDivisionOption | undefined) => {
                  setBusinessDivisionOption(option);
                }}
              />
            )}
            {companyConfiguration?.lineHaulEnabled === true && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '5px',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  checked={lineHaulOnly}
                  onChange={(e) => {
                    setLineHaulOnly(e.target.checked);
                  }}
                />
                <Typography>Line haul only</Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{ float: 'left' }}
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={1}
            >
              {terminalsEnabled && (
                <ReportGroupingSelection
                  labelText="Group by"
                  cacheId="INVOICES"
                  selectedOption={groupOrdersBy}
                  handleChange={(reportGroupingType: ReportGroupingType) => {
                    setGroupOrdersBy(reportGroupingType);
                  }}
                />
              )}
              <DownloadTypeSelection
                labelText="File type"
                cacheId="INVOICE_BILLING_SUMMARY"
                selectedOption={downloadType}
                handleChange={(selectedDownloadType: DownloadType) => {
                  setDownloadType(selectedDownloadType);
                }}
              />
              <SingleSelectFilterButton
                option={reportDetailType}
                handleChange={(
                  selectedDetailType:
                    | InvoiceBillingSummaryDetailType
                    | undefined,
                ) => {
                  setReportDetailType(
                    selectedDetailType ??
                      InvoiceBillingSummaryDetailType.Detailed,
                  );
                }}
                options={Object.values(InvoiceBillingSummaryDetailType)}
                enableDefaultFilter={false}
                filterTitle="Report type:"
              />
            </Box>
            <Box sx={{ float: 'right' }}>
              <Button
                sx={{ width: '100px' }}
                variant="contained"
                color="info"
                disabled={
                  billingSummaryLoading && downloadType === DownloadType.CSV
                }
                onClick={startDownloadWithMeasurement}
              >
                Download
              </Button>
            </Box>
          </Grid>
          <CSVLink
            ref={csvLink}
            data={csvReportData.csvData}
            filename={csvReportData.fileName}
            className="hidden"
            target="_blank"
          />
        </Grid>
      </Box>
    </Modal>
  );
};

export default DownloadInvoiceBillingSummaryModal;
