import CheckIcon from '@mui/icons-material/Check';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  TextField,
  Box,
  IconButton,
  Fade,
  Stack,
  Button,
  useTheme,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { type Dayjs } from 'dayjs';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import TimePickerComponent from './time-picker-component';

export type DateFieldProps = {
  readonly date: Date | null;
  readonly setDate: (newDate: Date | null) => void;
  readonly editable?: boolean;
  readonly hideMMDDYYY?: boolean;
  readonly hideHHMM?: boolean;
  readonly closeEditingOnChange?: string;
  readonly onInstantiateDate?: () => void;
  readonly onFinishEditing?: (date: Date) => void;
};
const DateField = ({
  date,
  setDate,
  editable = true,
  hideMMDDYYY = false,
  hideHHMM = false,
  closeEditingOnChange,
  onInstantiateDate,
  onFinishEditing,
}: DateFieldProps) => {
  const theme = useTheme();
  const [isEditing, setIsEditing] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    setIsEditing(false);
  }, [closeEditingOnChange]);

  const emptyStateEditComponent = (
    <Button
      sx={{ p: '5px', color: theme.palette.grey['600'] }}
      onClick={() => {
        setIsEditing(true);
        if (isNil(date) && !isNil(onInstantiateDate)) {
          onInstantiateDate();
        }
      }}
    >
      + Add time
    </Button>
  );

  const dateEditComponent = (
    <Fade in={isHovering}>
      <IconButton
        sx={{ p: '5px' }}
        onClick={() => {
          setIsEditing(true);
          if (isNil(date) && !isNil(onInstantiateDate)) {
            onInstantiateDate();
          }
        }}
      >
        <ModeEditIcon sx={{ fontSize: '20px' }} />
      </IconButton>
    </Fade>
  );

  const buildEditComponent = () => {
    if (!editable) {
      return null;
    }

    if (isNil(date)) {
      return emptyStateEditComponent;
    }

    return dateEditComponent;
  };

  let format = 'MM/DD/YYYY hh:mm a';
  if (hideHHMM) {
    format = 'MM/DD/YYYY';
  }
  if (hideMMDDYYY) {
    format = 'hh:mm a';
  }

  const getFormattedDate = () => {
    if (isNil(date)) {
      return { day: null, time: null };
    }
    const day = dayjs(date).format('MM/DD/YYYY');
    const time = dayjs(date).format('hh:mm a');
    return { day, time };
  };

  if (isEditing) {
    return (
      <Stack direction="row" alignItems="center" spacing={0.5}>
        {!hideMMDDYYY && (
          <DatePicker
            renderInput={(props) => (
              <TextField sx={{ width: 130 }} size="small" {...props} />
            )}
            value={date}
            onChange={(newDate) => {
              setDate(newDate);
            }}
          />
        )}
        {!hideHHMM && (
          <TimePickerComponent
            hideClearable
            appointmentTime={date}
            updateAppointmentTime={(time: Dayjs | undefined | null) => {
              if (!isNil(time)) {
                const newTimeString = `${dayjs(date).format(
                  'MM/DD/YYYY',
                )} ${time.format('hh:mm a')}`;
                const newTime = dayjs(newTimeString);
                if (newTime.isValid()) {
                  setDate(newTime.toDate());
                }
              }
            }}
            width={40}
          />
        )}
        <IconButton
          onClick={() => {
            if (!isNil(onFinishEditing) && !isNil(date)) {
              onFinishEditing(date);
            }
            setIsEditing(false);
          }}
        >
          <CheckIcon sx={{ fontSize: '20px' }} />
        </IconButton>
      </Stack>
    );
  }

  const { day, time } = getFormattedDate();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: '2px',
        alignItems: 'center',
      }}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      {isNil(date) ? (
        !editable && '-'
      ) : (
        <Stack direction="column">
          {!hideMMDDYYY && <Typography variant="body2">{day}</Typography>}
          {!hideHHMM && <Typography variant="body2">{time}</Typography>}
        </Stack>
      )}
      {buildEditComponent()}
    </Box>
  );
};

export default DateField;
