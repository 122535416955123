import {
  type TooltipProps,
  Stack,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import { type PropsWithChildren } from 'react';
import { styled } from 'styled-components';
import {
  type LineHaulManifestStatus,
  type TerminalEntity,
} from '../../../../../generated/graphql';
import theme from '../../../../../theme';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.concreteGrey[90],
    maxWidth: 220,
    borderColor: theme.palette.concreteGrey[90],
    transform: 'translateX(-50%) !important',
    left: '20px !important',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.concreteGrey[90],
    left: '50% !important',
    transform: 'translateX(-50%) !important',
  },
}));

type LineHaulManifestsTooltipProps = PropsWithChildren<{
  readonly terminalsWithStatuses: Array<{
    status: LineHaulManifestStatus | 'Awaiting';
    terminal: Pick<TerminalEntity, 'uuid' | 'code'>;
  }>;
}>;

const LineHaulManifestsTooltip = ({
  terminalsWithStatuses,
  children,
}: LineHaulManifestsTooltipProps) => {
  const title = (
    <Stack direction="row" flexWrap="wrap" rowGap={0}>
      {terminalsWithStatuses.map(({ status, terminal }, index) => (
        <Stack
          // eslint-disable-next-line react/no-array-index-key -- Safe to use index as key since no reordering is done
          key={index}
          direction="row"
          sx={{ backgroundColor: theme.palette.concreteGrey[90] }}
          fontSize={12}
          alignItems="center"
        >
          <Stack direction="row" gap={0.5} alignItems="center">
            <Typography color="white" fontSize="inherit">
              {terminal.code}
            </Typography>
            <Typography
              color={theme.palette.concreteGrey[40]}
              fontSize="inherit"
            >
              {status.toLowerCase()}
            </Typography>
          </Stack>
          {index < terminalsWithStatuses.length - 1 && (
            <ChevronRightIcon
              sx={{ color: theme.palette.concreteGrey[40] }}
              fontSize="inherit"
            />
          )}
        </Stack>
      ))}
    </Stack>
  );
  return (
    <HtmlTooltip
      arrow
      title={title}
      placement="bottom-start"
      slotProps={{
        popper: {
          modifiers: [{ name: 'offset', options: { offset: [0, -7.5] } }],
        },
      }}
    >
      <span>{children}</span>
    </HtmlTooltip>
  );
};

export default LineHaulManifestsTooltip;
