import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Stack, TableBody, Typography } from '@mui/material';
import { findLastIndex, isEmpty, isNil } from 'lodash';
import { memo, useEffect, useRef } from 'react';
import { exhaustive } from 'shared/switch';
import { scrollParentToChild } from '../../../common/utils/utils';
import {
  type RouteFragment,
  RouteStatus,
  StopStatus,
} from '../../../generated/graphql';
import useRouteActions from '../hooks/use-route-actions';
import { type TableStopOnRoute } from './route-card-stops-list-columns';
import RouteCardStopsListRow from './route-card-stops-list-row';

const RouteCardStopsList = ({
  route,
  stops,
  numberOfStops,
}: {
  readonly route: RouteFragment;
  readonly stops: TableStopOnRoute[];
  readonly numberOfStops: number;
}) => {
  const ref = useRef<HTMLDivElement>();
  const { onDragStart, onDragEnd } = useRouteActions();

  const scrollStopIndexIntoView = (index: number) => {
    const stopChildElement = document?.getElementById(
      `stop_list_row_${route.slots[index]?.stops[0]?.uuid}`,
    );
    if (!isNil(ref.current) && !isNil(stopChildElement)) {
      scrollParentToChild(ref.current, stopChildElement);
    }
  };

  useEffect(() => {
    switch (route.routeInfo.status) {
      case RouteStatus.NotStarted: {
        scrollStopIndexIntoView(0);
        break;
      }
      case RouteStatus.InProgress: {
        const lastStopCompleteIndex = findLastIndex(
          route.slots,
          (slot) => slot.stops[0]?.status === StopStatus.Completed,
        );
        scrollStopIndexIntoView(
          Math.min(
            route.slots.length - 1,
            lastStopCompleteIndex + numberOfStops - 1,
          ),
        );
        break;
      }
      case RouteStatus.Complete: {
        const firstStopNotCompleteIndex = route.slots.findIndex(
          (slot) => slot.stops[0]?.status !== StopStatus.Completed,
        );
        scrollStopIndexIntoView(firstStopNotCompleteIndex);
        break;
      }
      case RouteStatus.Incomplete: {
        const firstStopNotArrivedIndex = route.slots.findIndex(
          (slot) => slot.stops[0]?.status === StopStatus.NotArrived,
        );
        scrollStopIndexIntoView(firstStopNotArrivedIndex);
        break;
      }
      case RouteStatus.AwaitingAgentConfirmation: {
        const firstStopNotConfirmedIndex = route.slots.findIndex((slot) =>
          isNil(slot.stops[0]?.confirmedByAgentAt),
        );
        scrollStopIndexIntoView(firstStopNotConfirmedIndex);
        break;
      }
      case RouteStatus.AwaitingSendToAgent: {
        const firstStopNotSentIndex = route.slots.findIndex((slot) =>
          isNil(slot.stops[0]?.sentToAgentAt),
        );
        scrollStopIndexIntoView(firstStopNotSentIndex);
        break;
      }
      default: {
        return exhaustive(route.routeInfo.status);
      }
    }
  }, []);

  return (
    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <Droppable droppableId={route.uuid}>
        {}
        {(provided, snapshot) => (
          <Stack
            sx={{
              height: 20 * numberOfStops,
              overflowY: 'scroll',
              overflowX: 'scroll',
            }}
            {...provided.droppableProps}
            ref={(el: HTMLDivElement) => {
              provided.innerRef(el);
              ref.current = el;
            }}
            className="hide-scrollbar"
          >
            {isEmpty(route.slots) && (
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ textAlign: 'center' }}
              >
                No Stops
              </Typography>
            )}
            {stops.map((stop, index) => {
              return (
                <Draggable
                  key={stop.uuid}
                  draggableId={stop.uuid}
                  index={index}
                  isDragDisabled={
                    stop.status !== StopStatus.NotArrived || route.locked
                  }
                >
                  {}
                  {(prov, snap) => (
                    <TableBody
                      ref={prov.innerRef}
                      id={`stop_list_row_${stop.uuid}`}
                      {...prov.draggableProps}
                      {...prov.dragHandleProps}
                    >
                      <RouteCardStopsListRow
                        idx={index}
                        stop={stop}
                        locked={route.locked}
                      />
                    </TableBody>
                  )}
                </Draggable>
              );
            })}
          </Stack>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default memo(RouteCardStopsList);
