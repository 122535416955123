import {
  Box,
  FormHelperText,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { isNil } from 'lodash';
import { type ChangeEvent, useEffect, useState } from 'react';
import { isNilOrEmptyString } from 'shared/string';
import useDimensionsInputRefs from './use-dimensions-input-refs';

const SMALL_FONT_SIZE_STYLE = { fontSize: '12px' };
const SMALL_CONTAINER_SX = {
  ...SMALL_FONT_SIZE_STYLE,
  height: '25px',
};

const DimensionsTextField = styled(TextField)`
  width: ${({
    value,
    resizeInputs,
  }: {
    value: string;
    resizeInputs: boolean;
  }) =>
    resizeInputs
      ? // Increase the width of the text field as the value gets longer.
        `${20 + Math.max(20, value.toString().length * 10)}px`
      : '75px'};
  flex-grow: 1;
  & .MuiOutlinedInput-root fieldset {
    border: none;
  }
  & .MuiInputBase-input {
    text-align: right;
  }
`;

const DimensionsTextFieldLabel = styled(Box)`
  background-color: white;
  padding: 0 4px;
`;

const DimensionsInputContainer = styled(Box)<{ error?: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 2px;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: 4px;
  border-color: ${({ theme, error }) =>
    error === true ? theme.palette.error.main : theme.palette.borderColor.main};
`;

// Removes unnecessary precision (e.g. from dividing values when converting units)
// without adding trailing zeros.
const formatDimension = (value: number | null | undefined): string => {
  if (isNil(value) || !Number.isFinite(value)) {
    return '';
  }
  return Number.parseFloat(value.toFixed(2)).toString();
};

type DimensionsInputProps = {
  readonly length: number | null | undefined;
  readonly width: number | null | undefined;
  readonly height: number | null | undefined;
  readonly onChangeLength: (length: number | null | undefined) => void;
  readonly onChangeWidth: (width: number | null | undefined) => void;
  readonly onChangeHeight: (height: number | null | undefined) => void;
  readonly disabled?: boolean;
  readonly showLabel?: boolean;
  /**
   * If showLabel is true, you'll likely also want to set this to false so
   * the labels aren't cut off.
   */
  readonly resizeInputs?: boolean;
  readonly size?: 'small';
  readonly error?: string;
  readonly lengthTestId?: string;
  readonly widthTestId?: string;
  readonly heightTestId?: string;
};

/**
 * Renders three inputs for length, width, and height with borders removed
 * to appear as a single input field.
 * Supports tabbing and arrow keys to navigate between inputs.
 * Units must be handled by the parent component.
 * Display an error if any values are invalid numbers.
 */
const DimensionsInput = ({
  length,
  width,
  height,
  onChangeLength,
  onChangeWidth,
  onChangeHeight,
  disabled,
  showLabel = false,
  resizeInputs = true,
  size,
  error,
  lengthTestId,
  widthTestId,
  heightTestId,
}: DimensionsInputProps) => {
  const dimensionsInputRefs = useDimensionsInputRefs();

  const [lengthInput, setLengthInput] = useState(formatDimension(length));
  const [widthInput, setWidthInput] = useState(formatDimension(width));
  const [heightInput, setHeightInput] = useState(formatDimension(height));

  useEffect(() => {
    setLengthInput(formatDimension(length));
    setWidthInput(formatDimension(width));
    setHeightInput(formatDimension(height));
  }, [length, width, height]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onInputChange: (value: string) => void,
    onValueChange: (value: number | null) => void,
  ) => {
    const newValue = e.target.value;
    const parsedFloat = Number.parseFloat(newValue);
    onInputChange(newValue);
    if (Number.isFinite(parsedFloat)) {
      onValueChange(parsedFloat);
    } else if (newValue === '') {
      onValueChange(null);
    }
  };

  const fontSizeStyle = size === 'small' ? SMALL_FONT_SIZE_STYLE : undefined;

  return (
    <Stack gap={0.25}>
      <DimensionsInputContainer
        sx={size === 'small' ? SMALL_CONTAINER_SX : undefined}
        error={!isNilOrEmptyString(error)}
      >
        <DimensionsTextField
          label={
            showLabel ? (
              <DimensionsTextFieldLabel>Length</DimensionsTextFieldLabel>
            ) : undefined
          }
          disabled={disabled}
          size="small"
          resizeInputs={resizeInputs}
          inputRef={(el) => {
            dimensionsInputRefs.current[0] = el;
          }}
          value={lengthInput}
          inputProps={{
            'data-testid': lengthTestId,
            style: fontSizeStyle,
          }}
          onChange={(e) => {
            handleInputChange(e, setLengthInput, onChangeLength);
          }}
        />
        <Typography>⨉</Typography>
        <DimensionsTextField
          label={
            showLabel ? (
              <DimensionsTextFieldLabel>Width</DimensionsTextFieldLabel>
            ) : undefined
          }
          disabled={disabled}
          size="small"
          resizeInputs={resizeInputs}
          inputRef={(el) => {
            dimensionsInputRefs.current[1] = el;
          }}
          value={widthInput}
          inputProps={{
            'data-testid': widthTestId,
            style: fontSizeStyle,
          }}
          onChange={(e) => {
            handleInputChange(e, setWidthInput, onChangeWidth);
          }}
        />
        <Typography>⨉</Typography>
        <DimensionsTextField
          label={
            showLabel ? (
              <DimensionsTextFieldLabel>Height</DimensionsTextFieldLabel>
            ) : undefined
          }
          disabled={disabled}
          size="small"
          resizeInputs={resizeInputs}
          inputRef={(el) => {
            dimensionsInputRefs.current[2] = el;
          }}
          value={heightInput}
          inputProps={{
            'data-testid': heightTestId,
            style: fontSizeStyle,
          }}
          onChange={(e) => {
            handleInputChange(e, setHeightInput, onChangeHeight);
          }}
        />
      </DimensionsInputContainer>
      {!isNilOrEmptyString(error) && (
        <FormHelperText sx={{ color: 'error.main' }}>{error}</FormHelperText>
      )}
    </Stack>
  );
};

export default DimensionsInput;
